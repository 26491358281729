import { useEffect, useState } from "react";

import ContainerProfileTest from "components/common/investor-test-check/ContainerProfileTest";
import GoogleTagManager from "react-gtm-module";
import { screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getWallet } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import { trackScreen } from "utils/amplitude";
import { trackAnalyticsEvent, AnalyticsEvents } from "utils/firebaseAnalytics";
import { trackFbEvent, FbEvents } from "utils/fbTracking";
import { DesktopOrTablet, Mobile } from "utils/responsive";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import MarketDesktop from "./desktop";
import MarketMobile from "./mobile";

const MarketsPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const { wallet } = useAppSelector((state: RootState) => state.wallet);
  const currentScreen = useAppSelector(getCurrentScreen);

  const [openNewListModal, setOpenNewListModal] = useState(false);

  useEffect(() => {
    trackScreen(screenNames.market, currentScreen);
    dispatch(setCurrentScreen(screenNames.market));
    GoogleTagManager.dataLayer({
      dataLayer: { event: `wapp_home_clic_ir_a_mercado` },
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.fbq("track", "wapp_home_clic_ir_a_mercado");

    trackAnalyticsEvent(AnalyticsEvents.HOME_CLIC_IR_A_MERCADO);
    trackFbEvent(FbEvents.HOME_CLIC_IR_A_MERCADO);
  }, []);

  useEffect(() => {
    if (!wallet) {
      dispatch(getWallet());
    }
  }, []);

  const handleOpenNewListModal = (value: boolean) => {
    setOpenNewListModal(value);
  };

  return (
    <div className="pages-market">
      <ContainerProfileTest />
      <Mobile>
        <MarketMobile
          openNewListModal={openNewListModal}
          setOpenNewListModal={handleOpenNewListModal}
        />
      </Mobile>
      <DesktopOrTablet>
        <MarketDesktop
          openNewListModal={openNewListModal}
          setOpenNewListModal={handleOpenNewListModal}
        />
      </DesktopOrTablet>
    </div>
  );
};

export default MarketsPage;
