import { useState, useRef, useEffect } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cardService } from "apis/services";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";
import { ThemeVariants } from "interfaces/theme";
import { CardSections, CardStatus, CardTypes } from "interfaces/card/enums";
import { Card } from "interfaces/card/interface";
import { getEnvironment } from "utils";
import { PomelosCardDataURL } from "components/page-cocos-card/constants";
import LoadingSpinner from "components/common/LoadingSpinner";
import { useCocosCard } from "context/CocosCardProvider";
import BasicWrapper from "components/common/BasicWrapper";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

const PhysicalActivation: React.FC = () => {
  const isDarkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );
  const {
    setSelectedSection,
    getPomeloData,
    pomeloData,
    setDisplayToast,
  } = useCocosCard();
  const { isProd } = getEnvironment();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cardToken, setCardToken] = useState<any>();
  const frameRef = useRef<HTMLIFrameElement>(null);

  const getCardToken = async () => {
    setIsLoading(true);
    try {
      const { data } = await API.get(cardService.credentials);

      setCardToken(data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCardToken();

    const interval = setInterval(async () => {
      await getPomeloData();
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const isCardActive = pomeloData?.cards?.find(
    (card: Card) =>
      card.cardType === CardTypes.PHYSICAL && card.status === CardStatus.ACTIVE
  );

  if (isCardActive) {
    ampli.cardActivationSuccess();
    setSelectedSection(CardSections.HOME);
    setDisplayToast({
      visible: true,
      message: "¡Listo! Tu tarjeta fue activada exitosamente.",
    });
  }

  const pomeloSecureDataUrl = isProd
    ? PomelosCardDataURL.PRODUCTION
    : PomelosCardDataURL.STAGING;

  const variableStyles = isDarkMode
    ? ".submit-button{background:rgba(255,255,255,1)!important;color:rgba(9,10,10,1)!important;}.pin-input,.pan-input{background:rgba(24,25,27,1)!important;color:rgba(237,238,240,1)!important;border:solid rgba(54,58,63,1) 0.5px!important;}"
    : ".submit-button{background:rgba(9,10,10,1)!important;color:white!important;}.pin-input,.pan-input{background:rgba(255,255,255,1)!important;color:rgba(28,32,36,1)!important;border:solid rgba(217,217,224,1) 0.5px!important;}";

  const inlineStayles =
    ".submit-button{height:45px;border-radius:8px;font-size:12px!important;font-weight:100!important;margin:0!important;margin-bottom:10px!important;margin-top:100px!important}.pin-input,.pan-input{width:auto!important;border-radius:8px!important;margin:0!important;margin-bottom:20px!important}.activation-form{padding:0!important}";

  const url = `${pomeloSecureDataUrl}/v1/activate-card?auth=${cardToken?.access_token}&locale=es&styles_string=${inlineStayles}${variableStyles}`;

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={{
        title: "Activación",
        onClick: () => setSelectedSection(CardSections.HOME),
      }}
      className={styles.physicalAcvitationContainer}
    >
      <header>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h1"
          className={styles.title}
        >
          Activá tu tarjeta para empezar
        </Heading>
        <Text variant={TextVariants.RegularText} className={styles.subtitle}>
          Para usar tu tarjeta ingresá los 16 números de la misma y creá un PIN
          de 4 dígitos.
        </Text>
      </header>
      {isLoading || !cardToken ? (
        <LoadingSpinner size={120} variant={ThemeVariants.Pay} />
      ) : (
        <iframe
          src={url}
          width="100%"
          height="80%"
          ref={frameRef}
          frameBorder={0}
          loading="lazy"
        />
      )}
    </BasicWrapper>
  );
};

export default PhysicalActivation;
