import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import mainRoutes from "router/routes";

import styles from "./styles.module.scss";

const GoToOrdersButton = () => {
  return (
    <div className={styles.button_container}>
      <Link to={`${mainRoutes.orders}?from=home`}>
        Ver órdenes del día <IoArrowBack />
      </Link>
    </div>
  );
};

export default GoToOrdersButton;
