import { Skeleton, Typography } from "@mui/material";
import MovementsHeader from "components/page-movements/components/movements-common/movements-header";
import MovementsRow from "components/page-movements/components/movements-common/movements-row";
import DesktopTableHeader from "components/page-movements/components/movements-common/table-header";
import { getInstrumentName } from "components/page-movements/utils";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { TickerMovement, TickerMovements } from "interfaces/movements";

import styles from "./styles.module.scss";

interface TickerDetailTableProps {
  data: TickerMovements[];
}

const TickerDetailTable: React.FC<TickerDetailTableProps> = ({ data }) => {
  const { isMobile } = useDeviceCheck();

  const hasData = data && data.length > 0;

  const renderLoaderRows = (rowsLength: number) =>
    Array.from(Array(rowsLength).keys()).map((i) => (
      <Skeleton animation="wave" height={64} key={i} />
    ));

  return (
    <>
      {!isMobile ? (
        <DesktopTableHeader isTicker isTickerDetail />
      ) : (
        <div className={styles.tableHeader}>
          <div />
          <div>
            <Typography>Precio promedio</Typography>
            <Typography>Total operación</Typography>
          </div>
          <Typography>Cantidad</Typography>
        </div>
      )}

      {hasData &&
        data.map((row: TickerMovements, index: number) => (
          <>
            <MovementsHeader
              key={index}
              settlementDate={row.executionDate}
              balance={row.balance}
              displaySettlementDate={isMobile}
              isTickerDetail
            />
            <div>
              {row.tickerMovements.map(
                (movement: TickerMovement, index: number) => {
                  return (
                    <MovementsRow
                      id_ticket={movement.id_ticket}
                      has_ticket_pdf={movement.has_ticket_pdf}
                      key={index}
                      operationType={movement.label ?? movement.description}
                      name={
                        getInstrumentName(movement.instrument_code) ??
                        movement.short_name
                      }
                      quantity={movement.quantity}
                      price={movement.price}
                      currency={movement.id_currency}
                      instrumentType={movement.instrument_type}
                      logoFile={movement.logo_file_name}
                      instrumentCode={movement.instrument_code}
                      settlementDate={movement.settlement_date}
                      executionDate={movement.execution_date}
                      amount={movement.amount}
                      isTicker
                      isTickerDetail
                    />
                  );
                }
              )}
            </div>
          </>
        ))}

      {!hasData && renderLoaderRows(5)}
    </>
  );
};

export default TickerDetailTable;
