import LoadingSpinner from "components/common/LoadingSpinner";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

const LoadingScreen = ({
  isFinalStep = false,
  isSelfieStep = false,
  displayText = true,
}: {
  isFinalStep?: boolean;
  isSelfieStep?: boolean;
  displayText?: boolean;
}) => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingSpinner size={100} />
      {isSelfieStep && (
        <>
          <Heading
            variant={HeadingVariants.RegularTitle}
            component="h2"
            color="var(--slate900)"
          >
            Iniciando cámara...
          </Heading>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Esto puede tardar unos segundos.
          </Text>
        </>
      )}
      {displayText && (
        <>
          <Heading
            variant={HeadingVariants.RegularTitle}
            component="h2"
            color="var(--slate900)"
          >
            {isFinalStep ? "Validando tus datos..." : "Revisando tus fotos..."}
          </Heading>

          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Esto puede tardar unos segundos.
          </Text>
        </>
      )}
    </div>
  );
};

export default LoadingScreen;
