import { Currencies } from "interfaces/wallet";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import Text, { TextVariants } from "components/common/Text";
import PriceLabel from "components/common/PriceLabel";
import Heading, { HeadingVariants } from "components/common/Heading";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface DetailsBodyProps {
  amount: number;
  currency: Currencies;
}

const DetailsHeader: React.FC<DetailsBodyProps> = ({ amount, currency }) => {
  const isArsCurrency = currency === Currencies.ARS;

  return (
    <div className={styles.container}>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.title}
      >
        Confirmá tu envío
      </Text>
      <div className={styles.iconsContainer}>
        {isArsCurrency ? (
          <ARGFlag size={px2rem(32)} />
        ) : (
          <USFlag size={px2rem(32)} />
        )}
      </div>
      <Heading
        component={"h1"}
        variant={HeadingVariants.RegularTitle}
        className={styles.amount}
        color="var(--slate900)"
      >
        <PriceLabel price={amount} currency={currency} />
      </Heading>
    </div>
  );
};

export default DetailsHeader;
