import CoinsReceive from "assets/illustrations/CoinsReceive";
import Finance from "assets/illustrations/Finance";
import SimpleBank from "assets/illustrations/SimpleBank";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";

import AcademyCard from "./AcademyCard";

import styles from "./styles.module.scss";

const CocosAcademySection: React.FC = () => {
  return (
    <div className={styles.academySectionWrapper}>
      <div className={styles.textWrapper}>
        <Heading
          variant={HeadingVariants.RegularTitle}
          color="var(--slate900)"
          component="h2"
        >
          Te enseñamos a invertir
        </Heading>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          Porque creemos que invertir es para todos y que la educación es el
          medio para alcanzarlo.
        </Text>
      </div>
      <div className={styles.carrousel}>
        <AcademyCard
          illustration={SimpleBank}
          title="¿Qué es el mercado de capitales?"
          url="https://cocos.capital/que-es-el-mercado-de-capitales"
        />
        <AcademyCard
          illustration={CoinsReceive}
          title="¿Qué es una inversión?"
          url="https://cocos.capital/que-es-una-inversion"
        />
        <AcademyCard
          illustration={Finance}
          title="¿Qué son los activos financieros?"
          url="https://cocos.capital/que-son-los-activos-financieros"
        />
      </div>
      <div className={styles.secondRectangle} />
    </div>
  );
};

export default CocosAcademySection;
