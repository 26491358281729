import { ConfirmFCIOrderContainer } from "components/common/confirm-fci-order-container";
import { UIModalSlider } from "components/common/modal-slider";

import "./index.scss";

interface PropTypes {
  open: boolean;
  onToggleOpen: () => void;
  onClickConfirm?: () => void;
  fciAlmostTotal: boolean;
}

export const ConfirmFCIOrderModal = (props: PropTypes) => {
  const { open, onToggleOpen, fciAlmostTotal } = props;
  return (
    <UIModalSlider
      classes="confirm-fci-order-modal"
      open={open}
      onToggleDisplay={onToggleOpen}
    >
      <ConfirmFCIOrderContainer
        infoLabel={
          fciAlmostTotal
            ? "Dado el monto ingresado, se realizará un rescate por el total de cuotapartes."
            : undefined
        }
        onClose={onToggleOpen}
      />
    </UIModalSlider>
  );
};
