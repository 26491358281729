import { useEffect, useState } from "react";

import API from "apis";
import { userService } from "apis/services";
import BasicWrapper from "components/common/BasicWrapper";
import { Error } from "components/common/error";
import InvestorTestContainer from "components/common/investor-test-container";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import {
  InvestorProfileResponse,
  InvestorTestResponse,
} from "interfaces/api-responses";
import { ErrorMessage } from "interfaces/errors";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { DesktopOrTablet, Mobile } from "utils/responsive";

import "./index.scss";

interface TestAnswer {
  id_question: string;
  id_option: string;
}

const InvestorTestPage: React.FC = () => {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState<TestAnswer[]>();
  const [
    investorProfile,
    setInvestorProfile,
  ] = useState<InvestorProfileResponse>();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [testQuestions, setTestQuestions] = useState<InvestorTestResponse[]>();
  const [errorProfile, setErrorProfile] = useState<ErrorMessage>({
    message: "",
    status: null,
  });
  const { isMobile } = useDeviceCheck();
  const navigate = useNavigate();

  const isInitialOrFinalStep = step === 0 || step === 10;

  const getInvestorTestQuestions = async () => {
    setLoadingQuestions(true);
    const { data } = await API.get<InvestorTestResponse[]>(
      userService.investorTest
    );

    setTestQuestions(data);
    setLoadingQuestions(false);
  };

  const handleClickAnswer = async (
    id_question: string,
    id_option: string,
    isFinalAnswer: boolean
  ) => {
    if (!answers) {
      setAnswers([{ id_question: id_question, id_option: id_option }]);
      setStep(step + 1);

      return;
    }
    const isEdit = answers.findIndex((i) => i.id_question === id_question) > -1;

    if (isEdit) {
      const newAnswers = answers.map((a) =>
        a.id_question === id_question
          ? { id_question: id_question, id_option: id_option }
          : a
      );

      setAnswers(newAnswers);
    } else {
      setAnswers([
        ...answers,
        { id_question: id_question, id_option: id_option },
      ]);
    }

    if (!isFinalAnswer) {
      setStep(step + 1);
    }
  };

  const sendTestAnswers = async () => {
    try {
      setStep(step + 1);

      setLoadingProfile(true);

      const { data } = await API.post<InvestorProfileResponse>(
        userService.investorTest,
        answers
      );

      setInvestorProfile(data);
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        setErrorProfile({
          message: error?.toString(),
          status: error?.response ? error.response.status : "500",
        });
      }
    } finally {
      setLoadingProfile(false);
    }
  };

  useEffect(() => {
    if (answers && testQuestions) {
      const isTestComplete = answers.length === testQuestions.length;

      if (isTestComplete && step === testQuestions.length) {
        sendTestAnswers();
      }
    }
  }, [answers]);

  const handleClickStartTest = () => {
    getInvestorTestQuestions();
    setStep(step + 1);
  };

  const handleClickReStartTest = () => {
    setStep(0);
    setErrorProfile({ message: "", status: null });
  };

  const handleGoBack = () => {
    if (isInitialOrFinalStep) {
      navigate(-1);
    } else {
      setStep(step - 1);
    }
  };

  if (errorProfile.message && isMobile)
    return (
      <div className="investor-test-container error">
        <Error
          textPrimaryButton="Realizar test de nuevo"
          textSecondaryButton="Volver a Perfil"
          title="Ups...Algo salió mal"
          urlSecondaryButton={mainRoutes.profile}
          onClickPrimaryButton={handleClickReStartTest}
        />
      </div>
    );

  return (
    <div className={`pages-investor-test step-${step}`}>
      <BasicWrapper
        navigationHeaderProps={{
          onClick: handleGoBack,
          title: "Test perfil de inversor",
          iconColor: "var(--blue900)",
          withCloseIcon: isInitialOrFinalStep,
        }}
      >
        <Mobile>
          <InvestorTestContainer
            errorProfile={errorProfile}
            investorProfile={investorProfile}
            loadingProfile={loadingProfile}
            loadingQuestions={loadingQuestions}
            step={step}
            testQuestions={testQuestions}
            onClickAnswer={handleClickAnswer}
            onClickRestart={handleClickReStartTest}
            onClickStart={handleClickStartTest}
          />
        </Mobile>
        <DesktopOrTablet>
          <InvestorTestContainer
            errorProfile={errorProfile}
            investorProfile={investorProfile}
            loadingProfile={loadingProfile}
            loadingQuestions={loadingQuestions}
            step={step}
            testQuestions={testQuestions}
            onClickAnswer={handleClickAnswer}
            onClickRestart={handleClickReStartTest}
            onClickStart={handleClickStartTest}
          />
        </DesktopOrTablet>
      </BasicWrapper>
    </div>
  );
};

export default InvestorTestPage;
