import { ATENCION_CRYPTO_EMAIL } from "constants/emails";

import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import {
  CryptoWithdrawalErrors,
  getWithdrawalErrorMessage,
  getWithdrawalErrorTitle,
} from "components/page-crypto-send/AmountPage/utils";
import { MFAErrorType } from "interfaces/auth";
import { ThemeVariants } from "interfaces/theme";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface ErrorProps {
  error?: CryptoWithdrawalErrors | MFAErrorType;
}

const Error: React.FC<ErrorProps> = ({ error }) => {
  const navigate = useNavigate();

  const title = getWithdrawalErrorTitle(error);
  const subtitle = getWithdrawalErrorMessage(error);

  const isLimitError = Object.values(CryptoWithdrawalErrors).includes(
    error as CryptoWithdrawalErrors
  );

  return (
    <div className={styles.container}>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.title}
      >
        {title}
      </Text>
      <div className={styles.iconContainer}>
        <X size={px2rem(24)} color="var(--red900)" />
      </div>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate800)"
        className={styles.info}
      >
        {subtitle}{" "}
        {isLimitError && (
          <>
            <Text
              className={styles.info}
              color="var(--slate800)"
              variant={TextVariants.RegularText}
            >
              Para aumentarlo, contactate con
            </Text>
            <a
              className={styles.email}
              href={`mailto:${ATENCION_CRYPTO_EMAIL}`}
            >
              {ATENCION_CRYPTO_EMAIL}
            </a>
          </>
        )}
      </Text>
      <div className={styles.buttonsWrapper}>
        <FilledButton
          variant={ThemeVariants.Crypto}
          onClick={() => navigate(CryptoRoutes.PORTFOLIO)}
        >
          Ir a portfolio
        </FilledButton>
        <OutlinedButton
          variant={ThemeVariants.Crypto}
          onClick={() => navigate(CryptoRoutes.HOME)}
        >
          Volver al inicio
        </OutlinedButton>
      </div>
    </div>
  );
};

export default Error;
