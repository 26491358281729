import React from "react";

import { IconButton, Typography } from "@mui/material";
import { ArrowDownIcon } from "assets/icons/arrow-down-collapse";
import { SearchIcon } from "assets/icons/search-icon";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { trackAction } from "utils/amplitude";
import { useAppSelector } from "hooks/redux";
import { getCurrentScreen } from "features/global/globalSlice";
import mainRoutes from "router/routes";

import "./index.scss";

export enum ListsButtonSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
  Full = "full",
}
export enum ListsButtonVariants {
  Secondary = "secondary",
  Primary = "primary",
}

interface ListsButtonProps {
  onClick: () => void;
  onClickSearchIcon: () => void;
  label: string;
  withGoBack?: boolean;
}

const ListsButton: React.FC<ListsButtonProps> = ({
  label,
  onClick,
  onClickSearchIcon,
  withGoBack,
}) => {
  const navigate = useNavigate();
  const currentScreen = useAppSelector(getCurrentScreen);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation;
    onClick();
  };

  const handleClickSearchIcon = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClickSearchIcon();
    trackAction(`${currentScreen} - Open search`);
  };

  const handleGoBack = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(mainRoutes.market);
  };

  return (
    <div className="comp-lists-button">
      {withGoBack && (
        <IconButton
          aria-label="menu"
          className="icon-button-header"
          color="inherit"
          edge="start"
          onClick={handleGoBack}
        >
          <IoArrowBack />
        </IconButton>
      )}

      <div
        className="select-content select-market-introjs"
        onClick={handleClick}
      >
        <Typography variant="buttonS">{label.replaceAll("_", " ")}</Typography>
        <ArrowDownIcon color="#0062E1" />
      </div>
      <IconButton
        className="search-icon-button"
        onClick={handleClickSearchIcon}
      >
        <SearchIcon color="#0062E1" />
      </IconButton>
    </div>
  );
};

export default ListsButton;
