import { Typography, Dialog } from "@mui/material";
import { CloseIcon } from "assets/icons/close";
import { OrderStatusTypes } from "interfaces/api-responses";
import { OrderStatusLabel, OrderStatusDescription } from "interfaces/orders";

import { OrderStatusBadge } from "../OrdersList/components/OrderStatusBadge";

import styles from "./styles.module.scss";

const OrdersInfoModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: styles.infoModal }}>
      <Typography className={styles.modalTitle}>Estado de órdenes</Typography>
      <div className={styles.textContent}>
        <OrderStatusBadge status={OrderStatusTypes.PENDING} />
        <Typography>
          <span>{OrderStatusLabel.PENDING.toUpperCase()}</span> -{" "}
          {OrderStatusDescription.PENDING}
        </Typography>
      </div>
      <div className={styles.textContent}>
        <OrderStatusBadge status={OrderStatusTypes.MARKET} />
        <Typography>
          <span>{OrderStatusLabel.MARKET.toUpperCase()}</span> -{" "}
          {OrderStatusDescription.MARKET}
        </Typography>
      </div>
      <div className={styles.textContent}>
        <OrderStatusBadge status={OrderStatusTypes.EXECUTED} />
        <Typography>
          <span>{OrderStatusLabel.EXECUTED.toUpperCase()}</span> -{" "}
          {OrderStatusDescription.EXECUTED}
        </Typography>
      </div>
      <div className={styles.textContent}>
        <OrderStatusBadge status={OrderStatusTypes.PARTIALLY_EXECUTED} />
        <Typography>
          <span>{OrderStatusLabel.PARTIALLY_EXECUTED.toUpperCase()}</span> -{" "}
          {OrderStatusDescription.PARTIALLY_EXECUTED}
        </Typography>
      </div>
      <div className={styles.textContent}>
        <OrderStatusBadge status={OrderStatusTypes.PARTIALLY_CANCELLED} />
        <Typography>
          <span>{OrderStatusLabel.PARTIALLY_CANCELLED.toUpperCase()}</span> -{" "}
          {OrderStatusDescription.PARTIALLY_CANCELLED}
        </Typography>
      </div>
      <div className={styles.textContent}>
        <OrderStatusBadge status={OrderStatusTypes.CANCELLED} />
        <Typography>
          <span>{OrderStatusLabel.CANCELLED.toUpperCase()}</span> -{" "}
          {OrderStatusDescription.CANCELLED}
        </Typography>
      </div>
      <div className={styles.textContent}>
        <OrderStatusBadge status={OrderStatusTypes.REJECTED} />
        <Typography>
          <span>{OrderStatusLabel.REJECTED.toUpperCase()}</span> -{" "}
          {OrderStatusDescription.REJECTED}
        </Typography>
      </div>

      <div className={styles.closeIcon} onClick={onClose}>
        <CloseIcon size={18} />
      </div>
    </Dialog>
  );
};

export default OrdersInfoModal;
