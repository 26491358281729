import CryptoPortfolioPage from "components/page-cocos-portfolio/Crypto";
import { PortfolioProvider } from "context/PortfolioProvider";

const Portfolio: React.FC = () => (
  <PortfolioProvider>
    <CryptoPortfolioPage />
  </PortfolioProvider>
);

export default Portfolio;
