import { DetailedHTMLProps, SelectHTMLAttributes } from "react";

import styles from "./styles.module.scss";

export interface Option {
  value: string;
  label: string | JSX.Element | React.ReactNode;
}

interface SelectAccountProps
  extends DetailedHTMLProps<
    SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  options: Option[];
  onChangeAccount: (value: string) => void;
}

const SelectAccount: React.FC<SelectAccountProps> = ({ ...props }) => {
  const { value } = props;

  // Select account feature should be able only for Capital
  return (
    <div className={styles.selectContainer}>
      <label className={styles.label} htmlFor="select-account">
        N° de Cuenta {value}
      </label>
    </div>
  );
};

export default SelectAccount;
