import CocosCardIntro3 from "lotties/cocos-card-03.json";
import Lottie from "react-lottie";

const CocosCardIntroAnimation3: React.FC = () => (
  <div>
    <Lottie
      isPaused={false}
      options={{
        loop: false,
        autoplay: true,
        animationData: CocosCardIntro3,
        rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
      }}
      height={350}
      width={300}
      isClickToPauseDisabled={true}
    />
  </div>
);

export default CocosCardIntroAnimation3;
