import PayLegals from "components/page-cocos-pay/Legals";
import { ThemeVariants } from "interfaces/theme";

import Heading, { HeadingVariants } from "../Heading";
import LoadingSpinner from "../LoadingSpinner";
import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface LoaderPageProps {
  variant: ThemeVariants;
  title: string;
  subtitle?: string;
}

const LoaderPage: React.FC<LoaderPageProps> = ({
  variant = ThemeVariants.Capital,
  title,
  subtitle = "Por favor, aguardá un instante.",
}) => {
  return (
    <div className={styles.loaderPageContainer}>
      <div className={styles.wrapper}>
        <div className={styles.loader}>
          <LoadingSpinner size={133.3} variant={variant} />
          <Heading
            variant={HeadingVariants.RegularTitle}
            color="var(--slate900)"
            component="h2"
            className={styles.title}
          >
            {title}
          </Heading>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            {subtitle}
          </Text>
        </div>
        <PayLegals />
      </div>
    </div>
  );
};

export default LoaderPage;
