import { ATENCION_CRYPTO_EMAIL } from "constants/emails";

import { useState } from "react";

import { ThemeVariants } from "interfaces/theme";
import OutlinedButton from "components/common/OutlinedButton";
import PageHeader from "components/common/PageHeader";
import SegmentedControl from "components/common/SegmentedControl";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

import LimitTab from "./components/Tab";
import { LimitTabs } from "./utils";

import styles from "./styles.module.scss";

interface CryptoLimitsPageProps {
  goBack?: () => void;
}

const CryptoLimitsComponent: React.FC<CryptoLimitsPageProps> = ({ goBack }) => {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<LimitTabs>(
    LimitTabs.BLOCKCHAIN
  );

  const tabOptions = [
    { name: LimitTabs.BLOCKCHAIN },
    { name: LimitTabs.COCOSTAG },
  ];

  const increaseLimits = () => {
    window.location.href = `mailto:${ATENCION_CRYPTO_EMAIL}`;
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
      return;
    }
    navigate(-1);
  };

  return (
    <div className={styles.CryptoLimitsPageContainer}>
      <div>
        <PageHeader
          className={styles.header}
          icon={ArrowLeft}
          title={"Límites"}
          onClick={handleGoBack}
        />
        <SegmentedControl
          options={tabOptions}
          onClick={(option) => setSelectedTab(option)}
          selected={selectedTab}
        />
        <LimitTab tab={selectedTab} />
      </div>
      <OutlinedButton variant={ThemeVariants.Crypto} onClick={increaseLimits}>
        Quiero aumentar mis límites
      </OutlinedButton>
    </div>
  );
};

export default CryptoLimitsComponent;
