import { CardSections } from "interfaces/card/enums";
import { useCocosCard } from "context/CocosCardProvider";

import CardOnboarding from "./Onboarding";
import CardSelection from "./CardSelection";
import CardShipping from "./ShippingInfo";
import CardHome from "./Home";
import VirtualReady from "./VirtualReady";
import PhysicalIntro from "./PhysicalIntro";
import PhysicalReady from "./PhysicalReady";
import PhysicalActivation from "./PhysicalActivation";
import CardShippingPayment from "./ShippingPayment";

const CocosCardPage = () => {
  const { selectedSection } = useCocosCard();

  const sections = {
    [CardSections.ONBOARDING]: <CardOnboarding />,
    [CardSections.CARD_SELECTION]: <CardSelection />,
    [CardSections.VIRTUAL_READY]: <VirtualReady />,
    [CardSections.PHYSICAL_INTRO]: <PhysicalIntro />,
    [CardSections.SHIPPING_INFO]: <CardShipping />,
    [CardSections.SHIPPING_PAYMENT]: <CardShippingPayment />,
    [CardSections.PHYSICAL_READY]: <PhysicalReady />,
    [CardSections.PHYSICAL_ACTIVATION]: <PhysicalActivation />,
    [CardSections.HOME]: <CardHome />,
  };

  return <>{sections[selectedSection]}</>;
};

export default CocosCardPage;
