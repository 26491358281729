import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

const PhoneFormTitle: React.FC = () => {
  return (
    <div className={styles.PhoneFormTitleContainer}>
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
      >
        Ingresá tu número de teléfono móvil
      </Heading>
      <Text variant={TextVariants.RegularTextL} color="var(--slate800)">
        Necesitamos confirmar tu número enviando un mensaje de texto.
      </Text>
    </div>
  );
};

export default PhoneFormTitle;
