import { CDN_URL } from "constants/index";

import { useState } from "react";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

export interface BankAccountRowProps {
  name: string;
  accountId: string;
  logo: string;
  onClick?: () => void;
}

const BankAccountRow: React.FC<BankAccountRowProps> = ({
  name,
  accountId,
  logo,
  onClick,
}) => {
  const [bankLogoSrc, setBankLogoSrc] = useState(logo);

  const firstFive = accountId.substring(0, 5);
  const lastFive = accountId.substring(accountId.length - 5);
  const shortId = `${firstFive}...${lastFive}`;

  const handleImgError = (e: any) => {
    e.stopPropagation();
    setBankLogoSrc(`${CDN_URL}/logos/bancos/default.svg`);
  };

  return (
    <div className={styles.bankAccountRowContainer} onClick={onClick}>
      <div className={styles.bankName}>
        <img
          src={bankLogoSrc}
          width={20}
          height={20}
          loading="lazy"
          onError={handleImgError}
        />
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {name}
        </Text>
      </div>
      <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
        {shortId}
      </Text>
    </div>
  );
};

export default BankAccountRow;
