import { useState } from "react";

import { ampli } from "ampli";
import API from "apis";
import { debounce } from "lodash";
import { cardService } from "apis/services";
import { useNavigate } from "react-router-dom";
import { CreditCard, Pause, Play, Settings } from "lucide-react";
import mainRoutes from "router/routes";
import CircularButton, {
  CircularButtonVariants,
} from "components/common/CircularButton";
import { CardStatus, CardTypesLabel } from "interfaces/card/enums";
import { useCocosCard } from "context/CocosCardProvider";
import px2rem from "utils/px2rem";

import CardInfo from "../CardInfo";

import styles from "./styles.module.scss";

const MainActions: React.FC = () => {
  const {
    cardStatus,
    selectedCard,
    physicalCard,
    virtualCard,
    pomeloData,
    setPomeloData,
  } = useCocosCard();
  const navigate = useNavigate();

  const [openCardDetails, setOpenCardDetails] = useState<boolean>(false);

  const iconColor = "var(--white-to-black)";
  const iconSize = px2rem(20);

  const isPhysicalCard = selectedCard === CardTypesLabel.PHYSICAL;
  const idCard = isPhysicalCard ? physicalCard?.idCard : virtualCard?.idCard;
  const isCardActive = cardStatus === CardStatus.ACTIVE;

  const updateCardStatus = (status: CardStatus, idCard?: string) => {
    if (!pomeloData || !pomeloData.cards) return;

    const cardToUpdate = pomeloData.cards.find(
      (card) => card.idCard === idCard
    );

    if (cardToUpdate) {
      cardToUpdate.status = status;
      setPomeloData({
        ...pomeloData,
        cards: pomeloData.cards.map((card) =>
          card.idCard === idCard ? cardToUpdate : card
        ),
      });
    }
  };

  const pauseResumeCard = debounce(async () => {
    try {
      const newStatus = isCardActive ? CardStatus.BLOCKED : CardStatus.ACTIVE;
      const apiEndpoint = isCardActive ? cardService.pause : cardService.resume;
      const requestData = isCardActive
        ? { idCard, reason: "USER_INTERNAL_REASON" }
        : { idCard };

      ampli.cardPause({
        card_type: selectedCard,
        card_status: newStatus,
      });

      updateCardStatus(newStatus, idCard);

      const {
        data: { status },
      } = await API.post(apiEndpoint, requestData);

      updateCardStatus(status, idCard);
    } catch (error: any) {
      console.error(error);
    }
  }, 300);

  const getPauseResumeIcon = () => {
    if (isCardActive) {
      return <Pause size={iconSize} color={iconColor} />;
    }

    return (
      <Play
        size={iconSize}
        color={iconColor}
        style={{ marginLeft: px2rem(2) }}
      />
    );
  };

  const onClickCardInfo = () => {
    ampli.cardGetData({ card_type: selectedCard });
    setOpenCardDetails(true);
  };

  const onClickCardSettings = () => {
    ampli.cardSettings({ card_type: selectedCard });
    navigate(mainRoutes.cardSettings);
  };

  return (
    <>
      <div className={styles.mainActionsContainer}>
        <CircularButton
          variant={CircularButtonVariants.Pay}
          label="Ver datos"
          onClick={onClickCardInfo}
        >
          <CreditCard size={iconSize} color={iconColor} />
        </CircularButton>
        <CircularButton
          variant={CircularButtonVariants.Pay}
          label={isCardActive ? "Pausar" : "Reanudar"}
          onClick={() => pauseResumeCard()}
        >
          {getPauseResumeIcon()}
        </CircularButton>
        <CircularButton
          variant={CircularButtonVariants.Pay}
          label="Ajustes"
          onClick={onClickCardSettings}
        >
          <Settings size={iconSize} color={iconColor} />
        </CircularButton>
      </div>

      <CardInfo
        open={openCardDetails}
        onClose={() => setOpenCardDetails(false)}
      />
    </>
  );
};

export default MainActions;
