import { useNavigate } from "react-router-dom";
import NothingHere from "assets/icons/nothing-here";
import { Typography } from "@mui/material";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import mainRoutes from "router/routes";
import NoCtte from "components/common/NoCtte";

import styles from "./styles.module.scss";

const EmptyState = ({
  isUserOnboarded = true,
  hasMovements,
  userHasAccount = true,
  hasFilters = false,
  filtersRedirect,
}: {
  isUserOnboarded?: boolean;
  hasMovements: boolean;
  userHasAccount?: boolean;
  hasFilters?: boolean;
  filtersRedirect?: () => void;
}) => {
  const navigate = useNavigate();
  const handleRedirectWallet = () => {
    navigate(mainRoutes.receive);
  };

  if (!userHasAccount && !isUserOnboarded) {
    return (
      <div className={styles.container}>
        <NoCtte withImage />
      </div>
    );
  }

  if (isUserOnboarded && hasFilters && !hasMovements) {
    return (
      <div className={styles.container}>
        <NothingHere />
        <Typography variant="textL_bold" component="h3">
          Sin movimientos
        </Typography>
        <Typography variant="textM" component="p">
          No encontramos movimientos para los filtros aplicados.
        </Typography>
        {filtersRedirect && (
          <ButtonUI
            size={ButtonSizes.Large}
            variant={ButtonVariants.Primary}
            onClick={filtersRedirect}
          >
            Probar con otros filtros
          </ButtonUI>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <NothingHere />
      <Typography variant="textL_bold" component="h3">
        No hay movimientos
      </Typography>
      <Typography variant="textM" component="p">
        Ingresa dinero y comenza a operar
      </Typography>
      <ButtonUI
        size={ButtonSizes.Large}
        variant={ButtonVariants.Primary}
        onClick={handleRedirectWallet}
      >
        Ingresar fondos
      </ButtonUI>
    </div>
  );
};

export default EmptyState;
