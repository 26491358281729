import { InvestmentSuggestedResponse } from "interfaces/markets";
import ErrorContainer from "components/common/error-modal";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";
import LoadingSpinner from "components/common/LoadingSpinner";

import BondData from "./components/BondData";
import BondFlow from "./components/BondFlow";

import styles from "./styles.module.scss";

interface InvestmentSuggestionResumeProps {
  instrument: InvestmentSuggestedResponse;
  handleOnClose: () => void;
}

const InvestmentSuggestionResume: React.FC<InvestmentSuggestionResumeProps> = ({
  instrument,
  handleOnClose,
}) => {
  const calculator = useAppSelector((state: RootState) => state.calculator);
  const { analisis, loading, errorMessage } = calculator;

  if (loading)
    return (
      <div className={styles.loader}>
        <LoadingSpinner />
      </div>
    );

  if (errorMessage.message)
    return (
      <ErrorContainer
        subtitle="No podemos mostrar el flujo de fondos actualmente."
        textPrimaryButton="Volver al activo"
        title="Ups... Hubo un error"
        onClickPrimaryButton={handleOnClose}
      />
    );

  return (
    <div className={styles.resumePage}>
      <BondData instrument={instrument} analysis={analisis} />
      <BondFlow instrument={instrument} analysis={analisis} />
    </div>
  );
};

export default InvestmentSuggestionResume;
