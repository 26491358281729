interface PropTypes {
  size?: number;
  color?: string;
  thickness?: number;
}

export const ArrowDown = ({
  color = "#002C65",
  size = 24,
  thickness = 1.5,
}: PropTypes) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 12L12 16M12 16L16 12M12 16V8M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={thickness}
      />
    </svg>
  );
};
