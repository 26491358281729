import { Typography } from "@mui/material";
import { TickerDetails } from "interfaces/movements";
import { formatToCurrency } from "utils";

import styles from "./styles.module.scss";

interface CardDetailHiddenProps {
  tickerDetails: TickerDetails;
}

const CardDetailHidden: React.FC<CardDetailHiddenProps> = ({
  tickerDetails,
}) => {
  const {
    sell_quantity,
    total_quantity,
    avg_buy_exec_price,
    avg_buy_avail_price,
    avg_sell_price,
    last_price,
    historic_exec_revenue,
    historic_avail_revenue,
    close_price,
    prev_close_price,
  } = tickerDetails;

  const isPositiveValue = (value: number) => value >= 0;

  return (
    <div id={styles.bodyCard}>
      <Typography className={styles.subtitle}>Ganancia realizada</Typography>

      <div className={styles.result}>
        <Typography
          className={`${styles.resultGreen} ${
            isPositiveValue(historic_exec_revenue) ? styles.green : styles.red
          }`}
        >
          ${formatToCurrency(historic_exec_revenue, true)}
        </Typography>

        <Typography className={styles.symbol}>=</Typography>

        <div className={styles.textValue}>
          <Typography>{formatToCurrency(sell_quantity, true)}</Typography>
          <Typography>Total de ventas</Typography>
        </div>

        <Typography className={styles.symbol}>x</Typography>

        <div className={styles.textValue}>
          <Typography>( ${formatToCurrency(avg_sell_price)}</Typography>
          <Typography>Precio prom de venta</Typography>
        </div>

        <Typography className={styles.symbol}>-</Typography>

        <div className={styles.textValue}>
          <Typography>${formatToCurrency(avg_buy_exec_price)} )</Typography>
          <Typography>Precio prom compras realizadas</Typography>
        </div>
      </div>

      <Typography className={styles.subtitle}>Ganancia no realizada</Typography>

      <div className={styles.result}>
        <Typography
          className={`${styles.resultGreen} ${
            isPositiveValue(historic_avail_revenue) ? styles.green : styles.red
          }`}
        >
          ${formatToCurrency(historic_avail_revenue, true)}
        </Typography>

        <Typography className={styles.symbol}>=</Typography>

        <div className={styles.textValue}>
          <Typography>{formatToCurrency(total_quantity, true)}</Typography>
          <Typography>Tenencia actual</Typography>
        </div>

        <Typography className={styles.symbol}>x</Typography>

        <div className={styles.textValue}>
          <Typography>
            ( ${formatToCurrency(last_price || close_price || prev_close_price)}
          </Typography>
          <Typography>Precio actual</Typography>
        </div>

        <Typography className={styles.symbol}>-</Typography>

        <div className={styles.textValue}>
          <Typography>${formatToCurrency(avg_buy_avail_price)} )</Typography>
          <Typography>Precio prom compras no realizadas</Typography>
        </div>
      </div>
    </div>
  );
};

export default CardDetailHidden;
