import Text, { TextVariants } from "components/common/Text";
import { ChevronRight, LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface ProfileRowProps {
  icon: LucideIcon;
  title: string;
  subtitle: string;
}

const ProfileRow: React.FC<ProfileRowProps> = ({
  icon: Icon,
  title,
  subtitle,
}) => {
  return (
    <div className={styles.profileRowContainer}>
      <div className={styles.rowData}>
        <div className={styles.icon}>
          <Icon size={px2rem(16)} color="var(--background-1)" />
        </div>
        <div className={styles.text}>
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            {title}
          </Text>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            {subtitle}
          </Text>
        </div>
      </div>
      <ChevronRight size={px2rem(16)} color="var(--slate800)" />
    </div>
  );
};

export default ProfileRow;
