import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { getInvestorProfileData } from "features/user/userActions";
import { RootState } from "store/store";
import InvestorTestCheck from "components/common/investor-test-check";
import InvestorTestNoProfile from "components/common/investor-test-check/NoProfile";
import ProfileExpiration from "components/common/investor-test-check/ProfileExpiration";
import { useAppDispatch, useAppSelector } from "hooks/redux";

const ContainerProfileTest: React.FC = () => {
  const [dateInvestorTest, setDateInvestorTest] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const investorProfile = useAppSelector(
    (state: RootState) => state?.user?.investorProfile
  );
  const investorDataEndpoint = useAppSelector(
    (state: RootState) => state.user.investorData
  );
  const [investorData, setInvestorData] = useState<string>("");
  const [investorDate, setInvestorDate] = useState<string>("");

  const [openInvestorModal, setOpenInvestorModal] = useState<boolean>(
    investorProfile
  );

  const getInvestorData = async () => {
    try {
      if (investorDataEndpoint) {
        setInvestorData(investorDataEndpoint?.expiration_days);
        setInvestorDate(investorDataEndpoint?.expiration_date);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!investorDataEndpoint) {
      dispatch(getInvestorProfileData());
    }
  }, []);

  useEffect(() => {
    getInvestorData();
  }, [investorDataEndpoint]);

  useEffect(() => {
    const newDate = dayjs(investorDate, "DD/MM").format("DD/MM");
    const year = dayjs(investorDate, "DD/MM/YYYY").format("YYYY");

    setDateInvestorTest(`${newDate}/${+year - 1}`);
    setLoading(false);
  }, [investorDate]);

  return (
    <>
      {!loading &&
        +investorData <= 10 &&
        +investorData >= 0 &&
        investorDate && (
          <InvestorTestCheck
            dateInvestorTest={dateInvestorTest}
            investorData={investorData}
            openModal={openInvestorModal}
            setOpenModal={setOpenInvestorModal}
          />
        )}
      {!loading && investorData === null && (
        <InvestorTestNoProfile
          openModal={openInvestorModal}
          setOpenModal={setOpenInvestorModal}
        />
      )}
      {!loading && +investorData < 0 && investorDate && (
        <ProfileExpiration
          dateInvestorTest={dateInvestorTest}
          investorData={investorData}
          openModal={openInvestorModal}
          setOpenModal={setOpenInvestorModal}
        />
      )}
    </>
  );
};

export default ContainerProfileTest;
