import Text, { TextVariants } from "components/common/Text";
import { formatPercentage } from "utils";

interface AllocationPercentageProps {
  allocation: number;
}

const AllocationPercentage: React.FC<AllocationPercentageProps> = ({
  allocation,
}) => {
  return (
    <div>
      <Text color="var(--slate800)" variant={TextVariants.RegularTextS}>
        {formatPercentage(allocation, false)}
      </Text>
    </div>
  );
};

export default AllocationPercentage;
