import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { isEmpty } from "lodash";
import {
  AccountsSteps,
  useCapitalSendAccounts,
} from "context/CapitalSendAccountsProvider";
import { Currencies } from "interfaces/wallet";
import { AccountsResponse } from "interfaces/api-responses";
import { useNavigate, Navigate } from "react-router-dom";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import BasicWrapper from "components/common/BasicWrapper";
import SegmentedControl from "components/common/SegmentedControl";
import NoCtte from "components/common/NoCtte";
import BankAccountRow from "components/common/BankAccountRow";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import LoadingSpinner from "components/common/LoadingSpinner";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import AccountDetailsSheet from "./AccountDetailsSheet";
import AccountsEmptyState from "./AccountsEmptyState";

import styles from "./styles.module.scss";

enum AccountsTabs {
  ARS = "ARS",
  USD = "US$",
}

const Accounts: React.FC = () => {
  const navigate = useNavigate();

  const {
    isDesktop,
    setAccountSelected,
    setStep,
    accountSelected,
    currency,
    setCurrency,
    accounts,
    getAccounts,
    isLoading,
    getBankLogo,
  } = useCapitalSendAccounts();

  const [selectedTab, setSelectedTab] = useState<AccountsTabs>(
    currency === Currencies.ARS ? AccountsTabs.ARS : AccountsTabs.USD
  );

  const dispatch = useAppDispatch();
  const user = useAppSelector(getLoggedUser);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { hasAccount } = user;

  if (!hasAccount) {
    return (
      <div className={styles.emptyStateWrapper}>
        <NoCtte withImage />
      </div>
    );
  }

  const receiveOptions = [
    { name: AccountsTabs.ARS, icon: <ARGFlag size={16} /> },
    { name: AccountsTabs.USD, icon: <USFlag size={16} /> },
  ];

  const onClose = () => {
    if (isDesktop) {
      return dispatch(closeRightModal());
    }

    return navigate(-1);
  };

  const handleTabClick = (option: AccountsTabs) => {
    setCurrency(option === AccountsTabs.ARS ? Currencies.ARS : Currencies.USD);
    setSelectedTab(option);
  };

  const handleClickAccount = (account: AccountsResponse) => {
    setIsOpen(true);
    setAccountSelected(account);
  };

  const onClickAddNewAccount = () => {
    ampli.agendaAddNewAccount();
    setStep(AccountsSteps.NEW_ACCOUNT);
  };

  useEffect(() => {
    getAccounts();
  }, [selectedTab]);

  return (
    <>
      <BasicWrapper
        navigationHeaderProps={{
          title: "Agenda",
          onClick: onClose,
          withCloseIcon: isDesktop,
        }}
        primaryButtonProps={{
          children: "Agendar una cuenta",
          onClick: onClickAddNewAccount,
        }}
      >
        <div className={styles.tabControls}>
          <SegmentedControl
            options={receiveOptions}
            selected={selectedTab}
            onClick={(option) => handleTabClick(option)}
          />
        </div>
        {isLoading ? (
          <LoadingSpinner size={100} />
        ) : (
          <>
            {isEmpty(accounts) ? (
              <AccountsEmptyState />
            ) : (
              <div className={styles.accountsContainer}>
                <div className={styles.accountsList}>
                  {accounts.map((account) => {
                    const { cbu_cvu, entity, id_bank_account, logo } = account;
                    return (
                      <BankAccountRow
                        key={id_bank_account}
                        name={entity}
                        accountId={cbu_cvu}
                        logo={getBankLogo(logo)}
                        onClick={() => handleClickAccount(account)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </BasicWrapper>

      {accountSelected && (
        <AccountDetailsSheet
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          bankAccount={accountSelected}
          currency={currency}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
};

export default Accounts;
