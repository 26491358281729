import AssetSelectCard from "components/common/AssetSelectCard";
import Text, { TextVariants } from "components/common/Text";
import InputRadio from "components/common/input/InputRadio";

import styles from "./styles.module.scss";

interface Option {
  value: string;
  label: string;
}

interface SelectBottomSheetProps {
  title: string;
  value: string;
  options: Option[];
  setOpen: (value: boolean) => void;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
  name: string;
}

const SelectBottomSheet = ({
  title,
  value,
  options,
  setOpen,
  onChange,
  onBlur,
  name,
}: SelectBottomSheetProps) => {
  return (
    <div className={styles.selectBottomSheetContainer}>
      <Text
        color="var(--slate900)"
        className={styles.title}
        variant={TextVariants.RegularText}
      >
        {title}
      </Text>
      <div className={styles.optionsContainer}>
        {options.map((option: Option) => (
          <InputRadio
            key={option.value}
            value={option.value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            checked={option.value === value}
            displayRadio={false}
          >
            <AssetSelectCard
              ticker={option.label}
              checked={option.value === value}
              onClick={() => setOpen(false)}
            />
          </InputRadio>
        ))}
      </div>
    </div>
  );
};

export default SelectBottomSheet;
