import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { LEFT, RIGHT, useSwipeable } from "react-swipeable";
import { CardTypesLabel } from "interfaces/card/enums";
import { useCocosCard } from "context/CocosCardProvider";

import styles from "./styles.module.scss";

interface CardSliderProps {
  physicalCard: React.ReactNode;
  virtualCard: React.ReactNode;
}

const CardSlider: React.FC<CardSliderProps> = ({
  physicalCard,
  virtualCard,
}) => {
  const { selectedCard, setSelectedCard } = useCocosCard();

  const isPhysicalCard = selectedCard === CardTypesLabel.PHYSICAL;

  const [currentIndex, setCurrentIndex] = useState(isPhysicalCard ? 0 : 1);

  const handlers = useSwipeable({
    delta: 30,
    preventScrollOnSwipe: true,
    onSwiping: (eventData) => {
      const { dir, deltaX } = eventData;
      if (dir === RIGHT && deltaX > 50) {
        setCurrentIndex(0);
        setSelectedCard(CardTypesLabel.PHYSICAL);
      } else if (dir === LEFT && deltaX < -10) {
        setCurrentIndex(1);
        setSelectedCard(CardTypesLabel.VIRTUAL);
      }
    },
  });

  useEffect(() => {
    setCurrentIndex(isPhysicalCard ? 0 : 1);
  }, [isPhysicalCard]);

  return (
    <div {...handlers} className={styles.slider}>
      <div
        className={classNames(styles.sliderItem, {
          [styles.selected]: currentIndex === 0,
          [styles.backVirtual]: currentIndex !== 0,
        })}
      >
        {physicalCard}
      </div>

      <div
        className={classNames(styles.sliderItem, {
          [styles.selected]: currentIndex === 1,
          [styles.backPhysical]: currentIndex !== 1,
        })}
      >
        {virtualCard}
      </div>
    </div>
  );
};

export default CardSlider;
