import { ChangeEvent } from "react";

import InputField from "components/common/input/InputField";
import { COUNTRIES } from "components/page-sign-up/utils";

import CountryCodeSelect from "./CountryCodeSelect";

import styles from "./styles.module.scss";

interface PhoneFormInputsProps {
  phoneNumber: string;
  isPhoneValid: boolean;
  setPhoneNumber: (phone: string) => void;
  setCountryCode: (phone: number) => void;
}

const PhoneFormInputs: React.FC<PhoneFormInputsProps> = ({
  setCountryCode,
  setPhoneNumber,
  isPhoneValid,
  phoneNumber,
}) => {
  const handleOnChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <div className={styles.PhoneFormInputsContainer}>
      <CountryCodeSelect options={COUNTRIES} setCountryCode={setCountryCode} />
      <InputField
        label="Número de teléfono móvil"
        placeholder="Ingresa el número de teléfono móvil"
        name="phone"
        value={phoneNumber}
        onChange={handleOnChangePhone}
        hasError={!isPhoneValid}
        errorMessage="Ingrese un teléfono válido"
        type="number"
        pattern="\d*"
      />
    </div>
  );
};

export default PhoneFormInputs;
