import { ReactElement } from "react";

import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { formatToCurrency } from "utils";

import styles from "./styles.module.scss";

interface MovementsRowProps {
  executionDate: string;
  icon: ReactElement;
  label: string;
  name: string;
  onClick?: () => void;
  price: number;
  settlementDate: string;
}

const MovementsRow = ({
  executionDate,
  icon,
  label,
  name,
  onClick,
  price,
  settlementDate,
}: MovementsRowProps) => {
  const getClass = (value: number) => {
    if (value <= 0) return `${styles.negative}`;
    return `${styles.positive}`;
  };

  const formattedDate = (date: string) =>
    dayjs(date)
      .locale("es-mx")
      .format("DD MMM YYYY")
      .replace(/^(.{3})(.)/, (match, group1, group2) => {
        return group1 + group2.toUpperCase();
      });

  return (
    <div className={styles.cashRow} onClick={onClick}>
      <Typography className={styles.date}>
        {formattedDate(executionDate)}
      </Typography>
      <Typography className={styles.date}>
        {formattedDate(settlementDate)}
      </Typography>
      <div className={styles.nameCol}>
        {icon}
        <Typography className={styles.name}>QR</Typography>
      </div>
      <Typography className={styles.secondCol}>
        {label}
        {name && <span>{name}</span>}
      </Typography>
      <Typography className={`${styles.thirdCol} ${getClass(price ?? 0)} `}>
        {formatToCurrency(price, false, true)}
      </Typography>
    </div>
  );
};

export default MovementsRow;
