import { InvestmentSuggestedResponse } from "interfaces/markets";
import Text, { TextVariants } from "components/common/Text";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";

import styles from "./styles.module.scss";

interface DetailDataProps {
  instrument: InvestmentSuggestedResponse;
  onChangePage: () => void;
}

const DetailData: React.FC<DetailDataProps> = ({
  instrument,
  onChangePage,
}) => {
  const gotFlow = useAppSelector((state: RootState) => state.calculator.check);

  const cards = [
    {
      heading: "Rendimiento",
      value: `${instrument.tir}%`,
      title: "Rendimiento en dólares",
      text:
        "Rentabilidad que se obtendrá si se mantiene el instrumento hasta vencimiento",
    },
    {
      heading: "Fechas y plazos",
      value: instrument.expiration,
      title: "Vencimiento",
      text: "Fecha donde se pagará por completo la deuda.",
    },
    {
      heading: "Amortización",
      value: instrument.amort_title,
      title: "Tipo",
      text:
        "Forma en la que se pagara el capital (puede ser en cuotas o 100% a vencimiento).",
    },
  ];

  return (
    <div className={styles.detailData}>
      <div className={styles.dataCards}>
        {gotFlow && (
          <Text
            variant={TextVariants.SemiboldTextL}
            className={styles.checkFlow}
            onClick={onChangePage}
          >
            Ver flujo de fondos
          </Text>
        )}
        {cards.map(({ value, title, heading, text }) => (
          <div key={value}>
            <Text
              variant={TextVariants.RegularText}
              color="var(--slate800)"
              className={styles.cardHeadingDetail}
            >
              {heading}
            </Text>
            <div className={styles.cardDetail}>
              <div className={styles.cardTextDetail}>
                <Text
                  variant={TextVariants.RegularText}
                  color="var(--slate800)"
                >
                  {title}
                </Text>
                <Text
                  variant={TextVariants.SemiboldText}
                  color="var(--slate900)"
                >
                  {value}
                </Text>
              </div>
              <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
                {text}
              </Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailData;
