import { useState, useEffect } from "react";

import { isEmpty } from "lodash";
import { Typography } from "@mui/material";
import MonetaryTable from "components/page-movements/components/movements-common/movements-monetary/components/monetary-table";
import { useMovements } from "context/MovementsProvider";
import { CashMovements } from "interfaces/movements";
import { Currencies } from "interfaces/wallet";
import { StatusOnboarding } from "interfaces/createAccount";
import FilterButtonV2 from "components/common/filter-button-v2";
import { TableHeader } from "components/common/table-header";
import { trackAction, trackScreen } from "utils/amplitude";
import { getCurrentScreen } from "features/global/globalSlice";
import { screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import EmptyState from "components/page-movements/components/empty-state";
import { getLoggedUser } from "store/selectors/user.selector";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

interface MovementsMonetaryProps {
  cashMovements: CashMovements[];
  onClickFilter: (value: boolean) => void;
}

const MovementsMonetary: React.FC<MovementsMonetaryProps> = ({
  cashMovements,
  onClickFilter,
}) => {
  const dispatch = useAppDispatch();
  const {
    filters,
    setFilters,
    movementsLoading,
    getCashMovementsData,
  } = useMovements();
  const user = useAppSelector(getLoggedUser);
  const currentScreen = useAppSelector(getCurrentScreen);

  const isUserOnboarded = user?.statusOnboarding === StatusOnboarding.APPROVED;
  const hasMovements = !isEmpty(cashMovements);

  const displayEmptyState =
    !movementsLoading && (!user?.hasAccount || !hasMovements);

  const filtersCount = filters.type.length + (filters.date_from ? 1 : 0);

  const CurrencyTypes = [
    {
      label: "AR$",
      value: Currencies.ARS,
    },
    {
      label: "US$",
      value: Currencies.USD,
    },
    {
      label: "US$ Cable",
      value: Currencies.EXT,
    },
    {
      label: "US$ Especie",
      value: Currencies.EXT_ESP,
    },
  ];

  const [selectedCurrency, setSelectedCurrency] = useState<string>(
    Currencies.ARS
  );

  useEffect(() => {
    trackScreen(screenNames.monetaryMovements, currentScreen);
    dispatch(setCurrentScreen(screenNames.monetaryMovements));
  }, []);

  useEffect(() => {
    setSelectedCurrency(filters.currency);
  }, [filters.currency]);

  const handleClickCurrency = (value: Currencies) => {
    trackAction(`${currentScreen} - Click select currency`, {
      currency: value,
    });
    setSelectedCurrency(value);
    setFilters({ date_to: "", date_from: "", type: [], currency: value });
    getCashMovementsData(value, 1);
  };

  const handleOpenFilters = () => {
    onClickFilter(true);
  };

  return (
    <div className={styles.divContainer}>
      <div className={styles.currencyContainer}>
        <Typography className={styles.currencyLabel}>
          Elegí la moneda y consultá las operaciones realizadas
        </Typography>
        <div className={styles.currencyBottom}>
          <TableHeader
            options={CurrencyTypes}
            selected={selectedCurrency}
            onClickHeaderOption={handleClickCurrency}
          />
          <FilterButtonV2
            onClick={handleOpenFilters}
            filtersCount={filtersCount}
          />
        </div>
      </div>

      {displayEmptyState && (
        <EmptyState
          isUserOnboarded={isUserOnboarded}
          hasMovements={hasMovements}
          userHasAccount={user?.hasAccount}
          hasFilters={filtersCount > 0}
          filtersRedirect={handleOpenFilters}
        />
      )}

      {!displayEmptyState && cashMovements && (
        <MonetaryTable data={cashMovements} />
      )}
    </div>
  );
};

export default MovementsMonetary;
