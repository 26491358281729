import React, { ChangeEvent, useState } from "react";

import classNames from "classnames";
import { Eye, EyeOff } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
}

const PasswordInput: React.FC<InputFieldProps> = ({
  label,
  hasError,
  errorMessage,
  onChange,
  value,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const Icon = showPassword ? EyeOff : Eye;
  const inputFieldStyles = classNames(styles.input, {
    [styles.inputError]: hasError,
  });

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onChange && onChange(event);
  };

  return (
    <div className={styles.inputContainer}>
      <label htmlFor={props.id} className={styles.label}>
        {label}
      </label>
      <div className={styles.wrapper}>
        <input
          type={showPassword ? "text" : "password"}
          placeholder={props.placeholder}
          onChange={handleOnChange}
          value={inputValue}
          className={inputFieldStyles}
          id={props.id}
          name={props.name}
          {...props}
        />
        <Icon
          className={styles.button}
          color="var(--blue800)"
          size={px2rem(16)}
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      {hasError && (
        <div className={styles.inputErrorMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export default PasswordInput;
