import { Crypto, getCrypto } from "constants/cryptos";

import { IconProps } from "assets/icons/BTC";
import AssetSelectCard from "components/common/AssetSelectCard";
import Heading, { HeadingVariants } from "components/common/Heading";
import PageHeader from "components/common/PageHeader";
import { getCurrentScreen } from "features/global/globalSlice";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Currencies } from "interfaces/wallet";
import { X } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { CryptoRoutes, cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";

import styles from "./styles.module.scss";

export interface Fiat {
  name: string;
  icon: React.FC<IconProps>;
  ticker: Currencies;
  onClick: () => void;
  badge?: string;
}

interface AssetsPageProps {
  assets: Crypto[];
  fiat?: Fiat[];
  onClick: (ticker: Crypto) => void;
}

const AssetsPage: React.FC<AssetsPageProps> = ({ assets, fiat, onClick }) => {
  const location = useLocation();
  const { isMobile } = useDeviceCheck();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const previousScreen = useAppSelector(getCurrentScreen);

  const onClickExit = () => {
    if (isMobile) {
      navigate(-1);
      return;
    }
    dispatch(closeRightModal());
  };

  const handleOnClick = (value: string) => {
    const isSwap = location.pathname === CryptoRoutes.SWAP;
    const screenName = isSwap
      ? cryptoScreenNames.swap
      : cryptoScreenNames.receiveSelectAsset;
    const properties = {
      crypto_ticker: value,
      previous_screen: previousScreen,
    };

    trackAction(`${screenName} - Click Asset`, properties, Projects.CRYPTO);
    const crypto = getCrypto(value);
    onClick(crypto);
  };

  return (
    <div className={styles.assetsPageContainer}>
      <PageHeader
        title={"Seleccioná un activo"}
        onClick={onClickExit}
        icon={X}
      />
      <div className={styles.assets}>
        {fiat && (
          <>
            <Heading
              variant={HeadingVariants.RegularTitle}
              color="var(--slate800)"
              component="h2"
            >
              Moneda
            </Heading>
            {fiat.map(({ name, icon, ticker, onClick, badge }) => {
              return (
                <AssetSelectCard
                  key={ticker}
                  icon={icon}
                  name={name}
                  ticker={ticker}
                  onClick={onClick}
                  badge={badge}
                />
              );
            })}
            <Heading
              component="h2"
              color="var(--slate800)"
              className={styles.cryptoTitle}
              variant={HeadingVariants.RegularTitle}
            >
              Cripto
            </Heading>
          </>
        )}
        {assets.map(({ icon, ticker, name }) => (
          <AssetSelectCard
            icon={icon}
            name={name}
            ticker={ticker}
            key={ticker}
            onClick={handleOnClick}
          />
        ))}
      </div>
    </div>
  );
};

export default AssetsPage;
