import BreadCrumb from "components/common/BreadCrumb";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface ProfileSectionPreviewProps {
  title: string;
  detail: string;
  subtitleList: string[];
  onClickBreadcrumb: (v: string) => void;
  children: React.ReactNode;
}

const ProfileSectionPreview: React.FC<ProfileSectionPreviewProps> = ({
  title,
  detail,
  subtitleList,
  onClickBreadcrumb,
  children,
}) => {
  const showBreadCrumb = subtitleList.length > 0;

  return (
    <div className={styles.profileSectionPreviewContainer}>
      <div className={styles.header}>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h1"
          color="var(--slate800)"
        >
          {title}
        </Heading>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {detail}
        </Text>
      </div>
      <div className={styles.content}>
        {showBreadCrumb && (
          <BreadCrumb options={subtitleList} onClick={onClickBreadcrumb} />
        )}
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
};

export default ProfileSectionPreview;
