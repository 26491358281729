import { ReactNode } from "react";

import { InvestmentSuggestedResponse } from "interfaces/markets";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface InvestmentErrorsProps {
  children: string | ReactNode;
  instrument: InvestmentSuggestedResponse;
  handleOnClick: () => void;
}

const InvestmentErrors: React.FC<InvestmentErrorsProps> = ({ children }) => (
  <Text
    color="var(--red800)"
    className={styles.warningBanner}
    variant={TextVariants.RegularTextS}
  >
    {children}
  </Text>
);

export default InvestmentErrors;
