import { IconProps } from "assets/icons/BTC";

const CardOutOfStock: React.FC<IconProps> = () => (
  <svg
    width="136"
    height="96"
    viewBox="0 0 136 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="No Stock" clipPath="url(#clip0_3677_20821)">
      <path
        id="Vector"
        d="M126.504 19.418H134.941V27.8552"
        stroke="#DC3E42"
        strokeWidth="0.90626"
        strokeMiterlimit="10"
      />
      <path
        id="Vector_2"
        d="M134.941 69.6055V78.0427H126.504"
        stroke="#DC3E42"
        strokeWidth="0.90626"
        strokeMiterlimit="10"
      />
      <g id="Group 5">
        <path
          id="Vector_3"
          d="M91.611 -0.00195015L44.6911 -0.00195276C41.0379 -0.00195296 38.0764 2.96349 38.0764 6.62155L38.0764 89.2648C38.0763 92.9228 41.0379 95.8883 44.6911 95.8883L91.611 95.8883C95.2642 95.8883 98.2257 92.9228 98.2257 89.2648L98.2257 6.62155C98.2257 2.96349 95.2642 -0.00194994 91.611 -0.00195015Z"
          fill="#113264"
        />
        <g id="Group 3">
          <path
            id="Vector_4"
            d="M52.0539 84.6763C52.0509 83.6711 52.2712 82.6778 52.6989 81.7683C53.1266 80.8589 53.7509 80.056 54.5266 79.418C53.5342 78.6002 52.3302 78.0818 51.0548 77.923C49.7794 77.7642 48.4852 77.9716 47.3231 78.5211C46.1609 79.0705 45.1786 79.9394 44.4907 81.0264C43.8028 82.1134 43.4375 83.3738 43.4375 84.6607C43.4375 85.9476 43.8027 87.208 44.4907 88.295C45.1786 89.382 46.1609 90.2509 47.3231 90.8004C48.4852 91.3498 49.7794 91.5572 51.0548 91.3984C52.3302 91.2396 53.5342 90.7212 54.5266 89.9034C53.7549 89.2687 53.1331 88.4709 52.7056 87.5672C52.2781 86.6635 52.0555 85.6762 52.0539 84.6763Z"
            fill="#DC3E42"
          />
          <path
            id="Vector_5"
            d="M58.8341 77.8867C57.2606 77.8863 55.7366 78.4372 54.5262 79.444C55.2993 80.0809 55.9218 80.8814 56.3493 81.7878C56.7767 82.6943 56.9984 83.6843 56.9984 84.6867C56.9984 85.6891 56.7767 86.6791 56.3493 87.5856C55.9218 88.492 55.2993 89.2925 54.5262 89.9294C55.3659 90.6213 56.3593 91.1008 57.4228 91.3275C58.4863 91.5542 59.5887 91.5215 60.6369 91.2321C61.6851 90.9427 62.6485 90.4052 63.4457 89.6648C64.2429 88.9244 64.8506 88.0029 65.2176 86.978C65.5845 85.9531 65.6999 84.8548 65.5541 83.7759C65.4082 82.697 65.0053 81.669 64.3795 80.7786C63.7536 79.8883 62.9231 79.1617 61.958 78.6602C60.9929 78.1587 59.9214 77.897 58.8341 77.8971L58.8341 77.8867Z"
            fill="#FFC419"
          />
          <path
            id="Vector_6"
            d="M56.999 84.6763C57.0019 83.6711 56.7816 82.6778 56.3539 81.7683C55.9263 80.8589 55.302 80.056 54.5262 79.418C53.7532 80.0549 53.1307 80.8554 52.7032 81.7618C52.2758 82.6683 52.0541 83.6583 52.0541 84.6607C52.0541 85.6631 52.2758 86.6531 52.7032 87.5596C53.1307 88.466 53.7532 89.2665 54.5262 89.9034C55.2979 89.2687 55.9198 88.4709 56.3473 87.5672C56.7748 86.6635 56.9973 85.6763 56.999 84.6763Z"
            fill="#F7881A"
          />
        </g>
        <path
          id="Chip"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.8349 10.1465L72.1067 10.1465L72.1067 15.526L77.8349 15.526L77.8349 10.1465ZM71.604 10.1465L71.0159 10.1465C69.7906 10.1465 68.7972 11.1412 68.7972 12.3682L68.7972 17.9296L71.604 15.6573L71.604 10.1465ZM68.7972 18.5772L68.7972 24.1358C68.7972 25.3628 69.7906 26.3575 71.0159 26.3575L71.6045 26.3575C71.6045 26.3568 71.6045 26.3561 71.6045 26.3554L71.6045 20.8498L68.7972 18.5772ZM72.1072 26.3575L77.835 26.3575C77.835 26.3568 77.835 26.3561 77.835 26.3554L77.835 20.9807L72.1072 20.9807L72.1072 26.3554C72.1072 26.3561 72.1072 26.3568 72.1072 26.3575ZM78.3376 26.3575L78.9266 26.3575C80.152 26.3575 81.1454 25.3628 81.1454 24.1358L81.1454 18.5763L78.3377 20.8493L78.3377 26.3554C78.3377 26.3561 78.3376 26.3568 78.3376 26.3575ZM81.1454 17.9304L81.1454 12.3682C81.1454 11.1412 80.152 10.1465 78.9266 10.1465L78.3376 10.1465L78.3376 15.6574L81.1454 17.9304ZM69.1962 18.2534L71.9442 16.0287L77.9973 16.0287L80.7454 18.2534L77.9973 20.478L71.9442 20.478L69.1962 18.2534Z"
          fill="url(#paint0_linear_3677_20821)"
        />
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.0946 36.4746L63.3341 40.8349L61.1568 41.9717C57.1704 44.0273 55.2627 48.3097 56.906 51.5073C58.5493 54.7048 63.1215 55.6599 67.108 53.5836L69.2853 52.4624L71.5247 56.8123L69.3578 57.9335C62.9712 61.2505 55.4441 59.3454 52.5618 53.7393C49.6796 48.1332 52.5618 40.8868 58.9277 37.5906L61.0946 36.4746ZM74.9491 65.2241L72.7096 60.8638L74.8869 59.727C78.8734 57.6714 80.7811 53.389 79.1377 50.1914C77.4944 46.9938 72.9222 46.0387 68.9357 48.1151L66.7585 49.2363L64.519 44.8864L66.6859 43.7652C73.0725 40.4482 80.5996 42.3532 83.4819 47.9593C86.3642 53.5654 83.4819 60.8118 77.116 64.108L74.9491 65.2241Z"
          fill="#104D87"
        />
      </g>
      <path
        id="Vector_7"
        d="M129.435 25.8926H6.56488V71.572H129.435V25.8926Z"
        fill="#DC3E42"
      />
      <g id="Vector_8">
        <path
          d="M108.114 57.8848L114.539 40.4142H116.11L122.536 57.8848H120.894L119.3 53.4292H111.35L109.732 57.8848H108.114ZM111.842 52.0222H118.784L115.032 41.5867H115.594L111.842 52.0222Z"
          fill="white"
        />
        <path
          d="M93.6709 57.8848V40.4142H99.3225C101.042 40.4142 102.527 40.7738 103.778 41.4929C105.044 42.1964 106.021 43.2048 106.709 44.518C107.397 45.8156 107.741 47.3555 107.741 49.1378C107.741 50.9044 107.397 52.4443 106.709 53.7575C106.021 55.0707 105.044 56.0869 103.778 56.806C102.527 57.5252 101.042 57.8848 99.3225 57.8848H93.6709ZM95.1952 56.4777H99.299C100.69 56.4777 101.894 56.1729 102.91 55.5632C103.942 54.9535 104.739 54.1014 105.302 53.0071C105.881 51.8971 106.17 50.6073 106.17 49.1378C106.17 47.6526 105.881 46.3628 105.302 45.2684C104.739 44.1741 103.942 43.3299 102.91 42.7358C101.879 42.1261 100.675 41.8212 99.299 41.8212H95.1952V56.4777Z"
          fill="white"
        />
        <path
          d="M77.1281 57.8848L83.5535 40.4142H85.1247L91.5501 57.8848H89.9086L88.314 53.4292H80.3643L78.7462 57.8848H77.1281ZM80.8568 52.0222H87.7981L84.046 41.5867H84.6088L80.8568 52.0222Z"
          fill="white"
        />
        <path
          d="M71.6199 57.8848V41.8212H66.7422V40.4142H77.9984V41.8212H73.1441V57.8848H71.6199Z"
          fill="white"
        />
        <path
          d="M57.2076 58.1662C56.0038 58.1662 54.8782 57.9473 53.8307 57.5095C52.7833 57.0562 51.8609 56.4308 51.0636 55.6335C50.2819 54.8206 49.6644 53.8669 49.211 52.7726C48.7733 51.6626 48.5544 50.451 48.5544 49.1378C48.5544 47.8089 48.7733 46.5973 49.211 45.503C49.6644 44.4086 50.2897 43.4628 51.087 42.6655C51.8843 41.8525 52.7989 41.2272 53.8307 40.7894C54.8782 40.3517 56.0038 40.1328 57.2076 40.1328C58.3957 40.1328 59.5135 40.3517 60.561 40.7894C61.6084 41.2272 62.523 41.8525 63.3047 42.6655C64.102 43.4628 64.7273 44.4164 65.1807 45.5264C65.6341 46.6208 65.8608 47.8245 65.8608 49.1378C65.8608 50.451 65.6341 51.6626 65.1807 52.7726C64.7273 53.8669 64.102 54.8206 63.3047 55.6335C62.523 56.4308 61.6084 57.0562 60.561 57.5095C59.5135 57.9473 58.3957 58.1662 57.2076 58.1662ZM57.2076 56.7122C58.255 56.7122 59.2165 56.5168 60.092 56.126C60.9675 55.7351 61.7179 55.188 62.3432 54.4844C62.9842 53.7809 63.4767 52.9758 63.8206 52.0691C64.1645 51.1467 64.3365 50.1696 64.3365 49.1378C64.3365 48.1216 64.1645 47.1601 63.8206 46.2534C63.4767 45.331 62.9842 44.518 62.3432 43.8145C61.7179 43.111 60.9675 42.5638 60.092 42.173C59.2321 41.7665 58.2707 41.5633 57.2076 41.5633C56.1445 41.5633 55.1752 41.7665 54.2997 42.173C53.4399 42.5638 52.6895 43.111 52.0485 43.8145C51.4232 44.518 50.9385 45.331 50.5946 46.2534C50.2506 47.1601 50.0787 48.1216 50.0787 49.1378C50.0787 50.1696 50.2506 51.1467 50.5946 52.0691C50.9385 52.9758 51.4232 53.7809 52.0485 54.4844C52.6895 55.188 53.4477 55.7351 54.3232 56.126C55.1987 56.5168 56.1601 56.7122 57.2076 56.7122Z"
          fill="white"
        />
        <path
          d="M38.0222 58.1662C36.7872 58.1662 35.6537 57.9473 34.6219 57.5095C33.6057 57.0562 32.7225 56.4308 31.972 55.6335C31.2216 54.8206 30.6354 53.8669 30.2133 52.7726C29.8068 51.6782 29.6036 50.4744 29.6036 49.1612C29.6036 47.848 29.8068 46.6442 30.2133 45.5499C30.6354 44.4555 31.2216 43.5019 31.972 42.6889C32.7381 41.876 33.6292 41.2506 34.6454 40.8129C35.6772 40.3595 36.8028 40.1328 38.0222 40.1328C39.226 40.1328 40.2969 40.3439 41.2349 40.766C42.1886 41.1881 42.9859 41.7274 43.6269 42.384C44.2835 43.0407 44.7525 43.7207 45.0339 44.4242L43.6269 45.0808C43.1579 44.0021 42.4387 43.1501 41.4694 42.5248C40.5158 41.8838 39.3667 41.5633 38.0222 41.5633C36.6934 41.5633 35.5052 41.8838 34.4578 42.5248C33.4103 43.1657 32.5896 44.0568 31.9955 45.1981C31.417 46.3237 31.1278 47.6448 31.1278 49.1612C31.1278 50.662 31.417 51.9831 31.9955 53.1243C32.5896 54.2656 33.4025 55.1489 34.4343 55.7742C35.4818 56.3996 36.6778 56.7122 38.0222 56.7122C39.226 56.7122 40.3047 56.4465 41.2584 55.9149C42.2277 55.3677 42.9859 54.633 43.5331 53.7106C44.0959 52.7726 44.3773 51.7017 44.3773 50.4979V49.6771L45.0574 50.4041H38.0222V49.044H45.9016V50.3572C45.9016 51.4515 45.6983 52.4755 45.2919 53.4292C44.901 54.3672 44.3538 55.1958 43.6503 55.9149C42.9468 56.6184 42.1104 57.1734 41.1411 57.5799C40.1875 57.9707 39.1479 58.1662 38.0222 58.1662Z"
          fill="white"
        />
        <path
          d="M14.2866 57.8848L20.712 40.4142H22.2832L28.7086 57.8848H27.0671L25.4724 53.4292H17.5228L15.9047 57.8848H14.2866ZM18.0152 52.0222H24.9565L21.2045 41.5867H21.7673L18.0152 52.0222Z"
          fill="white"
        />
      </g>
      <path
        id="Vector_9"
        d="M1.0687 27.8552V19.418H9.50839"
        stroke="#DC3E42"
        strokeWidth="0.90626"
        strokeMiterlimit="10"
      />
      <path
        id="Vector_10"
        d="M9.50839 78.0427H1.0687V69.6055"
        stroke="#DC3E42"
        strokeWidth="0.90626"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3677_20821"
        x1="74.9713"
        y1="26.3575"
        x2="74.9713"
        y2="10.1465"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#D1D5E0" />
        <stop offset="0.4" stopColor="#DADEE9" />
        <stop offset="0.76" stopColor="#E6EAF4" />
      </linearGradient>
      <clipPath id="clip0_3677_20821">
        <rect
          width="135.084"
          height="96"
          fill="white"
          transform="translate(0.458008)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CardOutOfStock;
