import Text, { TextVariants } from "components/common/Text";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { useAppState } from "context/AppStateProvider";
import { usePortfolio } from "context/PortfolioProvider";
import { Divider } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface DetailedBalanceProps {
  isLoading: boolean;
  cashBalance?: number;
  holdingsBalance?: number;
}

const DetailedBalance: React.FC<DetailedBalanceProps> = ({
  isLoading,
  cashBalance,
  holdingsBalance,
}) => {
  const { isBalanceHidden } = useAppState();
  const { selectedCurrency } = usePortfolio();

  if (isLoading)
    return (
      <div className={styles.skeletonWrapper}>
        <div>
          <Skeleton height={px2rem(20)} />
          <Skeleton height={px2rem(10)} />
        </div>
        <Divider orientation="vertical" className={styles.divider} />
        <div>
          <Skeleton height={px2rem(20)} />
          <Skeleton height={px2rem(10)} />
        </div>
      </div>
    );

  return (
    <div className={styles.detailedBalanceWrapper}>
      <div>
        <PriceLabel
          currency={selectedCurrency}
          size={PriceLabelSizes.Medium}
          price={holdingsBalance ?? 0}
          isBalanceHidden={isBalanceHidden}
        />
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          Tenencia valorizada
        </Text>
      </div>
      <Divider orientation="vertical" className={styles.divider} />
      <div>
        <PriceLabel
          currency={selectedCurrency}
          size={PriceLabelSizes.Medium}
          price={cashBalance ?? 0}
          isBalanceHidden={isBalanceHidden}
        />
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          Total dinero
        </Text>
      </div>
    </div>
  );
};

export default DetailedBalance;
