import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

const EmailPasswordTitle: React.FC = () => {
  return (
    <div className={styles.EmailPasswordTitleContainer}>
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
      >
        Creá tu cuenta
      </Heading>
      <Text variant={TextVariants.RegularTextL} color="var(--slate800)">
        Asegurate de mantenerla segura.
      </Text>
    </div>
  );
};

export default EmailPasswordTitle;
