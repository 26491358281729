import BasicWrapper from "components/common/BasicWrapper";
import InputField from "components/common/input/InputField";
import { useAppSelector } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Navigate, useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { getUserFullName } from "utils";

import styles from "./styles.module.scss";

export const PersonalDataPage: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();
  const user = useAppSelector(getLoggedUser);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { first_name, middle_name, last_name, email } = user;

  const onClose = () => {
    navigate(-1);
  };

  const navigationHeaderProps = isMobile
    ? {
        title: "Datos personales",
        onClick: onClose,
        withCloseIcon: false,
      }
    : undefined;

  return (
    <BasicWrapper
      navigationHeaderProps={navigationHeaderProps}
      className={styles.personalData}
    >
      <div className={styles.inputsContainer}>
        <InputField
          label="Nombre"
          value={getUserFullName(first_name, middle_name, last_name)}
          disabled
        />
        <InputField label="Email" value={email} disabled />
      </div>
    </BasicWrapper>
  );
};
