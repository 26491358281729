import { UIModalSlider } from "components/common/modal-slider";
import { useNavigate } from "react-router-dom";
import { PaymentData } from "interfaces/pay/interface";

import ChaveConfirm from "./Confirm";
import ChaveError from "./Error";

interface CodeBottomSheetProps {
  open: boolean;
  error?: boolean;
  paymentData?: PaymentData;
  onClose: () => void;
}

const CodeBottomSheet = ({
  open,
  error,
  paymentData,
  onClose,
}: CodeBottomSheetProps) => (
  <UIModalSlider open={open} onToggleDisplay={onClose}>
    {paymentData && (
      <ChaveConfirm paymentData={paymentData} onClose={onClose} />
    )}
    {error && <ChaveError onClose={onClose} />}
  </UIModalSlider>
);

export default CodeBottomSheet;
