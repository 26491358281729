import BidPage from "components/page-bid";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

const Bid: React.FC = () => {
  const { isMobile } = useDeviceCheck();

  if (isMobile) return <BidPage />;

  return <Navigate replace to={mainRoutes.home} />;
};

export default Bid;
