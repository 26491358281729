import { useAppSelector } from "hooks/redux";
import { ButtonBase, Typography } from "@mui/material";
import { useMarket } from "context/MarketProvider";
import SectionsButtons from "components/common/SectionsButtons";
import { getLoggedUser } from "store/selectors/user.selector";
import { Sections } from "interfaces/markets";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

interface FavoritosProps {
  onChangeSubSection: (newOption: string) => void;
  setOpenNewListModal: (v: boolean) => void;
}

const Favoritos: React.FC<FavoritosProps> = ({
  onChangeSubSection,
  setOpenNewListModal,
}) => {
  const { selectedInstrumentSubtype, selectedInstrumentType } = useMarket();
  const user = useAppSelector(getLoggedUser);

  const handleClickAddOption = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenNewListModal(true);
  };

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { favoritesLists } = user;

  if (selectedInstrumentType !== Sections.FAVORITOS) return null;

  return (
    <div className="favorites-list">
      <SectionsButtons
        listsOptions={favoritesLists}
        newClass="scrollable"
        selected={selectedInstrumentSubtype || ""}
        onClickHeaderOption={onChangeSubSection}
      />
      <div className="add-option">
        <ButtonBase onClick={handleClickAddOption}>
          <Typography variant="textS_bold">+</Typography>
        </ButtonBase>
      </div>
    </div>
  );
};

export default Favoritos;
