import Heading, { HeadingVariants } from "components/common/Heading";
import { useFavoriteInstrumentsQuery } from "apis/market/queries/useFavoriteInstruments";
import AssetMarketCard from "components/common/AssetMarketCard";
import { useNavigate } from "react-router-dom";
import { InstrumentData } from "interfaces/api-responses";
import mainRoutes from "router/routes";
import { isEmpty } from "lodash";
import EmptyState, { EmptyButtonTypes } from "components/common/EmptyState";
import { Star } from "lucide-react";
import { ThemeVariants } from "interfaces/theme";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { clearShortTickerMarketData } from "features/markets";
import { ampli } from "ampli";

import styles from "./styles.module.scss";

const DailyTrendsSection: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const { data } = useFavoriteInstrumentsQuery({
    listId: user?.favoritesLists[0].id_list,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClickInstrument = (instrument: InstrumentData) => {
    ampli.homeExited({ referral: "favorite_asset" });
    dispatch(clearShortTickerMarketData());
    navigate(
      `${mainRoutes.market}/${instrument.instrument_type}/${instrument.instrument_code}`
    );
  };

  const onClickEmptyState = () => {
    ampli.homeExited({ referral: "favorites_empty" });
    navigate(mainRoutes.market);
  };

  return (
    <div className={styles.dailyTrendsWrapper}>
      <Heading
        component="h2"
        color="var(--slate800)"
        className={styles.header}
        variant={HeadingVariants.RegularSubtitle}
      >
        Favoritos{" "}
        {
          //TODO: Eventually update this to "Tendencias Diarias" when the rest of the functionality is done
        }
      </Heading>
      {isEmpty(data) ? (
        <EmptyState
          icon={Star}
          buttonText="Ir al mercado"
          onClick={onClickEmptyState}
          title="Creá tu lista de favoritos"
          buttonType={EmptyButtonTypes.LINK}
          buttonVariant={ThemeVariants.Capital}
          subtitle="Agregá instrumentos a tu lista haciendo tap en la estrella de tu pantalla."
        />
      ) : (
        <div className={styles.listWrapper}>
          {data!.map((instrument) => {
            return (
              <AssetMarketCard
                key={instrument.id_security}
                variation={instrument.variation || 0}
                image={instrument.logo_file_name || ""}
                onClick={() => onClickInstrument(instrument)}
                value={instrument.ask || instrument.close || 0}
                ticker={instrument.short_ticker || instrument.long_ticker}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DailyTrendsSection;
