import { useEffect, useState } from "react";

import Variation from "components/common/CurrentVariation";
import { BookSocketResponse } from "interfaces/api-responses/socket/book";
import { socket } from "socket";

interface DailyVariationProps {
  longTicker: string;
  variation?: number;
}

const DailyVariation: React.FC<DailyVariationProps> = ({
  longTicker,
  variation,
}) => {
  const [
    socketVariationData,
    setSocketVariationData,
  ] = useState<BookSocketResponse>();

  useEffect(() => {
    const messageHandler = (message: BookSocketResponse) => {
      if (
        message.long_ticker === longTicker &&
        message.variation !== socketVariationData?.variation
      ) {
        setSocketVariationData(message);
      }
    };

    socket.on("message", messageHandler);

    return () => {
      socket.off("message", messageHandler);
    };
  }, []);

  if (!socketVariationData?.variation && !variation) return <div>--</div>;

  return (
    <div>
      <Variation value={socketVariationData?.variation || variation} />
    </div>
  );
};

export default DailyVariation;
