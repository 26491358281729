import { InvestmentSuggestedResponse } from "interfaces/markets";

import BidtDetailData from "./components/detail-data/detail-data";
import BidDetailOperation from "./components/detail-operation/detail-operation";

import styles from "./styles.module.scss";

interface BidDetailProps {
  instrument: InvestmentSuggestedResponse;
  onChangePage: () => void;
  onOperationAble: (val: boolean) => void;
}
const BidDetail: React.FC<BidDetailProps> = ({
  instrument,
  onChangePage,
  onOperationAble,
}) => {
  return (
    <div className={styles.detailPage}>
      <BidtDetailData instrument={instrument} onChangePage={onChangePage} />
      <BidDetailOperation
        instrument={instrument}
        onOperationAble={onOperationAble}
      />
    </div>
  );
};

export default BidDetail;
