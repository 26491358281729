import { ChangeEvent, useEffect, useState } from "react";

import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ArrowDownIcon } from "assets/icons/arrow-down-collapse";
import { ArrowTopIcon } from "assets/icons/arrow-top";
import { useMovements } from "context/MovementsProvider";
import dayjs from "dayjs";
import { DatesFilter } from "interfaces/ui";
import { getColor } from "utils/getColor";
import { trackAction } from "utils/amplitude";
import { useAppSelector } from "hooks/redux";
import { getCurrentScreen } from "features/global/globalSlice";

import styles from "./styles.module.scss";

interface SelectDateProps {
  onClickPeriod: () => void;
}

const UISelectDate: React.FC<SelectDateProps> = ({ onClickPeriod }) => {
  const { dateFilters, setDateFilters, filters } = useMovements();
  const [selectedValue, setSelectedValue] = useState<string>(filters.date_from);
  const [expanded, setExpanded] = useState<boolean>(true);
  const currentScreen = useAppSelector(getCurrentScreen);

  const iconColor = getColor("--blue-to-lb");
  const someDateSelected = dateFilters.find((e: DatesFilter) => e.checked);
  const datesFormatted = `${dayjs(filters.date_from).format(
    "DD/MM/YYYY"
  )} - ${dayjs(filters.date_to).format("DD/MM/YYYY")}`;
  const anothetPeriodLabel =
    filters.date_to && !someDateSelected
      ? datesFormatted
      : "No elegiste ningún período";

  const notExpandedLabel = someDateSelected
    ? someDateSelected.label
    : filters.date_to
    ? datesFormatted
    : "Ningún filtro elegido";

  useEffect(() => {
    setSelectedValue(filters.date_from);
  }, [filters.date_from]);

  const handleClickPeriod = () => {
    const filters = dateFilters.map((r: DatesFilter) => {
      return { ...r, checked: false };
    });

    setDateFilters(filters);
    onClickPeriod();
  };

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const filters = dateFilters.map((r: DatesFilter) => {
      return { ...r, checked: r.from.toString() === e.currentTarget.value };
    });

    trackAction(`${currentScreen} - Click filter`, {
      type: "Date",
    });

    setDateFilters(filters);
  };

  return (
    <div id={styles.selectDate}>
      <div className={styles.header}>
        <Typography className={styles.title}>Fecha</Typography>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <ArrowTopIcon />
          ) : (
            <ArrowDownIcon color="#0062E1" size={16} type="thick" />
          )}
        </IconButton>
      </div>

      {expanded && (
        <>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChangeValue}
          >
            {dateFilters.map((option, index: number) => (
              <div key={index} className={styles.formControl}>
                <FormControlLabel
                  labelPlacement="start"
                  value={option.from}
                  control={
                    <Radio
                      checked={selectedValue === option.from.toString()}
                      sx={{
                        "&, &.Mui-checked": {
                          color: iconColor,
                        },
                      }}
                    />
                  }
                  label={<Typography>{option.label}</Typography>}
                />
              </div>
            ))}
          </RadioGroup>
          <div className={styles.anotherPeriod} onClick={handleClickPeriod}>
            <div>
              <Typography>Otro período</Typography>
              <Typography className={styles.grayText}>
                {anothetPeriodLabel}
              </Typography>
            </div>
            <IconButton size="small">{">"}</IconButton>
          </div>
        </>
      )}

      {!expanded && (
        <Typography className={styles.grayText}>{notExpandedLabel}</Typography>
      )}
    </div>
  );
};

export default UISelectDate;
