import { SettlementIndex } from "constants/portfolio";

import { useEffect, useState } from "react";

import BasicWrapper from "components/common/BasicWrapper";
import FilledButton from "components/common/FilledButton";
import Footer from "components/common/Footer";
import SegmentedControl from "components/common/SegmentedControl";
import TransactionInput from "components/common/TransactionInput";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Currencies, PortfolioFromType } from "interfaces/wallet";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import type { RootState } from "store/store";
import { getWallet } from "features/wallet/walletSlice";
import { OrderType } from "interfaces/markets";
import { updateOrderOptions } from "features/markets";
import { CocosFund, FundScreen } from "components/page-capital-funds/helpers";
import type { InstrumentData } from "interfaces/api-responses";
import GoogleTagManager from "react-gtm-module";
import Banner, { BannerVariants } from "components/common/Banner";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { FbEvents, trackFbEvent } from "utils/fbTracking";
import { ampli } from "ampli";
import useKeyboardHeight, { getExtraSpace } from "hooks/useKeyboardHeight";
import px2rem from "utils/px2rem";
import { usePortfolioQuery } from "apis/portfolio/queries/usePortfolioQuery";

import ConfirmBottomSheet from "./components/ConfirmBottomSheet";
import FundEarnings from "./components/FundEarnings";

import styles from "./styles.module.scss";

export enum FundStep {
  ACTIONS = "ACTIONS",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

interface AddWithdrawFundsProps {
  availableToSell: number;
  marketData: InstrumentData;
  onClose: () => void;
  onFundOrderConfirm: () => void;
  selectedFund: CocosFund;
  tab: FundScreen;
}

const AddWithdrawFunds = ({
  tab,
  onClose,
  selectedFund,
  availableToSell,
  marketData,
  onFundOrderConfirm,
}: AddWithdrawFundsProps) => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useDeviceCheck();
  const [selectedFundTab, setSelectedFundTab] = useState<FundScreen>(tab);
  const [isOpen, setIsOpen] = useState(false);

  const wallet = useAppSelector((state: RootState) => state.wallet.wallet);
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const { sellingPower } = useAppSelector((state: RootState) => state.wallet);

  const { data: porfolioData } = usePortfolioQuery(
    selectedFund.currency,
    PortfolioFromType.BROKER
  );
  const holdingQuantity = porfolioData?.holdings.find(
    (item) => item.ticker === selectedFund.ticker
  )?.settlements[SettlementIndex.INF].quantity;

  const buttonText = order.type === OrderType.Buy ? "Agregar" : "Retirar";

  const getAvailableBuy = () => {
    return (
      (wallet &&
        marketData &&
        Math.min(
          Number(
            wallet.CI[marketData?.currency === Currencies.ARS ? "ars" : "usd"]
          ),
          Number(
            wallet["24hs"][
              marketData?.currency === Currencies.ARS ? "ars" : "usd"
            ]
          )
        )) ??
      0
    );
  };

  const closeIcon = isDesktop ? !(availableToSell > 0) : false;

  const FOOTER_HEIGHT = 80;
  const keyboardHeight = useKeyboardHeight();
  const extraSpace = getExtraSpace(keyboardHeight, FOOTER_HEIGHT);

  const isWithdraw = selectedFundTab === FundScreen.WITHDRAW_FUNDS;
  const availableBuy = getAvailableBuy();
  const balanceInput = isWithdraw ? availableToSell : availableBuy;
  const fundsTabOptions = [
    { name: FundScreen.ADD_FUNDS },
    { name: FundScreen.WITHDRAW_FUNDS },
  ];

  const onPressEnter = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") goToNextStep();
  };

  const goToNextStep = () => {
    setIsOpen(true);
  };

  const handleChangeValue = (value: number) => {
    if (value !== undefined) {
      const priceFactor = marketData?.price_factor || 1000;
      const contractSize = marketData?.contract_size || 1;
      const price = marketData?.last || marketData?.close;
      const quantity = price
        ? (value * priceFactor) / (price * contractSize)
        : 0;

      const amountNearTotal =
        (order.amount &&
          order.type === OrderType.Sell &&
          sellingPower?.available.inf &&
          !order.totalRedemption &&
          !insufficientActivesToSell() &&
          quantity > Number(sellingPower?.available.inf) * 0.95) ||
        false;

      dispatch(
        updateOrderOptions({
          ...order,
          amount: value,
          quantity,
          long_ticker: marketData?.long_ticker,
          instrument_code: marketData?.instrument_code,
          totalRedemption: amountNearTotal,
        })
      );
    }
  };

  const handleTabClick = (opt: FundScreen) => {
    const isBuy = opt === FundScreen.ADD_FUNDS;
    const orderType = isBuy ? OrderType.Buy : OrderType.Sell;

    const baseOrderOptions = {
      ...order,
      price: marketData?.last,
      type: orderType,
    };

    if (isBuy) {
      delete baseOrderOptions.totalRedemption;
    } else {
      baseOrderOptions.totalRedemption = false;
    }

    dispatch(updateOrderOptions(baseOrderOptions));
    setSelectedFundTab(opt);
    return;
  };

  const insufficientActivesToSell = () => {
    if (order.type === OrderType.Sell && order?.amount) {
      return order.totalRedemption
        ? !(
            order.quantity &&
            holdingQuantity &&
            sellingPower?.available &&
            (marketData?.currency === Currencies.USD
              ? availableToSell >= order.amount
              : Number(holdingQuantity.toFixed(2)) >=
                Number(order.quantity.toFixed(2)))
          )
        : (Number(availableToSell.toFixed(2)) || 0) < order.amount;
    }

    return false;
  };

  const insufficientAmount = () => {
    if (
      !order.amount ||
      (order.type === OrderType.Buy &&
        order.amount < (marketData?.min_lot_size || 1000))
    ) {
      return true;
    }
  };

  const insufficientToBuy = () => {
    if (order.type === OrderType.Buy && order?.amount) {
      return (availableBuy || 0) < order?.amount;
    }

    return false;
  };

  const isButtonDisabled =
    insufficientAmount() || insufficientActivesToSell() || insufficientToBuy();
  const minToBuy = !isWithdraw ? insufficientAmount() : false;
  const currency =
    selectedFund.currency === Currencies.ARS ? "pesos" : "dólares";
  const bannerText = `Recibís tus ${currency} en el momento.`;
  const showEarnings = !isWithdraw;

  useEffect(() => {
    ampli.fciOrderScreenViewed({
      ticker: marketData?.short_ticker,
      real_time_returns: showEarnings,
    });
    GoogleTagManager.dataLayer({
      dataLayer: {
        event: "fci_order_screen_viewed",
        ticker: order.long_ticker,
      },
    });

    if (selectedFund.ticker === PaymentCurrencies.COCORMA) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq("track", "cocorma_fci_order_screen_viewed");

      trackFbEvent(FbEvents.COCORMA_ORDER_SCREEN_VIEWED);
    }

    if (!wallet) {
      dispatch(getWallet());
    }
    dispatch(
      updateOrderOptions({
        ...order,
        type: isWithdraw ? OrderType.Sell : OrderType.Buy,
        long_ticker: marketData?.long_ticker,
        instrument_short_name: marketData?.instrument_short_name,
        price: marketData?.last,
        instrument_code: marketData?.instrument_code,
      })
    );
  }, []);

  return (
    <BasicWrapper
      navigationHeaderProps={{
        title: selectedFund.name,
        badgeText: "24/7",
        onClick: onClose,
        withCloseIcon: closeIcon,
      }}
      className={styles.capitalFundsPage}
    >
      <div style={{ paddingBottom: px2rem(extraSpace) }}>
        <div className={styles.tabControls}>
          <SegmentedControl
            options={fundsTabOptions}
            selected={selectedFundTab}
            onClick={(option) => handleTabClick(option)}
          />
        </div>
        {isWithdraw && (
          <div className={styles.bannerWrapper}>
            <Banner text={bannerText} variant={BannerVariants.INFO_CAPITAL} />
          </div>
        )}
        <TransactionInput
          onChange={handleChangeValue}
          currency={selectedFund.currency}
          balance={Number(balanceInput.toFixed(2)) ?? 0}
          onKeyDown={onPressEnter}
          isDesktop={isDesktop}
          minToBuy={minToBuy}
          minLimit={marketData?.min_lot_size || 1000}
        />
        <FundEarnings
          amount={order.amount ?? 0}
          selectedFund={selectedFund}
          showEarnings={showEarnings}
        />
        <Footer>
          <FilledButton
            onClick={goToNextStep}
            disabled={isButtonDisabled ?? true}
          >
            {buttonText}
          </FilledButton>
        </Footer>
        <ConfirmBottomSheet
          amount={order.amount ?? 0}
          onConfirm={onFundOrderConfirm}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isWithdraw={isWithdraw}
          fund={selectedFund}
        />
      </div>
    </BasicWrapper>
  );
};

export default AddWithdrawFunds;
