import { useState } from "react";

import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import { Typography } from "@mui/material";
import { CloseIcon } from "assets/icons/close";
import { CSVLink } from "react-csv";
import { trackAction } from "utils/amplitude";

import DownloadIconButton from "./DownloadIconButton";

import styles from "./styles.module.scss";

const CsvDownloadLink = ({
  data,
  filename,
  screenName,
}: {
  data: (string | number | undefined)[][];
  filename: string;
  screenName: string;
}) => {
  const [filePath, setFilePath] = useState<string>("");
  const [displayDownloandBanner, setDisplayDownloadBanner] = useState<boolean>(
    false
  );

  const trackDownloadEvent = () => trackAction(`${screenName} - Download CSV`);

  const generateCsv = (data: (string | number | undefined)[][]) => {
    const convertRowToString = (row: (string | number | undefined)[]) =>
      row.map((field: string | number | undefined) => `"${field}"`).join(",");

    return data.map(convertRowToString).join("\n");
  };

  const openCsvFile = async () => {
    await FileOpener.open({
      filePath,
      openWithDefault: false,
      contentType: "text/csv",
    });
  };

  if (Capacitor.isNativePlatform()) {
    const downloadCsv = async () => {
      const file = await Filesystem.writeFile({
        path: filename,
        data: generateCsv(data),
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      setFilePath(file.uri);
      setDisplayDownloadBanner(true);
      trackDownloadEvent();
    };

    return (
      <>
        <DownloadIconButton onClick={downloadCsv} />

        {displayDownloandBanner && (
          <div className={styles.downloadMessage}>
            <div
              className={styles.closeIcon}
              onClick={() => setDisplayDownloadBanner(false)}
            >
              <CloseIcon size={18} />
            </div>
            <Typography component="p" variant="textS" color="white">
              Detalle descargado correctamente
            </Typography>
            <a onClick={openCsvFile}>
              <Typography variant="textM_bold" color="white">
                Abrir archivo
              </Typography>
            </a>
          </div>
        )}
      </>
    );
  }

  return (
    <CSVLink
      data={data}
      filename={filename}
      onClick={() => trackDownloadEvent()}
    >
      <DownloadIconButton />
    </CSVLink>
  );
};

export default CsvDownloadLink;
