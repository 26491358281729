import { Grid, Typography } from "@mui/material";
import { useDeviceCheck } from "hooks/useDeviceCheck";

import styles from "./styles.module.scss";

const InvestmentFlowHeader: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  return (
    <>
      <Grid className={styles.flowColumn} item xs={4}>
        <Typography align={"left"} className={styles.headerTitles}>
          Fecha
        </Typography>
      </Grid>
      <Grid className={styles.flowColumn} item xs={2}>
        <Typography align={"right"} className={styles.headerTitles}>
          Capital
        </Typography>
      </Grid>
      <Grid className={styles.flowColumn} item xs={3}>
        <Typography align={"right"} className={styles.headerTitles}>
          Intereses
        </Typography>
      </Grid>
      <Grid className={styles.flowColumn} item xs={3}>
        <Typography align={"right"} className={styles.headerTitles}>
          Flujo
        </Typography>
      </Grid>
      {!isMobile && (
        <Grid className={styles.flowColumn} item xs={4}>
          <Typography align={"right"} className={styles.headerTitles}>
            Acumulado
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default InvestmentFlowHeader;
