import { PeriodTime, PortfolioHoldings } from "interfaces/portfolio";
import Text, { TextVariants } from "components/common/Text";
import { formatPercentage } from "utils";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import AssetPortfolioRow from "components/common/AssetPortfolioRow";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import { useAppState } from "context/AppStateProvider";
import { usePerformanceQuery } from "apis/portfolio/queries/usePerformanceQuery";

import { GroupedByTypeHolding } from "..";

import styles from "./styles.module.scss";

interface InstrumentsGroupedRowProps {
  type: string;
  totalAmount: number;
  instrumentType: string;
  totalPercentage: number;
  currency: BalanceCurrencies;
  isSettlementExpanded: boolean;
  holdings: GroupedByTypeHolding;
  onClick: (instrument: PortfolioHoldings) => void;
}

const InstrumentsGroupedRow: React.FC<InstrumentsGroupedRowProps> = ({
  type,
  holdings,
  onClick,
  currency,
  totalAmount,
  instrumentType,
  totalPercentage,
  isSettlementExpanded,
}) => {
  const { isBalanceHidden } = useAppState();

  const { data: dailyPerformanceData } = usePerformanceQuery(
    PeriodTime.DAY,
    !true
  );

  const { data: historicPerformanceData } = usePerformanceQuery(
    PeriodTime.MAX,
    !true
  );

  const totalResult = historicPerformanceData?.tickers.reduce((acc, ticker) => {
    if (ticker.instrument_type === instrumentType) {
      return acc + ticker.result;
    }
    return acc;
  }, 0);

  const totalDailyPerformance = dailyPerformanceData?.tickers.reduce(
    (acc, ticker) => {
      if (ticker.instrument_type === instrumentType) {
        return acc + ticker.result;
      }
      return acc;
    },
    0
  );

  const getTextColor = (amount: number) => {
    if (amount >= 0) return "var(--green800)";

    return "var(--red800)";
  };

  const shouldShowdailyPerformance =
    instrumentType && totalDailyPerformance !== undefined;

  const shouldShowTotalResult = instrumentType && totalResult !== undefined;

  return (
    <>
      <div className={styles.instrumentsGroupedRow}>
        <Text
          className={styles.typeRow}
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
        >
          {type}
        </Text>
        <div className={styles.priceRow}>
          <PriceLabel
            price={totalAmount}
            currency={currency}
            size={PriceLabelSizes.Xsmall}
            isBalanceHidden={isBalanceHidden}
          />
        </div>
        <Text
          className={styles.percentageRow}
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
        >
          {formatPercentage(totalPercentage, false)}
        </Text>
        {shouldShowdailyPerformance && (
          <div className={styles.totalPerformanceRow}>
            <PriceLabel
              price={totalDailyPerformance}
              currency={Currencies.ARS}
              size={PriceLabelSizes.Xsmall}
              isBalanceHidden={isBalanceHidden}
              color={getTextColor(totalDailyPerformance)}
              symbolColor={getTextColor(totalDailyPerformance)}
            />
          </div>
        )}

        {shouldShowTotalResult && (
          <div className={styles.totalResultRow}>
            <PriceLabel
              price={totalResult}
              currency={Currencies.ARS}
              size={PriceLabelSizes.Xsmall}
              isBalanceHidden={isBalanceHidden}
              color={getTextColor(totalResult)}
              symbolColor={getTextColor(totalResult)}
            />
          </div>
        )}
      </div>
      {holdings.map((holding) => (
        <div key={holding.ticker}>
          <AssetPortfolioRow
            onClick={() => onClick(holding)}
            currency={currency}
            holding={holding}
            isSettlementExpanded={isSettlementExpanded}
            totalPerformance={holding.totalPerformance}
            totalVariation={holding.totalVariation}
            dailyPerformance={holding.dailyPerformance}
            dailyVariation={holding.dailyVariation}
            averagePrice={holding.averagePrice}
          />
        </div>
      ))}
    </>
  );
};

export default InstrumentsGroupedRow;
