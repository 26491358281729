import { useEffect } from "react";

import { ampli } from "ampli";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { ThemeVariants } from "interfaces/theme";
import { useCocosCard } from "context/CocosCardProvider";
import SectionTitle from "components/common/SectionTitle";
import LoadingSpinner from "components/common/LoadingSpinner";

import CardTransactionsList from "../../../page-cocos-card-transactions/TransactionsGroup";

const MAX_HOME_TRANSACTIONS = 3;

const TransactionsHome: React.FC = () => {
  const { getCardTransactions, selectedCard, isLoading } = useCocosCard();
  const navigate = useNavigate();

  const goToMovements = () => {
    ampli.cardMovements({ card_type: selectedCard });
    navigate(mainRoutes.cardTransactions);
  };

  useEffect(() => {
    getCardTransactions(MAX_HOME_TRANSACTIONS);
  }, [selectedCard]);

  return (
    <>
      <SectionTitle label="Movimientos" onClick={goToMovements} />
      {isLoading ? (
        <LoadingSpinner size={120} variant={ThemeVariants.Pay} />
      ) : (
        <CardTransactionsList isHome />
      )}
    </>
  );
};

export default TransactionsHome;
