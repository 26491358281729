export const isPhoneValid = (value: string) => {
  if (value.length < 4) return true;
  return phoneRegex.test(value);
};

export const phoneRegex = /^\+?(54)9?(?:(?:0)?(3894|3892|3891|3888|3887|3886|3885|3878|3877|3876|3873|3869|3868|3867|3865|3863|3862|3861|3858|3857|3856|3855|3854|3846|3845|3844|3843|3841|3838|3837|3835|3832|3827|3826|3825|3821|3786|3782|3781|3777|3775|3774|3773|3772|3758|3757|3756|3755|3754|3751|3743|3741|3735|3734|3731|3725|3721|3718|3716|3715|3711|3585|3584|3583|3582|3576|3575|3574|3573|3572|3571|3564|3563|3562|3549|3548|3547|3546|3544|3543|3542|3541|3537|3533|3532|3525|3524|3522|3521|3498|3497|3496|3493|3492|3491|3489|3487|3483|3482|3476|3472|3471|3469|3468|3467|3466|3465|3464|3463|3462|3460|3458|3456|3455|3454|3447|3446|3445|3444|3442|3438|3437|3436|3435|3409|3408|3407|3406|3405|3404|3402|3401|3400|3388|3387|3385|3382|3329|3327|2983|2982|2972|2966|2964|2963|2962|2954|2953|2952|2948|2946|2945|2942|2940|2936|2935|2934|2933|2932|2931|2929|2928|2927|2926|2925|2924|2923|2922|2921|2920|2903|2902|2901|2658|2657|2656|2655|2652|2651|2648|2647|2646|2626|2625|2624|2622|2478|2477|2475|2474|2473|2396|2395|2394|2393|2392|2358|2357|2356|2355|2354|2353|2352|2346|2345|2344|2343|2342|2338|2337|2336|2335|2334|2333|2331|2326|2325|2324|2323|2320|2317|2316|2314|2302|2297|2296|2292|2291|2286|2285|2284|2283|2281|2274|2273|2272|2271|2268|2267|2266|2265|2264|2262|2261|2257|2255|2254|2252|2246|2245|2244|2243|2242|2241|2229|2227|2226|2225|2224|2223|2221|2202)([\d]{6})|(?:(?:0)?(388|387|385|383|381|380|379|376|370|364|362|358|353|351|348|345|343|342|341|336|299|298|297|294|291|280|266|264|263|261|260|249|237|236|230|223|221|220)([\d]{7}))|(?:(?:0)?(11)([\d]{8})))$/;

import { size } from "lodash";
import validator from "validator";

export const isValidEmail = (value: string) => {
  return validator.isEmail(value) || size(value) < 3;
};

export const isValidPassword = (password: string) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  return regex.test(password);
};

export enum SignUpStep {
  EMAIL = "email",
  EMAIL_VALIDATION = "emailValidation",
  PHONE = "phone",
  PHONE_VALIDATION = "phoneValidation",
}

export interface CountryCodeOptions {
  name: string;
  countryCode: string;
  phoneCode: number;
}

export const COUNTRIES: CountryCodeOptions[] = [
  {
    name: "Argentina",
    countryCode: "ar",
    phoneCode: 54,
  },
  {
    name: "Afghanistan",
    countryCode: "af",
    phoneCode: 93,
  },
  {
    name: "Albania",
    countryCode: "al",
    phoneCode: 355,
  },
  {
    name: "Algeria",
    countryCode: "dz",
    phoneCode: 213,
  },
  {
    name: "Andorra",
    countryCode: "ad",
    phoneCode: 376,
  },
  {
    name: "Angola",
    countryCode: "ao",
    phoneCode: 244,
  },
  {
    name: "Armenia",
    countryCode: "am",
    phoneCode: 374,
  },
  {
    name: "Australia",
    countryCode: "au",
    phoneCode: 61,
  },
  {
    name: "Austria",
    countryCode: "at",
    phoneCode: 43,
  },
  {
    name: "Azerbaijan",
    countryCode: "az",
    phoneCode: 994,
  },
  {
    name: "Bahamas",
    countryCode: "bs",
    phoneCode: 1242,
  },
  {
    name: "Bahrain",
    countryCode: "bh",
    phoneCode: 973,
  },
  {
    name: "Bangladesh",
    countryCode: "bd",
    phoneCode: 880,
  },
  {
    name: "Barbados",
    countryCode: "bb",
    phoneCode: 1246,
  },
  {
    name: "Belarus",
    countryCode: "by",
    phoneCode: 375,
  },
  {
    name: "Belgium",
    countryCode: "be",
    phoneCode: 32,
  },
  {
    name: "Belize",
    countryCode: "bz",
    phoneCode: 501,
  },
  {
    name: "Benin",
    countryCode: "bj",
    phoneCode: 229,
  },
  {
    name: "Bhutan",
    countryCode: "bt",
    phoneCode: 975,
  },
  {
    name: "Bolivia",
    countryCode: "bo",
    phoneCode: 591,
  },
  {
    name: "Bosnia and Herzegovina",
    countryCode: "ba",
    phoneCode: 387,
  },
  {
    name: "Botswana",
    countryCode: "bw",
    phoneCode: 267,
  },
  {
    name: "Brazil",
    countryCode: "br",
    phoneCode: 55,
  },
  {
    name: "Brunei Darussalam",
    countryCode: "bn",
    phoneCode: 673,
  },
  {
    name: "Bulgaria",
    countryCode: "bg",
    phoneCode: 359,
  },
  {
    name: "Burkina Faso",
    countryCode: "bf",
    phoneCode: 226,
  },
  {
    name: "Burundi",
    countryCode: "bi",
    phoneCode: 257,
  },
  {
    name: "Cabo Verde",
    countryCode: "cv",
    phoneCode: 238,
  },
  {
    name: "Cambodia",
    countryCode: "kh",
    phoneCode: 855,
  },
  {
    name: "Cameroon",
    countryCode: "cm",
    phoneCode: 237,
  },
  {
    name: "Canada",
    countryCode: "ca",
    phoneCode: 1,
  },
  {
    name: "Central African Republic",
    countryCode: "cf",
    phoneCode: 236,
  },
  {
    name: "Chad",
    countryCode: "td",
    phoneCode: 235,
  },
  {
    name: "Chile",
    countryCode: "cl",
    phoneCode: 56,
  },
  {
    name: "China",
    countryCode: "cn",
    phoneCode: 86,
  },
  {
    name: "Colombia",
    countryCode: "co",
    phoneCode: 57,
  },
  {
    name: "Comoros",
    countryCode: "km",
    phoneCode: 269,
  },
  {
    name: "Congo",
    countryCode: "cg",
    phoneCode: 242,
  },
  {
    name: "Costa Rica",
    countryCode: "cr",
    phoneCode: 506,
  },
  {
    name: "Croatia",
    countryCode: "hr",
    phoneCode: 385,
  },
  {
    name: "Cuba",
    countryCode: "cu",
    phoneCode: 53,
  },
  {
    name: "Cyprus",
    countryCode: "cy",
    phoneCode: 357,
  },
  {
    name: "Czech Republic",
    countryCode: "cz",
    phoneCode: 420,
  },
  {
    name: "Democratic Republic of the Congo",
    countryCode: "cd",
    phoneCode: 243,
  },
  {
    name: "Denmark",
    countryCode: "dk",
    phoneCode: 45,
  },
  {
    name: "Djibouti",
    countryCode: "dj",
    phoneCode: 253,
  },
  {
    name: "Dominica",
    countryCode: "dm",
    phoneCode: 1767,
  },
  {
    name: "Dominican Republic",
    countryCode: "do",
    phoneCode: 1,
  },
  {
    name: "Ecuador",
    countryCode: "ec",
    phoneCode: 593,
  },
  {
    name: "Egypt",
    countryCode: "eg",
    phoneCode: 20,
  },
  {
    name: "El Salvador",
    countryCode: "sv",
    phoneCode: 503,
  },
  {
    name: "Equatorial Guinea",
    countryCode: "gq",
    phoneCode: 240,
  },
  {
    name: "Eritrea",
    countryCode: "er",
    phoneCode: 291,
  },
  {
    name: "Estonia",
    countryCode: "ee",
    phoneCode: 372,
  },
  {
    name: "Eswatini",
    countryCode: "sz",
    phoneCode: 268,
  },
  {
    name: "Ethiopia",
    countryCode: "et",
    phoneCode: 251,
  },
  {
    name: "Fiji",
    countryCode: "fj",
    phoneCode: 679,
  },
  {
    name: "Finland",
    countryCode: "fi",
    phoneCode: 358,
  },
  {
    name: "France",
    countryCode: "fr",
    phoneCode: 33,
  },
  {
    name: "Gabon",
    countryCode: "ga",
    phoneCode: 241,
  },
  {
    name: "Gambia",
    countryCode: "gm",
    phoneCode: 220,
  },
  {
    name: "Georgia",
    countryCode: "ge",
    phoneCode: 995,
  },
  {
    name: "Germany",
    countryCode: "de",
    phoneCode: 49,
  },
  {
    name: "Ghana",
    countryCode: "gh",
    phoneCode: 233,
  },
  {
    name: "Greece",
    countryCode: "gr",
    phoneCode: 30,
  },
  {
    name: "Grenada",
    countryCode: "gd",
    phoneCode: 1473,
  },
  {
    name: "Guatemala",
    countryCode: "gt",
    phoneCode: 502,
  },
  {
    name: "Guinea",
    countryCode: "gn",
    phoneCode: 224,
  },
  {
    name: "Guinea-Bissau",
    countryCode: "gw",
    phoneCode: 245,
  },
  {
    name: "Guyana",
    countryCode: "gy",
    phoneCode: 592,
  },
  {
    name: "Haiti",
    countryCode: "ht",
    phoneCode: 509,
  },
  {
    name: "Honduras",
    countryCode: "hn",
    phoneCode: 504,
  },
  {
    name: "Hungary",
    countryCode: "hu",
    phoneCode: 36,
  },
  {
    name: "Iceland",
    countryCode: "is",
    phoneCode: 354,
  },
  {
    name: "India",
    countryCode: "in",
    phoneCode: 91,
  },
  {
    name: "Indonesia",
    countryCode: "id",
    phoneCode: 62,
  },
  {
    name: "Iran",
    countryCode: "ir",
    phoneCode: 98,
  },
  {
    name: "Iraq",
    countryCode: "iq",
    phoneCode: 964,
  },
  {
    name: "Ireland",
    countryCode: "ie",
    phoneCode: 353,
  },
  {
    name: "Israel",
    countryCode: "il",
    phoneCode: 972,
  },
  {
    name: "Italy",
    countryCode: "it",
    phoneCode: 39,
  },
  {
    name: "Jamaica",
    countryCode: "jm",
    phoneCode: 1876,
  },
  {
    name: "Japan",
    countryCode: "jp",
    phoneCode: 81,
  },
  {
    name: "Jordan",
    countryCode: "jo",
    phoneCode: 962,
  },
  {
    name: "Kazakhstan",
    countryCode: "kz",
    phoneCode: 7,
  },
  {
    name: "Kenya",
    countryCode: "ke",
    phoneCode: 254,
  },
  {
    name: "Kiribati",
    countryCode: "ki",
    phoneCode: 686,
  },
  {
    name: "Kuwait",
    countryCode: "kw",
    phoneCode: 965,
  },
  {
    name: "Kyrgyzstan",
    countryCode: "kg",
    phoneCode: 996,
  },
  {
    name: "Lao People's Democratic Republic",
    countryCode: "la",
    phoneCode: 856,
  },
  {
    name: "Latvia",
    countryCode: "lv",
    phoneCode: 371,
  },
  {
    name: "Lebanon",
    countryCode: "lb",
    phoneCode: 961,
  },
  {
    name: "Lesotho",
    countryCode: "ls",
    phoneCode: 266,
  },
  {
    name: "Liberia",
    countryCode: "lr",
    phoneCode: 231,
  },
  {
    name: "Libya",
    countryCode: "ly",
    phoneCode: 218,
  },
  {
    name: "Liechtenstein",
    countryCode: "li",
    phoneCode: 423,
  },
  {
    name: "Lithuania",
    countryCode: "lt",
    phoneCode: 370,
  },
  {
    name: "Luxembourg",
    countryCode: "lu",
    phoneCode: 352,
  },
  {
    name: "Madagascar",
    countryCode: "mg",
    phoneCode: 261,
  },
  {
    name: "Malawi",
    countryCode: "mw",
    phoneCode: 265,
  },
  {
    name: "Malaysia",
    countryCode: "my",
    phoneCode: 60,
  },
  {
    name: "Maldives",
    countryCode: "mv",
    phoneCode: 960,
  },
  {
    name: "Mali",
    countryCode: "ml",
    phoneCode: 223,
  },
  {
    name: "Malta",
    countryCode: "mt",
    phoneCode: 356,
  },
  {
    name: "Marshall Islands",
    countryCode: "mh",
    phoneCode: 692,
  },
  {
    name: "Mauritania",
    countryCode: "mr",
    phoneCode: 222,
  },
  {
    name: "Mauritius",
    countryCode: "mu",
    phoneCode: 230,
  },
  {
    name: "Mexico",
    countryCode: "mx",
    phoneCode: 52,
  },
  {
    name: "Micronesia",
    countryCode: "fm",
    phoneCode: 691,
  },
  {
    name: "Moldova",
    countryCode: "md",
    phoneCode: 373,
  },
  {
    name: "Monaco",
    countryCode: "mc",
    phoneCode: 377,
  },
  {
    name: "Mongolia",
    countryCode: "mn",
    phoneCode: 976,
  },
  {
    name: "Montenegro",
    countryCode: "me",
    phoneCode: 382,
  },
  {
    name: "Morocco",
    countryCode: "ma",
    phoneCode: 212,
  },
  {
    name: "Mozambique",
    countryCode: "mz",
    phoneCode: 258,
  },
  {
    name: "Myanmar",
    countryCode: "mm",
    phoneCode: 95,
  },
  {
    name: "Namibia",
    countryCode: "na",
    phoneCode: 264,
  },
  {
    name: "Nauru",
    countryCode: "nr",
    phoneCode: 674,
  },
  {
    name: "Nepal",
    countryCode: "np",
    phoneCode: 977,
  },
  {
    name: "Netherlands",
    countryCode: "nl",
    phoneCode: 31,
  },
  {
    name: "New Zealand",
    countryCode: "nz",
    phoneCode: 64,
  },
  {
    name: "Nicaragua",
    countryCode: "ni",
    phoneCode: 505,
  },
  {
    name: "Niger",
    countryCode: "ne",
    phoneCode: 227,
  },
  {
    name: "Nigeria",
    countryCode: "ng",
    phoneCode: 234,
  },
  {
    name: "North Korea",
    countryCode: "kp",
    phoneCode: 850,
  },
  {
    name: "North Macedonia",
    countryCode: "mk",
    phoneCode: 389,
  },
  {
    name: "Norway",
    countryCode: "no",
    phoneCode: 47,
  },
  {
    name: "Oman",
    countryCode: "om",
    phoneCode: 968,
  },
  {
    name: "Pakistan",
    countryCode: "pk",
    phoneCode: 92,
  },
  {
    name: "Palau",
    countryCode: "pw",
    phoneCode: 680,
  },
  {
    name: "Palestine",
    countryCode: "ps",
    phoneCode: 970,
  },
  {
    name: "Panama",
    countryCode: "pa",
    phoneCode: 507,
  },
  {
    name: "Papua New Guinea",
    countryCode: "pg",
    phoneCode: 675,
  },
  {
    name: "Paraguay",
    countryCode: "py",
    phoneCode: 595,
  },
  {
    name: "Peru",
    countryCode: "pe",
    phoneCode: 51,
  },
  {
    name: "Philippines",
    countryCode: "ph",
    phoneCode: 63,
  },
  {
    name: "Poland",
    countryCode: "pl",
    phoneCode: 48,
  },
  {
    name: "Portugal",
    countryCode: "pt",
    phoneCode: 351,
  },
  {
    name: "Qatar",
    countryCode: "qa",
    phoneCode: 974,
  },
  {
    name: "Romania",
    countryCode: "ro",
    phoneCode: 40,
  },
  {
    name: "Russian Federation",
    countryCode: "ru",
    phoneCode: 7,
  },
  {
    name: "Rwanda",
    countryCode: "rw",
    phoneCode: 250,
  },
  {
    name: "Saint Kitts and Nevis",
    countryCode: "kn",
    phoneCode: 1869,
  },
  {
    name: "Saint Lucia",
    countryCode: "lc",
    phoneCode: 1758,
  },
  {
    name: "Saint Vincent and the Grenadines",
    countryCode: "vc",
    phoneCode: 1784,
  },
  {
    name: "Samoa",
    countryCode: "ws",
    phoneCode: 685,
  },
  {
    name: "San Marino",
    countryCode: "sm",
    phoneCode: 378,
  },
  {
    name: "Sao Tome and Principe",
    countryCode: "st",
    phoneCode: 239,
  },
  {
    name: "Saudi Arabia",
    countryCode: "sa",
    phoneCode: 966,
  },
  {
    name: "Senegal",
    countryCode: "sn",
    phoneCode: 221,
  },
  {
    name: "Serbia",
    countryCode: "rs",
    phoneCode: 381,
  },
  {
    name: "Seychelles",
    countryCode: "sc",
    phoneCode: 248,
  },
  {
    name: "Sierra Leone",
    countryCode: "sl",
    phoneCode: 232,
  },
  {
    name: "Singapore",
    countryCode: "sg",
    phoneCode: 65,
  },
  {
    name: "Slovakia",
    countryCode: "sk",
    phoneCode: 421,
  },
  {
    name: "Slovenia",
    countryCode: "si",
    phoneCode: 386,
  },
  {
    name: "Solomon Islands",
    countryCode: "sb",
    phoneCode: 677,
  },
  {
    name: "Somalia",
    countryCode: "so",
    phoneCode: 252,
  },
  {
    name: "South Africa",
    countryCode: "za",
    phoneCode: 27,
  },
  {
    name: "South Korea",
    countryCode: "kr",
    phoneCode: 82,
  },
  {
    name: "South Sudan",
    countryCode: "ss",
    phoneCode: 211,
  },
  {
    name: "Spain",
    countryCode: "es",
    phoneCode: 34,
  },
  {
    name: "Sri Lanka",
    countryCode: "lk",
    phoneCode: 94,
  },
  {
    name: "Sudan",
    countryCode: "sd",
    phoneCode: 249,
  },
  {
    name: "Suriname",
    countryCode: "sr",
    phoneCode: 597,
  },
  {
    name: "Sweden",
    countryCode: "se",
    phoneCode: 46,
  },
  {
    name: "Switzerland",
    countryCode: "ch",
    phoneCode: 41,
  },
  {
    name: "Syria",
    countryCode: "sy",
    phoneCode: 963,
  },
  {
    name: "Taiwan",
    countryCode: "tw",
    phoneCode: 886,
  },
  {
    name: "Tajikistan",
    countryCode: "tj",
    phoneCode: 992,
  },
  {
    name: "Tanzania",
    countryCode: "tz",
    phoneCode: 255,
  },
  {
    name: "Thailand",
    countryCode: "th",
    phoneCode: 66,
  },
  {
    name: "Timor-Leste",
    countryCode: "tl",
    phoneCode: 670,
  },
  {
    name: "Togo",
    countryCode: "tg",
    phoneCode: 228,
  },
  {
    name: "Tonga",
    countryCode: "to",
    phoneCode: 676,
  },
  {
    name: "Trinidad and Tobago",
    countryCode: "tt",
    phoneCode: 1868,
  },
  {
    name: "Tunisia",
    countryCode: "tn",
    phoneCode: 216,
  },
  {
    name: "Turkey",
    countryCode: "tr",
    phoneCode: 90,
  },
  {
    name: "Turkmenistan",
    countryCode: "tm",
    phoneCode: 993,
  },
  {
    name: "Tuvalu",
    countryCode: "tv",
    phoneCode: 688,
  },
  {
    name: "Uganda",
    countryCode: "ug",
    phoneCode: 256,
  },
  {
    name: "Ukraine",
    countryCode: "ua",
    phoneCode: 380,
  },
  {
    name: "United Arab Emirates",
    countryCode: "ae",
    phoneCode: 971,
  },
  {
    name: "United Kingdom",
    countryCode: "gb",
    phoneCode: 44,
  },
  {
    name: "United States",
    countryCode: "us",
    phoneCode: 1,
  },
  {
    name: "Uruguay",
    countryCode: "uy",
    phoneCode: 598,
  },
  {
    name: "Uzbekistan",
    countryCode: "uz",
    phoneCode: 998,
  },
  {
    name: "Vanuatu",
    countryCode: "vu",
    phoneCode: 678,
  },
  {
    name: "Venezuela",
    countryCode: "ve",
    phoneCode: 58,
  },
  {
    name: "Vietnam",
    countryCode: "vn",
    phoneCode: 84,
  },
  {
    name: "Yemen",
    countryCode: "ye",
    phoneCode: 967,
  },
  {
    name: "Zambia",
    countryCode: "zm",
    phoneCode: 260,
  },
  {
    name: "Zimbabwe",
    countryCode: "zw",
    phoneCode: 263,
  },
];
