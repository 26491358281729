import styles from "./styles.module.scss";

const EllipseOne: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="98"
    height="98"
    viewBox="0 0 98 98"
    fill="none"
    className={styles.ellipseOne}
  >
    <g filter="url(#filter0_f_6166_13620)">
      <ellipse
        cx="49"
        cy="48.6543"
        rx="40"
        ry="40.0011"
        fill="var(--slate400)"
      />
      <path
        d="M88.8214 48.6543C88.8214 70.6476 70.9928 88.4767 49 88.4767C27.0072 88.4767 9.17857 70.6476 9.17857 48.6543C9.17857 26.6609 27.0072 8.83177 49 8.83177C70.9928 8.83177 88.8214 26.6609 88.8214 48.6543Z"
        stroke="#363A3F"
        strokeWidth="0.357143"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_6166_13620"
        x="0.428572"
        y="0.0817699"
        width="97.1429"
        height="97.1449"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="4.28571"
          result="effect1_foregroundBlur_6166_13620"
        />
      </filter>
    </defs>
  </svg>
);

export default EllipseOne;
