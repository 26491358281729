import { Asterisk } from "lucide-react";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";

import styles from "./styles.module.scss";

interface FatcaInfoProps {
  onClick: (value: boolean) => void;
}

const FatcaInfo = ({ onClick }: FatcaInfoProps) => {
  return (
    <div className={styles.infoContainer}>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.fatcaTitle}
      >
        US Person/FATCA
      </Text>
      <Heading
        variant={HeadingVariants.RegularTitle}
        component="h2"
        color="var(--slate900)"
        className={styles.fatcaInfoHeading}
      >
        FATCA (Foreign Account Tax Compliance Act)
      </Heading>
      <Text variant={TextVariants.RegularText} color="var(--slate800)">
        Serán consideras personas bajo la órbita de la{" "}
        <Text
          variant={TextVariants.SemiboldText}
          color="var(--slate800)"
          component="span"
        >
          Ley FATCA
        </Text>{" "}
        aquellas que:
      </Text>
      <div className={styles.fatcaBullets}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          <Asterisk /> Sean ciudadanos estadounidenses.
        </Text>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          <Asterisk /> Sean residentes permanentes en los Estados Unidos de
          América.
        </Text>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          <Asterisk /> Tributen en los Estados Unidos de América.
        </Text>
      </div>
      <FilledButton onClick={() => onClick(false)}>Entendido</FilledButton>
    </div>
  );
};

export default FatcaInfo;
