import ProfileLegals from "components/page-profile/page-legals";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

const Legals: React.FC = () => {
  const { isMobile } = useDeviceCheck();

  if (isMobile) {
    return <ProfileLegals />;
  }

  return <Navigate replace to={mainRoutes.profile} />;
};

export default Legals;
