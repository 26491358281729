import { CocosTariffURL } from "constants/index";

import { useEffect } from "react";

import { Browser } from "@capacitor/browser";
import { ampli } from "ampli";
import Footer from "components/common/Footer";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Tiers } from "interfaces/user";
import { Navigate } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAccountTierQuery } from "apis/user/queries/useAccountTierQuery";
import ErrorPage from "components/common/ErrorPage";
import LoadingScreen from "components/page-crypto-cable/Loading";
import { ThemeVariants } from "interfaces/theme";
import { useActivateGoldMutation } from "apis/user/mutations/useActivateGoldMutation";

import GoldBenefitsList from "./components/BenefitsList";
import GoldButtonsWrapper from "./components/ButtonsWrapper";
import GoldHeader from "./components/Header";
import GoldConfirm from "./Confirm";

import styles from "./styles.module.scss";

const CocosGoldPage = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();
  const { data: accountTier, error, isLoading } = useAccountTierQuery();
  const currentScreen = useAppSelector(getCurrentScreen);
  const mutationActivateGold = useActivateGoldMutation();

  const isGoldOrRequested =
    accountTier &&
    [Tiers.GOLD, Tiers.GOLD_REQUESTED].includes(accountTier.tier);

  if (accountTier?.tier === Tiers.FREE) {
    return <Navigate to={mainRoutes.home} replace />;
  }

  const activateGold = async () => {
    ampli.requestContactCocosGold();
    await mutationActivateGold.mutateAsync();
  };

  const openComissions = async () => {
    Browser.open({ url: CocosTariffURL });
  };

  useEffect(() => {
    ampli.screenCocosGold({ previous_screen: currentScreen });
    dispatch(setCurrentScreen(screenNames.gold));

    return () => {
      if (!isMobile) {
        dispatch(setCurrentScreen(currentScreen));
      }
    };
  }, []);

  if (mutationActivateGold.isSuccess || isGoldOrRequested) {
    return <GoldConfirm />;
  }

  if (isLoading || mutationActivateGold.isLoading) {
    return <LoadingScreen variant={ThemeVariants.Capital} />;
  }

  if (error || mutationActivateGold.error) {
    return (
      <ErrorPage
        errorMessage={
          error?.message || (mutationActivateGold?.error?.message as string)
        }
      />
    );
  }

  return (
    <div className={styles.goldPageContainer}>
      <div>
        <GoldHeader />
        <GoldBenefitsList />
      </div>
      <Footer>
        <GoldButtonsWrapper
          onClickActivate={activateGold}
          onClickComissions={openComissions}
        />
      </Footer>
    </div>
  );
};

export default CocosGoldPage;
