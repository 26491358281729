import { useEffect } from "react";

import { ampli } from "ampli";
import FilledButton from "components/common/FilledButton";
import Footer from "components/common/Footer";
import OutlinedButton from "components/common/OutlinedButton";
import PageHeader from "components/common/PageHeader";
import { useCocosPay } from "context/CocosPayProvider";
import dayjs from "dayjs";
import { PaySteps, PaymentStatus } from "interfaces/pay/enums";
import { ThemeVariants } from "interfaces/theme";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";

import ResponseDetail from "./ResponseDetail";
import ResponseHeader from "./ResponseHeader";

import styles from "./styles.module.scss";

const PayResponse: React.FC = () => {
  const { setStep, receiptData, paymentData } = useCocosPay();

  const navigate = useNavigate();

  const {
    businessName,
    quantity,
    idPayment,
    createdAt,
    paymentMethod,
    status,
  } = receiptData;

  const { countryCode } = paymentData;

  const isSuccess = status === PaymentStatus.COMPLETED;
  const title = isSuccess ? "¡Listo!" : "Algo salió mal";

  useEffect(() => {
    const properties = {
      paid_to: businessName,
      amount: quantity,
      date: dayjs(createdAt).format("DD/MM/YYYY"),
      time: dayjs(createdAt).format("HH:mm"),
      method: paymentMethod,
      receipt_number: idPayment,
      country_code: countryCode,
    };
    if (isSuccess) {
      ampli.payScreenSuccess(properties);
      return;
    }
    ampli.payScreenFailed(properties);
  }, []);

  const onClickRetry = () => {
    ampli.payFailedRetry({
      paid_to: businessName,
      amount: quantity,
      receipt_number: idPayment,
    });
    setStep(PaySteps.QR);
  };

  const onClickDownload = () => {
    ampli.payDownloadReceipt({
      amount: quantity,
      method: paymentMethod,
      paid_to: businessName,
      receipt_number: idPayment,
      date: dayjs(createdAt).format("DD/MM/YYYY"),
      time: dayjs(createdAt).format("HH:mm"),
      referral: "success",
    });
    setStep(PaySteps.RECEIPT);
  };

  const onClickExit = () => {
    ampli.payExit({ referral: isSuccess ? "success" : "failed" });
    navigate(-1);
  };

  return (
    <div className={styles.PayResponseContainer}>
      <div>
        <PageHeader
          icon={X}
          iconColor="var(--black-to-white)"
          title={title}
          onClick={onClickExit}
        />
        <ResponseHeader success={isSuccess} />
        <ResponseDetail />
      </div>
      <Footer>
        <div className={styles.buttonsWrapper}>
          {isSuccess ? (
            <FilledButton variant={ThemeVariants.Pay} onClick={onClickDownload}>
              Descargar comprobante
            </FilledButton>
          ) : (
            <FilledButton variant={ThemeVariants.Pay} onClick={onClickRetry}>
              Volver a intentar
            </FilledButton>
          )}
          <OutlinedButton variant={ThemeVariants.Pay} onClick={onClickExit}>
            Volver al inicio
          </OutlinedButton>
        </div>
      </Footer>
    </div>
  );
};

export default PayResponse;
