import { useNavigate } from "react-router-dom";

import mainRoutes from "../../../router/routes";

import "./index.scss";

interface INeedHelp {
  leyend: string;
}

export const NeedHelp = ({ leyend }: INeedHelp) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(mainRoutes.help);
  };

  return (
    <div className="need-help">
      <span>{leyend}</span>
      <a onClick={() => handleRedirect()}>Solicitá ayuda.</a>
    </div>
  );
};
