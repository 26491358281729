import { ReactElement } from "react";

import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import PayReceipt from "components/page-movement-receipt/PayReceipt";
import { getInstrumentName } from "components/page-movements/utils";
import { useAppState } from "context/AppStateProvider";
import { useAppDispatch } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import {
  CashMovement,
  MovementsTypes,
  OperationType,
  Source,
} from "interfaces/movements";
import { QrCode } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";

import MovementsRow from "./MovementsRow";

interface DisplayMovement {
  label: string;
  img: ReactElement;
}

interface MovementsRowPayProps {
  movement: CashMovement;
}

const MovementsRowPay: React.FC<MovementsRowPayProps> = ({ movement }) => {
  const {
    description,
    instrument_type,
    label,
    logo_file_name,
    operation_type,
    instrument_code,
    quantity,
    created_at,
    short_name,
    source,
    id_cash_movement,
  } = movement;
  const { openRightModalContent } = useAppState();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();

  const logoFile = (
    <InstrumentLogoUI
      logoFileName={logo_file_name}
      type={instrument_type}
      variant={InstrumentLogoVariants.Primary}
    />
  );

  const getLabelText = (source: Source, operationType: string): string => {
    let text = label ?? description;

    if (source === Source.PAY) {
      if (operationType === OperationType.CHARGEBACK) {
        text = "Contracargos - Ágil Pagos";
      } else {
        text = "Pago - Ágil Pagos";
      }
    }

    return text;
  };

  const displayMovement: {
    [key in Source]: DisplayMovement;
  } = {
    [Source.PAY]: {
      label: getLabelText(source, operation_type),
      img: <QrCode width={48} height={48} color="var(--blue-to-lb)" />,
    },
    [Source.CAPITAL]: {
      label: "",
      img: logoFile,
    },
    [Source.COINAG]: {
      label: "",
      img: logoFile,
    },
    [Source.CRYPTO]: {
      label: "",
      img: logoFile,
    },
    [Source.DEPOSIT]: {
      label: "",
      img: logoFile,
    },
    [Source.CARD]: {
      label: "",
      img: logoFile,
    },
  };

  const movementLabel = displayMovement[source].label;
  const icon = displayMovement[source].img;
  const name = getInstrumentName(instrument_code) ?? short_name ?? "";

  const handleOnClick = async () => {
    if (isMobile) {
      sessionStorage.setItem("scroll", window.scrollY.toString());
      sessionStorage.setItem("movements_section", MovementsTypes.MONETARY);
      return navigate(`${mainRoutes.movementReceipt}/${id_cash_movement}`);
    }
    return openRightModalContent(
      <PayReceipt id={id_cash_movement.toString()} />
    );
  };

  return (
    <MovementsRow
      executionDate={created_at}
      settlementDate={created_at}
      icon={icon}
      label={movementLabel}
      name={name}
      price={quantity}
      onClick={handleOnClick}
    />
  );
};

export default MovementsRowPay;
