import { useEffect } from "react";

import { ampli } from "ampli";
import CardOutOfStock from "assets/illustrations/CocosCard/CardOutOfStock";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { ThemeVariants } from "interfaces/theme";
import { UIModalSlider } from "components/common/modal-slider";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";

import styles from "./styles.module.scss";

interface OutOfStockProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
  isEmptyCard?: boolean;
}

const OutOfStock: React.FC<OutOfStockProps> = ({
  open,
  onContinue,
  onClose,
  isEmptyCard = false,
}) => {
  const { isIOS } = getPlatform();

  useEffect(() => {
    ampli.cardOutOfStock();
  }, []);

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      <Text variant={TextVariants.RegularText} className={styles.title}>
        ¡Oh no...!
      </Text>
      <div className={styles.sheetContent}>
        <CardOutOfStock />
        <Text variant={TextVariants.RegularText} className={styles.text}>
          La tarjeta física salió como pan caliente, estate atento porque pronto
          vamos a tener más en stock.
        </Text>
      </div>
      <div
        className={classNames(styles.buttonWrapper, { [styles.ios]: isIOS })}
      >
        <FilledButton variant={ThemeVariants.Pay} onClick={onContinue}>
          {isEmptyCard ? "Volver a tarjetas" : "Pedí tu tarjeta virtual"}
        </FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default OutOfStock;
