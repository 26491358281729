import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";
import mainRoutes from "router/routes";
import { useCapitalSendAccounts } from "context/CapitalSendAccountsProvider";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import { useAppDispatch } from "hooks/redux";

import styles from "./styles.module.scss";

const Error = ({ isAccountsError }: { isAccountsError?: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isDesktop } = useCapitalSendAccounts();

  const onClose = () => {
    if (isDesktop) {
      dispatch(closeRightModal());
    }

    return navigate(mainRoutes.home);
  };

  const handleCloseSidebar = () => {
    dispatch(closeRightModal());
  };

  return (
    <div className={styles.errorContainer}>
      <BasicWrapper
        navigationHeaderProps={{
          title: "Algo salió mal",
          onClick: isDesktop ? handleCloseSidebar : onClose,
        }}
        primaryButtonProps={{ children: "Volver al inicio", onClick: onClose }}
      >
        <div className={styles.iconContainer}>
          <X color="var(--red900)" />
        </div>
        <Text
          variant={TextVariants.RegularText}
          className={styles.text}
          color="var(--slate800)"
        >
          {`No pudimos ${
            isAccountsError ? "agendar la cuenta" : "realizar el envío"
          }. Por favor, volvé a intentarlo más tarde.`}
        </Text>
      </BasicWrapper>
    </div>
  );
};

export default Error;
