import MarketPage from "components/page-market";
import { MarketProvider } from "context/MarketProvider";

const Market: React.FC = () => (
  <MarketProvider>
    <MarketPage />
  </MarketProvider>
);

export default Market;
