import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { useAppState } from "context/AppStateProvider";
import { Currencies } from "interfaces/wallet";

interface AmountProps {
  amount: number;
  currency: Currencies;
}

const Amount: React.FC<AmountProps> = ({ amount, currency }) => {
  const { isBalanceHidden } = useAppState();
  const priceLabelColor = isBalanceHidden
    ? "var(--slate800)"
    : "var(--slate900)";

  return (
    <PriceLabel
      price={amount}
      currency={currency}
      color={priceLabelColor}
      size={PriceLabelSizes.Xsmall}
      isBalanceHidden={isBalanceHidden}
    />
  );
};

export default Amount;
