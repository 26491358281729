import Text, { TextVariants } from "components/common//Text";
import AvailableLabel from "components/common/AvailableLabel";
import Variation from "components/common/CurrentVariation";
import { ThemeVariants } from "interfaces/theme";
import { useAppState } from "context/AppStateProvider";
import { Currencies } from "interfaces/wallet";
import Skeleton from "react-loading-skeleton";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface BalanceProps {
  available: number;
  isLoading?: boolean;
  currency?: Currencies;
  dateToDisplay?: string;
  variant: ThemeVariants;
  variationAmount: number;
  variationLabel?: string;
  variationPercentage: number;
}

const Balance: React.FC<BalanceProps> = ({
  variant,
  currency,
  available,
  isLoading,
  dateToDisplay,
  variationLabel,
  variationAmount,
  variationPercentage,
}) => {
  const { isBalanceHidden } = useAppState();

  if (isLoading)
    return (
      <div className={styles.balanceContainer}>
        <Skeleton height={px2rem(35)} />
        <Skeleton height={px2rem(18)} />
      </div>
    );

  return (
    <div className={styles.balanceContainer}>
      <AvailableLabel
        available={available}
        currency={currency}
        variant={variant}
      />
      {dateToDisplay ? (
        <Text variant={TextVariants.RegularTextS}>{dateToDisplay}</Text>
      ) : (
        <Variation
          value={variationPercentage}
          amount={variationAmount}
          text={variationLabel}
          hideBalances={isBalanceHidden}
        />
      )}
    </div>
  );
};

export default Balance;
