import { useState } from "react";

import { useDeviceCheck } from "hooks/useDeviceCheck";
import { FlowAnalysis, InvestmentSuggestedResponse } from "interfaces/markets";
import { MdExpandMore } from "react-icons/md";
import { screenNames } from "router/routes";
import Text, { TextVariants } from "components/common/Text";
import CsvDownloadLink from "components/common/CsvDownloadLink";
import classNames from "classnames";

import FlowBodyRow from "./components/FlowBodyRow";
import FlowHeader from "./components/FlowHeader";

import styles from "./styles.module.scss";

interface BondFlowProps {
  instrument: InvestmentSuggestedResponse;
  analysis: FlowAnalysis;
}

const BondFlow: React.FC<BondFlowProps> = ({ instrument, analysis }) => {
  const { isMobile } = useDeviceCheck();
  const [isFullFlow, setIsFullFlow] = useState(false);
  const flow = analysis.flow;

  const flowToDownload = [
    ["Fecha", "Capital", "Intereses", "Flujo", "Acumulado"],
    ...flow.map((obj) => {
      return [obj.date, obj.capital, obj.interes, obj.cashFlow, obj.acumulated];
    }),
  ];
  const flowRows = isFullFlow ? flow : flow.slice(0, 4);

  const extendFlowLabel = `Ver flujo ${isFullFlow ? "parcial" : "completo"}`;

  return (
    <div className={styles.bondFlow}>
      <div className={styles.resumeSubtitle}>
        <Text variant={TextVariants.SemiboldTextL}>
          Flujo de fondos para {analysis.quantity} VN:
        </Text>
        <CsvDownloadLink
          data={flowToDownload}
          filename={`${instrument.instrument_code}-flow.csv`}
          screenName={screenNames.investment}
        />
      </div>
      <div
        className={styles.flowTable}
        style={{ gridTemplateColumns: `repeat(${isMobile ? 4 : 5}, 1fr)` }}
      >
        <FlowHeader isMobile={isMobile} />
        {flowRows.map((flowRow, index) => (
          <FlowBodyRow
            key={flowRow.date}
            isMobile={isMobile}
            flowRow={flowRow}
            index={index}
          />
        ))}
      </div>
      <div
        className={styles.expandFlow}
        onClick={() => setIsFullFlow(!isFullFlow)}
      >
        <Text variant={TextVariants.SemiboldText} className={styles.expandLink}>
          {extendFlowLabel}
        </Text>
        <MdExpandMore
          size={16}
          className={classNames({ [styles.Full]: isFullFlow })}
        />
      </div>
    </div>
  );
};

export default BondFlow;
