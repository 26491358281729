import { useEffect, useState } from "react";

import BasicWrapper from "components/common/BasicWrapper";
import { MailCheck } from "lucide-react";
import { ampli } from "ampli";
import { supabase } from "utils/supabase";
import { useTimeLeft } from "hooks/useTimeLeft";

interface EmailReviewProps {
  email: string;
  onGoBack: () => void;
  setError: (err: string) => void;
}

const ONE_MINUTE = 60;

const ReviewEmail = ({ email, onGoBack, setError }: EmailReviewProps) => {
  const { timeLeft, isTimeCompleted, setTimeLeft } = useTimeLeft(ONE_MINUTE);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const buttonText = !isTimeCompleted
    ? `Volver a enviar (en ${timeLeft} segs)`
    : "Volver a enviar";

  const subtitle = `Te enviamos un email con instrucciones para recuperar tu contraseña a: ${email}.`;

  const handleResendEmail = async (emailToResend: string) => {
    setIsSubmitting(true);
    ampli.forgotPasswordResendEmail();

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(
        emailToResend
      );

      if (error) {
        console.error("Error al reenviar el mail: ", error);
        setError(
          "Ocurrió un error al reenviar el email. Por favor, inténtelo nuevamente."
        );
      }
    } catch (err) {
      setError("Ocurrió un error inesperado. Por favor, inténtelo nuevamente.");
    } finally {
      setTimeLeft(ONE_MINUTE);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    ampli.forgotPasswordCheckEmail();
  }, []);

  return (
    <BasicWrapper
      icon={MailCheck}
      navigationHeaderProps={{
        onClick: onGoBack,
        withCloseIcon: false,
      }}
      title="Revisá tu casilla de correo"
      subtitle={subtitle}
      secondaryButtonProps={{
        children: buttonText,
        onClick: () => handleResendEmail(email),
        disabled: !isTimeCompleted || isSubmitting,
      }}
    />
  );
};

export default ReviewEmail;
