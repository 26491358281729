import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";

import styles from "./styles.module.scss";

interface GoldButtonsWrapperProps {
  onClickActivate: () => void;
  onClickComissions: () => void;
}

const GoldButtonsWrapper: React.FC<GoldButtonsWrapperProps> = ({
  onClickActivate,
  onClickComissions,
}) => {
  return (
    <div className={styles.goldButtonsWrapperContainer}>
      <FilledButton className={styles.filled} onClick={onClickActivate}>
        Quiero ser Cocos Gold
      </FilledButton>
      <OutlinedButton className={styles.outlined} onClick={onClickComissions}>
        Ver comisiones del servicio
      </OutlinedButton>
    </div>
  );
};

export default GoldButtonsWrapper;
