import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { useAppState } from "context/AppStateProvider";
import { Currencies } from "interfaces/wallet";

interface AverageCostProps {
  averageCost?: number;
}

const AverageCost: React.FC<AverageCostProps> = ({ averageCost }) => {
  const { isBalanceHidden } = useAppState();

  const priceLabelColor = isBalanceHidden
    ? "var(--slate800)"
    : "var(--slate900)";

  if (!averageCost) return <div>--</div>;

  return (
    <PriceLabel
      price={averageCost}
      currency={Currencies.ARS}
      color={priceLabelColor}
      size={PriceLabelSizes.Xsmall}
      isBalanceHidden={isBalanceHidden}
    />
  );
};

export default AverageCost;
