import HelpPage from "components/page-profile/page-help";
import { ProfileProvider } from "context/ProfileProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

const Help: React.FC = () => {
  const { isMobile } = useDeviceCheck();

  if (isMobile)
    return (
      <ProfileProvider>
        <HelpPage />
      </ProfileProvider>
    );

  return <Navigate replace to={mainRoutes.profile} />;
};

export default Help;
