import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { InfoIcon } from "assets/icons/info";
import { closeModalInvestor } from "features/user/userSlice";
import AlertModal from "components/common/alert-modal";
import CheckboxUI from "components/common/controls/checkbox";
import mainRoutes from "router/routes";
import { useAppDispatch } from "hooks/redux";

import "./index.scss";

interface PropTypes {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  investorData: string;
  dateInvestorTest: string;
}

const InvestorTestCheck = (props: PropTypes) => {
  const { openModal, setOpenModal, investorData, dateInvestorTest } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [notShowAgain, setNotShowAgain] = useState(false);

  const closeModal = async () => {
    notShowAgain && (await dispatch(closeModalInvestor(false)));
    setOpenModal(false);
    navigate(mainRoutes.profileTest);
  };

  const goToHome = () => {
    notShowAgain && dispatch(closeModalInvestor(false));
    setOpenModal(false);
    navigate(mainRoutes.home);
  };

  useEffect(() => {
    investorData && dateInvestorTest;
  }, [investorData, dateInvestorTest]);

  return (
    <AlertModal
      alertIcon={<InfoIcon color="#0062e1" size={50} />}
      bodyContainer={
        <CheckboxUI
          checked={notShowAgain}
          label={"No volver a mostrar durante la sesion."}
          onChange={(newValue) => setNotShowAgain(newValue)}
        />
      }
      bodyText={
        <>
          {+investorData > 0 && (
            <Typography className="subtitle" component="p" variant="textL">
              En {investorData} días se vence tu perfil de inversor, de acuerdo
              al test realizado el {dateInvestorTest}. No podrás operar una vez
              vencido.
              <br />
            </Typography>
          )}
          {+investorData === 0 && (
            <Typography className="subtitle" component="p" variant="textL">
              Hoy se vence tu perfil de inversor, tu último test realizado fue
              el {dateInvestorTest}. No podrás operar a partír de mañana
              <br />
            </Typography>
          )}
          <Typography className="subtitle" component="p" variant="textL_bold">
            Por favor completalo a la brevedad.
          </Typography>
        </>
      }
      headingText={
        <>
          {+investorData > 0 && (
            <Typography component="p" variant="heading4_bold">
              Vencimiento cercano de tu perfil de inversor
            </Typography>
          )}
          {+investorData === 0 && (
            <Typography component="p" variant="heading4_bold">
              Hoy se vence tu perfil de inversor
            </Typography>
          )}
        </>
      }
      open={openModal}
      primaryButtonText={"Ir al test"}
      secondaryButtonText={"Ir al Inicio"}
      onClickMainButton={closeModal}
      onClickSecondButton={goToHome}
    />
  );
};

export default InvestorTestCheck;
