import classNames from "classnames";
import { Check, ArrowRight, ArrowLeft, ScanFace } from "lucide-react";
import Text, { TextVariants } from "components/common/Text";
import IconBadge from "components/common/Badges/IconBadge";

import CameraTimer from "../CameraTimer";

import styles from "./styles.module.scss";

const CameraVisorSelfie = ({
  isTrackingRightStep = false,
  isTrackingLeftStep = false,
  isFinalStep = false,
  isSuccess = false,
  displayTimer = false,
}: {
  isTrackingRightStep?: boolean;
  isTrackingLeftStep?: boolean;
  isFinalStep?: boolean;
  isSuccess?: boolean;
  displayTimer?: boolean;
}) => {
  const containerStyles = classNames({
    [styles.container]: true,
    [styles.trackingRight]: isTrackingRightStep,
    [styles.trackingLeft]: isTrackingLeftStep,
    [styles.final]: isFinalStep,
    [styles.success]: isSuccess,
  });

  const disclaimerStyles = classNames({
    [styles.textContent]: true,
    [styles.success]: isSuccess,
  });

  const getTextLabel = () => {
    if (isSuccess)
      return (
        <>
          <IconBadge backgroundColor="var(--green600)">
            <Check color="#fff" />
          </IconBadge>
          <Text variant={TextVariants.RegularText} color="#fff">
            ¡Listo!
          </Text>
        </>
      );
    if (isTrackingRightStep)
      return (
        <>
          <IconBadge backgroundColor="#fff">
            <ArrowRight color="#1c2024" />
          </IconBadge>
          <Text variant={TextVariants.RegularText} color="#fff">
            Gira tu cabeza hacia la <b>derecha</b>.
          </Text>
        </>
      );
    if (isTrackingLeftStep)
      return (
        <>
          <IconBadge backgroundColor="#fff">
            <ArrowLeft color="#1c2024" />
          </IconBadge>
          <Text variant={TextVariants.RegularText} color="#fff">
            Gira tu cabeza hacia la <b>izquierda</b>.
          </Text>
        </>
      );
    if (isFinalStep)
      return (
        <>
          <IconBadge backgroundColor="#fff">
            <ScanFace color="#1c2024" />
          </IconBadge>
          <Text variant={TextVariants.RegularText} color="#fff">
            <b>Centrá</b> tu cara dentro del marco para que tomemos una selfie.
          </Text>
        </>
      );
  };

  return (
    <div className={styles.wrapper}>
      <div className={containerStyles}>{displayTimer && <CameraTimer />}</div>
      <div className={disclaimerStyles}>{getTextLabel()}</div>
    </div>
  );
};

export default CameraVisorSelfie;
