import { useEffect } from "react";

import FundsProvider from "context/FundsProvider";
import { useParams } from "react-router-dom";
import { resetOrder } from "features/markets";
import { useAppDispatch } from "hooks/redux";

import FundsWrapper from "./components/common/FundsWrapper";
import { CocosFundsList, CocosFund, AvailableFunds } from "./helpers";

const findCocosFund = (shortName: string | undefined): CocosFund | null => {
  return CocosFundsList.find((fund) => fund.ticker === shortName) ?? null;
};

interface CapitalFundsProps {
  shortName?: string;
}

const CapitalFunds: React.FC<CapitalFundsProps> = ({ shortName }) => {
  const dispatch = useAppDispatch();
  const { fundId } = useParams<{
    fundId: AvailableFunds;
  }>();

  const selectedFund = findCocosFund(shortName ?? fundId);

  if (!selectedFund) {
    return null;
  }

  useEffect(() => {
    dispatch(resetOrder());
  }, []);

  return (
    <FundsProvider>
      <FundsWrapper selectedFund={selectedFund} />
    </FundsProvider>
  );
};

export default CapitalFunds;
