import { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import NumbersBadge from "components/common/Badges/NumbersBadge";
import Text, { TextVariants } from "components/common/Text";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { getLoggedUser } from "store/selectors/user.selector";
import { DesktopOrTablet, Mobile } from "utils/responsive";

import styles from "./styles.module.scss";

const SelectAccount: React.FC = () => {
  const user = useSelector(getLoggedUser);
  const listRef = useRef<HTMLDivElement>(null);

  const [cookie, setCookie] = useCookies();
  const [showOptions, setShowOptions] = useState<boolean>(false);

  if (!user) return null;

  const { id_accounts } = user;

  const [selectedAccount, setSelectedAccount] = useState<number>(
    Number(cookie["id_account"]) || id_accounts[0]
  );

  const onlyAccount = id_accounts.length <= 1;

  const filteredOptions = id_accounts.filter(
    (account) => account !== selectedAccount
  );

  const accountNumberLabel = "Cuenta N°";

  useEffect(() => {
    document.addEventListener("click", handleOnClickOutside);

    return () => {
      document.removeEventListener("click", handleOnClickOutside);
    };
  }, []);

  const handleOnClickOutside = (event: MouseEvent) => {
    if (listRef.current && !listRef.current.contains(event.target as Node))
      setShowOptions(false);
  };

  const handleOnSelectAccount = (value: number) => {
    setCookie("id_account", value, { path: "/" });
    setSelectedAccount(value);
    setShowOptions(false);
  };

  return (
    <div
      ref={listRef}
      className={classNames(styles.selectAccountContainer, {
        [styles.noSelect]: onlyAccount,
      })}
    >
      <Mobile>
        <NumbersBadge
          label={accountNumberLabel}
          number={selectedAccount}
          onClick={() => setShowOptions(!showOptions)}
        />
      </Mobile>
      <DesktopOrTablet>
        <Text
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
          onClick={() => setShowOptions(!showOptions)}
        >
          {accountNumberLabel}{" "}
          <Text
            variant={TextVariants.SemiboldTextS}
            component="span"
            color="var(--slate800)"
          >
            {selectedAccount}
          </Text>
        </Text>
      </DesktopOrTablet>
      {showOptions && (
        <div className={styles.options}>
          {filteredOptions.map((account) => (
            <Text
              key={account}
              className={styles.option}
              variant={TextVariants.RegularValueS}
              color="var(--slate800)"
              onClick={() => handleOnSelectAccount(account)}
            >
              {account}
            </Text>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectAccount;
