import { PerformancePeriods } from "components/page-cocos-portfolio/utils";
import type { PeriodTime } from "interfaces/portfolio";
import type { Currencies, PortfolioFromType } from "interfaces/wallet";

import type { MarketTickersParams } from "./market/queries/useMarketTickersQuery";

export const userKeys = {
  accountTier: ["accountTier"] as const,
  users: ["users"] as const,
};

export const marketKeys = {
  marketTickers: (params: MarketTickersParams, listId?: string) =>
    ["marketTickers", params, listId] as const,
  marketHistoricalData: (longTicker: string, interval?: string) =>
    ["marketHistoricalData", longTicker, interval] as const,
  favoriteInstruments: (listId?: string) =>
    ["favoriteInstruments", listId] as const,
  homeTickers: ["homeTickers"] as const,
};

export const portfolioKeys = {
  portfolio: (currency: Currencies, from: PortfolioFromType) =>
    ["portfolio", "portfolio", currency, from] as const,
  balance: (currency: Currencies, period: PeriodTime) =>
    ["portfolio", "balance", currency, period] as const,
  history: (currency: Currencies, period: PeriodTime) =>
    ["portfolio", "history", currency, period] as const,
  performance: (period: PeriodTime, isCrypto: boolean) =>
    ["portfolio", "performance", period, isCrypto] as const,
};

export const fundsKeys = {
  holdings: (securityId: number, period?: PeriodTime, offset?: number) =>
    ["fci", "holdings", securityId, period, offset] as const,
};

export const cardKeys = {
  cardUser: ["cardUser"] as const,
};

export const cryptoKeys = {
  prices: ["prices", "crypto"] as const,
  performance: (period: PerformancePeriods) => ["performance", period] as const,
};

export const payKeys = {
  prices: ["prices", "pix"] as const,
};

export const bannerKeys = {
  banner: (banner: string) => ["banner", banner] as const,
};
