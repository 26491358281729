import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import Text, { TextVariants } from "components/common/Text";
import { CheckCheck, ChevronRight, Moon } from "lucide-react";
import px2rem from "utils/px2rem";
import TextBadge from "components/common/Badges/TextBadge";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface OptionProps {
  title: string;
  subtitle: string;
  isFixed?: boolean;
  buyingPrice: number;
  isBlurred?: boolean;
  sellingPrice: number;
  onClickOption: () => void;
}

const Option: React.FC<OptionProps> = ({
  title,
  isFixed,
  subtitle,
  isBlurred,
  buyingPrice,
  sellingPrice,
  onClickOption,
}) => {
  const handleOptionClick = () => {
    if (isBlurred) return;

    onClickOption();
  };

  return (
    <div onClick={handleOptionClick} className={styles.optionWrapper}>
      <div className={styles.row}>
        <div>
          <div className={styles.badgeWrapper}>
            <Text variant={TextVariants.RegularText} color="var(--slate900)">
              {title}
            </Text>
            {isFixed && (
              <TextBadge variant={ThemeVariants.Capital} text="24/7" />
            )}
          </div>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            {subtitle}
          </Text>
        </div>
        {!isBlurred && (
          <ChevronRight size={px2rem(16)} color="var(--slate800)" />
        )}
      </div>
      <div className={styles.quoteBox}>
        {isFixed && (
          <div className={styles.fixedBanner}>
            <CheckCheck size={px2rem(20)} color="var(--green800)" />
            <Text variant={TextVariants.SemiboldTextS} color="var(--green800)">
              Sin comisiones
            </Text>
          </div>
        )}
        <div className={styles.quoteWrapper}>
          <div className={styles.quote}>
            <Text
              color="var(--slate800)"
              className={styles.quoteTitlte}
              variant={TextVariants.RegularTextS}
            >
              Vendés a
            </Text>
            <PriceLabel price={sellingPrice} size={PriceLabelSizes.Small} />
          </div>
          <div className={styles.divider} />
          <div className={styles.quote}>
            <Text
              color="var(--slate800)"
              className={styles.quoteTitlte}
              variant={TextVariants.RegularTextS}
            >
              Comprás a
            </Text>
            <PriceLabel price={buyingPrice} size={PriceLabelSizes.Small} />
          </div>
        </div>
        {isBlurred && (
          <div className={styles.blurred}>
            <Moon
              size={px2rem(12)}
              color="var(--blue200)"
              className={styles.icon}
            />
            <Text
              color="var(--blue900)"
              className={styles.text}
              variant={TextVariants.SemiboldTextXS}
            >
              En este momento el mercado esta cerrado
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default Option;
