import { Fragment, useEffect, useState } from "react";

import { Skeleton } from "@mui/material";
import { Spinner } from "components/common/spinner";
import MovementsRowPay from "components/page-movements/components/movements-common/MovementsRowPay";
import MovementsHeader from "components/page-movements/components/movements-common/movements-header";
import MovementsRow from "components/page-movements/components/movements-common/movements-row";
import DesktopTableHeader from "components/page-movements/components/movements-common/table-header";
import { getInstrumentName } from "components/page-movements/utils";
import { useMovements } from "context/MovementsProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import {
  CashMovement,
  CashMovements,
  MovementsTypes,
  Source,
} from "interfaces/movements";
import InfiniteScroll from "react-infinite-scroll-component";

import styles from "./styles.module.scss";

interface MonetaryTableProps {
  data: CashMovements[];
}

const MonetaryTable: React.FC<MonetaryTableProps> = ({ data }) => {
  const { isMobile } = useDeviceCheck();
  const {
    filters,
    movementsLoading,
    movementsPagination,
    getCashMovementsData,
  } = useMovements();

  const [loadMoreElements, setLoadMoreElements] = useState<boolean>(false);

  useEffect(() => {
    setLoadMoreElements(false);
  }, [data]);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scroll");

    if (!movementsLoading && scrollPosition) {
      window.scrollTo(0, +scrollPosition);
      sessionStorage.removeItem("scroll");
    }
  }, [movementsLoading]);

  if (movementsLoading && !loadMoreElements) {
    return (
      <div className={styles.loadingState}>
        <Spinner />
      </div>
    );
  }

  const loadElements = () => {
    if (
      movementsPagination &&
      movementsPagination.total_pages !== movementsPagination.curr_page
    ) {
      const { currency } = filters;

      setLoadMoreElements(true);
      getCashMovementsData(currency, movementsPagination.curr_page + 1);
    }
  };

  const renderLoaderRows = (rowsLength: number) =>
    Array.from(Array(rowsLength).keys()).map((i) => (
      <Skeleton animation="wave" height={64} key={i} />
    ));

  return (
    <InfiniteScroll
      dataLength={data.length}
      hasMore={
        movementsPagination?.curr_page !== movementsPagination?.total_pages
      }
      loader={renderLoaderRows(3)}
      next={loadElements}
    >
      {!isMobile && <DesktopTableHeader />}

      {data.map((row: CashMovements) => (
        <Fragment key={row.executionDate}>
          <MovementsHeader
            settlementDate={row.executionDate}
            balance={row.balance}
            displaySettlementDate={isMobile}
          />
          <div>
            {row.cashMovements.map((movement: CashMovement, index: number) => {
              if (movement.source === Source.PAY) {
                return <MovementsRowPay key={index} movement={movement} />;
              }

              return (
                <MovementsRow
                  id_ticket={movement.id_ticket}
                  has_ticket_pdf={movement.has_ticket_pdf}
                  key={index}
                  operationType={movement.label ?? movement.description}
                  name={
                    getInstrumentName(movement.instrument_code) ??
                    movement.short_name
                  }
                  quantity={movement.quantity}
                  price={movement.price ?? 0}
                  total={movement.quantity * (movement.price ?? 1)}
                  instrumentType={movement.instrument_type}
                  logoFile={movement.logo_file_name}
                  currency={movement.id_currency}
                  instrumentCode={movement.instrument_code}
                  executionDate={movement.execution_date}
                  settlementDate={movement.settlement_date}
                  section={MovementsTypes.MONETARY}
                  source={movement.source}
                />
              );
            })}
          </div>
        </Fragment>
      ))}
    </InfiniteScroll>
  );
};

export default MonetaryTable;
