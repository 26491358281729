import classNames from "classnames";
import { Check, Fullscreen, IterationCw } from "lucide-react";
import Text, { TextVariants } from "components/common/Text";
import IconBadge from "components/common/Badges/IconBadge";

import styles from "./styles.module.scss";

const CameraVisor = ({
  isDNIFront,
  isDNIBack,
  isCompleted,
}: {
  isDNIFront?: boolean;
  isDNIBack?: boolean;
  isCompleted?: boolean;
}) => {
  const containerStyles = classNames({
    [styles.container]: true,
    [styles.success]: isCompleted,
  });

  const disclaimerStyles = classNames({
    [styles.textContent]: true,
    [styles.success]: isCompleted,
  });

  const whiteColor = "#fff";
  const blackColor = "#1c2024";

  const getIcon = () => {
    if (isCompleted) return <Check color={whiteColor} />;
    if (isDNIBack) return <IterationCw color={blackColor} />;
    return <Fullscreen color={blackColor} />;
  };

  const getTextLabel = () => {
    const boldString = <b>{isDNIFront ? "frente" : "dorso"}</b>;

    return (
      <>
        <IconBadge
          backgroundColor={isCompleted ? "var(--green600)" : whiteColor}
        >
          {getIcon()}
        </IconBadge>
        {isCompleted ? (
          <Text variant={TextVariants.RegularText} color={whiteColor}>
            Confirmá que todo el {boldString} del documento sea visible y nítido
          </Text>
        ) : (
          <Text variant={TextVariants.RegularText} color={whiteColor}>
            Colocá todo el {boldString} de tu DNI dentro del marco de
            referencia.
          </Text>
        )}
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={containerStyles} />
      <div className={disclaimerStyles}>{getTextLabel()}</div>
    </div>
  );
};

export default CameraVisor;
