import { useEffect, useState } from "react";

export const useTimeLeft = (seconds: number) => {
  const [timeLeft, setTimeLeft] = useState(seconds);

  const isTimeCompleted = timeLeft === 0;

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [timeLeft, setTimeLeft]);

  return { timeLeft, isTimeCompleted, setTimeLeft };
};
