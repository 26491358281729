import React, { useEffect, useState } from "react";

import { LogoCocos } from "assets/logos/cocos";
import { useAuth } from "context/AuthProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import { getEnvironment } from "utils";
import Toast, { ToastVariants } from "components/common/Toast";
import { useBiometry } from "hooks/useBiometry";
import { Capacitor } from "@capacitor/core";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Validate2FAAuth from "./Validate2FA";
import PhoneSignUp from "./PhoneSignUp";
import SignIn from "./SignIn";

import styles from "./styles.module.scss";

const LoginPage: React.FC = () => {
  const {
    pending2FA,
    isAuthenticated,
    pendingPhoneSignUp,
    signInWithBiometrics,
  } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { biometryType } = useBiometry();
  const logout = searchParams.get("logout");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showTrustedDeviceQuestion = useAppSelector(
    (state: RootState) => state.user.showTrustedDeviceQuestion
  );
  const user = useAppSelector(getLoggedUser);
  const { biometricsSignIn } = useBiometry();

  const { isProd, environment } = getEnvironment();
  const [isBannerOpen, setIsBannerOpen] = useState<boolean>(!isProd);

  const redirectAfterLogin = async () => {
    if (
      Capacitor.isNativePlatform() &&
      (await biometricsSignIn.isEnabled()) === null
    ) {
      navigate(mainRoutes.enableBiometry);
      return;
    }

    if (user?.phone_confirmed || !user?.hasAccount)
      return navigate(mainRoutes.home);

    return navigate(mainRoutes.phoneValidation);
  };

  useEffect(() => {
    dispatch(setCurrentScreen(screenNames.login));
    document.documentElement.classList.add("newStyles");

    return () => {
      document.documentElement.classList.remove("newStyles");
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && showTrustedDeviceQuestion !== null) {
      if (showTrustedDeviceQuestion) {
        return navigate(mainRoutes.trustedDevice);
      }

      redirectAfterLogin();
    }
  }, [user]);

  useEffect(() => {
    if (biometryType) {
      setSearchParams({});
      if (!logout) {
        signInWithBiometrics();
      }
    }
  }, [biometryType]);

  const isShowingSignIn = !pending2FA && !pendingPhoneSignUp;

  const isShowing2FA = pending2FA && !pendingPhoneSignUp;

  return (
    <div className={styles.loginWrapper}>
      {isBannerOpen && (
        <Toast
          message={`¡Cuidado! Actualmente estás trabajando en ${environment}`}
          variant={ToastVariants.Warning}
          onClose={() => setIsBannerOpen(false)}
        />
      )}
      {isShowingSignIn && (
        <>
          <div className={styles.icon}>
            <LogoCocos size={110} className={styles.logo} />
          </div>
          <SignIn />
        </>
      )}
      {isShowing2FA && (
        <div className={styles.TFAWrapper}>
          <Validate2FAAuth />
        </div>
      )}
      {pendingPhoneSignUp && <PhoneSignUp />}
    </div>
  );
};

export default LoginPage;
