// import CocosGoldBanner from "components/common/CocosGoldBanner";
import ProfileCard from "components/page-profile/components/ProfileCard";
import ProfileRow from "components/page-profile/components/ProfileRow";
import { useProfile } from "context/ProfileProvider";
import { ProfileSection } from "interfaces/profile";

import styles from "./styles.module.scss";

interface ProfileMenuProps {
  options: ProfileSection[];
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ options }) => {
  const { onClickSection, selectedSection } = useProfile();
  return (
    <div className={styles.profileMenuContainer}>
      <ProfileCard />
      <div className={styles.list}>
        {options.map((section) => {
          const { icon, title } = section;
          return (
            <ProfileRow
              key={title}
              selected={selectedSection.title === title}
              icon={icon}
              title={title}
              onClick={() => onClickSection(section)}
            />
          );
        })}
      </div>
      {/* <CocosGoldBanner /> */}
    </div>
  );
};

export default ProfileMenu;
