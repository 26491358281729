import { FlowAnalysis, InvestmentSuggestedResponse } from "interfaces/markets";

import BondTicketBottom from "./components/ticket-bottom";
import BondTicketTop from "./components/ticket-top";

import styles from "./styles.module.scss";

interface InvestmentBondDataProps {
  instrument: InvestmentSuggestedResponse;
  analysis: FlowAnalysis;
}

const InvestmentBondData: React.FC<InvestmentBondDataProps> = ({
  instrument,
  analysis,
}) => {
  return (
    <div className={styles.bondData}>
      <BondTicketTop instrument={instrument} />
      <div className={styles.divisor}>
        <span className={styles.leftSphere} />
        <span className={styles.rightSphere} />
      </div>
      <BondTicketBottom analysis={analysis} />
    </div>
  );
};

export default InvestmentBondData;
