import { useEffect, useState } from "react";

import { InvestmentSuggestedResponse } from "interfaces/markets";
import { useAppSelector } from "hooks/redux";
import { Spinner } from "components/common/spinner";

import InvestmentBondData from "./components/bond-data";
import InvestmentBondFlow from "./components/bond-flow";

import styles from "./styles.module.scss";

interface investmentResumeProps {
  instrument: InvestmentSuggestedResponse;
}
const BidResume: React.FC<investmentResumeProps> = ({ instrument }) => {
  const analysis = useAppSelector((state) => state.calculator.analisis);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (analysis.quantity != 0) setLoading(false);
  }, [analysis]);

  if (loading)
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  return (
    <div className={styles.resumePage}>
      <InvestmentBondData instrument={instrument} analysis={analysis} />
      <InvestmentBondFlow instrument={instrument} analysis={analysis} />
    </div>
  );
};

export default BidResume;
