interface PropTypes {
  size?: number;
  color?: string;
}

export const MepIcon = ({ color, size = 24 }: PropTypes) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14.5 11H13V6H17C17.6 6 18 5.6 18 5C18 4.4 17.6 4 17 4H13V1C13 0.4 12.6 0 12 0C11.4 0 11 0.4 11 1V4H9.5C7 4 5 6 5 8.5C5 11 7 13 9.5 13H11V18H6C5.4 18 5 18.4 5 19C5 19.6 5.4 20 6 20H11V23C11 23.6 11.4 24 12 24C12.6 24 13 23.6 13 23V20H14.5C17 20 19 18 19 15.5C19 13 17 11 14.5 11ZM9.5 11C8.1 11 7 9.9 7 8.5C7 7.1 8.1 6 9.5 6H11V11H9.5ZM13 18H14.5C15.9 18 17 16.9 17 15.5C17 14.1 15.9 13 14.5 13H13V18Z"
        fill={color}
        fillRule="evenodd"
      />
      <mask
        height={size}
        id="mask0_2117_630"
        maskUnits="userSpaceOnUse"
        width="14"
        x="5"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M14.5 11H13V6H17C17.6 6 18 5.6 18 5C18 4.4 17.6 4 17 4H13V1C13 0.4 12.6 0 12 0C11.4 0 11 0.4 11 1V4H9.5C7 4 5 6 5 8.5C5 11 7 13 9.5 13H11V18H6C5.4 18 5 18.4 5 19C5 19.6 5.4 20 6 20H11V23C11 23.6 11.4 24 12 24C12.6 24 13 23.6 13 23V20H14.5C17 20 19 18 19 15.5C19 13 17 11 14.5 11ZM9.5 11C8.1 11 7 9.9 7 8.5C7 7.1 8.1 6 9.5 6H11V11H9.5ZM13 18H14.5C15.9 18 17 16.9 17 15.5C17 14.1 15.9 13 14.5 13H13V18Z"
          fill={color}
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_2117_630)">
        <rect fill={color} height="24" width="24" />
      </g>
    </svg>
  );
};
