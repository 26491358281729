import { CDN_URL } from "constants/index";

import CocosBrand, { CocosBrandVariants } from "assets/icons/CocosBrand";
import px2rem from "utils/px2rem";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface LogoProps {
  isCrypto: boolean;
  ticker: string;
  logo: string;
}

const Logo: React.FC<LogoProps> = ({ isCrypto, logo, ticker }) => {
  const getImageLogo = (img: string) => {
    if (!img) return `${CDN_URL}/logos/ARG.jpg`;

    return `${CDN_URL}/logos/${img}`;
  };

  return (
    <div className={styles.logoContainer}>
      {isCrypto ? (
        <CocosBrand variant={CocosBrandVariants.CRYPTO} size={px2rem(24)} />
      ) : (
        <div className={styles.logo}>
          <img src={getImageLogo(logo)} alt="" />
        </div>
      )}
      <Text
        color="var(--slate900)"
        className={styles.text}
        variant={TextVariants.RegularTextS}
      >
        {ticker}
      </Text>
    </div>
  );
};

export default Logo;
