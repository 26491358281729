import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { useAppState } from "context/AppStateProvider";
import { Currencies } from "interfaces/wallet";

interface PriceProps {
  price?: number;
}

const Price: React.FC<PriceProps> = ({ price }) => {
  const { isBalanceHidden } = useAppState();
  const priceLabelColor = isBalanceHidden
    ? "var(--slate800)"
    : "var(--slate900)";

  if (!price) return <div>--</div>;

  return (
    <PriceLabel
      price={price}
      currency={Currencies.ARS}
      size={PriceLabelSizes.Xsmall}
      color={priceLabelColor}
      isBalanceHidden={isBalanceHidden}
    />
  );
};

export default Price;
