import { useState } from "react";

import CloseAccountModal from "components/common/CloseAccountPage";
import { useProfile } from "context/ProfileProvider";
import { useAppSelector } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Moon, Trash2 } from "lucide-react";
import { RootState } from "store/store";
import { useDarkModeMutation } from "apis/user/mutations/useDarkModeMutation";

import ProfileSectionTemplate from "../components/SectionTemplate";

const ProfileConfig: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  const {
    onClickBreadcrumb,
    breadcrumbList,
    addBreadcrumbOption,
  } = useProfile();
  const darkModeMutation = useDarkModeMutation();
  const dark_theme = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );

  const [darkMode, setDarkMode] = useState<boolean>(dark_theme || false);
  const [showCloseAccount, setShowCloseAccount] = useState<boolean>(false);

  const sectionTitle = "Configuración";

  const deleteAccountTitle = "Eliminar cuenta";

  const showCloseAccountModal =
    breadcrumbList.at(-1) === deleteAccountTitle || showCloseAccount;

  const handleDarkMode = (darkFlag: boolean) => {
    darkModeMutation.mutate(darkFlag);

    darkFlag
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
    setDarkMode(darkFlag);
  };

  const handleOpenModal = () => {
    if (isMobile) {
      setShowCloseAccount(true);
      return;
    }
    addBreadcrumbOption(deleteAccountTitle);
  };

  const handleCloseModal = () => {
    if (isMobile) {
      setShowCloseAccount(false);
      return;
    }
    onClickBreadcrumb(deleteAccountTitle);
  };

  const sectionOptions = [
    {
      icon: Moon,
      label: "Modo oscuro",
      onClick: () => handleDarkMode(!darkMode),
      toggleValue: darkMode,
    },
    {
      icon: Trash2,
      iconColor: "var(--red800)",
      label: "Eliminar cuenta",
      onClick: handleOpenModal,
    },
  ];

  if (showCloseAccountModal)
    return <CloseAccountModal closeModal={handleCloseModal} />;

  return (
    <ProfileSectionTemplate
      title={sectionTitle}
      subtitle={isMobile ? sectionTitle : undefined}
      options={sectionOptions}
      withHeader={isMobile}
    />
  );
};

export default ProfileConfig;
