import { useState, useEffect } from "react";

import { ampli } from "ampli";
import { ThemeVariants } from "interfaces/theme";
import { CardSections } from "interfaces/card/enums";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import mainRoutes from "router/routes";
import CocosCardIntro1 from "components/common/CocosCardIntro1";
import CocosCardIntro2 from "components/common/CocosCardIntro2";
import CocosCardIntro3 from "components/common/CocosCardIntro3";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import Stepper from "components/common/Stepper";
import { useCocosCard } from "context/CocosCardProvider";

import LoadingScreen from "../Loading";

import styles from "./styles.module.scss";

const STEPS_COUNT = 3;
const TRANSITION_TIME = 7000;

const CardIntro = () => {
  const navigate = useNavigate();
  const {
    setSelectedSection,
    isLoading,
    userStatus,
    pomeloData,
  } = useCocosCard();
  const { has2FA } = useSelector((state: RootState) => state.user);

  const [currentStep, setCurrentStep] = useState<number>(1);

  const onClose = () => {
    ampli.cardIntroExit();
    navigate(mainRoutes.home);
  };

  const goToNextStep = () => setCurrentStep(currentStep + 1);

  const goToCardSelection = () => {
    ampli.cardIntroTapGetCard();
    setSelectedSection(CardSections.CARD_SELECTION);
  };

  const goTo2FAConfiguration = () => {
    ampli.card2FaInactive();
    navigate(mainRoutes.cocos2FA, {
      state: { theme: ThemeVariants.Pay, isCocosCard: true },
    });
  };

  const handleOnContinue = () => {
    if (currentStep === STEPS_COUNT) {
      return has2FA ? goToCardSelection() : goTo2FAConfiguration();
    }

    return goToNextStep();
  };

  const navigationHeaderProps = {
    iconColor: "var(--black-to-white)",
    title: "Cocos Card",
    onClick: onClose,
  };

  const getButonText = () =>
    currentStep < STEPS_COUNT ? "Continuar" : "Quiero mi Cocos Card";

  const primaryButtonProps = {
    children: getButonText(),
    onClick: handleOnContinue,
  };

  const getText = () => {
    const text = [
      {
        title: "Revolucioná la manera en que manejás tus finanzas",
        subtitle: "Comprá en todo el mundo, online o en persona.",
        image: <CocosCardIntro1 />,
      },
      {
        title: "Pagá con lo que vos elijas",
        subtitle:
          "Configurá tus métodos de pago de preferencia: pesos, dólares, o FCIs",
        image: <CocosCardIntro2 />,
      },
      {
        title: "Manejá tus finanzas de forma simple en un solo lugar",
        subtitle: "¡Solicitá tu tarjeta sin cargo, y activala desde la app!",
        image: <CocosCardIntro3 />,
      },
    ];

    return text[currentStep - 1];
  };

  useEffect(() => {
    if (pomeloData) {
      setSelectedSection(CardSections.HOME);
    }
  }, [userStatus]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (currentStep < STEPS_COUNT) {
      timer = setTimeout(() => {
        goToNextStep();
      }, TRANSITION_TIME);

      return () => clearTimeout(timer);
    }

    return;
  }, [currentStep]);

  const { title, subtitle, image } = getText();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <BasicWrapper
      navigationHeaderProps={navigationHeaderProps}
      primaryButtonProps={primaryButtonProps}
      className={styles.cardIntroContainer}
      themeVariant={ThemeVariants.Pay}
    >
      <Stepper
        stepsCount={STEPS_COUNT}
        currentStep={currentStep}
        variant={ThemeVariants.Pay}
        withTransition
      />
      <div className={styles.content}>
        <div className={styles.imageContainer}>{image}</div>
        <div className={styles.textWrapper}>
          <Heading
            variant={HeadingVariants.RegularTitle}
            color="var(--slate900)"
            component="h1"
          >
            {title}
          </Heading>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            {subtitle}
          </Text>
        </div>
      </div>
    </BasicWrapper>
  );
};

export default CardIntro;
