import Text, { TextVariants } from "components/common/Text";
import { ChevronDown, ChevronsUpDown, ChevronUp } from "lucide-react";
import px2rem from "utils/px2rem";
import classNames from "classnames";

import { SortingMethod, SortingMethods } from "..";

import styles from "./styles.module.scss";

interface InstrumentsTableHeaderProps {
  activeSortingMethod: SortingMethod;
  setSortingMethod: (sortingMethod: SortingMethod) => void;
}

const InstrumentsTableHeader: React.FC<InstrumentsTableHeaderProps> = ({
  setSortingMethod,
  activeSortingMethod,
}) => {
  const TABLE_HEADER = [
    { label: "Especie" },
    { label: "Cantidad" },
    { label: "Precio" },
    { label: "Importe", sortingMethod: SortingMethods.DEFAULT },
    { label: "% Portfolio" },
    { label: "Costo" },
    { label: "% Var. (1D)", sortingMethod: SortingMethods.DAYLY_VARIATION },
    { label: "Rdo. (1D)", sortingMethod: SortingMethods.DAYLY_PERFORMANCE },
    { label: "% Variación", sortingMethod: SortingMethods.VARIATION },
    { label: "Resultado", sortingMethod: SortingMethods.RESULT },
  ];

  const onClickSortingMethod = (sortingMethod?: SortingMethods) => {
    console.log({ sortingMethod, activeSortingMethod });

    if (sortingMethod === undefined) return;

    if (sortingMethod === activeSortingMethod.method) {
      if (activeSortingMethod.isAsc) {
        setSortingMethod({ method: SortingMethods.DEFAULT, isAsc: false });
        return;
      }
      setSortingMethod({ method: sortingMethod, isAsc: true });
      return;
    }

    setSortingMethod({ method: sortingMethod, isAsc: false });
  };

  const getIcon = (sortingMethod?: SortingMethods) => {
    if (activeSortingMethod.method === sortingMethod) {
      if (activeSortingMethod.isAsc) return ChevronUp;
      return ChevronDown;
    }
    return ChevronsUpDown;
  };

  return (
    <div className={styles.instrumentsTableHeader}>
      {TABLE_HEADER.map(({ label, sortingMethod }) => {
        const hasSortingMethod = sortingMethod !== undefined;
        const isActiveSortingMethod =
          activeSortingMethod.method === sortingMethod;
        const textColor = isActiveSortingMethod
          ? "var(--blue800)"
          : "var(--slate800)";

        const Icon = getIcon(sortingMethod);

        return (
          <Text
            key={label}
            color={textColor}
            variant={TextVariants.RegularTextS}
            className={classNames({
              [styles.isSortable]: hasSortingMethod,
              [styles.activeSortingMethod]: isActiveSortingMethod,
            })}
            onClick={() => onClickSortingMethod(sortingMethod)}
          >
            {hasSortingMethod && <Icon size={px2rem(12)} color={textColor} />}{" "}
            {label}
          </Text>
        );
      })}
    </div>
  );
};

export default InstrumentsTableHeader;
