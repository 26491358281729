import { useEffect } from "react";

import IconBadge from "components/common/Badges/IconBadge";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import NavigationHeader from "components/common/NavigationHeader";
import Text, { TextVariants } from "components/common/Text";
import { useCreateAccount } from "context/CreateAccountProvider";
import { Clock } from "lucide-react";
import { onboardingScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackAction, trackScreen } from "utils/amplitude";
import { AnalyticsEvents, trackAnalyticsEvent } from "utils/firebaseAnalytics";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { FbEvents, trackFbEvent } from "utils/fbTracking";

import styles from "../styles.module.scss";

const PendingScreen = () => {
  const dispatch = useAppDispatch();
  const { goToHome } = useCreateAccount();

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackAnalyticsEvent(AnalyticsEvents.HOME_EXITO_PROCESO_COMITENTE);
    trackFbEvent(FbEvents.HOME_EXITO_PROCESO_COMITENTE);

    trackScreen(
      onboardingScreenNames.verificationPending,
      previousScreen,
      undefined,
      Projects.ONBOARDING
    );

    dispatch(setCurrentScreen(onboardingScreenNames.verificationPending));
  }, []);

  const onClickBack = () => {
    trackAction(
      `${onboardingScreenNames.verificationPending} - Click Quit`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  const onClickGoToHome = () => {
    trackAction(
      `${onboardingScreenNames.verificationPending} -  Click Home`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  return (
    <div className={styles.finalContainer}>
      <NavigationHeader title="Verificación en proceso" onClick={onClickBack} />
      <div className={styles.content}>
        <IconBadge backgroundColor="var(--yellow300)">
          <Clock color="var(--yellow900)" />
        </IconBadge>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h2"
          color="var(--slate900)"
          className={styles.title}
        >
          ¡Gracias por tu tiempo!
        </Heading>
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate800)"
          className={styles.text}
        >
          Tu solicitud ya fue enviada. Pronto vas a recibir novedades via email.
          Recordá revisar tu casilla de spam.
        </Text>
      </div>

      <div className={styles.buttonContainer}>
        <FilledButton onClick={onClickGoToHome}>Volver al inicio</FilledButton>
      </div>
    </div>
  );
};

export default PendingScreen;
