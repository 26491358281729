import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { AuthMFAVerifyResponse, User } from "@supabase/supabase-js";
import { useBiometry } from "hooks/useBiometry";
import { useCookies } from "react-cookie";
import { socket } from "socket";
import { logOutUser, setLoading } from "features/user/userSlice";
import { loginUser } from "features/user/userActions";
import { RootState } from "store/store";
import { supabase } from "utils/supabase";
import { Device } from "@capacitor/device";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import { trackAction } from "utils/amplitude";
import { Capacitor } from "@capacitor/core";
import API from "apis";
import { factorsService, userService } from "apis/services";
import AuthAPI from "apis/auth";
import secureStorage from "utils/secureStorage";
import { ampli } from "ampli";
import { getLoggedUser } from "store/selectors/user.selector";
import GoogleTagManager from "react-gtm-module";
import { AnalyticsEvents, trackAnalyticsEvent } from "utils/firebaseAnalytics";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { trackFbEvent, FbEvents } from "utils/fbTracking";
import { TokenType } from "interfaces/auth";
import axios from "axios";

interface AuthState {
  user: User | null;
  loading: boolean;
  error: string;
  isAuthenticated: boolean;
  pending2FA: boolean;
  pendingPhoneSignUp: boolean;
}
export interface MfaVerifyParams {
  mfaFactorId: string;
  mfaChallengeId: string;
  mfaCode: string;
}

interface AuthHook extends AuthState {
  clearErrors: () => void;
  signInWithPassword: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  signInWithBiometrics: () => Promise<void>;
  signInVerify2FA: (verifyParams: MfaVerifyParams) => Promise<void>;
  addTrustedDevice: () => Promise<void>;
  verify2FA: (verifyParams: MfaVerifyParams) => Promise<AuthMFAVerifyResponse>;
  verifyPhone: (
    verifyParams: MfaVerifyParams,
    onError: () => void
  ) => Promise<void>;
  verifyUserEmail: (token: string | null, type: TokenType) => Promise<void>;
}

const AuthContext = createContext<AuthHook | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const generateErrorMessage = (): string => {
  return "Tuvimos un problema, intente más tarde.";
};

const useAuthContextValues = (): AuthHook => {
  const isNative = Capacitor.isNativePlatform();
  const userData = useAppSelector(getLoggedUser);
  const stateUserLoading = useAppSelector(
    (state: RootState) => state.user.loading
  );
  const dispatch = useAppDispatch();
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    loading: true,
    error: "",
    isAuthenticated: false,
    pending2FA: false,
    pendingPhoneSignUp: false,
  });
  const { biometricsCredentials } = useBiometry();
  const [, , removeCookie] = useCookies();

  const handleSignOut = async () => {
    try {
      dispatch(logOutUser());
      removeCookie("id_account", { path: "/" });
      socket.disconnect();
      document.documentElement.classList.remove("dark");
    } finally {
      setAuthState({
        loading: false,
        user: null,
        isAuthenticated: false,
        pending2FA: false,
        error: "",
        pendingPhoneSignUp: false,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (!userData && !stateUserLoading) {
        setAuthState((state) => ({ ...state, loading: true }));
        const { data, error } = await supabase.auth.getSession();
        if (error || !data.session) {
          setAuthState((state) => ({ ...state, loading: false }));
          return;
        }

        await loadUserData(data.session.user);
      }
    })();
  }, [userData]);

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error || !data.session) {
        await handleSignOut();
      }
    })();
  }, []);

  const _signIn = async (
    email: string,
    password: string,
    withBiometrics: boolean
  ) => {
    setAuthState({
      user: null,
      isAuthenticated: false,
      pending2FA: false,
      error: "",
      loading: true,
      pendingPhoneSignUp: false,
    });
    const { data, error: signInError } = await supabase.auth.signInWithPassword(
      {
        email: email.toLowerCase(),
        password,
      }
    );

    if (signInError) {
      let errorMessage: string;
      if (signInError.message?.includes("Invalid login credentials")) {
        errorMessage =
          "El correo electrónico o la contraseña ingresados son incorrectos. Por favor, inténtalo de nuevo.";
      } else {
        errorMessage = generateErrorMessage();
        trackAction("signin_error", {
          type: `SignIn with ${
            withBiometrics ? "biometry" : "password"
          } - Error`,
          email,
          error: JSON.stringify(signInError),
        });
      }
      setAuthState((state) => ({
        ...state,
        error: errorMessage,
        loading: false,
      }));
      return;
    }

    if (!data.user.phone) {
      setAuthState((state) => ({
        ...state,
        pendingPhoneSignUp: true,
      }));
    }

    if (!withBiometrics) {
      try {
        await biometricsCredentials.reset(email, password);
      } catch (error: any) {
        trackAction("signin_error", {
          type: "SignIn with password - Error on reset biometry credentials",
          email,
          error: JSON.stringify(error),
        });
      }
    }

    const isTrustedDevice = await isThisATrustedDevice(email);
    if (!isTrustedDevice) {
      setAuthState((state) => ({
        ...state,
        isAuthenticated: false,
        pending2FA: true,
        loading: false,
      }));
    }
  };

  const signInWithPassword = async (email: string, password: string) => {
    await _signIn(email, password, false);
  };

  useEffect(() => {
    if (!isNative || !userData?.hasAccount) return;

    const createDeviceToken = async (token: string) => {
      const { model, platform, osVersion } = await Device.getInfo();

      await API.post(userService.deviceToken, {
        token: token,
        id_user: authState?.user?.id,
        device: model,
        os_version: osVersion,
        platform,
      });
    };

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        PushNotifications.register();
      }
    });

    PushNotifications.addListener("registration", (token: Token) => {
      createDeviceToken(token.value)
        .catch((error) => {
          trackAction("Failed to create device token", { error });
        })
        .finally(() => {
          PushNotifications.removeAllListeners();
        });
    });
  }, [userData?.hasAccount]);

  const loadUserData = async (user: User) => {
    if (!user.phone) {
      setAuthState((state) => ({
        ...state,
        loading: false,
        pendingPhoneSignUp: true,
      }));
      return;
    }

    const {
      data: authAssuranceLevelData,
      error: authAssuranceLevelError,
    } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

    if (authAssuranceLevelError) {
      trackAction("signin_error", {
        type: "Error on getting AAL",
        email: user.email,
        error: JSON.stringify(authAssuranceLevelError),
      });
      setAuthState((state) => ({
        ...state,
        error: generateErrorMessage(),
        loading: false,
        pendingPhoneSignUp: false,
      }));
      return;
    }

    if (authAssuranceLevelData.currentLevel != "aal2") {
      const isTrustedDevice =
        user.email && (await isThisATrustedDevice(user.email));
      if (!isTrustedDevice) {
        setAuthState((state) => ({
          ...state,
          error: "",
          loading: false,
          isAuthenticated: false,
          pending2FA: true,
          pendingPhoneSignUp: false,
        }));
      }
      return;
    }

    dispatch(setLoading(true));

    setAuthState((state) => ({
      ...state,
      error: "",
      loading: false,
      isAuthenticated: true,
      pending2FA: false,
      pendingPhoneSignUp: false,
      user,
    }));

    dispatch(loginUser({ user }));
  };

  const signInWithBiometrics = async () => {
    const credentials = await biometricsCredentials.get();
    if (!credentials) {
      return;
    }
    await _signIn(credentials.username, credentials.password, true);
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    await handleSignOut();
  };

  const signInVerify2FA = async (
    mfaParams: MfaVerifyParams,
    email?: string
  ) => {
    setAuthState((state) => ({ ...state, error: "", loading: true }));

    const { data, error } = await verify2FA(mfaParams);

    if (error) {
      trackAction("signin_error", {
        type: "Error on signInVerify2FA",
        email,
        error: JSON.stringify(error),
      });
      if (mfaParams.mfaFactorId === "device") {
        await secureStorage.remove("trustedDeviceToken");
        setAuthState((state) => ({
          ...state,
          pending2FA: true,
          loading: false,
        }));
        return;
      }
      setAuthState((state) => ({
        ...state,
        pending2FA: true,
        error: error.message,
        loading: false,
      }));
      return;
    }

    await loadUserData(data.user);
  };

  const verifyPhone = async (
    mfaParams: MfaVerifyParams,
    onError: () => void
  ) => {
    setAuthState((state) => ({ ...state, error: "", loading: true }));

    const { data, error } = await verify2FA(mfaParams);

    if (error) {
      setAuthState((state) => ({
        ...state,
        pendingPhoneSignUp: true,
        error: generateErrorMessage(),
        loading: false,
      }));

      onError();
      return;
    }

    ampli.phoneVerificationCodeSuccess({ referral: "signup" });
    ampli.signupSuccess();

    GoogleTagManager.dataLayer({
      dataLayer: { event: "wapp_altas_exito_fin_registro_usuario" },
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.fbq("track", "wapp_altas_exito_fin_registro_usuario");

    trackAnalyticsEvent(AnalyticsEvents.ALTAS_EXITO_FIN_REGISTRO_USUARIO);
    trackFbEvent(FbEvents.ALTAS_EXITO_FIN_REGISTRO_USUARIO);

    await loadUserData(data.user);
  };

  const verify2FA = (
    verifyParams: MfaVerifyParams
  ): Promise<AuthMFAVerifyResponse> => {
    return supabase.auth.mfa.verify({
      factorId: verifyParams.mfaFactorId,
      challengeId: verifyParams.mfaChallengeId,
      code: verifyParams.mfaCode,
    });
  };

  const isThisATrustedDevice = async (email: string) => {
    try {
      const { identifier: deviceId } = await Device.getId();
      if (!(await secureStorage.hasKey("trustedDeviceToken"))) return false;

      const trustedDeviceToken = await secureStorage.get("trustedDeviceToken");
      await signInVerify2FA(
        {
          mfaFactorId: "device",
          mfaChallengeId: deviceId,
          mfaCode: trustedDeviceToken,
        },
        email
      );
      return true;
    } catch (error: any) {
      trackAction("signin_error", {
        type: "Error on isThisATrustedDevice",
        email,
        error: JSON.stringify(error),
      });
      return false;
    }
  };

  const addTrustedDevice = async () => {
    const { identifier: deviceId } = await Device.getId();
    const { data: trustedDeviceToken } = await AuthAPI.post(
      factorsService.devices,
      {
        deviceId,
      }
    );
    await secureStorage.set("trustedDeviceToken", trustedDeviceToken);
  };

  const verifyUserEmail = async (token: string | null, type: TokenType) => {
    const EXPIRATION_LINK_ERROR =
      "El link al que accediste es inválido o expiró.";

    setAuthState((state) => ({
      ...state,
      error: "",
      isAuthenticated: false,
    }));

    try {
      if (!token) {
        ampli.signupEmailValidationError({
          type: "Undefined Token",
          message: "No se envió un token desde el deep link.",
        });

        return setAuthState((state) => ({
          ...state,
          error: "Token inválido, por favor intente de nuevo más tarde.",
        }));
      }

      const res = await AuthAPI.get(`/v2/verify?token=${token}&type=${type}`);

      const { accessToken, refreshToken } = res.data;

      const { error, data } = await supabase.auth.setSession({
        access_token: accessToken,
        refresh_token: refreshToken,
      });

      if (error) {
        ampli.signupEmailValidationError({
          type: "Supabase SDK setSession Error",
          message: `Error al setear una session en supabase. { access_token: ${accessToken}, refresh_token: ${refreshToken} }`,
        });
        throw error;
      }

      if (data.user) {
        await loadUserData(data.user);
        return setAuthState((state) => ({
          ...state,
          user: data.user,
          isAuthenticated: true,
        }));
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        ampli.signupEmailValidationError({
          type: error.response.data.error,
          message: error.response.data.description,
        });
      } else {
        ampli.signupEmailValidationError({
          type: "Ajax Unknown Error",
          message: error as string,
        });
      }

      return setAuthState((state) => ({
        ...state,
        error: EXPIRATION_LINK_ERROR,
      }));
    }
  };

  const clearErrors = () => {
    return setAuthState((state) => ({
      ...state,
      error: "",
    }));
  };

  return {
    ...authState,
    clearErrors,
    signInWithPassword,
    signOut,
    signInWithBiometrics,
    signInVerify2FA,
    addTrustedDevice,
    verify2FA,
    verifyPhone,
    verifyUserEmail,
    loading: authState.loading || stateUserLoading,
  };
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuthContextValues();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthHook => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("[AuthContext] Missing context");
  }
  return context;
};
