import { IconProps } from "assets/icons/BTC";

export const LogoCocos: React.FC<IconProps> = ({ size, ...props }) => {
  const originalWidth = 325;
  const originalHeight = 145;

  const width = size ? size : originalWidth;
  const height = size
    ? (originalHeight / originalWidth) * +size
    : originalHeight;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 325 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.0130154 114.161C-0.0916447 110.42 0.559096 106.697 1.92623 103.214C3.29336 99.7316 5.3487 96.5613 7.96904 93.8936C10.5894 91.2255 13.7208 89.1151 17.1754 87.6885C20.6301 86.2618 24.3369 85.5485 28.0736 85.591C34.5217 85.4002 40.829 87.5034 45.8756 91.5271C50.9225 95.5511 54.382 101.236 55.6405 107.571H39.4717C38.6054 105.313 37.0362 103.393 34.9965 102.096C32.9565 100.799 30.5538 100.194 28.144 100.37C21.7752 100.37 15.8059 105.241 15.8059 114.161C15.8059 123.08 21.8222 128.069 28.144 128.069C30.6883 128.234 33.2103 127.507 35.2773 126.012C37.3444 124.518 38.8274 122.349 39.4717 119.879H55.6405C54.5897 126.386 51.2155 132.289 46.1452 136.492C41.0748 140.695 34.6532 142.912 28.0736 142.73C24.3342 142.783 20.6229 142.076 17.1637 140.653C13.7045 139.23 10.5691 137.12 7.94673 134.45C5.32434 131.78 3.26936 128.606 1.90579 125.118C0.542223 121.631 -0.101642 117.904 0.0130154 114.161Z"
        fill="#113264"
      />
      <path
        d="M59.3076 114.161C59.2844 108.475 60.9491 102.91 64.0902 98.1739C67.231 93.4372 71.707 89.7421 76.9492 87.5577C82.1916 85.3735 87.9636 84.7983 93.533 85.9051C99.1024 87.012 104.218 89.7515 108.229 93.7752C112.241 97.7989 114.968 102.926 116.065 108.505C117.161 114.083 116.577 119.863 114.387 125.108C112.197 130.354 108.499 134.83 103.764 137.968C99.0286 141.105 93.4692 142.763 87.7913 142.73C84.0255 142.807 80.2833 142.121 76.7895 140.712C73.2958 139.303 70.123 137.2 67.4619 134.531C64.801 131.862 62.7066 128.681 61.3054 125.181C59.9038 121.68 59.2245 117.931 59.3076 114.161ZM100.788 114.161C100.788 106.018 95.4526 100.323 87.9556 100.323C80.4589 100.323 75.1005 106.018 75.1005 114.161C75.1005 122.303 80.4352 127.998 87.9556 127.998C95.4761 127.998 100.788 122.35 100.788 114.161Z"
        fill="#113264"
      />
      <path
        d="M121.093 114.161C120.988 110.42 121.639 106.697 123.006 103.214C124.373 99.7316 126.429 96.5613 129.049 93.8936C131.669 91.2255 134.801 89.1151 138.256 87.6885C141.71 86.2618 145.417 85.5482 149.153 85.591C155.602 85.4002 161.909 87.5032 166.956 91.5271C172.002 95.5511 175.462 101.235 176.721 107.571H160.552C159.685 105.313 158.116 103.393 156.076 102.096C154.036 100.799 151.634 100.194 149.224 100.37C142.855 100.37 136.886 105.241 136.886 114.161C136.886 123.08 142.902 128.069 149.224 128.069C151.768 128.234 154.29 127.506 156.357 126.012C158.424 124.518 159.907 122.349 160.552 119.879H176.721C175.67 126.386 172.296 132.289 167.225 136.492C162.155 140.695 155.733 142.912 149.153 142.73C145.414 142.783 141.703 142.076 138.244 140.653C134.784 139.23 131.649 137.12 129.027 134.45C126.404 131.78 124.349 128.606 122.986 125.118C121.622 121.631 120.978 117.904 121.093 114.161Z"
        fill="#113264"
      />
      <path
        d="M180.432 114.161C180.409 108.475 182.074 102.91 185.215 98.1739C188.356 93.4374 192.831 89.7424 198.074 87.5579C203.316 85.3735 209.088 84.7983 214.658 85.9051C220.227 87.0123 225.342 89.7515 229.354 93.7752C233.366 97.7989 236.093 102.926 237.189 108.505C238.285 114.083 237.701 119.863 235.511 125.108C233.321 130.354 229.624 134.83 224.889 137.968C220.153 141.105 214.594 142.763 208.916 142.73C205.15 142.807 201.408 142.121 197.914 140.712C194.42 139.303 191.248 137.2 188.586 134.531C185.925 131.862 183.831 128.681 182.43 125.181C181.028 121.68 180.349 117.931 180.432 114.161ZM221.912 114.161C221.912 106.018 216.577 100.323 209.08 100.323C201.583 100.323 196.249 106.018 196.249 114.161C196.249 122.303 201.56 127.998 209.08 127.998C216.601 127.998 221.912 122.35 221.912 114.161Z"
        fill="#113264"
      />
      <path
        d="M242.334 123.386L257.61 123.503C257.868 127.433 260.453 130.234 266.094 130.234C271.217 130.234 273.144 128.21 273.144 125.362C273.144 122.515 270.16 121.103 263.885 120.279C253.215 118.985 244.308 114.325 244.308 102.958C244.308 92.0625 252.087 85.5909 265.459 85.5909C278.833 85.5909 287.996 92.8393 287.996 103.853L272.416 103.735C272.204 100.723 270.065 98.5109 265.506 98.5109C261.535 98.5109 259.936 100.37 259.936 102.606C259.936 105.618 261.065 106.394 269.619 107.618C281.84 109.383 289.432 113.29 289.432 124.421C289.432 136.988 279.089 142.777 265.929 142.777C249.525 142.777 242.334 133.034 242.334 123.386Z"
        fill="#113264"
      />
      <path
        d="M264.19 65.564C251.406 65.7054 239.632 59.4926 234.25 48.4317C232.343 44.384 231.354 39.964 231.354 35.4885C231.354 31.0127 232.343 26.5928 234.25 22.545C238.122 13.9813 244.9 7.06897 253.38 3.03581L259.631 0L265.718 12.4963L259.467 15.5556C253.947 18.1452 249.519 22.6045 246.964 28.1459C245.912 30.3466 245.355 32.7509 245.33 35.1903C245.306 37.6296 245.816 40.0449 246.823 42.2661C251.312 51.4441 264.261 54.3623 275.706 48.7849L281.956 45.7256L288.045 58.2217L281.793 61.281C276.339 63.9636 270.36 65.4025 264.284 65.4936"
        fill="#113264"
      />
      <path
        d="M298.009 81.7547L291.641 69.3763L297.822 66.1993C309.15 60.3629 314.579 48.2197 309.901 39.1356C305.226 30.0518 292.229 27.3689 280.901 33.2288L274.72 36.4058L268.351 24.0273L274.532 20.8502C292.674 11.4369 314.085 16.8496 322.262 32.7582C330.442 48.6668 322.262 69.2349 304.168 78.5777L298.009 81.7547Z"
        fill="#0D74CE"
      />
    </svg>
  );
};
