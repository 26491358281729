import { useEffect } from "react";

import API from "apis";
import { userService } from "apis/services";
import { useProfile } from "context/ProfileProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { useLocation } from "react-router-dom";
import mainRoutes from "router/routes";

declare global {
  interface Window {
    zE?: any;
    zESettings?: any;
  }
}

interface JWTResponse {
  jwt: string;
}

interface ZendeskChatProps {
  zendeskKey: string;
  defer: boolean;
}

export const ZendeskChat: React.FC<ZendeskChatProps> = ({
  zendeskKey,
  defer,
}) => {
  const location = useLocation();
  const { setZendeskOpen } = useProfile();
  const { isMobile } = useDeviceCheck();

  const retrieveZendeskJWT = (callback: (jwt: string) => void) => {
    API.get<JWTResponse>(userService.zendesk).then((res) => {
      callback(res.data.jwt);
    });
  };

  const showZendeskChat = () => {
    if (
      typeof window === "undefined" ||
      !window.document ||
      !window.document.createElement
    ) {
      return false;
    }
    return (
      (isMobile && window.location.pathname === mainRoutes.chat) ||
      (!isMobile && window.location.pathname === mainRoutes.profile)
    );
  };

  const initializeChat = () => {
    if (window.zE) {
      if (showZendeskChat()) {
        window.zE("messenger", "show");
        window.zE("messenger", "loginUser", retrieveZendeskJWT);
        isMobile && window.zE("messenger", "open");
      } else {
        window.zE("messenger", "hide");
      }
      window.zE("messenger:on", "open", () => {
        setZendeskOpen(true);
      });
      window.zE("messenger:on", "close", () => {
        setZendeskOpen(false);
      });
    }
  };

  const insertScript = (zendeskKey: string, defer?: boolean) => {
    const script = document.createElement("script");
    if (defer) {
      script.defer = true;
    } else {
      script.async = true;
    }
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.addEventListener("load", initializeChat);
    document.body.appendChild(script);
  };

  useEffect(() => {
    insertScript(zendeskKey, defer);
    window.zESettings = {
      ...window.zESettings,
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: retrieveZendeskJWT,
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    initializeChat();

    return () => {
      if (window.zE) {
        window.zE("messenger", "hide");
        window.zE("messenger", "logoutUser");
      }
    };
  }, [location]);

  return null;
};
