import { CircularProgress, LinearProgress, Typography } from "@mui/material";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import LoadingSpinner from "components/common/LoadingSpinner";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { UIBox } from "components/common/box";
import ErrorContainer from "components/common/error-modal";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import {
  InvestorProfileResponse,
  InvestorTestResponse,
} from "interfaces/api-responses";
import { ErrorMessage } from "interfaces/errors";
import { InvestorTypes } from "interfaces/user";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import {
  aggressiveInvestorProfile,
  conservativeInvestorProfile,
  moderateInvestorProfile,
} from "utils/constants";

import "./index.scss";

interface InvestorTestContainerProps {
  step: number;
  onClickAnswer: (
    id_question: string,
    id_option: string,
    isFinalAnswer: boolean
  ) => void;
  onClickStart: () => void;
  onClickRestart: () => void;
  loadingProfile: boolean;
  investorProfile?: InvestorProfileResponse;
  errorProfile: ErrorMessage;
  testQuestions?: InvestorTestResponse[];
  loadingQuestions: boolean;
}

const InvestorTestContainer: React.FC<InvestorTestContainerProps> = ({
  step,
  testQuestions,
  loadingQuestions,
  loadingProfile,
  errorProfile,
  investorProfile,
  onClickAnswer,
  onClickRestart,
  onClickStart,
}) => {
  const navigate = useNavigate();
  const { isDesktopOrTablet } = useDeviceCheck();

  const handleRedirectToMarket = () => {
    navigate(mainRoutes.market);
  };
  const handleClickAnswer = (
    id_question: string,
    id_option: string,
    isFinalAnswer: boolean
  ) => () => {
    onClickAnswer(id_question, id_option, isFinalAnswer);
  };

  if (errorProfile.message && isDesktopOrTablet)
    return (
      <div className="investor-test-container error">
        <ErrorContainer
          textPrimaryButton="Realizar test de nuevo"
          title="Ups...Algo salió mal"
          onClickPrimaryButton={onClickRestart}
        />
      </div>
    );

  const productAccordingProfile = (investorType: string) => {
    if (
      investorType.toLowerCase() === InvestorTypes.CONSERVADOR.toLowerCase()
    ) {
      return conservativeInvestorProfile?.map((product: string) => (
        <li key={product}>{product}</li>
      ));
    } else if (
      investorType.toLowerCase() === InvestorTypes.MODERADO.toLowerCase()
    ) {
      return moderateInvestorProfile?.map((product: string) => (
        <li key={product}>{product}</li>
      ));
    } else if (
      investorType.toLowerCase() === InvestorTypes.AGRESIVO.toLowerCase()
    ) {
      return aggressiveInvestorProfile?.map((product: string) => (
        <li key={product}>{product}</li>
      ));
    }
  };

  return (
    <div
      className={`investor-test-container step-${step} ${
        testQuestions && step === testQuestions?.length + 1 ? "step-final" : ""
      }`}
    >
      {step === 0 && (
        <div className="step-0">
          <Heading
            variant={HeadingVariants.RegularTitle}
            color="var(--slate900)"
            component="h1"
          >
            ¿Qué tipo de inversor creés que sos?
          </Heading>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Contestá las siguiente preguntas, conocé que tipo de inversor sos y
            cual es el tipo de inversión adecuada para vos.
          </Text>
          <FilledButton onClick={onClickStart}>Completá el test</FilledButton>
        </div>
      )}
      {testQuestions && step > 0 && step <= testQuestions.length && (
        <div className={`step-content`}>
          {loadingQuestions || !testQuestions ? (
            <div>
              <CircularProgress size={30} />
            </div>
          ) : (
            <div>
              <LinearProgress
                value={(100 / testQuestions.length) * step}
                variant="determinate"
              />
              <Typography component="p" variant="heading4">
                {testQuestions[step - 1].label}
              </Typography>
              <div className="answers-container">
                {testQuestions[step - 1].options.map((option, index) => (
                  <UIBox
                    key={index}
                    onClick={handleClickAnswer(
                      testQuestions[step - 1].id_question,
                      option.id_option,
                      step === testQuestions.length
                    )}
                  >
                    <Typography variant="textS">{option.label}</Typography>
                  </UIBox>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {testQuestions &&
        step === testQuestions?.length + 1 &&
        (loadingProfile ? (
          <div className="loader">
            <LoadingSpinner size={200} />
          </div>
        ) : (
          <div className="step-final">
            <Heading
              variant={HeadingVariants.RegularTitle}
              color="var(--slate900)"
              component="h1"
            >
              Eres un inversor {investorProfile?.profile}
            </Heading>

            <Text
              className="subtitle"
              variant={TextVariants.RegularTextS}
              color="var(--slate800)"
            >
              Productos sugeridos que puede operar:
            </Text>
            <UIBox className="suggested-products">
              <ul>
                {productAccordingProfile(
                  investorProfile?.profile ?? "CONSERVADOR"
                )}
              </ul>
            </UIBox>
            <div className="button-container">
              <FilledButton onClick={handleRedirectToMarket}>
                Comenzar a operar
              </FilledButton>
              <OutlinedButton onClick={onClickRestart}>
                Realizar test de nuevo
              </OutlinedButton>
            </div>
          </div>
        ))}
    </div>
  );
};

export default InvestorTestContainer;
