import { useEffect, useState } from "react";

import AuthAPI from "apis/auth";
import { ThemeVariants } from "interfaces/theme";
import ConfirmCodePage from "components/common/ConfirmCode";
import LoadingSpinner from "components/common/LoadingSpinner";
import { useAuth } from "context/AuthProvider";
import { MFAErrorType, MFAType } from "interfaces/auth";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { supabase } from "utils/supabase";
import { factorsService } from "apis/services";
import ErrorPage from "components/common/ErrorPage";
import { trackAction } from "utils/amplitude";

import styles from "./styles.module.scss";

interface FactorData {
  id: string;
  type: MFAType;
}

const Validate2FAAuth: React.FC = () => {
  const { signInVerify2FA, error } = useAuth();
  const navigate = useNavigate();

  const [factor, setFactor] = useState<FactorData | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      await AuthAPI.get(factorsService.default)
        .then(({ data }) => {
          const factorData: FactorData = {
            id: data.id,
            type: data.factor_type,
          };
          setFactor(factorData);
        })
        .catch((error) => {
          supabase.auth.getSession().then((sessionResponse) => {
            const { data: sessionData, error: sessionError } = sessionResponse;
            if (error) {
              trackAction("signin_error", {
                type:
                  "Error on get session into Validate2FAAuth - get default factor",
                email: "UNKNOWN",
                sessionError,
                error: JSON.stringify(error),
              });
            } else {
              trackAction("signin_error", {
                type:
                  "Error on get session into Validate2FAAuth - get default factor",
                email: sessionData.session?.user.email,
                error: JSON.stringify(error),
              });
            }
          });
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!factor) {
        return;
      }

      const { error } = await supabase.auth.mfa.challenge({
        factorId: factor.id,
      });
      if (error) {
        supabase.auth.getSession().then((sessionResponse) => {
          const { data: sessionData, error: sessionError } = sessionResponse;
          if (sessionError) {
            trackAction("signin_error", {
              type: "Error on get session into Validate2FAAuth - MFA.challenge",
              email: "UNKNOWN",
              sessionError: JSON.stringify(sessionError),
              error: JSON.stringify(error),
            });
          } else {
            trackAction("signin_error", {
              type: "Error on get session into Validate2FAAuth - MFA.challenge",
              email: sessionData.session?.user.email,
              error: JSON.stringify(error),
            });
          }
        });

        let errorMessage = "No podemos mostrar esta página en este momento.";
        if (error.message === "over_email_send_rate_limit") {
          errorMessage =
            "Ocurrió un error al intentar enviar el mail con el código para iniciar sesión. Por favor, esperá un minuto y volvé a intentar.";
        }
        setErrorMessage(errorMessage);
        return;
      }
      setLoading(false);
    })();
  }, [factor]);

  const handleVerify = async (mfaCode: string) => {
    if (!factor) return true;

    setLoading(true);

    const { id: mfaFactorId } = factor;

    await signInVerify2FA({
      mfaFactorId,
      mfaChallengeId: "_",
      mfaCode,
    });

    setLoading(false);
    return false;
  };

  if (loading || !factor) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner size={100} variant={ThemeVariants.Capital} />
      </div>
    );
  }

  if (errorMessage) {
    return <ErrorPage errorMessage={errorMessage} />;
  }

  const mfaError = Object.keys(MFAErrorType).includes(error)
    ? (error as MFAErrorType)
    : undefined;

  return (
    <ConfirmCodePage
      onClickContinue={handleVerify}
      onClickBack={() => navigate(mainRoutes.logout)}
      factorType={factor.type}
      variant={ThemeVariants.Capital}
      error={mfaError}
    />
  );
};

export default Validate2FAAuth;
