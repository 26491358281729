import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { useAppState } from "context/AppStateProvider";
import { Currencies } from "interfaces/wallet";

interface TotalPerformanceProps {
  totalPerformance?: number;
}

const TotalPerformance: React.FC<TotalPerformanceProps> = ({
  totalPerformance,
}) => {
  const { isBalanceHidden } = useAppState();

  const getPriceLabelColor = () => {
    if (isBalanceHidden) return "var(--slate800)";

    if (totalPerformance && totalPerformance < 0) return "var(--red800)";

    return "var(--green800)";
  };

  const priceLabelColor = getPriceLabelColor();

  if (!totalPerformance) return <div>--</div>;

  return (
    <PriceLabel
      price={totalPerformance}
      currency={Currencies.ARS}
      color={priceLabelColor}
      symbolColor={priceLabelColor}
      size={PriceLabelSizes.Xsmall}
      isBalanceHidden={isBalanceHidden}
    />
  );
};

export default TotalPerformance;
