import { useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import {
  CameraPreview,
  CameraSampleOptions,
} from "@capacitor-community/camera-preview";
import { Filesystem, Directory } from "@capacitor/filesystem";
import {
  BarcodeScanner,
  BarcodeFormat,
} from "@capacitor-mlkit/barcode-scanning";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackScreen, trackAction } from "utils/amplitude";
import { trackAnalyticsEvent, AnalyticsEvents } from "utils/firebaseAnalytics";
import { trackFbEvent, FbEvents } from "utils/fbTracking";
import { onboardingScreenNames } from "router/routes";
import { useCreateAccount } from "context/CreateAccountProvider";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";
import NavigationHeader from "components/common/NavigationHeader";
import Stepper from "components/common/Stepper";
import isDNIQrCodeValid from "components/page-create-account/utils/isDNIQrCodeValid";
import * as Sentry from "@sentry/react";
import { cropImageForScanDni } from "components/page-create-account/utils/processImage";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Rejected from "../Final/Rejected";
import CameraVisor from "../../CameraVisor";
import CameraButton from "../../CameraButton";
import { BASE64_PREFIX } from "../../../constants";

import styles from "./styles.module.scss";

const DNIFront = () => {
  const {
    goToHome,
    goToNextStep,
    setDniCode,
    setDniFrontImage,
    setIsLoading,
    isLoading,
    error,
  } = useCreateAccount();
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<string>("");

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackAnalyticsEvent(AnalyticsEvents.HOME_EXITO_PASO1_PROCESO_COMITENTE);
    trackFbEvent(FbEvents.HOME_EXITO_PASO1_PROCESO_COMITENTE);

    trackScreen(
      onboardingScreenNames.scanIdFront,
      previousScreen,
      undefined,
      Projects.ONBOARDING
    );

    dispatch(setCurrentScreen(onboardingScreenNames.scanIdFront));
  }, []);

  useEffect(() => {
    if (!image) {
      startCamera();
    }
  }, [image]);

  const startCamera = async () => {
    try {
      await CameraPreview.start({
        parent: "cameraPreview",
        toBack: true,
        storeToFile: false,
        disableAudio: true,
        enableOpacity: true,
        enableHighResolution: true,
        width: window.screen.width,
        height: window.screen.height,
        rotateWhenOrientationChanged: false,
        lockAndroidOrientation: true,
      });
    } catch (e: any) {
      Sentry.captureException(e, {
        tags: {
          page: Projects.ONBOARDING,
          code: "CAMERA_START_ERROR",
        },
      });
    }
  };

  const cameraPreviewPictureOptions: CameraSampleOptions = {
    quality: 100,
  };

  const takePhoto = async () => {
    trackAction(
      `${onboardingScreenNames.scanIdFront} - Click Snapshot`,
      undefined,
      Projects.ONBOARDING
    );

    try {
      const result = await CameraPreview.capture(cameraPreviewPictureOptions);

      setImage(`${BASE64_PREFIX}${result.value}`);
      await CameraPreview.stop();
    } catch (e: any) {
      Sentry.captureException(e, {
        tags: {
          page: Projects.ONBOARDING,
          code: "CAMERA_CAPTURE_ERROR",
        },
      });
    }
  };

  const takePhotoAgain = () => {
    trackAction(
      `${onboardingScreenNames.scanIdFront} - Click Repeat`,
      undefined,
      Projects.ONBOARDING
    );

    setImage("");
  };

  const scanImage = async () => {
    const imagePath = `${uuidv4()}-dni.jpg`;
    setDniFrontImage(image);
    setIsLoading(true);

    trackAction(
      `${onboardingScreenNames.scanIdFront} - Click Confirm`,
      undefined,
      Projects.ONBOARDING
    );

    try {
      const cropFrontDNI = await cropImageForScanDni(image);

      const file = await Filesystem.writeFile({
        path: imagePath,
        data: cropFrontDNI,
        directory: Directory.Documents,
      });

      const { barcodes } = await BarcodeScanner.readBarcodesFromImage({
        formats: [BarcodeFormat.Pdf417],
        path: file.uri,
      });

      if (isDNIQrCodeValid(barcodes)) {
        setDniCode(barcodes[0].rawValue);
      }
    } catch (e: any) {
      // Do not break. Proceed with manual transcription in case of an error
      Sentry.captureException(e, {
        tags: {
          page: Projects.ONBOARDING,
          code: "SCANNING_DNI_BARCODE_ERROR",
        },
      });
    }

    goToNextStep();
    setIsLoading(false);
  };

  const onClose = async () => {
    if (!image) {
      try {
        await CameraPreview.stop();
      } catch (e: any) {
        Sentry.captureException(e, {
          tags: {
            page: Projects.ONBOARDING,
            code: "CAMERA_CLOSE_ERROR",
          },
        });
      }
    }

    trackAction(
      `${onboardingScreenNames.scanIdFront} - Click Quit`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  if (error) {
    return <Rejected isErrorState />;
  }

  return (
    <div id="cameraPreview" className={styles.cameraContainer}>
      {image && (
        <img src={image} alt="Taken photo" className={styles.imagePreview} />
      )}
      <div className={styles.dniFrontContainer}>
        <div className={styles.content}>
          <NavigationHeader
            title="Frente DNI"
            onClick={onClose}
            iconColor="#fff"
            titleColor="#fff"
          />
          <Stepper stepsCount={4} currentStep={1} />

          <CameraVisor isDNIFront isCompleted={!!image} />

          {image ? (
            <div className={styles.buttonContainer}>
              <OutlinedButton onClick={takePhotoAgain}>
                Volver a tomar
              </OutlinedButton>
              <FilledButton onClick={scanImage} isLoading={isLoading}>
                Confirmar foto
              </FilledButton>
            </div>
          ) : (
            <div className={styles.cameraButton}>
              <CameraButton onClick={takePhoto} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DNIFront;
