import {
  getTimeData,
  PortfolioTabs,
  SettlementIndex,
  PORTFOLIO_CHART_PERIOD,
} from "constants/portfolio";

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { useBalanceQuery } from "apis/portfolio/queries/useBalance";
import AvailableLabel from "components/common/AvailableLabel";
import Variation from "components/common/CurrentVariation";
import type { ChartDataPoint } from "components/common/LineChart/NewLineChart";
import { useAppState } from "context/AppStateProvider";
import { PortfolioScreen, usePortfolio } from "context/PortfolioProvider";
import dayjs from "dayjs";
import { ThemeVariants } from "interfaces/theme";
import Skeleton from "react-loading-skeleton";
import { usePortfolioQuery } from "apis/portfolio/queries/usePortfolioQuery";
import { PortfolioFromType } from "interfaces/wallet";
import { MIN_HOLDING_CRYPTO } from "components/page-cocos-portfolio/utils";

import ValueChartSection from "../common/ChartSection";
import InstrumentsBalanceCards from "../common/InstrumentsBalanceCards";
import PortfolioCurrencySwitch from "../common/CurrencySwtich";
import CashBalanceCards from "./components/CashBalanceCards";
import PortfolioSkeletonLoader from "../common/PortfolioSkeletonLoader";

import styles from "./styles.module.scss";

interface ValueTabsProps {
  variant?: ThemeVariants;
}

const ValueTab = ({ variant = ThemeVariants.Capital }: ValueTabsProps) => {
  const { isBalanceHidden } = useAppState();
  const {
    selectedPeriod,
    selectedCurrency,
    setSelectedPeriod,
    setPortfolioScreen,
  } = usePortfolio();

  const isCapital = variant === ThemeVariants.Capital;

  const portfolioFrom = isCapital
    ? PortfolioFromType.BROKER
    : PortfolioFromType.CRYPTO;

  const {
    data: portfolio,
    isError: isPorfolioError,
    isLoading: isPortfolioLoading,
  } = usePortfolioQuery(selectedCurrency, portfolioFrom);

  const {
    data: portfolioBalance,
    isLoading: isBalanceLoading,
    isError: isBalanceError,
  } = useBalanceQuery({
    currency: selectedCurrency,
    period: selectedPeriod,
  });

  const { variationText, timeOption } = getTimeData(selectedPeriod);

  const initialChartData = {
    balance: portfolioBalance?.totalBalance,
    percentage: portfolioBalance?.variation.percentage || 0,
    amount: portfolioBalance?.variation.amount,
    date: variationText,
  };

  const [chartData, setChartData] = useState(initialChartData);

  const availableBalance = chartData.balance || portfolioBalance?.totalBalance;
  const variationAmount =
    chartData.amount ?? portfolioBalance?.variation.amount;
  const variationPercentage =
    chartData.percentage ?? portfolioBalance?.variation.percentage;

  const onChangePortfolio = (data?: ChartDataPoint) => {
    const date = data ? dayjs(data.date).format("DD-MM-YYYY") : "";

    if (!data?.variation) {
      return setChartData(initialChartData);
    }

    setChartData({
      balance: data.value,
      percentage: data.variation.percentage,
      amount: data.variation.amount,
      date,
    });
  };

  const handleOnChangeTime = (value: number) => {
    ampli.portfolioChartRangeSwitched({
      tab: PortfolioTabs.VALUE,
      range: PORTFOLIO_CHART_PERIOD[value],
    });
    setSelectedPeriod(PORTFOLIO_CHART_PERIOD[value]);
  };

  useEffect(() => {
    setChartData({
      balance: portfolioBalance?.totalBalance,
      percentage: portfolioBalance?.variation.percentage || 0,
      amount: portfolioBalance?.variation.amount,
      date: variationText,
    });
  }, [portfolioBalance]);

  useEffect(() => {
    if (isPorfolioError || isBalanceError) {
      setPortfolioScreen(PortfolioScreen.PORTFOLIO_ERROR);
    }
  }, [isPorfolioError, isBalanceError]);

  if (isPortfolioLoading || !portfolio) return <PortfolioSkeletonLoader />;

  // El endpoint devuelve siempre el instrument de crypto aunque tenga 0
  // por eso filtramos para los instrumentos que el usuario tenga tenencia mayor a 0
  const holdings = isCapital
    ? portfolio.holdings.filter(
        ({ settlements, toBeSettled }) =>
          settlements[SettlementIndex.INF].amount > 0 || toBeSettled > 0
      )
    : portfolio.holdings.filter(
        ({ settlements, isCrypto }) =>
          !isCrypto ||
          settlements[SettlementIndex.INF].quantity > MIN_HOLDING_CRYPTO
      );

  return (
    <div className={styles.desktopWrapper}>
      <div>
        <PortfolioCurrencySwitch variant={variant} />
        {isBalanceLoading ? (
          <>
            <Skeleton className={styles.balanceLoader} />
            <Skeleton className={styles.variationLoader} />
          </>
        ) : (
          <>
            <AvailableLabel
              currency={selectedCurrency}
              available={availableBalance ?? 0}
              variant={variant}
            />
            <Variation
              value={variationPercentage || 0}
              amount={variationAmount}
              currency={selectedCurrency}
              text={chartData.date}
              hideBalances={isBalanceHidden}
            />
          </>
        )}
        <div className={styles.chartWrapper}>
          <ValueChartSection
            selectedTime={timeOption}
            handleOnChangeTime={handleOnChangeTime}
            onChangePortfolio={onChangePortfolio}
            variant={variant}
          />
        </div>
      </div>
      <div>
        <CashBalanceCards cashHoldings={portfolio.cash} />
        <InstrumentsBalanceCards
          holdings={holdings}
          selectedCurrency={selectedCurrency}
          variant={variant}
        />
      </div>
    </div>
  );
};

export default ValueTab;
