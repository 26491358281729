import { CocosTariffURL } from "constants/index";

import { useEffect, useState } from "react";

import { InvestmentSuggestedResponse } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { updateOrderOptions } from "features/markets/marketsSlice";
import { RootState } from "store/store";
import { UIChipSelector } from "components/common/controls/chip-selector";
import { UIPriceInputV2 } from "components/common/price-input";
import { getCurrencyFormatted, getSettlementByTicker } from "utils";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface investmentDataProps {
  instrument: InvestmentSuggestedResponse;
  onOperationAble: (val: boolean) => void;
}

const BidDetailOperation: React.FC<investmentDataProps> = ({
  instrument,
  onOperationAble,
}) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const wallet = useAppSelector((state: RootState) => state.wallet.wallet);

  const geBuyingPower = () => {
    const walletTerm = wallet?.[term];

    if (order.currency === Currencies.ARS) {
      return +(walletTerm?.ars ?? 0);
    }

    return +(walletTerm?.usd ?? 0);
  };

  const term = instrument && getSettlementByTicker(instrument.long_ticker);
  const buyingPower = geBuyingPower();
  const [selectedChip, setSelectedChip] = useState<number | null>(null);
  const chipsPercentages = [0.25, 0.5, 0.75, 1];

  const chipsOptions = chipsPercentages.map((percentage) => ({
    value: Math.floor(buyingPower * percentage),
    label: `${percentage * 100}%`,
  }));

  useEffect(() => {
    if (
      order.amount &&
      order.amount >= instrument.monto_minimo &&
      order.amount <= buyingPower
    ) {
      onOperationAble(true);
    } else {
      onOperationAble(false);
    }
  }, [buyingPower]);

  const handleClickChip = (option: number) => {
    if (selectedChip === option) {
      setSelectedChip(null);
    } else {
      setSelectedChip(option);
    }
    handleChangePrice(option);
  };

  const changeInputPrice = (value: number) => {
    if (selectedChip && selectedChip !== value) {
      setSelectedChip(null);
    }
    handleChangePrice(value);
  };
  const handleChangePrice = (value: number) => {
    const quantity =
      Math.floor(
        (Math.floor(value / (instrument.price / 100)) -
          instrument.lamina_minima) /
          instrument.lamina_incremental
      ) *
        instrument.lamina_incremental +
      instrument.lamina_minima;
    dispatch(
      updateOrderOptions({
        ...order,
        amount: value,
        quantity: quantity,
      })
    );
    if (value >= instrument.monto_minimo && value <= buyingPower) {
      onOperationAble(true);
      return;
    }
    onOperationAble(false);
  };

  return (
    <div className={styles.detailOperation}>
      <Text
        variant={TextVariants.RegularText}
        className={styles.investmentAmount}
      >
        ¿Qué monto querés invertir?
      </Text>
      <UIPriceInputV2
        key="amount"
        value={order.amount}
        currency={order.currency || Currencies.ARS}
        handleChangeValue={changeInputPrice}
        disabled={false}
      />
      <Text variant={TextVariants.RegularText} className={styles.available}>
        Disponible&nbsp;
        {getCurrencyFormatted(order.currency || Currencies.ARS, buyingPower)}
      </Text>
      {buyingPower >= 5 && (
        <div className={styles.priceChips}>
          {chipsOptions.map((option) => (
            <UIChipSelector
              isSelected={option.value === selectedChip}
              key={option.value}
              title={option.label}
              onClick={() => handleClickChip(option.value)}
            />
          ))}
        </div>
      )}
      <Text variant={TextVariants.RegularText} className={styles.infoMessage}>
        Esta operación tiene comisiones según el{" "}
        <a href={CocosTariffURL} target="_blank" rel="noreferrer">
          tarifario vigente.
        </a>
      </Text>
    </div>
  );
};

export default BidDetailOperation;
