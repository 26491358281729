import { getListMenuHome } from "constants/capitalMenu";

import { useEffect, useState } from "react";

import { useMovements } from "context/MovementsProvider";
import { MovementsTypes } from "interfaces/movements";
import { Currencies } from "interfaces/wallet";
import CommonLayout from "layouts/common";
import DrawerLayout from "layouts/drawer";
import { openRightModal } from "features/rightModal/rightModalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import {
  OverlappedButtonsTypes,
  UIOverlappedButtons,
} from "components/common/overlapped-buttons";
import { trackAction } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

import MovementsFilter from "../movements-common/movements-filter";
import MovementsInstrument from "../movements-common/movements-instrument";
import MovementsMonetary from "../movements-common/movements-monetary";

import styles from "./styles.module.scss";

const MovementsDesktop = () => {
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();
  const currentScreen = useAppSelector(getCurrentScreen);
  const movementTypes = [
    {
      label: "Monetarios",
      value: MovementsTypes.MONETARY,
    },
    {
      label: "Instrumentos",
      value: MovementsTypes.INSTRUMENT,
    },
  ];

  const {
    section,
    setSection,
    cashMovementsData,
    handleClearFilters,
    tickersMovementsData,
    getCashMovementsData,
    getTickerMovementsData,
  } = useMovements();

  const [filter, setFilter] = useState<boolean>(false);

  const handleSection = (value: string) => {
    handleClearFilters();
    setSection(value);
    if (value === MovementsTypes.MONETARY) {
      getCashMovementsData(Currencies.ARS);
      return;
    }
    getTickerMovementsData();
  };

  const handleFilter = (value: boolean) => {
    trackAction(`${currentScreen} - ${value ? "Open" : "Close"} filters`);
    setFilter(value);
  };

  const handleOnClose = () => {
    handleFilter(false);
  };

  useEffect(() => {
    if (filter) {
      openRightModalContent(
        <DrawerLayout classes="movements-filters" withHeader={false}>
          <MovementsFilter handleFilter={handleFilter} />
        </DrawerLayout>
      );
      dispatch(
        openRightModal({
          onClose: handleOnClose,
          withCloseButton: false,
        })
      );
    }
  }, [filter]);

  const isMonetary = section === MovementsTypes.MONETARY;

  return (
    <div className={styles.desktopPage}>
      <CommonLayout {...getListMenuHome()} withHeader={false}>
        <div className={styles.header}>
          <UIOverlappedButtons
            options={movementTypes}
            selectedValue={section}
            type={OverlappedButtonsTypes.Primary}
            onChangeValue={handleSection}
          />
        </div>
        {isMonetary && (
          <MovementsMonetary
            cashMovements={cashMovementsData}
            onClickFilter={handleFilter}
          />
        )}
        {!isMonetary && (
          <MovementsInstrument
            tickerMovements={tickersMovementsData}
            onClickFilter={handleFilter}
          />
        )}
      </CommonLayout>
    </div>
  );
};

export default MovementsDesktop;
