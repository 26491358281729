import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { isEmpty } from "lodash";
import { transfersService } from "apis/services";
import { Currencies } from "interfaces/wallet";
import {
  useCapitalSendAccounts,
  AccountsSteps,
} from "context/CapitalSendAccountsProvider";
import { AccountsResponse } from "interfaces/api-responses";
import Text, { TextVariants } from "components/common/Text";
import BankAccountRow from "components/common/BankAccountRow";
import BasicWrapper from "components/common/BasicWrapper";
import LoadingSpinner from "components/common/LoadingSpinner";

import ConfirmWithdrawSheet from "./ConfirmWithdrawSheet";
import AccountsEmptyState from "./AccountsEmptyState";

import styles from "./styles.module.scss";

const Accounts: React.FC = () => {
  const {
    setAccountSelected,
    setStep,
    accountSelected,
    currency,
    getBankLogo,
    getAccounts,
    accounts,
    isLoading,
  } = useCapitalSendAccounts();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickNewAccount = () => {
    ampli.agendaAddNewAccount();
    setStep(AccountsSteps.NEW_ACCOUNT);
  };

  const handleClickAccount = (account: AccountsResponse) => {
    ampli.withdrawBankAccountSelected();
    setIsOpen(true);
    setAccountSelected(account);
  };

  const navigationHeaderProps = {
    title: "¿A qué cuenta vas a enviar?",
    onClick: () => setStep(AccountsSteps.AMOUNT),
    withCloseIcon: false,
  };

  useEffect(() => {
    getAccounts();
  }, []);

  if (isLoading) {
    return (
      <BasicWrapper navigationHeaderProps={navigationHeaderProps}>
        <LoadingSpinner size={100} />
      </BasicWrapper>
    );
  }

  return (
    <>
      <BasicWrapper
        navigationHeaderProps={navigationHeaderProps}
        secondaryButtonProps={{
          children: "Agendar una cuenta",
          onClick: handleClickNewAccount,
        }}
      >
        {isEmpty(accounts) ? (
          <AccountsEmptyState />
        ) : (
          <div className={styles.accountsContainer}>
            <Text
              variant={TextVariants.RegularText}
              className={styles.accountsTitle}
            >
              Cuentas en {currency === Currencies.ARS ? "Pesos" : "Dólares"}
            </Text>
            <div className={styles.accountsList}>
              {accounts.map((account) => {
                const { cbu_cvu, entity, id_bank_account, logo } = account;
                return (
                  <BankAccountRow
                    key={id_bank_account}
                    name={entity}
                    accountId={cbu_cvu}
                    onClick={() => handleClickAccount(account)}
                    logo={getBankLogo(logo)}
                  />
                );
              })}
            </div>
          </div>
        )}
      </BasicWrapper>

      {accountSelected && (
        <ConfirmWithdrawSheet
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          bankAccount={accountSelected}
        />
      )}
    </>
  );
};

export default Accounts;
