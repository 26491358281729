import { useEffect, useState } from "react";

import { ampli } from "ampli";
import CocosTagBottomSheet from "components/common/CocosTagBottomSheet";
import CocosTagSignBanner from "components/common/CocosTagSign";
import PageHeader from "components/common/PageHeader";
import {
  getCurrentScreen,
  setCurrentScreen,
} from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { LogOut, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes, { cryptoScreenNames } from "router/routes";

import DefaultSection from "./DefaultSection";
import LogoVersion from "./LogoVersion";
import SectionsList from "./SectionsList";
import UserDataSection from "./UserDataSection";

import styles from "./styles.module.scss";

const PageProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const previousScreen = useAppSelector(getCurrentScreen);

  const [open, setOpen] = useState<boolean>(false);

  const sessionOptions = [
    {
      label: "Cerrar sesión",
      onClick: () => navigate(mainRoutes.logout),
      icon: LogOut,
    },
  ];

  useEffect(() => {
    ampli.cryptoScreenProfile({
      previous_screen: previousScreen,
    });

    dispatch(setCurrentScreen(cryptoScreenNames.profile));
  }, []);

  return (
    <div className={styles.pageProfileContainer}>
      <div className={styles.wrapper}>
        <PageHeader icon={X} title="" onClick={() => navigate(-1)} />
        <UserDataSection />
        <div className={styles.cocosTag}>
          <CocosTagSignBanner onClick={() => setOpen(true)} />
        </div>
        <SectionsList />
        <DefaultSection title="Sesión" options={sessionOptions} />
      </div>
      <LogoVersion />
      <CocosTagBottomSheet open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default PageProfile;
