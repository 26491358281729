import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Check } from "lucide-react";
import { Currencies } from "interfaces/wallet";
import mainRoutes from "router/routes";
import { formatToCurrency } from "utils";
import { useCapitalSendAccounts } from "context/CapitalSendAccountsProvider";
import { getWallet } from "features/wallet/walletSlice";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import { useAppDispatch } from "hooks/redux";

import DetailsBody from "../Accounts/ConfirmWithdrawSheet/DetailsBody";

import styles from "./styles.module.scss";

const TransactionSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    amount,
    accountSelected,
    currency,
    isDesktop,
  } = useCapitalSendAccounts();

  useEffect(() => {
    dispatch(getWallet());
  }, []);

  const onClose = () => {
    if (isDesktop) {
      dispatch(closeRightModal());
    }

    return navigate(mainRoutes.home);
  };

  const handleCloseSidebar = () => {
    dispatch(closeRightModal());
  };

  const getCurrencyLabel = () => {
    if (currency === Currencies.ARS) {
      return "$";
    }
    return "US$";
  };

  return (
    <div className={styles.successContainer}>
      <BasicWrapper
        navigationHeaderProps={{
          title: "¡Listo!",
          onClick: isDesktop ? handleCloseSidebar : onClose,
        }}
        primaryButtonProps={
          isDesktop
            ? undefined
            : {
                children: "Volver al inicio",
                onClick: onClose,
              }
        }
      >
        <div className={styles.iconContainer}>
          <Check color="var(--green800)" />
        </div>
        <Text variant={TextVariants.RegularText} className={styles.text}>
          Ya enviaste {getCurrencyLabel()}{" "}
          <Text
            variant={TextVariants.SemiboldText}
            className={styles.textAmount}
          >
            {formatToCurrency(amount)}
          </Text>{" "}
          que restaremos de tu portfolio.
        </Text>
        <DetailsBody bankAccount={accountSelected} />
      </BasicWrapper>
    </div>
  );
};

export default TransactionSuccess;
