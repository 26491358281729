import Text, { TextVariants } from "components/common/Text";
import { Asterisk, Check } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface BulletProps {
  label: string;
  validated: boolean;
}

const Bullet: React.FC<BulletProps> = ({ label, validated }) => {
  const textColor = validated ? "var(--green800)" : "var(--slate800)";

  return (
    <div className={styles.BulletContainer}>
      {validated ? (
        <Check size={px2rem(12)} color="var(--green800)" />
      ) : (
        <Asterisk size={px2rem(12)} color="var(--blue800)" />
      )}
      <Text variant={TextVariants.RegularTextS} color={textColor}>
        {label}
      </Text>
    </div>
  );
};

export default Bullet;
