import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { marketKeys } from "apis/queryKeyFactories";
import { marketService } from "apis/services";
import { MarketData } from "interfaces/api-responses";
import { ErrorMessage } from "interfaces/errors";
import { Sections, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";

export const fetchHomeInstruments = async () => {
  const { data } = await API.get<MarketData>(marketService.tickers, {
    params: {
      currency: Currencies.ARS,
      instrument_type: Sections.HOME,
      instrumnet_subtype: Sections.HOME,
      settlement_days: SettlementTerms._24,
      segment: "C",
    },
  });

  return data.items;
};

export const useHomeTickersQuery = () => {
  return useAppQuery<any, ErrorMessage>({
    queryKey: () => marketKeys.homeTickers,
    queryFn: () => fetchHomeInstruments(),
    options: {
      onError: () => {
        return {
          message: "Hubo un error obteniendo los instrumentos de la home",
          status: 500,
        };
      },
      staleTime: Infinity,
    },
  });
};
