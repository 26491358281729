import { useEffect, useState } from "react";

import Loader from "components/common/Loader";
import { useAuth } from "context/AuthProvider";
import { Navigate } from "react-router-dom";

const LogoutPage: React.FC = () => {
  const [loggingOut, setLoggingOut] = useState(true);
  const { signOut } = useAuth();

  useEffect(() => {
    const logOut = async () => {
      await signOut();
      setLoggingOut(false);
    };

    logOut();
  }, []);

  if (loggingOut) {
    return <Loader />;
  }

  return <Navigate to="/login?logout=true" />;
};

export default LogoutPage;
