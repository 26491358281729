import React, { useEffect, useState } from "react";

import { CircularProgress, Typography } from "@mui/material";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { trackAction } from "utils/amplitude";
import { screenNames } from "router/routes";
import { AcademyResponse } from "interfaces/api-responses";
import { homeService } from "apis/services";
import API from "apis";
import { isEmpty } from "lodash";

import "./index.scss";

const AcademyHome: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [academyData, setAcademyData] = useState<AcademyResponse>([]);

  const handleClick = () => {
    trackAction(`${screenNames.home} - Click Cocos University`);
  };

  useEffect(() => {
    const getAcademyData = async () => {
      try {
        const { data } = await API.get<AcademyResponse>(homeService.academy);
        setAcademyData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getAcademyData();
  }, []);

  if (isLoading) {
    return (
      <div className={`academy-content ${isMobile ? "mobile" : "desktop"}`}>
        <CircularProgress color="error" size={18} />
      </div>
    );
  }

  if (isEmpty(academyData)) return null;

  return (
    <div className={`academy-content ${isMobile ? "mobile" : "desktop"}`}>
      <Typography className="academy-title">Cocos Academy</Typography>
      <div className="cards-container">
        {academyData.map((univ, index) => (
          <div key={index} className="cards-academy">
            <Typography className="card-title">{univ.header}</Typography>
            <Typography className="card-info">{univ.title}</Typography>
            <a
              href={univ.target_url}
              rel="noreferrer"
              target="_blank"
              onClick={handleClick}
            >
              {univ.call_to_action}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcademyHome;
