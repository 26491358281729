import { SVGProps } from "react";

export interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const PayIcon: React.FC<IconProps> = ({
  width = 114,
  height = 80,
  color,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 114 80"
      fill="none"
      {...props}
    >
      <path
        d="M0.00453076 39.7402C-0.0319022 38.438 0.194626 37.142 0.670534 35.9297C1.14644 34.7173 1.86192 33.6137 2.77408 32.6851C3.68626 31.7563 4.77631 31.0217 5.97891 30.525C7.18151 30.0284 8.47186 29.7801 9.77264 29.7949C12.0173 29.7285 14.2129 30.4606 15.9697 31.8613C17.7265 33.2621 18.9308 35.2409 19.3689 37.4464H13.7404C13.4388 36.6601 12.8926 35.9918 12.1825 35.5405C11.4724 35.089 10.636 34.8783 9.79716 34.9396C7.58012 34.9396 5.50216 36.6353 5.50216 39.7402C5.50216 42.845 7.59648 44.5818 9.79716 44.5818C10.6828 44.6392 11.5607 44.3861 12.2803 43.8658C12.9999 43.3456 13.5161 42.5908 13.7404 41.7309H19.3689C19.0031 43.9958 17.8285 46.0509 16.0635 47.514C14.2985 48.9771 12.063 49.7486 9.77264 49.6855C8.47091 49.7038 7.17898 49.4578 5.9748 48.9624C4.77063 48.4671 3.67918 47.7324 2.76632 46.803C1.85344 45.8735 1.13809 44.7686 0.663419 43.5547C0.188752 42.3408 -0.0353823 41.0432 0.00453076 39.7402Z"
        fill={color || "#090A0A"}
      />
      <path
        d="M20.6454 39.7402C20.6374 37.7609 21.2169 35.8239 22.3103 34.1751C23.4036 32.5262 24.9618 31.2399 26.7866 30.4795C28.6115 29.7192 30.6208 29.5189 32.5596 29.9042C34.4983 30.2895 36.279 31.2432 37.6754 32.6439C39.072 34.0445 40.0213 35.8293 40.403 37.7713C40.7845 39.7133 40.5813 41.7251 39.819 43.5512C39.0565 45.3773 37.7695 46.9354 36.1211 48.0276C34.4726 49.1198 32.5374 49.6969 30.5608 49.6855C29.2499 49.7123 27.9472 49.4733 26.731 48.9828C25.5148 48.4923 24.4103 47.7604 23.484 46.8313C22.5577 45.9021 21.8287 44.795 21.3409 43.5764C20.853 42.3577 20.6165 41.0527 20.6454 39.7402ZM35.0849 39.7402C35.0849 36.9057 33.2278 34.9232 30.618 34.9232C28.0084 34.9232 26.1431 36.9057 26.1431 39.7402C26.1431 42.5746 28.0001 44.5572 30.618 44.5572C33.236 44.5572 35.0849 42.5911 35.0849 39.7402Z"
        fill={color || "#090A0A"}
      />
      <path
        d="M42.1535 39.7402C42.117 38.438 42.3435 37.142 42.8194 35.9297C43.2953 34.7173 44.0108 33.6137 44.923 32.6851C45.8351 31.7563 46.9252 31.0217 48.1278 30.525C49.3304 30.0284 50.6208 29.78 51.9215 29.7949C54.1662 29.7285 56.3618 30.4605 58.1186 31.8613C59.8753 33.2621 61.0797 35.2408 61.5178 37.4464H55.8892C55.5877 36.6601 55.0415 35.9918 54.3314 35.5404C53.6212 35.0889 52.785 34.8783 51.9461 34.9395C49.729 34.9395 47.651 36.6353 47.651 39.7402C47.651 42.845 49.7454 44.5817 51.9461 44.5817C52.8317 44.6392 53.7097 44.386 54.4292 43.8658C55.1487 43.3456 55.6649 42.5908 55.8892 41.7308H61.5178C61.152 43.9959 59.9774 46.0508 58.2124 47.5139C56.4474 48.977 54.212 49.7486 51.9215 49.6855C50.6198 49.7037 49.3279 49.4578 48.1237 48.9624C46.9195 48.467 45.828 47.7324 44.9152 46.803C44.0023 45.8735 43.287 44.7686 42.8123 43.5547C42.3376 42.3408 42.1135 41.0432 42.1535 39.7402Z"
        fill={color || "#090A0A"}
      />
      <path
        d="M62.8098 39.7402C62.8018 37.7609 63.3813 35.8239 64.4747 34.1751C65.568 32.5263 67.1261 31.24 68.951 30.4796C70.7759 29.7192 72.7852 29.5189 74.724 29.9042C76.6627 30.2896 78.4434 31.2432 79.8398 32.6439C81.2364 34.0445 82.1857 35.8293 82.5674 37.7713C82.949 39.7133 82.7457 41.7251 81.9834 43.5512C81.2209 45.3773 79.9339 46.9354 78.2854 48.0276C76.637 49.1198 74.7018 49.6968 72.7252 49.6855C71.4143 49.7123 70.1116 49.4733 68.8954 48.9829C67.6792 48.4923 66.5747 47.7605 65.6484 46.8314C64.722 45.9022 63.9931 44.795 63.5053 43.5764C63.0174 42.3577 62.7809 41.0528 62.8098 39.7402ZM77.2493 39.7402C77.2493 36.9057 75.3922 34.9232 72.7824 34.9232C70.1728 34.9232 68.3156 36.9057 68.3156 39.7402C68.3156 42.5747 70.1645 44.5572 72.7824 44.5572C75.4004 44.5572 77.2493 42.5911 77.2493 39.7402Z"
        fill={color || "#090A0A"}
      />
      <path
        d="M84.3584 42.9515L89.6759 42.9925C89.766 44.3605 90.6658 45.3353 92.6293 45.3353C94.4128 45.3353 95.0836 44.6309 95.0836 43.6396C95.0836 42.6484 94.0447 42.1569 91.8603 41.8701C88.1461 41.4195 85.0455 39.7975 85.0455 35.8406C85.0455 32.0477 87.7534 29.7948 92.4085 29.7948C97.0638 29.7948 100.254 32.3181 100.254 36.152L94.83 36.111C94.7563 35.0624 94.0119 34.2924 92.4248 34.2924C91.0422 34.2924 90.4859 34.9396 90.4859 35.7178C90.4859 36.7664 90.8786 37.0367 93.8564 37.4627C98.1107 38.0771 100.753 39.437 100.753 43.3119C100.753 47.6865 97.1532 49.7018 92.5721 49.7018C86.8617 49.7018 84.3584 46.3102 84.3584 42.9515Z"
        fill={color || "#090A0A"}
      />
      <path
        d="M91.9667 22.8233C87.5162 22.8726 83.4176 20.7098 81.5441 16.8594C80.8802 15.4504 80.536 13.9118 80.536 12.3538C80.536 10.7958 80.8802 9.25714 81.5441 7.84809C82.892 4.86699 85.2515 2.46076 88.2035 1.05679L90.3796 0L92.4985 4.35004L90.3224 5.41502C88.4009 6.31648 86.8594 7.86879 85.9701 9.7978C85.604 10.5639 85.41 11.4008 85.4014 12.25C85.393 13.0992 85.5704 13.9399 85.921 14.7132C87.4835 17.9081 91.9913 18.9239 95.9756 16.9824L98.1511 15.9174L100.271 20.2674L98.0944 21.3324C96.1957 22.2662 94.1143 22.7671 91.9994 22.7988"
        fill={color || "#090A0A"}
      />
      <path
        d="M103.739 28.4594L101.522 24.1504L103.674 23.0445C107.617 21.0128 109.507 16.7856 107.879 13.6234C106.251 10.4612 101.727 9.52729 97.7838 11.5672L95.6321 12.6731L93.4151 8.36405L95.5666 7.2581C101.882 3.98122 109.335 5.86543 112.182 11.4034C115.029 16.9413 112.182 24.1012 105.883 27.3535L103.739 28.4594Z"
        fill={color || "#8145B5"}
      />
      <path
        d="M37.8967 80V54.8145H43.2056V57.1881C44.5827 55.2312 46.7207 54.2347 48.895 54.2347C54.2764 54.2347 57.9364 58.7463 57.9364 64.2001C57.9364 69.7083 54.222 74.2019 48.9856 74.2019C46.5758 74.2019 44.5464 73.0241 43.3687 71.3572L43.4774 73.6221V80H37.8967ZM47.9347 69.0742C50.3083 69.0742 52.4282 67.371 52.4282 64.2001C52.4282 61.1743 50.3808 59.3442 47.8984 59.3442C45.4161 59.3442 43.423 61.4823 43.423 64.2001C43.423 67.0992 45.5248 69.0742 47.9347 69.0742Z"
        fill={color || "#8145B5"}
      />
      <path
        d="M68.0493 74.2019C63.0122 74.2019 59.4065 69.7083 59.4065 64.2001C59.4065 58.7282 63.0666 54.2165 68.0131 54.2165C70.7853 54.2165 73.0139 55.3037 74.1554 57.2243V54.8145H79.4462V73.5858H74.1373V71.2122C72.9958 73.1147 70.7853 74.2019 68.0493 74.2019ZM69.4083 69.056C71.963 69.056 73.9199 66.9542 73.9199 64.2001C73.9199 61.5004 71.9268 59.3442 69.4264 59.3442C66.9622 59.3442 64.9328 61.1743 64.9328 64.2001C64.9328 67.3891 67.0347 69.056 69.4083 69.056Z"
        fill={color || "#8145B5"}
      />
      <path
        d="M81.7679 80V75.434H84.9025C86.642 75.434 87.3667 74.7454 87.9465 73.1328L88.2002 72.4262L81.2425 54.8326H87.113L90.6644 64.3994L91.1898 66.61L91.6972 64.3994L95.176 54.8507L101.119 54.8145L93.9077 73.2597C91.6066 79.1665 89.2692 80 85.0112 80H81.7679Z"
        fill={color || "#8145B5"}
      />
      <path
        d="M28.3107 71.1571C28.2679 70.5457 28.4103 69.9355 28.719 69.4064C29.0279 68.8773 29.4889 68.4537 30.0419 68.191C30.5948 67.9282 31.214 67.8385 31.8187 67.9336C32.4233 68.0287 32.9853 68.3041 33.4312 68.7239C33.8771 69.1437 34.1863 69.6885 34.3183 70.2869C34.4503 70.8854 34.3989 71.5098 34.1711 72.0786C33.9433 72.6475 33.5494 73.1343 33.0411 73.4756C32.5326 73.8168 31.9333 73.9966 31.3212 73.9915C30.9352 74.0138 30.5487 73.9571 30.1853 73.8248C29.8219 73.6926 29.4892 73.4876 29.2074 73.2224C28.9257 72.9571 28.701 72.6373 28.5468 72.2822C28.3926 71.927 28.3122 71.5442 28.3107 71.1571Z"
        fill={color || "#8145B5"}
      />
    </svg>
  );
};

export default PayIcon;
