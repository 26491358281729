import { Currencies } from "interfaces/wallet";

export enum Payment {
  CLOSE = "CLOSE",
  OPEN = "OPEN",
}

export enum PaySteps {
  QR,
  CODE,
  METHOD,
  AMOUNT,
  PIN,
  RESPONSE,
  RECEIPT,
}

export enum PaymentStatus {
  PENDING_USER_CONFIRMATION = "PENDING_USER_CONFIRMATION",
  PROCESSING = "PROCESSING",
  PENDING_EXECUTION = "PENDING_EXECUTION",
  COMPLETED = "COMPLETED",
  TO_BE_REVERSED = "TO_BE_REVERSED",
  FAILED = "FAILED",
  TO_BE_CONFIRM_BY_BRIDGE = "TO_BE_CONFIRM_BY_BRIDGE",
}

export enum PayError {
  UNSUPPORTED_DEVICE = "UNSUPPORTED_DEVICE",
  PERMISSIONS_DENIED = "PERMISSIONS_DENIED",
  INVALID_QR = "INVALID_QR",
  ALREADY_PAID = "ALREADY_PAID",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  PAYMENT_METHODS_ERROR = "PAYMENT_METHODS_ERROR",
}

export enum PaymentCurrencies {
  ARS = "ARS",
  USD = "USD",
  BRL = "BRL",
  COCOA = "COCOA",
  COCOAUSD = "COCOAUSD",
  COCORMA = "COCORMA",
  USDC = "USDC",
  USDT = "USDT",
  DAI = "DAI",
  STABLECOIN = "STABLECOIN",
}

export enum PaymentMethodErrors {
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  ARS_DEBT = "ARS_DEBT",
}

export enum PaySource {
  QR = "QR",
  CARD = "CARD",
  BRIDGE = "BRIDGE",
}
