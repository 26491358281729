export enum CropType {
  DNI = "DNI",
  SELFIE = "SELFIE",
}

export const cropBase64ImageToBlob = async (
  base64: string,
  type: CropType
): Promise<Blob> => {
  // Fast length check
  if (base64.length < 1000) {
    throw new Error(`Small base64-encoded image size: ${base64}`);
  }

  const img = new Image();
  img.src = base64;

  await new Promise<void>((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = (error) => reject(error);
  });

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Unable to get canvas context");
  }

  let imageHeight: number;
  let targetHeight: number;

  const originalWidth = img.width;
  const originalHeight = img.height;

  const TOP_MARGIN = 60;
  const offsetY = (TOP_MARGIN / innerWidth) * originalHeight;

  if (type === CropType.DNI) {
    targetHeight = originalHeight * (50 / 100);
    imageHeight = targetHeight;
  } else {
    targetHeight = originalHeight * (80 / 100);
    imageHeight = originalHeight;
  }

  const qualityFactor = 0.9;

  canvas.width = originalWidth;
  canvas.height = targetHeight;

  ctx.drawImage(
    img,
    0, // X-coordinate of the top-left corner of the crop area in the original image
    offsetY, // Y-coordinate of the top-left corner of the crop area in the original image
    originalWidth, // Width of the area to crop from the original image
    imageHeight, // Height of the area to crop from the original image
    0, // X-coordinate on canvas
    0, // Y-coordinate on canvas
    originalWidth, // Width of the image to be drawn on canvas
    imageHeight // Height of the image to be drawn on canvas
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Canvas to Blob conversion failed"));
        }
      },
      "image/jpeg",
      qualityFactor
    );
  });
};

export const cropImageForScanDni = async (base64: string): Promise<string> => {
  if (base64.length < 1000) {
    throw new Error(`Small base64-encoded image size: ${base64}`);
  }

  const img = new Image();
  img.src = base64;

  await new Promise<void>((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = (error) => reject(error);
  });

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Unable to get canvas context");
  }

  const originalWidth = img.width;
  const originalHeight = img.height;

  const cropHeight = originalHeight * (40 / 100);
  const MARGIN_TOP = 60;
  const offsetY = (MARGIN_TOP / innerWidth) * originalHeight;

  canvas.width = originalWidth;
  canvas.height = cropHeight;

  ctx.drawImage(
    img,
    0,
    offsetY,
    originalWidth,
    cropHeight,
    0,
    0,
    originalWidth,
    cropHeight
  );

  return canvas.toDataURL("image/jpeg", 1);
};
