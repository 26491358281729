import Divider from "components/common/Divider";
import Heading, { HeadingVariants } from "components/common/Heading";
import Currency from "components/common/Text/Currency";
import { useCocosPay } from "context/CocosPayProvider";
import { Currencies } from "interfaces/wallet";
import BRFlag from "assets/icons/BRFlag";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

const ResponseDetail: React.FC = () => {
  const { paymentData } = useCocosPay();

  const {
    businessName,
    quantity,
    idPayment,
    transactionCurrency,
  } = paymentData;

  const isBR = transactionCurrency === Currencies.BRL;

  return (
    <div className={styles.ResponseDetailContainer}>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate800)"
          component="span"
        >
          Pagaste a
        </Heading>
        <Heading
          className={styles.businessName}
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate900)"
          component="span"
        >
          {businessName}
        </Heading>
      </div>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate800)"
          component="span"
        >
          Monto
        </Heading>
        <div className={styles.amount}>
          {isBR && <BRFlag size={px2rem(16)} />}
          <Currency
            className={styles.currency}
            color="var(--slate900)"
            ticker={isBR ? Currencies.BRL : Currencies.ARS}
            amount={quantity || 0}
          />
        </div>
      </div>
      <Divider className={styles.divider} />
      <div>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          component="h3"
          color="var(--slate800)"
        >
          Comprobante de pago N°
        </Heading>
        <Heading
          className={styles.receiptId}
          variant={HeadingVariants.SemiboldSubtitle}
          component="h3"
          color="var(--slate900)"
        >
          {idPayment}
        </Heading>
      </div>
    </div>
  );
};

export default ResponseDetail;
