import { useEffect } from "react";

import { useMovements } from "context/MovementsProvider";
import { isEmpty } from "lodash";
import { TickersMovements } from "interfaces/movements";
import { StatusOnboarding } from "interfaces/createAccount";
import FilterButtonV2 from "components/common/filter-button-v2";
import EmptyState from "components/page-movements/components/empty-state";
import { getCurrentScreen } from "features/global/globalSlice";
import { setCurrentScreen } from "features/global/globalSlice";
import { trackScreen } from "utils/amplitude";
import { screenNames } from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import InstrumentsTable from "./components/instruments-table";

import styles from "./styles.module.scss";

interface MovementsInstrumentProps {
  tickerMovements: TickersMovements[];
  onClickFilter: (value: boolean) => void;
}

const MovementsInstrument: React.FC<MovementsInstrumentProps> = ({
  tickerMovements,
  onClickFilter,
}) => {
  const dispatch = useAppDispatch();
  const { filters, movementsLoading } = useMovements();
  const user = useAppSelector(getLoggedUser);
  const currentScreen = useAppSelector(getCurrentScreen);
  const isUserOnboarded = user?.statusOnboarding === StatusOnboarding.APPROVED;
  const hasMovements = !isEmpty(tickerMovements);

  const displayEmptyState =
    !movementsLoading && (!user?.hasAccount || !hasMovements);

  const filtersCount = filters.type.length + (filters.date_from ? 1 : 0);

  useEffect(() => {
    trackScreen(currentScreen, screenNames.instrumentMovements);
    dispatch(setCurrentScreen(screenNames.instrumentMovements));
  }, []);

  const handleOpenFilters = () => {
    onClickFilter(true);
  };

  if (displayEmptyState)
    return (
      <EmptyState
        isUserOnboarded={isUserOnboarded}
        hasMovements={hasMovements}
        userHasAccount={user?.hasAccount}
        hasFilters={filtersCount > 0}
        filtersRedirect={handleOpenFilters}
      />
    );

  return (
    <div className={styles.divContainer}>
      <div className={styles.filterRow}>
        <FilterButtonV2
          onClick={handleOpenFilters}
          filtersCount={filtersCount}
        />
      </div>

      {tickerMovements && <InstrumentsTable data={tickerMovements} />}
    </div>
  );
};

export default MovementsInstrument;
