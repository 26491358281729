import { ConfirmOrderContainer } from "components/common/confirm-market-order-container";
import { UIModalSlider } from "components/common/modal-slider";

import "./index.scss";

interface PropTypes {
  open: boolean;
  onToggleOpen: () => void;
  onClickConfirm?: () => void;
}

export const ConfirmOrderModal = (props: PropTypes) => {
  const { open, onToggleOpen } = props;

  return (
    <UIModalSlider
      classes="confirm-order-modal"
      open={open}
      onToggleDisplay={onToggleOpen}
    >
      <ConfirmOrderContainer onClose={onToggleOpen} />
    </UIModalSlider>
  );
};
