import { useEffect } from "react";

import { ampli } from "ampli";
import { Home, ShoppingBag, CreditCard } from "lucide-react";
import { ThemeVariants } from "interfaces/theme";
import { CardSections } from "interfaces/card/enums";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { useCocosCard } from "context/CocosCardProvider";
import Text, { TextVariants } from "components/common/Text";
import Heading, { HeadingVariants } from "components/common/Heading";
import BasicWrapper from "components/common/BasicWrapper";
import InfoRow from "components/page-cocos-card/components/InfoRow";

import styles from "./styles.module.scss";

const PhysicalReady: React.FC = () => {
  const { setSelectedSection, shippingInfo } = useCocosCard();
  const navigate = useNavigate();

  useEffect(() => {
    ampli.cardPhysicalCreationReady();
  }, []);

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={{
        title: "¡Listo!",
        onClick: () => navigate(mainRoutes.home),
      }}
      className={styles.virtualReadyContainer}
      primaryButtonProps={{
        children: "Ir a tarjetas",
        onClick: () => setSelectedSection(CardSections.HOME),
      }}
      secondaryButtonProps={{
        children: "Volver al inicio",
        onClick: () => navigate(mainRoutes.home),
      }}
    >
      <div className={styles.titleHeader}>
        <Heading
          variant={HeadingVariants.RegularTitle}
          color="var(--slate900)"
          component="h1"
        >
          Tu tarjeta esta en camino
        </Heading>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Debería llegar en los próximos días.
        </Text>
      </div>
      <div>
        <InfoRow
          icon={Home}
          title="Te enviaremos tu tarjeta"
          subtitle={`Llegará a la dirección que solicitaste: ${shippingInfo?.street_name} ${shippingInfo?.street_number}, CP ${shippingInfo.zip_code}.`}
        />
        <InfoRow
          icon={ShoppingBag}
          title="Ya podés empezar a comprar"
          subtitle="Activamos una tarjeta virtual para que puedas usar online y pagar tus servicios favoritos."
        />
        <InfoRow
          icon={CreditCard}
          title="Recordá activar tu tarjeta"
          subtitle="Vamos a proporcionarte toda la información necesaria para que sepas cuando llega."
        />
      </div>
    </BasicWrapper>
  );
};

export default PhysicalReady;
