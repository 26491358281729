import { useState } from "react";

import { IconButton, Typography, TextField } from "@mui/material";
import { useMovements } from "context/MovementsProvider";
import { es } from "date-fns/locale";
import dayjs from "dayjs";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { IoArrowBack } from "react-icons/io5";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { DateRange } from "react-date-range";

import styles from "./styles.module.scss";

interface CalendarSectionProps {
  onClickCancel: () => void;
  onClickApply: () => void;
}

const CalendarSection: React.FC<CalendarSectionProps> = ({
  onClickApply,
  onClickCancel,
}) => {
  const { filters, setFilters } = useMovements();
  const today = new Date();

  const firstDay = new Date("2020-01-01");
  const [dateRange, setDateRange] = useState([
    {
      startDate: today,
      endDate: today,
      key: "selection",
    },
  ]);

  const handleDateChange = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const handleApply = () => {
    setFilters({
      ...filters,
      date_from: dayjs(dateRange[0].startDate).format("YYYY-MM-DD"),
      date_to: dayjs(dateRange[0].endDate).format("YYYY-MM-DD"),
    });
    onClickApply();
  };

  return (
    <div className={styles.filterBody}>
      <div className={styles.filterTopRow}>
        <IconButton onClick={onClickCancel}>
          <IoArrowBack />
        </IconButton>
        <Typography>Elegir período</Typography>
      </div>
      <div className={styles.dateRangeSelected}>
        <TextField
          disabled
          focused
          id="outlined-disabled"
          value={`${dayjs(dateRange[0].startDate).format(
            "DD/MM/YYYY"
          )} - ${dayjs(dateRange[0].endDate).format("DD/MM/YYYY")}`}
          label="Fecha"
          size="small"
          sx={{ width: "215px" }}
        />
      </div>
      <DateRange
        editableDateInputs={true}
        onChange={handleDateChange}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        dateDisplayFormat="dd/MM/yyyy"
        weekdayDisplayFormat="eeeee"
        monthDisplayFormat="MMM"
        weekStartsOn={0}
        locale={es}
        minDate={firstDay}
        maxDate={today}
      />
      <ButtonUI
        size={ButtonSizes.Full}
        variant={ButtonVariants.Primary}
        onClick={handleApply}
        newClass={styles.primaryButton}
      >
        Aplicar
      </ButtonUI>
      <ButtonUI
        size={ButtonSizes.Full}
        variant={ButtonVariants.Secondary}
        onClick={onClickCancel}
      >
        Cancelar
      </ButtonUI>
    </div>
  );
};

export default CalendarSection;
