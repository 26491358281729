import React, { useEffect } from "react";

import Toast, { ToastVariants } from "components/common/Toast";
import {
  getCurrentScreen,
  setCurrentScreen,
} from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { screenNames } from "router/routes";
import { getEnvironment } from "utils";
import { trackScreen } from "utils/amplitude";
import { DesktopOrTablet, Mobile } from "utils/responsive";

import ProfileDesktop from "./desktop";
import ProfileMobile from "./mobile";

import "./index.scss";

const ProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentScreen = useAppSelector(getCurrentScreen);

  const isUpdaterActive = !import.meta.env.VITE_UPDATE;
  const { isProd, environment } = getEnvironment();

  useEffect(() => {
    trackScreen(screenNames.profile, currentScreen);
    dispatch(setCurrentScreen(screenNames.profile));
  }, []);

  return (
    <>
      <Mobile>
        <ProfileMobile />
      </Mobile>
      <DesktopOrTablet>
        <ProfileDesktop />
      </DesktopOrTablet>
      {!isProd && (
        <Toast
          message={`Environment: ${environment} - Updater active: ${isUpdaterActive}`}
          variant={ToastVariants.Capital}
          onClose={() => console.log("nothing")}
        />
      )}
    </>
  );
};

export default ProfilePage;
