import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { useAppState } from "context/AppStateProvider";
import { Currencies } from "interfaces/wallet";

interface DailyPerformanceProps {
  performance?: number;
}

const DailyPerformance: React.FC<DailyPerformanceProps> = ({ performance }) => {
  const { isBalanceHidden } = useAppState();

  const getPriceLabelColor = () => {
    if (isBalanceHidden) return "var(--slate800)";

    if (performance && performance < 0) return "var(--red800)";

    return "var(--green800)";
  };

  const priceLabelColor = getPriceLabelColor();

  if (!performance) return <div>--</div>;

  return (
    <PriceLabel
      price={performance}
      currency={Currencies.ARS}
      color={priceLabelColor}
      symbolColor={priceLabelColor}
      size={PriceLabelSizes.Xsmall}
      isBalanceHidden={isBalanceHidden}
    />
  );
};

export default DailyPerformance;
