const isLengthOk = (cuit: string) => {
  return cuit.length === 11;
};

const sanitizeCuit = (cuit: string) => {
  const fixedCuit = cuit.replace(/-/g, "");
  if (fixedCuit.length === 10) {
    return `${fixedCuit.substring(0, 2)}0${fixedCuit.substring(2)}`; // add 0 if cuit lenght = 10
  }
  return fixedCuit;
};

const isTypeOk = (cuit: string) => {
  const code = parseInt(cuit.substring(0, 2), 10);
  const validTypes = [20, 23, 24, 27, 30, 33, 34];
  return validTypes.includes(code);
};

const checksumIsOk = (cuit: string) => {
  const digits = cuit.split("").map(Number);
  const aMult = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  let sum = 0;
  for (let i = 0; i <= 9; i += 1) {
    sum += digits[i] * aMult[i];
  }
  let diff = 11 - (sum % 11);
  if (diff === 11) diff = 0;
  return diff === digits[10];
};

const isCuitValid = (cuit: string) => {
  const sanitizedCuit = sanitizeCuit(cuit);
  return (
    isLengthOk(sanitizedCuit) &&
    isTypeOk(sanitizedCuit) &&
    checksumIsOk(sanitizedCuit)
  );
};

export default isCuitValid;
