import { faceIdBiometryTypes, touchIdBiometryTypes } from "constants/biometry";

import { ChangeEvent, FormEvent, useState, MouseEvent } from "react";

import InputField from "components/common/input/InputField";
import { isValidEmail } from "components/page-sign-up/utils";
import PasswordInput from "components/common/input/PasswordInput";
import { useAuth } from "context/AuthProvider";
import Footer from "components/common/Footer";
import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { Link, useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { useBiometry } from "hooks/useBiometry";
import validator from "validator";
import FaceIdIcon from "assets/icons/face-id-icon";
import FingerprintIcon from "assets/icons/fingerprint-icon";
import px2rem from "utils/px2rem";
import GoogleTagManager from "react-gtm-module";
import { AnalyticsEvents, trackAnalyticsEvent } from "utils/firebaseAnalytics";

import styles from "./styles.module.scss";

const SignIn: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [hasBlurredEmailInput, setHasBlurredEmailInput] = useState<boolean>(
    false
  );
  const { biometryType } = useBiometry();
  const navigate = useNavigate();

  const {
    error,
    loading,
    signInWithPassword,
    signInWithBiometrics,
  } = useAuth();

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.toLowerCase());
  };

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    signInWithPassword(email, password);
  };

  const onClickNoAccount = () => {
    GoogleTagManager.dataLayer({
      dataLayer: { event: "wapp_altas_clic_paso1_crear_cuenta" },
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.fbq("track", "wapp_altas_clic_paso1_crear_cuenta");

    trackAnalyticsEvent(AnalyticsEvents.ALTAS_CLIC_PASO1_CREAR_CUENTA);
    navigate(mainRoutes.signUp);
  };

  const signInDisabled = !password || !validator.isEmail(email);

  return (
    <div className={styles.signInWrapper}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <InputField
          type="email"
          label="Email"
          value={email}
          onChange={onChangeEmail}
          placeholder="Ingresá tu email"
          hasError={!isValidEmail(email) && hasBlurredEmailInput}
          errorMessage={"Ingresá un mail válido"}
          onBlur={() => setHasBlurredEmailInput(true)}
        />
        <PasswordInput
          name="password"
          value={password}
          label="Contraseña"
          onChange={onChangePassword}
          placeholder="Ingresá tu contraseña"
        />
        <Footer>
          <FilledButton
            type="submit"
            isLoading={loading}
            onClick={handleSubmit}
            disabled={signInDisabled}
          >
            Iniciar sesión
          </FilledButton>
          <OutlinedButton onClick={onClickNoAccount}>
            No tengo cuenta
          </OutlinedButton>
        </Footer>
      </form>
      {error && (
        <Text
          className={styles.signInErrorText}
          variant={TextVariants.RegularTextS}
        >
          {error}
        </Text>
      )}
      <Link to={mainRoutes.forgotPassword}>
        <Text color="var(--blue800)" variant={TextVariants.SemiboldTextS}>
          ¿Olvidaste tu contraseña?
        </Text>
      </Link>
      {biometryType && (
        <div
          onClick={signInWithBiometrics}
          className={styles.biometricsWrapper}
        >
          <div className={styles.divider} />
          {touchIdBiometryTypes.includes(biometryType) && (
            <FingerprintIcon
              size={px2rem(24)}
              fill="var(--blue800)"
              className={styles.icon}
            />
          )}
          {faceIdBiometryTypes.includes(biometryType) && (
            <FaceIdIcon
              size={px2rem(24)}
              fill="var(--blue800)"
              className={styles.icon}
            />
          )}
          <div className={styles.divider} />
        </div>
      )}
    </div>
  );
};

export default SignIn;
