import { useState, useEffect } from "react";

import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import API from "apis";
import { Info } from "lucide-react";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackScreen, trackAction } from "utils/amplitude";
import { trackAnalyticsEvent, AnalyticsEvents } from "utils/firebaseAnalytics";
import { trackFbEvent, FbEvents } from "utils/fbTracking";
import { onboardingScreenNames } from "router/routes";
import { onBoardingService } from "apis/services";
import { CreateAccountData } from "interfaces/api-responses";
import { useCreateAccount } from "context/CreateAccountProvider";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import Select from "components/common/NewSelect";
import InputRadio from "components/common/input/InputRadio";
import { UIModalSlider } from "components/common/modal-slider";
import NavigationHeader from "components/common/NavigationHeader";
import Stepper from "components/common/Stepper";
import {
  civilStatusOptions,
  fatcaOptions,
  soiOptions,
  pepOptions,
  residenceOptions,
  Option,
} from "components/page-create-account/constants";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Rejected from "../Final/Rejected";
import SelectBottomSheet from "../../SelectBottomSheet";
import FatcaInfo from "../../FatcaInfo";
import LoadingScreen from "../../Loading";

import styles from "./styles.module.scss";

interface Values {
  residenceCountry: string;
  civilStatus: string;
  fatca: string;
  soi: string;
  pep: string;
}

const ValidationSchema = Yup.object().shape({
  residenceCountry: Yup.string().required(),
  civilStatus: Yup.string().required(),
  fatca: Yup.string().required(),
  soi: Yup.string().required(),
  pep: Yup.string().required(),
});

const PersonalInfo = () => {
  const dispatch = useAppDispatch();
  const {
    goToHome,
    goToNextStep,
    error,
    setError,
    setAccountError,
  } = useCreateAccount();
  const [displayFactaInfo, setDisplayFactaInfo] = useState<boolean>(false);
  const [isArgResident, setIsArgResident] = useState<boolean>(true);
  const [
    isSelectCivilStatusOpen,
    setIsSelectCivilStatusOpen,
  ] = useState<boolean>(false);
  const [isSelectResidenseOpen, setIsSelectResidenceOpen] = useState<boolean>(
    false
  );

  const previousScreen = useAppSelector(getCurrentScreen);
  const isFatca = (value: string) => value === fatcaOptions.TRUE;
  const isSoi = (value: string) => value === soiOptions.TRUE;
  const isPep = (value: string) => value === pepOptions.TRUE;

  useEffect(() => {
    trackAnalyticsEvent(AnalyticsEvents.HOME_EXITO_PASO5_PROCESO_COMITENTE);
    trackFbEvent(FbEvents.HOME_EXITO_PASO5_PROCESO_COMITENTE);

    trackScreen(
      onboardingScreenNames.personalQuestions,
      previousScreen,
      undefined,
      Projects.ONBOARDING
    );

    dispatch(setCurrentScreen(onboardingScreenNames.personalQuestions));
  }, []);

  const formik = useFormik({
    initialValues: {
      residenceCountry: "AR",
      civilStatus: "",
      fatca: fatcaOptions.FALSE,
      soi: soiOptions.FALSE,
      pep: pepOptions.FALSE,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (
      values: Values,
      { setSubmitting }: FormikHelpers<Values>
    ) => {
      setSubmitting(true);
      const { civilStatus, fatca, soi, pep, residenceCountry } = values;

      try {
        await API.patch<CreateAccountData>(onBoardingService.createAccount, {
          residenceCountry,
          civilStatus,
          fatca: isFatca(fatca),
          soi: isSoi(soi),
          pep: isPep(pep),
        });

        // Extra loading time to process onboarding result
        await new Promise((resolve) => {
          setTimeout(resolve, 5000);
        });

        goToNextStep();
      } catch (error: any) {
        setAccountError(
          error?.response?.data?.message ||
            error?.message ||
            "An unexpected error occurred"
        );
        setError(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onConfirm = () => {
    const properties = {
      Residence: isArgResident,
      Marital_Status: formik.values.civilStatus,
      FATCA: isFatca(formik.values.fatca),
      SOI: isSoi(formik.values.soi),
      PEP: isPep(formik.values.pep),
    };

    trackAction(
      `${onboardingScreenNames.personalQuestions} - Click Send`,
      properties,
      Projects.ONBOARDING
    );
  };

  const onClickBack = () => {
    trackAction(
      `${onboardingScreenNames.personalQuestions} - Click Quit`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  const onClickTooltip = () => {
    trackAction(
      `${onboardingScreenNames.personalQuestions} - Click info tooltip`,
      undefined,
      Projects.ONBOARDING
    );

    setDisplayFactaInfo(true);
  };

  if (error) {
    return <Rejected isErrorState />;
  }

  if (formik.isSubmitting) {
    return <LoadingScreen isFinalStep />;
  }

  const getSelectedValue = (value: string, options: Option[]) =>
    options.find((option: Option) => option.value === value)?.label || "";

  return (
    <div className={styles.personalInfoContainer}>
      <div className={styles.content}>
        <header>
          <NavigationHeader title="Datos personales" onClick={onClickBack} />
          <Stepper stepsCount={4} currentStep={4} withoutMarginBottom />
        </header>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h2"
          color="var(--slate900)"
          className={styles.title}
        >
          Para finalizar, respondé las siguientes preguntas:
        </Heading>

        <form onSubmit={formik.handleSubmit}>
          <section>
            <Text variant={TextVariants.RegularText} color="var(--slate900)">
              ¿Residís en Argentina?
            </Text>

            <InputRadio
              value="argentinianTrue"
              name="residence"
              key="argentinianTrue"
              onChange={() => setIsArgResident(true)}
              checked={isArgResident}
            >
              Sí, soy residente argentino
            </InputRadio>
            <InputRadio
              value="argentinianFalse"
              name="residence"
              key="argentinianFalse"
              onChange={() => setIsArgResident(false)}
              checked={!isArgResident}
            >
              No, no soy residente argentino
            </InputRadio>
          </section>

          {!isArgResident && (
            <section>
              <Text
                variant={TextVariants.RegularText}
                color="var(--slate900)"
                className={styles.sectionSubtitle}
              >
                ¿Dónde residís?
              </Text>

              <Select
                label="Lugar de residencia"
                placeholder="Seleccionar"
                value={getSelectedValue(
                  formik.values.residenceCountry,
                  residenceOptions
                )}
                open={isSelectResidenseOpen}
                setOpen={setIsSelectResidenceOpen}
              >
                <SelectBottomSheet
                  title="Seleccioná un país"
                  value={formik.values.residenceCountry}
                  options={residenceOptions}
                  setOpen={setIsSelectResidenceOpen}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="residenceCountry"
                />
              </Select>
            </section>
          )}

          <section>
            <Text
              variant={TextVariants.RegularText}
              color="var(--slate900)"
              className={styles.sectionSubtitle}
            >
              ¿Cuál es tu estado civil?
            </Text>

            <Select
              label="Estado civil"
              placeholder="Seleccionar"
              value={getSelectedValue(
                formik.values.civilStatus,
                civilStatusOptions
              )}
              open={isSelectCivilStatusOpen}
              setOpen={setIsSelectCivilStatusOpen}
            >
              <SelectBottomSheet
                title="Seleccioná un estado"
                value={formik.values.civilStatus}
                options={civilStatusOptions}
                setOpen={setIsSelectCivilStatusOpen}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="civilStatus"
              />
            </Select>
          </section>

          <section>
            <Text variant={TextVariants.RegularText} color="var(--slate900)">
              ¿Sos US Person/FATCA?
              <Info className={styles.infoFatca} onClick={onClickTooltip} />
            </Text>

            <InputRadio
              value={fatcaOptions.TRUE}
              name="fatca"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.fatca === fatcaOptions.TRUE}
            >
              Sí, soy US Person/FATCA
            </InputRadio>
            <InputRadio
              value={fatcaOptions.FALSE}
              name="fatca"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.fatca === fatcaOptions.FALSE}
            >
              No, no soy US Person/FATCA
            </InputRadio>
          </section>

          <UIModalSlider
            open={displayFactaInfo}
            onToggleDisplay={() => setDisplayFactaInfo(false)}
          >
            <FatcaInfo onClick={setDisplayFactaInfo} />
          </UIModalSlider>

          <section>
            <Text variant={TextVariants.RegularText} color="var(--slate900)">
              ¿Sos sujeto obligado (SOI)?
            </Text>

            <InputRadio
              value={soiOptions.TRUE}
              name="soi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={isSoi(formik.values.soi)}
            >
              Sí, soy sujeto obligado (SOI)
            </InputRadio>
            <InputRadio
              value={soiOptions.FALSE}
              name="soi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={!isSoi(formik.values.soi)}
            >
              No, no soy sujeto obligado (SOI)
            </InputRadio>
          </section>

          <section>
            <Text variant={TextVariants.RegularText} color="var(--slate900)">
              ¿Sos persona expuesta políticamente (PEP)?
            </Text>

            <InputRadio
              value={pepOptions.TRUE}
              name="pep"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={isPep(formik.values.pep)}
            >
              Sí, soy persona expuesta políticamente (PEP)
            </InputRadio>
            <InputRadio
              value={pepOptions.FALSE}
              name="pep"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={!isPep(formik.values.pep)}
            >
              No, no soy persona expuesta políticamente (PEP)
            </InputRadio>
          </section>

          <div className={styles.buttonContainer}>
            <FilledButton
              type="submit"
              disabled={!formik.isValid || !formik.dirty}
              isLoading={formik.isSubmitting}
              onClick={onConfirm}
            >
              Enviar
            </FilledButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInfo;
