import CocosCardIntro2 from "lotties/cocos-card-02.json";
import Lottie from "react-lottie";

const CocosCardIntroAnimation2: React.FC = () => (
  <div>
    <Lottie
      isPaused={false}
      options={{
        loop: false,
        autoplay: true,
        animationData: CocosCardIntro2,
        rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
      }}
      height={350}
      width={300}
      isClickToPauseDisabled={true}
    />
  </div>
);

export default CocosCardIntroAnimation2;
