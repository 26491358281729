import { Typography } from "@mui/material";
import { Currency } from "components/common/currency";
import { useMarket } from "context/MarketProvider";
import { Instruments, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";

const ShortHeader: React.FC = () => {
  const {
    currency,
    available,
    settlementDays,
    selectedInstrumentType,
  } = useMarket();

  return (
    <div className="options-resume">
      <div>
        <Typography variant="textS">Moneda: </Typography>
        <Typography variant="textS_bold">
          {currency === Currencies.ARS ? "AR$" : "US$"}
        </Typography>
        <Typography variant="textS">|</Typography>
        <Typography variant="textS">Plazo: </Typography>
        <Typography variant="textS_bold">
          {selectedInstrumentType === Instruments.FCI ||
          settlementDays === SettlementTerms.CI
            ? "C.I."
            : settlementDays}
        </Typography>
        <Typography variant="textS">|</Typography>
        <Typography variant="textS">Disponible: </Typography>
        <Typography variant="textS_bold">
          <Currency withoutDecimals={true}>{available}</Currency>
        </Typography>
      </div>
    </div>
  );
};

export default ShortHeader;
