import { AccountsResponse } from "interfaces/api-responses";
import { useAppSelector } from "hooks/redux";
import { getLoggedUser } from "store/selectors/user.selector";
import { getUserFullName } from "utils";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface DetailsBodyProps {
  bankAccount?: AccountsResponse;
}

const DetailsBody: React.FC<DetailsBodyProps> = ({ bankAccount }) => {
  const user = useAppSelector(getLoggedUser);
  if (!user || !bankAccount) return null;

  const { entity, cbu_cvu } = bankAccount;

  const { first_name, last_name } = user;
  return (
    <div className={styles.details}>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Banco
        </Text>
        <Text
          variant={TextVariants.RegularText}
          component="span"
          color="var(--slate900)"
        >
          {entity}
        </Text>
      </div>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Enviar a
        </Text>
        <Text
          variant={TextVariants.RegularText}
          component="span"
          color="var(--slate900)"
        >
          {getUserFullName(first_name, last_name)}
        </Text>
      </div>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          CBU
        </Text>
        <Text
          variant={TextVariants.SemiboldText}
          component="span"
          color="var(--slate900)"
        >
          {cbu_cvu}
        </Text>
      </div>
    </div>
  );
};

export default DetailsBody;
