import { createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import API from "apis";
import { factorsService, userService } from "apis/services";
import { ampli } from "ampli";
import { Factor, User } from "@supabase/supabase-js";
import {
  getDolarMep,
  getInvestmentSuggested,
  getMarketSchedules,
  getTickersRules,
} from "features/markets";
import AuthAPI from "apis/auth";
import { isEmpty } from "lodash";
import { MFAType } from "interfaces/auth";
import secureStorage from "utils/secureStorage";

import {
  CocosProducts,
  Investor,
  UserData,
  UserSettings,
} from "../../interfaces/user";
import { fetchUserData, setLoading, setUserError } from "./userSlice";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async ({ user }: { user: User }, { dispatch }) => {
    try {
      dispatch(check2FA());
      dispatch(checkTrustedDevice());
      const { data: userSettings } = await API.get<UserSettings>(
        userService.userSettings
      );
      const { data: userData } = await API.get<UserData>(userService.me);

      const userAccount = userData.id_accounts[0];

      ampli.identify(userData.id, {
        is_phone_verified: userData.phone_confirmed,
        crypto_enabled: userData.enabled_products.includes(
          CocosProducts.CRYPTO
        ),
        pay_enabled: userData.enabled_products.includes(CocosProducts.PAY),
        tier: userData.tier,
        ...(userData.hasAccount && {
          owned_account_number: userAccount,
        }),
        ...(userData.status_crypto && {
          crypto_status: userData.status_crypto,
        }),
        ...(userData.card_status && {
          card_status: userData.card_status,
        }),
      });

      dispatch(
        fetchUserData({
          user: user,
          userData,
          statusOnboarding: userSettings.statusOnboarding,
        })
      );
      dispatch(getInitialData());
    } catch (e: any) {
      console.error("Error: ", e);
      dispatch(
        setUserError({
          message: "Hubo un error al loguear al usuario",
          status: 500,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateCableNotification = createAsyncThunk(
  "user/updateCableNotification",
  async (userData: any, { dispatch }) => {
    try {
      API.patch(userService.userSettings, {
        cable_notification: true,
      });

      return userData;
    } catch (e: any) {
      dispatch(
        setUserError({
          message: "Hubo un error para aceptar la notificación.",
          status: 500,
        })
      );
    }
  }
);

export const getInvestorProfileData = createAsyncThunk(
  "user/getInvestorProfileData",
  async (_, { dispatch }) => {
    try {
      const { data } = await API.get<Investor>(userService.investorProfile);
      return data;
    } catch (error: any) {
      dispatch(setUserError({ message: error.message, status: error.status }));
    }
  }
);

export const check2FA = createAsyncThunk<
  boolean,
  void,
  { rejectValue: boolean }
>("user/check2FA", async (_, { rejectWithValue }) => {
  try {
    const { data } = await AuthAPI.get(factorsService.factors);
    const factors: Factor[] = data.all.filter(
      (x: Factor) => x.status === "verified" && x.factor_type === MFAType.TOTP
    );
    return !isEmpty(factors);
  } catch (e: any) {
    console.error("Error: ", e);

    return rejectWithValue(false);
  }
});

export const checkTrustedDevice = createAsyncThunk(
  "user/checkTrustedDevice",
  async () => {
    return !(await secureStorage.hasKey("trustedDeviceToken"));
  }
);

// Chequear cuando se haga el refactor de market
export const getInitialData = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(getInvestmentSuggested());
    dispatch(getDolarMep());
    dispatch(getMarketSchedules());
    dispatch(getTickersRules());
  };
};
