import { ButtonBase } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Avatar from "components/common/avatar/index";
import ToCryptoButton, {
  ToCryptoButtonSizes,
} from "components/common/to-crypto-button";
import CocosGoldPage from "components/page-cocos-gold";
import { useAppState } from "context/AppStateProvider";
import { useAppSelector } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { CocosProducts, Tiers } from "interfaces/user";
import { Link, Navigate } from "react-router-dom";
import { useAccountTierQuery } from "apis/user/queries/useAccountTierQuery";
import mainRoutes, { screenNames } from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { trackAction } from "utils/amplitude";
import { DesktopOrTablet, Mobile } from "utils/responsive";

import "./index.scss";

export const HeaderHome = () => {
  const { openRightModalContent } = useAppState();
  const user = useAppSelector(getLoggedUser);
  const { isMobile } = useDeviceCheck();
  const { data: accountTier } = useAccountTierQuery();

  const tier = accountTier?.tier;
  const isGold = tier === Tiers.GOLD;
  const showGold = false;

  const openGold = () => {
    openRightModalContent(<CocosGoldPage />);
    handleGold();
  };

  const handleGold = () => {
    trackAction(`${screenNames.home} - Click Activate Gold`);
  };

  const handleProfileClick = () => {
    trackAction(`${screenNames.home} - Click Profile`);
  };

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { hasAccount, enabled_products } = user;

  const isCryptoAvailable =
    isMobile && hasAccount && enabled_products.includes(CocosProducts.CRYPTO);

  const HeaderContent = (
    <div className={`header-home ${isMobile ? "mobile" : "desktop"}`}>
      {isCryptoAvailable ? (
        <div className="to-crypto">
          <ToCryptoButton size={ToCryptoButtonSizes.Large} />
        </div>
      ) : (
        <div className={`name-and-gold ${isMobile ? "mobile" : "desktop"}`}>
          <Typography className="name-user" variant="heading4">
            Hola!
          </Typography>
          {isGold && (
            <Typography
              className={`gold ${isMobile ? "mobile" : "desktop"}`}
              sx={{ fontSize: isMobile ? "14px" : "16px" }}
            >
              Tu cuenta es gold ★
            </Typography>
          )}
          {showGold &&
            (isMobile ? (
              <Typography className={`gold ${isMobile ? "mobile" : "desktop"}`}>
                ¿Querés ser Gold?&nbsp;
                <Link to={mainRoutes.cocosGold} onClick={handleGold}>
                  <b className="gold-link">Activalo acá</b>
                </Link>
              </Typography>
            ) : (
              <ButtonBase onClick={openGold}>
                <Typography className="gold">
                  ¿Querés ser Gold?&nbsp;
                  <b>Activalo acá</b>
                </Typography>
              </ButtonBase>
            ))}
        </div>
      )}

      <div className="header-icons">
        <Link to={mainRoutes.profile} onClick={handleProfileClick}>
          <Avatar />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="header-home-subcontent">
      <Mobile>
        <>
          <AppBar>{HeaderContent}</AppBar>
        </>
      </Mobile>
      <DesktopOrTablet>
        <>{HeaderContent}</>
      </DesktopOrTablet>
    </div>
  );
};
