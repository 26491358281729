import type { ReactNode } from "react";

import type { BalanceCurrencies } from "interfaces/wallet";
import classNames from "classnames";
import { SettlementPeriod } from "interfaces/portfolio";
import { formatQuantity } from "utils";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import px2rem from "utils/px2rem";
import HiddenValue from "components/common/HiddenValue";

import Text, { TextVariants } from "../../Text";

import styles from "./styles.module.scss";

interface SettlmentsDetailsProps {
  amount: number;
  currency: BalanceCurrencies;
  isBalanceHidden: boolean;
  period: string;
  quantity: number;
}

const SettlmentsDetails = ({
  amount,
  currency,
  isBalanceHidden,
  period,
  quantity,
}: SettlmentsDetailsProps) => {
  const periodText = period === SettlementPeriod.CI ? "Hoy (C. I.)" : "En 24hs";
  const displayQuantity = quantity > 0 ? formatQuantity(quantity) : "--";
  const priceLabelColor = isBalanceHidden
    ? "var(--slate800)"
    : "var(--slate900)";

  const balanceHidden = (item: ReactNode) => {
    if (isBalanceHidden)
      return (
        <HiddenValue
          className={styles.hiddenValue}
          size={px2rem(7)}
          color="var(--slate800)"
        />
      );
    return item;
  };

  return (
    <div className={styles.assetSettlementWrapper}>
      <div className={styles.settlementRowHeading}>
        {
          <Text color="var(--slate800)" variant={TextVariants.RegularTextS}>
            {periodText}
          </Text>
        }
      </div>
      <div
        className={classNames(
          styles.settlementRow,
          styles.settlementRowQuantity
        )}
      >
        {balanceHidden(
          <Text color="var(--slate800)" variant={TextVariants.RegularTextS}>
            {displayQuantity}
          </Text>
        )}
      </div>
      <div
        className={classNames(styles.settlementRow, styles.settlementRowAmount)}
      >
        {amount > 0 ? (
          <PriceLabel
            price={amount}
            currency={currency}
            color={priceLabelColor}
            size={PriceLabelSizes.Xsmall}
            isBalanceHidden={isBalanceHidden}
          />
        ) : (
          <Text color="var(--slate800)" variant={TextVariants.RegularTextS}>
            --
          </Text>
        )}
      </div>
    </div>
  );
};

export default SettlmentsDetails;
