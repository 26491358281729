import styles from "./styles.module.scss";

const CameraVisor: React.FC = () => {
  return (
    <div className={styles.CameraVisorContainer}>
      <div className={styles.visor} />
      <div className={styles.topLeftCorner} />
      <div className={styles.topRightCorner} />
      <div className={styles.bottomLeftCorner} />
      <div className={styles.bottomRightCorner} />
    </div>
  );
};

export default CameraVisor;
