import { ReactNode } from "react";

import { ThemeVariants } from "interfaces/theme";
import { ConfirmOrder } from "components/common/confirm-order";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import {
  Instruments,
  InvestmentSuggestedResponse,
  OrderType,
} from "interfaces/markets";
import Drawer from "layouts/drawer";
import { Navigate, useNavigate } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import {
  setConfirmOrderData,
  updateOrderOptions,
  getOrderById,
} from "features/markets";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import { formatToCurrency } from "utils";
import { trackAction } from "utils/amplitude";
import FilledButton from "components/common/FilledButton";
import CapitalReceivePage from "components/page-capital-receive";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

import InvestmentErrors from "./components/InvestmentErrors";

import styles from "./styles.module.scss";

interface InvestmentSuggestionButtonProps {
  instrument: InvestmentSuggestedResponse;
  isMarketOpen: boolean;
  operationAble: boolean;
  errorMessage: null | ReactNode;
}

const InvestmentSuggestionButton: React.FC<InvestmentSuggestionButtonProps> = ({
  instrument,
  isMarketOpen,
  operationAble,
  errorMessage,
}) => {
  const { isMobile } = useDeviceCheck();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();

  const user = useAppSelector(getLoggedUser);
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const wallet = useAppSelector((state: RootState) => state.wallet.wallet);

  const { isIOS } = getPlatform();

  const handleCloseSidebar = () => {
    if (isMobile) {
      return navigate(-1);
    }

    dispatch(closeRightModal());
  };

  const handleClickOrderRow = (orderId: string) => {
    dispatch(getOrderById(orderId));
  };

  const onErrorBannerClick = () => {
    if (isMobile) {
      navigate(mainRoutes.receive);
    } else {
      openRightModalContent(
        <Drawer withHeader={false}>
          <CapitalReceivePage isDesktop />
        </Drawer>
      );
    }
  };

  const goToOrder = (data: any) => (
    <Drawer>
      <ConfirmOrder
        data={data}
        onClickOrderDetail={handleClickOrderRow}
        onClose={handleCloseSidebar}
        instrument_type={Instruments.BONOS_CORP}
      />
    </Drawer>
  );

  const confirmOrder = () => {
    if (instrument && instrument.long_ticker && order.quantity) {
      dispatch(
        updateOrderOptions({
          ...order,
          amount: order.quantity * (instrument.price / 100),
        })
      );

      trackAction(`${screenNames.investment} - Show Confirmation`, {
        side: OrderType.Buy,
        ticker: instrument.long_ticker,
        type: Instruments.BONOS_CORP,
        currency: order.currency,
        price: instrument.price,
        amount: order.amount,
        quantity: order.quantity,
        fee: 0,
      });
      const data = [
        {
          label: "Especie",
          value: instrument.long_ticker.split("-")[0],
        },
        {
          label: "Plazo de liquidación",
          value: order.term,
        },
        {
          label: "Precio",
          value: formatToCurrency(instrument.price),
        },
        {
          label: "Comisiones Estimadas",
          value: 0,
        },
        {
          label: "Monto a Invertir",
          value: order.quantity * (instrument.price / 100),
        },
      ];
      dispatch(setConfirmOrderData(data));
      if (isMobile) {
        navigate(mainRoutes.orderConfirm);
      } else {
        openRightModalContent(goToOrder(data));
      }
    }
  };

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { hasAccount } = user;

  const disabled = !hasAccount || !operationAble || !isMarketOpen;

  return (
    <div
      className={classNames(
        { [styles.ios]: isIOS },
        styles.confirmButtonContainer
      )}
    >
      {errorMessage && wallet && (
        <InvestmentErrors
          instrument={instrument}
          handleOnClick={onErrorBannerClick}
        >
          {errorMessage}
        </InvestmentErrors>
      )}
      <FilledButton
        disabled={disabled}
        variant={ThemeVariants.Capital}
        onClick={confirmOrder}
      >
        Confirmar Compra
      </FilledButton>
    </div>
  );
};

export default InvestmentSuggestionButton;
