import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { Currencies } from "interfaces/wallet";
import Heading, { HeadingVariants } from "components/common/Heading";
import getCurrencyIcon from "components/page-cocos-card/Home/PaymentMethodList/CurrencyIcon";
import { TaxTypes } from "components/page-cocos-card/constants";

import styles from "./styles.module.scss";

interface TaxesDetail {
  amountDetails: Record<string, any>;
  amountSettlement: number;
}

const TaxesDetail: React.FC<TaxesDetail> = ({
  amountDetails,
  amountSettlement,
}) => {
  const getTaxesAmount = (amountType: string) => {
    return amountDetails
      .filter((amount: any) => amount.type === amountType)
      .reduce((acc: number, tax: any) => acc + Number(tax.amount), 0);
  };

  return (
    <>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate800)"
          component="span"
        >
          Monto original
        </Heading>
        <Heading
          variant={HeadingVariants.RegularValueS}
          color="var(--slate800)"
          component="span"
          className={styles.subtitle}
        >
          {getCurrencyIcon({
            currency: PaymentCurrencies.USD,
            size: 16,
          })}
          <PriceLabel
            price={amountSettlement}
            size={PriceLabelSizes.Small}
            currency={Currencies.USD}
          />
        </Heading>
      </div>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate800)"
          component="span"
        >
          Impuestos
        </Heading>
        <Heading
          variant={HeadingVariants.RegularValueS}
          color="var(--slate800)"
          component="span"
          className={styles.subtitle}
        >
          <PriceLabel
            price={getTaxesAmount(TaxTypes.TAX)}
            size={PriceLabelSizes.Small}
          />
        </Heading>
      </div>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate800)"
          component="span"
        >
          Cargos
        </Heading>
        <Heading
          variant={HeadingVariants.RegularValueS}
          color="var(--slate800)"
          component="span"
          className={styles.subtitle}
        >
          <PriceLabel
            price={getTaxesAmount(TaxTypes.MARKUP)}
            size={PriceLabelSizes.Small}
          />
        </Heading>
      </div>
    </>
  );
};

export default TaxesDetail;
