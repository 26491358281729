import { SVGProps } from "react";

export interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CocosCapFullIcon: React.FC<IconProps> = ({
  width = 114,
  height = 80,
  color = "var(--slate500)",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 114 80"
      fill="none"
      {...props}
    >
      <path
        d="M0.0045403 39.8235C-0.0319694 38.5187 0.195034 37.2199 0.67194 36.005C1.14885 34.7902 1.86583 33.6842 2.77991 32.7536C3.69399 31.8229 4.78633 31.0867 5.99145 30.589C7.19656 30.0913 8.48962 29.8425 9.79314 29.8574C12.0425 29.7908 14.2427 30.5245 16.0032 31.9282C17.7637 33.3318 18.9705 35.3147 19.4095 37.5249H13.7692C13.467 36.737 12.9197 36.0673 12.2081 35.6149C11.4964 35.1625 10.6584 34.9514 9.81773 35.0128C7.59604 35.0128 5.51368 36.7122 5.51368 39.8235C5.51368 42.9349 7.61242 44.6753 9.81773 44.6753C10.7052 44.7328 11.585 44.4791 12.3061 43.9578C13.0271 43.4365 13.5444 42.6801 13.7692 41.8184H19.4095C19.0429 44.0881 17.8659 46.1475 16.0972 47.6134C14.3285 49.0796 12.0883 49.853 9.79314 49.7896C8.48868 49.8079 7.19402 49.5616 5.98731 49.065C4.78066 48.5685 3.68689 47.8326 2.77211 46.901C1.85733 45.9698 1.14047 44.8625 0.664811 43.6461C0.189148 42.4296 -0.0354567 41.1293 0.0045403 39.8235Z"
        fill={color}
      />
      <path
        d="M20.6887 39.8235C20.6807 37.8401 21.2613 35.899 22.357 34.2467C23.4527 32.5944 25.0141 31.3055 26.8428 30.5434C28.6715 29.7815 30.6851 29.5808 32.6279 29.9669C34.5707 30.3531 36.3551 31.3087 37.7545 32.7123C39.154 34.116 40.1053 35.9043 40.4877 37.8505C40.8701 39.7966 40.6664 41.8126 39.9024 43.6425C39.1385 45.4724 37.8486 47.0338 36.1968 48.1283C34.5449 49.2227 32.6056 49.8009 30.6249 49.7896C29.3113 49.8164 28.0059 49.577 26.7871 49.0857C25.5684 48.5938 24.4616 47.8607 23.5333 46.9296C22.605 45.9985 21.8745 44.8889 21.3856 43.6677C20.8967 42.4465 20.6597 41.1389 20.6887 39.8235ZM35.1585 39.8235C35.1585 36.9831 33.2975 34.9964 30.6823 34.9964C28.0671 34.9964 26.1979 36.9831 26.1979 39.8235C26.1979 42.664 28.0589 44.6506 30.6823 44.6506C33.3057 44.6506 35.1585 42.6804 35.1585 39.8235Z"
        fill={color}
      />
      <path
        d="M42.2419 39.8235C42.2054 38.5187 42.4324 37.2199 42.9092 36.005C43.3862 34.7902 44.1031 33.6842 45.0172 32.7536C45.9313 31.8229 47.0235 31.0867 48.2287 30.589C49.4339 30.0913 50.7269 29.8425 52.0306 29.8574C54.2796 29.7908 56.4802 30.5245 58.2406 31.9282C60.001 33.3318 61.2076 35.3147 61.6469 37.5249H56.0067C55.7044 36.737 55.1567 36.0673 54.4453 35.6149C53.7338 35.1625 52.8956 34.9514 52.055 35.0128C49.8333 35.0128 47.7509 36.7122 47.7509 39.8235C47.7509 42.9349 49.8497 44.6753 52.055 44.6753C52.9425 44.7328 53.8225 44.4791 54.5434 43.9578C55.2642 43.4365 55.7819 42.6801 56.0067 41.8184H61.6469C61.2804 44.0881 60.1033 46.1475 58.3345 47.6134C56.5656 49.0796 54.3256 49.853 52.0306 49.7896C50.7259 49.8079 49.4316 49.5616 48.2245 49.065C47.0179 48.5685 45.9242 47.8326 45.0094 46.901C44.0946 45.9698 43.3778 44.8625 42.9021 43.6461C42.4264 42.4296 42.2018 41.1293 42.2419 39.8235Z"
        fill={color}
      />
      <path
        d="M62.9418 39.8235C62.9333 37.8401 63.5143 35.899 64.6097 34.2467C65.7056 32.5944 67.267 31.3055 69.0955 30.5434C70.9244 29.7815 72.9377 29.5808 74.8807 29.9669C76.8237 30.3531 78.608 31.3087 80.0075 32.7123C81.4065 34.116 82.3583 35.9043 82.7403 37.8505C83.1228 39.7966 82.9192 41.8126 82.1551 43.6425C81.3911 45.4724 80.1014 47.0338 78.4499 48.1283C76.7979 49.2227 74.8582 49.8009 72.8777 49.7896C71.5641 49.8164 70.2589 49.577 69.0401 49.0857C67.8213 48.5938 66.7146 47.8607 65.7863 46.9296C64.858 45.9985 64.1273 44.8889 63.6382 43.6677C63.1497 42.4465 62.9127 41.1389 62.9418 39.8235ZM77.4113 39.8235C77.4113 36.9831 75.5504 34.9964 72.9349 34.9964C70.3199 34.9964 68.4591 36.9831 68.4591 39.8235C68.4591 42.664 70.3119 44.6506 72.9349 44.6506C75.5584 44.6506 77.4113 42.6804 77.4113 39.8235Z"
        fill={color}
      />
      <path
        d="M84.5355 43.0416L89.8641 43.0826C89.9542 44.4536 90.8562 45.4305 92.8236 45.4305C94.6108 45.4305 95.2828 44.7245 95.2828 43.7312C95.2828 42.7379 94.2419 42.2453 92.053 41.958C88.3308 41.5065 85.224 39.881 85.224 35.9159C85.224 32.1149 87.9376 29.8574 92.6021 29.8574C97.2671 29.8574 100.464 32.3859 100.464 36.2278L95.0289 36.1868C94.9553 35.136 94.209 34.3643 92.6185 34.3643C91.2331 34.3643 90.6756 35.0129 90.6756 35.7927C90.6756 36.8435 91.0693 37.1145 94.0532 37.5413C98.3165 38.1571 100.964 39.5198 100.964 43.4028C100.964 47.7866 97.3572 49.8061 92.7663 49.8061C87.044 49.8061 84.5355 46.4075 84.5355 43.0416Z"
        fill={color}
      />
      <path
        d="M92.1595 22.8712C87.6996 22.9205 83.5926 20.7532 81.7154 16.8948C81.0499 15.4828 80.7049 13.9409 80.7049 12.3797C80.7049 10.8184 81.0499 9.27655 81.7154 7.86457C83.0661 4.87718 85.4305 2.46592 88.3886 1.059L90.569 0L92.6927 4.35916L90.5118 5.42638C88.5862 6.32972 87.0416 7.88531 86.1504 9.81838C85.7834 10.586 85.5891 11.4248 85.5807 12.2757C85.5722 13.1267 85.7496 13.9692 86.1011 14.744C87.6672 17.9456 92.1844 18.9636 96.1769 17.018L98.3573 15.9507L100.481 20.3099L98.3001 21.3771C96.3975 22.313 94.3118 22.8148 92.1924 22.8466"
        fill={color}
      />
      <path
        d="M103.957 28.5192L101.735 24.2011L103.892 23.0928C107.843 21.0569 109.737 16.8209 108.105 13.6521C106.474 10.4833 101.941 9.5474 97.9889 11.5915L95.8329 12.6998L93.6111 8.38166L95.7672 7.27342C102.096 3.98967 109.565 5.87781 112.418 11.4273C115.271 16.9769 112.418 24.1518 106.105 27.4109L103.957 28.5192Z"
        fill={color}
      />
      <path
        d="M28.3701 71.3064C28.3273 70.6939 28.4699 70.0824 28.7794 69.5521C29.0889 69.0218 29.5508 68.5975 30.1049 68.3342C30.659 68.0709 31.2796 67.9808 31.8855 68.0761C32.4914 68.1713 33.0546 68.4473 33.5014 68.8683C33.9482 69.2888 34.258 69.8346 34.3903 70.4344C34.5226 71.0342 34.4712 71.6598 34.2429 72.23C34.0146 72.7997 33.6199 73.2878 33.1104 73.63C32.6009 73.9716 32.0004 74.1518 31.387 74.1467C31.0002 74.1692 30.6129 74.1124 30.2487 73.9796C29.8845 73.8473 29.5511 73.6417 29.2688 73.3761C28.9865 73.1104 28.7612 72.7899 28.6067 72.4337C28.4522 72.0779 28.3717 71.6945 28.3701 71.3064Z"
        fill={color}
      />
      <path
        d="M36.978 64.3201C36.9425 63.0211 37.1695 61.7281 37.6455 60.5191C38.1215 59.3102 38.8365 58.2101 39.7476 57.2846C40.6588 56.3591 41.7472 55.6275 42.9478 55.1337C44.1483 54.64 45.436 54.3946 46.7337 54.4115C48.9697 54.3486 51.1558 55.0798 52.905 56.4755C54.6546 57.8713 55.8546 59.8415 56.293 62.0379H50.6687C50.3693 61.2522 49.8248 60.5839 49.1157 60.1329C48.4066 59.6819 47.5712 59.4721 46.7337 59.5341C44.5203 59.5341 42.4543 61.2255 42.4543 64.3201C42.4543 67.4153 44.5448 69.1391 46.7337 69.1391C47.6172 69.1982 48.4939 68.9471 49.2119 68.4285C49.9304 67.91 50.4458 67.1567 50.6687 66.2988H56.2765C55.9142 68.5538 54.7475 70.6015 52.9927 72.0611C51.238 73.5206 49.0143 74.2941 46.7337 74.2373C45.4347 74.2565 44.1452 74.0125 42.9431 73.5187C41.7409 73.0255 40.6511 72.2934 39.7394 71.3665C38.8276 70.4396 38.1129 69.3376 37.6383 68.1263C37.1637 66.9155 36.9391 65.6206 36.978 64.3201Z"
        fill={color}
      />
      <path
        d="M57.5719 64.3201C57.5719 58.8859 61.2039 54.4115 66.1064 54.4115C68.8528 54.4115 71.0661 55.4871 72.1976 57.3916V55.0028H77.4441V73.6379H72.1484V71.3064C71.0168 73.1944 68.82 74.2781 66.1064 74.2781C61.1466 74.2537 57.5719 69.7877 57.5719 64.3201ZM71.9597 64.3201C71.985 63.711 71.8898 63.1023 71.6795 62.5297C71.4693 61.9571 71.1478 61.432 70.7343 60.9842C70.3204 60.5365 69.8224 60.1751 69.2691 59.9203C68.7153 59.6659 68.1169 59.5237 67.5082 59.5012C65.0485 59.5012 63.0568 61.3156 63.0568 64.3201C63.0568 67.489 65.1391 69.1391 67.4918 69.1391C68.1042 69.1269 68.7083 68.9912 69.2672 68.7402C69.8266 68.4895 70.3298 68.1282 70.7465 67.6786C71.1633 67.229 71.4857 66.7 71.6941 66.1228C71.9024 65.546 71.993 64.9331 71.9597 64.3201Z"
        fill={color}
      />
      <path
        d="M81.1658 55.0028H86.4292V57.3503C87.0637 56.4427 87.908 55.7021 88.8903 55.1919C89.8721 54.6818 90.9628 54.4166 92.0694 54.4199C97.4065 54.4199 101.038 58.9019 101.038 64.3122C101.038 69.722 97.3572 74.2452 92.1595 74.2452C91.074 74.2546 90.0016 74.0031 89.0329 73.5117C88.0643 73.0203 87.2275 72.3037 86.593 71.4214L86.6995 73.6708V80H81.1658V55.0028ZM95.578 64.3201C95.578 61.3156 93.545 59.5012 91.0857 59.5012C89.8655 59.5885 88.7237 60.1357 87.8902 61.0321C87.0566 61.9285 86.5934 63.1079 86.5934 64.3323C86.5934 65.5573 87.0566 66.7367 87.8902 67.633C88.7237 68.5294 89.8655 69.0767 91.0857 69.164C93.4633 69.164 95.578 67.4725 95.578 64.3201Z"
        fill={color}
      />
    </svg>
  );
};

export default CocosCapFullIcon;
