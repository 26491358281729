import React from "react";

import { Typography } from "@mui/material";
import { ArrowUpIcon } from "assets/icons/arrow-up-circle";
import MarketList from "components/common/MarketList";
import { NewListModal } from "components/common/new-list-modal";
import Caucion from "components/page-market/components/Caucion";
import Header from "components/page-market/components/Header/";
import SubSections from "components/page-market/components/SubSections";
import { useMarket } from "context/MarketProvider";
import { Instruments } from "interfaces/markets";
import CommonLayout from "layouts/common";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";

import MarketClosedModal from "../components/MarketClosedModal";
import MarketOptions from "./components/MarketOptions";

import "./index.scss";

interface MarketMobileProps {
  setOpenNewListModal: (value: boolean) => void;
  openNewListModal: boolean;
}

const MarketMobile: React.FC<MarketMobileProps> = ({
  setOpenNewListModal,
  openNewListModal,
}) => {
  const { errorMessage } = useAppSelector((state: RootState) => state.markets);
  const {
    currency,
    settlementDays,
    selectedInstrumentType,
    selectedInstrumentSubtype,
  } = useMarket();

  const isCaucion = selectedInstrumentType === Instruments.CAUCION;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="market-mobile">
      <MarketClosedModal />
      <CommonLayout withHeader={false}>
        <Header />
        {!isCaucion && (
          <div className="market-content">
            <MarketOptions />
            <div className="markets-container">
              <SubSections setOpenNewListModal={setOpenNewListModal} />
              {errorMessage?.message && (
                <div className="error-message">Ups, algo salió mal</div>
              )}
              <MarketList
                selectedInstrumentType={selectedInstrumentType}
                selectedInstrumentSubtype={selectedInstrumentSubtype}
                settlementDays={settlementDays}
                currency={currency}
              />
              <div className="hide-display-btn" onClick={scrollToTop}>
                <Typography variant="buttonS">Ir arriba</Typography>
                <div>
                  <ArrowUpIcon color="#0062e1" size={18} />
                </div>
              </div>
            </div>
          </div>
        )}
        {isCaucion && <Caucion />}
        <NewListModal
          open={openNewListModal}
          setOpenModal={setOpenNewListModal}
        />
      </CommonLayout>
    </div>
  );
};

export default MarketMobile;
