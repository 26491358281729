import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { bannerKeys } from "apis/queryKeyFactories";
import { BannersService } from "apis/services";
import { Banner } from "interfaces/api-responses";
import type { ErrorMessage } from "interfaces/errors";

export const fetchBanner = async (banner: string) => {
  const { data } = await API.get<Banner>(`${BannersService.banners}/${banner}`);

  return data;
};

export const useBanner = (banner: string) => {
  return useAppQuery<Banner, ErrorMessage>({
    queryKey: () => bannerKeys.banner(banner),
    queryFn: () => fetchBanner(banner),
    options: {
      onError: () => {
        return {
          message: "Hubo un error al obtener la información",
          status: 500,
        };
      },
    },
  });
};
