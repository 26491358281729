import { ATENCION_CAPITAL_EMAIL } from "constants/emails";

import { useEffect } from "react";

import IconBadge from "components/common/Badges/IconBadge";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import NavigationHeader from "components/common/NavigationHeader";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { useCreateAccount } from "context/CreateAccountProvider";
import { UserRoundX } from "lucide-react";
import { onboardingScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackAction, trackScreen } from "utils/amplitude";
import { AnalyticsEvents, trackAnalyticsEvent } from "utils/firebaseAnalytics";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { FbEvents, trackFbEvent } from "utils/fbTracking";

import styles from "../styles.module.scss";

const RejectedScreen = ({ isErrorState }: { isErrorState?: boolean }) => {
  const dispatch = useAppDispatch();
  const { accountError, goToHome, setStep, cuitError } = useCreateAccount();

  const previousScreen = useAppSelector(getCurrentScreen);
  const screenName = isErrorState
    ? onboardingScreenNames.error
    : onboardingScreenNames.rejected;

  const analyticsScreenName = isErrorState
    ? AnalyticsEvents.HOME_ERROR_PROCESO_COMITENTE
    : AnalyticsEvents.HOME_REJECTED_PROCESO_COMITENTE;

  const fbScreenName = isErrorState
    ? FbEvents.HOME_ERROR_PROCESO_COMITENTE
    : FbEvents.HOME_REJECTED_PROCESO_COMITENTE;

  useEffect(() => {
    trackAnalyticsEvent(analyticsScreenName);
    trackFbEvent(fbScreenName);

    trackScreen(
      `${screenName}: ${accountError}`,
      previousScreen,
      undefined,
      Projects.ONBOARDING
    );

    dispatch(setCurrentScreen(screenName));
  }, []);

  const onClickBack = () => {
    trackAction(`${screenName} - Click Quit`, undefined, Projects.ONBOARDING);

    goToHome();
  };

  const onClickGoToHome = () => {
    trackAction(`${screenName} -  Click Home`, undefined, Projects.ONBOARDING);

    goToHome();
  };

  const onClickRetry = async () => {
    trackAction(
      `${screenName} -  Click Try Again`,
      undefined,
      Projects.ONBOARDING
    );

    setStep(0);
  };

  const CXEmail = (
    <a
      href={`mailto:${ATENCION_CAPITAL_EMAIL}`}
      target="_blank"
      rel="noreferrer"
      className={styles.emailLink}
    >
      {ATENCION_CAPITAL_EMAIL}
    </a>
  );

  return (
    <div className={styles.finalContainer}>
      <NavigationHeader title="Algo salió mal" onClick={onClickBack} />
      <div className={styles.content}>
        <IconBadge backgroundColor="var(--red300)">
          <UserRoundX color="var(--red900)" />
        </IconBadge>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h2"
          color="var(--slate900)"
          className={styles.title}
        >
          No pudimos validar tu identidad
        </Heading>
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate800)"
          className={styles.text}
        >
          {isErrorState && !cuitError ? (
            <>
              Por favor, reintentá nuevamente. <br />
              Si el problema persiste, no dudes en comunicarte con {
                CXEmail
              }{" "}
              para que podamos ayudarte.
            </>
          ) : (
            <>
              Recordá que para abrir una cuenta comitente tenés que ser mayor de
              18 años y no tener una cuenta comitente abierta o en proceso de
              apertura. Por cualquier consulta, comunicate a {CXEmail}
            </>
          )}
        </Text>
      </div>

      <div className={styles.buttonContainer}>
        {!isErrorState && (
          <FilledButton onClick={onClickRetry}>Volver a intentar</FilledButton>
        )}
        <OutlinedButton onClick={onClickGoToHome}>
          Volver al inicio
        </OutlinedButton>
      </div>
    </div>
  );
};

export default RejectedScreen;
