import { Typography } from "@mui/material";
import { Instruments, InvestmentSuggestedResponse } from "interfaces/markets";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";

import styles from "./styles.module.scss";

interface BondTicketTopProps {
  instrument: InvestmentSuggestedResponse;
}

const BondTicketTop: React.FC<BondTicketTopProps> = ({ instrument }) => {
  return (
    <div className={styles.topCard}>
      <div className={styles.logo}>
        <InstrumentLogoUI
          logoFileName={instrument.logo_file_name}
          type={Instruments.BONOS_CORP}
          variant={InstrumentLogoVariants.Primary}
        />
      </div>
      <Typography className={styles.bondCode}>
        {instrument.instrument_code}
      </Typography>
      <Typography className={styles.bondName}>{instrument.name}</Typography>
    </div>
  );
};

export default BondTicketTop;
