import { useEffect, useRef, useState } from "react";

import { Typography } from "@mui/material";
import { ArrowLeftIcon } from "assets/icons/arrow-left";
import { Currency } from "components/common/currency";
import { DecimalRound } from "components/common/decimal-round-rules";
import RetryButton from "components/common/retry-button";
import { BidOfferTable } from "components/common/table-bid-offer";
import { usePrevious } from "hooks/usePrevious";
import { InstrumentData } from "interfaces/api-responses";
import { Schedules } from "interfaces/calendar";
import {
  Instruments,
  Method,
  OrderType,
  SettlementTerms,
} from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import _ from "lodash";
import { NumberFormatValues } from "react-number-format";
import { Navigate, useLocation } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import {
  resetOrder,
  setMarketDataDetail,
  updateOrderOptions,
} from "features/markets/marketsSlice";
import { getSellingPower, getWallet } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { checkMarketOpen } from "store/selectors/markets.selector";
import { getLoggedUser } from "store/selectors/user.selector";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { UIInput } from "components/common/input";
import {
  AmountInputSizes,
  UIAmountInput,
} from "components/common/input/amount";
import { InputSizes, UIPriceInput } from "components/common/input/price";
import UIModalInfo from "components/common/modalInfo";
import {
  OverlappedButtonsTypes,
  UIOverlappedButtons,
} from "components/common/overlapped-buttons";
import { SelectSizes, SelectTypes, UISelect } from "components/common/select";
import {
  formatPercentage,
  getCurrencyLabel,
  getDecimalScaleByRule,
  getMaxAvailable,
  getSettlementDays,
  getTerm,
} from "utils";
import { trackAction } from "utils/amplitude";
import blurSelectedElement from "utils/blurSelectedElement";
import { Mobile } from "utils/responsive";
import openDDJJ from "utils/openDDJJ";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import NoCtte from "../NoCtte";

import "./index.scss";

interface MarketDetailTradeCommonProps {
  market: InstrumentData;
  onClickContinueTrade: () => void;
  onChangeCurrency?: (arg: any) => void;
  onChangeSettlement?: (arg: any) => void;
}

const MarketDetailTradeCommon: React.FC<MarketDetailTradeCommonProps> = ({
  market,
  onClickContinueTrade,
  onChangeCurrency,
  onChangeSettlement,
}) => {
  const dispatch = useAppDispatch();

  const [hideTableSection, setHideTableSection] = useState(false);
  const wallet = useAppSelector((state: RootState) => state.wallet.wallet);
  const { sellingPower } = useAppSelector((state: RootState) => state.wallet);
  const loadingWallet = useAppSelector((state) => state.wallet.loading);
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const { shortTickerData } = useAppSelector((state) => state.markets);

  const { marketDetail } = useAppSelector((state: RootState) => state.markets);

  const tickerRules = useAppSelector(
    (state: RootState) => state.markets.tickersRulesData
  );
  const user = useAppSelector(getLoggedUser);
  const location = useLocation();
  const state = location.state as { currency?: Currencies; type?: OrderType };
  const [openModalInfo, setOpenModalInfo] = useState({
    title: "",
    text: "",
    open: false,
  });

  const getSchedules = () => {
    const isNationalBond =
      market.instrument_subtype === "NACIONALES_ARS" ||
      market.instrument_subtype === "NACIONALES_USD";

    if (isNationalBond && order.term !== SettlementTerms.CI)
      return Schedules.SOBERANOS;

    if (market.instrument_type === Instruments.CAUCION)
      return Schedules.CAUCION;

    return order.term ?? SettlementTerms._24;
  };

  const schedules = getSchedules();

  const isMarketOpen = checkMarketOpen(Schedules[schedules]);

  const prevCountRef = useRef<string>();

  const getAvailableBuy = () => {
    return (
      wallet &&
      order.term &&
      (order.currency === Currencies.ARS
        ? Number(wallet[order.term].ars)
        : Number(wallet[order.term].usd))
    );
  };
  const getAvailableSell = () => {
    return (
      sellingPower?.available &&
      Number(sellingPower.available[order.term || SettlementTerms._24])
    );
  };

  const [availableToBuy, setAvailableToBuy] = useState(getAvailableBuy());

  const availableBuy = getMaxAvailable(
    market.instrument_type,
    market.instrument_subtype,
    order.type || OrderType.Buy,
    availableToBuy
  );
  const [availableSell, setAvailableSell] = useState(getAvailableSell());
  const [sellingPriceMarket, setSellingPriceMarket] = useState(0);
  const [buyingPriceMarket, setBuyingPriceMarket] = useState(0);
  const [highlightClass, setHighlightClass] = useState<string>();

  const isHardcodedMessage =
    market.currency === Currencies.USD &&
    market.instrument_type === "BONOS_PUBLICOS" &&
    market.short_ticker &&
    (market.short_ticker.startsWith("AL") ||
      market.short_ticker.startsWith("GD"));

  const prevPrice = usePrevious(market.last);

  const isMarketSell =
    order.type === OrderType.Sell && order.method === "mercado";

  useEffect(() => {
    if (!marketDetail) return;
    if (market.asks) {
      const best = (market.asks && _.minBy(market.asks, "price")?.price) || 0;

      setBuyingPriceMarket(best);

      if (!order.enabledAmount && order.type === OrderType.Buy) {
        if (order.method === "mercado") {
          dispatch(updateOrderOptions({ ...order, price: best }));
          if (order.quantity) {
            dispatch(
              updateOrderOptions({
                ...order,
                amount:
                  ((best * order.quantity) / (marketDetail.price_factor || 1)) *
                  (market.contract_size || 1),
              })
            );
          }
        }
      }
    } else {
      setBuyingPriceMarket(0);
    }
  }, [market.asks]);

  useEffect(() => {
    if (!marketDetail) return;
    if (market.bids) {
      const best = (market.bids && _.maxBy(market.bids, "price")?.price) || 0;

      setSellingPriceMarket(best);
      if (!order.enabledAmount && order.type === OrderType.Sell) {
        if (order.method === "mercado") {
          dispatch(updateOrderOptions({ ...order, price: best }));
          if (order.quantity) {
            dispatch(
              updateOrderOptions({
                ...order,
                amount:
                  ((best * order.quantity) / (marketDetail.price_factor || 1)) *
                  (marketDetail.contract_size || 1),
              })
            );
          }
        }
      }
    } else {
      setSellingPriceMarket(0);
    }
  }, [market.bids]);

  let intervalId: NodeJS.Timeout;

  useEffect(() => {
    if (market.last !== undefined) {
      clearTimeout(intervalId);
      if (prevPrice && prevPrice < (market.last ?? 1)) {
        setHighlightClass("price-highlight up");
      }
      if (prevPrice && prevPrice > (market.last ?? 1)) {
        setHighlightClass("price-highlight down");
      }
    }
    intervalId = setInterval(() => {
      setHighlightClass("price-highlight");
    }, 3000);
  }, [market.last]);

  useEffect(() => {
    prevCountRef.current = market.long_ticker;
    dispatch(getSellingPower(market.long_ticker));
    if (!wallet) {
      dispatch(getWallet());
    }
    const bidOrAsk = buyOrSell(order?.type);
    if (
      market &&
      order.instrument_code &&
      market.instrument_code &&
      !market.instrument_code.includes(order.instrument_code)
    ) {
      dispatch(
        resetOrder({
          instrument_code: market.instrument_code,
          long_ticker: market.long_ticker,
          instrument_short_name: market.instrument_short_name,
          term: SettlementTerms._24,
          method: Method.MARKET,
          currency: state?.currency || Currencies.ARS,
          type: state?.type || OrderType.Buy,
          price:
            order.method === "mercado"
              ? bidOrAsk && Number(bidOrAsk.toString())
              : order.price
              ? order.price
              : undefined,
        })
      );
    } else {
      dispatch(
        updateOrderOptions({
          ...order,
          instrument_short_name: market.instrument_short_name,
          long_ticker: market.long_ticker,
          instrument_code: market.instrument_code,
          currency: state?.currency || order.currency,
          type: state?.type || order.type,
          method: Method.MARKET,
          price:
            order.method === Method.MARKET
              ? bidOrAsk && Number(bidOrAsk.toString())
              : order.price
              ? order.price
              : undefined,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (market.long_ticker && prevCountRef.current !== market.long_ticker) {
      dispatch(getSellingPower(market.long_ticker));
    }
  }, [market.long_ticker]);

  useEffect(() => {
    if (wallet && order && order.term) {
      setAvailableToBuy(getAvailableBuy());
    }
  }, [wallet, order.currency, order.term]);

  useEffect(() => {
    if (sellingPower && order.term) {
      setAvailableSell(getAvailableSell());
    }
  }, [sellingPower, order.term]);

  const handleHideTableSection = () => {
    trackAction(`${screenNames.newOrder} - Show Book`, {
      selected: hideTableSection,
    });
    setHideTableSection(!hideTableSection);
  };

  const getVariationClass = () => {
    if (market.variation) {
      return `variation ${market.variation > 0 ? "positive" : "negative"}`;
    }

    return "variation";
  };

  const handleClickContinue = () => {
    trackAction(`${screenNames.newOrder} - Show Confirmation`, order);
    blurSelectedElement();
    onClickContinueTrade();
  };

  const handleChangeSelectedTrade = (value: string) => {
    trackAction(`${screenNames.newOrder} - Change Order Side`, {
      selected: value,
    });
    if (
      (value === OrderType.Buy || value === OrderType.Sell) &&
      value !== order?.type
    ) {
      dispatch(
        updateOrderOptions({
          ...order,
          type: value,
          enabledAmount: value === OrderType.Buy,
        })
      );
    }
  };

  const handleChangePriceValue = ({ floatValue }: NumberFormatValues) => {
    if (!marketDetail) return null;

    if (floatValue !== order?.price) {
      dispatch(
        updateOrderOptions({
          ...order,
          price: floatValue ? Number(floatValue) : undefined,
          quantity:
            floatValue !== undefined
              ? (((order.amount || 0) / floatValue) *
                  (marketDetail.price_factor || 1)) /
                (marketDetail.contract_size || 1)
              : undefined,
        })
      );
    }
  };

  const handleChangeAmountValue = ({ floatValue }: NumberFormatValues) => {
    if (!marketDetail) return null;

    const bidOrAsk = buyOrSell(order?.type);

    const price =
      order.method === "mercado" ? bidOrAsk && bidOrAsk : order.price;

    const newQuantity =
      floatValue !== undefined && price
        ? (((floatValue || 0) / price) * (marketDetail.price_factor || 1)) /
          (marketDetail.contract_size || 1)
        : undefined;

    dispatch(
      updateOrderOptions({
        ...order,
        amount: floatValue,
        quantity: newQuantity,
      })
    );
  };

  const handleChangeNominalQuantityValue = ({
    floatValue,
  }: NumberFormatValues) => {
    if (!marketDetail) return;
    const bidOrAsk = buyOrSell(order?.type);

    const price =
      order.method === "mercado" ? bidOrAsk && bidOrAsk : order.price || 0;

    dispatch(
      updateOrderOptions({
        ...order,
        quantity: floatValue,
        amount:
          floatValue !== undefined && price !== undefined
            ? ((price * floatValue) / (marketDetail.price_factor || 1)) *
              (marketDetail.contract_size || 1)
            : 0,
      })
    );
  };

  const buyOrSell = (type: OrderType): number => {
    return type === OrderType.Buy ? buyingPriceMarket : sellingPriceMarket;
  };

  const handleChangeTerm = (value?: string) => {
    trackAction(`${screenNames.newOrder} - Change Settlement`, {
      selected: value,
    });
    if (
      value &&
      value !== order.term &&
      (value === SettlementTerms.CI || value === SettlementTerms._24)
    ) {
      let ticker = shortTickerData.find(
        (s: InstrumentData) =>
          s.term === getTerm(value) && s.currency === order.currency
      );

      if (!ticker) {
        const term = getTerm(value);
        ticker = shortTickerData.find((s: InstrumentData) => s.term === term);
      }

      const best =
        order.type === OrderType.Buy
          ? (ticker?.asks && _.minBy(ticker.asks, "price")?.price) || 0
          : (ticker?.bids && _.maxBy(ticker.bids, "price")?.price) || 0;

      dispatch(
        updateOrderOptions({
          ...order,
          instrument_short_name: ticker?.instrument_short_name,
          term: value as SettlementTerms,
          long_ticker: ticker?.long_ticker,
          currency: ticker?.currency as Currencies,
          price: best,
        })
      );
      onChangeSettlement
        ? onChangeSettlement(ticker)
        : dispatch(setMarketDataDetail(ticker));
    }
  };
  const handleChangeMethod = (value?: string) => {
    trackAction(`${screenNames.newOrder} - Change Type`, { selected: value });
    const bidOrAsk = buyOrSell(order?.type);

    if (
      value &&
      value !== order.currency &&
      (value === Method.LIMIT || value === Method.MARKET)
    ) {
      dispatch(
        updateOrderOptions({
          ...order,
          method: value,
          price: bidOrAsk && Number(bidOrAsk.toFixed(2)),
        })
      );
    }
  };

  const handleChangeCurrency = (newValue?: string) => {
    trackAction(`${screenNames.newOrder} - Change Currency`, {
      selected: newValue,
    });
    if (
      newValue &&
      newValue !== order.currency &&
      (newValue === Currencies.USD || newValue === Currencies.ARS)
    ) {
      const term = getTerm(order.term);
      let ticker = shortTickerData.find(
        (s: InstrumentData) => s.currency === newValue && s.term === term
      );

      if (!ticker)
        ticker = shortTickerData.find(
          (s: InstrumentData) => s.currency === newValue
        );
      const best =
        order.type === OrderType.Buy
          ? (ticker?.asks && _.minBy(ticker.asks, "price")?.price) || 0
          : (ticker?.bids && _.maxBy(ticker.bids, "price")?.price) || 0;

      dispatch(
        updateOrderOptions({
          ...order,
          instrument_short_name: ticker?.instrument_short_name,
          currency: newValue,
          long_ticker: ticker?.long_ticker,
          term: getSettlementDays(ticker?.settlement_days),
          price: best,
        })
      );
      onChangeCurrency
        ? onChangeCurrency(ticker)
        : dispatch(setMarketDataDetail(ticker));
    }
  };

  const handleClickToggleAmountQuantity = () => {
    trackAction(`${screenNames.newOrder} - Change Amount or Nominal`, {
      selected: order.enabledAmount ? "Nominal" : "Amount",
    });
    dispatch(
      updateOrderOptions({
        ...order,
        enabledAmount: !order.enabledAmount,
      })
    );
  };

  const options = [
    {
      label: "Vender",
      value: OrderType.Sell,
    },
    {
      label: "Comprar",
      value: OrderType.Buy,
    },
  ];

  const insufficientActivesToSell = () => {
    if (!availableSell) {
      return true;
    }
    if (order.type === OrderType.Sell && order.quantity) {
      return availableSell < order?.quantity;
    }

    return false;
  };

  const insufficientToBuy = () => {
    if (!availableBuy) {
      return true;
    }
    if (
      order.type === OrderType.Buy &&
      availableBuy !== undefined &&
      order.amount
    ) {
      return availableBuy < order?.amount;
    }

    return false;
  };

  const getPriceValue = () => {
    const price = order.price ? order.price.toString() : "0";

    return price;
  };

  const getCurrencyOptions = () => {
    const currencies = _.groupBy(shortTickerData, "currency");

    return Object.keys(currencies).map((c) => ({
      value: c,
      label: c,
    }));
  };

  const getTermOptions = () => {
    const currencies = _.groupBy(shortTickerData, "currency");
    const terms = order.currency
      ? _.groupBy(currencies[order.currency], "term")
      : {};

    return (Object.keys(terms) || ["24hs"])
      .map((t) => ({
        value: t,
        label: t === "CI" ? "C.I." : "24 hs",
      }))
      .sort(
        (
          a: { value: string; label: string },
          b: { value: string; label: string }
        ) => {
          if (a.value === "CI") return -2;
          if (b.value === "CI") return 2;
          if (a.value === "24hs") return -1;
          if (b.value === "24hs") return 1;

          return 0;
        }
      );
  };

  const retryGetSellingPower = () => {
    dispatch(getSellingPower(market.long_ticker));
  };

  const isDisabled =
    !order.amount ||
    !order.quantity ||
    Boolean(order.quantity && Math.floor(order.quantity) < 1) ||
    (order.type === OrderType.Sell && insufficientActivesToSell()) ||
    (order.type === OrderType.Buy && insufficientToBuy()) ||
    order.quantity < (marketDetail?.min_lot_size || 1) ||
    !isMarketOpen;

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { hasAccount } = user;

  return (
    <div
      className={`comp-market${
        !hideTableSection ? " with-tables" : " no-tables"
      }`}
    >
      <div className="flex-col col-1">
        <Mobile>
          <div className="hide-btn" onClick={handleHideTableSection}>
            <ArrowLeftIcon />
            <Typography variant="buttonS">Ocultar</Typography>
          </div>
        </Mobile>
        <BidOfferTable
          rows={market.asks || []}
          ruleId={market.id_tick_size_rule}
          tickSize={market.tick_size}
          type="ask"
        />
        <div>
          <div className="table-container">
            <div className="variation-last">
              <Typography variant="textXS">Último Operado</Typography>
              <div className="variation-last-values">
                <div className={getVariationClass()}>
                  <Typography variant="textXS_bold">
                    {market.variation !== undefined
                      ? formatPercentage(market.variation ?? 0)
                      : "-"}
                  </Typography>
                </div>
                <div className="last">
                  <div className="price-variation">
                    <div className={highlightClass || "price-highlight"}>
                      <Typography variant="textS_bold">
                        {market.last ? (
                          <DecimalRound
                            ruleId={market.id_tick_size_rule}
                            tickSize={market.tick_size}
                          >
                            {market.last}
                          </DecimalRound>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BidOfferTable
              rows={market.bids || []}
              ruleId={market.id_tick_size_rule}
              tickSize={market.tick_size}
              type="bid"
            />
          </div>
        </div>
      </div>
      <div className="flex-col col-2">
        {hideTableSection && (
          <div className="display-btn" onClick={handleHideTableSection}>
            <ArrowLeftIcon />
            <Typography variant="buttonS">Mostrar info mercado</Typography>
          </div>
        )}
        <div className="buttons">
          <UIOverlappedButtons
            options={options}
            selectedValue={order.type}
            type={OverlappedButtonsTypes.BuySell}
            onChangeValue={handleChangeSelectedTrade}
          />
        </div>
        <div className="content">
          {hideTableSection ? (
            <div className="selects-container">
              <UISelect
                cantBeEmpty
                hideHelperText
                options={getCurrencyOptions()}
                size={SelectSizes.Small}
                type={SelectTypes.Secondary}
                value={order.currency}
                onChange={handleChangeCurrency}
                downArrowSize={14}
              />
              <UISelect
                cantBeEmpty
                hideHelperText
                options={getTermOptions()}
                size={SelectSizes.Small}
                type={SelectTypes.Secondary}
                value={order.term || "24hs"}
                onChange={handleChangeTerm}
                downArrowSize={14}
              />
              <UISelect
                cantBeEmpty
                hideHelperText
                options={[
                  { value: "limite", label: "Precio Límite" },
                  { value: "mercado", label: "Precio de Mercado" },
                ]}
                size={SelectSizes.Small}
                type={SelectTypes.Secondary}
                value={order.method || "mercado"}
                onChange={handleChangeMethod}
                downArrowSize={14}
              />
            </div>
          ) : (
            <div className="selects-container">
              <div className="selects-wrapper">
                <div className="select-currency-intro">
                  <UISelect
                    cantBeEmpty
                    hideHelperText
                    options={getCurrencyOptions()}
                    size={SelectSizes.Small}
                    type={SelectTypes.Secondary}
                    value={order.currency}
                    onChange={handleChangeCurrency}
                    downArrowSize={24}
                  />
                </div>
                <div className="settlement-intro">
                  <UISelect
                    cantBeEmpty
                    hideHelperText
                    options={getTermOptions()}
                    size={SelectSizes.Small}
                    type={SelectTypes.Secondary}
                    value={order.term || "24hs"}
                    onChange={handleChangeTerm}
                    downArrowSize={24}
                  />
                </div>
              </div>
              <div className="limit-market-intro">
                <UISelect
                  cantBeEmpty
                  hideHelperText
                  options={[
                    { value: "limite", label: "Precio Límite" },
                    { value: "mercado", label: "Precio de Mercado" },
                  ]}
                  size={SelectSizes.Small}
                  type={SelectTypes.Secondary}
                  value={order.method || Method.MARKET}
                  onChange={handleChangeMethod}
                  downArrowSize={20}
                />
              </div>
            </div>
          )}
          <div
            className={`price ${
              order.method === Method.MARKET ? "market" : "limit"
            }`}
          >
            {order.method !== Method.MARKET ? (
              <UIPriceInput
                currencyValue={order.currency || "ARS"}
                decimalScale={getDecimalScaleByRule(
                  getPriceValue(),
                  market.id_tick_size_rule,
                  market.tick_size,
                  tickerRules
                )}
                size={InputSizes.Small}
                value={getPriceValue()}
                onChangeValue={handleChangePriceValue}
              />
            ) : (
              <UIInput disabled placeholder="Mercado" />
            )}
          </div>
          <NoCtte />
          {hasAccount &&
            (order.enabledAmount ? (
              <div>
                <Typography variant="textS">
                  {order.type === OrderType.Buy ? "Monto a invertir" : "Monto"}
                </Typography>
                <div className="xl-input-wrapper">
                  <UIAmountInput
                    classes="usd"
                    currencyValue={order.currency || "ARS"}
                    size={AmountInputSizes.Small}
                    value={order.amount ? order.amount.toString() : ""}
                    onChangeValue={handleChangeAmountValue}
                    onClickToggle={handleClickToggleAmountQuantity}
                    withToggle={!isMarketSell}
                  />
                  {order.type === OrderType.Buy &&
                    (availableBuy &&
                    order.amount &&
                    availableBuy < order?.amount ? (
                      <Typography
                        className="not-enough"
                        component="p"
                        variant="textXS"
                      >
                        No tienes saldo suficiente
                      </Typography>
                    ) : (
                      <Typography component="p" variant="textXS">
                        {`Disponible: ${getCurrencyLabel(order.currency)} `}
                        <Currency>{availableBuy}</Currency>
                      </Typography>
                    ))}
                  {order.type === OrderType.Sell &&
                    (availableSell !== null &&
                    availableSell !== undefined &&
                    order.quantity &&
                    availableSell < order?.quantity ? (
                      <Typography
                        className="not-enough"
                        component="p"
                        variant="textXS"
                      >
                        No tienes activos suficientes
                      </Typography>
                    ) : (
                      <Typography component="p" variant="textXS">
                        {`Estimado: ${getCurrencyLabel(order.currency)} `}
                        {availableSell && (
                          <Currency>
                            {(availableSell * (order.price || 0)) /
                              ((market.price_factor || 1) *
                                (market.contract_size || 1))}
                          </Currency>
                        )}
                      </Typography>
                    ))}
                </div>
                <Typography
                  className="nominal-text"
                  component="p"
                  variant="textS"
                >
                  Cantidad de nominales:
                </Typography>
                <UIPriceInput
                  prefix={false}
                  disableDecimals
                  disabled
                  placeholder="0"
                  value={Math.trunc(
                    order.quantity ? order.quantity : 0
                  ).toString()}
                />
                {isHardcodedMessage && (
                  <Typography className="hardcoded-message-error">
                    Esta operación tiene una comisión del 0,25%
                  </Typography>
                )}
              </div>
            ) : (
              <div>
                <Typography variant="textS">
                  {order.type === OrderType.Buy
                    ? "Cantidad a comprar"
                    : "Cantidad a vender"}
                </Typography>
                <div className="xl-input-wrapper">
                  <UIAmountInput
                    disableDecimals
                    prefix={false}
                    size={AmountInputSizes.Small}
                    value={order.quantity ? order.quantity.toString() : ""}
                    onChangeValue={handleChangeNominalQuantityValue}
                    onClickToggle={handleClickToggleAmountQuantity}
                    withToggle={!isMarketSell}
                  />
                  {order.type === OrderType.Buy &&
                    order &&
                    order.price !== null &&
                    order.price !== undefined &&
                    availableBuy &&
                    availableBuy !== null &&
                    availableBuy !== undefined &&
                    (order.quantity &&
                    order.price &&
                    order.quantity >
                      Math.floor(
                        availableBuy /
                          (order?.price / (market?.price_factor || 1))
                      ) ? (
                      <Typography
                        className="not-enough"
                        component="p"
                        variant="textXS"
                      >
                        No tienes saldo suficiente
                      </Typography>
                    ) : (
                      <Typography component="p" variant="textXS">
                        Cantidad estimada:&nbsp;
                        {order.price === 0
                          ? "-"
                          : Math.floor(
                              availableBuy /
                                (order?.price / (market?.price_factor || 1))
                            )}
                      </Typography>
                    ))}
                  {order.type === OrderType.Sell &&
                    (availableSell !== undefined &&
                    order.quantity &&
                    availableSell < order?.quantity ? (
                      <Typography
                        className="not-enough"
                        component="p"
                        variant="textXS"
                      >
                        No tienes activos suficientes
                      </Typography>
                    ) : (
                      <Typography component="p" variant="textXS">
                        Cantidad disponible: {availableSell}
                        {!!availableSell || availableSell === 0 || (
                          <RetryButton
                            loading={loadingWallet}
                            onClick={retryGetSellingPower}
                          />
                        )}
                      </Typography>
                    ))}
                </div>
                <Typography component="p" variant="textS">
                  Monto
                </Typography>
                <UIPriceInput
                  disabled
                  currencyValue={order.currency || "ARS"}
                  value={order.amount?.toString() || "0"}
                />
                {isHardcodedMessage && (
                  <Typography className="hardcoded-message-error">
                    Esta operación tiene una comisión del 0,25%
                  </Typography>
                )}
              </div>
            ))}
          <div className="button-continuar-introjs">
            <ButtonUI
              disabled={isDisabled}
              size={ButtonSizes.Full}
              variant={ButtonVariants.Primary}
              onClick={handleClickContinue}
            >
              <Typography variant="buttonS">Continuar</Typography>
            </ButtonUI>
          </div>
          {order.currency === "USD" && (
            <Typography variant="textXS">
              Al presionar Continuar estás aceptando la&nbsp;
              <b onClick={openDDJJ} style={{ cursor: "pointer" }}>
                <u>Declaración Jurada</u>
              </b>
            </Typography>
          )}
        </div>
      </div>
      {openModalInfo.open && (
        <UIModalInfo
          bodyText={openModalInfo.text}
          headingText={openModalInfo.title}
          open={openModalInfo.open}
          primaryButtonText="Volver"
          onClickMainButton={() =>
            setOpenModalInfo({ ...openModalInfo, open: false })
          }
        />
      )}
    </div>
  );
};

export default MarketDetailTradeCommon;
