import {
  CRYPTOS,
  Crypto,
  Cryptos,
  Network,
  getCrypto,
  getNetwork,
} from "constants/cryptos";

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import API from "apis";
import { cryptoService } from "apis/services";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import AssetsPage from "components/common/Assets";
import CableSheet from "components/common/CableSheet";
import Heading, { HeadingVariants } from "components/common/Heading";
import LoadingSpinner from "components/common/LoadingSpinner";
import {
  getCurrentScreen,
  setCurrentScreen,
} from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { CryptoTicker } from "interfaces/api-responses";
import { ThemeVariants } from "interfaces/theme";
import { Currencies } from "interfaces/wallet";
import { useLocation, useNavigate } from "react-router-dom";
import { CryptoRoutes, cryptoScreenNames } from "router/routes";
import { getCurrenciesLabels } from "utils";
import { Projects, trackAction, trackScreen } from "utils/amplitude";

import ShareAddressPage from "./ShareAddress";

import styles from "./styles.module.scss";

interface LocationState {
  defaultTicker: Cryptos;
}

const ReceivePage: React.FC = () => {
  const locationState = useLocation().state as LocationState | null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedAsset, setSelectedAsset] = useState<Crypto>();
  const [selectedNetwork, setSelectedNetwork] = useState<Network>();
  const [address, setAddress] = useState<string>();
  const [networks, setNetworks] = useState<CryptoTicker[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [showCableOb, setShowCableOb] = useState<boolean>(false);

  const previousScreen = useAppSelector(getCurrentScreen);

  const availableNetworks = networks
    .find(({ ticker }) => ticker === selectedAsset?.ticker)
    ?.networks.map((network) => getNetwork(network));

  useEffect(() => {
    trackScreen(
      cryptoScreenNames.receive,
      previousScreen,
      undefined,
      Projects.CRYPTO
    );
    dispatch(setCurrentScreen(cryptoScreenNames.receive));
  }, []);

  const handleClickAsset = (crypto: Crypto) => {
    setSelectedAsset(crypto);
  };

  const handleClickNetwork = (network?: Network) => {
    setSelectedNetwork(network);
    const properties = {
      crypto_network: network,
    };

    trackAction(
      `${cryptoScreenNames.receive} - Click Select Network`,
      properties,
      Projects.CRYPTO
    );
  };

  useEffect(() => {
    if (locationState) {
      const stateCrypto = getCrypto(locationState.defaultTicker);
      setSelectedAsset(stateCrypto);
    }

    const getNetworks = async () => {
      try {
        setError(false);

        const { data } = await API.get<CryptoTicker[]>(cryptoService.tickers);
        setNetworks(data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getNetworks();
  }, []);

  useEffect(() => {
    if (!selectedAsset || !selectedNetwork) return;

    const isCombinationValid = availableNetworks?.find(
      (network) => network.name === selectedNetwork.name
    );

    if (!isCombinationValid) return;

    const getCustomersAddress = async () => {
      try {
        const { data } = await API.get<{ address: string }[]>(
          `${cryptoService.depositAddress}?ticker=${selectedAsset.ticker}&network=${selectedNetwork.name}`
        );

        setAddress(data[0].address);
      } catch (error) {
        setAddress(undefined);
      }
    };

    getCustomersAddress();
  }, [selectedAsset, selectedNetwork]);

  const onClickUsd = () => {
    ampli.receiveSelectedAssetType({ type: "usd" });

    return setShowCableOb(true);
  };

  const fiat = [
    {
      icon: ARGFlag,
      name: getCurrenciesLabels(Currencies.ARS),
      ticker: Currencies.ARS,
      onClick: () => navigate(CryptoRoutes.RECEIVE_ARS),
    },
    {
      icon: USFlag,
      name: "Dólares del exterior",
      ticker: Currencies.USD,
      onClick: onClickUsd,
      badge: "Nuevo",
    },
  ];

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );

  if (error || !networks) {
    if (!networks)
      return (
        <Heading variant={HeadingVariants.RegularTitle} component="h2">
          Ups... en este momento no pudimos cargar las redes disponibles
        </Heading>
      );
  }

  return (
    <div className={styles.receivePageContainer}>
      {!selectedAsset && (
        <AssetsPage assets={CRYPTOS} onClick={handleClickAsset} fiat={fiat} />
      )}
      {selectedAsset && (
        <ShareAddressPage
          address={address}
          networks={networks}
          crypto={selectedAsset}
          network={selectedNetwork}
          availableNetworks={availableNetworks}
          setSelectedNetwork={handleClickNetwork}
          onClickCrypto={() => setSelectedAsset(undefined)}
        />
      )}
      <CableSheet isOpen={showCableOb} setIsOpen={setShowCableOb} />
    </div>
  );
};

export default ReceivePage;
