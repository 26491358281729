import { useEffect, useState } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Schedules } from "interfaces/calendar";
import { Method, OrderType, SettlementTerms } from "interfaces/markets";
import { checkFlow, getAnalysis } from "features/calculator/calculatorSlice";
import { getWallet } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { checkMarketOpen } from "store/selectors/markets.selector";
import { getSettlementByTicker } from "utils";
import { trackAction } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { resetOrder } from "features/markets/marketsSlice";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useDeviceCheck } from "hooks/useDeviceCheck";

import { ACTIVE_BID } from "./constants";
import BidConfirm from "./components/bid-confirm";
import BidDetail from "./components/bid-detail";
import BidResume from "./components/bid-resume";
import BasicWrapper from "../BasicWrapper";

import styles from "./styles.module.scss";

dayjs.extend(utc);
dayjs.extend(timezone);

enum optionsPage {
  DETAIL = "Detalle",
  RESUME = "Resumen",
}

const BidContainer = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();
  const navigate = useNavigate();

  const [selectedPage, setSelectedPage] = useState<optionsPage>(
    optionsPage.DETAIL
  );
  const isDetail = selectedPage === optionsPage.DETAIL;

  const order = useAppSelector((state: RootState) => state.markets.newOrder);

  const [settlementDays, setSettlementDays] = useState<SettlementTerms>(
    SettlementTerms._24
  );
  const title = isDetail ? ACTIVE_BID.name : "Resumen";

  const [operationAble, setOperationAble] = useState<boolean>(false);

  const isMarketOpen = checkMarketOpen(Schedules[settlementDays]);

  const calculatedQuantity =
    Math.floor(
      (Math.floor(ACTIVE_BID.monto_minimo / (ACTIVE_BID.price / 100)) -
        ACTIVE_BID.lamina_minima) /
        ACTIVE_BID.lamina_incremental
    ) *
      ACTIVE_BID.lamina_incremental +
    ACTIVE_BID.lamina_minima;

  useEffect(() => {
    trackAction("bid_screen_viewed");
    dispatch(
      checkFlow({ instrument_code: ACTIVE_BID.instrument_code, invest: true })
    );
  }, []);

  useEffect(() => {
    if (ACTIVE_BID.long_ticker) {
      const term = getSettlementByTicker(ACTIVE_BID.long_ticker);
      dispatch(
        resetOrder({
          long_ticker: ACTIVE_BID.long_ticker,
          price: ACTIVE_BID.price,
          term: term,
          amount: 0,
          quantity: calculatedQuantity,
          method: Method.LIMIT,
          instrument_code: ACTIVE_BID.instrument_code,
          currency: ACTIVE_BID.currency,
          type: OrderType.Buy,
        })
      );
      setSettlementDays(term);
    }
  }, []);

  const handleGoToFlow = () => {
    if (!order.quantity) {
      throw new Error("Error: quantity is undefined.");
    }

    const days = +ACTIVE_BID.long_ticker.split("-")[1].slice(-1) - 1;
    const objectToSend = {
      bond: ACTIVE_BID.instrument_code,
      price: ACTIVE_BID.price,
      expiration_date: dayjs()
        .add(days, "days")
        .tz("America/Buenos_Aires")
        .format("YYYY-MM-DD"),
      quantity: order.quantity,
      logo: ACTIVE_BID.logo_file_name,
    };
    dispatch(getAnalysis(objectToSend));
    setSelectedPage(optionsPage.RESUME);
  };

  const handleGoBack = () => {
    setSelectedPage(optionsPage.DETAIL);
  };

  const handleCloseModal = () => {
    return isMobile ? navigate(mainRoutes.home) : dispatch(closeRightModal());
  };

  useEffect(() => {
    dispatch(getWallet());
  }, [dispatch]);

  return (
    <BasicWrapper
      navigationHeaderProps={{
        onClick: !isDetail ? handleGoBack : handleCloseModal,
        title,
        withCloseIcon: isDetail,
      }}
      className={styles.modalWrapper}
    >
      <div className={styles.investmentContainer}>
        {isDetail ? (
          <BidDetail
            instrument={ACTIVE_BID}
            onChangePage={handleGoToFlow}
            onOperationAble={setOperationAble}
          />
        ) : (
          <BidResume instrument={ACTIVE_BID} />
        )}
        <BidConfirm
          isMarketOpen={isMarketOpen}
          instrument={ACTIVE_BID}
          operationAble={operationAble}
        />
      </div>
    </BasicWrapper>
  );
};

export default BidContainer;
