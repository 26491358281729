import { useState } from "react";

import API from "apis";
import { cardService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import { CardTypes } from "interfaces/card/enums";
import { Globe, Rocket, ShieldCheck } from "lucide-react";
import { CardSections } from "interfaces/card/enums";
import { useCocosCard } from "context/CocosCardProvider";
import Heading, { HeadingVariants } from "components/common/Heading";
import FilledButton from "components/common/FilledButton";

import InfoRow from "../../components/InfoRow";

import styles from "./styles.module.scss";

const VirtualEmptyContent: React.FC = () => {
  const { setSelectedSection, getPomeloData, setIsError } = useCocosCard();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createVirtualCard = async () => {
    setIsLoading(true);

    try {
      const requestCardCreation = [CardTypes.VIRTUAL];
      await API.post(cardService.requestCard, { requestCardCreation });
      await getPomeloData();

      setSelectedSection(CardSections.VIRTUAL_READY);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.emptyCardContent}>
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
        className={styles.titleHeader}
      >
        Disfrutá todos sus beneficios:
      </Heading>
      <div>
        <InfoRow
          icon={Globe}
          title="Hacé pagos online"
          subtitle="Tu tarjeta virtual es ideal para consumos en sitios web, o a través de plataformas e-commerce."
        />
        <InfoRow
          icon={ShieldCheck}
          title="De forma segura"
          subtitle="Podés pausar y volver a activar tu tarjeta cuando lo desees."
        />
      </div>
      <FilledButton
        variant={ThemeVariants.Pay}
        onClick={createVirtualCard}
        isLoading={isLoading}
        disabled={isLoading}
      >
        Pedir tarjeta virtual
      </FilledButton>
    </div>
  );
};

export default VirtualEmptyContent;
