import { Typography } from "@mui/material";
import EmptyState from "components/page-movements/components/empty-state";
import dayjs from "dayjs";
import {
  TickerDetails,
  TickerMovement,
  TickerMovements,
} from "interfaces/movements";
import { useAppSelector } from "hooks/redux";
import { screenNames } from "router/routes";
import UIDateRange from "components/common/date-range";
import UIDropdownCard from "components/common/dropdown-card";
import CsvDownloadLink from "components/common/CsvDownloadLink";
import { trackAction } from "utils/amplitude";
import { getCurrentScreen } from "features/global/globalSlice";

import CardDetailHidden from "./components/cardHidden";
import CardDetailVisible from "./components/cardVisible";
import TickerDetailTable from "./components/ticker-detail-table";

import styles from "./styles.module.scss";

interface TickerMovementsBodyProps {
  updateMovements: (from: string, to: string) => void;
  tickerData: TickerMovements[];
  tickerDetails: TickerDetails;
  plainCard?: boolean;
}

const TickerMovementsBody: React.FC<TickerMovementsBodyProps> = ({
  tickerData,
  tickerDetails,
  updateMovements,
  plainCard = false,
}) => {
  const currentScreen = useAppSelector(getCurrentScreen);
  const today = dayjs().format("YYYY-MM-DD");
  const dateFrom = dayjs(new Date(2021, 0, 1)).format("YYYY-MM-DD"); // 1st January 2021

  const movementsDetailsCsv = [
    ["Fecha", "Tipo", "Nominales", "Precio", "Moneda"],
    ...tickerData.flatMap((obj: TickerMovements) => {
      return obj.tickerMovements.map((mov: TickerMovement) => {
        return [
          mov.execution_date,
          mov.label,
          mov.quantity,
          mov.price,
          mov.id_currency,
        ];
      });
    }),
  ];

  const trackExpand = (expanded: boolean) => {
    trackAction(
      `${currentScreen} - Click ${expanded ? "hide" : "expand"} info`
    );
  };

  return (
    <div className={styles.tickerMovementsBody}>
      {plainCard ? (
        <div className={styles.cardContainer}>
          <CardDetailVisible tickerDetails={tickerDetails} />
          <CardDetailHidden tickerDetails={tickerDetails} />
        </div>
      ) : (
        <UIDropdownCard trackFunction={trackExpand}>
          <CardDetailVisible tickerDetails={tickerDetails} />
          <CardDetailHidden tickerDetails={tickerDetails} />
        </UIDropdownCard>
      )}

      <div className={styles.downloadDiv}>
        <Typography className={styles.subtitle}>
          Elegí un período y conocé tus movimientos
        </Typography>
        {tickerData && tickerData.length > 0 && (
          <CsvDownloadLink
            data={movementsDetailsCsv}
            filename={`movement-table-${tickerData[0].tickerMovements[0].instrument_code}.csv`}
            screenName={screenNames.movementsDetail}
          />
        )}
      </div>
      <div className={styles.dateSelector}>
        <UIDateRange
          firstDate={dateFrom}
          secondDate={today}
          onChange={updateMovements}
        />
      </div>
      {tickerData.length > 0 ? (
        <TickerDetailTable data={tickerData} />
      ) : (
        <EmptyState hasMovements={false} hasFilters={true} />
      )}
    </div>
  );
};

export default TickerMovementsBody;
