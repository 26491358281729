import ProfileLearn from "components/page-profile/page-learn";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

const Learn: React.FC = () => {
  const { isMobile } = useDeviceCheck();

  if (isMobile) {
    return <ProfileLearn />;
  }

  return <Navigate replace to={mainRoutes.profile} />;
};

export default Learn;
