import { InvestmentSuggestedResponse } from "interfaces/markets";

import DetailData from "./components/DetailData";
import DetailOperation from "./components/DetailOperation";

import styles from "./styles.module.scss";

interface investmentDetailProps {
  instrument: InvestmentSuggestedResponse;
  onChangePage: () => void;
  onOperationAble: (val: boolean) => void;
}
const InvestmentSuggestionDetail: React.FC<investmentDetailProps> = ({
  instrument,
  onChangePage,
  onOperationAble,
}) => {
  return (
    <div className={styles.detailPage}>
      <DetailOperation
        instrument={instrument}
        onOperationAble={onOperationAble}
      />
      <DetailData instrument={instrument} onChangePage={onChangePage} />
    </div>
  );
};

export default InvestmentSuggestionDetail;
