import { useState, useEffect } from "react";

import { ampli } from "ampli";
import { useNavigate } from "react-router-dom";
import {
  useCapitalSendAccounts,
  AccountsSteps,
} from "context/CapitalSendAccountsProvider";
import { Currencies } from "interfaces/wallet";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import BasicWrapper from "components/common/BasicWrapper";
import TransactionInput from "components/common/TransactionInput";
import SegmentedControl from "components/common/SegmentedControl";
import Footer from "components/common/Footer";
import FilledButton from "components/common/FilledButton";
import { useAppDispatch } from "hooks/redux";

import ConfirmWithdrawSheet from "../Accounts/ConfirmWithdrawSheet";
import BannerCocoa from "../BannerCocoa";

import styles from "./styles.module.scss";

export enum SendTabs {
  ARS = "ARS",
  USD = "US$",
}

const Amount = ({ wallet }: { wallet: any }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    setStep,
    setAmount,
    setCurrency,
    isDesktop,
    accountSelected,
    currency,
  } = useCapitalSendAccounts();

  const [selectedTab, setSelectedTab] = useState<SendTabs>(
    currency === Currencies.ARS ? SendTabs.ARS : SendTabs.USD
  );
  const [amountToSend, setAmountToSend] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    ampli.withdrawScreenViewed({
      referral: accountSelected ? "bank_accounts" : "portfolio",
    });
  }, []);

  const receiveOptions = [
    { name: SendTabs.ARS, icon: <ARGFlag size={16} /> },
    { name: SendTabs.USD, icon: <USFlag size={16} /> },
  ];

  const onClose = () => {
    setAmount(0);

    if (isDesktop) {
      return dispatch(closeRightModal());
    }

    return navigate(-1);
  };

  const goToNextStep = () => {
    ampli.withdrawAmountConfirmed({
      amount: amountToSend,
      currency,
    });

    setAmount(amountToSend);

    if (accountSelected) {
      return setIsOpen(true);
    }
    return setStep(AccountsSteps.ACCOUNTS);
  };

  const isArsCurrency = currency === Currencies.ARS;

  const handleTabClick = (option: SendTabs) => {
    const currency = option === SendTabs.ARS ? Currencies.ARS : Currencies.USD;
    setCurrency(currency);
    setSelectedTab(option);
  };

  const availableAmount =
    wallet &&
    (isArsCurrency
      ? Math.min(Number(wallet.CI.ars), Number(wallet["24hs"].ars))
      : Math.min(Number(wallet.CI.usd), Number(wallet["24hs"].usd)));

  const onPressEnter = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") goToNextStep();
  };

  const handleChangeValue = (value: any) => {
    setAmountToSend(value);
  };

  const exeededAmountErrorTracking = () => {
    ampli.withdrawAmountExceededError();
  };

  const insufficientFunds = amountToSend <= 0 || availableAmount < amountToSend;

  return (
    <div className={styles.capitalSendPage}>
      <BasicWrapper
        navigationHeaderProps={{ title: "Extraé dinero", onClick: onClose }}
      >
        {!accountSelected && (
          <div className={styles.tabControls}>
            <SegmentedControl
              options={receiveOptions}
              selected={selectedTab}
              onClick={(option) => handleTabClick(option)}
            />
          </div>
        )}
        <TransactionInput
          onChange={handleChangeValue}
          currency={isArsCurrency ? Currencies.ARS : Currencies.USD}
          balance={availableAmount}
          onKeyDown={onPressEnter}
          isDesktop={isDesktop}
          trackingEvent={exeededAmountErrorTracking}
        />

        <Footer>
          <BannerCocoa />
          <FilledButton onClick={goToNextStep} disabled={insufficientFunds}>
            Continuar
          </FilledButton>
        </Footer>
      </BasicWrapper>

      {accountSelected && (
        <ConfirmWithdrawSheet
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          bankAccount={accountSelected}
        />
      )}
    </div>
  );
};

export default Amount;
