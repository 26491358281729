import ff from "constants/featureFlags";

import { useEffect, useState } from "react";

import mainRoutes, { screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import MEPSelector from "components/common/MEPSelector";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import TotalBalanceCard from "components/common/TotalBalanceCard";
import { ThemeVariants } from "interfaces/theme";
import HomeLayout from "layouts/HomeLayout";
import getPlatform from "utils/getPlatform";
import { StatusBar, Style } from "@capacitor/status-bar";
import { CocosProducts } from "interfaces/user";
import { getLoggedUser } from "store/selectors/user.selector";
import { useNavigate } from "react-router-dom";
import { ampli } from "ampli";
import useFeatureFlag from "hooks/useFeatureFlag";

import ActionsSection from "./ActionsSection";
import MepPricesSection from "./MepPricesSection";
import DailyTrendsSection from "./DailyTrendsSection";
import CocosAcademySection from "./CocosAcademySection";
import HighlightedsSection from "./HightlightedsSection";
import GetPerformanceSection from "./GetPerformanceSection";
import HomeEmptyState from "./EmptyState";
import CardSection from "./CardSection";
import NoAccountSheet from "./NoAccountSheet";
import OrdersSection from "./OrdersSection";
import API from "../../../apis";
import { walletService } from "../../../apis/services";
import PaymentsError from "./PaymentsError";

import styles from "./styles.module.scss";

export const HomeMobile: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isMEPOpen, setIsMepOPEN] = useState("");
  const [isNoAccountSheetOpen, setIsNoAccountSheetOpen] = useState<boolean>(
    false
  );
  const [paymentsError, setPaymentsError] = useState<boolean>(false);
  const navigate = useNavigate();

  const { isFeatureEnabled: isPortfolioRequestEnabled } = useFeatureFlag(
    ff.ENABLE_PORTFOLIO_DOUBLE_REQUEST
  );

  const user = useAppSelector(getLoggedUser);
  const isPaymentEnabled = user?.enabled_products.includes(CocosProducts.PAY);
  const shouldShowEmptyState = !user?.hasAccount;

  const { isNative } = getPlatform();

  const handleClickPay = () => {
    if (!user?.hasAccount) {
      ampli.onboardingBottomsheetViewed({ referral: "pay" });
      setIsNoAccountSheetOpen(true);
      return;
    }

    if (!isPaymentEnabled) {
      setPaymentsError(true);
      return;
    }

    ampli.homeExited({ referral: "pay" });
    navigate(mainRoutes.pay);
  };

  const handleClickMEP = (referral: string) => {
    setIsMepOPEN(referral);
  };

  const handleClickSend = () => {
    navigate(mainRoutes.send);
    ampli.homeExited({ referral: "send" });

    useEffect(() => {
      if (isPortfolioRequestEnabled) {
        API.get(walletService.newPortfolioBalance, {
          params: {
            currency: "ARS",
            period: "1D",
          },
        });
      }
    }, [isPortfolioRequestEnabled]);
  };

  useEffect(() => {
    if (isNative) {
      StatusBar.setStyle({ style: Style.Dark });
    }

    dispatch(setCurrentScreen(screenNames.home));
    document.documentElement.classList.add(styles.home, "newStyles");

    return () => {
      document.documentElement.classList.remove(styles.home, "newStyles");
      if (isNative) {
        StatusBar.setStyle({
          style: user?.dark_theme ? Style.Dark : Style.Light,
        });
      }
    };
  }, []);

  if (paymentsError)
    return <PaymentsError onClose={() => setPaymentsError(false)} />;

  return (
    <HomeLayout
      className={styles.homeWrapper}
      variant={ThemeVariants.Capital}
      setIsNoAccountSheetOpen={setIsNoAccountSheetOpen}
    >
      <div className={styles.backgroundWrapper}>
        <TotalBalanceCard
          variant={ThemeVariants.Capital}
          setIsNoAccountSheetOpen={setIsNoAccountSheetOpen}
          trackingEvent={() => ampli.homeExited({ referral: "deposit" })}
        />
        <CardSection setIsNoAccountSheetOpen={setIsNoAccountSheetOpen} />
        <ActionsSection
          onClickPay={handleClickPay}
          onClickSend={handleClickSend}
          onClickMep={() => handleClickMEP("mep")}
        />
      </div>
      <div className={styles.contentWrapper}>
        {shouldShowEmptyState && (
          <div className={styles.emptyStateWrapper}>
            <HomeEmptyState />
          </div>
        )}
        <MepPricesSection onClick={() => handleClickMEP("mep_banner")} />
        <HighlightedsSection />
        <GetPerformanceSection />
        <OrdersSection />
        <DailyTrendsSection />
        <CocosAcademySection />
        <MEPSelector
          isOpen={!!isMEPOpen}
          trackingReferral={isMEPOpen}
          onClose={() => setIsMepOPEN("")}
        />
      </div>
      <NoAccountSheet
        isOpen={isNoAccountSheetOpen}
        onClose={() => setIsNoAccountSheetOpen(false)}
      />
    </HomeLayout>
  );
};
