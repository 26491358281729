import { Store, Globe, LockKeyhole, Truck, CreditCard } from "lucide-react";
import classNames from "classnames";
import px2rem from "utils/px2rem";
import { CardTypes } from "interfaces/card/enums";
import CocosCardLogo from "assets/logos/CocosCardLogo";
import CocosCardLogoLight from "assets/logos/CocosCardLogoLight";
import Mastercard from "assets/logos/Mastercard";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

export enum CardTypeEmpty {
  EMPTY = "EMPTY",
}

interface CardProps {
  type: CardTypes | CardTypeEmpty;
  number?: string;
  isPaused?: boolean;
  isPending?: boolean;
}

const Card: React.FC<CardProps> = ({
  type,
  number,
  isPaused = false,
  isPending = false,
}) => {
  const isPhysical = type === CardTypes.PHYSICAL;
  const isVirtual = type === CardTypes.VIRTUAL;
  const isEmpty = type === CardTypeEmpty.EMPTY;

  const isCardPendingOrEmpty = isPending || isEmpty;

  const ProductBadge = () => {
    const badgeIcons = {
      pending: <Truck color="var(--slate900)" size={px2rem(16)} />,
      paused: <LockKeyhole color="var(--slate900)" size={px2rem(16)} />,
      physical: <Store color="var(--blue400)" size={px2rem(16)} />,
      virtual: <Globe color="white" size={px2rem(16)} />,
      empty: <CreditCard color="var(--black-to-white)" size={px2rem(16)} />,
    };

    const getBadgeIcon = () => {
      if (isPaused) return badgeIcons.paused;
      if (isPending) return badgeIcons.pending;
      if (isPhysical) return badgeIcons.physical;
      if (isVirtual) return badgeIcons.virtual;
      return badgeIcons.empty;
    };

    return (
      <div
        className={classNames(styles.productBadge, {
          [styles.empty]: isCardPendingOrEmpty,
          [styles.paused]: isPaused,
          [styles.physical]: isPhysical,
          [styles.virtual]: isVirtual,
        })}
      >
        {getBadgeIcon()}
      </div>
    );
  };

  const cocosCardLogo = isCardPendingOrEmpty ? (
    <CocosCardLogoLight />
  ) : (
    <CocosCardLogo isDisabled={isPaused} />
  );

  return (
    <div
      className={classNames(styles.card, {
        [styles.physical]: isPhysical && !isPending,
        [styles.virtual]: isVirtual,
        [styles.paused]: isPaused,
        [styles.empty]: isCardPendingOrEmpty,
      })}
    >
      <ProductBadge />
      <div className={styles.logo}>{cocosCardLogo}</div>
      <div className={styles.mastercardLogo}>
        <Mastercard isDisabled={isPaused} />
      </div>
      {type !== CardTypeEmpty.EMPTY && (
        <Text
          variant={TextVariants.RegularTextS}
          className={classNames(styles.cardNumber, {
            [styles.paused]: isPaused,
          })}
        >
          <span>* * * * </span>
          {number}
        </Text>
      )}
    </div>
  );
};

export default Card;
