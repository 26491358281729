import { ReactNode } from "react";

import { ArrowUpRight, LucideIcon } from "lucide-react";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface HighlightedBannerProps {
  color: string;
  heading: string;
  icon: LucideIcon;
  children: ReactNode;
  onClick: () => void;
}

const HighlightedBanner: React.FC<HighlightedBannerProps> = ({
  color,
  heading,
  onClick,
  children,
  icon: Icon,
}) => {
  const borderColor = `var(--${color}600)`;
  const fadeColor = `var(--${color}200)`;
  const primaryColor = `var(--${color}800)`;

  return (
    <div
      onClick={onClick}
      className={styles.bannerContainer}
      style={{
        border: `1px solid ${borderColor}`,
      }}
    >
      <div
        className={styles.bannerHeading}
        style={{
          background: `linear-gradient(90deg, ${fadeColor} 0%, var(--white-to-black) 100%)`,
        }}
      >
        <Icon color={primaryColor} />
        <Text variant={TextVariants.SemiboldTextS} color={primaryColor}>
          {heading}
        </Text>
      </div>
      <div className={styles.infoWrapper}>
        {children}
        <div className={styles.rightArrow}>
          <ArrowUpRight color={primaryColor} size={20} />
        </div>
      </div>
    </div>
  );
};

export default HighlightedBanner;
