import { useEffect, useState } from "react";

import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import CryptoFullIcon from "assets/icons/CryptoIcon";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

const LogoVersion: React.FC = () => {
  const [version, setVersion] = useState<string>();

  useEffect(() => {
    const checkVersion = async () => {
      if (!Capacitor.isNativePlatform()) return;
      setVersion((await CapacitorUpdater.current()).bundle.version);
    };

    checkVersion();
  }, []);

  return (
    <div className={styles.logoVersionContainer}>
      <CryptoFullIcon />
      {version && (
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          Versión {version}
        </Text>
      )}
    </div>
  );
};

export default LogoVersion;
