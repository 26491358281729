import Variation from "components/common/CurrentVariation";
import { Instruments } from "interfaces/markets";

interface TotalVariatonProps {
  totalVariation?: number;
  instrumentType: Instruments;
}

const TotalVariation: React.FC<TotalVariatonProps> = ({
  totalVariation,
  instrumentType,
}) => {
  if (!totalVariation || instrumentType === Instruments.FCI)
    return <div>--</div>;

  return (
    <div>
      <Variation value={totalVariation} />
    </div>
  );
};

export default TotalVariation;
