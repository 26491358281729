import { getListMenuHome } from "constants/capitalMenu";

import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { InstrumentData } from "interfaces/api-responses";
import { BookSocketResponse } from "interfaces/api-responses/socket/book";
import { Instruments } from "interfaces/markets";
import CommonLayout from "layouts/common";
import { useNavigate, useParams } from "react-router-dom";
import { screenNames } from "router/routes";
import { socket } from "socket";
import {
  setCurrentScreen,
  getCurrentScreen,
} from "features/global/globalSlice";
import { addTickerToSubscribeList } from "features/prices/capitalPricesSlice";
import {
  cleanHistoricalData,
  clearShortTickerMarketData,
  getHistoricalDataByLongTicker,
  getMarketDetailByShortTicker,
  resetOrder,
  setMarketDataDetail,
  updateOrderOptions,
} from "features/markets";
import { clearSellingPower } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { ToggleSizes, ToggleUI } from "components/common/controls/toggle";
import { trackAction, trackScreen } from "utils/amplitude";
import { DesktopOrTablet, Mobile } from "utils/responsive";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import MarketDetailDesktop from "./desktop";
import MarketDetailMobile from "./mobile";

type MarketParams = {
  id: string;
};

const MarketDetailPage: React.FC = () => {
  const { id } = useParams<MarketParams>();
  const instrument_type = Instruments.FCI;
  const dispatch = useAppDispatch();
  const marketDetail = useAppSelector(
    (state: RootState) => state.markets.marketDetail
  );
  const shortTickerData = useAppSelector(
    (state: RootState) => state.markets.shortTickerData
  );
  const order = useAppSelector((state: RootState) => state.markets.newOrder);

  const marketDetailLoading = useAppSelector(
    (state: RootState) => state?.markets?.marketDetailLoading
  );
  const currentScreen = useAppSelector(getCurrentScreen);
  const lastPage = sessionStorage.getItem("lastPage") || "";

  const navigate = useNavigate();

  const [market, setMarket] = useState(marketDetail);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [fciRescueAlmostTotal, setFciRescueAlmostTotal] = useState(false);
  const [quickPurchase, setQuickPurchase] = useState<boolean>(false);
  const [socketData, setSocketData] = useState<
    BookSocketResponse | InstrumentData | undefined
  >();

  useEffect(() => {
    const trackProps = {
      ticker: marketDetail?.long_ticker,
      instrumentType: instrument_type,
    };
    trackScreen(
      marketDetail?.instrument_type === Instruments.FCI
        ? screenNames.fci
        : screenNames.newOrder,
      currentScreen,
      trackProps
    );
    dispatch(
      setCurrentScreen(
        marketDetail?.instrument_type === Instruments.FCI
          ? screenNames.fci
          : screenNames.newOrder
      )
    );
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(
        getMarketDetailByShortTicker({ short_ticker: id, instrument_type })
      );
    }
    if (
      shortTickerData &&
      shortTickerData.length > 0 &&
      shortTickerData[0]?.instrument_code !== id
    ) {
      dispatch(clearShortTickerMarketData());
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (
      shortTickerData.length > 0 &&
      shortTickerData.some((s) => s.instrument_code === id)
    ) {
      const selectedMarket =
        shortTickerData[0].instrument_type === Instruments.FCI
          ? shortTickerData[0]
          : shortTickerData.filter(
              (m) => m.term === order.term && m.currency === order.currency
            )[0];

      if (selectedMarket) {
        dispatch(setMarketDataDetail(selectedMarket));
        dispatch(
          getHistoricalDataByLongTicker({
            long_ticker: selectedMarket.long_ticker,
          })
        );
      }
    }
  }, [shortTickerData, id, order.term, order.currency]);

  useEffect(() => {
    if (marketDetail?.long_ticker) {
      dispatch(addTickerToSubscribeList(marketDetail.long_ticker));
    }
  }, [id, marketDetail?.long_ticker]);

  useEffect(() => {
    const messageHandler = (messageFromBackEnd: any) => {
      const message: BookSocketResponse = messageFromBackEnd;
      setSocketData(message);
    };
    socket.on("message", messageHandler);
    return () => {
      socket.off("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    if (
      socketData &&
      socketData !== marketDetail &&
      ((!marketDetail && socketData.long_ticker.split("-")[0] === id) ||
        (marketDetail && socketData.long_ticker === marketDetail.long_ticker))
    ) {
      dispatch(setMarketDataDetail({ ...socketData }));
    }
  }, [socketData]);

  useEffect(() => {
    if (!openConfirmModal && market) {
      dispatch(setMarketDataDetail(market));
    }
  }, [market]);

  useEffect(() => {
    if (!id) return;
    if (order && !order.instrument_code?.includes(id)) {
      dispatch(clearSellingPower());
      dispatch(cleanHistoricalData());

      if (marketDetail && id === marketDetail?.instrument_code) {
        dispatch(
          updateOrderOptions({
            ...order,
            long_ticker: marketDetail.long_ticker,
            instrument_code: marketDetail.instrument_code,
            instrument_short_name: marketDetail.instrument_short_name,
            currency: marketDetail.currency,
          })
        );
      } else {
        dispatch(resetOrder());
      }
    }
    if (marketDetail && id === marketDetail?.instrument_code) {
      dispatch(
        updateOrderOptions({
          ...order,
          long_ticker: marketDetail.long_ticker,
          instrument_code: marketDetail.instrument_code,
          instrument_short_name: marketDetail.instrument_short_name,
          currency: marketDetail.currency,
        })
      );
    }
  }, [marketDetail?.instrument_code, marketDetail?.long_ticker]);

  const handleToggleOpenConfirmModal = (fciAlmostTotal?: boolean) => {
    setFciRescueAlmostTotal(fciAlmostTotal ?? false);
    setOpenConfirmModal(!openConfirmModal);
  };

  const handleQuickPurchase = () => {
    if (quickPurchase) {
      navigate(
        `/market/${market?.instrument_type}/${market?.instrument_code}`,
        { state: market }
      );
    }
  };

  const handleMarketDetail = async () => {
    await setMarket(marketDetail);
  };

  useEffect(() => {
    handleQuickPurchase();
  }, [quickPurchase]);

  useEffect(() => {
    handleMarketDetail();
  }, [marketDetailLoading]);

  const handleSwitchQuickPurchase = () => {
    trackAction(`${screenNames.newOrder} - Click Quick Buy Switch`);
    setQuickPurchase(true);
  };

  const handleGoBack = () => {
    if (lastPage) {
      navigate(lastPage);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="pages-market-detail">
      <CommonLayout
        assetToolbar
        hideBottomNavigation
        goBack={handleGoBack}
        isBond={
          marketDetail?.instrument_type === "BONOS_PUBLICOS" ||
          marketDetail?.instrument_type === "BONOS_CORP"
        }
        mobileHeaderTitle={marketDetail?.short_ticker}
        {...getListMenuHome()}
      >
        <Mobile>
          {(marketDetail?.instrument_type === Instruments?.BONOS_CORP ||
            marketDetail?.instrument_type === Instruments?.BONOS_PUBLICOS ||
            marketDetail?.instrument_type === Instruments?.CEDEARS ||
            marketDetail?.instrument_type === Instruments?.ACCIONES ||
            marketDetail?.instrument_type === "LETRAS") && (
            <div className="market-switch">
              <span>
                <Typography variant="textS_bold">Compra rápida</Typography>
              </span>
              <ToggleUI
                disabled={marketDetailLoading}
                size={ToggleSizes.Medium}
                value={quickPurchase}
                checked={quickPurchase}
                onChange={() => handleSwitchQuickPurchase()}
              />
            </div>
          )}
          <MarketDetailMobile
            fciAlmostTotal={fciRescueAlmostTotal}
            openConfirmModal={openConfirmModal}
            onOpenConfirmModal={handleToggleOpenConfirmModal}
          />
        </Mobile>
        <DesktopOrTablet>
          <MarketDetailDesktop />
        </DesktopOrTablet>
      </CommonLayout>
    </div>
  );
};

export default MarketDetailPage;
