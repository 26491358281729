import Text, { TextVariants } from "components/common/Text";
import { IconProps } from "assets/icons/BTC";
import px2rem from "utils/px2rem";
import { Browser } from "@capacitor/browser";
import { ampli } from "ampli";

import styles from "./styles.module.scss";

interface AcademyCardProps {
  url: string;
  title: string;
  illustration: React.FC<IconProps>;
}

const AcademyCard: React.FC<AcademyCardProps> = ({
  url,
  title,
  illustration: Illustration,
}) => {
  const onClickCard = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    ampli.homeExited({ referral: "academy" });
    Browser.open({ url });
  };
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={styles.cardWrapper}
      onClick={onClickCard}
    >
      <Text
        className={styles.text}
        color="var(--slate900)"
        variant={TextVariants.SemiboldTextS}
      >
        {title}
      </Text>
      <Illustration size={px2rem(80)} className={styles.illustration} />
    </a>
  );
};

export default AcademyCard;
