import { PortfolioTabs } from "constants/portfolio";

export const MIN_THRESHOLD_CRYPTO = 0.05;

export const MIN_HOLDING_CRYPTO = 1e-8;

export enum SelectedTab {
  RETURNS = "Returns",
  VALUE = "Value",
}

export const getNumberOfDecimals = (amount: number) => {
  return amount < MIN_THRESHOLD_CRYPTO ? 8 : 2;
};

export enum PerformancePeriods {
  DAILY = "daily",
  HISTORIC = "historic",
}

export const getTabName = (tab: PortfolioTabs) => {
  return tab === PortfolioTabs.RETURNS
    ? SelectedTab.RETURNS
    : SelectedTab.VALUE;
};
