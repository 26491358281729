import Heading, { HeadingVariants } from "components/common/Heading";
import { X } from "lucide-react";
import px2rem from "utils/px2rem";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import { ThemeVariants } from "interfaces/theme";
import { useCocosPay } from "context/CocosPayProvider";
import { PaySteps } from "interfaces/pay/enums";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface ChaveErrorProps {
  onClose: () => void;
}

const ChaveError: React.FC<ChaveErrorProps> = ({ onClose }) => {
  const { setStep } = useCocosPay();
  const { isIOS } = getPlatform();

  return (
    <div
      className={classNames(styles.chaveErrorContainer, {
        [styles.isIos]: isIOS,
      })}
    >
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        component="h2"
        color="var(--slate900)"
      >
        Destinatario incorrecto
      </Heading>
      <div className={styles.icon}>
        <X size={px2rem(24)} color="var(--red900)" />
      </div>
      <Text variant={TextVariants.RegularTextL} color="var(--slate800)">
        Ingresaste un destinatario incorrecto. Por favor, volvé a intentarlo.
      </Text>
      <div className={styles.buttonsWrapper}>
        <FilledButton variant={ThemeVariants.Pay} onClick={onClose}>
          Volver a intentar
        </FilledButton>
        <OutlinedButton
          variant={ThemeVariants.Pay}
          onClick={() => setStep(PaySteps.QR)}
        >
          Escanear QR
        </OutlinedButton>
      </div>
    </div>
  );
};

export default ChaveError;
