import NewMepPage from "components/page-mep/NewMep";
import { Navigate, useLocation } from "react-router-dom";
import mainRoutes from "router/routes";

interface LocationState {
  isFixed: boolean;
}

const Mep: React.FC = () => {
  const locationState = useLocation().state as LocationState | null;

  if (!locationState) return <Navigate to={mainRoutes.home} />;

  return <NewMepPage isFixed={locationState.isFixed} />;
};

export default Mep;
