import Divider from "components/common/Divider";
import { useNavigate } from "react-router-dom";

import { profileMenuOptions } from "../utils";
import ProfileRow from "./ProfileRow";

import styles from "./styles.module.scss";

const SectionsList: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.sectionsListContainer}>
      {profileMenuOptions.map(({ icon, title, subtitle, route }, index) => (
        <div key={title} onClick={() => navigate(route)}>
          <ProfileRow icon={icon} title={title} subtitle={subtitle} />
          {index !== profileMenuOptions.length - 1 && (
            <Divider className={styles.divider} />
          )}
        </div>
      ))}
    </div>
  );
};

export default SectionsList;
