import { useEffect, useState } from "react";

import Text, { TextVariants } from "components/common/Text";
import { InvestmentSuggestedResponse } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { updateOrderOptions } from "features/markets/marketsSlice";
import { RootState } from "store/store";
import { UIChipSelector } from "components/common/controls/chip-selector";
import { UIPriceInputV2 } from "components/common/price-input";
import { getCurrencyFormatted, getSettlementByTicker } from "utils";
import Banner, { BannerVariants } from "components/common/Banner";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

interface DetailOperationrops {
  instrument: InvestmentSuggestedResponse;
  onOperationAble: (val: boolean) => void;
}

const DetailOperation: React.FC<DetailOperationrops> = ({
  instrument,
  onOperationAble,
}) => {
  const dispatch = useAppDispatch();

  const wallet = useAppSelector((state: RootState) => state.wallet.wallet);
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const [selectedChip, setSelectedChip] = useState<number | null>(null);

  const term = instrument && getSettlementByTicker(instrument.long_ticker);
  const buyingPower = +(wallet?.[term]?.usd ?? 0);
  const chipsPercentages = [0.25, 0.5, 0.75, 1];
  const MIN_AVAILABLE = 5;
  const cashAvailable = `Disponible ${getCurrencyFormatted(
    Currencies.USD,
    buyingPower
  )}`;

  const chipsOptions = chipsPercentages.map((percentage) => ({
    value: Math.floor(buyingPower * percentage),
    label: `${percentage * 100}%`,
  }));

  const handleClickChip = (option: number) => {
    if (selectedChip === option) {
      setSelectedChip(null);
    } else {
      setSelectedChip(option);
    }
    handleChangePrice(option);
  };

  const changeInputPrice = (value: number) => {
    if (selectedChip && selectedChip !== value) {
      setSelectedChip(null);
    }
    handleChangePrice(value);
  };

  const handleChangePrice = (value: number) => {
    const quantity =
      Math.floor(
        (Math.floor(value / (instrument.price / 100)) -
          instrument.lamina_minima) /
          instrument.lamina_incremental
      ) *
        instrument.lamina_incremental +
      instrument.lamina_minima;
    dispatch(
      updateOrderOptions({
        ...order,
        amount: value,
        quantity: quantity,
      })
    );
    if (value >= instrument.monto_minimo && value <= buyingPower) {
      return onOperationAble(true);
    }
    onOperationAble(false);
  };

  useEffect(() => {
    if (
      order.amount &&
      order.amount >= instrument.monto_minimo &&
      order.amount <= buyingPower
    ) {
      onOperationAble(true);
    } else {
      onOperationAble(false);
    }
  }, [buyingPower, order.amount]);

  return (
    <div className={styles.detailOperation}>
      <Text
        variant={TextVariants.SemiboldText}
        className={styles.investmentAmount}
      >
        ¿Qué monto querés invertir?
      </Text>
      <UIPriceInputV2
        key="amount"
        value={order.amount}
        currency={Currencies.USD}
        handleChangeValue={changeInputPrice}
      />
      <Text
        variant={TextVariants.SemiboldText}
        className={styles.availableText}
      >
        {cashAvailable}
      </Text>
      {buyingPower >= MIN_AVAILABLE && (
        <div className={styles.priceChips}>
          {chipsOptions.map((option) => (
            <UIChipSelector
              isSelected={option.value === selectedChip}
              key={option.value}
              title={option.label}
              onClick={() => handleClickChip(option.value)}
              id={`investment-suggested-${option.label}`}
            />
          ))}
        </div>
      )}

      <Banner
        text={`El monto total a invertir puede ser levemente inferior al operarse por
        lotes de ${instrument.lamina_incremental} V/N`}
        variant={BannerVariants.INFO_CAPITAL}
      />
    </div>
  );
};

export default DetailOperation;
