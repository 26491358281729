import { useState } from "react";

import { CircularProgress, Typography } from "@mui/material";
import FCIMarketDetailTrade from "components/common/fci-detail-trade";
import MarketDetailTradeCommon from "components/common/market-detail-trade";
import { AnimatePresence, motion } from "framer-motion";
import { Instruments } from "interfaces/markets";
import { getHistoricalDataByLongTicker } from "features/markets";
import { RootState } from "store/store";
import { UIChartLine } from "components/common/charts/line";
import { intervalsOptions } from "utils/constants";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { ConfirmFCIOrderModal } from "./../mobile/confirm-fci-order-modal";
import { ConfirmOrderModal } from "./../mobile/confirm-order-modal";
import { Orders } from "./../mobile/orders";

import "./index.scss";

interface MarketDetailMobileProps {
  onOpenConfirmModal: () => void;
  openConfirmModal: boolean;
  fciAlmostTotal: boolean;
}

const MarketDetailMobile: React.FC<MarketDetailMobileProps> = ({
  onOpenConfirmModal,
  fciAlmostTotal,
  openConfirmModal,
}) => {
  const [selectedInterval, setselectedInterval] = useState("1A");
  const { marketDetail } = useAppSelector((state: RootState) => state.markets);
  const marketHistoricData = useAppSelector(
    (state: RootState) => state.markets.marketHistoricData
  );
  const marketLoading = useAppSelector(
    (state: RootState) => state.markets.loading
  );
  const marketDetailLoading = useAppSelector(
    (state: RootState) => state.markets.marketDetailLoading
  );
  const dispatch = useAppDispatch();

  const handleChangeInterval = (interval: string) => () => {
    if (marketDetail) {
      setselectedInterval(interval);
      dispatch(
        getHistoricalDataByLongTicker({
          long_ticker: marketDetail.long_ticker,
          interval,
        })
      );
    }
  };

  return (
    <>
      <AnimatePresence>
        <motion.div
          animate={{
            x: 0,
          }}
          className="pages-market-detail"
          initial={{
            x: -10000,
          }}
          layoutId={marketDetail?.short_ticker}
          transition={{
            layout: { duration: 0.2 },
          }}
        >
          {marketDetail && (
            <>
              {marketDetail.instrument_type === Instruments.FCI && (
                <FCIMarketDetailTrade
                  onClickContinueTrade={onOpenConfirmModal}
                />
              )}
              {marketDetail.instrument_type !== Instruments.FCI && (
                <MarketDetailTradeCommon
                  market={marketDetail}
                  onClickContinueTrade={onOpenConfirmModal}
                />
              )}
              <Orders />
              <div className="chart-wrapper">
                <UIChartLine
                  id_tick_size_rule={marketDetail.id_tick_size_rule}
                  loading={marketDetailLoading}
                  name={marketDetail.short_ticker}
                  prices={marketHistoricData?.prices || []}
                  tick_size={marketDetail.tick_size}
                />

                <div className="intervals-wrapper">
                  {intervalsOptions.map((i) => (
                    <div
                      key={i}
                      className={`interval ${
                        selectedInterval === i ? "selected" : ""
                      }`}
                      onClick={handleChangeInterval(i)}
                    >
                      <Typography variant="textS">{i}</Typography>
                    </div>
                  ))}
                </div>
              </div>
              {openConfirmModal &&
                marketDetail.instrument_type === Instruments.FCI && (
                  <ConfirmFCIOrderModal
                    fciAlmostTotal={fciAlmostTotal}
                    open={openConfirmModal}
                    onToggleOpen={onOpenConfirmModal}
                  />
                )}
              {openConfirmModal &&
                marketDetail.instrument_type !== Instruments.FCI && (
                  <ConfirmOrderModal
                    open={openConfirmModal}
                    onToggleOpen={onOpenConfirmModal}
                  />
                )}
            </>
          )}
          {!marketDetail && marketLoading && (
            <div className="loading">
              <CircularProgress size={40} />
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default MarketDetailMobile;
