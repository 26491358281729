import PageProfile from "components/page-profile";
import { ProfileProvider } from "context/ProfileProvider";

const Profile: React.FC = () => (
  <ProfileProvider>
    <PageProfile />
  </ProfileProvider>
);

export default Profile;
