import { useEffect } from "react";

import { ampli } from "ampli";
import Lens from "assets/illustrations/Lens";
import QRError from "assets/illustrations/QRError";
import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import { getMessageError } from "components/page-cocos-pay/utils";
import { PayError } from "interfaces/pay/enums";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

interface QRErrorModalProps {
  error: PayError;
  onToggleDisplay: () => void;
}

const QRErrorModal: React.FC<QRErrorModalProps> = ({
  error,
  onToggleDisplay,
}) => {
  const navigate = useNavigate();

  const alreadyPaid = error === PayError.ALREADY_PAID;

  const buttonLabel =
    error === PayError.INVALID_QR
      ? "Intentar con otro QR"
      : "Volver a intentar";

  const title = alreadyPaid ? "Atención" : "Algo salió mal";

  const Icon = alreadyPaid ? Lens : QRError;

  useEffect(() => {
    ampli.payScanFailed({ failed_reason: error });
  }, []);

  const onClickRetry = () => {
    ampli.payScanRetry({ referral: error });
    if ([PayError.INVALID_QR, PayError.ALREADY_PAID].includes(error)) {
      onToggleDisplay();
      return;
    }
    window.location.reload();
  };

  const onClickHome = () => {
    ampli.payExit({ referral: "scan_qr" });
    navigate(-1);
  };

  return (
    <UIModalSlider
      onToggleDisplay={onToggleDisplay}
      open={true}
      classes={styles.qrErrorModal}
    >
      <Text variant={TextVariants.RegularText} color="var(--slate900)">
        {title}
      </Text>
      <Icon />
      <Text
        className={styles.message}
        variant={TextVariants.RegularTextL}
        color="var(--slate800)"
      >
        {getMessageError(error)}
      </Text>
      <div className={styles.buttonsWrapper}>
        <FilledButton variant={ThemeVariants.Pay} onClick={onClickRetry}>
          {buttonLabel}
        </FilledButton>
        <OutlinedButton variant={ThemeVariants.Pay} onClick={onClickHome}>
          Ir al inicio
        </OutlinedButton>
      </div>
    </UIModalSlider>
  );
};

export default QRErrorModal;
