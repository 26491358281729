import { useState, useEffect } from "react";

import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Info } from "lucide-react";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackScreen, trackAction } from "utils/amplitude";
import { trackAnalyticsEvent, AnalyticsEvents } from "utils/firebaseAnalytics";
import { trackFbEvent, FbEvents } from "utils/fbTracking";
import { onboardingScreenNames } from "router/routes";
import { useCreateAccount } from "context/CreateAccountProvider";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import Select from "components/common/NewSelect";
import InputField from "components/common/input/InputField";
import { UIModalSlider } from "components/common/modal-slider";
import NavigationHeader from "components/common/NavigationHeader";
import Stepper from "components/common/Stepper";
import {
  genderOptions,
  OnboardingImageFileName,
  OnboardingDocumentType,
} from "components/page-create-account/constants";
import { CropType } from "components/page-create-account/utils/processImage";
import * as Sentry from "@sentry/react";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Rejected from "../Final/Rejected";
import SelectBottomSheet from "../../SelectBottomSheet";
import LoadingScreen from "../../Loading";
import ProcedureNumberInfo from "../../ProcedureNumberInfo";
import isCuitValid from "../../../utils/isCuitValid";
import getDNI from "../../../utils/getDNI";

import styles from "./styles.module.scss";

interface Values {
  cuit: string;
  gender: string;
  procedureNumber: string;
}

const ValidationSchema = Yup.object().shape({
  cuit: Yup.string(),
  gender: Yup.string().required(),
  procedureNumber: Yup.string()
    .matches(/^\d{9}$/)
    .required(),
});

const ManualInput = () => {
  const dispatch = useAppDispatch();
  const {
    goToHome,
    dniFrontImage,
    dniBackImage,
    createUserAccount,
    uploadDocument,
    setIsLoading,
    isLoading,
    setError,
    error,
    goToNextStep,
  } = useCreateAccount();

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [cuitError, setCuitError] = useState<boolean>(false);
  const [
    displayProcedureNumberInfo,
    setDisplayProcedureNumberInfo,
  ] = useState<boolean>(false);

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackAnalyticsEvent(AnalyticsEvents.HOME_EXITO_PASO3_PROCESO_COMITENTE);
    trackFbEvent(FbEvents.HOME_EXITO_PASO3_PROCESO_COMITENTE);

    trackScreen(
      onboardingScreenNames.personalInfo,
      previousScreen,
      undefined,
      Projects.ONBOARDING
    );

    dispatch(setCurrentScreen(onboardingScreenNames.personalInfo));
  }, []);

  const formik = useFormik({
    initialValues: {
      cuit: "",
      gender: "",
      procedureNumber: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (
      values: Values,
      { setSubmitting }: FormikHelpers<Values>
    ) => {
      setSubmitting(true);
      const { cuit, gender, procedureNumber } = values;

      if (!isCuitValid(cuit)) {
        setSubmitting(false);
        setCuitError(true);
        return;
      }

      const cuitString = cuit.toString();
      const dni = getDNI(cuitString);
      const formatProcedureNumber = `00${procedureNumber.toString()}`;

      try {
        setIsLoading(true);
        await createUserAccount({
          cuit: cuitString,
          gender,
          dni,
          procedureNumber: formatProcedureNumber,
          manualTranscription: true,
        });
        await uploadImages();
        goToNextStep();
      } catch (error: any) {
        Sentry.captureException(error, {
          tags: {
            page: Projects.ONBOARDING,
            code: "SEND_MANUAL_DNI_DATA_ERROR",
          },
        });

        setError(true);
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  const uploadImages = async () => {
    await uploadDocument(
      OnboardingDocumentType.DNI_FRENTE_AR,
      dniFrontImage,
      OnboardingImageFileName.DNI_FRENTE_AR,
      CropType.DNI
    );
    await uploadDocument(
      OnboardingDocumentType.DNI_DORSO_AR,
      dniBackImage,
      OnboardingImageFileName.DNI_DORSO_AR,
      CropType.DNI
    );
  };

  const onClickBack = () => {
    trackAction(
      `${onboardingScreenNames.personalInfo} - Click Quit`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  const onConfirm = () => {
    trackAction(
      `${onboardingScreenNames.personalInfo} - Click Continue`,
      { gender: formik.values.gender },
      Projects.ONBOARDING
    );
  };

  const onClickTooltip = () => {
    trackAction(
      `${onboardingScreenNames.personalInfo} - Click info tooltip`,
      undefined,
      Projects.ONBOARDING
    );

    setDisplayProcedureNumberInfo(true);
  };

  if (error) {
    return <Rejected isErrorState />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  const getSelectedValue = (value: any) =>
    genderOptions.find((option: any) => option.value === value)?.label || "";

  return (
    <div className={styles.manualInputContainer}>
      <div className={styles.content}>
        <NavigationHeader title="Datos personales" onClick={onClickBack} />
        <Stepper stepsCount={4} currentStep={2} />
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h2"
          color="var(--slate900)"
          className={styles.title}
        >
          Por favor completá los siguientes datos:
        </Heading>

        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <section>
            <InputField
              label="CUIT/CUIL"
              name="cuit"
              placeholder="Ingresá tu CUIT/CUIL"
              value={formik.values.cuit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={cuitError}
              errorMessage="El CUIT ingresado no es válido"
              onFocus={() => setCuitError(false)}
            />
          </section>

          <section>
            <Select
              label="Sexo"
              placeholder="Seleccionar"
              value={getSelectedValue(formik.values.gender)}
              open={isSelectOpen}
              setOpen={setIsSelectOpen}
            >
              <SelectBottomSheet
                title="Seleccioná un género"
                value={formik.values.gender}
                options={genderOptions}
                setOpen={setIsSelectOpen}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="gender"
              />
            </Select>
          </section>

          <section>
            <Text
              className={styles.infoProcedureNumberText}
              variant={TextVariants.RegularTextS}
              color="var(--slate800)"
            >
              Número de trámite
              <Info
                className={styles.infoProcedureNumber}
                onClick={onClickTooltip}
              />
            </Text>
            <div className={styles.inputContainer}>
              <InputField
                name="procedureNumber"
                placeholder="Ingresá el número de trámite"
                value={formik.values.procedureNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={9}
              />
            </div>
          </section>

          <UIModalSlider
            open={displayProcedureNumberInfo}
            onToggleDisplay={() => setDisplayProcedureNumberInfo(false)}
          >
            <ProcedureNumberInfo onClick={setDisplayProcedureNumberInfo} />
          </UIModalSlider>

          <FilledButton
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
            onClick={onConfirm}
            className={styles.button}
          >
            Continuar
          </FilledButton>
        </form>
      </div>
    </div>
  );
};

export default ManualInput;
