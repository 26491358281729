import CryptoCablePage from "components/page-crypto-cable";
import { CocosPayProvider } from "context/CocosPayProvider";
import { CryptoCableProvider } from "context/CryptoCableProvider";

const Cable: React.FC = () => (
  <CryptoCableProvider>
    <CocosPayProvider>
      <CryptoCablePage />
    </CocosPayProvider>
  </CryptoCableProvider>
);

export default Cable;
