import { SVGProps } from "react";

export interface IconProps extends SVGProps<SVGSVGElement> {
  size?: number | string;
}

const PixBG: React.FC<IconProps> = ({ size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="124"
      viewBox="0 0 325 124"
      fill="none"
      {...props}
    >
      <g opacity="0.28">
        <rect
          width="104.352"
          height="104.352"
          rx="20.8704"
          transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 27.0082 162.436)"
          fill="#252C3D"
          fillOpacity="0.51"
        />
        <g clipPath="url(#clip0_5883_66868)">
          <path
            d="M58.5008 78.3742C69.6344 75.3909 81.0784 81.9981 84.0617 93.1317C87.0449 104.265 80.4377 115.709 69.3041 118.693C58.1705 121.676 46.7265 115.069 43.7432 103.935C40.76 92.8014 47.3672 81.3574 58.5008 78.3742Z"
            fill="#6DA544"
          />
          <path
            d="M67.1906 110.804L80.5559 94.0711L60.6147 86.2625L47.2494 102.996L67.1906 110.804Z"
            fill="#FFDA44"
          />
          <path
            d="M62.0239 91.5214C65.8964 90.4838 69.8769 92.7819 70.9146 96.6545C71.9522 100.527 69.6541 104.508 65.7815 105.545C61.909 106.583 57.9285 104.285 56.8908 100.412C55.8532 96.5396 58.1513 92.5591 62.0239 91.5214Z"
            fill="white"
          />
          <path
            d="M60.5133 99.9111C59.294 100.238 58.0675 100.368 56.8677 100.32C55.8904 96.4769 58.1811 92.5509 62.023 91.5215C64.3986 90.8849 66.8136 91.5053 68.5663 92.9727C67.1241 96.2707 64.2524 98.9092 60.5133 99.9111Z"
            fill="#0052B4"
          />
          <path
            d="M70.4239 95.346C70.6249 95.7583 70.7907 96.1949 70.9139 96.6545C71.9516 100.527 69.6534 104.508 65.7808 105.545C62.8913 106.319 59.9423 105.236 58.2 103.027C59.2016 102.97 60.2118 102.81 61.2181 102.54C65.2907 101.449 68.5213 98.7692 70.4239 95.346Z"
            fill="#0052B4"
          />
        </g>
        <rect
          width="104.352"
          height="104.352"
          rx="20.8704"
          transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 137.377 132.863)"
          fill="#252C3D"
          fillOpacity="0.51"
        />
        <g clipPath="url(#clip1_5883_66868)">
          <path
            d="M168.869 48.8011C180.003 45.8179 191.447 52.4251 194.43 63.5587C197.413 74.6923 190.806 86.1363 179.672 89.1196C168.539 92.1028 157.095 85.4956 154.112 74.362C151.128 63.2284 157.735 51.7844 168.869 48.8011Z"
            fill="#6DA544"
          />
          <path
            d="M177.559 81.2311L190.924 64.4981L170.983 56.6895L157.618 73.4225L177.559 81.2311Z"
            fill="#FFDA44"
          />
          <path
            d="M172.392 61.9484C176.265 60.9108 180.245 63.2089 181.283 67.0815C182.321 70.954 180.022 74.9345 176.15 75.9721C172.277 77.0098 168.297 74.7116 167.259 70.8391C166.221 66.9666 168.52 62.9861 172.392 61.9484Z"
            fill="white"
          />
          <path
            d="M170.882 70.338C169.662 70.6647 168.436 70.7946 167.236 70.7473C166.259 66.9039 168.549 62.9779 172.391 61.9484C174.767 61.3119 177.182 61.9322 178.935 63.3997C177.492 66.6977 174.621 69.3361 170.882 70.338Z"
            fill="#0052B4"
          />
          <path
            d="M180.792 65.7729C180.993 66.1853 181.159 66.6219 181.282 67.0815C182.32 70.9541 180.022 74.9346 176.149 75.9722C173.26 76.7465 170.311 75.6628 168.568 73.4544C169.57 73.3967 170.58 73.237 171.586 72.9674C175.659 71.8762 178.89 69.1962 180.792 65.7729Z"
            fill="#0052B4"
          />
        </g>
        <rect
          width="104.352"
          height="104.352"
          rx="20.8704"
          transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 247.745 103.289)"
          fill="#252C3D"
          fillOpacity="0.51"
        />
        <g clipPath="url(#clip2_5883_66868)">
          <path
            d="M279.238 19.2278C290.371 16.2446 301.815 22.8517 304.798 33.9854C307.782 45.119 301.174 56.563 290.041 59.5462C278.907 62.5295 267.463 55.9223 264.48 44.7887C261.497 33.655 268.104 22.2111 279.238 19.2278Z"
            fill="#6DA544"
          />
          <path
            d="M287.927 51.6578L301.293 34.9248L281.351 27.1162L267.986 43.8492L287.927 51.6578Z"
            fill="#FFDA44"
          />
          <path
            d="M282.761 32.3751C286.633 31.3374 290.614 33.6356 291.651 37.5081C292.689 41.3807 290.391 45.3612 286.518 46.3988C282.646 47.4365 278.665 45.1383 277.628 41.2658C276.59 37.3932 278.888 33.4127 282.761 32.3751Z"
            fill="white"
          />
          <path
            d="M281.25 40.7647C280.031 41.0914 278.804 41.2213 277.604 41.1739C276.627 37.3306 278.918 33.4046 282.76 32.3751C285.135 31.7386 287.55 32.3589 289.303 33.8264C287.861 37.1244 284.989 39.7628 281.25 40.7647Z"
            fill="#0052B4"
          />
          <path
            d="M291.161 36.1996C291.362 36.612 291.527 37.0485 291.651 37.5082C292.688 41.3808 290.39 45.3612 286.518 46.3989C283.628 47.1731 280.679 46.0895 278.937 43.881C279.938 43.8234 280.949 43.6637 281.955 43.3941C286.027 42.3029 289.258 39.6228 291.161 36.1996Z"
            fill="#0052B4"
          />
        </g>
        <rect
          width="540.89"
          height="104.352"
          transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 27.0082 162.436)"
          fill="url(#paint0_linear_5883_66868)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5883_66868"
          x1="1.64785e-09"
          y1="52.1759"
          x2="374.057"
          y2="52.2757"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.4" stopColor="var(--green800)" />
          <stop offset="1" stopColor="var(--green800)" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_5883_66868">
          <rect
            width="41.7407"
            height="41.7407"
            fill="white"
            transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 49.1446 124.094)"
          />
        </clipPath>
        <clipPath id="clip1_5883_66868">
          <rect
            width="41.7407"
            height="41.7407"
            fill="white"
            transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 159.513 94.5213)"
          />
        </clipPath>
        <clipPath id="clip2_5883_66868">
          <rect
            width="41.7407"
            height="41.7407"
            fill="white"
            transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 269.881 64.948)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PixBG;
