import { Cryptos } from "constants/cryptos";

import Text, { TextVariants } from "components/common/Text";
import { ArrowDownFromLine, ArrowUpFromLine } from "lucide-react";
import classNames from "classnames";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface BackgroundCardProps {
  icon: string;
  isUp?: boolean;
  cardColor: string;
  amount: number;
  ticker: Cryptos;
  tagName: string;
  className: string;
}

const BackgroundCard: React.FC<BackgroundCardProps> = ({
  icon,
  isUp,
  cardColor,
  amount,
  ticker,
  tagName,
  className,
}) => (
  <div className={classNames(className, styles.card)}>
    <div style={{ backgroundColor: cardColor }} className={styles.icon}>
      {icon}
    </div>
    <Text variant={TextVariants.SemiboldTextXS} color={cardColor}>
      {tagName}
    </Text>
    {isUp ? (
      <ArrowUpFromLine size={px2rem(8)} color="var(--slate500)" />
    ) : (
      <ArrowDownFromLine size={px2rem(8)} color="var(--slate500)" />
    )}
    <Text
      variant={TextVariants.SemiboldTextXS}
      color="var(--slate600)"
      className={styles.text}
    >
      {amount} {ticker}
    </Text>
  </div>
);

export default BackgroundCard;
