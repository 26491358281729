import Text, { TextVariants } from "components/common/Text";
import { Check } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface GoldBenefitProps {
  title: string;
  description: string;
}

const GoldBenefit: React.FC<GoldBenefitProps> = ({ title, description }) => {
  return (
    <div className={styles.goldBenefitContainer}>
      <div className={styles.icon}>
        <Check color="var(--white-to-black)" size={px2rem(16)} />
      </div>
      <div className={styles.info}>
        <Text variant={TextVariants.RegularText} color="var(--yellow800)">
          {title}
        </Text>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {description}
        </Text>
      </div>
    </div>
  );
};

export default GoldBenefit;
