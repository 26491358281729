import { useEffect } from "react";

import IconBadge from "components/common/Badges/IconBadge";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import NavigationHeader from "components/common/NavigationHeader";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { useCreateAccount } from "context/CreateAccountProvider";
import { Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes, { onboardingScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { refreshPage } from "utils";
import { Projects, trackAction, trackScreen } from "utils/amplitude";
import { AnalyticsEvents, trackAnalyticsEvent } from "utils/firebaseAnalytics";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { FbEvents, trackFbEvent } from "utils/fbTracking";

import styles from "../styles.module.scss";

const ApprovedScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { goToHome } = useCreateAccount();

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackAnalyticsEvent(AnalyticsEvents.HOME_EXITO_PROCESO_COMITENTE);
    trackFbEvent(FbEvents.HOME_EXITO_PROCESO_COMITENTE);

    trackScreen(
      onboardingScreenNames.verificationComplete,
      previousScreen,
      undefined,
      Projects.ONBOARDING
    );

    dispatch(setCurrentScreen(onboardingScreenNames.verificationComplete));
  }, []);

  const onClickBack = () => {
    trackAction(
      `${onboardingScreenNames.verificationComplete} - Click Quit`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  const onClickGoToHome = () => {
    trackAction(
      `${onboardingScreenNames.verificationComplete} -  Click Home`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  const onClickDeposit = () => {
    trackAction(
      `${onboardingScreenNames.verificationComplete} -  Click Deposit`,
      undefined,
      Projects.ONBOARDING
    );

    navigate(mainRoutes.receive);
    refreshPage();
  };

  return (
    <div className={styles.finalContainer}>
      <NavigationHeader title="Verificación completa" onClick={onClickBack} />
      <div className={styles.content}>
        <IconBadge backgroundColor="var(--green300)">
          <Check color="var(--green900)" />
        </IconBadge>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h2"
          color="var(--slate900)"
          className={styles.title}
        >
          ¡Gracias por tu tiempo!
        </Heading>
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate800)"
          className={styles.text}
        >
          Tu cuenta ya fue abierta. Podés comenzar a operar en Cocos Capital.
        </Text>
      </div>

      <div className={styles.buttonContainer}>
        <FilledButton onClick={onClickGoToHome}>Volver al inicio</FilledButton>
        <OutlinedButton onClick={onClickDeposit}>
          Ingresar dinero
        </OutlinedButton>
      </div>
    </div>
  );
};

export default ApprovedScreen;
