import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { payKeys } from "apis/queryKeyFactories";
import { paymentService } from "apis/services";
import { AxiosError } from "axios";
import { PixPrices } from "interfaces/api-responses";

const ONE_MINUTE = 60000;

const fetchPixPrices = async () => {
  const { data } = await API.get<PixPrices>(paymentService.pixPrices);

  return data;
};

export const usePixPricesQuery = () => {
  return useAppQuery<PixPrices, AxiosError>({
    queryKey: () => payKeys.prices,
    queryFn: fetchPixPrices,
    options: {
      onError: (e: AxiosError) => {
        return e;
      },
      retry: false,
      refetchInterval: ONE_MINUTE,
      staleTime: ONE_MINUTE,
    },
  });
};
