import { DETAIL_BALANCE } from "constants/portfolio";

import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import Amount, { AmountSizes } from "components/common/Amount";
import px2rem from "utils/px2rem";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { usePortfolio } from "context/PortfolioProvider";
import { PortfolioSettlements, SettlementPeriod } from "interfaces/portfolio";
import { useAppState } from "context/AppStateProvider";

import styles from "./styles.module.scss";

interface CurrencyDetailCardProps {
  settlements: PortfolioSettlements[];
  currency: Currencies;
}

const CurrencyDetailCard: React.FC<CurrencyDetailCardProps> = ({
  settlements,
  currency,
}) => {
  const { selectedCurrency } = usePortfolio();
  const { isBalanceHidden } = useAppState();

  const { icon: Icon, title } = DETAIL_BALANCE[currency];

  const getTextColor = (value: number) => {
    if (isBalanceHidden) return;
    if (value < 0) return "var(--red800)";
  };

  const ciAmount =
    settlements.find(({ period }) => {
      return period === SettlementPeriod.CI;
    })?.amount || 0;

  const oneDayAmount =
    settlements.find(({ period }) => {
      return period === SettlementPeriod.ONE_DAY;
    })?.amount || 0;

  const infAmount =
    settlements.find(({ period }) => {
      return period === SettlementPeriod.INF;
    })?.amount || 0;

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.topRow}>
        <div className={styles.currencyWrapper}>
          <Icon size={px2rem(16)} />
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            {title}
          </Text>
        </div>
      </div>
      <PriceLabel
        price={infAmount}
        currency={selectedCurrency}
        size={PriceLabelSizes.Small}
        color={getTextColor(infAmount)}
        isBalanceHidden={isBalanceHidden}
        symbolColor={getTextColor(infAmount)}
      />
      <div className={styles.balanceDetailWrapper}>
        <div className={styles.detailRow}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Hoy (C.I.)
          </Text>
          <Amount
            amount={ciAmount}
            currency={selectedCurrency}
            size={AmountSizes.Small}
            isBalanceHidden={isBalanceHidden}
            mainColor={getTextColor(ciAmount)}
            secondaryColor={getTextColor(ciAmount)}
          />
        </div>
        <div className={styles.detailRow}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            En 24 hs
          </Text>
          <Amount
            amount={oneDayAmount}
            size={AmountSizes.Small}
            currency={selectedCurrency}
            isBalanceHidden={isBalanceHidden}
            mainColor={getTextColor(oneDayAmount)}
            secondaryColor={getTextColor(oneDayAmount)}
          />
        </div>
        <div className={styles.detailRow}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Más de 24 hs
          </Text>
          <Amount
            amount={infAmount}
            size={AmountSizes.Small}
            currency={selectedCurrency}
            isBalanceHidden={isBalanceHidden}
            mainColor={getTextColor(infAmount)}
            secondaryColor={getTextColor(infAmount)}
          />
        </div>
      </div>
    </div>
  );
};

export default CurrencyDetailCard;
