import { useEffect, useState } from "react";

import { CircularProgress, Typography } from "@mui/material";
import API from "apis";
import { CaucionDesktop } from "components/page-market/desktop/caucion";
import { CaucionMobile } from "components/page-market/mobile/caucion";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { InstrumentData, MarketData } from "interfaces/api-responses";
import { CaucionSocketMessage } from "interfaces/api-responses/socket/book";
import { Schedules } from "interfaces/calendar";
import { Instruments, Method } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { NumberFormatValues } from "react-number-format";
import { socket } from "socket";
import {
  clearSubscribeList,
  setSubscribeList,
} from "features/prices//capitalPricesSlice";
import {
  getMarketsTypesLists,
  getSegment,
  updateCaucionOrderOptions,
} from "features/markets";
import { RootState } from "store/store";
import { checkMarketOpen } from "store/selectors/markets.selector";
import { DesktopOrTablet, Mobile } from "utils/responsive";
import { marketService } from "apis/services";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_PAGE = 1;
const PAGE_SIZE = 50;

const Caucion: React.FC = () => {
  const dispatch = useAppDispatch();
  const caucionOrder = useAppSelector(
    (state: RootState) => state.markets.caucionOrder
  );
  const { open } = useAppSelector((state) => state.rightModal);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [caucionData, setCaucionData] = useState<MarketData>();

  const [caucion, setCaucion] = useState<InstrumentData>();

  const isMarketOpen = checkMarketOpen(Schedules.CAUCION);

  const MAX_TERM = 14;

  const getDateLabel = () => {
    return dayjs()
      .add(caucionOrder.term, "days")
      .tz("America/Buenos_Aires")
      .format("DD MMM");
  };
  const [dateLabel, setDateLabel] = useState(getDateLabel());

  const { wallet } = useAppSelector((state: RootState) => state.wallet);
  const getAvailable = () => {
    return (
      wallet &&
      (caucionOrder.currency === "ARS"
        ? Number(wallet["CI"].ars)
        : Number(wallet["CI"].usd))
    );
  };
  const [available, setAvailable] = useState(getAvailable());

  useEffect(() => {
    const getCaucionData = async () => {
      try {
        setLoading(true);
        const { data } = await API.get<MarketData>(marketService.tickers, {
          params: {
            instrument_type: Instruments.CAUCION,
            currency: Currencies.ARS,
            segment: getSegment(Instruments.CAUCION),
            page: DEFAULT_PAGE,
            size: PAGE_SIZE,
          },
        });
        data.items.sort((a, b) => (a.term || 0) - (b.term || 0));
        setCaucionData(data);

        dispatch(
          updateCaucionOrderOptions({
            ...caucionOrder,
            term: data.items[0].term || 1,
          })
        );

        setCaucion(data.items.find((m) => m.term === caucionOrder.term));
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getCaucionData();
  }, []);

  useEffect(() => {
    if (open) return;

    if (caucion?.long_ticker) {
      dispatch(setSubscribeList([caucion.long_ticker]));
    }
  }, [caucion?.long_ticker, open]);

  useEffect(() => {
    return () => {
      dispatch(clearSubscribeList());
    };
  }, []);

  useEffect(() => {
    const messageHandler = (message: CaucionSocketMessage) => {
      setCaucionData((prev) => {
        if (!prev) return;
        return {
          ...prev,
          items: prev?.items.map((caucion) => {
            if (caucion.long_ticker === message.long_ticker) {
              return { ...caucion, ...message };
            }
            return caucion;
          }),
        };
      });
    };
    socket.on("message", messageHandler);
    return () => {
      socket.off("message", messageHandler);
    };
  }, [socket]);

  useEffect(() => {
    if (!caucionData) return;
    const termCaucion = caucionData.items.find(
      (m) => m.term === caucionOrder.term
    );

    if (!termCaucion) {
      setCaucion(caucionData.items[0]);
    } else {
      setCaucion(termCaucion);
    }
  }, [caucionData, caucionOrder.term]);

  const getCurrencyOptions = () => {
    const mock = ["ARS"];

    return mock.map((c) => ({
      value: c,
      label: c,
    }));
  };

  useEffect(() => {
    if (wallet) {
      setAvailable(getAvailable());
    }
  }, [wallet, caucionOrder.currency]);

  const handleChangeAmountValue = ({ floatValue }: NumberFormatValues) => {
    dispatch(
      updateCaucionOrderOptions({
        ...caucionOrder,
        amount: floatValue,
      })
    );
  };

  const handleChangeRateValue = ({ floatValue }: NumberFormatValues) => {
    if (caucionOrder.method === "limite") {
      dispatch(
        updateCaucionOrderOptions({
          ...caucionOrder,
          rate: floatValue,
        })
      );
    }
  };

  const handleChangeCurrency = (newValue?: string) => {
    if (newValue === Currencies.ARS && newValue !== caucionOrder.currency) {
      dispatch(
        updateCaucionOrderOptions({
          ...caucionOrder,
          currency: newValue,
        })
      );
      dispatch(
        getMarketsTypesLists({ type: Instruments.CAUCION, currency: newValue })
      );
    }
  };

  const handleChangeMethod = (newValue?: string) => {
    if (
      (newValue === Method.MARKET || newValue === Method.LIMIT) &&
      newValue !== caucionOrder.method
    ) {
      dispatch(
        updateCaucionOrderOptions({
          ...caucionOrder,
          method: newValue,
          rate: newValue === "mercado" ? getMarketRate() : caucionOrder.rate,
        })
      );
    }
  };

  const getMarketRate = () => {
    if (caucion && caucion.bids?.length) {
      const bidsPrices = caucion.bids.map((bid) => {
        return bid.price;
      });

      return Math.max(...bidsPrices);
    }
  };

  const handleChangeTerm = (mode: "add" | "substract") => (event: any) => {
    event.stopPropagation();
    if (!caucionData) return;

    const oldCaucionIndex = caucionData.items.findIndex(
      (m) => m.term === caucionOrder.term
    );
    const nextTerm = caucionData.items[oldCaucionIndex + 1]?.term;
    const prevTerm = caucionData.items[oldCaucionIndex - 1]?.term;

    if (mode === "substract" && (caucionOrder.term === 1 || !prevTerm)) {
      return;
    }
    if (
      mode === "add" &&
      (caucionOrder.term === MAX_TERM || !nextTerm || nextTerm > MAX_TERM)
    ) {
      return;
    }

    const newTerm = mode === "add" ? nextTerm : prevTerm;
    dispatch(
      updateCaucionOrderOptions({
        ...caucionOrder,
        term: newTerm ? newTerm : caucionData.items[0].term || 1,
      })
    );
  };

  useEffect(() => {
    const date = getDateLabel();

    setDateLabel(date);
  }, [caucionOrder.term, caucionData]);

  useEffect(() => {
    if (caucionOrder.method === "mercado") {
      const marketRate = getMarketRate();

      if (marketRate !== caucionOrder.rate) {
        dispatch(
          updateCaucionOrderOptions({
            ...caucionOrder,
            rate: marketRate,
          })
        );
      }
    }
  }, [caucion?.bids]);

  const getDisabledButton = () => {
    if (
      !caucionOrder.amount ||
      !caucionOrder.rate ||
      caucionOrder.currency !== "ARS" ||
      !isMarketOpen
    ) {
      return true;
    }

    return (
      !wallet?.CI.ars || (wallet && Number(wallet.CI.ars) < caucionOrder.amount)
    );
  };

  if (loading)
    return (
      <div className={styles.loading_wrapper}>
        <CircularProgress size={50} />
      </div>
    );

  if (error || !caucionData)
    return (
      <div className={styles.error_wrapper}>
        <Typography component="h1" variant="textL_bold">
          ¡Ups! Parece que algo salió mal.
        </Typography>
      </div>
    );

  return (
    <>
      <DesktopOrTablet>
        <CaucionDesktop
          available={available}
          caucion={caucion}
          dateLabel={dateLabel}
          getCurrencyOptions={getCurrencyOptions}
          getDisabledButton={getDisabledButton}
          onChangeAmountValue={handleChangeAmountValue}
          onChangeCurrency={handleChangeCurrency}
          onChangeMethod={handleChangeMethod}
          onChangeRateValue={handleChangeRateValue}
          onChangeTerm={handleChangeTerm}
          markets={caucionData}
        />
      </DesktopOrTablet>
      <Mobile>
        <CaucionMobile
          available={available}
          caucion={caucion}
          dateLabel={dateLabel}
          getCurrencyOptions={getCurrencyOptions}
          getDisabledButton={getDisabledButton}
          onChangeAmountValue={handleChangeAmountValue}
          onChangeCurrency={handleChangeCurrency}
          onChangeMethod={handleChangeMethod}
          onChangeRateValue={handleChangeRateValue}
          onChangeTerm={handleChangeTerm}
          markets={caucionData}
        />
      </Mobile>
    </>
  );
};

export default Caucion;
