import { useEffect, useState } from "react";

import { CountryCodeOptions } from "components/page-sign-up/utils";
import Text, { TextVariants } from "components/common/Text";
import { CircleFlag } from "react-circle-flags";
import { ChevronDown } from "lucide-react";

import styles from "./styles.module.scss";

interface CountryCodeSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  options: CountryCodeOptions[];
  disabled?: boolean;
  setCountryCode: (countryCode: number) => void;
}

const CountryCodeSelect: React.FC<CountryCodeSelectProps> = ({
  options,
  disabled = false,
  setCountryCode,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryCodeOptions>(
    options[0]
  );
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);

  const handleOnClickSelect = () => {
    if (disabled) return;
    setDisplayOptions(!displayOptions);
  };

  useEffect(() => {
    setCountryCode(selectedCountry.phoneCode);
  }, [selectedCountry]);

  const handleOnClickOption = (option: CountryCodeOptions) => {
    setCountryCode(option.phoneCode);
    setSelectedCountry(option);
    setDisplayOptions(false);
  };
  return (
    <div className={styles.CountryCodeSelectContainer}>
      <label className={styles.label}>País</label>
      <div className={styles.select} onClick={handleOnClickSelect}>
        <div className={styles.selectedCountry}>
          <CircleFlag countryCode={selectedCountry.countryCode} height="16" />
          <Text variant={TextVariants.RegularTextS} color="var(--slate900)">
            {selectedCountry.name} (+{selectedCountry.phoneCode})
          </Text>
        </div>
        <ChevronDown size={16} color="var(--slate800)" />
      </div>

      {displayOptions && (
        <div className={styles.displayer}>
          <div className={styles.list}>
            {options.map((option) => (
              <div
                key={option.countryCode}
                className={styles.option}
                onClick={() => handleOnClickOption(option)}
              >
                <CircleFlag countryCode={option.countryCode} height="16" />
                <Text
                  variant={TextVariants.RegularTextS}
                  color="var(--slate900)"
                >
                  {option.name} (+{option.phoneCode})
                </Text>
              </div>
            ))}
          </div>
          <div
            className={styles.bottom}
            onClick={() => setDisplayOptions(false)}
          />
        </div>
      )}
    </div>
  );
};

export default CountryCodeSelect;
