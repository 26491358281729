import Divider from "components/common/Divider";
import Heading, { HeadingVariants } from "components/common/Heading";
import Currency from "components/common/Text/Currency";
import { getFCIIconColor, getIcon } from "components/page-cocos-pay/utils";
import { useCocosPay } from "context/CocosPayProvider";
import dayjs from "dayjs";
import px2rem from "utils/px2rem";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { getCurrencySymbol } from "utils";
import { Currencies } from "interfaces/wallet";
import BRFlag from "assets/icons/BRFlag";

import styles from "./styles.module.scss";

const ReceiptDetail: React.FC = () => {
  const { receiptData } = useCocosPay();

  const {
    businessName,
    createdAt,
    paymentMethod,
    idPayment,
    transactionQuantity,
    transactionCurrency,
    localQuantity,
    idBankTransaction,
  } = receiptData;

  const slate900 = "var(--slate900)";
  const slate800 = "var(--slate800)";

  const Icon = getIcon(paymentMethod);

  const date = dayjs(createdAt).format("DD/MM/YYYY");

  const time = dayjs(createdAt).format("HH:mm");

  const isBR = transactionCurrency === PaymentCurrencies.BRL;

  const exchangeRate = localQuantity / transactionQuantity;

  return (
    <div className={styles.ReceiptDetailContainer}>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={slate800}
          component="span"
        >
          Pagaste a
        </Heading>
        <Heading
          className={styles.businessName}
          variant={HeadingVariants.RegularSubtitle}
          color={slate900}
          component="span"
        >
          {businessName}
        </Heading>
      </div>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={slate800}
          component="span"
        >
          Monto
        </Heading>
        <div className={styles.amountWrapper}>
          {isBR && <BRFlag size={px2rem(16)} />}
          <Currency
            className={styles.amount}
            color={slate900}
            ticker={transactionCurrency}
            amount={transactionQuantity}
          />
        </div>
      </div>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={slate800}
          component="span"
        >
          Fecha
        </Heading>
        <Heading
          variant={HeadingVariants.SemiboldValueS}
          color={slate900}
          component="span"
        >
          {date} - {time}
        </Heading>
      </div>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={slate800}
          component="span"
        >
          Pagaste con
        </Heading>
        <Heading
          variant={HeadingVariants.RegularValueS}
          color={slate900}
          component="span"
          className={styles.currency}
        >
          <Icon size={px2rem(16)} color={getFCIIconColor(paymentMethod)} />{" "}
          {paymentMethod}
        </Heading>
      </div>
      {isBR && (
        <div className={styles.row}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            color={slate800}
            component="span"
          >
            Cotización
          </Heading>
          <Heading
            variant={HeadingVariants.RegularValueS}
            color={slate800}
            component="span"
            className={styles.currency}
          >
            {getCurrencySymbol(Currencies.BRL)}
            <Heading
              variant={HeadingVariants.SemiboldValueS}
              color={slate900}
              component="span"
              className={styles.currency}
            >
              1 ≈ <Currency amount={exchangeRate} color={slate900} />
            </Heading>
          </Heading>
        </div>
      )}
      <Divider className={styles.divider} />
      <div>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          component="h3"
          color={slate800}
        >
          Comprobante de pago N°
        </Heading>
        <Heading
          className={styles.id}
          variant={HeadingVariants.SemiboldSubtitle}
          component="h3"
          color={slate900}
        >
          {idPayment}
        </Heading>
      </div>
      {idBankTransaction && (
        <div>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h3"
            color={slate800}
          >
            Comprobante bancario N°
          </Heading>
          <Heading
            className={styles.id}
            variant={HeadingVariants.SemiboldSubtitle}
            component="h3"
            color={slate900}
          >
            {idBankTransaction}
          </Heading>
        </div>
      )}
    </div>
  );
};

export default ReceiptDetail;
