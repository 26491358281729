import { Typography } from "@mui/material";
import { ContingenciaIcon } from "assets/icons/contingencia-icon";

import styles from "./styles.module.scss";

const MaintenancePage: React.FC = () => (
  <div className={styles.maintenanceWrapper}>
    <ContingenciaIcon className={styles.icon} />
    <Typography component="h1" variant="heading3" className={styles.title}>
      Estamos realizando mejoras técnicas
    </Typography>
    <Typography component="p" variant="textL" className={styles.text}>
      Estamos realizando un mantenimiento para restablecer la app en los
      próximos minutos.
    </Typography>
  </div>
);
export default MaintenancePage;
