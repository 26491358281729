import CreateNewAccountPage from "components/page-create-account";
import { CreateAccountProvider } from "context/CreateAccountProvider";

const CreateNewAccount: React.FC = () => (
  <CreateAccountProvider>
    <CreateNewAccountPage />
  </CreateAccountProvider>
);

export default CreateNewAccount;
