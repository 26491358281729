import GPay from "assets/icons/GPay";
import Text, { TextVariants } from "components/common/Text";
import Divider from "components/common/Divider";

import useGoogleFont from "../useGoogleFont";

import styles from "./styles.module.scss";

const GPayButton: React.FC = () => {
  useGoogleFont();

  return (
    <div className={styles.gpayBadge}>
      <GPay isActive />
      <Divider className={styles.divider} orientation="vertical" />
      <Text variant={TextVariants.RegularTextS} className={styles.gPayText}>
        Agregado a Google Pay
      </Text>
    </div>
  );
};

export default GPayButton;
