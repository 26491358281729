import classNames from "classnames";
import IconBadge from "components/common/Badges/IconBadge";
import NumbersBadge from "components/common/Badges/NumbersBadge";
import Text, { TextVariants } from "components/common/Text";
import { ChevronRight, LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface ProfileRowProps {
  icon: LucideIcon;
  title: string;
  detail?: string;
  notifications?: number;
  onClick: () => void;
  selected?: boolean;
}

const ProfileRow: React.FC<ProfileRowProps> = ({
  icon: Icon,
  title,
  detail,
  notifications,
  onClick,
  selected,
}) => {
  return (
    <div
      className={classNames(styles.profileRowContainer, {
        [styles.selected]: selected,
      })}
      onClick={onClick}
    >
      <div className={styles.wrapper}>
        <IconBadge backgroundColor="var(--blue900)" isSmall>
          <Icon size={px2rem(16)} color="var(--white-to-black)" />
        </IconBadge>
        <div className={styles.detail}>
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            {title}
          </Text>
          {detail && (
            <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
              {detail}
            </Text>
          )}
        </div>
      </div>

      <div className={styles.actions}>
        {notifications && <NumbersBadge number={notifications} />}
        <ChevronRight
          size={px2rem(16)}
          color="var(--slate800)"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default ProfileRow;
