import { ArrowRight, CreditCard } from "lucide-react";
import ARGFlag from "assets/icons/ARGFlag";
import { CardTransactionPayment } from "interfaces/card/interface";
import px2rem from "utils/px2rem";
import { PaymentCurrencies } from "interfaces/pay/enums";
import getCurrencyIcon from "components/page-cocos-card/Home/PaymentMethodList/CurrencyIcon";

import styles from "./styles.module.scss";

interface TransactionHeader {
  payments: CardTransactionPayment[];
  isCredit?: boolean;
}

const TransactionHeader: React.FC<TransactionHeader> = ({
  payments,
  isCredit = false,
}) => {
  if (!payments.length)
    return (
      <div
        className={styles.creditCardRejected}
        style={{ background: isCredit ? "var(--green800)" : "var(--red800)" }}
      >
        <CreditCard size={px2rem(20)} />
      </div>
    );
  const paymentMethods = payments.map((payment) => payment.payment_method);
  const ArsToCard = (
    <>
      <ArrowRight size={px2rem(16)} color="var(--slate800)" />
      <ARGFlag size={px2rem(32)} />
      <ArrowRight size={px2rem(16)} color="var(--slate800)" />
      <div className={styles.creditCard}>
        <CreditCard size={px2rem(20)} />
      </div>
    </>
  );

  const renderSinglePaymentMethod = (paymentMethod: PaymentCurrencies) => (
    <div className={styles.header}>
      {getCurrencyIcon({ currency: paymentMethod, size: 32 })}
      {ArsToCard}
    </div>
  );

  const renderMultiplePaymentMethods = () => (
    <div className={styles.header}>
      {paymentMethods.map((paymentMethod, index) => (
        <div key={index} className={styles.multiple}>
          {getCurrencyIcon({ currency: paymentMethod, size: 32 })}
        </div>
      ))}
      {ArsToCard}
    </div>
  );

  const getHeaderRow = () => {
    if (paymentMethods.length === 1) {
      return paymentMethods.includes(PaymentCurrencies.ARS) ? (
        <ARGFlag size={px2rem(32)} />
      ) : (
        renderSinglePaymentMethod(paymentMethods[0])
      );
    }

    return renderMultiplePaymentMethods();
  };

  return getHeaderRow();
};

export default TransactionHeader;
