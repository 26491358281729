import { getListMenuHome } from "constants/capitalMenu";

import { useNavigate } from "react-router-dom";
import { ConfirmOrder } from "components/common/confirm-order";
import CommonLayout from "layouts/common";
import { getOrderById } from "features/markets";
import { RootState } from "store/store";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

const OrderConfirmPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const data = useAppSelector(
    (state: RootState) => state.markets.confirmOrderData
  );

  const handleClickOrderRow = (orderId: string) => {
    dispatch(getOrderById(orderId));
  };

  const handleCloseSidebar = () => {
    navigate(-1);
  };

  return (
    <div className="confirm-order-view">
      <CommonLayout
        {...getListMenuHome()}
        hideBottomNavigation
        mobileHeaderTitle="Confirmar Orden"
      >
        {
          <ConfirmOrder
            data={data}
            onClickOrderDetail={handleClickOrderRow}
            onClose={handleCloseSidebar}
            instrument_type="BONOS_CORP"
          />
        }
      </CommonLayout>
    </div>
  );
};

export default OrderConfirmPage;
