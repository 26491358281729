import CocosGoldIcon from "assets/icons/CocosGold";
import Heading, { HeadingVariants } from "components/common/Heading";
import PageHeader from "components/common/PageHeader";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { RootState } from "store/store";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

const GoldHeader: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isMobile } = useDeviceCheck();

  const darkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );

  const handleGoBack = () => {
    return isMobile ? navigate(-1) : dispatch(closeRightModal());
  };

  return (
    <div className={styles.goldHeaderContainer}>
      {isMobile && (
        <PageHeader
          iconColor="var(--blue900)"
          titleColor="var(--blue900)"
          icon={ArrowLeft}
          onClick={handleGoBack}
        />
      )}
      <CocosGoldIcon
        className={styles.icon}
        color={darkMode ? "#fff" : undefined}
      />
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h2"
      >
        Activá nuestro servicio exclusivo y accedé a todos sus beneficios
      </Heading>
    </div>
  );
};

export default GoldHeader;
