import { useNavigate, useParams } from "react-router-dom";

import PayReceipt from "./PayReceipt";

const MovementReceiptPage: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  if (!id) {
    navigate(-1);
    return null;
  }

  return <PayReceipt id={id} />;
};

export default MovementReceiptPage;
