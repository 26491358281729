import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { ToggleSizes, ToggleUI } from "components/common/controls/toggle";

import styles from "./styles.module.scss";

interface InstrumentsTableHeaderProps {
  isSettlementExpanded: boolean;
  setIsSettlementExpanded: (state: boolean) => void;
  isTableGrouped: boolean;
  setIsTableGrouped: (state: boolean) => void;
}

const InstrumentsTableHeading = ({
  isSettlementExpanded,
  isTableGrouped,
  setIsSettlementExpanded,
  setIsTableGrouped,
}: InstrumentsTableHeaderProps) => {
  return (
    <div className={styles.instrumentsHeader}>
      <Heading
        component="h2"
        variant={HeadingVariants.RegularTitle}
        color="var(--slate800)"
      >
        Instrumentos
      </Heading>
      <div className={styles.switchWrapper}>
        <div className={styles.switchContainer}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Agrupar
          </Text>
          <ToggleUI
            checked={isTableGrouped}
            size={ToggleSizes.Small}
            onClick={() => setIsTableGrouped(!isTableGrouped)}
          />
        </div>
        <div className={styles.switchContainer}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Expandir plazos
          </Text>
          <ToggleUI
            checked={isSettlementExpanded}
            size={ToggleSizes.Small}
            onClick={() => setIsSettlementExpanded(!isSettlementExpanded)}
          />
        </div>
      </div>
    </div>
  );
};

export default InstrumentsTableHeading;
