import { ThemeVariants } from "interfaces/theme";
import Text, { TextVariants } from "components/common/Text";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface LinkProps {
  children: string;
  isSmall?: boolean;
  icon?: LucideIcon;
  onClick: () => void;
  variant: ThemeVariants;
  textColor?: string;
  backgroundColor?: string;
}

const Link: React.FC<LinkProps> = ({
  icon: Icon,
  isSmall,
  variant,
  onClick,
  children,
  textColor = "var(--black-to-white)",
  backgroundColor,
}) => {
  const textVariant = isSmall
    ? TextVariants.SemiboldTextS
    : TextVariants.SemiboldText;

  const getLinkColor = () => {
    if (variant === ThemeVariants.Capital) {
      return "var(--blue800)";
    }

    if (variant === ThemeVariants.Crypto) {
      return "var(--purple800)";
    }

    return textColor;
  };

  const linkColor = getLinkColor();

  return (
    <div
      className={classNames(styles.textWrapper, {
        [styles.withBackground]: backgroundColor,
      })}
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
    >
      <div style={{ borderBottom: `1px solid ${linkColor}` }}>
        <Text variant={textVariant} color={linkColor}>
          {children}
        </Text>
      </div>
      {Icon && <Icon color={linkColor} size={px2rem(16)} />}
    </div>
  );
};

export default Link;
