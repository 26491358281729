import { IconProps } from "assets/icons/BTC";

const Pencil: React.FC<IconProps> = () => (
  <svg
    width="84"
    height="84"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5381_55301)">
      <path
        d="M45.4788 30.8894H7.63174C3.41685 30.8894 0 34.3062 0 38.5211V76.3682C0 80.5831 3.41685 83.9999 7.63174 83.9999H45.4788C49.6937 83.9999 53.1105 80.5831 53.1105 76.3682V38.5211C53.1105 34.3062 49.6937 30.8894 45.4788 30.8894Z"
        fill="url(#paint0_linear_5381_55301)"
      />
      <path
        d="M28.4854 68.0495L13.3873 71.7509C13.2274 71.7886 13.0606 71.7849 12.9025 71.7402C12.7445 71.6955 12.6004 71.6113 12.4839 71.4954C12.3675 71.3796 12.2824 71.236 12.2369 71.0782C12.1913 70.9204 12.1867 70.7535 12.2235 70.5934L15.8994 55.5144L28.4854 68.0495Z"
        fill="white"
      />
      <path
        d="M79.8262 16.7395L67.2615 4.1748L15.9232 55.5131L28.488 68.0778L79.8262 16.7395Z"
        fill="#18191B"
      />
      <path
        d="M22.202 61.7978L73.5445 10.4553"
        stroke="#777B84"
        strokeWidth="1.10024"
        strokeMiterlimit="10"
      />
      <path
        d="M13.4573 65.582L18.3734 70.4918L13.4001 71.7637C13.2382 71.8056 13.0683 71.8045 12.907 71.7605C12.7458 71.7166 12.5987 71.6313 12.4806 71.5131C12.3624 71.3949 12.2771 71.2479 12.2331 71.0866C12.1891 70.9253 12.188 70.7554 12.2299 70.5935L13.4573 65.582Z"
        fill="#363A3F"
      />
      <path
        d="M77.1412 1.4929L82.5107 6.86237C82.9831 7.33482 83.3579 7.8957 83.6136 8.51298C83.8693 9.13026 84.0009 9.79186 84.0009 10.46C84.0009 11.1281 83.8693 11.7898 83.6136 12.407C83.3579 13.0243 82.9831 13.5852 82.5107 14.0576L79.8125 16.7559L67.2477 4.19113L69.9459 1.4929C70.9001 0.538747 72.1942 0.00271063 73.5436 0.00271059C74.893 0.00271063 76.1871 0.538747 77.1412 1.4929Z"
        fill="#363A3F"
      />
      <path
        d="M67.261 4.17188L79.828 16.7388"
        stroke="white"
        strokeWidth="1.10024"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5381_55301"
        x1="4.0639"
        y1="79.936"
        x2="49.7335"
        y2="34.2664"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#D1D5E0" />
        <stop offset="0.4" stopColor="#DADEE9" />
        <stop offset="0.76" stopColor="#E6EAF4" />
      </linearGradient>
      <clipPath id="clip0_5381_55301">
        <rect width="84" height="84" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Pencil;
