import { useState } from "react";

import Banner, { BannerVariants } from "components/common/Banner";
import InputField, { InputTypes } from "components/common/input/InputField";
import BasicWrapper from "components/common/BasicWrapper";
import { ThemeVariants } from "interfaces/theme";

import VerifyAccount from "./VerifyAccount";

import styles from "./styles.module.scss";

interface NewAccountProps {
  onClose: () => void;
  onError: () => void;
  onDisplayToast: () => void;
  referral: NewAccountReferral;
}
export enum NewAccountReferral {
  Withdraw = "withdraw",
  BankAccounts = "bank_accounts",
}

const NewAccount: React.FC<NewAccountProps> = ({
  onClose,
  onError,
  onDisplayToast,
  referral,
}) => {
  const [CBUOrAlias, setCBUOrAlias] = useState<string>("");
  const [displayMfa, setDisplayMfa] = useState<boolean>(false);

  const CBU_LENGTH = 22;

  const validCharsRegex = /^[a-zA-Z0-9.-]{1,22}$/;

  const cbuRegex = /^\d{22}$/;

  const aliasRegex = /^[\w.-]{6,20}$/;

  const hasCBUOrAliasError = () => {
    if (CBUOrAlias.length === 0) return false;
    if (!validCharsRegex.test(CBUOrAlias)) return true;
    return (
      !aliasRegex.test(CBUOrAlias) &&
      CBUOrAlias.length === CBU_LENGTH &&
      !cbuRegex.test(CBUOrAlias)
    );
  };

  const isButtonDisabled =
    !aliasRegex.test(CBUOrAlias) && !cbuRegex.test(CBUOrAlias);

  const onPaste = (v: string) => {
    setCBUOrAlias(v);
  };

  if (displayMfa) {
    return (
      <VerifyAccount
        CBUOrAlias={CBUOrAlias}
        onError={onError}
        onClose={onClose}
        onDisplayToast={onDisplayToast}
        referral={referral}
      />
    );
  }

  return (
    <BasicWrapper
      navigationHeaderProps={{
        title: "Agendar",
        onClick: onClose,
        withCloseIcon: false,
      }}
      secondaryButtonProps={{
        children: "Agendar",
        onClick: () => setDisplayMfa(true),
        disabled: isButtonDisabled,
      }}
    >
      <Banner
        className={styles.banner}
        variant={BannerVariants.INFO_CAPITAL}
        text="Únicamente podes enviar dinero a cuentas que estén a tu nombre. "
      />
      <InputField
        placeholder="Alias, CBU o CVU"
        type={InputTypes.Text}
        label="Ingresá tu Alias, CBU o CVU"
        withPaste
        onChange={(e) => {
          setCBUOrAlias(e.target.value);
        }}
        value={CBUOrAlias}
        onClickPaste={onPaste}
        variant={ThemeVariants.Capital}
        hasError={hasCBUOrAliasError()}
        errorMessage="Asegurate de ingresar una cuenta a tu nombre y los datos de manera correcta."
      />
    </BasicWrapper>
  );
};

export default NewAccount;
