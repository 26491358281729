import { useEffect, useState } from "react";

import { IconButton, Skeleton, Typography } from "@mui/material";
import { RefreshIcon } from "assets/icons/refresh";
import OrderRow from "components/common/order-row";
import { motion } from "framer-motion";
import { OrdersResponse } from "interfaces/api-responses";
import { clearOrdersListError, getOrders } from "features/markets";
import { RootState } from "store/store";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

interface PropTypes {
  onClickOrderRow?: (orderId: string) => void;
}

export const TradingViewOrders = (props: PropTypes) => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state: RootState) => state.markets.orders);
  const ordersError = useAppSelector(
    (state: RootState) => state.markets.getOrdersListError
  );
  const ordersLoading = useAppSelector(
    (state: RootState) => state.markets.getOrdersListLoading
  );
  const selectedMarket = useAppSelector(
    (state: RootState) => state.markets.marketDetail
  );
  const [filteredOrders, setFilteredOrders] = useState<
    OrdersResponse[] | undefined
  >([]);
  const { onClickOrderRow } = props;

  useEffect(() => {
    if (ordersError.message) {
      dispatch(clearOrdersListError());
    }
    if (!ordersLoading) {
      dispatch(getOrders());
    }
  }, []);

  useEffect(() => {
    if (orders && orders.length > 0 && selectedMarket?.long_ticker) {
      const ordersByTicker = orders.filter((o) =>
        selectedMarket.long_ticker.split("-")[0].includes(o.ticker)
      );

      setFilteredOrders(ordersByTicker.length > 0 ? ordersByTicker : undefined);
    }
    if (orders === undefined) {
      setFilteredOrders(undefined);
    }
  }, [orders]);

  const reloadOrders = () => {
    dispatch(clearOrdersListError());
    dispatch(getOrders());
  };

  return (
    <div className="orders-container">
      <div className="header">
        <Typography component="p" variant="buttonL">
          Órdenes del día
        </Typography>
        <IconButton onClick={reloadOrders}>
          <motion.div
            animate={{
              rotate: [ordersLoading ? 360 : 0, 0],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
            }}
          >
            <RefreshIcon size={14} />
          </motion.div>
        </IconButton>
      </div>
      <div className="orders-list">
        {ordersError.message && (
          <div className="error-message">
            <Typography component="p" variant="textS">
              Tuvimos un problema para buscar tus órdenes del día
            </Typography>
          </div>
        )}
        {filteredOrders ? (
          filteredOrders.map((o) => (
            <OrderRow
              key={o.order_id}
              order={o}
              onClickOrderRow={onClickOrderRow}
            />
          ))
        ) : (
          <Typography variant="textS">Todavía no hay órdenes</Typography>
        )}
        {ordersLoading && <Skeleton height={48} />}
      </div>
    </div>
  );
};
