import { useEffect } from "react";

import BasicWrapper from "components/common/BasicWrapper";
import { MailCheck } from "lucide-react";
import { useTimeLeft } from "hooks/useTimeLeft";
import { ampli } from "ampli";

interface EmailValidationProps {
  email: string;
  showForm: () => void;
  registerEmail: () => void;
}

const EmailValidation: React.FC<EmailValidationProps> = ({
  email,
  showForm,
  registerEmail,
}) => {
  const RESEND_COOLDOWN = 60;

  const { timeLeft, isTimeCompleted, setTimeLeft } = useTimeLeft(
    RESEND_COOLDOWN
  );

  const buttonText = !isTimeCompleted
    ? `Volver a enviar (en ${timeLeft} segs)`
    : "Volver a enviar";

  const onClickResend = () => {
    if (!isTimeCompleted) return;

    setTimeLeft(RESEND_COOLDOWN);
    registerEmail();
    ampli.signupEmailVerificationResendEmail();
  };

  // const onClickOpenEmail = () => {
  //   ampli.signupEmailVerificationOpenEmail();
  // };

  useEffect(() => {
    ampli.signupEmailVerificationScreenViewed();
  }, []);

  return (
    <BasicWrapper
      title="Revisá tu casilla de correo"
      subtitle={
        "Para confirmar tu email, hacé click en el botón del correo electrónico que te enviamos a " +
        email
      }
      navigationHeaderProps={{
        onClick: showForm,
      }}
      icon={MailCheck}
      // primaryButtonText="Abrir mi correo"
      // primaryAction={onClickOpenEmail}
      secondaryButtonProps={{
        children: buttonText,
        onClick: onClickResend,
        disabled: !isTimeCompleted,
      }}
    />
  );
};

export default EmailValidation;
