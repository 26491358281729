import { useState } from "react";

import Avatar from "components/common/avatar";
import NumbersBadge from "components/common/Badges/NumbersBadge";
import QuietButton from "components/common/QuietButton";
import Text, { TextVariants } from "components/common/Text";
import { useAppSelector } from "hooks/redux";
import { ThemeVariants } from "interfaces/theme";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { getUserFullName, refreshPage } from "utils";

import SelectAccount, { Option } from "./SelectAccount";

import styles from "./styles.module.scss";

const UserDataSection: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector(getLoggedUser);

  if (!user) return null;

  const tag = user.tag;

  const { id_accounts, first_name, middle_name, last_name } = user;
  const nombre = getUserFullName(first_name, middle_name, last_name);
  const cookies = new Cookies();
  const [account, setAccount] = useState(cookies.get("id_account"));
  const options: Option[] = id_accounts.map(
    (a) =>
      ({
        value: a.toString(),
        label: a.toString(),
      } as Option)
  );
  const handleClickAccount = (value: string) => {
    cookies.set("id_account", value, { path: "/" });
    setAccount(value);
    refreshPage();
  };
  return (
    <div className={styles.userDataSectionContainer}>
      <div className={styles.userData}>
        <Avatar isLarge variant={ThemeVariants.Crypto} />
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {nombre}
        </Text>
        {tag ? (
          <NumbersBadge
            variant={ThemeVariants.Crypto}
            number={`${tag} 🥥`}
            onClick={() => navigate(CryptoRoutes.TAG)}
          />
        ) : (
          <SelectAccount
            options={options}
            value={account}
            onChangeAccount={handleClickAccount}
          />
        )}
      </div>
      <QuietButton
        variant={ThemeVariants.Crypto}
        onClick={() => navigate(mainRoutes.personalData)}
      >
        Ver datos personales
      </QuietButton>
    </div>
  );
};

export default UserDataSection;
