import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { InfoIcon } from "assets/icons/info";
import { closeModalInvestor } from "features/user/userSlice";
import AlertModal from "components/common/alert-modal";
import CheckboxUI from "components/common/controls/checkbox";
import mainRoutes from "router/routes";
import { useAppDispatch } from "hooks/redux";

import "./index.scss";

interface PropTypes {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  investorData: string;
  dateInvestorTest: string;
}

const ProfileExpiration = (props: PropTypes) => {
  const { openModal, setOpenModal, investorData, dateInvestorTest } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [notShowAgain, setNotShowAgain] = useState(false);

  const closeModal = async () => {
    notShowAgain && (await dispatch(closeModalInvestor(false)));
    setOpenModal(false);
    navigate(mainRoutes.profileTest);
  };

  const changePositiveNumber = (n: string) => {
    if (n.length === 3) {
      return n.slice(1, 3);
    }
  };

  useEffect(() => {
    investorData && dateInvestorTest;
  }, [investorData, dateInvestorTest]);

  return (
    <AlertModal
      alertIcon={<InfoIcon color="#0062e1" size={50} />}
      bodyContainer={
        <CheckboxUI
          checked={notShowAgain}
          label={"No volver a mostrar durante la sesion."}
          onChange={(newValue) => setNotShowAgain(newValue)}
        />
      }
      bodyText={
        <>
          <Typography className="subtitle" component="p" variant="textL">
            Tu perfil de inversor está vencido hace{" "}
            {changePositiveNumber(investorData)} días. Tu último test realizado
            fue el {dateInvestorTest}. No podrás continuar hasta que no lo
            completes nuevamente.
            <br />
          </Typography>
        </>
      }
      headingText={
        <>
          <Typography component="p" variant="heading4_bold">
            Perfil de inversor vencido
          </Typography>
        </>
      }
      open={openModal}
      primaryButtonText={"Ir al test"}
      onClickMainButton={closeModal}
    />
  );
};

export default ProfileExpiration;
