import React from "react";

import { Box } from "@mui/material";

import "./index.scss";

export enum BoxTypes {
  Secondary = "secondary",
  Primary = "primary",
  Terciary = "terciary",
  Clickable = "clickable",
  ClickableXL = "clickable-xl",
}

interface PropTypes {
  children:
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactNode
    | React.ReactNode[]
    | JSX.Element
    | JSX.Element[];
  type?: BoxTypes;
  onClick?: (x?: any) => void;
  className?: string;
}
export const UIBox = (props: PropTypes) => {
  const { children, type = BoxTypes.Primary, onClick, className } = props;

  const handleClickBox = () => (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box
      className={`ui-box ${type} ${className}`}
      {...(onClick ? { onClick: handleClickBox() } : {})}
    >
      {children}
    </Box>
  );
};
