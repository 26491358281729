import { CDN_URL } from "constants/index";

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { useNavigate } from "react-router-dom";
import {
  useCapitalSendAccounts,
  AccountsSteps,
} from "context/CapitalSendAccountsProvider";
import mainRoutes from "router/routes";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { Currencies } from "interfaces/wallet";
import { AccountsResponse } from "interfaces/api-responses";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import OutlinedButton, {
  OutlinedButtonVariants,
} from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";

import DetailsBody from "./DetailsBody";
import ConfirmDelete from "./ConfirmDelete";

import styles from "./styles.module.scss";

interface AccountDetailsSheetProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  currency: Currencies;
  bankAccount: AccountsResponse;
  isDesktop: boolean;
}

const AccountDetailsSheet: React.FC<AccountDetailsSheetProps> = ({
  isOpen,
  setIsOpen,
  bankAccount,
  currency,
  isDesktop,
}) => {
  const navigate = useNavigate();
  const { isIOS } = getPlatform();

  const { setAccountSelected, setStep, getBankLogo } = useCapitalSendAccounts();
  const { id_bank_account, logo } = bankAccount;

  const [bankLogoSrc, setBankLogoSrc] = useState<string>();
  const [displayConfirmDelete, setDisplayConfirmDelete] = useState<boolean>(
    false
  );

  useEffect(() => {
    setBankLogoSrc(getBankLogo(logo));
  });

  const onClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setDisplayConfirmDelete(false);
    }, 200);
  };

  const onClickSendMoney = () => {
    ampli.agendaAccountSendMoney();
    setStep(AccountsSteps.AMOUNT);
    setAccountSelected(bankAccount);
    return navigate(mainRoutes.send);
  };

  const onClickDelete = () => {
    ampli.agendaAccountDeleted();
    setDisplayConfirmDelete(true);
  };

  const handleImgError = (e: any) => {
    e.stopPropagation();
    setBankLogoSrc(`${CDN_URL}/logos/bancos/default.svg`);
  };

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={onClose}
      classes={classNames(styles.sheetWrapper, { [styles.desktop]: isDesktop })}
    >
      {displayConfirmDelete ? (
        <ConfirmDelete
          isIOS={isIOS}
          onClose={onClose}
          idBankAccount={id_bank_account}
        />
      ) : (
        <>
          <div className={styles.headerContainer}>
            <Text
              variant={TextVariants.RegularText}
              color="var(--slate900)"
              className={styles.title}
            >
              Cuenta agendada
            </Text>
            <div className={styles.iconsContainer}>
              <img
                src={bankLogoSrc}
                width={32}
                height={32}
                loading="lazy"
                onError={handleImgError}
              />
            </div>
          </div>
          <DetailsBody bankAccount={bankAccount} currency={currency} />
          <div
            className={classNames(styles.buttonsWrapper, {
              [styles.ios]: isIOS,
            })}
          >
            {!isDesktop && (
              <FilledButton onClick={onClickSendMoney}>
                Enviar dinero
              </FilledButton>
            )}
            <OutlinedButton
              onClick={onClickDelete}
              variant={OutlinedButtonVariants.Warning}
            >
              Eliminar cuenta
            </OutlinedButton>
          </div>
        </>
      )}
    </UIModalSlider>
  );
};

export default AccountDetailsSheet;
