import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { trackScreen } from "utils/amplitude";
import { screenNames } from "router/routes";
import { getCurrentScreen } from "features/global/globalSlice";
import { setCurrentScreen } from "features/global/globalSlice";
import { MfaVerifyParams } from "context/AuthProvider";
import MfaChallenge from "components/common/MfaChallenge";
import { MFAErrorType } from "interfaces/auth";
import API from "apis";
import { AccountsResponse } from "interfaces/api-responses";
import { transfersService } from "apis/services";
import { mfaErrorCheck } from "utils/mfa";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { NewAccountReferral } from "..";

interface VerifyStepProps {
  CBUOrAlias: string;
  onError: () => void;
  onClose: () => void;
  onDisplayToast: () => void;
  referral: NewAccountReferral;
}

const VerifyStep: React.FC<VerifyStepProps> = ({
  CBUOrAlias,
  onError,
  onClose,
  onDisplayToast,
  referral,
}) => {
  const currentScreen = useAppSelector(getCurrentScreen);
  const dispatch = useAppDispatch();
  const [mfaError, setMfaError] = useState<MFAErrorType>();

  useEffect(() => {
    trackScreen(screenNames.newBankAccount, currentScreen);
    dispatch(setCurrentScreen(screenNames.newBankAccount));
  }, []);

  const onClickAddAccount = async (
    mfaParams: MfaVerifyParams,
    callAfterVerify: () => void
  ) => {
    setMfaError(undefined);
    try {
      await API.post<AccountsResponse>(transfersService.accounts, {
        cbu_cvu: CBUOrAlias,
        ...mfaParams,
      });

      ampli.bankAccountAdded({ referral });
      onClose();
      onDisplayToast();
      return true;
    } catch (error) {
      const isMfaError = mfaErrorCheck(error);
      if (isMfaError) {
        ampli.agendaNewAccountAddedError({ type: isMfaError });
        setMfaError(mfaError);
      } else {
        ampli.agendaNewAccountAddedError({ type: "Add account error" });
        onError();
      }
      return false;
    } finally {
      callAfterVerify();
    }
  };

  return (
    <MfaChallenge
      onVerify={onClickAddAccount}
      onClickBack={() => onClose()}
      error={mfaError}
    />
  );
};

export default VerifyStep;
