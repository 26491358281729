import { Typography } from "@mui/material";

import styles from "./styles.module.scss";

interface FilterButtonV2Props {
  onClick: () => void;
  filtersCount: number;
}

const FilterButtonV2: React.FC<FilterButtonV2Props> = ({
  onClick,
  filtersCount,
}) => {
  return (
    <div id={styles.filterButton} onClick={onClick}>
      <Typography className={styles.filter}>
        Filtrar
        {filtersCount > 0 && (
          <div className={styles.filterNumber}>
            <span>{filtersCount}</span>
          </div>
        )}
      </Typography>
    </div>
  );
};

export default FilterButtonV2;
