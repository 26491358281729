import { useEffect, useState } from "react";

import API from "apis";
import { cardService } from "apis/services";
import FilledButton from "components/common/FilledButton";
import LoadingSpinner from "components/common/LoadingSpinner";
import { PaymentCurrencies, PaymentMethodErrors } from "interfaces/pay/enums";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import px2rem from "utils/px2rem";
import {
  PaymentMethod,
  PaymentMethodsResponse,
} from "interfaces/pay/interface";

import PayMethodConfirm from "./components/ConfirmBottomSheet";
import MethodHeader from "./components/MethodHeader";
import MethodsList from "./components/MethodsList";

import styles from "./styles.module.scss";

const PayMethod: React.FC = () => {
  const [availableMethods, setAvailableMethods] = useState<PaymentMethod[]>([]);
  const [paymentMethodsMessages, setPaymentMethodsMessages] = useState<
    PaymentMethodErrors[]
  >([]);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethod>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const hasDebt = paymentMethodsMessages.includes(PaymentMethodErrors.ARS_DEBT);

  const noBalance = !!paymentMethodsMessages.find(
    (value) =>
      value === PaymentMethodErrors.ARS_DEBT ||
      value === PaymentMethodErrors.INSUFFICIENT_FUNDS
  );

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const { data } = await API.get<PaymentMethodsResponse>(
          cardService.shippingPaymentMethod
        );

        setAvailableMethods(data.paymentMethods);
        setPaymentMethodsMessages(data.messages);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getPaymentMethods();
  }, []);

  const handleDeposit = () => {
    navigate(mainRoutes.receive);
  };

  const shippingAmount =
    availableMethods.find(
      (spm: PaymentMethod) => spm.name === PaymentCurrencies.ARS
    )?.amount || 0;

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner size={px2rem(120)} variant={ThemeVariants.Pay} />
      </div>
    );

  return (
    <div className={styles.payMethodContainer}>
      <div>
        <MethodHeader balance={!noBalance} quantity={shippingAmount} />
        <MethodsList
          availableMethods={availableMethods}
          setSelectedMethod={setSelectedMethod}
          hasDebt={hasDebt}
        />
      </div>
      <div>
        {noBalance && (
          <FilledButton
            className={styles.depositMoneyButton}
            variant={ThemeVariants.Pay}
            onClick={handleDeposit}
          >
            Ingresar dinero
          </FilledButton>
        )}
      </div>
      <PayMethodConfirm
        selectedMethod={selectedMethod}
        setSelectedMethod={setSelectedMethod}
        shippingAmount={shippingAmount}
      />
    </div>
  );
};

export default PayMethod;
