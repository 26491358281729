import { useEffect } from "react";

import IconBadge from "components/common/Badges/IconBadge";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import NavigationHeader from "components/common/NavigationHeader";
import Text, { TextVariants } from "components/common/Text";
import { useCreateAccount } from "context/CreateAccountProvider";
import { Mail } from "lucide-react";
import { onboardingScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackAction, trackScreen } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import styles from "../styles.module.scss";

const InfoRequiredScreen = () => {
  const dispatch = useAppDispatch();
  const { goToHome } = useCreateAccount();

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackScreen(
      onboardingScreenNames.checkEmail,
      previousScreen,
      undefined,
      Projects.ONBOARDING
    );

    dispatch(setCurrentScreen(onboardingScreenNames.checkEmail));
  }, []);

  const onClickBack = () => {
    trackAction(
      `${onboardingScreenNames.checkEmail} - Click Quit`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  const onClickGoToHome = () => {
    trackAction(
      `${onboardingScreenNames.checkEmail} -  Click Home`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  return (
    <div className={styles.finalContainer}>
      <NavigationHeader title="Revisá tu mail" onClick={onClickBack} />
      <div className={styles.content}>
        <IconBadge backgroundColor="var(--blue300)">
          <Mail color="var(--blue900)" />
        </IconBadge>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h2"
          color="var(--slate900)"
          className={styles.title}
        >
          Por favor, revisá tu mail
        </Heading>
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate800)"
          className={styles.text}
        >
          Necesitamos validar algunos datos más. Pronto vas a recibir novedades
          via email. Recordá revisar tu casilla de spam.
        </Text>
      </div>

      <div className={styles.buttonContainer}>
        <FilledButton onClick={onClickGoToHome}>Volver al inicio</FilledButton>
      </div>
    </div>
  );
};

export default InfoRequiredScreen;
