import { IconButton } from "@mui/material";
import { DownloadIcon } from "assets/icons/download";
import { getColor } from "utils/getColor";

const DownloadIconButton = ({ onClick }: { onClick?: () => void }) => (
  <IconButton onClick={onClick}>
    <DownloadIcon color={getColor("--darkblue-to-light")} />
  </IconButton>
);

export default DownloadIconButton;
