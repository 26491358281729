import { IconProps } from "../BTC";

const PixIcon: React.FC<IconProps> = ({ size = 16, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_7480_8668)">
      <path
        d="M10.4274 10.2463C10.191 10.2468 9.95695 10.2005 9.73859 10.1101C9.52023 10.0197 9.32193 9.88695 9.15513 9.71952L7.31763 7.88202C7.25258 7.82023 7.16629 7.78577 7.07657 7.78577C6.98685 7.78577 6.90055 7.82023 6.83551 7.88202L4.99101 9.72652C4.82427 9.89403 4.62598 10.0268 4.40761 10.1172C4.18923 10.2077 3.95511 10.2539 3.71876 10.2533H3.35651L5.68401 12.5808C6.41026 13.307 7.58888 13.307 8.31513 12.5808L10.6488 10.2463H10.4274ZM3.71876 3.74677C4.20001 3.74677 4.65151 3.93402 4.99101 4.27352L6.83551 6.11802C6.8672 6.14977 6.90485 6.17496 6.94629 6.19214C6.98772 6.20933 7.03215 6.21817 7.07701 6.21817C7.12187 6.21817 7.16629 6.20933 7.20773 6.19214C7.24917 6.17496 7.28681 6.14977 7.31851 6.11802L9.15601 4.28052C9.32264 4.11312 9.52079 3.98038 9.739 3.88997C9.95722 3.79956 10.1912 3.75327 10.4274 3.75377H10.6488L8.31513 1.42015C7.96614 1.0714 7.49295 0.875488 6.99957 0.875488C6.50619 0.875488 6.033 1.0714 5.68401 1.42015L3.35651 3.74765L3.71876 3.74677Z"
        fill="#32BCAD"
      />
      <path
        d="M12.5799 5.68396L11.1694 4.27346C11.1377 4.28646 11.1039 4.29329 11.0696 4.29358H10.4283C10.0966 4.29358 9.77201 4.42833 9.53838 4.66283L7.70088 6.50033C7.61916 6.58248 7.52202 6.64767 7.41503 6.69215C7.30804 6.73663 7.19331 6.75953 7.07744 6.75953C6.96157 6.75953 6.84685 6.73663 6.73986 6.69215C6.63287 6.64767 6.53572 6.58248 6.454 6.50033L4.6095 4.65496C4.37286 4.41929 4.05273 4.28658 3.71876 4.28571H2.93125C2.89861 4.28545 2.86629 4.27922 2.83588 4.26733L1.42013 5.68396C0.69388 6.41021 0.69388 7.58883 1.42013 8.31596L2.83588 9.73171C2.86595 9.71961 2.89797 9.71308 2.93038 9.71246H3.71876C4.05125 9.71246 4.37501 9.57858 4.6095 9.34408L6.45313 7.49871C6.62135 7.33854 6.84473 7.24921 7.077 7.24921C7.30928 7.24921 7.53266 7.33854 7.70088 7.49871L9.53838 9.33621C9.77201 9.57071 10.0966 9.70458 10.4283 9.70458H11.0696C11.1046 9.70458 11.1388 9.71333 11.1694 9.72558L12.5799 8.31508C13.3061 7.58883 13.3061 6.41021 12.5799 5.68396Z"
        fill="#32BCAD"
      />
    </g>
    <defs>
      <clipPath id="clip0_7480_8668">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PixIcon;
