import { useCocosCard } from "context/CocosCardProvider";
import { CardTypesLabel } from "interfaces/card/enums";
import Heading, { HeadingVariants } from "components/common/Heading";

import styles from "./styles.module.scss";

const EmptyCardTitle: React.FC = () => {
  const { selectedCard } = useCocosCard();
  const isPhysicalCard = selectedCard === CardTypesLabel.PHYSICAL;
  const getCardLabel = isPhysicalCard ? "física" : "virtual";

  return (
    <Heading
      variant={HeadingVariants.RegularTitle}
      component="h1"
      className={styles.emptyCardTitle}
    >
      {`¿Todavía no pediste tu tarjeta ${getCardLabel}? ¡Es gratis!`}
    </Heading>
  );
};

export default EmptyCardTitle;
