import { useEffect, useState } from "react";

import { ampli } from "ampli";
import API from "apis";
import { BannersService } from "apis/services";
import ActionsSection from "components/common/ActionsSection";
import ActivitySection from "components/common/ActivitySection";
import CableSheet from "components/common/CableSheet";
import CocosTagBottomSheet from "components/common/CocosTagBottomSheet";
import CocosTagSign from "components/common/CocosTagSign";
import CryptoCableBanner from "components/common/CryptoCableBanner";
import HomeCrypto from "components/page-home/mobile/Crypto";
import {
  acceptCryptoTyc,
  getCurrentScreen,
  setCurrentScreen,
} from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Banner } from "interfaces/api-responses";
import CryptoPage from "layouts/CryptoPageLayout";
import { cryptoScreenNames } from "router/routes";
import { checkShowBanner } from "utils";

import HomeHeader from "./HomeHeader";
import MarketSection from "./MarketSection";
import PortfolioSection from "./PortfolioSection";

import styles from "./styles.module.scss";

const CryptoHomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const previousScreen = useAppSelector(getCurrentScreen);

  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCryptoCableBanner, setShowCryptoCableBanner] = useState<boolean>(
    false
  );
  const [showCocosTagBanner, setShowCocosTagBanner] = useState<boolean>(false);
  const [showCableOb, setShowCableOb] = useState<boolean>(false);

  const test = true;

  useEffect(() => {
    const getCryptoCableBannerData = async () => {
      try {
        const { data } = await API.get<Banner>(
          `${BannersService.banners}/crypto_cable_welcome`
        );
        const { seen } = data;
        setShowCryptoCableBanner(!seen);
      } catch (error) {
        setShowCryptoCableBanner(false);
      } finally {
        setIsLoading(false);
      }
    };

    getCryptoCableBannerData();
    dispatch(acceptCryptoTyc());
  }, []);

  useEffect(() => {
    const getCocosTagBannerData = async () => {
      try {
        const { data } = await API.get<Banner>(
          `${BannersService.banners}/cocos_pay_welcome`
        );
        const { seen, supressUntil } = data;
        setShowCocosTagBanner(checkShowBanner(seen, supressUntil));
      } catch (error) {
        setShowCocosTagBanner(false);
      } finally {
        setIsLoading(false);
      }
    };

    getCocosTagBannerData();
    ampli.cryptoScreenHome({ previous_screen: previousScreen });
    dispatch(setCurrentScreen(cryptoScreenNames.home));
  }, []);

  const onClickCryptoCableBanner = () => {
    setShowCableOb(true);
  };

  const displayCocosTagBanner = !showCryptoCableBanner && showCocosTagBanner;

  if (test) return <HomeCrypto />;

  return (
    <CryptoPage loading={isLoading}>
      <div className={styles.homePageContainer}>
        <HomeHeader />
        <PortfolioSection />
        <ActionsSection />
        {showCryptoCableBanner && (
          <CryptoCableBanner onClick={() => onClickCryptoCableBanner()} />
        )}
        {displayCocosTagBanner && (
          <CocosTagSign animated withHide onClick={() => setOpen(true)} />
        )}
        <MarketSection />
        <ActivitySection />
        <CocosTagBottomSheet open={open} onClose={() => setOpen(false)} />
      </div>
      <CableSheet isOpen={showCableOb} setIsOpen={setShowCableOb} />
    </CryptoPage>
  );
};

export default CryptoHomePage;
