import styles from "./styles.module.scss";

const EllipseTwo: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="196"
    height="195"
    viewBox="0 0 196 195"
    fill="none"
    className={styles.ellipseTwo}
  >
    <g filter="url(#filter0_f_6166_13619)">
      <ellipse
        cx="98"
        cy="97.6543"
        rx="80"
        ry="80.0021"
        fill="var(--slate300)"
      />
      <path
        d="M177.643 97.6543C177.643 141.641 141.986 177.299 98 177.299C54.0145 177.299 18.3571 141.641 18.3571 97.6543C18.3571 53.6676 54.0145 18.0093 98 18.0093C141.986 18.0093 177.643 53.6676 177.643 97.6543Z"
        stroke="#363A3F"
        strokeWidth="0.714286"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_6166_13619"
        x="0.857143"
        y="0.509304"
        width="194.286"
        height="194.29"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="8.57143"
          result="effect1_foregroundBlur_6166_13619"
        />
      </filter>
    </defs>
  </svg>
);

export default EllipseTwo;
