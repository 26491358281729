import { useState } from "react";

import { Input, Typography } from "@mui/material";
import dayjs from "dayjs";

import styles from "./styles.module.scss";

interface UIDateRangeProps {
  firstDate: string;
  secondDate: string;
  onChange: (firstDate: string, secondDate: string) => void;
}

const UIDateRange: React.FC<UIDateRangeProps> = ({
  firstDate,
  secondDate,
  onChange,
}) => {
  const [startDate, setStartDate] = useState(firstDate);
  const [endDate, setEndDate] = useState(secondDate);

  const handleOnChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setStartDate(value);
    onChange(value, endDate);
  };

  const handleOnChangeSecond = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEndDate(value);
    onChange(startDate, value);
  };

  return (
    <div id={styles.dateRangeContainer}>
      <div className={styles.label}>
        <Typography>Fecha</Typography>
      </div>
      <div className={styles.inputsDiv}>
        <Input
          value={startDate}
          type="date"
          onChange={handleOnChangeFirst}
          inputProps={{ max: dayjs().format("DD-MM-YYYY") }}
        />
        -
        <Input
          value={endDate}
          type="date"
          onChange={handleOnChangeSecond}
          inputProps={{ max: dayjs().format("DD-MM-YYYY") }}
        />
      </div>
    </div>
  );
};

export default UIDateRange;
