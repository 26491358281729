import React from "react";

import { useCreateAccount } from "context/CreateAccountProvider";
import { Steps } from "interfaces/createAccount";

import Intro from "./components/Steps/Intro";
import DNIFront from "./components/Steps/DNIFront";
import DNIBack from "./components/Steps/DNIBack";
import Selfie from "./components/Steps/Selfie";
import ManualInput from "./components/Steps/ManualInput";
import PersonalInfo from "./components/Steps/PersonalInfo";
import Final from "./components/Steps/Final";
import LoadingScreen from "./components/Loading";

const stepsComponents = {
  [Steps.INTRO]: <Intro />,
  [Steps.DNI_FRONT]: <DNIFront />,
  [Steps.DNI_BACK]: <DNIBack />,
  [Steps.MANUAL_INPUT]: <ManualInput />,
  [Steps.SELFIE]: <Selfie />,
  [Steps.PERSONAL_INFO]: <PersonalInfo />,
  [Steps.FINAL]: <Final />,
};

const CreateNewAccountPage: React.FC = () => {
  const { step, isInitialStateLoading } = useCreateAccount();

  if (isInitialStateLoading) return <LoadingScreen displayText={false} />;

  return stepsComponents[step];
};

export default CreateNewAccountPage;
