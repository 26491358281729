import { CapitalTermsAndConditionsURL } from "constants/index";

import { useEffect, useState } from "react";

import IconBadge from "components/common/Badges/IconBadge";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import NavigationHeader from "components/common/NavigationHeader";
import Text, { TextVariants } from "components/common/Text";
import InputCheckbox from "components/common/input/InputCheckbox";
import { useCreateAccount } from "context/CreateAccountProvider";
import { ShieldCheck } from "lucide-react";
import { onboardingScreenNames, screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackAction, trackScreen } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

const Intro = () => {
  const dispatch = useAppDispatch();
  const { goToHome, goToNextStep } = useCreateAccount();
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackScreen(
      onboardingScreenNames.tyc,
      previousScreen,
      undefined,
      Projects.ONBOARDING
    );

    dispatch(setCurrentScreen(screenNames.home));
  }, []);

  const onClickVerifyIdentity = () => {
    trackAction(
      `${onboardingScreenNames.tyc} - Click Verify Identity`,
      undefined,
      Projects.ONBOARDING
    );

    goToNextStep();
  };

  const onClickBack = () => {
    trackAction(
      `${onboardingScreenNames.tyc} - Click Quit`,
      undefined,
      Projects.ONBOARDING
    );

    goToHome();
  };

  return (
    <div className={styles.introContainer}>
      <NavigationHeader title="Verificación" onClick={onClickBack} />
      <div className={styles.content}>
        <IconBadge backgroundColor="var(--blue300)">
          <ShieldCheck color="var(--blue900)" />
        </IconBadge>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h2"
          color="var(--slate900)"
          className={styles.title}
        >
          Para mantener tu cuenta segura y poder operar, requerimos verificar tu
          identidad en Cocos Capital.
        </Heading>
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate800)"
          className={styles.text}
        >
          Vamos a pedirte algunos datos y solo te llevará unos minutos. Tené a
          mano tu DNI.
        </Text>
      </div>
      <InputCheckbox
        name="terms"
        checked={termsAccepted}
        onChange={() => setTermsAccepted(!termsAccepted)}
      >
        Acepto los{" "}
        <a
          href={CapitalTermsAndConditionsURL}
          rel="noreferrer"
          target="_blank"
          className={styles.terms}
        >
          Términos y Condiciones
        </a>
        .
      </InputCheckbox>
      <div className={styles.buttonContainer}>
        <FilledButton disabled={!termsAccepted} onClick={onClickVerifyIdentity}>
          Verificar identidad
        </FilledButton>
      </div>
    </div>
  );
};

export default Intro;
