import { useEffect } from "react";

const useGoogleFont = () => {
  useEffect(() => {
    const loadGoogleFont = async () => {
      try {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.crossOrigin = "anonymous";
        const fontUrl =
          "https://fonts.googleapis.com/css2?family=Google+Sans:ital,wght@0,400;0,600;0,800;1,400;1,600;1,800&display=swap";
        link.href = fontUrl;

        document.head.appendChild(link);
      } catch (err) {
        console.error("Error in useGoogleFont:", err);
      }
    };

    loadGoogleFont();
  }, []);
};

export default useGoogleFont;
