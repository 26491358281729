import { useEffect } from "react";

import {
  Backdrop,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import NoCtte from "components/common/NoCtte";
import ButtonUI, { ButtonSizes } from "components/common/button";
import { ButtonVariants } from "components/common/button/index";
import { Currency } from "components/common/currency";
import CurrentTariff from "components/common/current-tariff";
import {
  AmountInputSizes,
  UIAmountInput,
} from "components/common/input/amount";
import { UIPriceInput } from "components/common/input/price";
import {
  Option,
  SelectSizes,
  SelectTypes,
  UISelect,
} from "components/common/select";
import { BidOfferTable } from "components/common/table-bid-offer";
import { InstrumentData, MarketData } from "interfaces/api-responses";
import DrawerLayout from "layouts/drawer";
import { isEmpty } from "lodash";
import { NumberFormatValues } from "react-number-format";
import { useAppState } from "context/AppStateProvider";
import mainRoutes, { screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import { getLoggedUser } from "store/selectors/user.selector";
import { getCurrencyLabel } from "utils";
import { trackScreen } from "utils/amplitude";
import { caucionMin } from "utils/constants";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { ConfirmCaucionOrder } from "./confirm-sidebar";

import "./index.scss";

interface PropTypes {
  available?: number | null;
  caucion?: InstrumentData;
  dateLabel: string;
  onChangeTerm: (mode: "add" | "substract") => (event: any) => void;
  getCurrencyOptions: () => Option[];
  getDisabledButton: () => boolean;
  onChangeCurrency: (currency?: string) => void;
  onChangeMethod: (method?: string) => void;
  onChangeRateValue: ({ value }: NumberFormatValues) => void;
  onChangeAmountValue: ({ floatValue }: NumberFormatValues) => void;
  markets: MarketData;
}

export const CaucionDesktop = (props: PropTypes) => {
  const {
    available,
    caucion,
    dateLabel,
    getCurrencyOptions,
    getDisabledButton,
    onChangeAmountValue,
    onChangeCurrency,
    onChangeMethod,
    onChangeRateValue,
    onChangeTerm,
    markets,
  } = props;
  const { openRightModalContent } = useAppState();
  const dispatch = useAppDispatch();
  const caucionOrder = useAppSelector(
    (state: RootState) => state.markets.caucionOrder
  );
  const user = useAppSelector(getLoggedUser);
  const currentScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackScreen(screenNames.caucion, currentScreen);
    dispatch(setCurrentScreen(screenNames.caucion));
  }, []);

  const handleCloseSidebar = () => {
    dispatch(closeRightModal());
  };

  const ConfirmOrderComponent = () => (
    <DrawerLayout>
      <ConfirmCaucionOrder onCloseSidebar={handleCloseSidebar} />
    </DrawerLayout>
  );

  const handleClickContinue = () => {
    if (
      caucionOrder.amount &&
      caucionOrder.rate &&
      caucionOrder.amount >= caucionMin
    ) {
      openRightModalContent(ConfirmOrderComponent());
    }
  };

  const isOverAvailable =
    caucionOrder.amount && available && caucionOrder.amount > available;

  const earnings =
    caucionOrder.amount && caucionOrder.rate
      ? caucionOrder.amount *
        (caucionOrder?.rate / 100 - 0.05) *
        (caucionOrder.term / 365)
      : 0;

  const total =
    caucionOrder.amount && earnings && earnings + caucionOrder.amount;

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  return (
    <div className="pages-caucion-desktop">
      <Backdrop open={isEmpty(markets.items) || !caucion}>
        <CircularProgress />
      </Backdrop>
      {caucion && (
        <div className="caucion-content cols-wrapper">
          <div className="col tables">
            <BidOfferTable
              displayPercentage
              rows={caucion && caucion.asks ? caucion.asks : []}
              type="ask"
            />
            <div className="white-background">
              <Typography variant="textXS">Último Operado</Typography>
              <div className="last-operation">
                <div className={"last-highlight"}>
                  <Typography variant="textS_bold">
                    <Currency>{caucion.last}</Currency>&nbsp;%
                  </Typography>
                </div>
              </div>
            </div>
            <BidOfferTable
              displayPercentage
              rows={caucion && caucion.bids ? caucion.bids : []}
              type="bid"
            />
          </div>
          <div className="col caucion-inputs">
            <div className="selects-wrapper">
              <span className="side-text">
                <Typography variant="textM_bold">Colocador</Typography>
              </span>
              <UISelect
                cantBeEmpty
                hideHelperText
                disabled
                options={[
                  { value: "mercado", label: "Tasa de Mercado" },
                  { value: "limite", label: "Tasa Límite" },
                ]}
                size={SelectSizes.Small}
                type={SelectTypes.Secondary}
                value={caucionOrder.method}
                onChange={onChangeMethod}
                downArrowSize={14}
              />
              <UISelect
                cantBeEmpty
                disabled
                hideHelperText
                options={getCurrencyOptions()}
                size={SelectSizes.Small}
                type={SelectTypes.Secondary}
                value={caucionOrder.currency}
                onChange={onChangeCurrency}
                downArrowSize={14}
              />
            </div>
            <NoCtte />
            {user.hasAccount && (
              <>
                <UIPriceInput
                  disabled={caucionOrder.method === "mercado"}
                  prefix={false}
                  endAdorment={caucionOrder.rate ? "%" : undefined}
                  placeholder="Ingresa tasa de interés"
                  value={
                    caucionOrder.rate ? caucionOrder.rate.toString() : undefined
                  }
                  onChangeValue={onChangeRateValue}
                />
                <div className="amount-input">
                  <Typography variant="textS">Monto a invertir</Typography>
                  <UIAmountInput
                    disableDecimals
                    currencyValue={caucionOrder.currency}
                    size={AmountInputSizes.Small}
                    value={
                      caucionOrder.amount ? caucionOrder.amount.toString() : ""
                    }
                    onChangeValue={onChangeAmountValue}
                  />
                  <Typography
                    className="amount-subtitle"
                    component="p"
                    variant="textM"
                  >
                    {"Mínimo a invertir: AR$ "}
                    <Currency withoutDecimals>10000</Currency>
                  </Typography>
                  {!isOverAvailable ? (
                    <Typography variant="textXS">
                      {`Saldo disponible: ${getCurrencyLabel(
                        caucionOrder.currency
                      )} `}
                      <Currency>{available}</Currency>
                    </Typography>
                  ) : (
                    <Typography
                      className="not-enough"
                      component="p"
                      variant="textXS"
                    >
                      No tienes saldo suficiente
                    </Typography>
                  )}
                  <CurrentTariff newClass="tariff" />
                </div>
                <div className="days-input">
                  <Typography variant="textS"> Plazo (Días) | Fecha</Typography>
                  <div className="day-input-content">
                    <IconButton
                      disabled={caucionOrder.term === 1}
                      onClick={onChangeTerm("substract")}
                    >
                      -
                    </IconButton>
                    <Typography variant="textS_bold">
                      {caucionOrder.term}
                    </Typography>{" "}
                    |<Typography variant="captionS">{dateLabel}</Typography>
                    <IconButton
                      disabled={caucionOrder.term === 120}
                      onClick={onChangeTerm("add")}
                    >
                      +
                    </IconButton>
                  </div>
                </div>
                <ButtonUI
                  disabled={getDisabledButton()}
                  size={ButtonSizes.Small}
                  variant={ButtonVariants.Primary}
                  onClick={handleClickContinue}
                >
                  Continuar
                </ButtonUI>
              </>
            )}
          </div>
          <div className="col caucion-preview">
            <Typography className="title" variant="captionM">
              Inviertes
            </Typography>
            <Typography className="value" variant="textL_bold">
              {`${getCurrencyLabel(caucionOrder.currency)} `}
              <Currency>{caucionOrder.amount || "0"}</Currency>
            </Typography>
            <Typography className="title" variant="captionM">
              Plazo
            </Typography>
            <Typography className="value" variant="textL_bold">
              {caucionOrder.term} {caucionOrder.term === 1 ? "día" : "días"}
            </Typography>
            <Typography className="title" variant="captionM">
              Tasa de Interés
            </Typography>
            <Typography className="value" variant="textL_bold">
              {caucionOrder.method === "limite" ? "Límite" : "Mercado"}
            </Typography>
            <Typography className="title" variant="captionM">
              Recibes | Ganancia Neta (Estimado)
            </Typography>
            <div className="recive">
              <Typography variant="textL_bold">
                {`${getCurrencyLabel(caucionOrder.currency)} `}
                {total ? <Currency>{total}</Currency> : "---"}
                &nbsp;|&nbsp;
              </Typography>
              <Typography className="value" variant="textL_bold">
                {`${getCurrencyLabel(caucionOrder.currency)} `}
                {earnings ? <Currency>{earnings}</Currency> : "---"}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
