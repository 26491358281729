import { Typography } from "@mui/material";

import styles from "./styles.module.scss";

export const OrdersEmptyState = () => {
  return (
    <div className={styles.emptyStateContainer}>
      <Typography variant="textS_bold">No hay órdenes</Typography>
    </div>
  );
};
