import { useAppSelector } from "hooks/redux";
import { Divider, Typography } from "@mui/material";
import { useMovements } from "context/MovementsProvider";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import UIDropdownCheckbox from "components/common/dropdown-checkbox";
import UISelectDate from "components/common/select-date";
import { getCurrentScreen } from "features/global/globalSlice";
import { trackAction } from "utils/amplitude";

import styles from "./styles.module.scss";

interface FilterSectionProps {
  handleChangeSection: () => void;
  onClickFilter: () => void;
  onClickCancel: () => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  handleChangeSection,
  onClickCancel,
  onClickFilter,
}) => {
  const currentScreen = useAppSelector(getCurrentScreen);

  const {
    conceptFilters,
    handleUpdateFilters,
    handleClearFilters,
  } = useMovements();

  const onClickClearFilters = () => {
    handleClearFilters();
    trackAction(`${currentScreen} - Click clear filters`);
  };

  return (
    <div className={styles.filterBody}>
      <div>
        <div className={styles.filterTopRow}>
          <Typography>Selecciona los filtros</Typography>
          <Typography onClick={onClickClearFilters}>Borrar Filtros</Typography>
        </div>
        <UIDropdownCheckbox
          handleClickCheck={handleUpdateFilters}
          options={conceptFilters}
          title="Concepto"
        />
        <Divider style={{ marginBottom: "16px" }} />
        <UISelectDate onClickPeriod={handleChangeSection} />
      </div>
      <div>
        <ButtonUI
          size={ButtonSizes.Full}
          variant={ButtonVariants.Primary}
          onClick={onClickFilter}
          newClass={styles.primaryButton}
        >
          Filtrar
        </ButtonUI>
        <ButtonUI
          size={ButtonSizes.Full}
          variant={ButtonVariants.Secondary}
          onClick={onClickCancel}
        >
          Cancelar
        </ButtonUI>
      </div>
    </div>
  );
};

export default FilterSection;
