import Heading, { HeadingVariants } from "components/common/Heading";

import PixBanner from "../../HightlightedsSection/PixBanner";

import styles from "./styles.module.scss";

const FeaturesSection: React.FC = () => {
  return (
    <div className={styles.highlightedsWrapper}>
      <Heading
        component="h2"
        color="var(--slate800)"
        className={styles.header}
        variant={HeadingVariants.RegularSubtitle}
      >
        Destacados
      </Heading>
      <div className={styles.highlighteds}>
        <PixBanner />
      </div>
    </div>
  );
};

export default FeaturesSection;
