import { useEffect } from "react";

import { UIModalSlider } from "components/common/modal-slider";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { openRightModal } from "features/rightModal/rightModalSlice";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

import Selector from "./Selector";

import styles from "./styles.module.scss";

interface MEPSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  trackingReferral?: string;
}

const MEPSelector: React.FC<MEPSelectorProps> = ({
  onClose,
  isOpen,
  trackingReferral,
}) => {
  const { isIOS } = getPlatform();

  const { openRightModalContent } = useAppState();
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();
  const { open: rightModalOpen } = useAppSelector((state) => state.rightModal);

  useEffect(() => {
    if (!isMobile && isOpen) {
      dispatch(
        openRightModal({
          onClose,
        })
      );
      openRightModalContent(
        <div className={styles.desktopWrapper}>
          <Selector />
        </div>
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (!rightModalOpen) {
      onClose();
    }
  }, [rightModalOpen]);

  if (!isMobile) return null;

  return (
    <UIModalSlider
      open={!!isOpen}
      onToggleDisplay={onClose}
      classes={classNames(styles.bottomSheet, { [styles.ios]: isIOS })}
    >
      <Selector trackingReferral={trackingReferral} />
    </UIModalSlider>
  );
};

export default MEPSelector;
