import EmptyState from "components/common/EmptyState";
import { BookDashed } from "lucide-react";

import styles from "./styles.module.scss";

const AccountsEmptyState: React.FC = () => (
  <div className={styles.emptyStateWrapper}>
    <EmptyState
      title="Tu agenda está vacía"
      subtitle="Hacé click en “Agendar una cuenta” para agregar un contacto nuevo"
      icon={BookDashed}
    />
  </div>
);

export default AccountsEmptyState;
