import { IconProps } from "assets/icons/BTC";
import classNames from "classnames";

import styles from "./styles.module.scss";

const CoinsReceive: React.FC<IconProps> = ({ size, className, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 108 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(className, styles.svg)}
    {...props}
  >
    <g clipPath="url(#clip0_14549_16202)">
      <path
        d="M99.9252 0H63.622C60.7295 0 58.3848 2.34478 58.3848 5.23721V74.7628C58.3848 77.6552 60.7295 80 63.622 80H99.9252C102.818 80 105.162 77.6552 105.162 74.7628V5.23721C105.162 2.34478 102.818 0 99.9252 0Z"
        fill="#0588F0"
      />
      <path
        d="M105.162 8.7793H58.3848V70.9654H105.162V8.7793Z"
        className={styles.line}
      />
      <path
        d="M105.162 16.6709C105.417 16.6709 105.67 16.7214 105.905 16.8195C106.141 16.9176 106.355 17.0614 106.535 17.2425C106.714 17.4237 106.856 17.6386 106.953 17.8749C107.049 18.1112 107.097 18.3643 107.095 18.6194V26.6647C107.095 27.1774 106.892 27.6692 106.529 28.0318C106.167 28.3943 105.675 28.598 105.162 28.598V16.6709Z"
        fill="#0588F0"
      />
      <path
        d="M76.9385 4.81494H87.2159"
        stroke="#1C2024"
        strokeWidth="0.75343"
        strokeMiterlimit="10"
      />
      <path
        d="M81.7733 77.7746C83.0288 77.7746 84.0466 76.7568 84.0466 75.5013C84.0466 74.2458 83.0288 73.228 81.7733 73.228C80.5178 73.228 79.5 74.2458 79.5 75.5013C79.5 76.7568 80.5178 77.7746 81.7733 77.7746Z"
        className={styles.line}
      />
      <mask
        id="mask0_14549_16202"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="18"
        width="83"
        height="45"
      >
        <path
          d="M0 18.0108L0 62.2661L82.0135 62.2661V18.0108L0 18.0108Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_14549_16202)">
        <path
          d="M26.3421 60.5733C37.7061 60.5733 46.9185 51.3609 46.9185 39.9969C46.9185 28.6328 37.7061 19.4204 26.3421 19.4204C14.978 19.4204 5.76562 28.6328 5.76562 39.9969C5.76562 51.3609 14.978 60.5733 26.3421 60.5733Z"
          fill="#FFC419"
        />
        <path
          d="M26.3468 55.4768C34.8997 55.4768 41.8333 48.5433 41.8333 39.9904C41.8333 31.4374 34.8997 24.5039 26.3468 24.5039C17.7939 24.5039 10.8604 31.4374 10.8604 39.9904C10.8604 48.5433 17.7939 55.4768 26.3468 55.4768Z"
          fill="url(#paint0_linear_14549_16202)"
        />
        <path
          d="M26.3418 55.4765C30.4473 55.4765 34.3847 53.8456 37.2878 50.9425C40.1908 48.0395 41.8218 44.1021 41.8218 39.9966C41.8218 35.891 40.1908 31.9536 37.2878 29.0506C34.3847 26.1475 30.4473 24.5166 26.3418 24.5166"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M15.9437 28.5283C14.0306 30.2644 12.5824 32.4515 11.7308 34.8904C10.8792 37.3294 10.6513 39.9426 11.0679 42.4921C11.4846 45.0416 12.5325 47.4464 14.1162 49.4874C15.6998 51.5283 17.769 53.1406 20.1352 54.1774"
          stroke="white"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M26.377 32.0835L34.3811 40.0876L26.377 48.0896"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M34.3804 40.0874H17.1641"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7724 60.5731C82.14 60.5731 91.3553 51.3578 91.3553 39.9902C91.3553 28.6225 82.14 19.4072 70.7724 19.4072C59.4047 19.4072 50.1895 28.6225 50.1895 39.9902C50.1895 51.3578 59.4047 60.5731 70.7724 60.5731Z"
          fill="#FFC419"
        />
        <path
          d="M70.7726 55.4768C79.3255 55.4768 86.259 48.5433 86.259 39.9904C86.259 31.4374 79.3255 24.5039 70.7726 24.5039C62.2197 24.5039 55.2861 31.4374 55.2861 39.9904C55.2861 48.5433 62.2197 55.4768 70.7726 55.4768Z"
          fill="url(#paint1_linear_14549_16202)"
        />
        <path
          d="M70.7725 55.4767C72.843 55.5367 74.9046 55.1806 76.8352 54.4297C78.7657 53.6787 80.526 52.5481 82.0118 51.1047C83.4976 49.6613 84.6787 47.9345 85.4853 46.0266C86.2918 44.1186 86.7074 42.0682 86.7074 39.9967C86.7074 37.9253 86.2918 35.8748 85.4853 33.9669C84.6787 32.0589 83.4976 30.3321 82.0118 28.8887C80.526 27.4453 78.7657 26.3147 76.8352 25.5637C74.9046 24.8128 72.843 24.4568 70.7725 24.5167"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M60.3724 28.5176C58.4588 30.2537 57.0097 32.4409 56.1573 34.8801C55.3049 37.3192 55.0761 39.9329 55.4919 42.4831C55.9076 45.0332 56.9546 47.4389 58.5376 49.481C60.1206 51.5232 62.1893 53.1369 64.5553 54.1753"
          stroke="white"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M66.25 44.2117C66.25 46.5499 68.2721 48.4465 70.7662 48.4465C73.2604 48.4465 75.2847 46.5499 75.2847 44.2117C75.2847 39.4075 67.0446 39.6218 67.0446 35.387C67.0446 33.458 68.7116 31.897 70.7662 31.897C72.8209 31.897 74.4901 33.458 74.4901 35.387"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7725 50.878V29.4658"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M82.0977 60.5753V19.4072"
        stroke="white"
        strokeWidth="0.751265"
        strokeMiterlimit="10"
      />
      <path
        d="M9.21046 77.6379C9.21046 75.8768 8.51087 74.1879 7.2656 72.9426C6.02034 71.6974 4.33139 70.9978 2.57031 70.9978C4.33159 70.9972 6.02053 70.2971 7.26574 69.0515C8.51094 67.8059 9.21046 66.1167 9.21046 64.3555C9.21103 66.1169 9.91103 67.8061 11.1566 69.0517C12.4021 70.2972 14.0913 70.9972 15.8528 70.9978C14.0915 70.9978 12.4023 71.6973 11.1567 72.9425C9.9111 74.1877 9.21103 75.8767 9.21046 77.6379Z"
        fill="#0588F0"
        stroke="#0588F0"
        strokeWidth="0.850856"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M45.9157 16.8978C45.9151 15.6445 45.417 14.4427 44.5308 13.5565C43.6446 12.6703 42.4428 12.1721 41.1895 12.1716C42.4429 12.1716 43.6451 11.6736 44.5314 10.7873C45.4178 9.90094 45.9157 8.69879 45.9157 7.44531C45.9157 8.69879 46.4137 9.90094 47.3 10.7873C48.1863 11.6736 49.3885 12.1716 50.642 12.1716C49.3885 12.1716 48.1863 12.6695 47.3 13.5559C46.4137 14.4422 45.9157 15.6443 45.9157 16.8978Z"
        fill="#70B8FF"
        stroke="#70B8FF"
        strokeWidth="0.850856"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.3526 6.98211C17.3526 6.17478 17.0319 5.40052 16.461 4.82965C15.8902 4.25878 15.1159 3.93807 14.3086 3.93807C15.1157 3.9375 15.8897 3.61661 16.4604 3.04586C17.0312 2.47512 17.3521 1.70119 17.3526 0.894043C17.3526 1.70137 17.6733 2.47563 18.2442 3.0465C18.8151 3.61736 19.5893 3.93807 20.3967 3.93807C19.9968 3.93779 19.6009 4.01633 19.2314 4.16921C18.862 4.32208 18.5263 4.54629 18.2436 4.82902C17.9608 5.11174 17.7366 5.44742 17.5838 5.81687C17.4309 6.18632 17.3523 6.58228 17.3526 6.98211Z"
        fill="#D19DFF"
        stroke="#D19DFF"
        strokeWidth="0.850856"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_14549_16202"
        x1="41.2682"
        y1="39.9904"
        x2="10.2974"
        y2="39.9904"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#FFC419" />
        <stop offset="0.22" stopColor="#FFC724" stopOpacity="0.97" />
        <stop offset="0.38" stopColor="#FFCF42" stopOpacity="0.89" />
        <stop offset="0.58" stopColor="#FFDB74" stopOpacity="0.76" />
        <stop offset="0.8" stopColor="#FFEDB9" stopOpacity="0.58" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_14549_16202"
        x1="85.6961"
        y1="39.9904"
        x2="54.7254"
        y2="39.9904"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#FFC419" />
        <stop offset="0.22" stopColor="#FFC724" stopOpacity="0.97" />
        <stop offset="0.38" stopColor="#FFCF42" stopOpacity="0.89" />
        <stop offset="0.58" stopColor="#FFDB74" stopOpacity="0.76" />
        <stop offset="0.8" stopColor="#FFEDB9" stopOpacity="0.58" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <clipPath id="clip0_14549_16202">
        <rect width="107.095" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CoinsReceive;
