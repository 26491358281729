import CircularButton from "components/common/CircularButton";
import { DollarSign, QrCode, Send } from "lucide-react";
import px2rem from "utils/px2rem";
import { Capacitor } from "@capacitor/core";

import styles from "./styles.module.scss";

interface ActionsSectionsProps {
  onClickMep: () => void;
  onClickPay: () => void;
  onClickSend: () => void;
}

const ActionsSection: React.FC<ActionsSectionsProps> = ({
  onClickMep,
  onClickPay,
  onClickSend,
}) => {
  const iconSize = px2rem(20);
  const iconColor = "var(--white-to-black)";

  const isNative = Capacitor.isNativePlatform();

  return (
    <div className={styles.actionsWrapper}>
      <CircularButton label="Dólar MEP" onClick={onClickMep}>
        <DollarSign size={iconSize} color={iconColor} />
      </CircularButton>
      <CircularButton label="Pagar" onClick={onClickPay} disabled={!isNative}>
        <QrCode size={iconSize} color={iconColor} />
      </CircularButton>
      <CircularButton label="Extraer" onClick={onClickSend}>
        <Send size={iconSize} color={iconColor} />
      </CircularButton>
    </div>
  );
};

export default ActionsSection;
