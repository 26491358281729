import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface StepperProps {
  stepsCount: number;
  currentStep: number;
  withoutMarginBottom?: boolean;
  withTransition?: boolean;
  variant?: ThemeVariants;
}

const Stepper = ({
  stepsCount,
  currentStep,
  withoutMarginBottom = false,
  withTransition = false,
  variant = ThemeVariants.Capital,
}: StepperProps) => (
  <div
    className={classNames(styles.stepperContainer, {
      [styles.withoutMarginBottom]: withoutMarginBottom,
    })}
  >
    {Array.from({ length: stepsCount }, (_, index) => index + 1).map(
      (step: number, i: number) => (
        <div key={i} className={classNames(styles.stepper)}>
          <div
            className={classNames(styles[variant], {
              [styles.progressTransition]:
                withTransition && step === currentStep,
              [styles.filled]: step <= currentStep,
            })}
          />
        </div>
      )
    )}
  </div>
);

export default Stepper;
