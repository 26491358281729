import { CDN_URL } from "constants/index";

import { Currencies } from "interfaces/wallet";

import Text, { TextVariants } from "../Text";
import Variation from "../CurrentVariation";
import Amount, { AmountSizes } from "../Amount";

import styles from "./styles.module.scss";

interface AssetMarketCardProps {
  image: string;
  ticker: string;
  onClick?: (asset: string) => void;
  value: number;
  variation: number;
}

const AssetMarketCard: React.FC<AssetMarketCardProps> = ({
  image,
  ticker,
  onClick,
  value,
  variation,
}) => {
  const handleOnClick = () => {
    onClick && onClick(ticker);
  };

  return (
    <div className={styles.assetWrapper} onClick={handleOnClick}>
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          <img src={`${CDN_URL}/logos/${image}`} alt="" />
        </div>
        <Text
          color="var(--slate800)"
          className={styles.tickerName}
          variant={TextVariants.RegularTextS}
        >
          {ticker}
        </Text>
      </div>
      <Amount
        isSmallCurrency
        currency={Currencies.ARS}
        amount={value}
        size={AmountSizes.Small}
      />
      <div className={styles.variationContainer}>
        <Variation value={variation} />
      </div>
    </div>
  );
};

export default AssetMarketCard;
