import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { marketKeys } from "apis/queryKeyFactories";
import { marketService } from "apis/services";
import dayjs from "dayjs";
import { HistoricDataMarketData } from "interfaces/api-responses";
import { ErrorMessage } from "interfaces/errors";
import { getIntervalParameters } from "utils";

interface UseMarketHistoricalDataQueryProps {
  longTicker: string;
  interval?: string;
}

const FOUR_HOURS = 4 * 60 * 60 * 1000;

export const fetchMarketHistoricalData = async (
  longTicker: string,
  interval?: string
) => {
  const intervalParams = getIntervalParameters(interval);
  const { data } = await API.get<HistoricDataMarketData>(
    `${marketService.tickers}/${longTicker}/historic-data`,
    {
      params: {
        date_from: dayjs()
          .subtract(intervalParams.value, intervalParams.frame)
          .format("YYYY-MM-DD"),
      },
    }
  );

  return data;
};

export const useMarketHistoricalDataQuery = ({
  longTicker,
  interval,
}: UseMarketHistoricalDataQueryProps) => {
  return useAppQuery<HistoricDataMarketData, ErrorMessage>({
    queryKey: () => marketKeys.marketHistoricalData(longTicker, interval),
    queryFn: () => fetchMarketHistoricalData(longTicker, interval),
    options: {
      onError: () => {
        return {
          message: "Hubo un error obteniendo los datos históricos",
          status: 500,
        };
      },
      staleTime: FOUR_HOURS,
      cacheTime: FOUR_HOURS,
    },
  });
};
