import { useState } from "react";

import { ampli } from "ampli";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import { useCocosCard } from "context/CocosCardProvider";
import { CardSections } from "interfaces/card/enums";
import { REGION_OPTIONS } from "components/page-cocos-card/constants";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface ShippingConfirmProps {
  open: boolean;
  onClose: () => void;
}

const ShippingConfirm: React.FC<ShippingConfirmProps> = ({ open, onClose }) => {
  const { shippingInfo, setSelectedSection } = useCocosCard();

  const slate800 = "var(--slate800)";
  const slate900 = "var(--slate900)";

  const shippingInfoFields = [
    {
      label: "Provincia",
      value: REGION_OPTIONS.find((r) => r.value === shippingInfo?.region)
        ?.label,
    },
    { label: "Localidad", value: shippingInfo?.city },
    { label: "Calle", value: shippingInfo?.street_name },
    { label: "Altura", value: shippingInfo?.street_number },
    { label: "Código Postal", value: shippingInfo?.zip_code },
  ];

  const handleConfirm = async () => {
    ampli.cardDeliveryConfirm({ province: shippingInfo?.city });
    setSelectedSection(CardSections.SHIPPING_PAYMENT);
  };

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.shippingConfirmContainer}
    >
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        color={slate900}
        component="h2"
        className={styles.title}
      >
        Confirmá tus datos
      </Heading>
      <Text variant={TextVariants.RegularText} color={slate800}>
        Datos de envío
      </Text>
      <div className={styles.confirmBottomSheetDetail}>
        {shippingInfoFields.map((field) => (
          <div className={styles.row} key={field.label}>
            <Text variant={TextVariants.RegularText} color={slate800}>
              {field.label}
            </Text>
            <Text variant={TextVariants.SemiboldText} color={slate800}>
              {field.value}
            </Text>
          </div>
        ))}
      </div>
      <div className={styles.buttonsWrapper}>
        <OutlinedButton variant={ThemeVariants.Pay} onClick={onClose}>
          Editar
        </OutlinedButton>
        <FilledButton variant={ThemeVariants.Pay} onClick={handleConfirm}>
          Confirmar
        </FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default ShippingConfirm;
