import { useEffect } from "react";

import { useMovements } from "context/MovementsProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { MovementsTypes } from "interfaces/movements";
import { Currencies } from "interfaces/wallet";

import MovementsDesktop from "./components/movements-desktop";
import MovementsMobile from "./components/movements-mobile";

import styles from "./styles.module.scss";

const MovementsPage: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  const {
    getCashMovementsData,
    getTickerMovementsData,
    setSection,
  } = useMovements();

  useEffect(() => {
    const previousSection = sessionStorage.getItem("movements_section");

    if (previousSection && previousSection === MovementsTypes.INSTRUMENT) {
      setSection(previousSection);
      getTickerMovementsData();
    } else {
      getCashMovementsData(Currencies.ARS);
    }
  }, []);

  return (
    <div className={styles.movementsPage}>
      {isMobile ? <MovementsMobile /> : <MovementsDesktop />}
    </div>
  );
};

export default MovementsPage;
