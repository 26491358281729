import { useState } from "react";

import { IconButton, Typography } from "@mui/material";
import { HideIcon } from "assets/icons/eye/hide";
import { ViewIcon } from "assets/icons/eye/view";
import { ShareIcon } from "assets/icons/share";
import dayjs from "dayjs";
import * as htmlToImage from "html-to-image";
import { TickerDetails } from "interfaces/movements";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency, getCurrencyLabel } from "utils";
import { getColor } from "utils/getColor";
import { trackAction } from "utils/amplitude";
import { useAppSelector } from "hooks/redux";
import { getCurrentScreen } from "features/global/globalSlice";

import styles from "./styles.module.scss";

interface CardDetailVisibleProps {
  tickerDetails: TickerDetails;
}

const CardDetailVisible: React.FC<CardDetailVisibleProps> = ({
  tickerDetails,
}) => {
  const currentScreen = useAppSelector(getCurrentScreen);
  const [displayInfo, setDisplayInfo] = useState(true);
  const iconColor = getColor("--darkblue-to-light");
  const {
    instrument_code,
    total_quantity,
    total_holding,
    historic_revenue,
  } = tickerDetails;

  const isPositiveValue = (value: number) => value >= 0;

  const handleDisplayinfo = () => {
    trackAction(
      `${currentScreen} - Click ${displayInfo ? "hide" : "show"} balance`
    );
    setDisplayInfo(!displayInfo);
  };

  const handleShare = () => {
    trackAction(`${currentScreen} - Click share`);
    const div = document.getElementById("card");

    if (div) {
      htmlToImage
        .toBlob(div, { skipAutoScale: true, height: 500 })
        .then(async function (blob) {
          if (navigator && blob) {
            navigator.share({
              files: [
                new File(
                  [blob],
                  `Detalle tenencia ${instrument_code} - ${dayjs().format(
                    "DD-MM-YYYY HH:mm"
                  )}.jpeg`,
                  {
                    type: "image/jpeg",
                    lastModified: new Date().getTime(),
                  }
                ),
              ],
            });
          } else if (blob) {
            navigator.clipboard.write([
              new ClipboardItem({
                [blob.type]: blob,
              }),
            ]);
          }
        });
    }
  };

  return (
    <div id={"card"} className={styles.card}>
      <div className={styles.actions}>
        <IconButton onClick={handleDisplayinfo}>
          {displayInfo ? (
            <ViewIcon color={iconColor} size={20} />
          ) : (
            <HideIcon color={iconColor} size={20} />
          )}
        </IconButton>
        <IconButton onClick={handleShare}>
          <ShareIcon color={iconColor} size={20} />
        </IconButton>
      </div>
      <div className={styles.mainInfo}>
        <Typography>Tenencia actual</Typography>
        <Typography
          className={`${
            isPositiveValue(total_holding) ? styles.green : styles.red
          }`}
        >
          {getCurrencyLabel(Currencies.ARS)}&nbsp;
          {displayInfo ? formatToCurrency(total_holding) : "*****"}
        </Typography>
        <Typography className={styles.quantity}>
          = {formatToCurrency(total_quantity)} nominales
        </Typography>
      </div>

      <div className={styles.mainInfo}>
        <Typography>Ganancia histórica</Typography>
        <Typography
          className={`${
            isPositiveValue(historic_revenue) ? styles.green : styles.red
          }`}
        >
          {getCurrencyLabel(Currencies.ARS)}&nbsp;
          {displayInfo ? formatToCurrency(historic_revenue) : "*****"}
        </Typography>
      </div>
    </div>
  );
};

export default CardDetailVisible;
