import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import dayjs from "dayjs";
import {
  CashMovements,
  CashMovementsResponse,
  FilterSections,
  MovementsFilters,
  MovementsTypes,
  Pagination,
  TickerMovementsResponse,
  TickersMovements,
} from "interfaces/movements";
import { CheckBoxOptions, DatesFilter } from "interfaces/ui";
import { Currencies } from "interfaces/wallet";
import { trackAction } from "utils/amplitude";
import { useAppSelector } from "hooks/redux";
import { getCurrentScreen } from "features/global/globalSlice";
import API from "apis";
import { walletService } from "apis/services";

const LIMIT_VALUE = 50;

const cache = {
  ttl: 30 * 1000,
};

interface Context {
  section: string;
  setSection: (v: string) => void;
  filterSection: string;
  setFilterSection: (v: string) => void;
  conceptFilters: CheckBoxOptions[];
  handleUpdateFilters: (o: string, c: boolean) => void;
  filters: MovementsFilters;
  setFilters: (v: MovementsFilters) => void;
  handleClearFilters: () => void;
  dateFilters: DatesFilter[];
  setDateFilters: (v: DatesFilter[]) => void;
  getCashMovementsData: (currency: string, page?: number) => void;
  getTickerMovementsData: (page?: number) => void;
  cashMovementsData: CashMovements[];
  tickersMovementsData: TickersMovements[];
  movementsLoading: boolean;
  movementsPagination?: Pagination;
}

const MovementsContext = createContext<Context | null>(null);

export const MovementsProvider = ({ children }: { children: ReactNode }) => {
  const [movementsPagination, setMovementsPagination] = useState<Pagination>({
    total_items: 0,
    total_pages: 0,
    per_page: 50,
    curr_page: 0,
  });
  const [cashMovementsData, setCashMovementsData] = useState<CashMovements[]>(
    []
  );
  const [tickersMovementsData, setTickersMovementData] = useState<
    TickersMovements[]
  >([]);
  const [movementsLoading, setMovevementsLoading] = useState<boolean>(false);
  const currentScreen = useAppSelector(getCurrentScreen);
  const [section, setSection] = useState<string>(MovementsTypes.MONETARY);
  const [filterSection, setFilterSection] = useState<string>(
    FilterSections.FILTERS
  );

  const filtersInitialState = {
    date_to: "",
    date_from: "",
    currency: Currencies.ARS,
    type: [],
  };

  const today = dayjs().format("YYYY-MM-DD");

  const dateOptionsInitialState = [
    {
      label: "Hoy",
      from: today,
      to: today,
      checked: false,
    },
    {
      label: "Ayer",
      from: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      to: today,
      checked: false,
    },
    {
      label: "Última semana",
      from: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
      to: today,
      checked: false,
    },
    {
      label: "Últimos 15 días",
      from: dayjs().subtract(15, "day").format("YYYY-MM-DD"),
      to: today,
      checked: false,
    },
    {
      label: "Últimos 30 dias",
      from: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
      to: today,
      checked: false,
    },
    {
      label: "Últimos 6 meses",
      from: dayjs().subtract(6, "month").format("YYYY-MM-DD"),
      to: today,
      checked: false,
    },
  ];

  const conceptFiltersInitialState = [
    {
      option: "Todos",
      checked: true,
    },
    {
      option: "Compra",
      checked: false,
    },
    {
      option: "Venta",
      checked: false,
    },
    {
      option: "Transferencias",
      checked: false,
    },
    {
      option: "Suscripción",
      checked: false,
    },
    {
      option: "Rescate",
      checked: false,
    },
  ];

  const [conceptFilters, setConceptFilters] = useState<CheckBoxOptions[]>(
    conceptFiltersInitialState
  );
  const [dateFilters, setDateFilters] = useState<DatesFilter[]>(
    dateOptionsInitialState
  );
  const [filters, setFilters] = useState<MovementsFilters>(filtersInitialState);

  const getCashMovementsData = async (currency: string, page: number = 1) => {
    try {
      setMovevementsLoading(true);

      const {
        data: cashMovements,
      }: { data: CashMovementsResponse } = await API.get(
        walletService.cashMovements,
        {
          params: {
            currency,
            date_from: filters.date_from,
            date_to: filters.date_to,
            types: filters.type,
            limit: LIMIT_VALUE,
            offset: LIMIT_VALUE * (page - 1),
          },
          cache,
        }
      );

      if (page === 1) {
        setCashMovementsData(cashMovements.data);
      } else {
        const newData = cashMovementsData.map((e) => {
          const toAdd = cashMovements.data.find(
            (f) => f.executionDate === e.executionDate
          );
          if (toAdd) {
            e.cashMovements = [...e.cashMovements, ...toAdd.cashMovements];
          }
          return e;
        });

        cashMovements.data.forEach((item) => {
          if (
            !cashMovementsData.some(
              (existingItem) =>
                existingItem.executionDate === item.executionDate
            )
          ) {
            newData.push(item);
          }
        });

        setCashMovementsData(newData);
      }

      setMovementsPagination(cashMovements.pagination);
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        throw new Error("[Movements] Error retrieving cash_movements");
      }
    } finally {
      setMovevementsLoading(false);
    }
  };

  const getTickerMovementsData = async (page: number = 1) => {
    try {
      setMovevementsLoading(true);

      const { data }: { data: TickerMovementsResponse } = await API.get(
        walletService.tickerMovements,
        {
          params: {
            types: filters.type,
            date_from: filters.date_from,
            date_to: filters.date_to,
            limit: LIMIT_VALUE,
            offset: LIMIT_VALUE * (page - 1),
          },
        }
      );

      if (page === 1) {
        setTickersMovementData(data.data);
      } else {
        const newData = tickersMovementsData.map((e) => {
          const toAdd = data.data.find(
            (f) => f.executionDate === e.executionDate
          );
          if (toAdd) {
            e.tickerMovements = [
              ...e.tickerMovements,
              ...toAdd.tickerMovements,
            ];
          }
          return e;
        });

        data.data.forEach((item) => {
          if (
            !tickersMovementsData.some(
              (existingItem) =>
                existingItem.executionDate === item.executionDate
            )
          ) {
            newData.push(item);
          }
        });

        setTickersMovementData(newData);
      }

      setMovementsPagination(data.pagination);
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        throw new Error("[Movements] Error retrieving tickers_movements");
      }
    } finally {
      setMovevementsLoading(false);
    }
  };

  useEffect(() => {
    const checkedValue = dateFilters.filter((e) => e.checked)[0];
    if (checkedValue)
      setFilters({
        ...filters,
        date_from: checkedValue.from,
        date_to: checkedValue.to,
      });
  }, [dateFilters]);

  useEffect(() => {
    const filterTypes = conceptFilters
      .filter((item) => item.checked)
      .map((item) => item.option);
    setFilters({
      ...filters,
      type: filterTypes[0] === "Todos" ? [] : filterTypes,
    });
  }, [conceptFilters]);

  const handleClearFilters = () => {
    setDateFilters(dateOptionsInitialState);
    setFilters(filtersInitialState);
    setConceptFilters(conceptFiltersInitialState);
  };

  const handleUpdateFilters = (option: string, checked: boolean) => {
    let updatedFilters;
    let checkAllChecked = false;

    trackAction(`${currentScreen} - Click filter`, {
      type: "concept",
      filter: option,
    });

    if (option !== "Todos") {
      updatedFilters = conceptFilters.map((filter) =>
        filter.option === option
          ? { ...filter, checked }
          : filter.option === "Todos"
          ? {
              ...filter,
              checked: false,
            }
          : filter
      );

      checkAllChecked = updatedFilters.every((item) => {
        return item.option === "Todos" || item.checked;
      });

      if (!checkAllChecked) {
        setConceptFilters(updatedFilters);
        return;
      }
    }
    updatedFilters = conceptFilters.map((filter) =>
      filter.option === "Todos"
        ? { ...filter, checked: true }
        : {
            ...filter,
            checked: false,
          }
    );

    setConceptFilters(updatedFilters);
  };

  const memoizedValues = useMemo(() => {
    return {
      section,
      filters,
      setSection,
      setFilters,
      dateFilters,
      filterSection,
      conceptFilters,
      setDateFilters,
      setFilterSection,
      movementsLoading,
      cashMovementsData,
      handleClearFilters,
      handleUpdateFilters,
      movementsPagination,
      tickersMovementsData,
      getCashMovementsData,
      getTickerMovementsData,
    };
  }, [
    section,
    filters,
    setSection,
    setFilters,
    dateFilters,
    filterSection,
    conceptFilters,
    setDateFilters,
    setFilterSection,
    movementsLoading,
    cashMovementsData,
    handleClearFilters,
    handleUpdateFilters,
    movementsPagination,
    tickersMovementsData,
    getCashMovementsData,
    getTickerMovementsData,
  ]);

  return (
    <MovementsContext.Provider value={memoizedValues}>
      {children}
    </MovementsContext.Provider>
  );
};

export default MovementsContext;

export const useMovements = () => {
  const context = useContext(MovementsContext);

  if (!context) {
    throw new Error("[MovementsContext] Missing context");
  }

  return context;
};
