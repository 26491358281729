import { useParams } from "react-router-dom";
import { useMarket } from "context/MarketProvider";
import { MarketParams } from "interfaces/markets";
import { screenNames } from "router/routes";
import { trackAction } from "utils/amplitude";

import NoFavoritos from "./components/NoFavoritos";
import Favoritos from "./components/Favoritos";

import styles from "./styles.module.scss";

interface SubSectionProps {
  setOpenNewListModal: (v: boolean) => void;
}

const SubSections: React.FC<SubSectionProps> = ({ setOpenNewListModal }) => {
  const { instrument_type } = useParams<MarketParams>();
  const {
    setFilterFlag,
    selectedInstrumentSubtype,
    setSelectedInstrumentSubtype,
  } = useMarket();

  const onChangeSubSection = (newOption: string) => {
    setFilterFlag(true);
    trackAction(`${screenNames.market} - Filter List By Subtype`, {
      type: instrument_type,
      subtype: newOption,
    });
    if (selectedInstrumentSubtype !== newOption) {
      setSelectedInstrumentSubtype(newOption);
    }
  };

  return (
    <div className={styles.subsections}>
      <NoFavoritos onChangeSubSection={onChangeSubSection} />
      <Favoritos
        onChangeSubSection={onChangeSubSection}
        setOpenNewListModal={setOpenNewListModal}
      />
    </div>
  );
};

export default SubSections;
