import Text, { TextVariants } from "components/common/Text";
import { ArrowUpRight } from "lucide-react";
import px2rem from "utils/px2rem";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { ampli } from "ampli";

import styles from "./styles.module.scss";

const OrdersSection: React.FC = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    ampli.homeExited({ referral: "daily_orders" });
    navigate(mainRoutes.orders);
  };

  return (
    <div className={styles.ordersSectionWrapper} onClick={handleOnClick}>
      <Text variant={TextVariants.SemiboldTextS} color="var(--blue800)">
        Ver órdenes del día
      </Text>
      <ArrowUpRight size={px2rem(16)} color="var(--blue800)" />
    </div>
  );
};

export default OrdersSection;
