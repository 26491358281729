import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { InfoIcon } from "assets/icons/info";
import AlertModal from "components/common/alert-modal";
import mainRoutes from "router/routes";

import "./index.scss";

interface PropTypes {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

const InvestorTestNoProfile = (props: PropTypes) => {
  const { openModal, setOpenModal } = props;
  const navigate = useNavigate();

  const closeModal = async () => {
    setOpenModal(false);
    navigate(mainRoutes.profileTest);
  };

  return (
    <AlertModal
      alertIcon={<InfoIcon color="#0062e1" size={50} />}
      bodyText={
        <>
          <Typography className="subtitle" component="p" variant="textL">
            Debés hacer un test para conocer tu perfil de inversor antes de
            comenzar a operar. Te va a llevar menos de 5 minutos.
            <br />
          </Typography>
        </>
      }
      headingText={
        <Typography component="p" variant="heading4_bold">
          Completá tu perfil de inversor.
        </Typography>
      }
      open={openModal}
      primaryButtonText={"Hacer el test"}
      onClickMainButton={closeModal}
    />
  );
};

export default InvestorTestNoProfile;
