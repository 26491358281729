import { useState } from "react";

import { Typography, IconButton } from "@mui/material";
import { IoArrowBack } from "react-icons/io5";
import RetryButton from "components/common/retry-button";
import { InfoIcon } from "assets/icons/info";
import { getOrders } from "features/markets/";
import { RootState } from "store/store";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import OrdersInfoModal from "../OrdersInfoModal";

import styles from "./styles.module.scss";

const OrderHeader = ({ goBack }: { goBack: () => void }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    (state: RootState) => state.markets.getOrdersListLoading
  );

  const getTransfersData = () => dispatch(getOrders());

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <header className={styles.ordersHeader}>
        <IconButton onClick={goBack}>
          <IoArrowBack />
        </IconButton>
        <Typography>Órdenes del día</Typography>
        <RetryButton
          loading={loading}
          disabled={loading}
          onClick={getTransfersData}
        />
        <div className={styles.infoButton} onClick={handleClickOpen}>
          <InfoIcon size={26} />
        </div>
      </header>

      <OrdersInfoModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default OrderHeader;
