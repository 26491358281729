interface PropTypes {
  size?: number;
  color?: string;
  type?: "thick" | "thin";
}

export const ArrowDownIcon = ({ color, size, type = "thin" }: PropTypes) => {
  return (
    <svg
      className="chevron-down-icon"
      fill="none"
      height={`${size || 16}`}
      viewBox={`0 0 ${size ? size + 1 : 17} ${size || 16}`}
      width={`${size ? size + 1 : 17}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9446 6.2221L8.50011 10.6665L4.05566 6.2221"
        stroke={color || "#060D12"}
        strokeLinecap="round"
        strokeWidth={type === "thin" ? "1.5" : "2"}
      />
    </svg>
  );
};
