import { Currencies } from "interfaces/wallet";

export const ACTIVE_BID = {
  long_ticker: "AER11-0002-C-CT-USD",
  price: 1,
  tir: 6,
  monto_minimo: 1,
  name: "ON AA2000 Clase 11",
  lamina_minima: 0,
  lamina_incremental: 1,
  logo_file_name: "",
  instrument_code: "AER11",
  expiration: "15-12-2026",
  settlement_date: "23-12-2024",
  settlement_days: "48hs",
  settlement: "24 meses",
  tasa: 6,
  amort_title: "Amortización",
  currency: Currencies.USD,
};
