import { CardTransaction } from "interfaces/card/interface";
import Divider from "components/common/Divider";

import MovementsRow from "./Row";

import styles from "./styles.module.scss";

interface TransactionsListProps {
  transactions: CardTransaction[];
}

const TransactionsList: React.FC<TransactionsListProps> = ({
  transactions,
}) => {
  return (
    <div className={styles.transactionsListContainer}>
      {transactions.map((transaction, index) => (
        <div
          key={transaction.id_card_transaction}
          className={styles.transactionItem}
        >
          <MovementsRow transaction={transaction} />
          {index !== transactions.length - 1 && (
            <Divider className={styles.divider} />
          )}
        </div>
      ))}
    </div>
  );
};

export default TransactionsList;
