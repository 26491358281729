import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { CardTransactionPayment } from "interfaces/card/interface";
import Text, { TextVariants } from "components/common/Text";
import Heading, { HeadingVariants } from "components/common/Heading";
import getCurrencyIcon from "components/page-cocos-card/Home/PaymentMethodList/CurrencyIcon";

import styles from "./styles.module.scss";

interface PaymentDetail {
  payments: CardTransactionPayment[];
}

const PaymentDetail: React.FC<PaymentDetail> = ({ payments }) => {
  if (!payments.length) return null;

  if (payments.length === 1) {
    return (
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={"var(--slate800)"}
          component="span"
        >
          Método de pago
        </Heading>
        <Heading
          variant={HeadingVariants.RegularValueS}
          color={"var(--slate800)"}
          component="span"
          className={styles.currency}
        >
          {getCurrencyIcon({
            currency: payments[0].payment_method,
            size: 16,
          })}
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            {payments[0].payment_method}
          </Text>
        </Heading>
      </div>
    );
  }

  return (
    <div className={styles.listContainer}>
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        color={"var(--slate800)"}
        component="span"
      >
        Método de pago múltiple
      </Heading>
      {payments.map((payment, index) => (
        <div key={payment.payment_method} className={styles.list}>
          <Heading
            variant={HeadingVariants.RegularValueS}
            color={"var(--slate800)"}
            component="span"
            className={styles.currency}
          >
            <div className={styles.position}>
              <Text
                variant={TextVariants.SemiboldValueXS}
                color="var(--white-to-black)"
              >
                {index + 1}
              </Text>
            </div>
            {getCurrencyIcon({
              currency: payment.payment_method,
              size: 16,
            })}
            <Text variant={TextVariants.RegularText} color="var(--slate900)">
              {payment.payment_method}
            </Text>
          </Heading>
          <Heading
            variant={HeadingVariants.RegularValueS}
            color={"var(--slate800)"}
            component="span"
            className={styles.currency}
          >
            <PriceLabel price={payment.amount} size={PriceLabelSizes.Small} />
          </Heading>
        </div>
      ))}
    </div>
  );
};

export default PaymentDetail;
