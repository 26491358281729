import { useEffect } from "react";

import {
  useCapitalSendAccounts,
  AccountsSteps,
} from "context/CapitalSendAccountsProvider";
import { getWallet } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import NewAccount, {
  NewAccountReferral,
} from "components/page-capital-accounts/NewAccount";
import Toast, { ToastVariants } from "components/common/Toast";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Amount from "./Amount";
import Accounts from "./Accounts";
import Success from "./Success";
import Error from "./Error";

interface CapitalSendPage {
  isDesktop?: boolean;
}

const CapitalSendPage: React.FC<CapitalSendPage> = ({ isDesktop = false }) => {
  const dispatch = useAppDispatch();
  const wallet = useAppSelector((state: RootState) => state.wallet.wallet);

  const {
    step,
    setIsDesktop,
    setStep,
    displayToast,
    setDisplayToast,
  } = useCapitalSendAccounts();

  useEffect(() => {
    setIsDesktop(isDesktop);

    if (!wallet) {
      dispatch(getWallet());
    }
  }, []);

  return (
    <>
      {step === AccountsSteps.AMOUNT && <Amount wallet={wallet} />}
      {step === AccountsSteps.ACCOUNTS && <Accounts />}
      {step === AccountsSteps.NEW_ACCOUNT && (
        <NewAccount
          referral={NewAccountReferral.Withdraw}
          onClose={() => setStep(AccountsSteps.ACCOUNTS)}
          onError={() => setStep(AccountsSteps.ERROR)}
          onDisplayToast={() =>
            setDisplayToast({
              visible: false,
              message: "Cuenta agendada exitosamente",
            })
          }
        />
      )}
      {step === AccountsSteps.SUCCESS && <Success />}
      {step === AccountsSteps.ERROR && <Error />}
      {displayToast.visible && (
        <Toast
          variant={ToastVariants.Success}
          message={displayToast.message}
          onClose={() => setDisplayToast({ visible: false, message: "" })}
        />
      )}
    </>
  );
};

export default CapitalSendPage;
