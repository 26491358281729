import { useEffect, useState } from "react";

import API from "apis";
import { onBoardingService } from "apis/services";
import {
  OnboardingData,
  FinalStatusOnboarding,
} from "interfaces/api-responses";

import ApprovedScreen from "./Approved";
import RejectedScreen from "./Rejected";
import PendingScreen from "./Pending";
import InfoRequiredScreen from "./InfoRequired";
import Loading from "../../../components/Loading";

const FinalScreen = () => {
  const [
    onboardingStatus,
    setOnboardingStatus,
  ] = useState<FinalStatusOnboarding>(FinalStatusOnboarding.PENDING);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getOnboarding = async () => {
      setLoading(true);
      try {
        const { data } = await API.get<OnboardingData>(
          onBoardingService.getOnboarding
        );
        setOnboardingStatus(data.status);
      } catch (error: any) {
        throw new Error("Error getting onboarding data");
      } finally {
        setLoading(false);
      }
    };

    getOnboarding();
  }, []);

  if (loading) {
    return <Loading displayText={false} />;
  }

  const screenComponents = {
    [FinalStatusOnboarding.PENDING]: <PendingScreen />,
    [FinalStatusOnboarding.REJECTED]: <RejectedScreen />,
    [FinalStatusOnboarding.APPROVED]: <ApprovedScreen />,
    [FinalStatusOnboarding.PENDING_WITH_REQUIREMENTS]: <InfoRequiredScreen />,
  };

  return screenComponents[onboardingStatus];
};

export default FinalScreen;
