import CocosGoldPage from "components/page-cocos-gold";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

const CocosGold: React.FC = () => {
  const { isMobile } = useDeviceCheck();

  if (isMobile) return <CocosGoldPage />;

  return <Navigate replace to={mainRoutes.home} />;
};

export default CocosGold;
