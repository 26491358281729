import PageHeader from "components/common/PageHeader";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { size } from "lodash";
import Text, { TextVariants } from "components/common/Text";
import mainRoutes from "router/routes";
import { phoneRegex } from "components/page-sign-up/utils";

import PhoneFormConfirm from "./PhoneFormConfirm";
import PhoneFormInputs from "./PhoneFormInputs";
import PhoneFormTitle from "./PhoneFormTitle";

import styles from "./styles.module.scss";

interface PhoneFormProps {
  hasError: boolean;
  isLoading: boolean;
  phoneNumber: string;
  countryCode?: number;
  registerPhone: () => void;
  setPhoneNumber: (phone: string) => void;
  setCountryCode: (countryCode: number) => void;
}

const PhoneForm: React.FC<PhoneFormProps> = ({
  hasError,
  isLoading,
  phoneNumber,
  countryCode,
  registerPhone,
  setPhoneNumber,
  setCountryCode,
}) => {
  const isValidPhone = (value: string) => {
    if (value.length < 4) return true;
    return phoneRegex.test(value);
  };

  const isPhoneValid = () => {
    const fullPhone = `${countryCode}${phoneNumber}`;
    if (countryCode === 54) {
      return isValidPhone(fullPhone);
    }
    const length = size(fullPhone);
    return length >= 5 && length <= 15;
  };

  const navigate = useNavigate();

  return (
    <div className={styles.PhoneFormContainer}>
      <div className={styles.wrapper}>
        <PageHeader icon={X} onClick={() => navigate(mainRoutes.logout)} />
        <PhoneFormTitle />
        <PhoneFormInputs
          phoneNumber={phoneNumber}
          isPhoneValid={isPhoneValid()}
          setCountryCode={setCountryCode}
          setPhoneNumber={setPhoneNumber}
        />
        {hasError && (
          <Text variant={TextVariants.RegularTextS} color="var(--red800)">
            No pudimos registrar tu télefono. Intentá de nuevo más tarde.
          </Text>
        )}
      </div>
      <PhoneFormConfirm
        isLoading={isLoading}
        phoneNumber={phoneNumber}
        isPhoneValid={isPhoneValid()}
        registerPhone={registerPhone}
      />
    </div>
  );
};

export default PhoneForm;
