import TVChartContainer from "components/common/tv-chart-container";
import { TradingViewOrders } from "components/page-orders/trading-view";
import { Instruments } from "interfaces/markets";
import { getOrderById } from "features/markets";
import { RootState } from "store/store";
import { UIChartLine } from "components/common/charts/line";
import { useAppSelector, useAppDispatch } from "hooks/redux";

import styles from "./styles.module.scss";

interface TradingExtendedProps {
  instrument: any;
}

const TradingExtended: React.FC<TradingExtendedProps> = ({ instrument }) => {
  const dispatch = useAppDispatch();
  const marketHistoricData = useAppSelector(
    (state: RootState) => state.markets.marketHistoricData
  );
  const marketDetail = useAppSelector(
    (state: RootState) => state.markets.marketDetail
  );

  const instrumentToShow =
    marketDetail && instrument.long_ticker !== marketDetail.long_ticker
      ? marketDetail.long_ticker
      : instrument.long_ticker;

  const getChart = () => {
    let chart;

    if (instrument && instrument.instrument_type !== Instruments.FCI) {
      chart = <TVChartContainer symbol={instrumentToShow} />;
    } else if (instrument && marketHistoricData) {
      chart = (
        <UIChartLine
          id_tick_size_rule={instrument.id_tick_size_rule}
          name={instrument.short_ticker}
          prices={marketHistoricData?.prices}
          tick_size={instrument.tick_size}
        />
      );
    }

    return chart;
  };

  const handleClickOrderRow = (orderId: string) => {
    dispatch(getOrderById(orderId));
  };

  return (
    <div className={styles.divContainer}>
      <div className={styles.chart}>{getChart()}</div>
      <div className={styles.orders}>
        <TradingViewOrders onClickOrderRow={handleClickOrderRow} />
      </div>
    </div>
  );
};

export default TradingExtended;
