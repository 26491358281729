import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import CapitalReceivePage from "components/page-capital-receive";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { InvestmentSuggestedResponse } from "interfaces/markets";
import Drawer from "layouts/drawer";
import { useNavigate } from "react-router";
import { RootState } from "store/store";
import { getCurrencyFormatted, getSettlementByTicker } from "utils";
import mainRoutes from "router/routes";
import { useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";
import { ACTIVE_BID } from "components/common/bid/constants";
import { Currencies } from "interfaces/wallet";

import styles from "./styles.module.scss";

interface InvestmentErrorsProps {
  instrument: InvestmentSuggestedResponse;
}

const InvestmentErrors: React.FC<InvestmentErrorsProps> = ({ instrument }) => {
  const navigate = useNavigate();
  const { openRightModalContent } = useAppState();
  const { isMobile } = useDeviceCheck();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);

  const wallet = useAppSelector((state: RootState) => state.wallet.wallet);
  const term = getSettlementByTicker(instrument.long_ticker);

  const currency = ACTIVE_BID.currency === Currencies.ARS ? "ars" : "usd";
  const buyingPower = +(wallet?.[term]?.[currency] ?? 0);

  const minToOperate = instrument.monto_minimo;

  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>("");

  const noBuyingPowerWithMinError = () => {
    return (
      <Typography id={styles.errorMessage} onClick={depositFunds}>
        No tenés suficiente saldo para hacer esta operación.
        <br />
        <Typography id={styles.errorMessage}>
          El monto mínimo de compra es de{" "}
          {getCurrencyFormatted(ACTIVE_BID.currency, minToOperate)}
        </Typography>
        <b>Ingresá dinero acá</b>
      </Typography>
    );
  };

  const noBuyingPowerError = () => {
    return (
      <Typography id={styles.errorMessage} onClick={depositFunds}>
        No tenés suficiente saldo para hacer esta operación.
        <br />
        <b>Ingresá dinero acá</b>
      </Typography>
    );
  };

  const minToBuy = () => {
    return (
      <Typography id={styles.errorMessage} className={styles.bold}>
        El monto mínimo de compra es de{" "}
        {getCurrencyFormatted(ACTIVE_BID.currency, minToOperate)}
      </Typography>
    );
  };

  useEffect(() => {
    handleCheckError();
  }, [order.amount, order.quantity, wallet]);

  const handleCheckError = () => {
    if (buyingPower <= minToOperate) {
      setErrorMessage(noBuyingPowerWithMinError());
      return;
    }
    if (order.amount === 0 || order.amount === undefined) {
      setErrorMessage("");
      return;
    }
    if (order.amount < minToOperate) {
      setErrorMessage(minToBuy());
      return;
    }
    if (order.amount > buyingPower) {
      setErrorMessage(noBuyingPowerError());
      return;
    }
    setErrorMessage("");
  };

  const depositFunds = () => {
    if (isMobile) {
      navigate(mainRoutes.receive);
    } else {
      openRightModalContent(
        <Drawer>
          <CapitalReceivePage isDesktop />
        </Drawer>
      );
    }
  };

  return <div>{errorMessage}</div>;
};

export default InvestmentErrors;
