import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { BondFlow, FlowAnalysis } from "interfaces/markets";
import { formatToCurrency, getCurrencyLabel } from "utils";

import styles from "./styles.module.scss";

interface BondTicketBottomProps {
  analysis: FlowAnalysis;
}

const BondTicketBottom: React.FC<BondTicketBottomProps> = ({ analysis }) => {
  const getTotal = () => {
    let total = 0;

    analysis.flow.map((row: BondFlow, index: number) => {
      if (index > 0) {
        total += row.cashFlow;
      }
    });
    const receive = `${getCurrencyLabel(
      analysis.flow[1].id_currency
    )} ${formatToCurrency(total)}`;

    return receive;
  };
  return (
    <div className={styles.bottomCard}>
      <div className={styles.bottomCardRow}>
        <Typography>Inversión inicial</Typography>
        <Typography>${formatToCurrency(analysis.price)}</Typography>
      </div>
      <div className={styles.bottomCardRow}>
        <Typography>TIR</Typography>
        <Typography>{formatToCurrency(analysis.tir)}%</Typography>
      </div>
      <div className={styles.bottomCardRow}>
        <Typography>Duration</Typography>
        <Typography>{formatToCurrency(analysis.duration)}</Typography>
      </div>
      <div className={styles.bottomCardRow}>
        <Typography>Fecha de liquidación</Typography>
        <Typography>
          {dayjs(analysis.expiration_date).format("DD-MM-YYYY")}
        </Typography>
      </div>
      <div className={styles.bottomCardRow}>
        <Typography className={styles.rowTitle}>Total a recibir</Typography>
        <Typography className={styles.rowValue}>{getTotal()}</Typography>
      </div>
    </div>
  );
};

export default BondTicketBottom;
