import { useEffect, useState } from "react";

import { supabase } from "utils/supabase";
import API from "apis";
import { userService } from "apis/services";
import { ampli } from "ampli";
import { AnalyticsEvents, trackAnalyticsEvent } from "utils/firebaseAnalytics";
import GoogleTagManager from "react-gtm-module";

import EmailPasswordForm from "./EmailPasswordForm";
import EmailValidation from "./EmailValidation";

const AltasPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showForm, setShowForm] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUserByEmail = async () => {
    try {
      await API.get(`${userService.users}?email=${email}`);
      const errorMessage =
        "Este email ya se encuentra registrado. Por favor, iniciá sesión.";

      ampli.signupEmailValidationError({
        type: "EMAIL_ALREADY_EXISTS",
        message: errorMessage,
      });

      setErrorMessage(errorMessage);
      return { success: true };
    } catch (error) {
      return { success: false };
    }
  };

  const registerEmail = async () => {
    setErrorMessage("");

    const { error } = await supabase.auth.signUp({
      email,
      password,
    });

    setIsLoading(false);

    if (error) {
      ampli.signupEmailValidationError({
        type: error.name,
        message: error.message,
      });

      setErrorMessage(
        "No pudimos crear tu cuenta, intentá de nuevo más tarde."
      );
      return;
    }
    setShowForm(false);

    GoogleTagManager.dataLayer({
      dataLayer: { event: "wapp_altas_envio_mail_validacion" },
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.fbq("track", "wapp_altas_envio_mail_validacion");

    trackAnalyticsEvent(AnalyticsEvents.ALTAS_ENVIO_MAIL_VALIDACION);
  };

  const onClickSignUp = async () => {
    setIsLoading(true);
    const { success } = await getUserByEmail();

    if (!success) {
      await registerEmail();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    ampli.signupStarted();
    document.documentElement.classList.add("newStyles");

    return () => {
      document.documentElement.classList.remove("newStyles");
    };
  }, []);

  if (showForm)
    return (
      <EmailPasswordForm
        email={email}
        setEmail={setEmail}
        password={password}
        isLoading={isLoading}
        setPassword={setPassword}
        errorMessage={errorMessage}
        registerEmail={onClickSignUp}
        hideForm={() => setShowForm(false)}
      />
    );

  return (
    <EmailValidation
      email={email}
      registerEmail={registerEmail}
      showForm={() => setShowForm(true)}
    />
  );
};

export default AltasPage;
