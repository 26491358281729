import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorMessage } from "interfaces/errors";
import {
  Currencies,
  MappedHoldingsType,
  PortfolioTotal,
} from "interfaces/wallet";
import _ from "lodash";

export interface PortfolioState {
  holdings?: MappedHoldingsType;
  errorMessage: ErrorMessage;
  loading: boolean;
  totalPortfolio: PortfolioTotal;
  currencyValue: Currencies;
  displayTypeValue: string;
  displayTotal: boolean;
}

export const initialState: PortfolioState = {
  holdings: undefined,
  errorMessage: {
    message: "",
    status: null,
  },
  loading: false,
  totalPortfolio: {},
  currencyValue: Currencies.ARS,
  displayTypeValue: "amount",
  displayTotal: true,
};

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setPortfolioTotal(
      state,
      action: PayloadAction<{ available: { ars: number; usd: number } }>
    ) {
      state.totalPortfolio = {
        ...state.totalPortfolio,
        ...action.payload,
      };
    },
    setCurrencyValue(state, action: PayloadAction<{ currency: Currencies }>) {
      state.currencyValue = action.payload.currency;
    },
    setDisplayTypeValue(state, action: PayloadAction<{ typeValue: string }>) {
      state.displayTypeValue = action.payload.typeValue;
    },
    setDisplayTotal(state, action: PayloadAction<{ shoulDisplay: boolean }>) {
      state.displayTotal = action.payload.shoulDisplay;
    },
  },
});

export const {
  setPortfolioTotal,
  setCurrencyValue,
  setDisplayTotal,
  setDisplayTypeValue,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
