import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { marketKeys } from "apis/queryKeyFactories";
import { marketService } from "apis/services";
import { InstrumentData, MarketData } from "interfaces/api-responses";
import { ErrorMessage } from "interfaces/errors";
import { Sections, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";

export const fetchFavoriteInstruments = async (listId: string) => {
  const { data } = await API.get<MarketData>(marketService.tickers, {
    params: {
      instrument_type: Sections.FAVORITOS,
      instrument_subtype: listId,
      settlement_days: SettlementTerms._24,
      currency: Currencies.ARS,
      segment: "C",
      size: 10,
      page: 1,
    },
  });

  return data.items;
};

export const useFavoriteInstrumentsQuery = ({
  listId,
}: {
  listId?: string;
}) => {
  return useAppQuery<InstrumentData[], ErrorMessage>({
    queryKey: () => marketKeys.favoriteInstruments(listId),
    queryFn: () => fetchFavoriteInstruments(listId ?? ""),
    options: {
      onError: () => {
        return {
          message: "Hubo un error obteniendo tus activos favoritos",
          status: 500,
        };
      },
      staleTime: Infinity,
    },
  });
};
