import { Typography } from "@mui/material";

import styles from "./styles.module.scss";

interface DesktopTableHeaderProps {
  isTicker?: boolean;
  isTickerDetail?: boolean;
}

const DesktopTableHeader: React.FC<DesktopTableHeaderProps> = ({
  isTicker = false,
  isTickerDetail = false,
}) => {
  return (
    <div
      className={`${styles.cashHeader} ${isTicker && styles.tickerHeader} ${
        isTickerDetail && styles.tickerDetailHeader
      }`}
    >
      <Typography>Fecha operación</Typography>
      <Typography>Fecha liquidación</Typography>
      {!isTickerDetail && <Typography>Especie</Typography>}
      <Typography>Operación</Typography>
      {isTicker && <Typography className={styles.numbers}>Cantidad</Typography>}
      {isTicker && (
        <Typography className={styles.numbers}>Precio promedio</Typography>
      )}
      <Typography className={styles.numbers}>Total Operación</Typography>
    </div>
  );
};

export default DesktopTableHeader;
