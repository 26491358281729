import React, {
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from "react";

import classNames from "classnames";

import styles from "./styles.module.scss";

interface InputRadioProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  children: React.ReactNode | string;
  value: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<any>) => void;
  displayRadio?: boolean;
}

const InputRadio = ({
  children,
  checked,
  onChange,
  displayRadio = true,
  ...props
}: InputRadioProps) => {
  const { name, value } = props;
  const inputStyles = classNames(styles.checkmark, {
    [styles.checkmarkSelected]: checked,
    [styles.hideRadio]: !displayRadio,
  });

  const inputContainerStyles = classNames(styles.inputContainer, {
    [styles.hideRadio]: !displayRadio,
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div>
      <label className={inputContainerStyles} htmlFor={value}>
        {children}
        <input
          type="radio"
          name={name}
          id={value}
          checked={checked}
          onChange={handleChange}
          {...props}
        />
        {displayRadio && <span className={inputStyles} />}
      </label>
    </div>
  );
};

export default InputRadio;
