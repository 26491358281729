import { getSubtypes } from "constants/instruments";

import { useMarket } from "context/MarketProvider";
import { Sections } from "interfaces/markets";
import SectionsButtons from "components/common/SectionsButtons";

interface NoFavoritosProps {
  onChangeSubSection: (newOption: string) => void;
}

const NoFavoritos: React.FC<NoFavoritosProps> = ({ onChangeSubSection }) => {
  const { selectedInstrumentType, selectedInstrumentSubtype } = useMarket();
  if (
    selectedInstrumentType === Sections.FAVORITOS ||
    selectedInstrumentType === Sections.HOME
  )
    return null;

  const subtypes = getSubtypes(selectedInstrumentType);

  if (!subtypes || subtypes.length === 1) return null;

  return (
    <SectionsButtons
      newClass={"scrollable"}
      selected={selectedInstrumentSubtype || ""}
      subTypesOptions={subtypes}
      onClickHeaderOption={onChangeSubSection}
    />
  );
};

export default NoFavoritos;
