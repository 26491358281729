import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { ThemeVariants } from "interfaces/theme";
import Pencil from "assets/illustrations/Pencil";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";

import styles from "./styles.module.scss";

const PendingCreation: React.FC = () => {
  const navigate = useNavigate();

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={{
        title: "Creación en proceso",
        onClick: () => navigate(-1),
        withCloseIcon: false,
      }}
      primaryButtonProps={{
        children: "Volver al inicio",
        onClick: () => navigate(mainRoutes.home),
      }}
    >
      <div className={styles.pendingCreationContainer}>
        <Pencil />
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Estamos creando tu cuenta. Este proceso puede demorar un rato. Por
          favor, volvé más tarde.
        </Text>
      </div>
    </BasicWrapper>
  );
};

export default PendingCreation;
