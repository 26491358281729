import { INSTRUMENTS } from "constants/instruments";

import { useState } from "react";

import ListsButton from "components/common/lists-button";
import SearchModal from "components/common/search-modal";
import ListsModal from "components/page-market/mobile/lists-modal";
import { useMarket } from "context/MarketProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Instruments, Sections } from "interfaces/markets";
import { screenNames } from "router/routes";
import { trackAction } from "utils/amplitude";

const Header: React.FC = () => {
  const { selectedInstrumentType } = useMarket();
  const isCaucion = selectedInstrumentType === Instruments.CAUCION;
  const [openListsModal, setOpenListsModal] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false);
  const { isMobile } = useDeviceCheck();

  const handleToggleDisplayListsModal = () => {
    if (!openListsModal) {
      trackAction(`${screenNames.market} - Click Categories`);
    }
    setOpenListsModal(!openListsModal);
  };

  const toggleSearch = () => {
    setOpenSearchModal(!openSearchModal);
  };

  if (!isMobile) return null;

  return (
    <>
      <ListsButton
        label={
          (selectedInstrumentType !== Sections.FAVORITOS
            ? INSTRUMENTS.find(
                (f) => f.instrument_type === selectedInstrumentType
              )?.name
            : selectedInstrumentType) || ""
        }
        onClick={handleToggleDisplayListsModal}
        onClickSearchIcon={toggleSearch}
        withGoBack={isCaucion}
      />
      <SearchModal
        handleToggleDisplayModal={toggleSearch}
        open={openSearchModal}
      />
      <ListsModal
        open={openListsModal}
        selected={selectedInstrumentType}
        onToggleDisplay={handleToggleDisplayListsModal}
      />
    </>
  );
};

export default Header;
