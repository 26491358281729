import { useEffect, useState } from "react";

import ErrorContainer from "components/common/error-modal";
import {
  FundScreen,
  getCuotapartes,
  CocosFund,
} from "components/page-capital-funds/helpers";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { getSellingPower, getWallet } from "features/wallet/walletSlice";
import {
  getMarketDetailByShortTicker,
  updateOrderOptions,
} from "features/markets";
import { ampli } from "ampli";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import { useFundOrderMutation } from "apis/market/mutations/useFundOrderMutation";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { useFundsHoldings } from "apis/fci/queries/useFundsHoldings";
import type { RootState } from "store/store";
import { Instruments } from "interfaces/markets";
import { useMarketHistoricalDataQuery } from "apis/market/queries/useMarketHistoricalDataQuery";

import AddWithdrawFunds from "../../AddWithdrawFunds";
import FCIHoldingsSkeletonLoader from "../FCIHoldingsSkeletonLoader";
import FundError from "../../AddWithdrawFunds/components/FundError";
import FundSuccess from "../../AddWithdrawFunds/components/FundSuccess";
import FundsHoldings from "../../FundsHoldings";

import styles from "./styles.module.scss";

const FundsWrapper = ({ selectedFund }: { selectedFund: CocosFund }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();
  const fundOrderMutation = useFundOrderMutation();

  const {
    data: holdingResponse,
    isLoading: isHoldingsLoading,
    isError,
  } = useFundsHoldings({
    securityId: selectedFund.securityId,
  });

  const {
    data: marketHistoricData,
    isLoading: isHistoricDataLoading,
  } = useMarketHistoricalDataQuery({
    longTicker: selectedFund.ticker,
    interval: "7D",
  });

  const [screen, setScreen] = useState<FundScreen>(FundScreen.FUND_RETURNS);

  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const { sellingPower, loading: isSellingPowerLoading } = useAppSelector(
    (state: RootState) => state.wallet
  );
  const shortTickerData = useAppSelector(
    (state: RootState) => state.markets.shortTickerData
  );

  const getAvailableSell = () => {
    if (marketHistoricData && sellingPower?.available) {
      const lastIndex = marketHistoricData?.prices.length - 1;
      const lastPrice = marketHistoricData?.prices[lastIndex].close;

      return (lastPrice / 1000) * getCuotapartes(sellingPower.available);
    }

    return 0;
  };

  const availableToSell = getAvailableSell();
  const hasSellingPower = availableToSell > 0;

  const onActionsClose = () => {
    if (!isMobile && !hasSellingPower) dispatch(closeRightModal());
    if (hasSellingPower) return setScreen(FundScreen.FUND_RETURNS);

    return navigate(mainRoutes.home);
  };

  const onFundOrderConfirm = async () => {
    try {
      ampli.fciOrderConfirmed({
        ticker: order.instrument_code,
        side: order.type,
        amount: order.amount,
        currency: order.currency,
      });

      const orderId = await fundOrderMutation.mutateAsync(order);

      dispatch(updateOrderOptions({ ...order, id: orderId }));
      dispatch(getWallet());

      if (order.long_ticker) {
        dispatch(getSellingPower(order.long_ticker));
      }
    } catch (error) {
      console.error("Mutation failed", error);
    }
  };

  const handleGoBack = () => {
    if (isMobile) return navigate(-1);

    return dispatch(closeRightModal());
  };

  const onCloseSuccessOrError = () => {
    if (!isMobile) return dispatch(closeRightModal());

    navigate(-1);
  };

  useEffect(() => {
    if (selectedFund.ticker) {
      dispatch(getSellingPower(selectedFund.ticker));
      dispatch(
        getMarketDetailByShortTicker({
          short_ticker: selectedFund.ticker,
          instrument_type: Instruments.FCI,
        })
      );
    }
  }, []);

  if (fundOrderMutation.isError)
    return (
      <FundError
        currency={selectedFund.currency}
        order={order}
        onClose={onCloseSuccessOrError}
      />
    );

  if (fundOrderMutation.isSuccess)
    return (
      <FundSuccess
        currency={selectedFund.currency}
        order={order}
        onClose={onCloseSuccessOrError}
      />
    );

  if (
    isHistoricDataLoading ||
    isSellingPowerLoading ||
    isHoldingsLoading ||
    fundOrderMutation.isLoading
  )
    return <FCIHoldingsSkeletonLoader onClick={handleGoBack} />;

  if (isError)
    return (
      <div className={styles.errorContainer}>
        <ErrorContainer
          subtitle="En este momento no podemos mostrar tus tenencias."
          textPrimaryButton="Volver a la home"
          onClickPrimaryButton={() => navigate(mainRoutes.home)}
        />
      </div>
    );

  if (
    screen === FundScreen.FUND_RETURNS &&
    holdingResponse &&
    holdingResponse.hasMovements
  )
    return (
      <FundsHoldings
        holdings={holdingResponse}
        selectedFund={selectedFund}
        onClick={handleGoBack}
        setScreen={setScreen}
      />
    );

  return (
    <AddWithdrawFunds
      availableToSell={availableToSell}
      marketData={shortTickerData[0]}
      onClose={onActionsClose}
      onFundOrderConfirm={onFundOrderConfirm}
      selectedFund={selectedFund}
      tab={screen}
    />
  );
};

export default FundsWrapper;
