import React, { useEffect, useState } from "react";

import ErrorContainer from "components/common/error-modal";

import SendResetPasswordEmail from "./SendResetPasswordEmail";
import EmailReview from "./EmailReview";

import styles from "./styles.module.scss";

enum Step {
  RECOVERY = "RECOVERY",
  REVIEW = "REVIEW",
}

const ForgotPasswordPage: React.FC = () => {
  const [step, setStep] = useState<Step>(Step.RECOVERY);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.documentElement.classList.add("newStyles");

    return () => {
      document.documentElement.classList.remove("newStyles");
    };
  }, []);

  if (error)
    return (
      <ErrorContainer
        subtitle={error}
        textPrimaryButton="Volver a intentar"
        onClickPrimaryButton={() => setError("")}
      />
    );

  return (
    <div className={styles.forgotPasswordRoot}>
      {step === Step.RECOVERY ? (
        <SendResetPasswordEmail
          setEmail={setEmail}
          onSuccess={() => setStep(Step.REVIEW)}
          setError={setError}
        />
      ) : (
        <EmailReview
          email={email}
          onGoBack={() => setStep(Step.RECOVERY)}
          setError={setError}
        />
      )}
    </div>
  );
};

export default ForgotPasswordPage;
