import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import DNIFront from "assets/images/DNIFront";
import DNIBack from "assets/images/DNIBack";

import styles from "./styles.module.scss";

interface ProcedureNumberInfoProps {
  onClick: (value: boolean) => void;
}

const ProcedureNumberInfo = ({ onClick }: ProcedureNumberInfoProps) => {
  return (
    <div className={styles.infoContainer}>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.procedureNumberTitle}
      >
        ¿Dónde encuentro el número de trámite?
      </Text>
      <div className={styles.dnisContainer}>
        <div className={styles.svgContainer}>
          <DNIFront />
          <Text variant={TextVariants.RegularValueXS} color="var(--slate800)">
            DNI con Nº de trámite al FRENTE
          </Text>
        </div>
        <div className={styles.svgContainer}>
          <DNIBack />
          <Text variant={TextVariants.RegularValueXS} color="var(--slate800)">
            DNI con Nº de trámite al DORSO
          </Text>
        </div>
      </div>
      <Text
        variant={TextVariants.RegularText}
        className={styles.procedureNumberText}
        color="var(--slate800)"
      >
        En el frente o dorso de tu DNI, bajo el título{" "}
        <Text
          component="span"
          color="var(--slate900)"
          variant={TextVariants.SemiboldText}
        >
          “Trámite Nº / Of. ident.”
        </Text>
        vas a encontrar el número de trámite (11 dígitos) a completar.
      </Text>
      <FilledButton className={styles.button} onClick={() => onClick(false)}>
        Entendido
      </FilledButton>
    </div>
  );
};

export default ProcedureNumberInfo;
