export const REGION_OPTIONS = [
  { value: "AR-C", label: "Ciudad de Buenos Aires" },
  { value: "AR-B", label: "Buenos Aires" },
  { value: "AR-X", label: "Córdoba" },
  { value: "AR-S", label: "Santa Fe" },
  { value: "AR-M", label: "Mendoza" },
  { value: "AR-D", label: "San Luis" },
  { value: "AR-E", label: "Entre Ríos" },
  { value: "AR-F", label: "La Rioja" },
  { value: "AR-G", label: "Santiago del Estero" },
  { value: "AR-H", label: "Chaco" },
  { value: "AR-J", label: "San Juan" },
  { value: "AR-K", label: "Catamarca" },
  { value: "AR-L", label: "La Pampa" },
  { value: "AR-N", label: "Misiones" },
  { value: "AR-P", label: "Formosa" },
  { value: "AR-Q", label: "Neuquén" },
  { value: "AR-R", label: "Río Negro" },
  { value: "AR-U", label: "Chubut" },
  { value: "AR-V", label: "Tierra del Fuego" },
  { value: "AR-W", label: "Corrientes" },
  { value: "AR-Y", label: "Jujuy" },
  { value: "AR-Z", label: "Santa Cruz" },
  { value: "AR-T", label: "Tucumán" },
  { value: "AR-A", label: "Salta" },
];

export const PomelosCardDataURL = {
  PRODUCTION: "https://secure-data-web.pomelo.la",
  STAGING: "https://secure-data-web-stage.pomelo.la",
};

export const MAX_LIMIT_AMOUNT = 1500000;
export const MASTERCARD_TSP = "MASTERCARD";

export const CARD_TERMS_AND_CONDITIONS =
  "https://assets.cocos.capital/cocos/reglamentos/CPAY%20-%20TYC%20-%20CARD%2BQR%20201124.pdf";
export const CARD_SUPPLMENT =
  "https://assets.cocos.capital/cocos/reglamentos/01.CCSA-Suplemento_TyC_apertura_de_cuenta-modalidades_de_captacion.pdf";
export const CARD_PRIVACY_POLICY =
  "https://assets.cocos.capital/cocos/reglamentos/CPAY%20-%20Poli%CC%81tica%20de%20Privacidad%20-%202024.pdf";

export enum TaxTypes {
  TAX = "TAX",
  MARKUP = "MARKUP",
}
