import { sleep } from "./sleep";

interface RetryOptions {
  retries?: number;
  delay?: number;
}

const withRetries = async <T>(
  f: () => Promise<T>,
  options: RetryOptions = {}
): Promise<T> => {
  const { retries = 3, delay = 500 } = options;
  let attempt = 0;
  let lastError = null;

  while (attempt < retries) {
    try {
      const result = await f();
      return result;
    } catch (error: any) {
      lastError = error;
      attempt += 1;
      await sleep(delay);
    }
  }

  throw lastError;
};

export { withRetries };
