import React, { useEffect } from "react";

import GPay from "assets/icons/GPay";
import Text, { TextVariants } from "components/common/Text";

import useGoogleFont from "../useGoogleFont";

import styles from "./styles.module.scss";

interface GPayButtonProps {
  isActive?: boolean;
  onClick: () => void;
}

const GPayButton: React.FC<GPayButtonProps> = ({ onClick }) => {
  useGoogleFont();

  return (
    <div className={styles.gpayButton} onClick={onClick}>
      <Text variant={TextVariants.RegularTextS} className={styles.gPayText}>
        Agregar a
      </Text>
      <GPay />
    </div>
  );
};

export default GPayButton;
