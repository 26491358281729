import { useEffect } from "react";

import { IconButton, Typography } from "@mui/material";
import MarketDetailSidebarDesktop from "components/common/market-detail-sidebar";
import TradingContainer from "components/common/trading-container";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Instruments, SettlementTerms } from "interfaces/markets";
import DrawerLayout from "layouts/drawer";
import LayoutV2 from "layouts/v2";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getMarketDetailByShortTicker } from "features/markets";
import { openRightModal } from "features/rightModal/rightModalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { getSettlementDays } from "utils";
import { trackAction } from "utils/amplitude";
import mainRoutes from "router/routes";
import { TickersMovements } from "interfaces/movements";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

import styles from "./styles.module.scss";

interface TickerMovementsHeaderProps {
  instrument_code: string;
  tickersMovementsData: TickersMovements[];
}

const TickerMovementsHeader: React.FC<TickerMovementsHeaderProps> = ({
  instrument_code,
  tickersMovementsData,
}) => {
  const { isMobile } = useDeviceCheck();
  const { openRightModalContent } = useAppState();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { shortTickerData } = useAppSelector((state) => state?.markets);
  const currentScreen = useAppSelector(getCurrentScreen);

  const gotData =
    shortTickerData &&
    shortTickerData.length > 0 &&
    shortTickerData[0].instrument_code === instrument_code;

  useEffect(() => {
    if (tickersMovementsData && tickersMovementsData.length > 0) {
      dispatch(
        getMarketDetailByShortTicker({
          short_ticker: instrument_code,
          instrument_type:
            tickersMovementsData[0].tickerMovements[0].instrument_type,
        })
      );
    }
  }, [tickersMovementsData]);

  const goBack = () => {
    navigate(-1);
  };

  const handleGoOperate = () => {
    trackAction(`${currentScreen} - Click operate`);
    const market =
      shortTickerData[0].instrument_type === Instruments.FCI
        ? shortTickerData[0]
        : shortTickerData.filter(
            (m) =>
              getSettlementDays(m.settlement_days) === SettlementTerms._24 &&
              m.currency ===
                tickersMovementsData[0].tickerMovements[0].id_currency
          )[0];

    if (isMobile) {
      navigate(
        `${mainRoutes.market}/${market.instrument_type}/${market.instrument_code}`,
        {
          state: market,
        }
      );
      return;
    }

    if (market.instrument_type === Instruments.FCI) {
      openRightModalContent(
        <DrawerLayout>
          <MarketDetailSidebarDesktop
            id={market.short_ticker ?? ""}
            ticker={market}
          />
        </DrawerLayout>
      );
      return;
    }
    openRightModalContent(
      <LayoutV2
        title={market.short_ticker ?? ""}
        quickOperation
        instrument={market}
      >
        <TradingContainer instrumentProp={market} />
      </LayoutV2>
    );
  };

  return (
    <div className={styles.tickerHeader}>
      <div className={styles.leftCol}>
        <IconButton onClick={goBack}>
          <IoArrowBack />
        </IconButton>
        {isMobile && (
          <div className={styles.header}>
            <Typography className={styles.title}>
              Movimientos {instrument_code}
            </Typography>
            <Typography className={styles.detail}>
              Encontrá tus operaciones realizadas
            </Typography>
          </div>
        )}
        {!isMobile && (
          <div className={styles.header}>
            <Typography className={styles.title}>
              Detalle {instrument_code}
            </Typography>
          </div>
        )}
      </div>
      {gotData ? (
        <Typography className={styles.goOperate} onClick={handleGoOperate}>
          Operar
        </Typography>
      ) : (
        <>&nbsp;</>
      )}
    </div>
  );
};

export default TickerMovementsHeader;
