import { useMovements } from "context/MovementsProvider";
import { FilterSections, MovementsTypes } from "interfaces/movements";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { trackAction } from "utils/amplitude";
import { getCurrentScreen } from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import CalendarSection from "./components/calendar";
import FilterSection from "./components/filters";

import styles from "./styles.module.scss";
interface MovementsFilterProps {
  handleFilter: (v: boolean) => void;
}

const MovementsFilter: React.FC<MovementsFilterProps> = ({ handleFilter }) => {
  const dispatch = useAppDispatch();
  const {
    section,
    filters,
    filterSection,
    setFilterSection,
    getCashMovementsData,
    getTickerMovementsData,
  } = useMovements();
  const currentScreen = useAppSelector(getCurrentScreen);

  const handleChangeSection = () => {
    setFilterSection("calendar");
  };

  const handleOnClickFilter = () => {
    trackAction(`${currentScreen} - Click apply filters `);
    if (section === MovementsTypes.MONETARY) {
      getCashMovementsData(filters.currency, 1);
    } else {
      getTickerMovementsData(1);
    }
    handleFilter(false);
    dispatch(closeRightModal());
  };

  const handleOnClickApply = () => {
    setFilterSection("filters");
  };

  const handleOnClickCancel = () => {
    dispatch(closeRightModal());
    handleFilter(false);
  };

  return (
    <div className={styles.divContainer}>
      {filterSection === FilterSections.FILTERS && (
        <FilterSection
          handleChangeSection={handleChangeSection}
          onClickCancel={handleOnClickCancel}
          onClickFilter={handleOnClickFilter}
        />
      )}
      {filterSection === FilterSections.CALENDAR && (
        <CalendarSection
          onClickCancel={handleOnClickApply}
          onClickApply={handleOnClickApply}
        />
      )}
    </div>
  );
};

export default MovementsFilter;
