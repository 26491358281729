import { useEffect, useState } from "react";

import { CircularProgress, IconButton, Typography } from "@mui/material";
import { ArrowLeftIcon } from "assets/icons/arrow-left";
import { FillStar } from "assets/icons/favorite/fill-star";
import { TransparentStar } from "assets/icons/favorite/transparent-star";
import BondCalculator from "components/common/BondCalculator";
import { ConfirmOrder } from "components/common/confirm-order";
import FCIMarketDetailTrade from "components/common/fci-detail-trade";
import MarketDetailTrade from "components/common/market-detail-trade";
import { OrderDetailSidebarDesktop } from "components/common/order-detail-sidebar";
import TVChartContainer from "components/common/tv-chart-container";
import { Orders } from "components/page-market/page-market-detail/mobile/orders";
import { AnimatePresence, motion } from "framer-motion";
import { Instruments, OrderType } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import DrawerLayout from "layouts/drawer";
import { BsCalculator } from "react-icons/bs";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { getOrderById, toggleInList } from "features/markets";
import {
  openRightModal,
  closeRightModal,
} from "features/rightModal/rightModalSlice";
import { setLoading } from "features/user/userSlice";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import { UIChartLine } from "components/common/charts/line";
import {
  calculateNetAmount,
  getComisiones,
  getCurrencyLabel,
  getFormattedNumber,
  getSettlementDays,
} from "utils";
import mainRoutes from "router/routes";
import { useAppState } from "context/AppStateProvider";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

const MarketDetailDesktop: React.FC = () => {
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const marketDetail = useAppSelector(
    (state: RootState) => state.markets.marketDetail
  );
  const marketHistoricData = useAppSelector(
    (state: RootState) => state.markets.marketHistoricData
  );

  const marketLoading = useAppSelector(
    (state: RootState) => state.markets.marketDetailLoading
  );
  const orderDetail = useAppSelector(
    (state: RootState) => state.markets.orderDetail
  );
  const gotFlow = useAppSelector((state: RootState) => state.calculator.check);

  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const user = useAppSelector(getLoggedUser);
  const [isFavorite, setIsFavorite] = useState(marketDetail?.isFavorite);

  useEffect(() => {
    if (marketDetail) {
      setIsFavorite(marketDetail.isFavorite);
    }
  }, [marketDetail]);
  const comisiones = getComisiones(
    marketDetail?.instrument_type,
    marketDetail?.instrument_subtype,
    order.type,
    order.price && order.quantity
      ? calculateNetAmount(
          order.price,
          order.quantity,
          marketDetail?.contract_size,
          marketDetail?.price_factor
        )
      : calculateNetAmount(
          ((order.amount || 0) / (order.quantity || 1)) *
            (marketDetail?.price_factor || 1),
          order.quantity || 1,
          marketDetail?.contract_size,
          marketDetail?.price_factor
        )
  );

  const isHardcodedMessage =
    order.currency === Currencies.USD &&
    marketDetail?.instrument_type === Instruments.BONOS_PUBLICOS &&
    marketDetail?.short_ticker &&
    (marketDetail?.short_ticker.startsWith("AL") ||
      marketDetail?.short_ticker.startsWith("GD"));

  useEffect(() => {
    if (orderDetail?.order_id) {
      openRightModalContent(OrderDetailSidebarComponent(orderDetail.order_id));
    }
  }, [orderDetail?.order_id]);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { dark_theme: darkMode, favoritesLists } = user;

  const handleToogleAssetInList = async (ev: any) => {
    ev.stopPropagation();
    setLoading(true);
    setIsFavorite(!isFavorite);

    if (marketDetail) {
      await toggleInList(
        !!isFavorite,
        favoritesLists[0].id_list,
        marketDetail.long_ticker
      );
    }
    setLoading(false);
  };

  const handleCloseSidebar = () => {
    dispatch(closeRightModal());
  };

  const OrderDetailSidebarComponent = (selectedId: string) => (
    <DrawerLayout>
      <OrderDetailSidebarDesktop
        id={selectedId}
        onCloseSidebar={handleCloseSidebar}
      />
    </DrawerLayout>
  );

  const handleClickOrderRow = (orderId: string) => {
    dispatch(getOrderById(orderId));
  };

  const ConfirmTradeComponent = (data: any, fciRescueAlmostTotal?: boolean) => {
    return (
      <ConfirmOrder
        data={data}
        infoLabel={
          fciRescueAlmostTotal
            ? "Dado el monto ingresado, se realizará un rescate por el total de cuotapartes."
            : undefined
        }
        onClickOrderDetail={handleClickOrderRow}
        onClose={handleCloseSidebar}
        instrument_type={marketDetail?.instrument_type || "ACCIONES"}
      />
    );
  };

  const handleClickContinueTrade = (fciAlmostTotal?: boolean) => {
    let data;
    if (marketDetail?.instrument_type !== "FCI") {
      if (isHardcodedMessage) {
        data = [
          {
            label: "Especie",
            value: `${marketDetail?.long_ticker.split("-")[0]}${
              marketDetail?.instrument_short_name
                ? ` | ${marketDetail?.instrument_short_name}`
                : ""
            }`,
          },
          {
            label: "Plazo de liquidación",
            value: getSettlementDays(marketDetail?.settlement_days || 0),
          },
          {
            label: order.method === "limite" ? "Precio límite" : "Precio",
            value:
              order.method === "limite"
                ? getFormattedNumber(
                    order.price,
                    marketDetail?.id_tick_size_rule,
                    marketDetail?.tick_size
                  )
                : "Mercado",
          },
          {
            label: "Cantidad de Nominales",
            value: order.quantity ? Math.floor(order.quantity) : "",
          },
          {
            label:
              order.type === OrderType.Buy
                ? "Monto a Invertir"
                : "Monto a recibir",
            value: `${getCurrencyLabel(order.currency)} ${comisiones[0].toFixed(
              2
            )}`,
          },
          {
            label:
              order.type === OrderType.Buy
                ? "Estimado a debitar"
                : "Estimado a acreditar",
            value: `${getCurrencyLabel(order.currency)} ${(
              comisiones[0] + comisiones[1]
            ).toFixed(2)}`,
          },
        ];
      } else {
        data = [
          {
            label: "Especie",
            value: `${marketDetail?.long_ticker.split("-")[0]}${
              marketDetail?.instrument_short_name
                ? ` | ${marketDetail?.instrument_short_name}`
                : ""
            }`,
          },
          {
            label: "Plazo de liquidación",
            value: getSettlementDays(marketDetail?.settlement_days || 0),
          },
          {
            label: order.method === "limite" ? "Precio límite" : "Precio",
            value:
              order.method === "limite"
                ? getFormattedNumber(
                    order.price,
                    marketDetail?.id_tick_size_rule,
                    marketDetail?.tick_size
                  )
                : "Mercado",
          },
          {
            label: "Cantidad de Nominales",
            value: order.quantity ? Math.floor(order.quantity) : "",
          },
          {
            label:
              order.type === OrderType.Buy
                ? "Monto a Invertir"
                : "Monto a recibir",
            value: `${getCurrencyLabel(order.currency)} ${comisiones[0].toFixed(
              2
            )}`,
          },
          {
            label: "Comisiones Estimadas",
            value: `${getCurrencyLabel(order.currency)} ${comisiones[1].toFixed(
              2
            )}`,
          },
          {
            label:
              order.type === OrderType.Buy
                ? "Estimado a debitar"
                : "Estimado a acreditar",
            value: `${getCurrencyLabel(order.currency)} ${(
              comisiones[0] + comisiones[1]
            ).toFixed(2)}`,
          },
        ];
      }
    } else {
      data = [
        {
          label: "Fondo",
          value: `${marketDetail.long_ticker.split("-")[0]} | ${
            marketDetail.instrument_short_name
          }`,
        },
        {
          label:
            order.type === OrderType.Buy
              ? "Monto a Invertir"
              : "Monto a recibir",
          value: `${getCurrencyLabel(order.currency)} ${order.amount?.toFixed(
            2
          )}`,
        },
        {
          label: "Comisiones estimadas",
          value: `${getCurrencyLabel(order.currency)} ${comisiones[1].toFixed(
            2
          )}`,
        },
        {
          label:
            order.type === OrderType.Buy
              ? "Estimado a debitar"
              : "Estimado a acreditar",
          value: `${getCurrencyLabel(order.currency)} ${(
            order.amount + comisiones[1]
          ).toFixed(2)} `,
        },
      ];
    }
    openRightModalContent(ConfirmTradeComponent(data, fciAlmostTotal ?? false));
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const openCalcu = () => (
    <DrawerLayout goBack={() => navigate(mainRoutes.market)}>
      <BondCalculator />
    </DrawerLayout>
  );

  const handleCalcu = () => {
    openRightModalContent(openCalcu());
    dispatch(
      openRightModal({
        onClose: () => setSearchParams({}),
      })
    );
    if (!marketDetail || !marketDetail.instrument_type) return;

    if (marketDetail.instrument_code)
      setSearchParams({
        bondType: marketDetail.instrument_type,
        bond: marketDetail.instrument_code,
      });
  };

  const getChart = () => {
    if (!marketDetail) return;
    if (marketDetail.instrument_type !== Instruments.FCI) {
      return <TVChartContainer symbol={marketDetail.long_ticker} />;
    }
    if (marketHistoricData) {
      return (
        <UIChartLine
          id_tick_size_rule={marketDetail.id_tick_size_rule}
          name={marketDetail.short_ticker}
          prices={marketHistoricData?.prices}
          tick_size={marketDetail.tick_size}
        />
      );
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        animate={{
          x: 0,
        }}
        className="pages-market-detail-desktop"
        initial={{
          x: -10000,
        }}
        layoutId={marketDetail?.short_ticker}
        transition={{
          layout: { duration: 0.2 },
        }}
      >
        {marketDetail && (
          <div className="market-detail-content-desktop">
            <div className="collapse-header">
              <div className="go-back" onClick={handleGoBack}>
                <ArrowLeftIcon />
                <Typography variant="buttonS">Volver</Typography>
              </div>
              <div className="favorites-close">
                {gotFlow &&
                  (marketDetail.instrument_type === "BONOS_PUBLICOS" ||
                    marketDetail.instrument_type === "BONOS_CORP") && (
                    <IconButton onClick={handleCalcu}>
                      <BsCalculator
                        className="icon-calculadora"
                        color={darkMode ? "#ffffff" : "#002C65"}
                        size={26}
                      />
                    </IconButton>
                  )}
                {!isFavorite && (
                  <IconButton onClick={handleToogleAssetInList}>
                    <TransparentStar color="#0062E1" size={26} />
                  </IconButton>
                )}
                {isFavorite && (
                  <IconButton onClick={handleToogleAssetInList}>
                    <FillStar color="#0062E1" size={26} />
                  </IconButton>
                )}
              </div>
            </div>
            <div className="market-name">
              <Typography variant="buttonS">
                {marketDetail.short_ticker}
              </Typography>
              &nbsp;
              {marketDetail.instrument_short_name && (
                <Typography variant="textXS">
                  {marketDetail.instrument_short_name}
                </Typography>
              )}
            </div>
            <div className="cols-wrapper">
              <div className="flex-col col-1">
                <div className="row chart-wrapper">{getChart()}</div>
                <div className="row row-2">
                  <Orders onClickOrderRow={handleClickOrderRow} />
                </div>
              </div>
              <div className="flex-col col-2">
                {marketDetail.instrument_type === "FCI" && (
                  <FCIMarketDetailTrade
                    onClickContinueTrade={handleClickContinueTrade}
                  />
                )}
                {marketDetail.instrument_type !== "FCI" && (
                  <MarketDetailTrade
                    market={marketDetail}
                    onClickContinueTrade={handleClickContinueTrade}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {!marketDetail && marketLoading && (
          <div className="loading">
            <CircularProgress size={40} />
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default MarketDetailDesktop;
