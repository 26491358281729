import { useEffect, useState } from "react";

import { CircularProgress, Divider, Typography } from "@mui/material";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { Currency } from "components/common/currency/index";
import ErrorContainer from "components/common/error-modal";
import Success from "components/common/success";
import dayjs from "dayjs";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import mainRoutes, { screenNames } from "router/routes";
import {
  setCreateOrderError,
  confirmCaucionOrder,
  resetCaucionOrder,
} from "features/markets";
import { RootState } from "store/store";
import { capitalizeEachWord, getComisiones, getCurrencyLabel } from "utils";
import { trackAction } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";

interface PropTypes {
  onClose: () => void;
  onClickOrderDetail?: (orderId: string) => void;
}

export const ConfirmCaucionOrderContainer = (props: PropTypes) => {
  const { onClickOrderDetail, onClose } = props;
  const caucionOrder = useAppSelector(
    (state: RootState) => state.markets.caucionOrder
  );
  const caucionError = useAppSelector(
    (state: RootState) => state.markets.caucionError
  );
  const loading = useAppSelector(
    (state: RootState) => state.markets.createOrderLoading
  );
  const orderError = useAppSelector(
    (state: RootState) => state.markets.caucionError
  );
  const getOrderLoading = useAppSelector(
    (state: RootState) => state.markets.getOrderLoading
  );
  const dispatch = useAppDispatch();

  const [step, setStep] = useState(1);
  const { isMobile } = useDeviceCheck();
  const [orderId, setOrderId] = useState<string | undefined>();
  const getDateLabel = () => {
    return capitalizeEachWord(
      dayjs().add(caucionOrder.term, "days").locale("es-mx").format("DD MMMM")
    );
  };

  const comisiones = getComisiones(
    "CAUCION",
    null,
    "SELL",
    caucionOrder.amount
  );

  useEffect(() => {
    if (caucionOrder.id || orderError.message) {
      setStep(step + 1);
    }
  }, [caucionOrder.id, orderError]);

  const handleClickConfirm = async () => {
    trackAction(`${screenNames.caucion} - Send Order`, {
      type: caucionOrder.method,
      totalAmount: caucionOrder.amount,
      rate: caucionOrder.rate,
      term: caucionOrder.term,
    });
    dispatch(confirmCaucionOrder(caucionOrder));
  };

  useEffect(() => {
    if (caucionOrder.id) {
      setOrderId(caucionOrder.id);
    }

    return () => {
      if (caucionOrder.id) {
        dispatch(resetCaucionOrder());
      }
      dispatch(setCreateOrderError({ message: "", status: null }));
    };
  }, [caucionOrder.id]);

  const handleClickOrderDetail = () => {
    if (onClickOrderDetail && orderId) {
      onClickOrderDetail(orderId);
    }
  };

  return (
    <div>
      {step === 1 && (
        <div className="step-1">
          <Typography variant="textS_bold">Confirmar orden</Typography>
          <div className="box">
            <div>
              <Typography component={"p"}>Monto</Typography>
              <Typography component={"p"} variant="textL_bold">
                {getCurrencyLabel(caucionOrder.currency)} {caucionOrder.amount}
              </Typography>
            </div>
            <div>
              <Typography component={"p"}>Plazo</Typography>
              <Typography component={"p"} variant="textL_bold">
                {caucionOrder.term}&nbsp;
                {caucionOrder.term > 1 ? "días " : "día "}|&nbsp;
                {getDateLabel()}
              </Typography>
            </div>
            <div>
              <Typography component={"p"}>Tasa de interés</Typography>
              <Typography component={"p"} variant="textL_bold">
                {caucionOrder.rate}%
              </Typography>
            </div>
            <Divider />
            <div>
              <Typography component={"p"}>Estimado a debitar</Typography>
              <Typography component={"p"} variant="textL_bold">
                {getCurrencyLabel(caucionOrder.currency)}{" "}
                <Currency>{comisiones[0]}</Currency>
              </Typography>
            </div>
          </div>
          <ButtonUI
            disabled={loading}
            size={ButtonSizes.Full}
            variant={ButtonVariants.Primary}
            onClick={handleClickConfirm}
            needReset={false}
          >
            <Typography variant="buttonL">
              {loading ? <CircularProgress size={16} /> : "Confirmar"}
            </Typography>
          </ButtonUI>
        </div>
      )}
      {step === 2 && caucionOrder.id && (
        <div className="step-2">
          <Success
            loadingSecondaryButton={getOrderLoading}
            subtitle="Para ver el estado de la orden podes ingresar a movimientos o desde el activo que operaste."
            textPrimaryButton="Ir a Mercado"
            textSecondaryButton="Ver estado de orden"
            title="¡Tu orden fue enviada con éxito!"
            urlPrimaryButton={
              window.location.pathname.includes("market")
                ? undefined
                : mainRoutes.market
            }
            urlSecondaryButton={
              isMobile
                ? `${mainRoutes.orderDetail}/${caucionOrder.id}`
                : undefined
            }
            onClickPrimaryButton={onClose}
            onClickSecondaryButton={handleClickOrderDetail}
          />
        </div>
      )}
      {step === 2 && orderError.message && !caucionOrder.id && (
        <ErrorContainer
          subtitle={
            caucionError.message.charAt(0).toUpperCase() +
            caucionError.message.slice(1).toLowerCase()
          }
          textPrimaryButton={"Volver al activo"}
          title="Ups... Hubo un error"
          onClickPrimaryButton={onClose}
        />
      )}
    </div>
  );
};
