import { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";
import { Spinner } from "components/common/spinner";
import MovementsHeader from "components/page-movements/components/movements-common/movements-header";
import MovementsRow from "components/page-movements/components/movements-common/movements-row";
import DesktopTableHeader from "components/page-movements/components/movements-common/table-header";
import { getInstrumentName } from "components/page-movements/utils";
import { useMovements } from "context/MovementsProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import {
  MovementsTypes,
  TickerMovement,
  TickersMovements,
} from "interfaces/movements";
import InfiniteScroll from "react-infinite-scroll-component";

import styles from "./styles.module.scss";

interface InstrumentsTableProps {
  data: TickersMovements[];
}

const InstrumentsTable: React.FC<InstrumentsTableProps> = ({ data }) => {
  const { isMobile } = useDeviceCheck();
  const {
    movementsLoading,
    movementsPagination,
    getTickerMovementsData,
  } = useMovements();
  const [loadMoreElements, setLoadMoreElements] = useState<boolean>(false);

  const loadElements = () => {
    if (
      movementsPagination &&
      movementsPagination.total_pages !== movementsPagination.curr_page
    ) {
      setLoadMoreElements(true);
      getTickerMovementsData(movementsPagination.curr_page + 1);
    }
  };

  useEffect(() => {
    setLoadMoreElements(false);
  }, [data]);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scroll");

    if (!movementsLoading && scrollPosition) {
      window.scrollTo(0, +scrollPosition);
      sessionStorage.removeItem("scroll");
    }
  }, [movementsLoading]);

  if (movementsLoading && !loadMoreElements) {
    return (
      <div className={styles.loadingState}>
        <Spinner />
      </div>
    );
  }

  const renderLoaderRows = (rowsLength: number) =>
    Array.from(Array(rowsLength).keys()).map((i) => (
      <Skeleton animation="wave" height={64} key={i} />
    ));

  return (
    <InfiniteScroll
      dataLength={data.length}
      hasMore={
        movementsPagination?.curr_page !== movementsPagination?.total_pages
      }
      loader={renderLoaderRows(3)}
      next={loadElements}
    >
      {!isMobile && <DesktopTableHeader isTicker />}

      {data.map((row: TickersMovements, index: number) => (
        <>
          <MovementsHeader
            key={index}
            settlementDate={row.executionDate}
            displaySettlementDate={isMobile}
          />
          <div>
            {row.tickerMovements.map(
              (movement: TickerMovement, index: number) => {
                return (
                  <MovementsRow
                    id_ticket={movement.id_ticket}
                    has_ticket_pdf={movement.has_ticket_pdf}
                    key={index}
                    operationType={movement.label ?? movement.description}
                    name={
                      getInstrumentName(movement.instrument_code) ??
                      movement.short_name
                    }
                    quantity={movement.quantity}
                    price={movement.price}
                    total={movement.amount}
                    settlement={movement.settlement}
                    instrumentType={movement.instrument_type}
                    logoFile={movement.logo_file_name}
                    currency={movement.id_currency}
                    instrumentCode={movement.instrument_code}
                    settlementDate={movement.settlement_date}
                    executionDate={movement.execution_date}
                    isTicker
                    section={MovementsTypes.INSTRUMENT}
                  />
                );
              }
            )}
          </div>
        </>
      ))}
    </InfiniteScroll>
  );
};

export default InstrumentsTable;
