import PageHeader from "components/common/PageHeader";
import { ZendeskChat } from "components/common/zendesk";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

const ChatPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.chat}>
      <PageHeader
        icon={ArrowLeft}
        title="Atención al cliente"
        onClick={() => navigate(-1)}
      />
      <ZendeskChat defer zendeskKey={import.meta.env.VITE_ZENDESK_API_KEY} />
    </div>
  );
};

export default ChatPage;
