import { SettlementIndex } from "constants/portfolio";

import { useEffect, useState } from "react";

import {
  PortfolioFromType,
  BalanceCurrencies,
  Currencies,
} from "interfaces/wallet";
import { usePortfolioQuery } from "apis/portfolio/queries/usePortfolioQuery";
import Skeleton from "react-loading-skeleton";
import { PeriodTime, PortfolioHoldings } from "interfaces/portfolio";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  clearSubscribeList,
  setSubscribeList,
} from "features/prices/capitalPricesSlice";
import { Instruments } from "interfaces/markets";
import PortfolioEmptyState, {
  PortfolioEmpty,
} from "components/page-cocos-portfolio/PortfolioContent/components/common/PortfolioEmptyState";
import AssetPortfolioRow from "components/common/AssetPortfolioRow";
import {
  cleanHistoricalData,
  resetMarketDataDetail,
  resetOrder,
} from "features/markets";
import { isCocosFundRedesign } from "components/page-capital-funds/helpers";
import DrawerLayout from "layouts/drawer";
import MarketDetailSidebarDesktop from "components/common/market-detail-sidebar";
import CapitalFunds from "components/page-capital-funds";
import LayoutV2 from "layouts/v2";
import mainRoutes from "router/routes";
import TradingContainer from "components/common/trading-container";
import { useNavigate } from "react-router-dom";
import type { RootState } from "store/store";
import { useAppState } from "context/AppStateProvider";
import { clearSellingPower } from "features/wallet/walletSlice";
import { usePerformanceQuery } from "apis/portfolio/queries/usePerformanceQuery";

import InstrumentsTableHeader from "./InstrumentsTableHeader";
import InstrumentsGroupedRow from "./InstrumentsGroupedRow";

import styles from "./styles.module.scss";

export enum SortingMethods {
  DEFAULT,
  DAYLY_VARIATION,
  DAYLY_PERFORMANCE,
  VARIATION,
  RESULT,
}

export interface SortingMethod {
  method: SortingMethods;
  isAsc: boolean;
}

export type GroupedByTypeHolding = Array<
  PortfolioHoldings & {
    dailyVariation?: number;
    dailyPerformance?: number;
    totalPerformance?: number;
    totalVariation?: number;
    averagePrice?: number;
  }
>;

type GroupedByType = {
  [type: string]: {
    type: string;
    totalAmount: number;
    instrumentType: string;
    totalPercentage: number;
    holdings: GroupedByTypeHolding;
  };
};

interface InstrumentsTableProps {
  currency: BalanceCurrencies;
  isSettlementExpanded: boolean;
  isTableGrouped: boolean;
}

const InstrumentsTable = ({
  currency,
  isTableGrouped,
  isSettlementExpanded,
}: InstrumentsTableProps) => {
  const navigate = useNavigate();
  const { openRightModalContent } = useAppState();
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const { data: portfolioData, isLoading } = usePortfolioQuery(
    currency,
    PortfolioFromType.BROKER
  );

  const [sortingMethod, setSortingMethod] = useState<SortingMethod>({
    method: SortingMethods.DEFAULT,
    isAsc: false,
  });

  const { data: dailyPerformanceData } = usePerformanceQuery(
    PeriodTime.DAY,
    !true
  );

  const { data: historicPerformanceData } = usePerformanceQuery(
    PeriodTime.MAX,
    !true
  );

  const subscribeSocketList = portfolioData?.holdings.map(
    ({ longTicker }) => longTicker
  );

  const mergedHoldingsData = portfolioData?.holdings.map((item) => {
    const instrumentDailyPerformance = dailyPerformanceData?.tickers.find(
      (instrument) => instrument.short_ticker === item.ticker
    );

    const instrumentHistoricPerformance = historicPerformanceData?.tickers.find(
      (instrument) => instrument.short_ticker === item.ticker
    );

    return {
      ...item,
      instrumentDailyPerformance,
      instrumentHistoricPerformance,
    };
  });

  const sortHoldings = () => {
    if (sortingMethod.method === SortingMethods.DEFAULT)
      return mergedHoldingsData?.sort((a, b) => {
        if (sortingMethod.isAsc) {
          return (
            a.settlements[SettlementIndex.INF].amount -
            b.settlements[SettlementIndex.INF].amount
          );
        }
        return (
          b.settlements[SettlementIndex.INF].amount -
          a.settlements[SettlementIndex.INF].amount
        );
      });

    if (sortingMethod.method === SortingMethods.DAYLY_PERFORMANCE)
      return mergedHoldingsData?.sort((a, b) => {
        if (sortingMethod.isAsc)
          return (
            (a.instrumentDailyPerformance?.result || 0) -
            (b.instrumentDailyPerformance?.result || 0)
          );
        return (
          (b.instrumentDailyPerformance?.result || 0) -
          (a.instrumentDailyPerformance?.result || 0)
        );
      });

    if (sortingMethod.method === SortingMethods.DAYLY_VARIATION)
      return mergedHoldingsData?.sort((a, b) => {
        if (sortingMethod.isAsc)
          return (
            (a.instrumentDailyPerformance?.result_percentage || 0) -
            (b.instrumentDailyPerformance?.result_percentage || 0)
          );

        return (
          (b.instrumentDailyPerformance?.result_percentage || 0) -
          (a.instrumentDailyPerformance?.result_percentage || 0)
        );
      });

    if (sortingMethod.method === SortingMethods.VARIATION)
      return mergedHoldingsData?.sort((a, b) => {
        if (sortingMethod.isAsc)
          return (
            (a.instrumentHistoricPerformance?.result_percentage || 0) -
            (b.instrumentHistoricPerformance?.result_percentage || 0)
          );
        return (
          (b.instrumentHistoricPerformance?.result_percentage || 0) -
          (a.instrumentHistoricPerformance?.result_percentage || 0)
        );
      });

    if (sortingMethod.method === SortingMethods.RESULT)
      return mergedHoldingsData?.sort((a, b) => {
        if (sortingMethod.isAsc)
          return (
            (a.instrumentHistoricPerformance?.result || 0) -
            (b.instrumentHistoricPerformance?.result || 0)
          );

        return (
          (b.instrumentHistoricPerformance?.result || 0) -
          (a.instrumentHistoricPerformance?.result || 0)
        );
      });
  };

  const sortedHoldings = sortHoldings();

  const getTypeName = (type: Instruments, ticker: string) => {
    if (type === Instruments.FCI) return type;
    if (type === Instruments.BONOS_CORP) return "Obligaciones negociables";
    if (type === Instruments.BONOS_PUBLICOS) return "Bonos públicos";
    if (type === undefined) return "Crypto";
    if (ticker === "US$ especie") return "Dólar especie";

    return type ? type.toLocaleLowerCase() : "";
  };

  const groupedByType = sortedHoldings?.reduce((acc: GroupedByType, item) => {
    if (!acc[item.type]) {
      acc[item.type] = {
        instrumentType: item.type,
        type: getTypeName(item.type, item.ticker),
        holdings: [],
        totalAmount: 0,
        totalPercentage: 0,
      };
    }

    acc[item.type].holdings.push({
      ...item,
      dailyPerformance: item.instrumentDailyPerformance?.result,
      totalPerformance: item.instrumentHistoricPerformance?.result,
      averagePrice: item.instrumentHistoricPerformance?.average_price,
      dailyVariation: item.instrumentDailyPerformance?.result_percentage,
      totalVariation: item.instrumentHistoricPerformance?.result_percentage,
    });

    const settlementAmount = item.settlements[2]?.amount || 0;
    acc[item.type].totalAmount += settlementAmount;

    const allocation = item.allocation || 0;
    acc[item.type].totalPercentage += allocation;

    return acc;
  }, {});

  const groupedHolding =
    groupedByType &&
    Object.values(groupedByType).sort((a, b) => b.totalAmount - a.totalAmount);

  const redirectToTrading = (type: string, code: string, ticker: any) => {
    navigate(`${mainRoutes.market}/${type}/${code}`, { state: ticker });
  };

  const QuickPurchaseComponent = (marketProp: any) => (
    <LayoutV2
      title={marketProp.short_ticker}
      withSearch
      quickOperation
      onClickExtended={() =>
        redirectToTrading(
          marketProp.instrument_type,
          marketProp.ticker,
          marketProp
        )
      }
      instrument={marketProp}
    >
      <TradingContainer instrumentProp={marketProp} />
    </LayoutV2>
  );

  const MarketDetailComponent = (selectedId: string, ticker: any) => (
    <DrawerLayout>
      <MarketDetailSidebarDesktop id={selectedId} ticker={ticker} />
    </DrawerLayout>
  );

  const onInstrumentRowClick = (holding: PortfolioHoldings) => {
    const {
      code,
      ticker,
      name,
      type,
      shortName,
      logo,
      longTicker,
      settlements,
      isCrypto,
    } = holding;

    if (ticker === "US$ especie" || isCrypto) {
      return;
    }

    const holdingMock = {
      ...holding,
      amount: settlements[SettlementIndex.INF].amount,
      currency: Currencies.ARS,
      instrument_code: code,
      instrument_name: name,
      instrument_type: type,
      logo_file_name: logo,
      long_ticker: longTicker,
      quantity: settlements[SettlementIndex.INF].quantity,
      short_ticker: shortName,
      ticker,
    };

    if (
      order.instrument_code &&
      ticker &&
      !order.instrument_code.includes(ticker)
    ) {
      resetMarketOrder();
      dispatch(resetOrder());
    }

    if (type !== Instruments.FCI) {
      return openRightModalContent(QuickPurchaseComponent(holdingMock));
    } else {
      const isNewFundsDesktop = isCocosFundRedesign(ticker) ? (
        <CapitalFunds shortName={ticker} />
      ) : (
        MarketDetailComponent(ticker, holdingMock)
      );

      return openRightModalContent(isNewFundsDesktop);
    }
  };

  const resetMarketOrder = () => {
    dispatch(resetMarketDataDetail());
    dispatch(cleanHistoricalData());
    dispatch(clearSellingPower());
  };

  useEffect(() => {
    dispatch(setSubscribeList(subscribeSocketList ?? []));

    return () => {
      dispatch(clearSubscribeList());
    };
  }, [portfolioData, isTableGrouped]);

  if (isLoading || !groupedHolding || !sortedHoldings)
    return <Skeleton height={50} count={10} />;

  if (Object.values(groupedByType).length === 0)
    return <PortfolioEmptyState portfolioState={PortfolioEmpty.INVESTMENT} />;

  return (
    <div>
      <InstrumentsTableHeader
        activeSortingMethod={sortingMethod}
        setSortingMethod={setSortingMethod}
      />
      <div className={styles.instrumentsTableContainer}>
        {isTableGrouped
          ? Object.values(groupedHolding).map((item) => (
              <InstrumentsGroupedRow
                key={item.type}
                type={item.type}
                currency={currency}
                holdings={item.holdings}
                totalAmount={item.totalAmount}
                onClick={onInstrumentRowClick}
                instrumentType={item.instrumentType}
                totalPercentage={item.totalPercentage}
                isSettlementExpanded={isSettlementExpanded}
              />
            ))
          : sortedHoldings?.map((holding) => (
              <div key={holding.ticker}>
                <AssetPortfolioRow
                  dailyVariation={
                    holding.instrumentDailyPerformance?.result_percentage
                  }
                  dailyPerformance={holding.instrumentDailyPerformance?.result}
                  totalPerformance={
                    holding.instrumentHistoricPerformance?.result
                  }
                  totalVariation={
                    holding.instrumentHistoricPerformance?.result_percentage
                  }
                  averagePrice={
                    holding.instrumentHistoricPerformance?.average_price
                  }
                  holding={holding}
                  currency={currency}
                  isSettlementExpanded={isSettlementExpanded}
                  onClick={() => onInstrumentRowClick(holding)}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default InstrumentsTable;
