import { useEffect, useState } from "react";

import { IconButton, Typography } from "@mui/material";
import { FillStar } from "assets/icons/favorite/fill-star";
import { TransparentStar } from "assets/icons/favorite/transparent-star";
import BondCalculator from "components/common/BondCalculator";
import TradingContainer from "components/common/trading-container";
import { InstrumentData } from "interfaces/api-responses";
import { Bonds, Instruments, SettlementTerms } from "interfaces/markets";
import DrawerLayout from "layouts/drawer";
import { BsCalculator } from "react-icons/bs";
import mainRoutes, { screenNames } from "router/routes";
import { getMarketsTypesLists, toggleInList } from "features/markets";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import { trackAction } from "utils/amplitude";
import { getColor } from "utils/getColor";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";
import { openRightModal } from "features/rightModal/rightModalSlice";

import TradingExtended from "../extended";

import styles from "./styles.module.scss";

interface TradingDesktopProps {
  instrument: InstrumentData;
}

const TradingDesktop: React.FC<TradingDesktopProps> = ({ instrument }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { openRightModalContent } = useAppState();
  const [selectedInstrument, setSelectedInstrument] = useState<InstrumentData>(
    instrument
  );
  const { marketDetail } = useAppSelector((state: RootState) => state.markets);
  const gotFlow = useAppSelector((state: RootState) => state.calculator.check);
  const isBond =
    selectedInstrument &&
    (selectedInstrument.instrument_type === Instruments.BONOS_PUBLICOS ||
      selectedInstrument.instrument_type === Instruments.BONOS_CORP);

  const iconColor = getColor("--darkblue-to-light");
  const { shortTickerData } = useAppSelector((state) => state.markets);
  const [isFavorite, setIsFavorite] = useState(
    shortTickerData.some((ticker) => ticker.isFavorite)
  );

  useEffect(() => {
    const isSelectedInstrumentWithSameCurrency =
      marketDetail &&
      marketDetail.instrument_code === selectedInstrument.instrument_code &&
      marketDetail.long_ticker !== selectedInstrument.long_ticker;

    if (isSelectedInstrumentWithSameCurrency) {
      setSelectedInstrument(marketDetail);
    }
  }, []);

  const user = useAppSelector(getLoggedUser);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;
  const { favoritesLists } = user;

  const handleCalcu = async () => {
    try {
      const trackProps = {
        ticker: selectedInstrument?.long_ticker,
      };
      trackAction(`${screenNames.newOrder} - Open Bond Calculator`, trackProps);
      const typeOrPublicBond =
        (selectedInstrument && selectedInstrument.instrument_type) ??
        Instruments.BONOS_PUBLICOS;

      await dispatch(
        getMarketsTypesLists({
          type: typeOrPublicBond,
          currency: undefined,
          settlement_days: SettlementTerms._24,
          subtype:
            typeOrPublicBond === Instruments.BONOS_PUBLICOS
              ? Bonds.BONOSP
              : Bonds.BONOSC,
        })
      );

      searchParams.set(
        "bondType",
        selectedInstrument?.instrument_type as string
      );
      searchParams.set("bond", selectedInstrument?.short_ticker as string);
      setSearchParams(searchParams);

      dispatch(openRightModal({ withCloseButton: false }));
      openRightModalContent(
        <DrawerLayout>
          <BondCalculator />
        </DrawerLayout>
      );
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  const handleToggleFavorite = async (ev: React.MouseEvent) => {
    ev.stopPropagation();
    const trackProps = {
      type: isFavorite ? "remove" : "add",
      ticker: selectedInstrument?.long_ticker,
    };
    trackAction(
      `${
        screenNames[
          selectedInstrument?.instrument_type === Instruments.FCI
            ? "fci"
            : "newOrder"
        ]
      } - Add Favorites`,
      trackProps
    );
    setIsFavorite(!isFavorite);
    if (selectedInstrument) {
      await toggleInList(
        !!isFavorite,
        favoritesLists[0].id_list,
        selectedInstrument.long_ticker
      );
    }
  };

  return (
    <div className={styles.divContainer}>
      <div className={styles.header}>
        <Typography>{selectedInstrument.short_ticker}</Typography>
        <div>
          {gotFlow && isBond && (
            <IconButton onClick={handleCalcu}>
              <BsCalculator
                className="icon-calculadora"
                color={getColor("--text-color-primary")}
                size={26}
              />
            </IconButton>
          )}
          {!isFavorite ? (
            <IconButton
              className="no-favorite"
              id="favorite"
              onClick={handleToggleFavorite}
            >
              <TransparentStar size={20} color={iconColor} />
            </IconButton>
          ) : (
            <IconButton className="favorite" onClick={handleToggleFavorite}>
              <FillStar size={20} color={iconColor} />
            </IconButton>
          )}
        </div>
      </div>
      <div className={styles.body}>
        <TradingExtended instrument={selectedInstrument} />
        <TradingContainer instrumentProp={selectedInstrument} />
      </div>
    </div>
  );
};

export default TradingDesktop;
