import { getTimeData } from "constants/portfolio";

import { usePortfolio } from "context/PortfolioProvider";
import { ThemeVariants } from "interfaces/theme";
import Balance from "components/common/Balance";

import DetailedBalance from "./DetailedBalance";

interface DesktopBalanceProps {
  isLoading: boolean;
  balanceData: {
    totalBalance?: number;
    cashBalance?: number;
    holdingsBalance?: number;
    variation: { amount?: number; percentage: number };
  };
  variant: ThemeVariants;
}

const DesktopBalance: React.FC<DesktopBalanceProps> = ({
  variant,
  isLoading,
  balanceData,
}) => {
  const { selectedCurrency, selectedPeriod } = usePortfolio();

  const { variationText } = getTimeData(selectedPeriod);
  const { totalBalance, variation, holdingsBalance, cashBalance } = balanceData;

  return (
    <>
      <Balance
        variant={variant}
        isLoading={isLoading}
        currency={selectedCurrency}
        available={totalBalance ?? 0}
        variationLabel={variationText}
        variationAmount={variation.amount ?? 0}
        variationPercentage={variation.percentage}
      />
      <DetailedBalance
        isLoading={isLoading}
        cashBalance={cashBalance}
        holdingsBalance={holdingsBalance}
      />
    </>
  );
};

export default DesktopBalance;
