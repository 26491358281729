import { useEffect, useRef, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { X } from "lucide-react";
import { useCryptoCable } from "context/CryptoCableProvider";
import { cryptoService } from "apis/services";
import PageHeader from "components/common/PageHeader";
import { useCocosPay } from "context/CocosPayProvider";

import LoadingScreen from "../Loading";

import styles from "./styles.module.scss";

const CreateAccount: React.FC = () => {
  const { setIsCreationInProgress, goToHome } = useCryptoCable();
  const { selectedMethod } = useCocosPay();

  const [iFrameUrl, setiFrameUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const frameRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const getBridgeUrl = async () => {
      setIsLoading(true);
      try {
        const { data } = await API.post<{ url: string }>(
          cryptoService.fiatExternalCustomerTos
        );
        setiFrameUrl(data.url);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getBridgeUrl();
  }, []);

  useEffect(() => {
    window.onmessage = (event) => {
      if (event.data && event.data.signedAgreementId) {
        const acceptBridgeTos = async () => {
          try {
            await API.post<{ signedAgreementId: string }>(
              cryptoService.fiatExternalCustomerV2,
              {
                signedAgreementId: event.data.signedAgreementId,
                paymentMethod: selectedMethod?.name,
              }
            );
          } catch (error) {
            console.error(error);
          } finally {
            setIsCreationInProgress(true);
          }
        };

        acceptBridgeTos();
      }
    };
  }, []);

  const onClose = () => {
    ampli.cclCryptoExit({ referral: "tyc" });
    goToHome();
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <div className={styles.iframeWrapper}>
      <PageHeader
        title={"Aceptá los Términos"}
        onClick={onClose}
        icon={X}
        className={styles.title}
      />
      <iframe src={iFrameUrl} width="100%" height="100%" ref={frameRef} />
    </div>
  );
};

export default CreateAccount;
