import { useEffect, useState } from "react";

import { Capacitor } from "@capacitor/core";
import { Factor } from "@supabase/supabase-js";
import Cocos2FAPage from "components/page-2fa";
import { useProfile } from "context/ProfileProvider";
import { useBiometry } from "hooks/useBiometry";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { MFAType } from "interfaces/auth";
import { KeySquare, ScanFace, ShieldCheck } from "lucide-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { RootState } from "store/store";
import { supabase } from "utils/supabase";

import ProfileSectionTemplate from "../components/SectionTemplate";

const ProfileSecurity: React.FC = () => {
  const sectionTitle = "Seguridad";

  const navigate = useNavigate();
  const { isNativeBiometricUnavailable, biometricsSignIn } = useBiometry();
  const { isMobile } = useDeviceCheck();
  const { breadcrumbList, addBreadcrumbOption } = useProfile();

  const [factors, setFactors] = useState<Factor[]>([]);
  const twoFAColor = factors.length > 0 ? "var(--green800)" : "var(--red800)";
  const [loading, setLoading] = useState<boolean>(true);

  const twoFATitle = "2FA";

  const signInWithBiometrics = useSelector((state: RootState) => {
    return Boolean(state.user.user?.signInWithBiometrics);
  });

  const checkSignInWithBiometrics = async () => {
    if (await isNativeBiometricUnavailable()) {
      return;
    }

    const isEnabled = await biometricsSignIn.isEnabled();
    await handleSignInWithBiometrics(!!isEnabled);
  };

  const handleSignInWithBiometrics = async (signInWithBiometrics: boolean) => {
    await biometricsSignIn.setEnabled(signInWithBiometrics);
  };

  const handleOpen2FA = () => {
    if (isMobile) {
      navigate(mainRoutes.cocos2FA);
      return;
    }
    addBreadcrumbOption(twoFATitle);
  };

  const sectionOptions = [
    {
      icon: KeySquare,
      label: "Verificación en 2 pasos",
      onClick: handleOpen2FA,
      rightIcon: ShieldCheck,
      rightIconColor: twoFAColor,
    },
    {
      icon: ScanFace,
      label: "Iniciar sesión con biometría",
      onClick: () => handleSignInWithBiometrics(!signInWithBiometrics),
      toggleValue: signInWithBiometrics,
      show: Capacitor.isNativePlatform(),
    },
  ];

  useEffect(() => {
    (async () => {
      const { data } = await supabase.auth.mfa.listFactors();

      if (data) {
        const factors = data.all.filter(
          (x) => x.status === "verified" && x.factor_type === MFAType.TOTP
        );

        setFactors(factors);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    checkSignInWithBiometrics();
  }, []);

  if (breadcrumbList.at(-1) === twoFATitle) return <Cocos2FAPage />;

  return (
    <ProfileSectionTemplate
      title={sectionTitle}
      subtitle={isMobile ? sectionTitle : undefined}
      options={sectionOptions}
      loading={loading}
      withHeader={isMobile}
    />
  );
};

export default ProfileSecurity;
