import px2rem from "utils/px2rem";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { LucideIcon } from "lucide-react";
import { IconProps } from "assets/icons/BTC";

import styles from "./styles.module.scss";

interface AssetProps {
  icon: LucideIcon | React.FC<IconProps>;
  title: string;
  subtitle?: string;
  onClick?: () => void;
}

const Asset: React.FC<AssetProps> = ({
  icon: Icon,
  title,
  subtitle,
  onClick,
}) => {
  return (
    <div className={styles.assetContainer} onClick={onClick}>
      <div className={styles.icon}>
        <Icon size={px2rem(16)} color="var(--slate900)" />
      </div>
      <div className={styles.text}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate900)"
          component="h3"
        >
          {title}
        </Heading>
        {subtitle && (
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            {subtitle}
          </Text>
        )}
      </div>
    </div>
  );
};

export default Asset;
