import { useEffect, useState } from "react";

import { OrderDetailSidebarDesktop } from "components/common/order-detail-sidebar";
import { ConfirmCaucionOrderContainer } from "components/page-market/components/Caucion/confirm-container";
import {
  clearCaucionError,
  getOrderById,
  resetCaucionOrder,
} from "features/markets";
import { RootState } from "store/store";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

interface PropTypes {
  onCloseSidebar: () => void;
}

export const ConfirmCaucionOrder = (props: PropTypes) => {
  const { onCloseSidebar } = props;
  const dispatch = useAppDispatch();
  const caucionOrder = useAppSelector(
    (state: RootState) => state.markets.caucionOrder
  );
  const [orderDetail, setOrderDetail] = useState<string | undefined>();
  const getOrderLoading = useAppSelector(
    (state: RootState) => state.markets.getOrderLoading
  );

  useEffect(() => {
    return () => {
      if (caucionOrder.id) {
        dispatch(resetCaucionOrder());
      }
      dispatch(clearCaucionError());
    };
  }, []);

  const handleClickOrderDetail = (orderId: string) => {
    dispatch(getOrderById(orderId));
    setOrderDetail(orderId);
  };

  if (orderDetail && !getOrderLoading) {
    return (
      <OrderDetailSidebarDesktop
        id={orderDetail}
        onCloseSidebar={onCloseSidebar}
      />
    );
  }

  return (
    <div className="caucion-confirm-order-sidebar">
      <ConfirmCaucionOrderContainer
        onClickOrderDetail={handleClickOrderDetail}
        onClose={onCloseSidebar}
      />
    </div>
  );
};
