import ChatPage from "components/page-profile/page-help/page-chat";
import { ProfileProvider } from "context/ProfileProvider";

const Chat: React.FC = () => (
  <ProfileProvider>
    <ChatPage />
  </ProfileProvider>
);

export default Chat;
