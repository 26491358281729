import InvestmentSuggestionContainer from "../common/investment-suggestion";

import "./index.scss";

const InvestSuggestPage = () => {
  return (
    <div className="investment-suggestion-view">
      <InvestmentSuggestionContainer />
    </div>
  );
};

export default InvestSuggestPage;
