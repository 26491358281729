import { useState } from "react";

import { Info } from "lucide-react";
import AvailableLabel from "components/common/AvailableLabel";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";

import AmountInfoBottomSheetProps from "./AmountInfoBottomSheet";

import styles from "./styles.module.scss";

interface AvailableAmountProps {
  amountAvailable: number;
}

const AvailableAmount: React.FC<AvailableAmountProps> = ({
  amountAvailable,
}) => {
  const [openInfoSheet, setOpenInfoSheet] = useState<boolean>(false);

  return (
    <div className={styles.availableAmountContainer}>
      <AvailableLabel
        withHideButton
        available={amountAvailable}
        variant={ThemeVariants.Pay}
      />
      <Text
        variant={TextVariants.RegularTextS}
        className={styles.availableAmountDisclaimer}
      >
        disponible para gastar{" "}
        <Info size={12} onClick={() => setOpenInfoSheet(true)} />
      </Text>

      {openInfoSheet && (
        <AmountInfoBottomSheetProps
          open={openInfoSheet}
          onClose={() => setOpenInfoSheet(false)}
        />
      )}
    </div>
  );
};

export default AvailableAmount;
