import { useEffect, useRef, useState } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import { X } from "lucide-react";
import { useNavigate } from "react-router";
import { updateTag } from "features/user/userSlice";
import { useAppDispatch } from "hooks/redux";

import { MAX_TAG_LENGTH, checkValidCharacter } from "../utils";
import TagInput from "./TagInput";

import styles from "./styles.module.scss";

const ClaimTag: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const invalidCharacter = !checkValidCharacter(value);

  const disabled =
    value === "" || value.length > MAX_TAG_LENGTH || invalidCharacter;

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const onClickCreate = async () => {
    try {
      setError(false);
      const { data } = await API.put<{ tag: string }>(
        `${cryptoService.tag}/${value}`
      );

      if (data) {
        dispatch(updateTag(data.tag));
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <div className={styles.ClaimTagContainer}>
      <div className={styles.wrapper}>
        <PageHeader
          className={styles.title}
          title="Cocostag"
          icon={X}
          onClick={() => navigate(-1)}
        />
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h1"
          color="var(--slate900)"
        >
          Creá tu cocostag
        </Heading>
        <Text
          className={styles.detail}
          variant={TextVariants.RegularTextL}
          color="var(--slate800)"
        >
          Con cocostag tenés un nombre único al que cualquiera puede enviarte
          crypto sin costo dentro de Cocos.
        </Text>
        <div>
          <TagInput value={value} onChange={setValue} ref={inputRef} />
          {invalidCharacter && (
            <Text
              className={styles.info}
              variant={TextVariants.RegularTextS}
              color="var(--red800)"
            >
              {`Tu cocostag sólo puede contener letras (excluyendo la 'ñ'), números, guiones medios ('-') y puntos ('.').`}
            </Text>
          )}
        </div>

        {error && (
          <Text
            className={styles.error}
            variant={TextVariants.RegularTextS}
            color="var(--red800)"
          >
            El nombre que elegiste ya fue tomado. Por favor, intentá con otro
            nombre nuevamente.
          </Text>
        )}
      </div>
      <FilledButton
        disabled={disabled}
        variant={ThemeVariants.Crypto}
        onClick={onClickCreate}
      >
        Continuar
      </FilledButton>
    </div>
  );
};

export default ClaimTag;
