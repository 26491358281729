import { useState } from "react";

import { Typography } from "@mui/material";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import dayjs from "dayjs";
import { getCurrentScreen } from "features/global/globalSlice";
import { useAppSelector } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { SettlementTerms } from "interfaces/markets";
import { MovementsTypes, Source } from "interfaces/movements";
import { Currencies } from "interfaces/wallet";
import { CreditCard, DownloadIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import {
  formatToCurrency,
  formatToCurrencyWithSymbol,
  getCurrencyLabel,
} from "utils";
import { trackAction } from "utils/amplitude";
import classNames from "classnames";
import px2rem from "utils/px2rem";
import API from "apis";
import Toast, { ToastVariants } from "components/common/Toast";

import styles from "./styles.module.scss";

interface MovementsRowProps {
  id_ticket: number;
  has_ticket_pdf: boolean;
  logoFile?: string;
  instrumentType?: string;
  name?: string;
  operationType: string;
  amount?: number;
  price?: number;
  quantity: number;
  total?: number;
  settlement?: SettlementTerms;
  currency?: Currencies;
  instrumentCode?: string;
  isTicker?: boolean;
  isTickerDetail?: boolean;
  executionDate: string;
  settlementDate: string;
  section?: MovementsTypes;
  source?: Source;
}

const MovementsRow: React.FC<MovementsRowProps> = ({
  id_ticket,
  has_ticket_pdf,
  logoFile,
  instrumentType,
  name,
  operationType,
  price,
  quantity,
  total,
  currency,
  instrumentCode,
  amount,
  executionDate,
  settlementDate,
  isTicker = false,
  isTickerDetail = false,
  section,
  source,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();
  const currentScreen = useAppSelector(getCurrentScreen);
  const [isErrorPDF, setIsErrorPDF] = useState(false);

  const isCardMovement = source && source === Source.CARD;
  const isClickeable = instrumentCode && instrumentType && !isTickerDetail;

  const formattedDate = (date: string) =>
    dayjs(date)
      .locale("es-mx")
      .format("DD MMM YYYY")
      .replace(/^(.{3})(.)/, (match, group1, group2) => {
        return group1 + group2.toUpperCase();
      });

  const getClass = (value: number) => {
    if (value <= 0) return `${styles.negative}`;
    return `${styles.positive}`;
  };

  const handleOnClick = () => {
    sessionStorage.setItem("scroll", window.scrollY.toString());
    if (section) {
      sessionStorage.setItem("movements_section", section);
    }
    trackAction(`${currentScreen} - Click instrument`, {
      instrument: instrumentCode,
      currency,
      operationType,
      quantity: Math.abs(quantity) || undefined,
      amount: Math.abs(amount || 0) || undefined,
    });

    navigate(`${mainRoutes.movements}/${instrumentCode}`);
  };

  const onDownloadClick = async (e: any, idTicker: number) => {
    e.preventDefault();
    e.stopPropagation();
    setIsErrorPDF(false);

    try {
      const { data } = await API.get(`v1/wallet/tickets/${idTicker}/download`, {
        responseType: "blob",
      });

      const blob = new Blob([data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${name}-${operationType}-${idTicker}.pdf`;

      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setIsErrorPDF(true);
      console.error("Error downloading PDF:", error);
    }
  };

  const quantityFormatted = formatToCurrency(quantity, quantity % 1 === 0); // Hide decimals if they are 00

  const getIcon = () => {
    if (isCardMovement) {
      return <CreditCard width={48} height={48} color="var(--blue-to-lb)" />;
    }
    if (!isTickerDetail) {
      return (
        <InstrumentLogoUI
          logoFileName={logoFile}
          type={instrumentType}
          variant={InstrumentLogoVariants.Primary}
        />
      );
    }
    return null;
  };

  return (
    <div
      className={classNames(styles.cashRow, {
        [styles.tickerRow]: isTicker,
        [styles.tickerDetailRow]: isTickerDetail,
        [styles.detailClickeable]: !isClickeable,
      })}
      onClick={isClickeable ? handleOnClick : undefined}
    >
      {isMobile && (
        <>
          {getIcon()}
          <Typography className={styles.secondCol}>
            {operationType}
            {name && <span>{name}</span>}
          </Typography>
          {isTickerDetail && amount && (
            <Typography className={styles.thirdCol}>
              {price && currency && (
                <span>
                  {getCurrencyLabel(currency)}&nbsp;
                  {formatToCurrency(price)}
                </span>
              )}
              <span>
                {getCurrencyLabel(currency)}&nbsp;
                {formatToCurrency(amount)}
              </span>
            </Typography>
          )}
          <Typography
            className={`${styles.thirdCol} ${getClass(quantity ?? 0)}`}
          >
            {isTicker
              ? quantityFormatted
              : formatToCurrencyWithSymbol(quantity, currency, false, true)}
            {Boolean(!isTickerDetail && price && currency) && (
              <span>
                {getCurrencyLabel(currency)}&nbsp;
                {formatToCurrency(price)}
              </span>
            )}
          </Typography>
        </>
      )}

      {!isMobile && (
        <>
          <Typography className={styles.date}>
            {formattedDate(executionDate)}
          </Typography>
          <Typography className={styles.date}>
            {formattedDate(settlementDate)}
          </Typography>

          {!isTickerDetail && (
            <div className={styles.nameContainer}>
              <InstrumentLogoUI
                logoFileName={logoFile}
                type={instrumentType}
                variant={InstrumentLogoVariants.Primary}
              />
              <Typography className={styles.name}>{name}</Typography>
            </div>
          )}
          <Typography>{operationType}</Typography>
          {isTicker && (
            <Typography
              className={`${getClass(quantity ?? 0)} ${styles.numbers}`}
            >
              {quantityFormatted}
            </Typography>
          )}
          {isTicker && (
            <Typography className={styles.numbers}>
              <span>
                {getCurrencyLabel(currency)}&nbsp;
                {formatToCurrency(price)}
              </span>
            </Typography>
          )}
          <div
            className={classNames({ [styles.downloadColumn]: has_ticket_pdf })}
          >
            <Typography
              className={`${!isTicker && getClass(amount ?? total ?? 0)} ${
                styles.numbers
              }`}
            >
              {formatToCurrencyWithSymbol(
                amount ?? total ?? 0,
                currency,
                false,
                true
              )}
            </Typography>
            {has_ticket_pdf && (
              <div
                className={styles.downloadIcon}
                onClick={(e) => onDownloadClick(e, id_ticket)}
              >
                <DownloadIcon size={px2rem(16)} />
              </div>
            )}
          </div>
          {isErrorPDF && (
            <Toast
              message="Error al descargar el comprobante, por favor intente nuevamente."
              variant={ToastVariants.Error}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MovementsRow;
