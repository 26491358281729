import { CurrencyCodes, CurrencyValues } from "interfaces/movements";

export const getInstrumentName = (code?: string) => {
  if (!code) return null;

  if (code === CurrencyCodes.PESOS) return CurrencyValues.ARS;

  if (code === CurrencyCodes.DOLARES) return CurrencyValues.USD;

  return code;
};
