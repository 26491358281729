import { useState } from "react";

import API from "apis";
import { X } from "lucide-react";
import classNames from "classnames";
import { transfersService } from "apis/services";
import { useCapitalSendAccounts } from "context/CapitalSendAccountsProvider";
import Text, { TextVariants } from "components/common/Text";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";

import styles from "./styles.module.scss";

interface ConfirmDeleteProps {
  isIOS: boolean;
  onClose: () => void;
  idBankAccount: string;
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({
  isIOS,
  onClose,
  idBankAccount,
}) => {
  const { getAccounts, setDisplayToast } = useCapitalSendAccounts();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDeleteAccount = async () => {
    try {
      setIsLoading(true);
      await API.delete(transfersService.accounts, {
        data: {
          idBankAccount,
        },
      });
      getAccounts();
      setDisplayToast({
        visible: true,
        message: "Cuenta eliminada exitosamente",
      });
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };
  return (
    <div className={styles.confirmDeleteContainer}>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.title}
      >
        Eliminar cuenta
      </Text>
      <div className={styles.iconContainer}>
        <X color="var(--red900)" />
      </div>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.title}
      >
        ¿Estás seguro que querés eliminar esta cuenta de banco?
      </Text>
      <div
        className={classNames(styles.buttonsWrapper, { [styles.ios]: isIOS })}
      >
        <FilledButton onClick={handleDeleteAccount} isLoading={isLoading}>
          Confirmar
        </FilledButton>
        <OutlinedButton onClick={onClose}>Cancelar</OutlinedButton>
      </div>
    </div>
  );
};

export default ConfirmDelete;
