import Divider from "components/common/Divider";
import Heading, { HeadingVariants } from "components/common/Heading";
import Currency from "components/common/Text/Currency";
import { getFCIIconColor, getIcon } from "components/page-cocos-pay/utils";
import dayjs from "dayjs";
import { PaymentCurrencies } from "interfaces/pay/enums";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface ReceiptDetailProps {
  businessName: string;
  quantity: number;
  createdAt: string;
  currency: PaymentCurrencies;
  idPayment: string;
  idPaymentShort: string;
  idBankTransaction?: string;
}

const ReceiptDetail: React.FC<ReceiptDetailProps> = ({
  businessName,
  quantity,
  createdAt,
  currency,
  idPayment,
  idPaymentShort,
  idBankTransaction,
}) => {
  const fontColor = "var(--slate900)";
  const Icon = getIcon(currency);

  const date = dayjs(createdAt).format("DD/MM/YYYY");

  const time = dayjs(createdAt).format("HH:mm");

  return (
    <div className={styles.receiptDetailContainer}>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={fontColor}
          component="span"
        >
          Pagaste a
        </Heading>
        <Heading
          className={styles.businessName}
          variant={HeadingVariants.RegularSubtitle}
          color={fontColor}
          component="span"
        >
          {businessName}
        </Heading>
      </div>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={fontColor}
          component="span"
        >
          Monto
        </Heading>
        <Currency
          className={styles.amount}
          color={fontColor}
          amount={quantity}
        />
      </div>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={fontColor}
          component="span"
        >
          Fecha
        </Heading>
        <Heading
          variant={HeadingVariants.SemiboldValueS}
          color={fontColor}
          component="span"
        >
          {date} - {time}
        </Heading>
      </div>
      <div className={styles.row}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={fontColor}
          component="span"
        >
          Pagaste con
        </Heading>
        <Heading
          variant={HeadingVariants.RegularValueS}
          color={fontColor}
          component="span"
          className={styles.currency}
        >
          <Icon size={px2rem(16)} color={getFCIIconColor(currency)} />{" "}
          {currency}
        </Heading>
      </div>
      <Divider className={styles.divider} />
      <div>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          component="h3"
          color={fontColor}
        >
          Comprobante de pago N°
        </Heading>
        <Heading
          className={styles.id}
          variant={HeadingVariants.SemiboldSubtitle}
          component="h3"
          color={fontColor}
        >
          {idPaymentShort}
        </Heading>
      </div>
      {idBankTransaction && (
        <div>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h3"
            color={fontColor}
          >
            Comprobante bancario N°
          </Heading>
          <Heading
            className={styles.id}
            variant={HeadingVariants.SemiboldSubtitle}
            component="h3"
            color={fontColor}
          >
            {idBankTransaction}
          </Heading>
        </div>
      )}
    </div>
  );
};

export default ReceiptDetail;
