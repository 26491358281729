import { getFirstSubtypeCode } from "constants/instruments";

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Instruments,
  MarketParams,
  MarketSection,
  Sections,
  SettlementTerms,
} from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { useAppSelector } from "hooks/redux";
import { Navigate, useParams } from "react-router-dom";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import mainRoutes from "router/routes";

interface Context {
  selectedInstrumentType: MarketSection;
  currency: Currencies;
  setCurrency: (v: Currencies) => void;
  settlementDays: SettlementTerms;
  setSettlementDays: (v: SettlementTerms) => void;
  available?: number | null;
  selectedInstrumentSubtype: string | null | undefined;
  setSelectedInstrumentSubtype: (v: string) => void;
  filterFlag: boolean;
  setFilterFlag: (v: boolean) => void;
  loading: boolean;
  setLoading: (v: boolean) => void;
}

const MarketContext = createContext<Context | null>(null);

export const MarketProvider = ({ children }: { children: ReactNode }) => {
  const { instrument_type } = useParams<MarketParams>();

  const user = useAppSelector(getLoggedUser);
  const { wallet } = useAppSelector((state: RootState) => state.wallet);

  const selectedInstrumentType = instrument_type || Instruments.ACCIONES;

  const getInstrumentSubtype = () => {
    if (
      instrument_type === Sections.FAVORITOS ||
      instrument_type === Sections.HOME
    ) {
      return user?.favoritesLists[0].id_list;
    }
    return getFirstSubtypeCode(instrument_type);
  };

  const [selectedInstrumentSubtype, setSelectedInstrumentSubtype] = useState(
    getInstrumentSubtype()
  );
  const [currency, setCurrency] = useState<Currencies>(Currencies.ARS);
  const [settlementDays, setSettlementDays] = useState<SettlementTerms>(
    SettlementTerms._24
  );
  const [filterFlag, setFilterFlag] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getAvailable = () => {
    if (!wallet) {
      return 0;
    }
    switch (currency) {
      case Currencies.ARS:
        return Number(wallet[settlementDays].ars);
      case Currencies.USD:
        return Number(wallet[settlementDays].usd);
      case Currencies.EXT:
        return Number(wallet[settlementDays].ext);
      default:
        return 0;
    }
  };

  const [available, setAvailable] = useState(getAvailable());

  useEffect(() => {
    if (wallet) {
      setAvailable(getAvailable());
    }
  }, [wallet, currency, settlementDays]);

  useEffect(() => {
    setSelectedInstrumentSubtype(getInstrumentSubtype());
  }, [instrument_type]);

  const memoizedValues = useMemo(() => {
    return {
      selectedInstrumentType,
      currency,
      setCurrency,
      settlementDays,
      setSettlementDays,
      available,
      selectedInstrumentSubtype,
      setSelectedInstrumentSubtype,
      filterFlag,
      setFilterFlag,
      loading,
      setLoading,
    };
  }, [
    selectedInstrumentType,
    currency,
    setCurrency,
    settlementDays,
    setSettlementDays,
    available,
    selectedInstrumentSubtype,
    setSelectedInstrumentSubtype,
    filterFlag,
    setFilterFlag,
    loading,
    setLoading,
  ]);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  return (
    <MarketContext.Provider value={memoizedValues}>
      {children}
    </MarketContext.Provider>
  );
};

export default MarketContext;

export const useMarket = () => {
  const context = useContext(MarketContext);
  if (!context) {
    throw new Error("[MarketContext] Missing context");
  }
  return context;
};
