import { FlowAnalysis, InvestmentSuggestedResponse } from "interfaces/markets";

import BondTicketBottom from "./components/BondTicketBottom";
import BondTicketTop from "./components/BondTicketTop";

import styles from "./styles.module.scss";

interface BondDataProps {
  instrument: InvestmentSuggestedResponse;
  analysis: FlowAnalysis;
}

const BondData: React.FC<BondDataProps> = ({ instrument, analysis }) => {
  return (
    <div className={styles.bondData}>
      <BondTicketTop instrument={instrument} />
      <BondTicketBottom analysis={analysis} />
    </div>
  );
};

export default BondData;
