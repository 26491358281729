import { useEffect, useState } from "react";

import API from "apis";
import { Limit, LimitPeriod } from "interfaces/api-responses";
import { cardService } from "apis/services";
import { Currency } from "components/common/currency";
import LoadingSpinner from "components/common/LoadingSpinner";
import { UIModalSlider } from "components/common/modal-slider";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

type AmountInfoBottomSheetPropsProps = {
  open: boolean;
  onClose: () => void;
};

const AmountInfoBottomSheetProps: React.FC<AmountInfoBottomSheetPropsProps> = ({
  open,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cardLimit, setCardLimit] = useState<number>(0);

  useEffect(() => {
    setIsLoading(true);

    const getLimit = async () => {
      try {
        const { data } = await API.get<Limit[]>(cardService.limits);
        const dailyLimit = data.filter(
          (limit) => limit.period === LimitPeriod.DAILY
        )[0].limit;

        setCardLimit(dailyLimit);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getLimit();
  }, []);

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      {isLoading ? (
        <LoadingSpinner size={120} variant={ThemeVariants.Pay} />
      ) : (
        <>
          <div className={styles.header}>
            <Text
              variant={TextVariants.RegularText}
              className={styles.headerTitle}
            >
              Disponible para gastar
            </Text>
          </div>
          <Text variant={TextVariants.RegularText} className={styles.text}>
            Monto disponible para gastar combinando los métodos de pago que
            elegiste.
          </Text>
          {cardLimit && (
            <Text variant={TextVariants.RegularText} className={styles.text}>
              Tenés un límite de consumo diario de ${" "}
              <Currency withoutDecimals>{cardLimit}</Currency>. Cualquier
              consumo superior a tu límite o a tu disponible será rechazado.
            </Text>
          )}
          <FilledButton
            variant={ThemeVariants.Pay}
            onClick={onClose}
            className={styles.button}
          >
            Entendido
          </FilledButton>
        </>
      )}
    </UIModalSlider>
  );
};

export default AmountInfoBottomSheetProps;
