import { IconProps } from "assets/icons/BTC";

import styles from "./styles.module.scss";

const DownloadApp: React.FC<IconProps> = ({ size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 57 68"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_15196_167011)">
      <path
        d="M50.6714 0H19.8114C17.3537 0 15.3613 1.99237 15.3613 4.45009V63.5499C15.3613 66.0076 17.3537 68 19.8114 68H50.6714C53.1292 68 55.1215 66.0076 55.1215 63.5499V4.45009C55.1215 1.99237 53.1292 0 50.6714 0Z"
        fill="#0588F0"
      />
      <path
        d="M55.1215 7.46289H15.3613V60.3208H55.1215V7.46289Z"
        className={styles.line}
      />
      <path
        d="M55.1216 14.1821C55.5573 14.1821 55.9753 14.3552 56.2834 14.6634C56.5915 14.9715 56.7646 15.3894 56.7646 15.8252V22.6568C56.7646 23.0926 56.5915 23.5105 56.2834 23.8186C55.9753 24.1268 55.5573 24.2999 55.1216 24.2999V14.1821Z"
        fill="#0588F0"
      />
      <path
        d="M31.1313 4.09229H39.8689"
        stroke="#1C2024"
        strokeWidth="0.641656"
        strokeMiterlimit="10"
      />
      <path
        d="M35.2427 66.1094C36.3106 66.1094 37.1763 65.2437 37.1763 64.1758C37.1763 63.1079 36.3106 62.2422 35.2427 62.2422C34.1748 62.2422 33.3091 63.1079 33.3091 64.1758C33.3091 65.2437 34.1748 66.1094 35.2427 66.1094Z"
        className={styles.line}
      />
      <path
        d="M18.3823 16.5C8.4412 16.5 0.382323 24.5589 0.382322 34.5C0.382321 44.4411 8.4412 52.5 18.3823 52.5C28.3234 52.5 36.3823 44.4411 36.3823 34.5C36.3823 24.5589 28.3235 16.5 18.3823 16.5Z"
        fill="#3DD68C"
      />
      <path
        d="M26.3413 35.3418L18.3992 43.2839L10.4595 35.3418"
        stroke="#1C2024"
        strokeWidth="0.831983"
        strokeMiterlimit="10"
      />
      <path
        d="M18.3989 43.2836L18.3989 26.2017"
        stroke="#1C2024"
        strokeWidth="0.831983"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_15196_167011">
        <rect width="56.765" height="68" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DownloadApp;
