import { Instruments, InvestmentSuggestedResponse } from "interfaces/markets";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface BondTicketTopProps {
  instrument: InvestmentSuggestedResponse;
}

const BondTicketTop: React.FC<BondTicketTopProps> = ({ instrument }) => {
  return (
    <div className={styles.topCard}>
      <div className={styles.logo}>
        <InstrumentLogoUI
          logoFileName={instrument.logo_file_name}
          type={Instruments.BONOS_CORP}
          variant={InstrumentLogoVariants.Primary}
        />
      </div>
      <Text variant={TextVariants.SemiboldTextL} className={styles.bondCode}>
        {instrument.instrument_code}
      </Text>
      <Text variant={TextVariants.RegularTextS}>{instrument.name}</Text>
    </div>
  );
};

export default BondTicketTop;
