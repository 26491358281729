import React, { useEffect } from "react";

import { ThemeVariants } from "interfaces/theme";
import LoadingSpinner from "components/common/LoadingSpinner";
import { useAuth } from "context/AuthProvider";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import CryptoRoute from "router/CryptoRoute";
import { supabase } from "utils/supabase";
interface PrivateRouteProps {
  component: React.FC;
  isCryptoRoute?: boolean;
}

const RequireAuth: React.FC<PrivateRouteProps> = ({
  component: Component,
  isCryptoRoute,
}) => {
  const {
    pendingPhoneSignUp,
    isAuthenticated,
    pending2FA,
    loading,
  } = useAuth();
  const location = useLocation();
  const isCrypto = location.pathname.includes(CryptoRoutes.HOME);
  const navigate = useNavigate();

  const pedingAuthStep = !isAuthenticated || pending2FA || pendingPhoneSignUp;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (event === "SIGNED_OUT" || !session) {
          navigate(mainRoutes.logout);
        }
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const loadingVariant = isCrypto
    ? ThemeVariants.Crypto
    : ThemeVariants.Capital;

  if (loading) {
    return (
      <div
        style={{
          height: "100%",
          backgroundColor: `var(--${isCrypto ? "slate50" : "background-2"})`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner variant={loadingVariant} />
      </div>
    );
  }

  if (pedingAuthStep) {
    return <Navigate replace to="/login" />;
  }

  if (isCryptoRoute) {
    return (
      <CryptoRoute>
        <Component />
      </CryptoRoute>
    );
  }

  return <Component />;
};

export default RequireAuth;
