import { isEmpty } from "lodash";
import { Barcode } from "@capacitor-mlkit/barcode-scanning";

const isDNIQrCodeValid = (barcodes: Barcode[]): boolean => {
  const NUMBER_OF_FIELDS_REQUIRED = 9;

  return (
    !isEmpty(barcodes) &&
    barcodes[0].rawValue?.split("@")?.length === NUMBER_OF_FIELDS_REQUIRED
  );
};

export default isDNIQrCodeValid;
