import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cardService } from "apis/services";
import px2rem from "utils/px2rem";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import OutlinedButton from "components/common/OutlinedButton";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import Text, { TextVariants } from "components/common/Text";
import Currency from "components/common/Text/Currency";
import { UIModalSlider } from "components/common/modal-slider";
import {
  getFCIIconColor,
  getIcon,
  isUSD,
} from "components/page-cocos-pay/utils";
import { useCocosCard } from "context/CocosCardProvider";
import CardErrorScreen from "components/page-cocos-card/Error";
import {
  CardSections,
  CardStatus,
  CardTypes,
  UserStatus,
} from "interfaces/card/enums";
import { ThemeVariants } from "interfaces/theme";
import { Currencies } from "interfaces/wallet";
import { PaymentMethod } from "interfaces/pay/interface";

import styles from "./styles.module.scss";

interface PayMethodConfirm {
  selectedMethod?: PaymentMethod;
  setSelectedMethod: (v?: PaymentMethod) => void;
  shippingAmount: number;
}

const PayMethodConfirm: React.FC<PayMethodConfirm> = ({
  selectedMethod,
  setSelectedMethod,
  shippingAmount,
}) => {
  const {
    setSelectedSection,
    shippingInfo,
    pomeloData,
    getPomeloData,
    virtualCard,
    userStatus,
  } = useCocosCard();

  const [isCardRequested, setIsCardRequested] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (isCardRequested) {
      if (virtualCard?.status === CardStatus.ACTIVE) {
        setIsLoading(false);
        setSelectedSection(CardSections.PHYSICAL_READY);
      } else if (userStatus === UserStatus.FAILED) {
        ampli.cardPhysicalCreationFailed();
        setIsLoading(false);
        setError(true);
      }

      const interval = setInterval(() => {
        getPomeloData();
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [userStatus, isCardRequested]);

  if (!selectedMethod) {
    return null;
  }

  const { name, amountAvailableArs, amountAvailable, amount } = selectedMethod;

  const isUSDCurrency = isUSD(name);
  const amountAvailableToUse = isUSDCurrency
    ? amountAvailable
    : amountAvailableArs;
  const payCurrency = isUSDCurrency ? Currencies.USD : Currencies.ARS;
  const amountToPay = isUSDCurrency ? amount : shippingAmount;

  const Icon = getIcon(name);

  const paymenteCurrency = isUSDCurrency ? Currencies.USD : Currencies.ARS;

  const slate600 = "var(--slate600)";
  const slate800 = "var(--slate800)";
  const slate900 = "var(--slate900)";

  const onClose = () => {
    setSelectedMethod();
  };

  const handleConfirm = async () => {
    ampli.cardCreationConfirmPayment({
      method: name,
      amount_ars: amountToPay,
    });
    setIsLoading(true);

    try {
      const endpoint = pomeloData ? cardService.requestCard : cardService.user;

      const requestCardCreation = pomeloData
        ? [CardTypes.PHYSICAL]
        : [CardTypes.PHYSICAL, CardTypes.VIRTUAL];

      const payload = {
        requestCardCreation,
        shippingInformation: { ...shippingInfo, country: "ARG" },
        paymentMethod: name,
      };

      await API.post(endpoint, payload);
      await getPomeloData();
      setIsCardRequested(true);
    } catch (e) {
      setError(true);
    }
  };

  if (error) return <CardErrorScreen />;

  return (
    <UIModalSlider
      open={true}
      onToggleDisplay={onClose}
      classes={styles.payMethodConfirmContainer}
    >
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        color={slate900}
        component="h2"
      >
        Confirmar pago
      </Heading>
      <div className={styles.header}>
        <Icon size={px2rem(32)} color={getFCIIconColor(name)} />
        <PriceLabel
          currency={paymenteCurrency}
          price={amountToPay}
          size={PriceLabelSizes.Default}
        />
      </div>
      <div className={styles.confirmBottomSheetDetail}>
        <div className={styles.row}>
          <Text variant={TextVariants.RegularText} color={slate800}>
            Disponible
          </Text>
          <Currency
            ticker={payCurrency}
            amount={amountAvailableToUse}
            color={slate900}
          />
        </div>
        {isUSDCurrency && selectedMethod?.exchangeRate && (
          <div className={styles.row}>
            <Text variant={TextVariants.RegularText} color={slate800}>
              Cotizacion
            </Text>
            <Text
              className={styles.rate}
              variant={TextVariants.SemiboldUpperValue}
              color={slate800}
            >
              <Currency
                ticker={name}
                amount={1}
                withoutDecimals
                color={slate900}
              />
              ≈
              <Currency
                amount={selectedMethod?.exchangeRate}
                color={slate900}
              />
            </Text>
          </div>
        )}
      </div>
      <Text variant={TextVariants.RegularTextS} color={slate600}>
        Al apretar al botón de pagar estas aceptando que el pago será procesado
        como una extracción de <b>Cocos Cap</b> hacia el CVU del cliente en{" "}
        <b>Ágil Pagos</b>
      </Text>
      <div className={styles.buttonsWrapper}>
        <OutlinedButton variant={ThemeVariants.Pay} onClick={onClose}>
          Volver
        </OutlinedButton>
        <FilledButton
          variant={ThemeVariants.Pay}
          onClick={handleConfirm}
          isLoading={isLoading}
        >
          Pagar
        </FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default PayMethodConfirm;
