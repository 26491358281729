import { SVGProps } from "react";

export interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CocosGoldIcon: React.FC<IconProps> = ({
  width = 95,
  height = 72,
  color,
  ...props
}) => {
  const principalColor = color || "#113264";
  const secondaryColor = color || "#0D74CE";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 95 72"
      fill="none"
      {...props}
    >
      <path
        d="M0.00377965 33.152C-0.0266135 32.0658 0.162361 30.9846 0.559373 29.9733C0.956386 28.9619 1.55325 28.0412 2.3142 27.2665C3.07515 26.4917 3.9845 25.8789 4.98772 25.4646C5.99096 25.0503 7.0674 24.8431 8.15254 24.8555C10.0251 24.8001 11.8567 25.4108 13.3222 26.5793C14.7878 27.7479 15.7924 29.3986 16.1579 31.2385H11.4625C11.2109 30.5826 10.7553 30.0251 10.1629 29.6485C9.5705 29.2719 8.87279 29.0961 8.17299 29.1473C6.32349 29.1473 4.59001 30.5619 4.59001 33.152C4.59001 35.7422 6.33714 37.191 8.17299 37.191C8.91184 37.2389 9.6442 37.0277 10.2445 36.5937C10.8448 36.1597 11.2754 35.53 11.4625 34.8127H16.1579C15.8528 36.7022 14.8729 38.4166 13.4005 39.6371C11.9281 40.8576 10.0632 41.5013 8.15254 41.4486C7.0666 41.4639 5.98885 41.2587 4.9843 40.8454C3.97976 40.4322 3.06925 39.8193 2.30772 39.0439C1.54618 38.2686 0.949417 37.3468 0.553438 36.3342C0.157461 35.3215 -0.0295166 34.239 0.00377965 33.152Z"
        fill={principalColor}
      />
      <path
        d="M17.2228 33.152C17.2161 31.5008 17.6995 29.8849 18.6117 28.5095C19.5237 27.1339 20.8236 26.0609 22.3459 25.4265C23.8683 24.7923 25.5445 24.6252 27.1618 24.9466C28.7791 25.2681 30.2646 26.0636 31.4296 27.2321C32.5946 28.4006 33.3865 29.8894 33.7049 31.5095C34.0232 33.1296 33.8537 34.8078 33.2177 36.3312C32.5817 37.8546 31.508 39.1544 30.1329 40.0655C28.7577 40.9766 27.1433 41.458 25.4944 41.4486C24.4009 41.4709 23.3141 41.2716 22.2995 40.8623C21.285 40.4532 20.3636 39.8426 19.5908 39.0675C18.8181 38.2924 18.2099 37.3688 17.803 36.3522C17.396 35.3355 17.1987 34.2469 17.2228 33.152ZM29.2685 33.152C29.2685 30.7874 27.7193 29.1336 25.5421 29.1336C23.3651 29.1336 21.809 30.7874 21.809 33.152C21.809 35.5165 23.3582 37.1705 25.5421 37.1705C27.7261 37.1705 29.2685 35.5303 29.2685 33.152Z"
        fill={principalColor}
      />
      <path
        d="M35.1652 33.152C35.1348 32.0658 35.3238 30.9845 35.7208 29.9733C36.1178 28.9619 36.7146 28.0412 37.4756 27.2665C38.2365 26.4917 39.1459 25.8789 40.1492 25.4646C41.1523 25.0503 42.2288 24.843 43.3139 24.8555C45.1865 24.8001 47.0181 25.4108 48.4837 26.5793C49.9492 27.7479 50.9539 29.3986 51.3194 31.2385H46.6239C46.3724 30.5826 45.9167 30.0251 45.3243 29.6485C44.7319 29.2718 44.0342 29.0961 43.3344 29.1472C41.4849 29.1472 39.7514 30.5619 39.7514 33.152C39.7514 35.7422 41.4986 37.1909 43.3344 37.1909C44.0732 37.2389 44.8057 37.0276 45.4059 36.5937C46.0061 36.1597 46.4368 35.53 46.6239 34.8127H51.3194C51.0142 36.7022 50.0343 38.4165 48.5619 39.637C47.0895 40.8575 45.2247 41.5013 43.3139 41.4486C42.228 41.4638 41.1503 41.2587 40.1457 40.8454C39.1412 40.4321 38.2306 39.8193 37.4691 39.0439C36.7076 38.2686 36.1108 37.3468 35.7148 36.3342C35.3189 35.3215 35.1319 34.239 35.1652 33.152Z"
        fill={principalColor}
      />
      <path
        d="M52.3972 33.1521C52.3904 31.5009 52.8739 29.885 53.786 28.5095C54.6981 27.1341 55.9979 26.061 57.5203 25.4267C59.0426 24.7923 60.7188 24.6253 62.3362 24.9467C63.9535 25.2682 65.439 26.0637 66.6039 27.2322C67.769 28.4006 68.5609 29.8895 68.8793 31.5095C69.1977 33.1296 69.0281 34.8079 68.3921 36.3312C67.7561 37.8547 66.6824 39.1544 65.3072 40.0655C63.9321 40.9767 62.3176 41.4581 60.6688 41.4486C59.5752 41.471 58.4885 41.2716 57.4739 40.8625C56.4593 40.4532 55.5379 39.8428 54.7652 39.0676C53.9924 38.2925 53.3842 37.3688 52.9773 36.3523C52.5703 35.3356 52.373 34.2471 52.3972 33.1521ZM64.4429 33.1521C64.4429 30.7875 62.8936 29.1336 60.7165 29.1336C58.5395 29.1336 56.9902 30.7875 56.9902 33.1521C56.9902 35.5167 58.5326 37.1705 60.7165 37.1705C62.9004 37.1705 64.4429 35.5303 64.4429 33.1521Z"
        fill={principalColor}
      />
      <path
        d="M70.3734 35.831L74.8094 35.8652C74.8845 37.0065 75.6352 37.8197 77.2731 37.8197C78.761 37.8197 79.3206 37.232 79.3206 36.4051C79.3206 35.5782 78.4539 35.1681 76.6316 34.9289C73.5332 34.553 70.9466 33.1999 70.9466 29.899C70.9466 26.7348 73.2056 24.8555 77.0889 24.8555C80.9725 24.8555 83.6334 26.9604 83.6334 30.1588L79.109 30.1245C79.0476 29.2498 78.4265 28.6074 77.1026 28.6074C75.9492 28.6074 75.4851 29.1473 75.4851 29.7965C75.4851 30.6713 75.8127 30.8968 78.2969 31.2522C81.8458 31.7647 84.0504 32.8992 84.0504 36.1317C84.0504 39.781 81.0471 41.4622 77.2254 41.4622C72.4617 41.4622 70.3734 38.6329 70.3734 35.831Z"
        fill={principalColor}
      />
      <path
        d="M76.7204 19.0397C73.0077 19.0807 69.5886 17.2765 68.0257 14.0645C67.4718 12.889 67.1847 11.6055 67.1847 10.3058C67.1847 9.00605 67.4718 7.72249 68.0257 6.54703C69.1501 4.06014 71.1184 2.05282 73.581 0.881594L75.3964 0L77.164 3.62889L75.3486 4.51732C73.7457 5.26933 72.4598 6.5643 71.7179 8.17352C71.4125 8.81261 71.2506 9.51081 71.2435 10.2192C71.2365 10.9276 71.3844 11.629 71.6769 12.274C72.9804 14.9393 76.7409 15.7867 80.0646 14.1671L81.8795 13.2786L83.6479 16.9075L81.8322 17.7959C80.2483 18.5749 78.5119 18.9928 76.7477 19.0192"
        fill={principalColor}
      />
      <path
        d="M86.5414 23.7416L84.692 20.1469L86.4869 19.2243C89.7766 17.5294 91.3532 14.0031 89.9947 11.3651C88.6369 8.72713 84.8629 7.94801 81.5732 9.64972L79.7782 10.5723L77.9287 6.97761L79.7236 6.05501C84.992 3.32138 91.2097 4.89322 93.5843 9.51306C95.9597 14.1329 93.5843 20.1058 88.3298 22.819L86.5414 23.7416Z"
        fill={secondaryColor}
      />
      <path
        d="M15.5976 63.9884C15.562 63.4783 15.6807 62.9693 15.9383 62.5279C16.196 62.0865 16.5805 61.7331 17.0418 61.514C17.5031 61.2948 18.0197 61.22 18.5241 61.2993C19.0285 61.3786 19.4973 61.6084 19.8693 61.9586C20.2413 62.3088 20.4992 62.7632 20.6093 63.2624C20.7194 63.7617 20.6766 64.2826 20.4865 64.7571C20.2965 65.2317 19.9679 65.6378 19.5438 65.9225C19.1197 66.2072 18.6197 66.3571 18.1091 66.3529C17.7871 66.3715 17.4646 66.3242 17.1615 66.2138C16.8583 66.1036 16.5808 65.9325 16.3457 65.7112C16.1107 65.49 15.9232 65.2232 15.7946 64.9269C15.666 64.6307 15.5989 64.3113 15.5976 63.9884Z"
        fill={secondaryColor}
      />
      <path
        d="M74.6752 66.5283C70.2011 66.5283 67.2083 62.7797 67.2083 58.1847C67.2083 53.6199 70.1104 49.8562 74.7206 49.8562C76.9425 49.8562 78.6052 51.1259 79.361 52.2293L79.2703 50.5061V44.717H83.8955V66.0144H79.4819V64.0343C78.6354 65.2435 77.0785 66.5283 74.6752 66.5283ZM75.5368 62.2507C77.6529 62.2507 79.3005 60.4973 79.3005 58.1847C79.3005 55.9174 77.668 54.1338 75.5368 54.1338C73.3904 54.1338 71.7882 55.7814 71.7882 58.1847C71.7882 60.4822 73.2695 62.2507 75.5368 62.2507Z"
        fill={secondaryColor}
      />
      <path
        d="M60.3892 66.0144V44.717H65.0447V66.0144H60.3892Z"
        fill={secondaryColor}
      />
      <path
        d="M49.8518 66.5132C44.9545 66.5132 41.5384 62.7797 41.5384 58.1847C41.5384 53.6199 44.9394 49.8562 49.8669 49.8562C54.8248 49.8562 58.2559 53.6048 58.2559 58.1847C58.2559 62.7949 54.8248 66.5132 49.8518 66.5132ZM49.8972 62.2205C52.0889 62.2205 53.6458 60.5729 53.6458 58.1847C53.6458 55.8116 52.0889 54.1489 49.8972 54.1489C47.7055 54.1489 46.1486 55.8116 46.1486 58.1847C46.1486 60.5729 47.7055 62.2205 49.8972 62.2205Z"
        fill={secondaryColor}
      />
      <path
        d="M31.0615 72.0001C25.6351 72.0001 23.3678 68.9619 23.3224 65.9086H27.6908C28.0233 67.3143 28.8546 68.1154 31.0615 68.1154C33.8427 68.1154 34.9763 66.5434 34.9763 64.5029V63.2332C33.9334 64.9715 32.1649 65.5307 30.1394 65.5307C25.6804 65.5307 22.6876 62.0693 22.6876 57.837C22.6876 53.6199 25.6351 49.8562 29.9883 49.8562C32.6032 49.8562 34.2659 51.1863 34.9914 52.3653V50.355H39.4051V64.4878C39.4051 68.9468 36.246 72.0001 31.0615 72.0001ZM31.0312 61.5403C33.1625 61.5403 34.7949 59.8323 34.7949 57.8219C34.7949 55.8872 33.1776 54.1338 31.0464 54.1338C28.9151 54.1338 27.2978 55.5395 27.2978 57.8219C27.2978 60.0439 28.9151 61.5403 31.0312 61.5403Z"
        fill={secondaryColor}
      />
    </svg>
  );
};

export default CocosGoldIcon;
