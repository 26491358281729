import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

const FlowHeader = ({ isMobile }: { isMobile: boolean }) => (
  <>
    <div className={styles.flowColumn}>
      <Text variant={TextVariants.SemiboldTextS}>Fecha</Text>
    </div>
    <div className={styles.flowColumn}>
      <Text variant={TextVariants.SemiboldTextS} className={styles.alignRight}>
        Capital
      </Text>
    </div>
    <div className={styles.flowColumn}>
      <Text variant={TextVariants.SemiboldTextS} className={styles.alignRight}>
        Intereses
      </Text>
    </div>
    <div className={styles.flowColumn}>
      <Text variant={TextVariants.SemiboldTextS} className={styles.alignRight}>
        Flujo
      </Text>
    </div>
    {!isMobile && (
      <div className={styles.flowColumn}>
        <Text
          variant={TextVariants.SemiboldTextS}
          className={styles.alignRight}
        >
          Acumulado
        </Text>
      </div>
    )}
  </>
);

export default FlowHeader;
