import { useEffect, useState } from "react";

import {
  Backdrop,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import NoCtte from "components/common/NoCtte";
import { Currency } from "components/common/currency";
import CurrentTariff from "components/common/current-tariff";
import { InstrumentData, MarketData } from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { NumberFormatValues } from "react-number-format";
import mainRoutes, { screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { clearCaucionError, resetCaucionOrder } from "features/markets";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import { getLoggedUser } from "store/selectors/user.selector";
import ButtonUI, { ButtonSizes } from "components/common/button";
import { ButtonVariants } from "components/common/button/index";
import {
  AmountInputSizes,
  UIAmountInput,
} from "components/common/input/amount";
import { UIPriceInput } from "components/common/input/price";
import {
  Option,
  SelectSizes,
  SelectTypes,
  UISelect,
} from "components/common/select";
import { getCurrencyLabel } from "utils";
import { trackScreen } from "utils/amplitude";
import { caucionMin } from "utils/constants";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { ConfirmCaucionOrder } from "./confirm-modal";

import "./index.scss";

interface PropTypes {
  available?: number | null;
  caucion?: InstrumentData;
  dateLabel: string;
  onChangeTerm: (mode: "add" | "substract") => (event: any) => void;
  getCurrencyOptions: () => Option[];
  getDisabledButton: () => boolean;
  onChangeCurrency: (currency?: string) => void;
  onChangeMethod: (method?: string) => void;
  onChangeRateValue: ({ value }: NumberFormatValues) => void;
  onChangeAmountValue: ({ floatValue }: NumberFormatValues) => void;
  markets: MarketData;
}

export const CaucionMobile = (props: PropTypes) => {
  const {
    available,
    caucion,
    dateLabel,
    getCurrencyOptions,
    getDisabledButton,
    onChangeAmountValue,
    onChangeCurrency,
    onChangeMethod,
    onChangeRateValue,
    onChangeTerm,
    markets,
  } = props;
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const dispatch = useAppDispatch();
  const caucionOrder = useAppSelector(
    (state: RootState) => state.markets.caucionOrder
  );
  const caucionError = useAppSelector(
    (state: RootState) => state.markets.caucionError
  );
  const user = useAppSelector(getLoggedUser);

  const currentScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackScreen(screenNames.caucion, currentScreen);
    dispatch(setCurrentScreen(screenNames.caucion));
  }, []);

  const handleToggleConfirmModal = () => {
    setOpenConfirmModal(!openConfirmModal);
    if (caucionOrder.id) {
      dispatch(resetCaucionOrder());
    }
    if (caucionError.message) {
      dispatch(clearCaucionError());
    }
  };

  const handleClickContinue = () => {
    if (
      caucionOrder.amount &&
      caucionOrder.rate &&
      caucionOrder.amount >= caucionMin
    ) {
      handleToggleConfirmModal();
    }
  };

  const isOverAvailable =
    caucionOrder.amount && available && caucionOrder.amount > available;

  const earnings =
    caucionOrder.amount && caucionOrder.rate
      ? caucionOrder.amount *
        (caucionOrder?.rate / 100 - 0.05) *
        (caucionOrder.term / 365)
      : 0;

  const total =
    caucionOrder.amount && earnings && earnings + caucionOrder.amount;

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  return (
    <div className="pages-caucion-mobile">
      <Backdrop open={isEmpty(markets.items) || !caucion}>
        <CircularProgress />
      </Backdrop>
      {caucion && (
        <div className="caucion-content">
          <span className="amount-available-text">
            <Typography variant="textS">Saldo disponible:</Typography>
            <Typography variant="textS_bold">
              {`${getCurrencyLabel(caucionOrder.currency)} `}
              <Currency>{available}</Currency>
            </Typography>
          </span>
          <div className="sub-content">
            <div className="side-content">
              <span className="side-text">
                <Typography variant="textM_bold">Colocador</Typography>
              </span>
              <div className="select">
                <UISelect
                  cantBeEmpty
                  disabled
                  hideHelperText
                  options={getCurrencyOptions()}
                  size={SelectSizes.Small}
                  type={SelectTypes.Secondary}
                  value={caucionOrder.currency}
                  onChange={onChangeCurrency}
                  downArrowSize={12}
                />
                <div className="price-input">
                  <UISelect
                    cantBeEmpty
                    hideHelperText
                    disabled
                    options={[
                      { value: "mercado", label: "Tasa de Mercado" },
                      { value: "limite", label: "Tasa Límite" },
                    ]}
                    size={SelectSizes.Small}
                    type={SelectTypes.Secondary}
                    value={caucionOrder.method}
                    onChange={onChangeMethod}
                    downArrowSize={12}
                  />
                </div>
              </div>

              <NoCtte />
              {user.hasAccount && (
                <>
                  <UIPriceInput
                    disabled={caucionOrder.method === "mercado"}
                    endAdorment={caucionOrder.rate ? "%" : undefined}
                    placeholder="Ingresa tasa de interés"
                    prefix={false}
                    value={
                      caucionOrder.rate
                        ? caucionOrder.rate.toString()
                        : undefined
                    }
                    onChangeValue={onChangeRateValue}
                  />
                  <div className="amount-input">
                    <Typography variant="textS">Monto a invertir</Typography>
                    <UIAmountInput
                      disableDecimals
                      currencyValue={caucionOrder.currency}
                      size={AmountInputSizes.Small}
                      value={
                        caucionOrder.amount
                          ? caucionOrder.amount.toString()
                          : ""
                      }
                      onChangeValue={onChangeAmountValue}
                    />
                    <Typography
                      className="amount-subtitle"
                      component="p"
                      variant="textXS"
                    >
                      {"Mínimo a invertir: AR$ "}
                      <Currency withoutDecimals>10000</Currency>
                    </Typography>
                    {!isOverAvailable ? (
                      <Typography variant="textXS">
                        {`Saldo disponible: ${getCurrencyLabel(
                          caucionOrder.currency
                        )} `}
                        <Currency>{available}</Currency>
                      </Typography>
                    ) : (
                      <Typography
                        className="not-enough"
                        component="p"
                        variant="textXS"
                      >
                        No tienes saldo suficiente
                      </Typography>
                    )}
                    <CurrentTariff newClass="tariff" />
                  </div>
                  <div className="days-input">
                    <Typography variant="textS">
                      {" "}
                      Plazo (Días) | Fecha
                    </Typography>
                    <div className="day-input-content">
                      <IconButton
                        disabled={caucionOrder.term === 1}
                        onClick={onChangeTerm("substract")}
                      >
                        -
                      </IconButton>
                      <Typography variant="textS_bold">
                        {caucionOrder.term}
                      </Typography>{" "}
                      |<Typography variant="captionS">{dateLabel}</Typography>
                      <IconButton
                        disabled={caucionOrder.term === 120}
                        onClick={onChangeTerm("add")}
                      >
                        +
                      </IconButton>
                    </div>
                  </div>
                  <ButtonUI
                    disabled={getDisabledButton()}
                    size={ButtonSizes.Small}
                    variant={ButtonVariants.Primary}
                    onClick={handleClickContinue}
                  >
                    Continuar
                  </ButtonUI>
                </>
              )}
            </div>
          </div>
          <div className="last-container">
            <Typography className="title">Inviertes</Typography>
            <Typography className="value" variant="textL_bold">
              {`${getCurrencyLabel(caucionOrder.currency)} `}
              <Currency>{caucionOrder.amount || "0"}</Currency>
            </Typography>
            <Typography className="title">Plazo</Typography>
            <Typography className="value" variant="textL_bold">
              {caucionOrder.term} {caucionOrder.term === 1 ? "día" : "días"}
            </Typography>
            <Typography className="title">Tasa de Interés</Typography>
            <Typography className="value" variant="textL_bold">
              {caucionOrder.method === "limite" ? "Límite" : "Mercado"}
            </Typography>
            <Typography className="title">
              Recibes | Ganancia Neta (Estimado)
            </Typography>
            <div className="recive">
              <Typography className="value" variant="textL_bold">
                {`${getCurrencyLabel(caucionOrder.currency)} `}
                {total ? <Currency>{total}</Currency> : "---"}
                &nbsp;|&nbsp;
              </Typography>
              <Typography className="value estimate" variant="textL_bold">
                {`${getCurrencyLabel(caucionOrder.currency)} `}
                {earnings ? <Currency>{earnings}</Currency> : "---"}
              </Typography>
            </div>
          </div>
          {openConfirmModal && (
            <ConfirmCaucionOrder
              open={openConfirmModal}
              onToggleOpen={handleToggleConfirmModal}
            />
          )}
        </div>
      )}
    </div>
  );
};
