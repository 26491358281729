import { AccountsResponse } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface DetailsBodyProps {
  bankAccount?: AccountsResponse;
  currency: Currencies;
}

const DetailsBody: React.FC<DetailsBodyProps> = ({ bankAccount, currency }) => {
  if (!bankAccount) return null;

  const { entity, cbu_cvu } = bankAccount;
  return (
    <div className={styles.details}>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Entidad
        </Text>
        <Text
          variant={TextVariants.RegularText}
          component="span"
          color="var(--slate900)"
        >
          {entity}
        </Text>
      </div>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          CBU
        </Text>
        <Text
          variant={TextVariants.SemiboldText}
          component="span"
          color="var(--slate900)"
        >
          {cbu_cvu}
        </Text>
      </div>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Moneda
        </Text>
        <Text
          variant={TextVariants.RegularText}
          component="span"
          color="var(--slate900)"
        >
          {currency}
        </Text>
      </div>
    </div>
  );
};

export default DetailsBody;
