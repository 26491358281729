import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import InputRadio from "components/common/input/InputRadio";

import { Option } from "../../constants";

import styles from "./styles.module.scss";

interface SelectBottomSheetProps {
  title: string;
  value: string;
  options: Option[];
  setOpen: (value: boolean) => void;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
  name: string;
}

const SelectBottomSheet = ({
  title,
  value,
  options,
  setOpen,
  onChange,
  onBlur,
  name,
}: SelectBottomSheetProps) => {
  return (
    <div className={styles.selectBottomSheetContainer}>
      {title && (
        <Text
          color="var(--slate900)"
          className={styles.title}
          variant={TextVariants.RegularText}
        >
          {title}
        </Text>
      )}
      <div className={styles.optionsContainer}>
        {options.map((option: Option) => (
          <InputRadio
            key={option.value}
            value={option.value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            checked={option.value === value}
          >
            {option.label}
          </InputRadio>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <FilledButton onClick={() => setOpen(false)} disabled={!value}>
          Seleccionar
        </FilledButton>
      </div>
    </div>
  );
};

export default SelectBottomSheet;
