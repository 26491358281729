import { IconProps } from "assets/icons/BTC";
import classNames from "classnames";

import styles from "./styles.module.scss";

const SimpleBank: React.FC<IconProps> = ({ size, className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 68 80"
    fill="none"
    className={classNames([className, styles.svg])}
    {...props}
  >
    <g clipPath="url(#clip0_14549_16189)">
      <path
        d="M69.9698 31.1694H6.54688V37.5619H69.9698V31.1694Z"
        fill="#0588F0"
      />
      <path
        d="M38.5884 0L0.666992 25.7923V32.1848H76.5156V25.7923L38.5884 0Z"
        fill="#0588F0"
      />
      <path
        d="M69.9698 67.2095H6.54688V73.602H69.9698V67.2095Z"
        fill="#0588F0"
      />
      <path d="M76.5158 73.6074H0V79.9999H76.5158V73.6074Z" fill="#0588F0" />
      <path
        d="M34.7791 37.5679H27.3203V67.2093H34.7791V37.5679Z"
        className={styles.line}
      />
      <path
        d="M49.3661 37.5679H41.9072V67.2093H49.3661V37.5679Z"
        className={styles.line}
      />
      <path
        d="M63.9588 37.5679H56.5V67.2093H63.9588V37.5679Z"
        className={styles.line}
      />
      <path
        d="M20.1864 37.5679H12.7275V67.2093H20.1864V37.5679Z"
        className={styles.line}
      />
      <path
        d="M12.7275 73.6074H64.4547"
        stroke="white"
        strokeWidth="0.986528"
        strokeMiterlimit="10"
      />
      <path
        d="M12.7275 31.1694H64.4547"
        stroke="white"
        strokeWidth="0.986528"
        strokeMiterlimit="10"
      />
      <path
        d="M38.2582 24.8856C42.0721 24.8856 45.1639 21.7938 45.1639 17.9799C45.1639 14.166 42.0721 11.0742 38.2582 11.0742C34.4443 11.0742 31.3525 14.166 31.3525 17.9799C31.3525 21.7938 34.4443 24.8856 38.2582 24.8856Z"
        stroke="white"
        strokeWidth="0.986528"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_14549_16189">
        <rect width="76.5158" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SimpleBank;
