import React, { useState } from "react";

import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { ArrowDownIcon } from "assets/icons/arrow-down-collapse";
import { ArrowTopIcon } from "assets/icons/arrow-top";
import { CheckboxIcon } from "assets/icons/checkbox/checkbox-icon";
import { CheckedCheckboxIcon } from "assets/icons/checkbox/checked-checkbox";
import { CheckBoxOptions } from "interfaces/ui";

import styles from "./styles.module.scss";

interface PropTypes {
  options: CheckBoxOptions[];
  title: string;
  handleClickCheck: (o: string, c: boolean) => void;
}

export default function UIDropdownCheckbox({
  options,
  title,
  handleClickCheck,
}: PropTypes) {
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    handleClickCheck(target.value, target.checked);
  };

  return (
    <div className={styles.dropdownCheckbox}>
      <div className={styles.header}>
        <Typography className={styles.title}>{title}</Typography>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <ArrowTopIcon />
          ) : (
            <ArrowDownIcon color="#0062E1" size={16} type="thick" />
          )}
        </IconButton>
      </div>
      {expanded && (
        <>
          {options.map((option: CheckBoxOptions, index: number) => (
            <div key={index} className={styles.checkboxRow}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={option.checked}
                    checkedIcon={<CheckedCheckboxIcon />}
                    value={option.option}
                    icon={<CheckboxIcon />}
                    onChange={handleChangeChecked}
                  />
                }
                label={<Typography>{option.option}</Typography>}
              />
            </div>
          ))}
        </>
      )}
      {!expanded && (
        <>
          {options.map((option: CheckBoxOptions, index: number) => {
            if (option.checked)
              return (
                <div key={index} className={styles.checkboxRow}>
                  <Typography>{option.option}</Typography>
                  <Checkbox
                    checked={option.checked}
                    checkedIcon={<CheckedCheckboxIcon />}
                    value={option.option}
                    icon={<CheckboxIcon />}
                    onChange={handleChangeChecked}
                  />
                </div>
              );
          })}
        </>
      )}
    </div>
  );
}
