import { Typography } from "@mui/material";
import { DesktopOrTablet, Mobile } from "utils/responsive";

import styles from "./styles.module.scss";

export const OrdersTableHeader = () => {
  return (
    <div className={styles.container}>
      <Mobile>
        <div>
          <Typography>Activo</Typography>
          <Typography>Operación</Typography>
        </div>
        <div>
          <Typography textAlign="right">Nominales</Typography>
          <Typography textAlign="right">Precio</Typography>
        </div>
        <Typography textAlign="right">Total operación</Typography>
      </Mobile>

      <DesktopOrTablet>
        <>
          <Typography variant="textXS">Especie</Typography>
          <Typography variant="textXS">Operación</Typography>
          <Typography variant="textXS" textAlign="right">
            Precio
          </Typography>
          <Typography variant="textXS" textAlign="right">
            Cantidad
          </Typography>
          <Typography variant="textXS" textAlign="right">
            Total operación
          </Typography>
          <Typography variant="textXS" textAlign="center">
            Plazo
          </Typography>
          <Typography variant="textXS">Estado</Typography>
        </>
      </DesktopOrTablet>
    </div>
  );
};
