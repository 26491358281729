import OrderDetailPage from "components/page-order-detail";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

const OrderDetail: React.FC = () => {
  const { isMobile } = useDeviceCheck();

  if (isMobile) return <OrderDetailPage />;

  return <Navigate replace to={`${mainRoutes.market}/ACCIONES`} />;
};

export default OrderDetail;
