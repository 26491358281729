interface PropTypes {
  size?: number | string;
  color?: string;
  thickness?: number;
}

export const HideIcon = (props: PropTypes) => {
  const { size = 17, color = "#0062E1", thickness = 1.5 } = props;

  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 17 17"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.15698 8.48975C1.15698 8.48975 3.82365 13.8231 8.49032 13.8231C13.157 13.8231 15.8236 8.48975 15.8236 8.48975"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M6.80367 14.0577L6.20117 15.9797"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={`${thickness}`}
      />
      <path
        d="M10.0361 14.0577L10.6386 15.9797"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={`${thickness}`}
      />
      <path
        d="M3.70123 12.4898L2.49023 14.0303"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={`${thickness}`}
      />
      <path
        d="M13.1387 12.4898L14.3497 14.0303"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={`${thickness}`}
      />
    </svg>
  );
};
