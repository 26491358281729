import MovementReceiptPage from "components/page-movement-receipt";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

const MovementReceipt: React.FC = () => {
  const { isMobile } = useDeviceCheck();

  if (isMobile) return <MovementReceiptPage />;

  return <Navigate replace to={mainRoutes.movements} />;
};

export default MovementReceipt;
