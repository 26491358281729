import { ChangeEvent, forwardRef } from "react";

import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import {
  MAX_TAG_LENGTH,
  checkValidCharacter,
} from "components/page-cocos-tag/utils";

import styles from "./styles.module.scss";

interface TagInputProps {
  value: string;
  onChange: (value: React.SetStateAction<string>) => void;
}

const TagInput = forwardRef<HTMLInputElement, TagInputProps>(
  ({ value, onChange }, ref) => {
    const CHAR_TO_CHANGE_VARIANT = 18;

    const getVariant = () => {
      if (value.length >= CHAR_TO_CHANGE_VARIANT) {
        return TextVariants.SemiboldTextL;
      }
      return TextVariants.SemiboldTextXL;
    };
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value.trim().toLowerCase();
      if (newValue.length > MAX_TAG_LENGTH) {
        return;
      }
      onChange(newValue);
    };

    const checkError = () => {
      return value.length > MAX_TAG_LENGTH || !checkValidCharacter(value);
    };

    return (
      <div
        className={classNames(styles.TagInputContainer, {
          [styles.error]: checkError(),
        })}
      >
        <input
          className={classNames(styles.input, styles[getVariant()])}
          value={value}
          onChange={handleOnChange}
          ref={ref}
        />
        <Text
          className={classNames(styles.displayer, styles[getVariant()])}
          variant={getVariant()}
          color="var(--purple800)"
        >
          {value}
        </Text>
      </div>
    );
  }
);

export default TagInput;
