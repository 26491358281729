import { useEffect, useState } from "react";

import { PhoneRegisterResponse } from "interfaces/api-responses";
import API from "apis";
import { phoneService } from "apis/services";
import { ampli } from "ampli";

import PhoneForm from "./PhoneForm";
import PhoneVerification from "./PhoneVerification";

const PhoneSignUp: React.FC = () => {
  const [countryCode, setCountryCode] = useState<number>();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isShowingForm, setIsShowingForm] = useState<boolean>(true);
  const [challengeData, setChallengeData] = useState<PhoneRegisterResponse>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const registerPhone = async () => {
    try {
      setHasError(false);
      setIsLoading(true);
      const { data } = await API.post<PhoneRegisterResponse>(
        phoneService.register,
        {
          phone: `${countryCode}${phoneNumber}`,
        }
      );
      setChallengeData(data);
      if (data.id === null) {
        setHasError(true);
        return;
      }
      setIsShowingForm(false);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    ampli.phoneNumberScreenViewed({ referral: "signup" });
  }, []);

  if (isShowingForm)
    return (
      <PhoneForm
        hasError={hasError}
        isLoading={isLoading}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        registerPhone={registerPhone}
        setCountryCode={setCountryCode}
        setPhoneNumber={setPhoneNumber}
      />
    );

  return (
    <PhoneVerification
      countryCode={countryCode}
      phoneNumber={phoneNumber}
      challengeData={challengeData}
      registerPhone={registerPhone}
      showForm={() => setIsShowingForm(true)}
    />
  );
};

export default PhoneSignUp;
