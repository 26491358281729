import { CDN_URL } from "constants/index";

import { createContext, ReactNode, useContext, useMemo, useState } from "react";

import { Outlet } from "react-router-dom";
import API from "apis";
import { transfersService } from "apis/services";
import { Currencies } from "interfaces/wallet";
import { AccountsResponse } from "interfaces/api-responses";
import { useAppSelector } from "hooks/redux";
import { getLoggedUser } from "store/selectors/user.selector";

export enum AccountsSteps {
  AMOUNT = "AMOUNT",
  ACCOUNTS = "ACCOUNTS",
  NEW_ACCOUNT = "NEW_ACCOUNT",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

interface ToastState {
  visible: boolean;
  message: string;
}

interface Context {
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  accountSelected?: AccountsResponse;
  setAccountSelected: (v: AccountsResponse) => void;
  error: boolean;
  setError: (v: boolean) => void;
  step: AccountsSteps;
  setStep: (v: AccountsSteps) => void;
  currency: Currencies;
  setCurrency: (v: Currencies) => void;
  isDesktop: boolean;
  setIsDesktop: (v: boolean) => void;
  getAccounts: () => Promise<void>;
  accounts: AccountsResponse[];
  displayToast: ToastState;
  setDisplayToast: (v: ToastState) => void;
  amount: number;
  setAmount: (v: number) => void;
  getBankLogo: (logo: string) => string;
}

const CapitalSendAccountsContext = createContext<Context | null>(null);

export const CapitalSendAccountsProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const darkMode = useAppSelector(getLoggedUser)?.dark_theme;

  const [step, setStep] = useState<AccountsSteps>(AccountsSteps.AMOUNT);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<AccountsResponse[]>([]);
  const [currency, setCurrency] = useState<Currencies>(Currencies.ARS);
  const [accountSelected, setAccountSelected] = useState<AccountsResponse>();
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [displayToast, setDisplayToast] = useState<ToastState>({
    visible: false,
    message: "",
  });

  const getAccounts = async () => {
    setIsLoading(true);
    try {
      const { data } = await API.get<AccountsResponse[]>(
        transfersService.accounts,
        {
          params: { currency },
        }
      );
      setAccounts(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBankLogo = (logo: string) => {
    const logoPath = darkMode ? `${logo}-dark` : logo;
    return `${CDN_URL}/logos/bancos/${logoPath}.svg`;
  };

  const memoizedValues = useMemo(() => {
    return {
      error,
      accountSelected,
      isLoading,
      setIsLoading,
      setAccountSelected,
      setError,
      step,
      setStep,
      currency,
      setCurrency,
      setIsDesktop,
      isDesktop,
      getAccounts,
      accounts,
      displayToast,
      setDisplayToast,
      amount,
      setAmount,
      getBankLogo,
    };
  }, [
    error,
    accountSelected,
    isLoading,
    setIsLoading,
    setAccountSelected,
    setError,
    step,
    setStep,
    currency,
    setCurrency,
    setIsDesktop,
    isDesktop,
    getAccounts,
    accounts,
    displayToast,
    setDisplayToast,
    amount,
    setAmount,
    getBankLogo,
  ]);

  return (
    <CapitalSendAccountsContext.Provider value={memoizedValues}>
      {children}
      <Outlet />
    </CapitalSendAccountsContext.Provider>
  );
};

export const useCapitalSendAccounts = () => {
  const context = useContext(CapitalSendAccountsContext);

  if (!context) throw new Error("[CapitalSendAccountsContext] Missing context");

  return context;
};
