import SearchBarComp from "components/common/search-bar";
import { SearchTypes } from "components/common/search-bar-ui";

import "./index.scss";

export const HeaderSearchBar: React.FC = () => {
  return (
    <div className="header-search-bar">
      <SearchBarComp isHeaderSearchBar type={SearchTypes.Secondary} />
    </div>
  );
};
