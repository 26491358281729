import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface InfoRowProps {
  icon: LucideIcon;
  title: string;
  subtitle: string;
}

const InfoRow: React.FC<InfoRowProps> = ({ icon: Icon, title, subtitle }) => {
  return (
    <div className={styles.infoRowContainer}>
      <div className={styles.icon}>
        <Icon size={px2rem(24)} color="var(--black-to-white)" />
      </div>
      <div className={styles.textWrapper}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {title}
        </Text>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {subtitle}
        </Text>
      </div>
    </div>
  );
};

export default InfoRow;
