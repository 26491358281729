import { PaymentCurrencies } from "interfaces/pay/enums";
import { getFCIIconColor } from "components/page-cocos-pay/utils";
import ARGFlag from "assets/icons/ARGFlag";
import USDT from "assets/icons/USDT";
import USDC from "assets/icons/USDC";
import DAI from "assets/icons/DAI";
import USFlag from "assets/icons/USFlag";
import BRFlag from "assets/icons/BRFlag";
import CocosBrand, { CocosBrandVariants } from "assets/icons/CocosBrand";
import OverlappedIcons from "components/common/OverlappedIcons";

const getCurrencyIcon = ({
  currency,
  size,
}: {
  currency: PaymentCurrencies;
  size: number;
}) => {
  const currencyIconMap = {
    [PaymentCurrencies.ARS]: <ARGFlag size={size} />,
    [PaymentCurrencies.USD]: <USFlag size={size} />,
    [PaymentCurrencies.BRL]: <BRFlag size={size} />,
    [PaymentCurrencies.USDT]: <USDT size={size} />,
    [PaymentCurrencies.USDC]: <USDC size={size} />,
    [PaymentCurrencies.DAI]: <DAI size={size} />,
    [PaymentCurrencies.COCOA]: (
      <CocosBrand
        variant={CocosBrandVariants.CAPITAL}
        size={size}
        color={getFCIIconColor(PaymentCurrencies.COCOA)}
      />
    ),
    [PaymentCurrencies.COCORMA]: (
      <CocosBrand
        variant={CocosBrandVariants.CAPITAL}
        size={size}
        color={getFCIIconColor(PaymentCurrencies.COCORMA)}
      />
    ),
    [PaymentCurrencies.COCOAUSD]: (
      <CocosBrand
        variant={CocosBrandVariants.CAPITAL}
        size={size}
        color={getFCIIconColor(PaymentCurrencies.COCOAUSD)}
      />
    ),
    [PaymentCurrencies.STABLECOIN]: (
      <OverlappedIcons icons={[USDT, USDC, DAI]} />
    ),
  };

  return (
    currencyIconMap[currency] || (
      <CocosBrand variant={CocosBrandVariants.CAPITAL} size={size} />
    )
  );
};

export default getCurrencyIcon;
