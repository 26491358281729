import { getListMenuHome } from "constants/capitalMenu";

import React, { useState } from "react";

import MarketList from "components/common/MarketList";
import { NewListModal } from "components/common/new-list-modal";
import SearchModal from "components/common/search-modal";
import Caucion from "components/page-market/components/Caucion";
import Header from "components/page-market/components/Header";
import SecondaryOptions from "components/page-market/components/SecondaryOptions";
import SubSections from "components/page-market/components/SubSections";
import { useMarket } from "context/MarketProvider";
import { Instruments } from "interfaces/markets";
import CommonLayout from "layouts/common";

import MarketClosedModal from "../components/MarketClosedModal";

import "./index.scss";

interface MarketDesktopProps {
  setOpenNewListModal: (value: boolean) => void;
  openNewListModal: boolean;
}

const MarketDesktop: React.FC<MarketDesktopProps> = ({
  setOpenNewListModal,
  openNewListModal,
}) => {
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const {
    currency,
    settlementDays,
    selectedInstrumentType,
    selectedInstrumentSubtype,
  } = useMarket();

  const handleToggleSearchModal = () => {
    setOpenSearchModal(!openSearchModal);
  };

  return (
    <div className="market-desktop wide">
      <CommonLayout {...getListMenuHome()} withHeader={false}>
        <MarketClosedModal />
        <SearchModal
          handleToggleDisplayModal={handleToggleSearchModal}
          open={openSearchModal}
        />
        {selectedInstrumentType !== Instruments.CAUCION && (
          <div className={`market-content`}>
            <div className="lists-content">
              <div className="types-filters">
                <Header />
                <SubSections setOpenNewListModal={setOpenNewListModal} />
              </div>
              <SecondaryOptions />
            </div>
            <div className="markets-container">
              <MarketList
                selectedInstrumentType={selectedInstrumentType}
                currency={currency}
                selectedInstrumentSubtype={selectedInstrumentSubtype}
                settlementDays={settlementDays}
              />
            </div>
          </div>
        )}
        {selectedInstrumentType === Instruments.CAUCION && (
          <div className="caucion-desktop">
            <Header />
            <Caucion />
          </div>
        )}
        <NewListModal
          open={openNewListModal}
          setOpenModal={setOpenNewListModal}
        />
      </CommonLayout>
    </div>
  );
};

export default MarketDesktop;
