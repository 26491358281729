import { FormEvent, MouseEvent } from "react";

import PageHeader from "components/common/PageHeader";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Text, { TextVariants } from "components/common/Text";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";
import { ampli } from "ampli";

import EmailPasswordConfirm from "./EmailPasswordConfirm";
import EmailPasswordTitle from "./EmailPasswordTitle";
import FormInputs from "./FormInputs";

import styles from "./styles.module.scss";

interface EmailPasswordFormProps {
  email: string;
  errorMessage: string;
  password: string;
  isLoading: boolean;
  hideForm: () => void;
  registerEmail: () => void;
  setEmail: (email: string) => void;
  setPassword: (passowrd: string) => void;
}

const EmailPasswordForm: React.FC<EmailPasswordFormProps> = ({
  email,
  password,
  setEmail,
  isLoading,
  setPassword,
  errorMessage,
  registerEmail,
}) => {
  const { isIOS } = getPlatform();
  const navigate = useNavigate();

  const handleGoBackClick = () => {
    ampli.signupEmailScreenBack();
    navigate(-1);
  };

  const handleSubmit = (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    registerEmail();
  };

  return (
    <div
      className={classNames(styles.EmailPasswordFormContainer, {
        [styles.ios]: isIOS,
      })}
    >
      <div className={styles.wrapper}>
        <PageHeader
          icon={ArrowLeft}
          iconColor="var(--blue900)"
          onClick={handleGoBackClick}
        />
        <EmailPasswordTitle />
        <form className={styles.form} onSubmit={handleSubmit}>
          <FormInputs
            email={email}
            password={password}
            setEmail={setEmail}
            setPassword={setPassword}
          />
          {errorMessage && (
            <Text variant={TextVariants.RegularTextS} color="var(--red800)">
              {errorMessage}
            </Text>
          )}
          <EmailPasswordConfirm
            email={email}
            password={password}
            isLoading={isLoading}
            registerEmail={handleSubmit}
          />
        </form>
      </div>
    </div>
  );
};

export default EmailPasswordForm;
