import {
  BookUser,
  FileCheck2,
  GraduationCap,
  MessageCircleQuestion,
  Scale,
  Settings,
  ShieldCheck,
} from "lucide-react";
import { CryptoRoutes } from "router/routes";

export const profileMenuOptions = [
  {
    icon: BookUser,
    title: "Agenda",
    subtitle: "Gestioná todos tus contactos",
    route: CryptoRoutes.AGENDA,
  },
  {
    icon: FileCheck2,
    title: "Límites",
    subtitle: "Consultá tus límites de operación",
    route: CryptoRoutes.LIMITS,
  },
  {
    icon: MessageCircleQuestion,
    title: "Centro de ayuda",
    subtitle: "Resolvé todas tus dudas o consultas",
    route: CryptoRoutes.HELP,
  },

  {
    icon: GraduationCap,
    title: "Aprendé",
    subtitle: "Iniciate en el mundo de las inversiones",
    route: CryptoRoutes.LEARN,
  },
  {
    icon: ShieldCheck,
    title: "Seguridad",
    subtitle: "Hacé tu experiencia más segura",
    route: CryptoRoutes.SECURITY,
  },
  {
    icon: Settings,
    title: "Configuración",
    subtitle: "Seleccioná todas tus preferencias",
    route: CryptoRoutes.CONFIG,
  },
  {
    icon: Scale,
    title: "Legales",
    subtitle: "Conocé nuestros términos y condiciones",
    route: CryptoRoutes.LEGALS,
  },
];
