export enum dollarCableService {
  fetchCotization = "/v1/markets/dolar-cable",
  submitOrder = "/orders/cable",
  fetchOrderStatus = "/orders/cable",
}

export enum userService {
  users = "/users",
  me = "/v1/users/me",
  zendesk = "/v1/users/zendesk-jwt",
  deviceToken = "/v1/users/device-token",
  accountTier = "/v1/users/account-tier",
  activateGold = "/v1/users/account-tier/upgrade",
  goldProfile = "/v1/users/gold-profile",
  userSettings = "/v1/users/user-settings",
  investorProfile = "/v1/users/investor-profile",
  investorTest = "/v1/users/investor-profile-test",
}

export enum portfolioService {
  portfolio = "/portfolio",
  balance = "/portfolio/balance",
  history = "/portfolio/history",
}

export const fciService = {
  holdings: (securityId: number) => `/fci/${securityId}/holdings`,
};

export enum phoneService {
  validation = "/v1/phone/validation",
  register = "/v1/phone/register",
}

export enum transfersService {
  transfers = "/v1/transfers",
  withdraw = "/v1/transfers/withdraw",
  accounts = "/v1/transfers/accounts",
  depositAccounts = "/v1/transfers/deposits/accounts",
}
export enum onBoardingService {
  createAccount = "onboarding/account",
  uploadDocument = "onboarding/document",
  getOnboarding = "onboarding",
}

export enum dolarMepService {
  getMepPrices = "/v1/public/mep-prices",
  sellOpenMep = "/v3/orders/sell-open-mep",
  sellCloseMep = "/v4/orders/sell-close-mep",
  confirmOpenOrder = "/v3/orders/buy-open-mep",
  confirmCloseOrder = "/v4/orders/buy-close-mep",
  sellOvernightMep = "/v4/orders/sell-overnight-mep",
  confirmOrderOvernight = "/v4/orders/buy-overnight-mep",
}

export enum marketService {
  lists = "/v1/markets/lists",
  tickers = "/v1/markets/tickers",
  schedules = "/v1/markets/schedule",
  listsTicker = "/v1/markets/lists/tickers",
  tickerRules = "/v1/markets/tickers/rules",
  searchTicker = "/v1/markets/tickers/search",
  investmentSuggest = "/v1/markets/investment-suggest",
  favoriteLists = "/v1/markets/tickers/favorites-lists",
}

export enum factorsService {
  factors = "/v1/factors",
  devices = "/v1/factors/devices",
  default = "/v1/factors/default",
}

export enum ordersService {
  orders = "/v2/orders",
  fciOrders = "/v2/orders/fci",
  bid = "/v2/orders/licitacion",
  dolarSwap = "/v2/orders/dolar/canje",
  buyingPower = "/v2/orders/buying-power",
  sellingPower = "/v2/orders/selling-power",
}

export enum calculatorService {
  bondFlowList = "/v1/calculator/bonds-flow-list",
  gotFlowList = "/v1/calculator/got-flow-list",
  analysis = "/v1/calculator/analisis",
}

export enum publicService {
  mepPrices = "/v1/public/mep-prices",
  status = "/v1/public/status",
}

export enum homeService {
  faqs = "/v1/home/faqs",
  academy = "/v1/home/academy",
}

export enum walletService {
  portfolio = "/v1/wallet/portfolio",
  cashMovements = "v1/wallet/cash_movements",
  tickerMovements = "/v1/wallet/tickers_movements",
  dailyPerformance = "/v1/wallet/performance/daily",
  globalPerformance = "/v1/wallet/performance/global",
  historicPerformance = "/v1/wallet/performance/historic",
  newPortfolio = "/portfolio",
  newPortfolioHistory = "/portfolio/history",
  newPortfolioBalance = "/portfolio/balance",
}

export enum cryptoService {
  portfolio = "/v1/crypto/customer/portfolio",
  movements = "/v1/crypto/customer/movements",
  withdrawalAddresses = "/v1/crypto/customer/addresses/withdrawal",
  prices = "v1/crypto/prices",
  depositAddress = "/v1/crypto/customer/addresses/deposit",
  networks = "v1/crypto/networks",
  performance = "v1/crypto/customer/performance",
  tickers = "v1/crypto/tickers",
  createSendOrder = "/v1/crypto/customer/orders/send",
  confirmSendOrder = "/v1/crypto/customer/orders/send/confirm",
  createBuy = "v1/crypto/customer/orders/buy",
  createSell = "v1/crypto/customer/orders/sell",
  confirmBuy = "v1/crypto/customer/orders/buy/confirm",
  confirmSell = "v1/crypto/customer/orders/sell/confirm",
  createSwap = "v1/crypto/customer/orders/swap",
  confirmSwap = "v1/crypto/customer/orders/swap/confirm",
  customer = "v1/crypto/customer",
  tag = "v1/crypto/customer/tags",
  uploadDocuments = "v1/crypto/customer/documents",
  historicPortfolio = "v1/crypto/customer/portfolio/historic",
  historicPrices = "v1/crypto/prices/historic",
  activityDetail = "v1/crypto/customer/movements", //Needs "detail" at the end
  limits = "v1/crypto/customer/orders/limits",
  recentTags = "v1/crypto/customer/tags?time=latests",
  fiatExternalCustomer = "v1/crypto/customer/fiat-external-customer",
  fiatExternalCustomerV2 = "v1/crypto/customer/fiat-external-customer/V2",
  fiatExternalCustomerTos = "v1/crypto/customer/fiat-external-customer/tos",
  fiatExternalCustomerPaymentMethods = "v1/crypto/customer/fiat-external-customer/payment-methods",
  fiatExternalCustomerPurchase = "v1/crypto/customer/fiat-external-customer/purchase-conditions",
  tncAccepted = "v1/crypto/customer/tnc/accept",
}

export enum BannersService {
  banners = "banners", //Needs banner name at the end
}

export enum paymentService {
  qr = "v1/payment/qr",
  payment = "v1/payment",
  pixPrices = "v1/public/pix-prices",
}

export enum cardService {
  user = "v1/cards/user",
  requestCard = "v1/cards/user/request-card",
  credentials = "v1/cards/user/credentials",
  pause = "v1/cards/pause",
  resume = "v1/cards/resume",
  cancel = "v1/cards/cancel",
  pin = "v1/cards/pin",
  paymentMethod = "v1/cards/payment-method",
  shippingPaymentMethod = "v2/cards/shipping-payment-methods",
  transactions = "v1/cards/transactions/grouped",
  limits = "v1/cards/limits",
  googlePayActivation = "v1/cards/token-provisioning/google-pay",
}
