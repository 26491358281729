import MovementsPage from "components/page-movements";
import { MovementsProvider } from "context/MovementsProvider";

const Movements: React.FC = () => (
  <MovementsProvider>
    <MovementsPage />
  </MovementsProvider>
);

export default Movements;
