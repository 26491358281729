import { useEffect } from "react";

import {
  useCapitalSendAccounts,
  AccountsSteps,
} from "context/CapitalSendAccountsProvider";
import Toast, { ToastVariants } from "components/common/Toast";
import Error from "components/page-capital-send/Error";

import Accounts from "./Accounts";
import NewAccount, { NewAccountReferral } from "./NewAccount";

interface CapitalAccountsPage {
  isDesktop?: boolean;
}

const CapitalAccountsPage: React.FC<CapitalAccountsPage> = ({
  isDesktop = false,
}) => {
  const {
    step,
    setIsDesktop,
    displayToast,
    setDisplayToast,
    setStep,
  } = useCapitalSendAccounts();

  useEffect(() => {
    setIsDesktop(isDesktop);
  }, []);

  return (
    <>
      {(step === AccountsSteps.ACCOUNTS || step === AccountsSteps.AMOUNT) && (
        <Accounts />
      )}
      {step === AccountsSteps.NEW_ACCOUNT && (
        <NewAccount
          referral={NewAccountReferral.BankAccounts}
          onClose={() => setStep(AccountsSteps.ACCOUNTS)}
          onError={() => setStep(AccountsSteps.ERROR)}
          onDisplayToast={() =>
            setDisplayToast({
              visible: true,
              message: "Cuenta agendada exitosamente",
            })
          }
        />
      )}
      {step === AccountsSteps.ERROR && <Error isAccountsError />}
      {displayToast.visible && (
        <Toast
          variant={ToastVariants.Success}
          message={displayToast.message}
          onClose={() => setDisplayToast({ visible: false, message: "" })}
        />
      )}
    </>
  );
};

export default CapitalAccountsPage;
