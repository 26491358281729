import { useNavigate } from "react-router-dom";
import { Currencies } from "interfaces/wallet";
import mainRoutes from "router/routes";
import { useCapitalSendAccounts } from "context/CapitalSendAccountsProvider";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { Projects, trackAction } from "utils/amplitude";
import { useAppDispatch } from "hooks/redux";
import GradientCard from "components/common/GradientCard";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import CapitalFunds from "components/page-capital-funds";
import { useAppState } from "context/AppStateProvider";

const CocoaBanner = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();
  const { isMobile } = useDeviceCheck();
  const { currency } = useCapitalSendAccounts();

  const isUSD = currency === Currencies.USD;
  const bannerTitle = isUSD
    ? "Ey! Sabés cuánto pueden rendir tus dólares?"
    : "Ey! Suscribí tus pesos y pagá directo con QR o tarjeta.";

  const onClickInfo = () => {
    const shortName = isUSD
      ? PaymentCurrencies.COCOAUSD
      : PaymentCurrencies.COCORMA;

    const trackingEvent = isUSD
      ? "cocoausd_from_withdraw"
      : "cocorma_from_withdraw";

    trackAction(trackingEvent, undefined, Projects.CAPITAL);

    if (isMobile) {
      return navigate(`${mainRoutes.funds}/${shortName}`);
    }

    dispatch(closeRightModal());
    openRightModalContent(<CapitalFunds shortName={shortName} />);
  };

  return (
    <GradientCard
      title={bannerTitle}
      link="Suscribite ahora"
      onClick={onClickInfo}
      currency={currency}
      isBanner
    />
  );
};

export default CocoaBanner;
