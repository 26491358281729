import PayIcon from "assets/icons/PayIcon";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface PayLegalsProps {
  iconColor?: string;
  textColor?: string;
}

const PayLegals: React.FC<PayLegalsProps> = (props: PayLegalsProps) => {
  const {
    iconColor = "var(--black-to-white)",
    textColor = "var(--slate600)",
  } = props;

  return (
    <div className={styles.payLegalsContainer}>
      <PayIcon width={68} height={48} color={iconColor} />
      <Text variant={TextVariants.RegularTextS} color={textColor}>
        Este pago será procesado como una extracción de <b>Cocos Cap</b> hacia
        el CVU del cliente en <b>Ágil Pagos</b>
      </Text>
    </div>
  );
};

export default PayLegals;
