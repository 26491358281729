import {
  CapitalPrivacyURL,
  CapitalTermsAndConditionsURL,
} from "constants/index";

import { Browser } from "@capacitor/browser";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { BookCheck, BookLock, BookCopy } from "lucide-react";
import {
  CARD_TERMS_AND_CONDITIONS,
  CARD_SUPPLMENT,
  CARD_PRIVACY_POLICY,
} from "components/page-cocos-card/constants";

import ProfileSectionTemplate from "../components/SectionTemplate";

const ProfileLegals: React.FC = () => {
  const { isMobile } = useDeviceCheck();

  const capitalOptions = [
    {
      icon: BookCheck,
      label: "Términos y condiciones",
      onClick: () => Browser.open({ url: CapitalTermsAndConditionsURL }),
    },
    {
      icon: BookLock,
      label: "Políticas de privacidad",
      onClick: () => Browser.open({ url: CapitalPrivacyURL }),
    },
  ];

  const cardOptions = [
    {
      icon: BookCheck,
      label: "Términos y condiciones",
      onClick: () => Browser.open({ url: CARD_TERMS_AND_CONDITIONS }),
    },
    {
      icon: BookCopy,
      label: "Suplemento",
      onClick: () => Browser.open({ url: CARD_SUPPLMENT }),
    },
    {
      icon: BookLock,
      label: "Políticas de privacidad",
      onClick: () => Browser.open({ url: CARD_PRIVACY_POLICY }),
    },
  ];

  return (
    <ProfileSectionTemplate
      title="Legales"
      subtitle={isMobile ? "Cocos Capital" : undefined}
      options={capitalOptions}
      withHeader={isMobile}
      secondarySubtitle="Cocos Card"
      secondaryOptions={cardOptions}
    />
  );
};

export default ProfileLegals;
