import { useEffect } from "react";

import { ampli } from "ampli";
import classNames from "classnames";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { isEmpty } from "lodash";
import { MAX_DEBT } from "components/page-cocos-pay/utils";
import { formatToCurrency } from "utils";
import { PaymentMethod } from "interfaces/pay/interface";

import MethodCard from "./MethodCard";

import styles from "./styles.module.scss";

interface MethodsListProps {
  availableMethods: PaymentMethod[];
  setSelectedMethod: (v?: PaymentMethod) => void;
  hasDebt?: boolean;
}

const MethodsList: React.FC<MethodsListProps> = ({
  availableMethods,
  setSelectedMethod,
  hasDebt = false,
}) => {
  const [methodsWithFunds, methodsWithoutFunds] = availableMethods.reduce<
    [PaymentMethod[], PaymentMethod[]]
  >(
    ([trueArray, falseArray], item) => {
      if (!item.hasEnoughFunds) {
        falseArray.push(item);
      } else {
        trueArray.push(item);
      }
      return [trueArray, falseArray];
    },
    [[], []]
  );

  const hasUSD = availableMethods.some(
    (method: PaymentMethod) => method.name === PaymentCurrencies.USD
  );

  const noBalance = isEmpty(methodsWithFunds);

  const handleOnClick = (currency: PaymentCurrencies) => {
    const selectedMethod = methodsWithFunds?.find(
      (obj) => obj.name === currency
    );

    if (!selectedMethod) return;
    setSelectedMethod(selectedMethod);
  };

  const showExtraLabel = () => {
    if (hasDebt) {
      return (
        <Text
          className={styles.noBalance}
          variant={TextVariants.RegularTextS}
          color="var(--red800)"
        >
          Para poder realizar un pago tu deuda no debe superar los ARS{" "}
          {formatToCurrency(MAX_DEBT, true)}
        </Text>
      );
    }
    if (noBalance) {
      return (
        <Text
          className={styles.noBalance}
          variant={TextVariants.RegularTextS}
          color="var(--red800)"
        >
          Ingresá dinero para poder realizar el pago
        </Text>
      );
    }

    return;
  };

  useEffect(() => {
    ampli.cardCreationPaymentMethods({ status: noBalance ? "nok" : "ok" });
  }, []);

  return (
    <div className={styles.methodsListContainer}>
      {!noBalance && (
        <>
          <Heading
            variant={HeadingVariants.RegularTitle}
            component="h2"
            color="var(--slate800)"
          >
            ¿Cómo vas a pagar el envío?
          </Heading>
          <div
            className={classNames(styles.list, styles.available, {
              [styles.noUSD]: !hasUSD,
            })}
          >
            {methodsWithFunds.map(
              ({
                name,
                amount,
                amountAvailableArs,
                amountAvailableUsd,
                price,
              }) => (
                <MethodCard
                  key={name}
                  currency={name}
                  availableArs={amountAvailableArs}
                  availableUsd={amountAvailableUsd}
                  amount={amount}
                  price={price}
                  onClick={() => handleOnClick(name)}
                />
              )
            )}
          </div>
        </>
      )}
      {!isEmpty(methodsWithoutFunds) && (
        <>
          <Heading
            variant={HeadingVariants.RegularTitle}
            component="h2"
            color="var(--slate800)"
          >
            Dinero insuficiente
          </Heading>
          <div className={styles.list}>
            {methodsWithoutFunds.map(
              ({
                name,
                amountAvailableArs,
                amountAvailableUsd,
                amount,
                price,
              }) => (
                <MethodCard
                  key={name}
                  currency={name}
                  availableArs={amountAvailableArs}
                  availableUsd={amountAvailableUsd}
                  amount={amount}
                  price={price}
                  disabled
                />
              )
            )}
          </div>
        </>
      )}
      {showExtraLabel()}
    </div>
  );
};

export default MethodsList;
