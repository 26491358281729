import { useEffect, useState } from "react";

import API from "apis";
import MarketRowActions from "components/common/market-row-actions";
import { NewListModal } from "components/common/new-list-modal";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { InstrumentData, MarketData } from "interfaces/api-responses";
import { MarketSection, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import {
  clearSubscribeList,
  setSubscribeList,
} from "features/prices/capitalPricesSlice";
import { marketService } from "apis/services";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getSegment } from "features/markets/marketsActions";

import List from "./List";
import LoaderRows from "./LoaderRows";

import "./index.scss";

const PAGE_SIZE = 50;

const cache = {
  ttl: 90 * 1000,
};

interface MarketListProps {
  hasCurrency?: boolean;
  selectedInstrumentType: MarketSection;
  selectedInstrumentSubtype?: string | null;
  settlementDays: SettlementTerms;
  currency: Currencies;
}

const MarketList: React.FC<MarketListProps> = ({
  currency,
  hasCurrency,
  settlementDays,
  selectedInstrumentType,
  selectedInstrumentSubtype,
}) => {
  const { open } = useAppSelector((state) => state.rightModal);

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [openNewListModal, setOpenNewListModal] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState<InstrumentData>();
  const [marketData, setMarketData] = useState<MarketData>();
  const [updatedMarketData, setUpdatedMarketData] = useState<MarketData>();

  const { isMobile } = useDeviceCheck();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedInstrumentSubtype !== marketData?.instrument_subtype) return;

    setUpdatedMarketData(marketData);
  }, [marketData]);

  const handleToggleDisplayModal = () => {
    setDisplayModal(!displayModal);
  };

  const handleCloseModalWithClear = () => {
    handleToggleDisplayModal();
    setSelectedMarket(undefined);
  };

  const handleAddToNewList = () => {
    handleToggleDisplayModal();
    setOpenNewListModal(true);
  };

  useEffect(() => {
    if (!selectedInstrumentSubtype) return;
    getMarketData(setLoading);
  }, [currency, settlementDays, selectedInstrumentSubtype]);

  const getMarketData = async (
    setLoading: (v: boolean) => void,
    page: number = 1
  ) => {
    try {
      setLoading(true);
      const params = {
        instrument_type: selectedInstrumentType,
        instrument_subtype: selectedInstrumentSubtype,
        settlement_days: settlementDays,
        currency,
        segment: getSegment(selectedInstrumentType),
        size: PAGE_SIZE,
        page,
      };

      const { data } = await API.get<MarketData>(marketService.tickers, {
        params,
        cache,
      });

      setError(false);

      if (page > 1 && marketData) {
        setMarketData({
          ...marketData,
          current_page: page,
          items: [...marketData.items, ...data.items],
        });
        return;
      }
      setMarketData(data);
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status !== 401) {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) return;

    if (marketData?.items) {
      const filteredList = marketData.items.map((s) => s.long_ticker);
      dispatch(setSubscribeList(filteredList));
    }
  }, [dispatch, marketData, open]);

  useEffect(() => {
    return () => {
      dispatch(clearSubscribeList());
    };
  }, []);

  if (error) {
    return <div>Parece que hubo un error..</div>;
  }

  if (loading) {
    return <LoaderRows rowsLength={6} />;
  }

  return (
    <div
      className={`comp-common-market-list ${isMobile ? "mobile" : "desktop"} `}
    >
      <List
        loading={loading}
        selectedInstrumentType={selectedInstrumentType}
        setSelectedMarket={setSelectedMarket}
        handleToggleDisplayModal={handleToggleDisplayModal}
        hasCurrency={hasCurrency}
        getMoreMarketItems={getMarketData}
        marketData={updatedMarketData}
        currency={currency}
      />
      <MarketRowActions
        open={displayModal}
        selectedMarket={selectedMarket}
        onClickCreateList={handleAddToNewList}
        onCloseModal={handleCloseModalWithClear}
        onToggleDisplayModal={handleToggleDisplayModal}
      />
      <NewListModal
        long_ticker={selectedMarket ? selectedMarket.long_ticker : undefined}
        open={openNewListModal}
        setOpenModal={setOpenNewListModal}
      />
    </div>
  );
};

export default MarketList;
