import styles from "./styles.module.scss";

const EllipseThree: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="264"
    height="264"
    viewBox="0 0 264 264"
    fill="none"
    className={styles.ellipseThree}
  >
    <g filter="url(#filter0_f_6166_13618)">
      <ellipse
        cx="132"
        cy="132.002"
        rx="110"
        ry="110.003"
        fill="var(--slate200)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_6166_13618"
        x="0"
        y="-0.000793457"
        width="264"
        height="264.006"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="11"
          result="effect1_foregroundBlur_6166_13618"
        />
      </filter>
    </defs>
  </svg>
);

export default EllipseThree;
