import { PORTFOLIO_CHART_PERIOD } from "constants/portfolio";

import { useEffect } from "react";

import { ampli } from "ampli";
import { usePerformanceQuery } from "apis/portfolio/queries/usePerformanceQuery";
import TimeFrameTabs from "components/common/LineChart/TimeFrameTabs";
import {
  MIN_HOLDING_CRYPTO,
  SelectedTab,
} from "components/page-cocos-portfolio/utils";
import { PortfolioScreen, usePortfolio } from "context/PortfolioProvider";
import {
  PeriodTime,
  PortfolioHoldings,
  SettlementPeriod,
} from "interfaces/portfolio";
import { ThemeVariants } from "interfaces/theme";
import { Currencies, PortfolioFromType } from "interfaces/wallet";
import { usePortfolioQuery } from "apis/portfolio/queries/usePortfolioQuery";

import InstrumentsBalanceCards from "../common/InstrumentsBalanceCards";
import PortfolioSkeletonLoader from "../common/PortfolioSkeletonLoader";

import styles from "./styles.module.scss";

interface ReturnsTabProps {
  variant?: ThemeVariants;
}

const ReturnsTab = ({ variant = ThemeVariants.Capital }: ReturnsTabProps) => {
  const isCapital = variant === ThemeVariants.Capital;
  const portfolioFrom = isCapital
    ? PortfolioFromType.BROKER
    : PortfolioFromType.CRYPTO;

  const {
    selectedCurrency,
    selectedPeriod,
    setSelectedPeriod,
    setPortfolioScreen,
  } = usePortfolio();

  const { data: portfolio, isError: isPortfolioError } = usePortfolioQuery(
    selectedCurrency,
    portfolioFrom
  );

  const isDaily = selectedPeriod === PeriodTime.DAY;

  const { data: performanceResponse, isLoading, isError } = usePerformanceQuery(
    selectedPeriod,
    !isCapital
  );

  const selectedTimeOption = PORTFOLIO_CHART_PERIOD.indexOf(selectedPeriod);

  const timeOptions = [
    { label: PeriodTime.DAY, value: 0 },
    { label: PeriodTime.MAX, value: 4 },
  ];

  const onChangeTime = (option: number) => {
    ampli.portfolioChartRangeSwitched({
      tab: SelectedTab.RETURNS,
      range: PORTFOLIO_CHART_PERIOD[option],
    });
    setSelectedPeriod(PORTFOLIO_CHART_PERIOD[option]);
  };

  useEffect(() => {
    if (selectedPeriod !== PeriodTime.MAX) {
      setSelectedPeriod(PeriodTime.DAY);
    }
  }, []);

  useEffect(() => {
    if (isError || isPortfolioError) {
      setPortfolioScreen(PortfolioScreen.PORTFOLIO_ERROR);
    }
  }, [isError, isPortfolioError]);

  if (isLoading || !performanceResponse) {
    return <PortfolioSkeletonLoader />;
  }

  const filteredHoldings =
    isDaily && !isCapital
      ? performanceResponse.tickers.filter(
          (item) => item.quantity > MIN_HOLDING_CRYPTO
        )
      : performanceResponse.tickers;

  const performanceMapping: PortfolioHoldings[] = filteredHoldings.map(
    (performanceHolding) => ({
      allocation: 0,
      ticker: performanceHolding.instrument_code,
      name: performanceHolding.instrument_short_name,
      shortName: performanceHolding.instrument_short_name,
      price: performanceHolding.last,
      longTicker: "",
      type: performanceHolding.instrument_type,
      subtype: "",
      logo: performanceHolding.logo_file_name,
      isCrypto: !isCapital,
      code: performanceHolding.short_ticker,
      securityId: "",
      toBeSettled: 0,
      resultPercentage:
        performanceHolding.result_percentage ?? performanceHolding.percentage,
      settlements: [
        {
          amount: performanceHolding.result,
          quantity: performanceHolding.quantity,
          period: SettlementPeriod.CI,
        },
        {
          amount: performanceHolding.result,
          quantity: performanceHolding.quantity,
          period: SettlementPeriod.ONE_DAY,
        },
        {
          amount: performanceHolding.result,
          quantity: performanceHolding.quantity,
          period: SettlementPeriod.INF,
        },
      ],
    })
  );

  if (isLoading || !portfolio) return <PortfolioSkeletonLoader />;

  return (
    <div className={styles.desktopWrapper}>
      <div className={styles.returnsBalanceWrapper}>
        <TimeFrameTabs
          timeOptions={timeOptions}
          selectedTime={selectedTimeOption}
          onClickOption={(v: number) => onChangeTime(v)}
        />
      </div>
      <InstrumentsBalanceCards
        holdings={performanceMapping}
        selectedCurrency={Currencies.ARS}
        isPerformance
        variant={variant}
      />
    </div>
  );
};

export default ReturnsTab;
