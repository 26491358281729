import ff from "constants/featureFlags";
import { cryptoStatus } from "constants/cryptos";

import { useState, useEffect } from "react";

import { Browser } from "@capacitor/browser";
import API from "apis";
import { ampli } from "ampli";
import { cryptoService, cardService } from "apis/services";
import useFeatureFlag from "hooks/useFeatureFlag";
import { ThemeVariants } from "interfaces/theme";
import {
  CardSections,
  CardTypes,
  CardTypesLabel,
  UserStatus,
} from "interfaces/card/enums";
import { updateCryptoStatus } from "features/user/userSlice";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import Footer from "components/common/Footer";
import FilledButton from "components/common/FilledButton";
import SegmentedControl from "components/common/SegmentedControl";
import { useCocosCard, cardOptions } from "context/CocosCardProvider";
import { useNavigate, Navigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { useAppSelector, useAppDispatch } from "hooks/redux";

import OutOfStock from "./OutOfStock";
import {
  CARD_PRIVACY_POLICY,
  CARD_SUPPLMENT,
  CARD_TERMS_AND_CONDITIONS,
} from "../constants";
import CardSlider from "../CardSlider";
import Card from "../components/Card";
import LoadingScreen from "../Loading";
import CardErrorScreen from "../Error";

import styles from "./styles.module.scss";

const CardSelection = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getLoggedUser);

  const { isFeatureEnabled: isCardOutOfStock } = useFeatureFlag(
    ff.ENABLE_CARD_OUT_OF_STOCK
  );

  const {
    selectedCard,
    setSelectedCard,
    setSelectedSection,
    pomeloData,
    getPomeloData,
    userStatus,
  } = useCocosCard();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isCardRequested, setIsCardRequested] = useState<boolean>(false);
  const [isOutOfStockOpen, setIsOutOfStockOpen] = useState<boolean>(false);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { status_crypto } = user;
  const liriumAccountCreated = status_crypto === cryptoStatus.APPROVED;
  const isPhysicalCard = selectedCard === CardTypesLabel.PHYSICAL;

  const onClose = () => {
    navigate(mainRoutes.home);
  };

  const createCustomer = async () => {
    const { data } = await API.post<any>(cryptoService.customer);
    dispatch(updateCryptoStatus(data.status));

    if (data.status === cryptoStatus.PENDING_DOCUMENTS) uploadDocuments();
    else throw new Error("Error in create customer");
  };

  const uploadDocuments = async () => {
    const { data } = await API.post<any>(cryptoService.uploadDocuments);
    dispatch(updateCryptoStatus(data.status));

    if (data.status === cryptoStatus.PENDING_ADDRESSES) createAddress();
    else throw new Error("Error in upload documents");
  };

  const createAddress = async () => {
    const { data } = await API.post<any>(cryptoService.depositAddress);
    dispatch(updateCryptoStatus(data.status));

    if (data.status === cryptoStatus.APPROVED) {
      isCardOutOfStock || !isPhysicalCard
        ? createVirtualCard()
        : setSelectedSection(CardSections.PHYSICAL_INTRO);
    } else throw new Error("Error in create address");
  };

  const createLiriumAccount = async () => {
    setIsLoading(true);

    try {
      if (status_crypto === cryptoStatus.PENDING || !status_crypto) {
        await createCustomer();
      }
      if (status_crypto === cryptoStatus.PENDING_DOCUMENTS) {
        await uploadDocuments();
      }
      if (status_crypto === cryptoStatus.PENDING_ADDRESSES) {
        await createAddress();
      }
    } catch (error: any) {
      setError(true);
    }
  };

  const createVirtualCard = async () => {
    setIsLoading(true);

    try {
      const requestCardCreation = [CardTypes.VIRTUAL];
      const endpoint = pomeloData ? cardService.requestCard : cardService.user;

      await API.post(endpoint, { requestCardCreation });

      setIsCardRequested(true);
    } catch (error) {
      ampli.cardErrorCreationFailed({
        card_type: CardTypes.VIRTUAL,
      });
      setError(true);
    }
  };

  useEffect(() => {
    if (isCardRequested) {
      if (userStatus === UserStatus.ACTIVE) {
        setIsLoading(false);
        setSelectedSection(CardSections.VIRTUAL_READY);
      } else if (userStatus === UserStatus.FAILED) {
        setIsLoading(false);
        setError(true);
      }

      const interval = setInterval(() => {
        getPomeloData();
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [userStatus, isCardRequested]);

  const startCardCreation = () => {
    ampli.cardRequestCard({ card_type: selectedCard });
    if (liriumAccountCreated) {
      return isPhysicalCard
        ? setSelectedSection(CardSections.PHYSICAL_INTRO)
        : createVirtualCard();
    }

    return createLiriumAccount();
  };

  const handleOnContinue = () => {
    if (isCardOutOfStock) {
      setIsOutOfStockOpen(true);
      return;
    }

    startCardCreation();
  };

  const outOfStockContinue = () => {
    setIsOutOfStockOpen(false);
    liriumAccountCreated ? createVirtualCard() : createLiriumAccount();
  };

  const navigationHeaderProps = {
    iconColor: "var(--black-to-white)",
    title: "Cocos Card",
    onClick: onClose,
    withCloseIcon: false,
  };

  const cardType = isPhysicalCard ? "física" : "virtual";

  const cardText = isPhysicalCard
    ? "Experimentá la libertad de comprar con una tarjeta contactless global en cualquier comercio."
    : "Comprá online y suscribite a tus servicios favoritos de forma ágil y segura.";

  const handleTabClick = (option: CardTypesLabel) => {
    setSelectedCard(option);
  };

  const onClickLegals = (url: string) => {
    Browser.open({ url });
  };

  useEffect(() => {
    isPhysicalCard ? ampli.physicalCardNotStarted : ampli.virtualCardNotStarted;
  }, [isPhysicalCard]);

  if (isLoading)
    return (
      <LoadingScreen
        title="Configurando tu cuenta..."
        subtitle="Esto puede tardar unos segundos."
      />
    );

  if (error) return <CardErrorScreen />;

  return (
    <BasicWrapper
      navigationHeaderProps={navigationHeaderProps}
      className={styles.cardSelectionContainer}
      themeVariant={ThemeVariants.Pay}
    >
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
        className={styles.titleHeader}
      >
        Pedí tu tarjeta
      </Heading>
      <div className={styles.tabControls}>
        <SegmentedControl
          options={cardOptions}
          selected={selectedCard}
          onClick={(option) => handleTabClick(option)}
        />
      </div>
      <CardSlider
        physicalCard={<Card type={CardTypes.PHYSICAL} />}
        virtualCard={<Card type={CardTypes.VIRTUAL} />}
      />
      <div className={styles.textWrapper}>
        <Text
          variant={TextVariants.RegularTextL}
          color="var(--slate900)"
        >{`Tarjeta ${cardType}`}</Text>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {cardText}
        </Text>
        <Footer>
          <Text variant={TextVariants.RegularTextS} color="var(--slate600)">
            Al continuar, confirmo haber leído y acepto los{" "}
            <Text
              variant={TextVariants.SemiboldTextS}
              component="span"
              color="var(--slate900)"
              onClick={() => onClickLegals(CARD_TERMS_AND_CONDITIONS)}
            >
              Términos y Condiciones
            </Text>
            ,{" "}
            <Text
              variant={TextVariants.SemiboldTextS}
              component="span"
              color="var(--slate900)"
              onClick={() => onClickLegals(CARD_SUPPLMENT)}
            >
              Suplemento
            </Text>{" "}
            y{" "}
            <Text
              variant={TextVariants.SemiboldTextS}
              component="span"
              color="var(--slate900)"
              onClick={() => onClickLegals(CARD_PRIVACY_POLICY)}
            >
              Política de Privacidad
            </Text>{" "}
            de Cocos .
          </Text>
          <FilledButton onClick={handleOnContinue} variant={ThemeVariants.Pay}>
            Empezar
          </FilledButton>
        </Footer>
      </div>

      {isOutOfStockOpen && (
        <OutOfStock
          open={isOutOfStockOpen}
          onContinue={outOfStockContinue}
          onClose={() => setIsOutOfStockOpen(false)}
        />
      )}
    </BasicWrapper>
  );
};

export default CardSelection;
