import { useState } from "react";

import { X } from "lucide-react";
import PageHeader from "components/common/PageHeader";
import Banner, { BannerVariants } from "components/common/Banner";
import InputField from "components/common/input/InputField";
import { useNavigate } from "react-router-dom";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import { useCocosPay } from "context/CocosPayProvider";
import { PaySteps } from "interfaces/pay/enums";
import OutlinedButton from "components/common/OutlinedButton";
import API from "apis";
import { PaymentData } from "interfaces/pay/interface";
import { paymentService } from "apis/services";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";

import CodeBottomSheet from "./CodeBottomSheet";

import styles from "./styles.module.scss";

const PayCode: React.FC = () => {
  const navigate = useNavigate();
  const { setStep } = useCocosPay();
  const { isIOS } = getPlatform();

  const [value, setValue] = useState<string>("");
  const [paymentData, setPaymentData] = useState<PaymentData>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleOnChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const searchValue = async () => {
    setIsLoading(true);
    try {
      const { data } = await API.get<PaymentData>(paymentService.qr, {
        params: {
          qrData: value,
        },
      });

      setPaymentData({ ...data, isChave: true });
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnCloseBottomSheet = () => {
    setError(false);
    setPaymentData(undefined);
  };

  return (
    <div
      className={classNames(styles.payCodeContainer, { [styles.isIos]: isIOS })}
    >
      <div className={styles.wrapper}>
        <PageHeader
          className={styles.header}
          icon={X}
          title="Chave Pix"
          onClick={onClickBack}
          iconColor="var(--black-to-white)"
        />
        <Banner
          variant={BannerVariants.INFO_PAY}
          className={styles.info}
          text="Ingresa una chave Pix, teléfono, correo electrónico, CPF o CNPJ o escanea un QR"
        />
        <InputField
          label="Ingresá la chave Pix"
          placeholder="teléfono, correo electrónico, CPF o CNPJ"
          withPaste
          variant={ThemeVariants.Pay}
          value={value}
          onChange={handleOnChangeValue}
          onClickPaste={setValue}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <FilledButton
          variant={ThemeVariants.Pay}
          disabled={value.length < 1}
          onClick={searchValue}
          isLoading={isLoading}
        >
          Buscar
        </FilledButton>
        <OutlinedButton
          variant={ThemeVariants.Pay}
          onClick={() => setStep(PaySteps.QR)}
        >
          Escanear QR
        </OutlinedButton>
      </div>
      <CodeBottomSheet
        open={!!paymentData || error}
        paymentData={paymentData}
        error={error}
        onClose={handleOnCloseBottomSheet}
      />
    </div>
  );
};

export default PayCode;
