import { size } from "lodash";

import Bullet from "./Bullet";

import styles from "./styles.module.scss";

interface PasswordBulletsProps {
  password: string;
}

const PasswordBullets: React.FC<PasswordBulletsProps> = ({ password }) => {
  const MIN_LENGTH = 8;

  const passwordValidations = [
    {
      label: "8 caracteres o más.",
      validated: size(password) >= MIN_LENGTH,
    },
    {
      label: "Tanto letras mayúsculas como minúsculas.",
      validated: /^(?=.*[a-z])(?=.*[A-Z]).*$/.test(password),
    },
    {
      label: "Al menos un número y un símbolo (como .!@#$%^).",
      validated: /^(?=.*\d)(?=.*[\W_]).*$/.test(password),
    },
  ];
  return (
    <div className={styles.PasswordBulletsContainer}>
      {passwordValidations.map(({ label, validated }) => (
        <Bullet key={label} label={label} validated={validated} />
      ))}
    </div>
  );
};

export default PasswordBullets;
