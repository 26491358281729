import { useEffect, useState } from "react";

import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

const CameraTimer = () => {
  const [remainingTime, setRemainingTime] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    startCountdown(5);

    return () => {
      setRemainingTime(0);
    };
  }, []);

  const startCountdown = (remainingSeconds: number) => {
    setRemainingTime(remainingSeconds);

    if (timer && remainingTime <= 0) {
      clearInterval(timer);
    }

    const tempId = setInterval(() => {
      setRemainingTime((prev: number) => prev - 1);
    }, 1000);

    setTimer(tempId);
  };

  if (remainingTime <= 0) return null;

  return (
    <div className={styles.cameraTimer}>
      <Text variant={TextVariants.RegularTextXL} className={styles.counter}>
        {remainingTime}
      </Text>
    </div>
  );
};

export default CameraTimer;
