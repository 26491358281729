import dayjs from "dayjs";
import { ampli } from "ampli";
import { Browser } from "@capacitor/browser";
import { ArrowUpRight } from "lucide-react";
import { ThemeVariants } from "interfaces/theme";
import { CardSections, CardStatus } from "interfaces/card/enums";
import { useCocosCard } from "context/CocosCardProvider";
import VerticalSteps from "components/common/VerticalSteps";
import Heading, { HeadingVariants } from "components/common/Heading";
import Link from "components/common/Link";
import FilledButton from "components/common/FilledButton";

import styles from "./styles.module.scss";

const ShippingDetail: React.FC = () => {
  const { setSelectedSection, physicalCard } = useCocosCard();

  if (!physicalCard) {
    return null;
  }

  const {
    startDate,
    courierCompany,
    courierTrackingUrl,
    status,
  } = physicalCard;

  const isShippingRejected = status === CardStatus.REJECTED;

  const onActivateCard = () => {
    ampli.cardActivateCard();
    setSelectedSection(CardSections.PHYSICAL_ACTIVATION);
  };

  return (
    <div className={styles.emptyCardContent}>
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
        className={styles.titleHeader}
      >
        ¿Qué pasó con mi tarjeta?
      </Heading>
      <div className={styles.verticalSteps}>
        {isShippingRejected ? (
          <VerticalSteps
            steps={[
              {
                label: "Pediste tu tarjeta física",
                value: `${dayjs(startDate).format(
                  "DD/MM/YYYY"
                )} | Preparando envío`,
              },
              {
                label: "Hubo un error en tu pedido",
                value: `${courierCompany} rechazó el envío de tu tarjeta.`,
                isWarning: true,
              },
            ]}
            variant={ThemeVariants.Pay}
          />
        ) : (
          <>
            <VerticalSteps
              steps={[
                {
                  label: "Pediste tu tarjeta física",
                  value: `${dayjs(startDate).format(
                    "DD/MM/YYYY"
                  )} | Preparando envío`,
                },
                {
                  label: "Tu tarjeta esta en camino",
                  value: `${courierCompany} te va a entregar tu tarjeta.`,
                },
              ]}
              variant={ThemeVariants.Pay}
            />
            {courierTrackingUrl && (
              <div className={styles.linkContainer}>
                <Link
                  onClick={() => Browser.open({ url: courierTrackingUrl })}
                  variant={ThemeVariants.Pay}
                  icon={ArrowUpRight}
                  isSmall
                >
                  Seguí el envío acá
                </Link>
              </div>
            )}
          </>
        )}
      </div>
      {isShippingRejected ? (
        <FilledButton
          variant={ThemeVariants.Pay}
          onClick={() => setSelectedSection(CardSections.PHYSICAL_INTRO)}
        >
          Volver a intentarlo
        </FilledButton>
      ) : (
        <FilledButton variant={ThemeVariants.Pay} onClick={onActivateCard}>
          Activar mi tarjeta
        </FilledButton>
      )}
    </div>
  );
};

export default ShippingDetail;
