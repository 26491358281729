import { UIModalSlider } from "components/common/modal-slider";
import Heading, { HeadingVariants } from "components/common/Heading";
import Asset from "components/common/Asset";
import { QrCode } from "lucide-react";
import PixIcon from "assets/icons/Pix";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface PixBottomSheetProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const PixBottomSheet = ({ isOpen, setIsOpen }: PixBottomSheetProps) => {
  const navigate = useNavigate();
  const { isIOS } = getPlatform();

  const onClickBack = () => {
    setIsOpen(false);
  };

  const onClickOption = (isPix?: boolean) => {
    navigate(mainRoutes.pay, {
      state: { isPix },
    });
  };

  return (
    <UIModalSlider open={isOpen} onToggleDisplay={onClickBack}>
      <div
        className={classNames(styles.bottomSheetContainer, {
          [styles.isIos]: isIOS,
        })}
      >
        <Heading
          variant={HeadingVariants.SemiboldSubtitle}
          color="var(--slate800)"
          component="h2"
          className={styles.title}
        >
          Pago con Pix
        </Heading>
        <div className={styles.list}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            color="var(--slate800)"
            component="h2"
          >
            ¿Cómo vas a pagar?
          </Heading>
          <Asset
            icon={PixIcon}
            title="Chave Pix"
            subtitle="Teléfono, correo electrónico, CPF o CNPJ"
            onClick={() => onClickOption(true)}
          />
          <Asset
            icon={QrCode}
            title="Escanear QR"
            subtitle="Pagá en segundos"
            onClick={onClickOption}
          />
        </div>
      </div>
    </UIModalSlider>
  );
};

export default PixBottomSheet;
