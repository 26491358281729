import { CocosFunds } from "constants/instruments";

import React from "react";

import { ArrowUpRight } from "lucide-react";
import classNames from "classnames";
import { formatPercentage } from "utils";
import { getAnnualRate } from "utils/getAnnualRate";
import { Currencies } from "interfaces/wallet";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface GradientCardProps {
  className?: string;
  link: string;
  onClick: () => void;
  subtitle?: string;
  title: string;
  variation?: number;
  isBanner?: boolean;
  currency?: Currencies;
}

const GradientCard: React.FC<GradientCardProps> = ({
  className,
  link,
  onClick,
  subtitle,
  title,
  variation,
  isBanner,
  currency = Currencies.USD,
}) => {
  const isUSD = currency === Currencies.USD;
  const annualRate = getAnnualRate(isUSD ? CocosFunds.USD : CocosFunds.RMA);
  const variationLabel = variation && `${formatPercentage(variation, false)} `;

  return (
    <div
      onClick={onClick}
      className={classNames(styles.cardContainer, className)}
    >
      <div>
        {variation && (
          <div className={styles.variantLabel}>
            <Text variant={TextVariants.RegularTextS} color="var(--green800)">
              <span>{variationLabel}</span>anual
            </Text>
          </div>
        )}
        <Text variant={TextVariants.RegularTextL} color="var(--slate900)">
          {title}
        </Text>

        {isBanner ? (
          <Text
            variant={TextVariants.RegularTextS}
            className={styles.bannerText}
          >
            Fondo Común de Inversión |{" "}
            <span>Tasa estimada {formatPercentage(annualRate, false)}</span>
          </Text>
        ) : (
          <Text
            variant={TextVariants.RegularTextS}
            className={styles.subtitle}
            color="var(--slate800)"
          >
            {subtitle}
          </Text>
        )}
      </div>
      <div className={styles.bottomLink}>
        <Text
          variant={TextVariants.RegularTextL}
          className={styles.linkText}
          color="var(--blue800)"
        >
          {link}
        </Text>
        <ArrowUpRight color="var(--blue800)" size={16} />
      </div>
    </div>
  );
};

export default GradientCard;
