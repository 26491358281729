import { PortfolioTabs } from "constants/portfolio";

import { ampli } from "ampli";
import { fetchBalance } from "apis/portfolio/queries/useBalance";
import { fetchPortfolioData } from "apis/portfolio/queries/usePortfolioQuery";
import { queryClient } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import CurrencySwitch from "components/common/CurrencySwtich";
import Text, { TextVariants } from "components/common/Text";
import { usePortfolio } from "context/PortfolioProvider";
import { ThemeVariants } from "interfaces/theme";
import { Currencies, PortfolioFromType } from "interfaces/wallet";

import styles from "./styles.module.scss";

interface PortfolioCurrencySwitchProps {
  variant?: ThemeVariants;
}

const PortfolioCurrencySwitch: React.FC<PortfolioCurrencySwitchProps> = ({
  variant = ThemeVariants.Capital,
}) => {
  if (variant === ThemeVariants.Crypto) return null;

  const portfolioFrom =
    variant === ThemeVariants.Capital
      ? PortfolioFromType.BROKER
      : PortfolioFromType.CRYPTO;

  const {
    selectedPeriod,
    selectedCurrency,
    setSelectedCurrency,
  } = usePortfolio();

  const handleClickSwitch = async () => {
    const switchOption =
      selectedCurrency === Currencies.ARS ? Currencies.USD : Currencies.ARS;

    ampli.portfolioCurrencySwitched({
      tab: PortfolioTabs.VALUE,
      currency: switchOption,
    });
    setSelectedCurrency(switchOption);

    await queryClient.fetchQuery(
      portfolioKeys.portfolio(switchOption, portfolioFrom),
      () => fetchPortfolioData(switchOption, portfolioFrom)
    );
    await queryClient.fetchQuery(
      portfolioKeys.balance(switchOption, selectedPeriod),
      () =>
        fetchBalance({
          currency: switchOption,
          period: selectedPeriod,
        })
    );
  };

  return (
    <div className={styles.switchAmount}>
      <Text
        variant={TextVariants.RegularTextL}
        color="var(--slate800)"
        className={styles.switchAmountText}
      >
        Expresado en
      </Text>
      <CurrencySwitch
        selectedCurrency={selectedCurrency}
        onClickSwitch={handleClickSwitch}
      />
    </div>
  );
};

export default PortfolioCurrencySwitch;
