import { CocosTariffURL } from "constants/index";

import { Typography } from "@mui/material";

interface CurrentTariffProps {
  newClass?: string;
}

const CurrentTariff: React.FC<CurrentTariffProps> = ({ newClass }) => {
  return (
    <Typography className={newClass ?? ""}>
      Esta operación tiene comisiones según el&nbsp;
      <a href={CocosTariffURL} target="_blank" rel="noreferrer">
        tarifario vigente.
      </a>
    </Typography>
  );
};

export default CurrentTariff;
