import { COPY_MESSAGE_TIME } from "constants/index";

import { useState } from "react";

import { Copy } from "lucide-react";
import { Clipboard } from "@capacitor/clipboard";
import Banner, { BannerVariants } from "components/common/Banner";
import BankAccountCard from "components/common/BankAccountCard";
import Heading, { HeadingVariants } from "components/common/Heading";
import Toast, { ToastVariants } from "components/common/Toast";
import VerticalSteps from "components/common/VerticalSteps";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

export enum ReceiveCurrency {
  ARS = "COCOS.CAP",
  USD = "COCOS.MEP",
}

interface ReceiveProps {
  aliasCurrency: ReceiveCurrency;
}

const CUIT_COCOS = "30-70842447-8";

const ReceivePage: React.FC<ReceiveProps> = ({ aliasCurrency }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const copyCVU = async () => {
    try {
      await Clipboard.write({ string: aliasCurrency });
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, COPY_MESSAGE_TIME);
    } catch (error) {
      console.log(error);
    }
  };

  const textCurrency =
    aliasCurrency === ReceiveCurrency.ARS ? "pesos" : "dólares";

  return (
    <div className={styles.receiveContainer}>
      <Banner
        className={styles.banner}
        text={`Únicamente podés recibir ${textCurrency} de cuentas que estén a tu nombre.`}
        variant={BannerVariants.WARNING}
      />
      <BankAccountCard
        items={[
          { label: "Alias", value: aliasCurrency, isBold: true },
          { label: "CUIT", value: CUIT_COCOS },
        ]}
        button={{
          icon: Copy,
          onClick: copyCVU,
          text: "Copiar Alias",
          variant: ThemeVariants.Capital,
        }}
      />
      <Heading
        component="h3"
        color="var(--slate800)"
        className={styles.title}
        variant={HeadingVariants.RegularTitle}
      >
        Cómo ingresar {textCurrency}
      </Heading>
      <VerticalSteps
        steps={[
          {
            label: "Copiá el alias",
            value: "Corroborá que sea el correcto al transferir.",
          },
          {
            label: "Realizá la transferencia",
            value: "Usá ese Alias como destinatario.",
          },
          {
            label: "¡Listo!",
            value: "El dinero se acreditará de manera automática.",
          },
        ]}
        className={styles.verticalSteps}
        variant={ThemeVariants.Capital}
      />
      {copied && (
        <Toast
          variant={ToastVariants.Success}
          onClose={() => setCopied(false)}
          message="Alias copiado exitosamente"
        />
      )}
    </div>
  );
};

export default ReceivePage;
