import { useEffect, useState } from "react";

import {
  BarcodeScanner,
  BarcodeValueType,
  LensFacing,
} from "@capacitor-mlkit/barcode-scanning";
import { ampli } from "ampli";
import classNames from "classnames";
import IconButton, { IconButtonSizes } from "components/common/IconButton";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import { useCocosPay } from "context/CocosPayProvider";
import { PayError, PaySteps } from "interfaces/pay/enums";
import { ThemeVariants } from "interfaces/theme";
import { ArrowUpRight, Flashlight, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Heading, { HeadingVariants } from "components/common/Heading";
import px2rem from "utils/px2rem";

import PayLegals from "../Legals";
import CameraVisor from "./components/CameraVisor";
import QRErrorModal from "./components/ErrorModal";

import styles from "./styles.module.scss";

const PayQR: React.FC = () => {
  const { onScanQR, error, setError, setStep } = useCocosPay();

  const navigate = useNavigate();

  const [enabledTorch, setEnabledTorch] = useState<boolean>(false);

  useEffect(() => {
    ampli.payScreenScanQr();
  }, []);

  useEffect(() => {
    BarcodeScanner.isSupported().then((result) => {
      if (!result.supported) {
        setError(PayError.UNSUPPORTED_DEVICE);
      }
    });
    if (!error) {
      initialize();
    }

    return () => {
      stopScan();
      setError(undefined);
    };
  }, []);

  useEffect(() => {
    if (error === PayError.UNSUPPORTED_DEVICE) {
      setTimeout(() => {
        navigate(-1);
      }, 2500);
    }
    if (error) {
      stopScan();
    }
  }, [error]);

  const startScan = async () => {
    document.querySelector("body")?.classList.add("barcode-scanner-active");
    const listener = await BarcodeScanner.addListener(
      "barcodeScanned",
      async (result) => {
        await listener.remove();
        document
          .querySelector("body")
          ?.classList.remove("barcode-scanner-active");
        await BarcodeScanner.stopScan();

        onScanQR(result.barcode.rawValue);
      }
    );

    await BarcodeScanner.startScan({ lensFacing: LensFacing.Back });
  };

  const stopScan = async () => {
    document.querySelector("body")?.classList.remove("barcode-scanner-active");

    await BarcodeScanner.removeAllListeners();

    await BarcodeScanner.stopScan();
  };

  const initialize = async () => {
    const { camera } = await BarcodeScanner.requestPermissions();
    if (camera === "granted" || camera === "limited") {
      startScan();
      return;
    }
    ampli.payScreenAccessCamara({ camara_status: camera });
    setError(PayError.PERMISSIONS_DENIED);
  };

  const toggleTorch = async () => {
    if (enabledTorch) {
      await BarcodeScanner.disableTorch();
      setEnabledTorch(false);
      return;
    }
    await BarcodeScanner.enableTorch();
    setEnabledTorch(true);
  };

  const onClickExit = () => {
    ampli.payExit({ referral: "scan_qr" });
    navigate(-1);
  };

  const onToggleDisplay = () => {
    setError(undefined);
    startScan();
  };

  return (
    <div className={classNames(styles.scanContainer)}>
      <div className={styles.topWrapper}>
        <PageHeader
          icon={X}
          title="Escaneá el QR para pagar"
          onClick={onClickExit}
          iconColor="#fff"
          titleColor="#fff"
        />
        <Text
          className={styles.info}
          variant={TextVariants.RegularTextS}
          color="#fff"
        >
          Acerca más la camara
        </Text>
      </div>
      <CameraVisor />
      <div className={styles.bottomWrapper}>
        <div className={styles.pix} onClick={() => setStep(PaySteps.CODE)}>
          <Heading
            variant={HeadingVariants.SemiboldSubtitle}
            className={styles.pixLabel}
            color="#fff"
            component="h3"
          >
            Pagar con Chave Pix
          </Heading>
          <ArrowUpRight size={px2rem(24)} color="#fff" />
        </div>

        <IconButton
          size={IconButtonSizes.XLarge}
          variant={ThemeVariants.Pay}
          icon={Flashlight}
          iconSize={19.2}
          onClick={toggleTorch}
          color="#fff"
        />
        <PayLegals iconColor="#fff" textColor="var(--slate400)" />
      </div>
      {error && (
        <QRErrorModal error={error} onToggleDisplay={onToggleDisplay} />
      )}
    </div>
  );
};

export default PayQR;
