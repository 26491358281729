import { PaymentData } from "interfaces/pay/interface";
import Heading, { HeadingVariants } from "components/common/Heading";
import PixIcon from "assets/icons/Pix";
import px2rem from "utils/px2rem";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { useCocosPay } from "context/CocosPayProvider";
import { PaySteps, Payment } from "interfaces/pay/enums";
import { ThemeVariants } from "interfaces/theme";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface ChaveConfirmProps {
  paymentData: PaymentData;
  onClose: () => void;
}

const ChaveConfirm: React.FC<ChaveConfirmProps> = ({
  paymentData,
  onClose,
}) => {
  const { setStep, setPaymentData } = useCocosPay();
  const { isIOS } = getPlatform();

  const onClickContinue = () => {
    setPaymentData(paymentData);
    const nextStep =
      paymentData.paymentType === Payment.CLOSE
        ? PaySteps.METHOD
        : PaySteps.AMOUNT;
    setStep(nextStep);
  };
  return (
    <div
      className={classNames(styles.chaveConfirmContainer, {
        [styles.isIos]: isIOS,
      })}
    >
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        color="var(--slate900)"
        component="h2"
      >
        Confirmá el destinatario
      </Heading>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <PixIcon size={px2rem(24)} />
        </div>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h3"
          color="var(--slate900)"
        >
          Chave Pix
        </Heading>
      </div>
      <div className={styles.info}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Nombre
        </Text>
        <Text
          variant={TextVariants.RegularText}
          className={styles.businessName}
          color="var(--slate900)"
        >
          {paymentData.businessName}
        </Text>
      </div>
      <div className={styles.buttonsWrapper}>
        <OutlinedButton variant={ThemeVariants.Pay} onClick={onClose}>
          Volver
        </OutlinedButton>
        <FilledButton variant={ThemeVariants.Pay} onClick={onClickContinue}>
          Continuar
        </FilledButton>
      </div>
    </div>
  );
};

export default ChaveConfirm;
