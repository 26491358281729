import { useEffect } from "react";

import USFlag from "assets/icons/USFlag";
import Heading, { HeadingVariants } from "components/common/Heading";
import SingleQuoteCard from "components/common/SingleQuoteCard";
import { getDolarMep } from "features/markets/marketsActions";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { DolarTypes } from "interfaces/markets";
import { RootState } from "store/store";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

const MepPricesSection: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const dispatch = useAppDispatch();
  const dolarMep = useAppSelector((state: RootState) => state.markets.dolarMep);
  const dolarType = DolarTypes.OPEN;

  useEffect(() => {
    const intervalCall = setInterval(() => {
      dispatch(getDolarMep());
    }, 45000);

    return () => {
      clearInterval(intervalCall);
    };
  }, [dolarMep]);

  return (
    <div className={styles.mepPricesContainer}>
      <Heading
        className={styles.title}
        variant={HeadingVariants.RegularSubtitle}
        component="h3"
        color="var(--slate800)"
      >
        Operá 24/7
      </Heading>
      <SingleQuoteCard
        icon={<USFlag size={px2rem(20)} />}
        name="Dólar MEP"
        bid={dolarMep[dolarType].ask ?? 0}
        ask={dolarMep[dolarType].bid ?? 0}
        onClick={onClick}
      />
    </div>
  );
};

export default MepPricesSection;
