import { useEffect, useState } from "react";

import BasicWrapper from "components/common/BasicWrapper";
import Code2FA, { MfaChallengeStatus } from "components/common/Code2FA";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { PhoneRegisterResponse } from "interfaces/api-responses";
import { ThemeVariants } from "interfaces/theme";
import { useAuth } from "context/AuthProvider";
import { ampli } from "ampli";

import styles from "./styles.module.scss";

dayjs.extend(utc);

interface PhoneVerificationProps {
  phoneNumber: string;
  countryCode?: number;
  showForm: () => void;
  registerPhone: () => void;
  challengeData?: PhoneRegisterResponse;
}

const PhoneVerification: React.FC<PhoneVerificationProps> = ({
  showForm,
  countryCode,
  phoneNumber,
  challengeData,
  registerPhone,
}) => {
  const codesInitialState = ["", "", "", "", "", ""];
  const { verifyPhone, loading, error } = useAuth();

  const [code, setCode] = useState<string>("");
  const [codes, setCodes] = useState<string[]>(codesInitialState);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    ampli.phoneVerificationCodeScreenViewed({ referral: "signup" });
  }, []);

  useEffect(() => {
    if (!challengeData || challengeData.expires_at === null) return;

    startCountdown(dayjs(challengeData.expires_at).diff(dayjs.utc(), "second"));

    return () => {
      setRemainingTime(0);
    };
  }, [challengeData?.expires_at]);

  useEffect(() => {
    const isCodeCompleted = code.length === 6;

    if (isCodeCompleted) {
      if (!challengeData?.id) return;
      verifyPhone(
        {
          mfaCode: code,
          mfaFactorId: "phone",
          mfaChallengeId: challengeData.id,
        },
        () => setCodes(codesInitialState)
      );
    }
  }, [code]);

  const startCountdown = (remainingSeconds: number) => {
    setRemainingTime(remainingSeconds);

    if (timer && remainingTime <= 0) {
      clearInterval(timer);
    }

    const tempId = setInterval(() => {
      setRemainingTime((prev: number) => prev - 1);
    }, 1000);

    setTimer(tempId);
  };

  const onChangeCode = (value: string) => {
    setCode(value);
  };

  const remainingTimeFormatted = dayjs(remainingTime * 1000).format("mm:ss");

  return (
    <BasicWrapper
      navigationHeaderProps={{
        onClick: showForm,
        withCloseIcon: false,
      }}
      title="Ingresá el código"
      subtitle={`Escribí o pegá el código enviado al: +${countryCode} ${phoneNumber}`}
    >
      <Code2FA
        codes={codes}
        status={error ? MfaChallengeStatus.ERROR : null}
        setCodes={setCodes}
        disabled={loading}
        onChange={onChangeCode}
        variant={ThemeVariants.Capital}
      />
      {error && (
        <Text
          variant={TextVariants.RegularTextS}
          color="var(--red800)"
          className={styles.errorMessage}
        >
          El código que ingresaste es incorrecto. Por favor, volvé a ingresar el
          código nuevamente.
        </Text>
      )}
      <div className={styles.buttonContainer}>
        <OutlinedButton onClick={registerPhone} disabled={remainingTime >= 0}>
          {remainingTime >= 0
            ? `Reenviar código en ${remainingTimeFormatted}`
            : "Reenviar código"}
        </OutlinedButton>
      </div>
    </BasicWrapper>
  );
};

export default PhoneVerification;
