import { useCocosCard } from "context/CocosCardProvider";
import { CardSections } from "interfaces/card/enums";
import Icon, { IconSizes, IconVariants } from "components/common/Icon";
import PageHeader from "components/common/PageHeader";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { ArrowLeft, Receipt } from "lucide-react";

import styles from "./styles.module.scss";

export interface MethodHeaderProps {
  balance: boolean;
  quantity: number;
}

const MethodHeader: React.FC<MethodHeaderProps> = ({ balance, quantity }) => {
  const { setSelectedSection } = useCocosCard();

  const iconVariant = balance ? IconVariants.PAY : IconVariants.FAIL;

  return (
    <div className={styles.methodHeaderContainer}>
      <PageHeader
        icon={ArrowLeft}
        withIconTitle
        iconColor="var(--black-to-white)"
        iconTitle={
          <Icon size={IconSizes.SMALL} variant={iconVariant} icon={Receipt} />
        }
        onClick={() => setSelectedSection(CardSections.SHIPPING_INFO)}
      />
      <PriceLabel price={quantity} size={PriceLabelSizes.Large} />
    </div>
  );
};

export default MethodHeader;
