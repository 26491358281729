import { IconProps } from "assets/icons/BTC";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Finance: React.FC<IconProps> = ({ size, className, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 94 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames([className, styles.svg])}
    {...props}
  >
    <g clipPath="url(#clip0_14549_16229)">
      <path
        d="M42.6083 0H3.9915C1.78706 0 0 1.78706 0 3.9915V29.2056C0 31.4101 1.78706 33.1971 3.9915 33.1971H42.6083C44.8128 33.1971 46.5998 31.4101 46.5998 29.2056V3.9915C46.5998 1.78706 44.8128 0 42.6083 0Z"
        fill="url(#paint0_linear_14549_16229)"
      />
      <path
        d="M89.2079 37.4023H50.5911C48.3867 37.4023 46.5996 39.1894 46.5996 41.3938V80.0107C46.5996 82.2151 48.3867 84.0022 50.5911 84.0022H89.2079C91.4124 84.0022 93.1994 82.2151 93.1994 80.0107V41.3938C93.1994 39.1894 91.4124 37.4023 89.2079 37.4023Z"
        fill="url(#paint1_linear_14549_16229)"
      />
      <path
        d="M59.5908 61.0649H55.9453V73.7866H59.5908V61.0649Z"
        fill="white"
      />
      <path
        d="M65.7041 65.7573H62.0586V73.7866H65.7041V65.7573Z"
        fill="white"
      />
      <path d="M77.9296 64.437H74.2842V73.7865H77.9296V64.437Z" fill="white" />
      <path
        d="M71.8164 61.0649H68.1709V73.7866H71.8164V61.0649Z"
        fill="white"
      />
      <path d="M84.042 51.9575H80.3965V73.7862H84.042V51.9575Z" fill="white" />
      <path
        d="M33.8955 7.00879H39.4893V12.6026"
        stroke="#1C2024"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M39.4894 7.00879L32.5996 13.8964"
        stroke="#1C2024"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M6.24609 26.9107L21.7008 11.4561L28.3701 18.1255L32.5997 13.8959"
        stroke="#1C2024"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M19.6162 46.2578V83.9996L31.2976 73.9889C32.5237 72.9319 33.507 71.6226 34.1803 70.1505C34.8536 68.6783 35.201 67.0781 35.1987 65.4593V46.2578H19.6162Z"
        fill="#0588F0"
      />
      <path
        d="M4.01172 46.2578V65.4593C4.01216 67.0798 4.36298 68.6811 5.04014 70.1533C5.71729 71.6256 6.70477 72.934 7.93489 73.9889L19.6163 83.9996V46.2578H4.01172Z"
        fill="#70B8FF"
      />
      <path
        d="M19.6161 49.7759H7.39258V64.922C7.39345 66.1908 7.6682 67.4446 8.19807 68.5975C8.72794 69.7504 9.50044 70.7754 10.4628 71.6024L19.6161 79.4443L28.7562 71.6024C29.7198 70.7763 30.4932 69.7515 31.0236 68.5984C31.5539 67.4453 31.8285 66.1912 31.8287 64.922V49.7759H19.6161Z"
        stroke="#1C2024"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M72.2213 30.7727C80.7189 30.7727 87.6076 23.884 87.6076 15.3863C87.6076 6.8887 80.7189 0 72.2213 0C63.7237 0 56.835 6.8887 56.835 15.3863C56.835 23.884 63.7237 30.7727 72.2213 30.7727Z"
        fill="#70B8FF"
      />
      <path
        d="M72.222 26.9617C78.6151 26.9617 83.7976 21.7791 83.7976 15.3861C83.7976 8.9931 78.6151 3.81055 72.222 3.81055C65.829 3.81055 60.6465 8.9931 60.6465 15.3861C60.6465 21.7791 65.829 26.9617 72.222 26.9617Z"
        fill="url(#paint2_linear_14549_16229)"
      />
      <path
        d="M64.0381 7.2002C61.9067 9.37902 60.7207 12.3106 60.7375 15.3585C60.7542 18.4064 61.9725 21.3247 64.1277 23.48C66.2829 25.6352 69.2013 26.8534 72.2492 26.8702C75.2971 26.887 78.2287 25.7009 80.4075 23.5695"
        stroke="#1C2024"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M83.7838 15.95C83.8768 14.0211 83.4862 12.0997 82.6473 10.3603C81.8084 8.62096 80.5479 7.11897 78.9806 5.99097C77.4132 4.86297 75.5887 4.14477 73.6729 3.9017C71.7572 3.65862 69.8111 3.8984 68.0117 4.59921"
        stroke="white"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M68.8447 18.54C68.8447 20.29 70.3567 21.7072 72.2213 21.7072C74.0859 21.7072 75.5979 20.29 75.5979 18.54C75.5979 14.9496 69.4398 15.1105 69.4398 11.9433C69.4398 10.5041 70.6851 9.33594 72.2213 9.33594C73.7575 9.33594 75.0028 10.5041 75.0028 11.9433"
        stroke="#1C2024"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M72.2217 23.5232V7.51758"
        stroke="#1C2024"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M61.9004 46.3882L31.4407 46.3882V32.3882C31.4407 28.349 33.0453 24.4752 35.9014 21.6191C38.7576 18.7629 42.6313 17.1583 46.6705 17.1583C50.7098 17.1583 54.5835 18.7629 57.4397 21.6191C60.2958 24.4752 61.9004 28.349 61.9004 32.3882V46.3882Z"
        fill="#FFC419"
      />
      <path
        d="M57.6423 33.0383C57.6423 31.5978 57.3586 30.1714 56.8073 28.8405C56.256 27.5096 55.448 26.3003 54.4294 25.2817C53.4108 24.2631 52.2016 23.4551 50.8707 22.9038C49.5398 22.3526 48.1134 22.0688 46.6729 22.0688"
        stroke="white"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M39.6855 54.1528C39.6855 55.9856 40.4135 57.7433 41.7092 59.0395C43.0049 60.3356 44.7624 61.0641 46.5952 61.0647C48.4283 61.0647 50.1864 60.3365 51.4826 59.0402C52.7788 57.744 53.507 55.986 53.507 54.1528H39.6855Z"
        className={styles.line}
      />
      <path
        d="M61.8252 46.313H31.3655L26.4637 53.0088C26.3922 53.1228 26.3529 53.254 26.3498 53.3885C26.3467 53.523 26.38 53.6558 26.4461 53.773C26.5123 53.8901 26.6088 53.9873 26.7256 54.0541C26.8423 54.1209 26.975 54.155 27.1095 54.1527H66.0922C66.2259 54.1534 66.3574 54.1182 66.4729 54.0507C66.5884 53.9832 66.6837 53.8859 66.7488 53.7691C66.8139 53.6522 66.8464 53.52 66.8429 53.3863C66.8395 53.2525 66.8002 53.1222 66.7291 53.0088L61.8252 46.313Z"
        fill="#FFC419"
      />
      <path
        d="M31.3652 46.313H61.8249"
        stroke="#1C2024"
        strokeWidth="0.643577"
        strokeMiterlimit="10"
      />
      <path
        d="M50.4697 21.5664H42.8702V18.0752C42.8702 17.5765 42.9685 17.0827 43.1593 16.622C43.3501 16.1612 43.6299 15.7426 43.9825 15.39C44.3351 15.0373 44.7538 14.7576 45.2145 14.5667C45.6753 14.3759 46.1691 14.2777 46.6678 14.2777C47.1665 14.2777 47.6603 14.3759 48.121 14.5667C48.5818 14.7576 49.0004 15.0373 49.353 15.39C49.7057 15.7426 49.9854 16.1612 50.1762 16.622C50.3671 17.0827 50.4653 17.5765 50.4653 18.0752V21.5664H50.4697Z"
        fill="#FFC419"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_14549_16229"
        x1="6.99339"
        y1="0.293135"
        x2="32.0863"
        y2="25.3838"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.26" stopColor="#D1D5E0" />
        <stop offset="0.79" stopColor="#E6EAF4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_14549_16229"
        x1="47.8846"
        y1="38.6873"
        x2="90.149"
        y2="80.9518"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.12" stopColor="#E6EAF4" />
        <stop offset="0.67" stopColor="#DADEE9" />
        <stop offset="1" stopColor="#D1D5E0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_14549_16229"
        x1="64.3349"
        y1="23.2728"
        x2="80.7053"
        y2="6.90251"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#70B8FF" />
        <stop offset="0.22" stopColor="#77BBFF" stopOpacity="0.97" />
        <stop offset="0.38" stopColor="#8AC5FF" stopOpacity="0.89" />
        <stop offset="0.58" stopColor="#A9D4FF" stopOpacity="0.76" />
        <stop offset="0.8" stopColor="#D3E9FF" stopOpacity="0.58" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <clipPath id="clip0_14549_16229">
        <rect width="93.1974" height="84" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Finance;
