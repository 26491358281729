import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import * as htmlToImage from "html-to-image";

export const shareScreenshot = async (
  div: HTMLElement | null,
  divID: string,
  name: string,
  text?: string,
  height: number = 500
) => {
  const fileName = `${name}.png`;

  if (!navigator.canShare) {
    if (!div) return null;

    const dataUrl = await htmlToImage.toPng(div);

    const imageData = dataUrl.split(",")[1];

    const file = await Filesystem.writeFile({
      path: fileName,
      data: imageData,
      directory: Directory.Documents,
      recursive: true,
    });

    const filePath = file.uri;

    await Share.share({
      title: "Compartir",
      url: filePath,
      dialogTitle: "Compartir con:",
      text,
    });
  } else {
    const div = document.getElementById(divID);
    if (div) {
      const blob = await htmlToImage.toBlob(div, {
        skipAutoScale: true,
        height,
      });

      if (!blob) return null;

      const file = new File([blob], fileName, {
        type: "image/png",
      });
      navigator.share({
        files: [file],
        text,
      });
    }
  }
};
