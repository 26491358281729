import { useEffect, useState } from "react";

import { clearCaucionError, resetCaucionOrder } from "features/markets";
import { RootState } from "store/store";
import { UIModalSlider } from "components/common/modal-slider";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { ConfirmCaucionOrderContainer } from "../../../components/Caucion/confirm-container";

import "./index.scss";

interface PropTypes {
  open: boolean;
  onToggleOpen: () => void;
  onClickConfirm?: () => void;
}

export const ConfirmCaucionOrder = (props: PropTypes) => {
  const { open, onToggleOpen } = props;
  const dispatch = useAppDispatch();
  const caucionOrder = useAppSelector(
    (state: RootState) => state.markets.caucionOrder
  );
  const orderError = useAppSelector(
    (state: RootState) => state.markets.caucionError
  );
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (caucionOrder.id || orderError.message) {
      setStep(step + 1);
    }
  }, [caucionOrder.id, orderError]);

  useEffect(() => {
    if (!open) {
      if (caucionOrder.id) {
        dispatch(resetCaucionOrder());
      }
      if (orderError.message) {
        dispatch(clearCaucionError());
      }
    }
  }, [open]);

  return (
    <UIModalSlider
      classes="caucion-confirm-order-modal"
      open={open}
      onToggleDisplay={onToggleOpen}
    >
      <ConfirmCaucionOrderContainer onClose={onToggleOpen} />
    </UIModalSlider>
  );
};
