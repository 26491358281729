import { useState } from "react";

import type { BalanceCurrencies } from "interfaces/wallet";
import { useAppSelector } from "hooks/redux";
import { getLoggedUser } from "store/selectors/user.selector";

import InstrumentsTableHeading from "./InstrumentsTableHeading";
import InstrumentsTable from "./InstrumentsTable";
import PortfolioEmptyState, {
  PortfolioEmpty,
} from "../../common/PortfolioEmptyState";

import styles from "./styles.module.scss";

interface InstrumentsTableProps {
  currency: BalanceCurrencies;
}

const InstrumentsTableWrapper = ({ currency }: InstrumentsTableProps) => {
  const [isTableGrouped, setIsTableGrouped] = useState(true);
  const [isSettlementExpanded, setIsSettlementExpanded] = useState(false);
  const user = useAppSelector(getLoggedUser);

  if (!user?.hasAccount)
    return <PortfolioEmptyState portfolioState={PortfolioEmpty.ACCOUNT} />;

  return (
    <div className={styles.instrumentsTableWrapper}>
      <InstrumentsTableHeading
        isSettlementExpanded={isSettlementExpanded}
        setIsSettlementExpanded={setIsSettlementExpanded}
        isTableGrouped={isTableGrouped}
        setIsTableGrouped={setIsTableGrouped}
      />
      <InstrumentsTable
        currency={currency}
        isSettlementExpanded={isSettlementExpanded}
        isTableGrouped={isTableGrouped}
      />
    </div>
  );
};

export default InstrumentsTableWrapper;
