import { usePricesQuery } from "apis/crypto/queries/usePrices";
import TotalBalanceCard from "components/common/TotalBalanceCard";
import { ThemeVariants } from "interfaces/theme";
import HomeLayout from "layouts/HomeLayout";
import { Projects } from "utils/amplitude";

import CardSection from "../CardSection";
import ActionsWrapper from "./ActionsWrapper";
import DailyTrends from "./DailyTrends";
import DolarCrypto from "./DolarCrypto";
import HowToInvest from "./HowToInvest";
import FeaturesSection from "./FeaturesSection";

import styles from "./styles.module.scss";

const HomeCrypto: React.FC = () => {
  const { data: pricesData } = usePricesQuery();

  return (
    <HomeLayout
      variant={ThemeVariants.Crypto}
      setIsNoAccountSheetOpen={() => false}
      className={styles.home}
    >
      <div className={styles.gradientBackground}>
        <TotalBalanceCard
          variant={ThemeVariants.Crypto}
          setIsNoAccountSheetOpen={() => false}
        />
        <CardSection
          setIsNoAccountSheetOpen={() => false}
          variant={ThemeVariants.Crypto}
          referral={Projects.CRYPTO}
        />
        <ActionsWrapper />
      </div>
      <div className={styles.content}>
        <DolarCrypto pricesData={pricesData} />
        <FeaturesSection />
        <DailyTrends pricesData={pricesData} />
        <HowToInvest />
      </div>
    </HomeLayout>
  );
};

export default HomeCrypto;
