import { ReactNode, useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import Drawer from "layouts/drawer";
import { Schedules } from "interfaces/calendar";
import { Method, OrderType, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import {
  AnalysisData,
  checkFlow,
  getAnalysis,
} from "features/calculator/calculatorSlice";
import { resetOrder } from "features/markets/marketsSlice";
import { getWallet } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { checkMarketOpen } from "store/selectors/markets.selector";
import { getCurrencyFormatted, getSettlementByTicker } from "utils";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import CapitalReceivePage from "components/page-capital-receive";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  openRightModal,
  closeRightModal,
} from "features/rightModal/rightModalSlice";
import { useAppState } from "context/AppStateProvider";

import InvestmentSuggestionButton from "./components/InvestmentSuggestionButton";
import InvestmentSuggestionDetail from "./components/InvestmentSuggestionDetail";
import InvestmentSuggestionResume from "./components/InvestmentSuggestionResume";
import BasicWrapper from "../BasicWrapper";

import styles from "./styles.module.scss";

dayjs.extend(utc);
dayjs.extend(timezone);

enum OptionsPage {
  DETAIL = "Detalle",
  RESUME = "Resumen",
}

const InvestmentSuggestionContainer = () => {
  const { isMobile } = useDeviceCheck();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openRightModalContent } = useAppState();

  const [errorMessage, setErrorMessage] = useState<null | ReactNode>(null);

  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const instrument = useAppSelector(
    (state: RootState) => state.markets.investmentSuggested
  )[0];

  const wallet = useAppSelector((state: RootState) => state.wallet.wallet);
  const term = getSettlementByTicker(instrument.long_ticker);
  const buyingPower = +(wallet?.[term]?.usd ?? 0);
  const minToOperate = instrument.monto_minimo;

  const [selectedPage, setSelectedPage] = useState<OptionsPage>(
    OptionsPage.DETAIL
  );
  const [settlementDays, setSettlementDays] = useState<SettlementTerms>(
    SettlementTerms._24
  );
  const [operationAble, setOperationAble] = useState(false);

  const isDetail = selectedPage === OptionsPage.DETAIL;
  const isMarketOpen = checkMarketOpen(Schedules[settlementDays]);

  const headerTitle = isDetail ? instrument.name : "Resumen";

  const calculatedQuantity =
    Math.floor(
      (Math.floor(instrument.monto_minimo / (instrument.price / 100)) -
        instrument.lamina_minima) /
        instrument.lamina_incremental
    ) *
      instrument.lamina_incremental +
    instrument.lamina_minima;

  const handleGoToFlow = () => {
    const days = +instrument.long_ticker.split("-")[1].slice(-1) - 1;
    const objectToSend = {
      bond: instrument.instrument_code,
      price: instrument.price,
      expiration_date: dayjs()
        .add(days, "days")
        .tz("America/Buenos_Aires")
        .format("YYYY-MM-DD"),
      quantity: order.quantity,
      logo: instrument.logo_file_name,
    };

    dispatch(getAnalysis(objectToSend as AnalysisData));
    setSelectedPage(OptionsPage.RESUME);
  };

  const handleOnClose = () => {
    if (isDetail && !isMobile) {
      dispatch(closeRightModal());
    }
    if (isDetail && isMobile) {
      navigate(-1);
    }

    setSelectedPage(OptionsPage.DETAIL);
  };

  const onErrorBannerClick = () => {
    if (isMobile) {
      navigate(mainRoutes.receive);
    } else {
      openRightModalContent(
        <Drawer withHeader={false}>
          <CapitalReceivePage isDesktop />
        </Drawer>
      );
      dispatch(openRightModal({ withCloseButton: false }));
    }
  };

  const handleCheckError = useCallback(() => {
    if (order.amount) {
      if (order.amount < minToOperate) {
        return setErrorMessage(
          `El monto mínimo de compra es de ${getCurrencyFormatted(
            Currencies.USD,
            instrument.monto_minimo
          )}`
        );
      }
      if (order.amount > buyingPower) {
        return setErrorMessage(
          <>
            No tenés suficiente saldo para hacer esta operación. <br />
            <span
              className={styles.errorMessageLink}
              onClick={onErrorBannerClick}
            >
              Ingresá dinero acá
            </span>
          </>
        );
      }
    }
    if (buyingPower <= 0) {
      return setErrorMessage(
        <>
          No tenés suficiente saldo para hacer esta operación. <br />
          <span onClick={onErrorBannerClick}>Ingresá dinero acá</span>
        </>
      );
    }

    setErrorMessage(null);
  }, [order.amount, minToOperate, buyingPower]);

  useEffect(() => {
    handleCheckError();
  }, [handleCheckError]);

  useEffect(() => {
    dispatch(
      checkFlow({ instrument_code: instrument.instrument_code, invest: true })
    );
  }, [instrument]);

  useEffect(() => {
    if (instrument && instrument.long_ticker) {
      const term = getSettlementByTicker(instrument.long_ticker);
      dispatch(
        resetOrder({
          long_ticker: instrument.long_ticker,
          price: instrument.price,
          term: term,
          amount: instrument.monto_minimo,
          quantity: calculatedQuantity,
          method: Method.LIMIT,
          instrument_code: instrument.instrument_code,
          currency: Currencies.USD,
          type: OrderType.Buy,
        })
      );
      setSettlementDays(term);
    }
  }, [instrument]);

  useEffect(() => {
    dispatch(getWallet());
  }, [dispatch]);

  return (
    <BasicWrapper
      navigationHeaderProps={{
        onClick: handleOnClose,
        title: headerTitle,
        withCloseIcon: isDetail,
      }}
      className={classNames(styles.investmentWrapper, {
        [styles.errorMessage]: errorMessage !== null,
      })}
      isMarketOpen={isMarketOpen}
    >
      {isDetail ? (
        <InvestmentSuggestionDetail
          instrument={instrument}
          onChangePage={handleGoToFlow}
          onOperationAble={setOperationAble}
        />
      ) : (
        <InvestmentSuggestionResume
          handleOnClose={handleOnClose}
          instrument={instrument}
        />
      )}
      {isDetail && (
        <InvestmentSuggestionButton
          errorMessage={errorMessage}
          isMarketOpen={isMarketOpen}
          instrument={instrument}
          operationAble={operationAble}
        />
      )}
    </BasicWrapper>
  );
};

export default InvestmentSuggestionContainer;
