import { getInstrumentName } from "constants/instruments";

import SectionsButtons from "components/common/SectionsButtons";
import { HeaderSearchBar } from "components/common/header-search-bar";
import { HeaderLists } from "components/common/list-header";
import { trackAction } from "utils/amplitude";
import { screenNames } from "router/routes";
import { Instruments, MarketSection, Sections } from "interfaces/markets";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { useMarket } from "context/MarketProvider";

interface MarketHeaderProps {
  onChangeSection: (v: MarketSection) => void;
}

const MarketHeader: React.FC<MarketHeaderProps> = ({ onChangeSection }) => {
  const { selectedInstrumentType } = useMarket();
  const { isMobile } = useDeviceCheck();

  const handleSelectClick = () => {
    trackAction(`${screenNames.market} - Click Categories`);
  };

  if (isMobile) return null;

  return (
    <div
      className={`type-wrapper ${
        selectedInstrumentType === Instruments.CAUCION ? "types-caucion" : ""
      }`}
    >
      <SectionsButtons
        customOptions={[
          { name: Sections.FAVORITOS, value: Sections.FAVORITOS },
        ]}
        selected={selectedInstrumentType}
        onClickHeaderOption={onChangeSection}
      />
      <HeaderLists
        customOptions={[{ name: "Dólar MEP", value: Sections.MEP }]}
        isActive={selectedInstrumentType !== Sections.FAVORITOS}
        placeholder="Seleccione una opción"
        selected={
          selectedInstrumentType !== Sections.FAVORITOS &&
          selectedInstrumentType !== Sections.HOME
            ? getInstrumentName(selectedInstrumentType)
            : undefined
        }
        onClickSelectOptions={onChangeSection}
        onClick={handleSelectClick}
      />
      <HeaderSearchBar />
    </div>
  );
};

export default MarketHeader;
