import { Cryptos } from "constants/cryptos";

import Tag from "components/common/CocosTagBottomSheet/components/Tag";
import ETHIcon from "assets/icons/ETH";
import px2rem from "utils/px2rem";
import USDTIcon from "assets/icons/USDT";
import BTCIcon from "assets/icons/BTC";
import USDCIcon from "assets/icons/USDC";

import EllipseOne from "./ElipseOne";
import EllipseTwo from "./EllipseTwo";
import EllipseThree from "./EllipseThree";
import BackgroundCard from "./BackgroundCard";

import styles from "./styles.module.scss";

interface SuccessTagBackgroundProps {
  tag: string;
}

const SuccessTagBackground: React.FC<SuccessTagBackgroundProps> = ({ tag }) => {
  const ICON_SIZE = px2rem(24);
  return (
    <div className={styles.SuccessTagBackgroundContainer} id="share-tag">
      <EllipseOne />
      <EllipseTwo />
      <EllipseThree />
      <ETHIcon className={styles.eth} size={ICON_SIZE} />
      <USDTIcon className={styles.usdt} size={ICON_SIZE} />
      <BTCIcon className={styles.btc} size={ICON_SIZE} />
      <USDCIcon className={styles.usdc} scale={ICON_SIZE} />
      <BackgroundCard
        isUp
        icon="🦄"
        tagName="teo"
        amount={10}
        ticker={Cryptos.USDC}
        cardColor="var(--purple500)"
        className={styles.teoCard}
      />
      <BackgroundCard
        isUp
        icon="🔥"
        tagName="ari"
        amount={20}
        ticker={Cryptos.USDT}
        cardColor="var(--red500)"
        className={styles.ariCard}
      />
      <BackgroundCard
        icon="😎"
        cardColor="var(--yellow500)"
        tagName="chiara"
        amount={0.01}
        ticker={Cryptos.ETH}
        className={styles.chiaraCard}
      />
      <div className={styles.tagWrapper}>
        <Tag tag={tag} />
      </div>
    </div>
  );
};

export default SuccessTagBackground;
