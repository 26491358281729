import { Share } from "@capacitor/share";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import OutlinedButton from "components/common/OutlinedButton";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import { X } from "lucide-react";
import { useNavigate } from "react-router";
import { CryptoRoutes } from "router/routes";

import SuccessTagBackground from "./TagBackground";

import styles from "./styles.module.scss";

interface SuccessTagProps {
  tag: string;
}

const SuccessTag: React.FC<SuccessTagProps> = ({ tag }) => {
  const navigate = useNavigate();

  const onShare = async () => {
    const shareText = `Enviá y recibí Crypto sin costos a contactos de Cocos Crypto a través de cocostag. El mío es: ${tag}`;
    // const shareImage = document.getElementById("share-tag");
    // const date = Date.now();
    // shareScreenshot(
    //   shareImage,
    //   "share-tag",
    //   "Cocostag - share tag" + date,
    //   `Enviá y recibí Crypto sin costos a contactos de Cocos Crypto a través de cocostag. El mío es: ${tag}`,
    //   220
    // );
    if ((await Share.canShare()).value) {
      Share.share({
        text: shareText,
      });
    } else if (navigator.canShare()) {
      navigator.share({ text: shareText });
    }
  };

  const goHome = () => {
    navigate(CryptoRoutes.HOME);
  };

  return (
    <div className={styles.SuccessTagContainer}>
      <div>
        <PageHeader
          className={styles.title}
          title="¡Listo!"
          icon={X}
          onClick={() => navigate(-1)}
        />
        <SuccessTagBackground tag={tag} />
        <div className={styles.wrapper}>
          <Heading
            variant={HeadingVariants.RegularTitle}
            className={styles.head}
            color="var(--slate900)"
            component="h2"
          >
            Ya tenés tu cocostag
          </Heading>
          <Text
            className={styles.text}
            variant={TextVariants.RegularText}
            color="var(--slate800)"
          >
            <Text
              variant={TextVariants.SemiboldText}
              className={styles.text}
              component="span"
              color="var(--purple800)"
            >
              {tag} 🥥&nbsp;
            </Text>
            está listo para enviar y recibir crypto sin costos.
          </Text>
          <Text
            className={styles.text}
            variant={TextVariants.RegularText}
            color="var(--slate800)"
          >
            ¡Compartilo con tus contactos!
          </Text>
        </div>
      </div>
      <div className={styles.buttonsWraper}>
        <FilledButton variant={ThemeVariants.Crypto} onClick={onShare}>
          Compartir mi cocostag
        </FilledButton>
        <OutlinedButton variant={ThemeVariants.Crypto} onClick={goHome}>
          Volver al inicio
        </OutlinedButton>
      </div>
    </div>
  );
};

export default SuccessTag;
