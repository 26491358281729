import { useEffect, useState } from "react";

import { ArrowUpRight, QrCode, X } from "lucide-react";
import px2rem from "utils/px2rem";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import Currency, { CurrencySizes } from "components/common/Text/Currency";
import { Currencies } from "interfaces/wallet";
import { usePixPricesQuery } from "apis/pay/queries/usePixPrices";
import Link from "components/common/Link";
import { ThemeVariants } from "interfaces/theme";
import { useBanner } from "apis/banners/queries/useBanner";
import { useBannerSeen } from "apis/banners/mutations/useBannerSeen";

import PixBG from "./BG";
import PixBottomSheet from "./PixBottomSheet";

import styles from "./styles.module.scss";

const PixBanner: React.FC = () => {
  const { data: pixPrices } = usePixPricesQuery();
  const { data: pixBannerData } = useBanner("pix");

  const [showFullBanner, setShowFullBanner] = useState<boolean>(
    !pixBannerData?.seen
  );
  const [bottomSheetIsOpen, setBottomSheetIsOpen] = useState<boolean>(false);

  const mutationSeenBanner = useBannerSeen();

  useEffect(() => {
    if (pixBannerData) setShowFullBanner(!pixBannerData.seen);
  }, [pixBannerData]);

  if (!pixPrices || !pixBannerData) return null;

  const onClickBanner = () => {
    setBottomSheetIsOpen(true);
  };

  const hideBanner = async (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setShowFullBanner(false);
    mutationSeenBanner.mutateAsync("pix");
  };

  return (
    <>
      <div className={styles.pixBannerContainer}>
        <div className={styles.wrapper} onClick={onClickBanner}>
          <div className={styles.header}>
            <div className={styles.title}>
              <div className={styles.icon}>
                <QrCode size={px2rem(16)} color="var(--green800)" />
              </div>
              <Heading
                variant={HeadingVariants.SemiboldSubtitle}
                color="var(--green50)"
                component="h2"
              >
                Pagá con Pix en Brasil
              </Heading>
            </div>
            {showFullBanner ? (
              <X
                size={px2rem(20)}
                color="var(--green50)"
                onClick={hideBanner}
              />
            ) : (
              <Link
                onClick={onClickBanner}
                variant={ThemeVariants.Pay}
                icon={ArrowUpRight}
                isSmall
                textColor="var(--green100)"
                backgroundColor="var(--green700)"
              >
                Pagar
              </Link>
            )}
          </div>
          {showFullBanner && (
            <Text
              className={styles.info}
              variant={TextVariants.RegularValueS}
              color="var(--green200)"
            >
              La forma más conveniente de pagar en Brasil a la mejor cotización.
            </Text>
          )}
          <div className={styles.price}>
            <div className={styles.priceSection}>
              <Text
                variant={TextVariants.SemiboldUpperValueS}
                color="var(--green400)"
              >
                T.C. R$ 1 =&nbsp;
              </Text>
              <Currency
                size={CurrencySizes.SMALL}
                amount={pixPrices.brlToArsRate}
                color="var(--green400)"
                signColor="var(--green400)"
                ticker={Currencies.ARS}
              />
            </div>
          </div>
        </div>
        <PixBG className={styles.bgFlag} />
      </div>
      <PixBottomSheet
        isOpen={bottomSheetIsOpen}
        setIsOpen={setBottomSheetIsOpen}
      />
    </>
  );
};

export default PixBanner;
