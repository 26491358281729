import { useState } from "react";

import EmptyMarketList from "components/common/EmptyMarketList";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { InstrumentData, MarketData } from "interfaces/api-responses";
import { MarketSection, Sections } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

import LoaderRows from "../LoaderRows";
import TableHeader from "./Header";
import Rows from "./Rows";

interface ListProps {
  loading?: boolean;
  selectedInstrumentType: MarketSection;
  setSelectedMarket: (v: InstrumentData) => void;
  handleToggleDisplayModal: () => void;
  currency: Currencies;
  hasCurrency?: boolean;
  getMoreMarketItems: (setLoading: (v: boolean) => void, page?: number) => void;
  marketData?: MarketData;
}

const List: React.FC<ListProps> = ({
  loading,
  hasCurrency,
  setSelectedMarket,
  getMoreMarketItems,
  selectedInstrumentType,
  handleToggleDisplayModal,
  marketData,
  currency,
}) => {
  const { isMobile } = useDeviceCheck();
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const loadMoreElements = () => {
    if (marketData && marketData.current_page < marketData.total_pages) {
      getMoreMarketItems(setLoadingMore, marketData.current_page + 1);
    }
  };

  if (loading || !marketData) return null;

  if (isEmpty(marketData.items)) {
    return (
      <>
        {(!selectedInstrumentType ||
          selectedInstrumentType === Sections.FAVORITOS) && (
          <EmptyMarketList selectedInstrumentType={selectedInstrumentType} />
        )}
      </>
    );
  }

  return (
    <InfiniteScroll
      dataLength={marketData.items.length}
      hasMore={marketData.current_page < marketData.total_pages}
      loader={loadingMore && <LoaderRows rowsLength={isMobile ? 2 : 3} />}
      next={loadMoreElements}
    >
      <TableHeader
        currency={currency}
        selectedInstrumentType={selectedInstrumentType}
      />
      <Rows
        hasCurrency={hasCurrency}
        selectedInstrumentType={selectedInstrumentType}
        setSelectedMarket={setSelectedMarket}
        handleToggleDisplayModal={handleToggleDisplayModal}
        markets={marketData.items}
      />
    </InfiniteScroll>
  );
};

export default List;
