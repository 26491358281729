import { useState, useEffect } from "react";

import { App } from "@capacitor/app";
import { lt, valid } from "semver";
import API from "apis";
import { cardService } from "apis/services";
import { GooglePay, TokenStatusReference } from "capacitor-google-pay";
import getPlatform from "utils/getPlatform";
import { ThemeVariants } from "interfaces/theme";
import { useCocosCard } from "context/CocosCardProvider";
import { MASTERCARD_TSP } from "components/page-cocos-card/constants";
import GPayButton from "components/common/GPay/GPayButton";
import GPayActive from "components/common/GPay/GPayActive";
import LoadingSpinner from "components/common/LoadingSpinner";

import styles from "./styles.module.scss";

interface GPayActivationProps {
  idCard: string;
  lastFour: string;
}

const GPayActivation: React.FC<GPayActivationProps> = ({
  idCard,
  lastFour,
}) => {
  const { isAndroid, isNative } = getPlatform();
  const { pomeloData, getPomeloData, setIsError } = useCocosCard();

  const [isTokenized, setIsTokenized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [tokenStatus, setTokenStatus] = useState<TokenStatusReference>();
  const [appVersion, setAppVersion] = useState<string>("");

  const targetVersion = "2.1.0";

  const getAppVersion = async () => {
    const { version } = await App.getInfo();

    return setAppVersion(version);
  };

  const getIsTokenized = async () => {
    const { isTokenized } = await GooglePay.isTokenized({
      lastDigits: lastFour,
      tsp: MASTERCARD_TSP,
    });

    setIsTokenized(isTokenized);
  };

  const getTokenStatus = async () => {
    const { tokens } = await GooglePay.listTokens();
    if (tokens.length === 0) {
      return;
    }

    setToken(tokens[0]);

    const { state } = await GooglePay.getTokenStatus({
      tokenReferenceId: tokens[0],
      tsp: MASTERCARD_TSP,
    });

    setTokenStatus(state);
  };

  const onClickAddGPay = () => {
    if (!isTokenized) {
      handleGPayTokenize();
    }

    if (token && tokenStatus !== TokenStatusReference.TOKEN_STATE_ACTIVE) {
      GooglePay.resumeTokenization({
        tokenReferenceId: token,
        tsp: MASTERCARD_TSP,
      });
    }
  };

  const handleGPayTokenize = async () => {
    setIsLoading(true);

    try {
      const {
        data: {
          data: { opc },
          user,
        },
      } = await API.post(cardService.googlePayActivation, {
        card_id: idCard,
      });

      const name = `${user.name} ${user.surname}`;

      GooglePay.pushProvision({
        opc,
        tsp: MASTERCARD_TSP,
        clientName: name,
        lastDigits: lastFour,
        address: {
          name,
          address1: `${user.legal_address.street_name} ${user.legal_address.street_number}`,
          countryCode: user.operation_country,
          postalCode: user.legal_address.zip_code,
          locality: user.legal_address.city,
          administrativeArea: user.legal_address.region,
          phoneNumber: user.phone,
        },
      });
    } catch (error: any) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isNative && isAndroid) {
      getIsTokenized();
      getTokenStatus();
    }
  }, [lastFour, idCard, pomeloData]);

  useEffect(() => {
    if (isNative) {
      getAppVersion();

      App.addListener("resume", () => {
        getPomeloData();
      });

      return () => {
        App.removeAllListeners();
      };
    }
  }, []);

  if (!isAndroid || (valid(appVersion) && lt(appVersion, targetVersion))) {
    return null;
  }

  return (
    <>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <LoadingSpinner size={100} variant={ThemeVariants.Pay} />
        </div>
      )}
      {isTokenized &&
      tokenStatus === TokenStatusReference.TOKEN_STATE_ACTIVE ? (
        <GPayActive />
      ) : (
        <GPayButton onClick={onClickAddGPay} />
      )}
    </>
  );
};

export default GPayActivation;
