import { IconProps } from "assets/icons/BTC";
import px2rem from "utils/px2rem";

const QRError: React.FC<IconProps> = ({
  width = px2rem(82),
  height = px2rem(96),
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 82 96"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_3612_46310)">
      <path
        d="M72.4673 0H28.9001C25.4304 0 22.6177 2.81276 22.6177 6.28247V89.7175C22.6177 93.1872 25.4304 96 28.9001 96H72.4673C75.937 96 78.7497 93.1872 78.7497 89.7175V6.28247C78.7497 2.81276 75.937 0 72.4673 0Z"
        fill="#0588F0"
      />
      <path
        d="M78.7497 10.5359H22.6177V85.1589H78.7497V10.5359Z"
        fill="#113264"
      />
      <path
        d="M78.75 20.022C79.3652 20.022 79.9552 20.2664 80.3902 20.7014C80.8252 21.1364 81.0696 21.7264 81.0696 22.3416V31.9863C81.0696 32.6015 80.8252 33.1915 80.3902 33.6265C79.9552 34.0615 79.3652 34.3059 78.75 34.3059V20.022Z"
        fill="#0588F0"
      />
      <path
        d="M44.8813 5.7771H57.2168"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M50.6849 93.3312C52.1925 93.3312 53.4147 92.109 53.4147 90.6014C53.4147 89.0938 52.1925 87.8716 50.6849 87.8716C49.1773 87.8716 47.9551 89.0938 47.9551 90.6014C47.9551 92.109 49.1773 93.3312 50.6849 93.3312Z"
        fill="#113264"
      />
      <path
        d="M1.38232 27.8425V19.4089H9.81837"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M51.5493 19.4089H59.9829V27.8425"
        stroke="#DC3E42"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M59.9829 69.5759V78.0095H51.5493"
        stroke="#DC3E42"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M9.81837 78.0095H1.38232V69.5759"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M54.4428 25.8525H8.72949V71.5659H54.4428V25.8525Z"
        fill="#DC3E42"
      />
      <path
        d="M29.3913 32.1179H15.1782V46.331H29.3913V32.1179Z"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M48.7048 51.4316H34.4917V65.6447H48.7048V51.4316Z"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M24.5078 37.0015H20.0615V41.4478H24.5078V37.0015Z"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M43.8218 56.3152H39.3755V60.7615H43.8218V56.3152Z"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M20.0615 55.8782H24.5078V51.4319H20.0615V55.8782Z"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M29.3911 60.7613V51.4316"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M15.1782 51.4316V60.3707H24.5079V65.6447H29.3913"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M19.8443 65.6448H15.1782"
        stroke="#1C2024"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M34.4917 46.331L48.7048 32.1179"
        stroke="white"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
      <path
        d="M34.4917 32.1179L48.7048 46.331"
        stroke="white"
        strokeWidth="0.905868"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_3612_46310">
        <rect
          width="80.1388"
          height="96"
          fill="white"
          transform="translate(0.930664)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default QRError;
