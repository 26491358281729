import { SettlementIndex } from "constants/portfolio";

import Text, { TextVariants } from "components/common/Text";
import { useAppState } from "context/AppStateProvider";
import px2rem from "utils/px2rem";
import HiddenValue from "components/common/HiddenValue";
import { formatQuantity } from "utils";
import { PortfolioSettlements } from "interfaces/portfolio";

interface QuantityProps {
  settlements: PortfolioSettlements[];
}

const Quantity: React.FC<QuantityProps> = ({ settlements }) => {
  const { isBalanceHidden } = useAppState();
  if (!settlements[SettlementIndex.INF].quantity) return <div>--</div>;

  return (
    <div>
      <Text color="var(--slate800)" variant={TextVariants.RegularTextS}>
        {isBalanceHidden ? (
          <HiddenValue size={px2rem(7)} color="var(--slate800)" />
        ) : (
          formatQuantity(settlements[SettlementIndex.INF].quantity)
        )}
      </Text>
    </div>
  );
};

export default Quantity;
