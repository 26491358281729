import { useState } from "react";

import { Typography } from "@mui/material";
import { useMarket } from "context/MarketProvider";
import { Sections } from "interfaces/markets";
import { trackAction } from "utils/amplitude";
import { screenNames } from "router/routes";
import ShortHeader from "components/page-market/mobile/components/ShortHeader";
import SecondaryOptions from "components/page-market/components/SecondaryOptions";
import { ArrowDownIcon } from "assets/icons/arrow-down-collapse";
import { ArrowTopIcon } from "assets/icons/arrow-top";

const MarketOptions: React.FC = () => {
  const { selectedInstrumentType } = useMarket();
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const handleChangeDisplayOptions = () => {
    if (!displayOptions) {
      trackAction(`${screenNames.market} - See More Filters Option`);
    } else {
      trackAction(`${screenNames.market} - Hide More Filters Option`);
    }
    setDisplayOptions(!displayOptions);
  };

  if (selectedInstrumentType === Sections.FAVORITOS) return null;

  return (
    <div>
      {!displayOptions && <ShortHeader />}
      {displayOptions && <SecondaryOptions />}
      <div className="hide-display-btn" onClick={handleChangeDisplayOptions}>
        <Typography variant="buttonS">
          {displayOptions ? "Ocultar" : "Ver más opciones"}
        </Typography>
        <div>
          {displayOptions ? (
            <ArrowTopIcon />
          ) : (
            <ArrowDownIcon color="#0062E1" size={16} type="thick" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketOptions;
