import ClosedModal from "components/common/market-closed-header";
import { useMarket } from "context/MarketProvider";
import { Schedules } from "interfaces/calendar";
import { Instruments, SettlementTerms } from "interfaces/markets";
import { checkMarketOpen } from "store/selectors/markets.selector";

const MarketClosedModal: React.FC = () => {
  const {
    settlementDays,
    selectedInstrumentType,
    selectedInstrumentSubtype,
  } = useMarket();

  const getSchedules = () => {
    const isNationalBond =
      selectedInstrumentSubtype === "NACIONALES_ARS" ||
      selectedInstrumentSubtype === "NACIONALES_USD";
    if (isNationalBond && settlementDays !== SettlementTerms.CI)
      return Schedules.SOBERANOS;

    if (selectedInstrumentType === Instruments.CAUCION)
      return Schedules.CAUCION;

    return settlementDays;
  };

  const schedules = getSchedules();
  const isMarketOpen = checkMarketOpen(Schedules[schedules]);

  if (selectedInstrumentType === Instruments.FCI) return null;

  return <ClosedModal open={!isMarketOpen} type={Schedules[schedules]} />;
};

export default MarketClosedModal;
