import { IconProps } from "../BTC";

const BRFlag: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_2028_14838)">
      <path
        d="M12.0002 24C18.6276 24 24.0002 18.6274 24.0002 12C24.0002 5.37257 18.6276 0 12.0002 0C5.37282 0 0.000244141 5.37257 0.000244141 12C0.000244141 18.6274 5.37282 24 12.0002 24Z"
        fill="#6DA544"
      />
      <path
        d="M12.0003 4.69565L21.9133 12L12.0003 19.3043L2.08728 12L12.0003 4.69565Z"
        fill="#FFDA44"
      />
      <path
        d="M12.0003 16.1739C14.3055 16.1739 16.1742 14.3052 16.1742 12C16.1742 9.69481 14.3055 7.8261 12.0003 7.8261C9.69513 7.8261 7.82642 9.69481 7.82642 12C7.82642 14.3052 9.69513 16.1739 12.0003 16.1739Z"
        fill="white"
      />
      <path
        d="M9.91288 11.7392C9.18706 11.7392 8.48661 11.8496 7.82727 12.0544C7.85647 14.3344 9.71291 16.174 11.9998 16.174C13.414 16.174 14.6629 15.47 15.4179 14.3942C14.1262 12.7772 12.1386 11.7392 9.91288 11.7392Z"
        fill="#0052B4"
      />
      <path
        d="M16.0964 12.7997C16.1466 12.5408 16.1738 12.2736 16.1738 12C16.1738 9.69481 14.3051 7.82614 11.9999 7.82614C10.2799 7.82614 8.80331 8.86681 8.16431 10.3527C8.72915 10.2356 9.31396 10.174 9.91297 10.174C12.3372 10.1739 14.5303 11.1816 16.0964 12.7997Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2028_14838">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BRFlag;
