import ProfileSecurity from "components/page-profile/page-security";
import { ProfileProvider } from "context/ProfileProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

const Security: React.FC = () => {
  const { isMobile } = useDeviceCheck();

  if (isMobile)
    return (
      <ProfileProvider>
        <ProfileSecurity />
      </ProfileProvider>
    );

  return <Navigate replace to={mainRoutes.profile} />;
};

export default Security;
