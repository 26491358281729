import { ChangeEvent, useState } from "react";

import { isValidEmail } from "components/page-sign-up/utils";
import InputField from "components/common/input/InputField";
import PasswordInput from "components/common/input/PasswordInput";

import PasswordBullets from "./PasswordBullets";

import styles from "./styles.module.scss";

interface FormInputsProps {
  email: string;
  password: string;
  setEmail: (email: string) => void;
  setPassword: (email: string) => void;
}

const FormInputs: React.FC<FormInputsProps> = ({
  email,
  password,
  setEmail,
  setPassword,
}) => {
  const [hasBlurredEmailInput, setHasBlurredEmailInput] = useState<boolean>(
    false
  );
  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.toLowerCase());
  };

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <div className={styles.FormInputsContainer}>
      <InputField
        label="Email"
        placeholder="Ingresá tu mail"
        name="email"
        type="email"
        value={email}
        onChange={onChangeEmail}
        hasError={!isValidEmail(email) && hasBlurredEmailInput}
        onBlur={() => setHasBlurredEmailInput(true)}
        errorMessage={"Ingrese un email válido"}
      />
      <PasswordInput
        label="Contraseña"
        placeholder="Ingresá tu contraseña"
        name="password"
        value={password}
        onChange={onChangePassword}
      />
      {password && <PasswordBullets password={password} />}
    </div>
  );
};

export default FormInputs;
