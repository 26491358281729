import { useState } from "react";

import { Typography } from "@mui/material";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { FlowAnalysis, InvestmentSuggestedResponse } from "interfaces/markets";
import { MdExpandMore } from "react-icons/md";
import { screenNames } from "router/routes";
import CsvDownloadLink from "components/common/CsvDownloadLink";

import InvestmentFlowBody from "./components/flow-body";
import InvestmentFlowHeader from "./components/flow-header";

import styles from "./styles.module.scss";

interface InvestmentBondFlowProps {
  instrument: InvestmentSuggestedResponse;
  analysis: FlowAnalysis;
}

const InvestmentBondFlow: React.FC<InvestmentBondFlowProps> = ({
  instrument,
  analysis,
}) => {
  const { isMobile } = useDeviceCheck();
  const [isFullFlow, setIsFullFlow] = useState<boolean>(false);
  const flow = analysis.flow;

  const flowToDownload = [
    ["Fecha", "Capital", "Intereses", "Flujo", "Acumulado"],
    ...flow.map((obj) => {
      return [obj.date, obj.capital, obj.interes, obj.cashFlow, obj.acumulated];
    }),
  ];
  const flowRows = isFullFlow ? flow : flow.slice(0, 4);

  const extendFlowLabel = `Ver flujo ${isFullFlow ? "parcial" : "completo"}`;

  return (
    <div className={styles.bondFlow}>
      <div className={styles.resumeSubtitle}>
        <Typography>Flujo de fondos para {analysis.quantity} VN:</Typography>
        <CsvDownloadLink
          data={flowToDownload}
          filename={`${instrument.instrument_code}-flow.csv`}
          screenName={screenNames.investment}
        />
      </div>

      <div
        className={styles.flowTable}
        style={{ gridTemplateColumns: `repeat(${isMobile ? 4 : 5}, 1fr)` }}
      >
        <InvestmentFlowHeader />
        <InvestmentFlowBody rowsData={flowRows} />
      </div>
      <div
        className={styles.expandFlow}
        onClick={() => setIsFullFlow(!isFullFlow)}
      >
        <Typography>{extendFlowLabel}</Typography>
        <MdExpandMore size={16} className={`${isFullFlow && styles.Full}`} />
      </div>
    </div>
  );
};

export default InvestmentBondFlow;
