import CocosPayPage from "components/page-cocos-pay";
import { CocosPayProvider } from "context/CocosPayProvider";

const CocosPay: React.FC = () => (
  <CocosPayProvider>
    <CocosPayPage />
  </CocosPayProvider>
);

export default CocosPay;
