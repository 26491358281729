import classNames from "classnames";
import { ChevronRight } from "lucide-react";
import px2rem from "utils/px2rem";

import Heading, { HeadingVariants } from "../Heading";

import styles from "./styles.module.scss";

interface BreadCrumbProps {
  textVariant?: HeadingVariants;
  options: string[];
  onClick: (v: string) => void;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({
  textVariant = HeadingVariants.RegularSubtitle,
  options,
  onClick,
}) => {
  const optionsLength = options.length - 1;

  return (
    <div className={styles.breadCrumbContainer}>
      {options.map((option, index) => {
        const isLast = index === optionsLength;

        return (
          <>
            <Heading
              key={option}
              variant={textVariant}
              className={classNames(styles.option, {
                [styles.clickable]: !isLast,
              })}
              color={isLast ? "var(--slate800)" : "var(--blue800)"}
              component="h2"
              onClick={isLast ? undefined : () => onClick(option)}
            >
              {option}
            </Heading>
            {!isLast && (
              <ChevronRight size={px2rem(16)} color="var(--slate800)" />
            )}
          </>
        );
      })}
    </div>
  );
};

export default BreadCrumb;
