import Heading, { HeadingVariants } from "components/common/Heading";
import { useProfile } from "context/ProfileProvider";
import { ProfileSection } from "interfaces/profile";

import ProfileRow from "../ProfileRow";

import styles from "./styles.module.scss";

interface ProfileListProps {
  title: string;
  options: ProfileSection[];
}

const ProfileList: React.FC<ProfileListProps> = ({ title, options }) => {
  const { onClickSection } = useProfile();
  return (
    <div className={styles.profileListContainer}>
      <Heading
        className={styles.title}
        variant={HeadingVariants.RegularSubtitle}
        component="h2"
        color="var(--slate800)"
      >
        {title}
      </Heading>
      <div className={styles.list}>
        {options.map((section) => {
          const { icon, title, detail, notifications } = section;
          return (
            <ProfileRow
              key={title}
              icon={icon}
              title={title}
              onClick={() => onClickSection(section)}
              detail={detail}
              notifications={notifications}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProfileList;
