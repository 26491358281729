interface GPayProps {
  isActive?: boolean;
}

const GPay: React.FC<GPayProps> = ({ isActive = false }) => (
  <svg
    width="45"
    height="18"
    viewBox="0 0 45 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Logo">
      <g id="Pay Typeface 3">
        <path
          id="Letter p 3"
          d="M21.0469 8.76914V13.7878H19.4435V1.39453H23.6941C24.7714 1.39453 25.69 1.75124 26.4416 2.46464C27.2099 3.17804 27.594 4.04906 27.594 5.07769C27.594 6.1312 27.2099 7.00222 26.4416 7.70732C25.6984 8.41243 24.7798 8.76084 23.6941 8.76084H21.0469V8.76914ZM21.0469 2.92089V7.24278H23.7275C24.3622 7.24278 24.8967 7.0271 25.3142 6.60404C25.7401 6.18097 25.9572 5.66666 25.9572 5.08598C25.9572 4.5136 25.7401 4.00758 25.3142 3.58452C24.8967 3.14486 24.3706 2.92918 23.7275 2.92918H21.0469V2.92089Z"
          fill={isActive ? "var(--slate800)" : "white"}
        />
        <path
          id="Letter a 3"
          d="M31.7861 5.02734C32.972 5.02734 33.9073 5.34257 34.592 5.97302C35.2768 6.60347 35.6192 7.46619 35.6192 8.56118V13.7873H34.091V12.6093H34.0242C33.3645 13.5799 32.4793 14.061 31.377 14.061C30.4333 14.061 29.6483 13.7873 29.0136 13.2315C28.379 12.6757 28.0616 11.9872 28.0616 11.1576C28.0616 10.2783 28.3957 9.58151 29.0638 9.0672C29.7318 8.54459 30.6254 8.28743 31.736 8.28743C32.688 8.28743 33.473 8.46164 34.0826 8.81004V8.44504C34.0826 7.88925 33.8655 7.42471 33.4229 7.03483C32.9803 6.64494 32.4626 6.45415 31.8697 6.45415C30.9761 6.45415 30.2663 6.82744 29.7485 7.58232L28.3372 6.70301C29.1139 5.58313 30.2663 5.02734 31.7861 5.02734ZM29.7151 11.1825C29.7151 11.5973 29.8905 11.9457 30.2496 12.2194C30.6003 12.4932 31.0179 12.6342 31.4939 12.6342C32.1703 12.6342 32.7716 12.3854 33.2977 11.8876C33.8238 11.3899 34.091 10.8092 34.091 10.1373C33.5899 9.74742 32.8968 9.54833 32.0033 9.54833C31.3519 9.54833 30.8091 9.70595 30.3748 10.0129C29.9322 10.3364 29.7151 10.7263 29.7151 11.1825Z"
          fill={isActive ? "var(--slate800)" : "white"}
        />
        <path
          id="Letter y 3"
          d="M44.3376 5.30078L38.993 17.5116H37.3395L39.3271 13.2395L35.803 5.30078H37.5483L40.087 11.3896H40.1204L42.5922 5.30078H44.3376Z"
          fill={isActive ? "var(--slate800)" : "white"}
        />
      </g>
      <g id="G Mark 1">
        <path
          id="Blue 500"
          d="M14.6694 7.75963C14.6694 7.24033 14.6226 6.74344 14.5358 6.26562H7.815V9.0031L11.6856 9.00394C11.5286 9.91477 11.0234 10.6912 10.2493 11.2088V12.9849H12.5533C13.8986 11.7481 14.6694 9.91975 14.6694 7.75963Z"
          fill="#4285F4"
        />
        <path
          id="Green 500 1"
          d="M10.2502 11.2073C9.60882 11.637 8.78291 11.8883 7.81672 11.8883C5.95029 11.8883 4.36696 10.639 3.79994 8.95508H1.42328V10.7867C2.60075 13.1078 5.02084 14.7005 7.81672 14.7005C9.74911 14.7005 11.3725 14.0692 12.5542 12.9825L10.2502 11.2073Z"
          fill="#34A853"
        />
        <path
          id="Yellow 500 1"
          d="M3.5761 7.59553C3.5761 7.1227 3.65543 6.66562 3.7999 6.23592V4.4043H1.42324C0.936383 5.36407 0.662476 6.44745 0.662476 7.59553C0.662476 8.74362 0.937218 9.82699 1.42324 10.7868L3.7999 8.95515C3.65543 8.52545 3.5761 8.06837 3.5761 7.59553Z"
          fill="#FABB05"
        />
        <path
          id="Red 500"
          d="M7.81665 3.30237C8.87137 3.30237 9.81586 3.66322 10.5616 4.36833L12.6034 2.34177C11.3633 1.19451 9.74654 0.490234 7.81665 0.490234C5.02161 0.490234 2.60069 2.08295 1.42322 4.404L3.79988 6.23562C4.3669 4.55166 5.95023 3.30237 7.81665 3.30237Z"
          fill="#E94235"
        />
      </g>
    </g>
  </svg>
);

export default GPay;
