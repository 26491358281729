import React, { useEffect, useRef } from "react";

import { Typography } from "@mui/material";

import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  ResolutionString,
  widget,
} from "../../../charting_library";
import datafeed from "./datafeed.js";

import "./index.scss";

interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
}

const TVChartContainer: React.FC<Partial<ChartContainerProps>> = ({
  symbol = "BBAR-0003-C-CT-ARS",
  interval = "D" as ResolutionString,
  libraryPath = "/charting_library/",
  chartsStorageUrl = "https://saveload.tradingview.com",
  chartsStorageApiVersion = "1.1",
  clientId = "tradingview.com",
  userId = "public_user_id",
  fullscreen = false,
  autosize = true,
  studiesOverrides = {},
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const tvWidgetRef = useRef<IChartingLibraryWidget | null>(null);
  const tradingViewUrl = "https://es.tradingview.com/";

  useEffect(() => {
    if (!ref.current) return;

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol,
      datafeed,
      interval,
      container: ref.current,
      library_path: libraryPath,
      timezone: "America/Argentina/Buenos_Aires",
      locale: "es",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "header_compare",
      ],
      enabled_features: [],
      charts_storage_url: chartsStorageUrl,
      charts_storage_api_version: chartsStorageApiVersion,
      client_id: clientId,
      user_id: userId,
      fullscreen,
      autosize,
      studies_overrides: studiesOverrides,
    };

    const tvWidget = new widget(widgetOptions);
    tvWidgetRef.current = tvWidget;

    return () => {
      if (tvWidgetRef.current !== null) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
      }
    };
  }, [symbol]);

  return (
    <div>
      <div ref={ref} className="TVChartContainer" />
      <Typography variant="textXS">
        Gráficos ofrecidos por{" "}
        <a href={tradingViewUrl} target="_blank" rel="noreferrer">
          TradingView.
        </a>
      </Typography>
    </div>
  );
};

export default TVChartContainer;
