import { useState } from "react";

import { Info } from "lucide-react";
import Text, { TextVariants } from "components/common/Text";
import { PaymentMethodProfile } from "interfaces/card/interface";
import getCurrencyIcon from "components/page-cocos-card/Home/PaymentMethodList/CurrencyIcon";

import PaymentMethod from "./PaymentMethod";
import PaymentMethodInfo from "./PaymentMethodInfo";
import PaymentMethodBottomSheet from "./PaymentMethodBottomSheet";

import styles from "./styles.module.scss";

const MAX_PAYMENT_METHODS_AVAILABLE = 3;

interface PaymentMethodListProps {
  list: PaymentMethodProfile[];
}

const PaymentMethodList: React.FC<PaymentMethodListProps> = ({ list }) => {
  const [openPaymentMehodSheet, setOpenPaymentMehodSheet] = useState<boolean>(
    false
  );
  const [openInfoSheet, setOpenInfoSheet] = useState<boolean>(false);
  const [positionSelected, setPositionSelected] = useState<number>(0);

  const onPaymentMethodClick = (position: number) => {
    setOpenPaymentMehodSheet(true);
    setPositionSelected(position);
  };

  return (
    <div className={styles.paymentMethodListWrapper}>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate800)"
        className={styles.title}
      >
        Elegí cómo pagás{" "}
        <Info size={16} onClick={() => setOpenInfoSheet(true)} />
      </Text>

      <div className={styles.listContainer}>
        {list.map((method, index) => {
          const position = index + 1;

          return (
            <PaymentMethod
              icon={getCurrencyIcon({
                currency: method.paymentMethod,
                size: 24,
              })}
              amount={method.amountAvailableArs}
              position={position}
              key={index}
              onClick={() => onPaymentMethodClick(position)}
            />
          );
        })}

        {list.length < MAX_PAYMENT_METHODS_AVAILABLE &&
          Array.from(
            { length: MAX_PAYMENT_METHODS_AVAILABLE - list.length },
            (_, index) => index
          ).map((index: number) => {
            const position = list.length + index + 1;

            return (
              <PaymentMethod
                isEmpty
                isDisabled={index !== 0}
                key={index}
                position={position}
                onClick={() => onPaymentMethodClick(position)}
              />
            );
          })}
      </div>

      {openInfoSheet && (
        <PaymentMethodInfo
          open={openInfoSheet}
          onClose={() => setOpenInfoSheet(false)}
        />
      )}

      {openPaymentMehodSheet && (
        <PaymentMethodBottomSheet
          open={openPaymentMehodSheet}
          onClose={() => setOpenPaymentMehodSheet(false)}
          positionSelected={positionSelected}
        />
      )}
    </div>
  );
};

export default PaymentMethodList;
