import Heading, { HeadingVariants } from "components/common/Heading";
import { usePortfolioQuery } from "apis/portfolio/queries/usePortfolioQuery";
import { PortfolioFromType } from "interfaces/wallet";
import { usePortfolio } from "context/PortfolioProvider";
import Skeleton from "react-loading-skeleton";
import px2rem from "utils/px2rem";

import CurrencyDetailCard from "./CurrencyDetailCard";

import styles from "./styles.module.scss";

const CurrencyDetails: React.FC = () => {
  const { selectedCurrency } = usePortfolio();
  const { data: portfolioData, isLoading } = usePortfolioQuery(
    selectedCurrency,
    PortfolioFromType.BROKER
  );

  const CARD_HEIGHT = px2rem(162);

  return (
    <div>
      <Heading
        component="h3"
        color="var(--slate800)"
        variant={HeadingVariants.RegularTitle}
      >
        Dinero
      </Heading>
      <div className={styles.cardsWrapper}>
        {isLoading ? (
          <div className={styles.skeletonWrapper}>
            <Skeleton height={CARD_HEIGHT} />
            <Skeleton height={CARD_HEIGHT} />
            <Skeleton height={CARD_HEIGHT} />
          </div>
        ) : (
          <>
            {portfolioData?.cash.map(({ ticker, settlements }) => {
              return (
                <CurrencyDetailCard
                  key={ticker}
                  currency={ticker}
                  settlements={settlements}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default CurrencyDetails;
