import { useState, useRef, useEffect } from "react";

import API from "apis";
import { cardService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import { CardTypesLabel } from "interfaces/card/enums";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { getEnvironment } from "utils";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";
import { PomelosCardDataURL } from "components/page-cocos-card/constants";
import { useCocosCard } from "context/CocosCardProvider";
import { UIModalSlider } from "components/common/modal-slider";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";

import styles from "./styles.module.scss";

interface CardInfoProps {
  open: boolean;
  onClose: () => void;
}

interface CardToken {
  access_token: string;
  expires_in: number;
  token_type: string;
}

const CardInfo: React.FC<CardInfoProps> = ({ open, onClose }) => {
  const isDarkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );
  const { selectedCard, virtualCard, physicalCard } = useCocosCard();
  const { isProd } = getEnvironment();
  const { isIOS } = getPlatform();

  const [cardToken, setCardToken] = useState<CardToken>();
  const frameRef = useRef<HTMLIFrameElement>(null);

  const getCardToken = async () => {
    try {
      const { data } = await API.get(cardService.credentials);

      setCardToken(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const isPhysicalCard = selectedCard === CardTypesLabel.PHYSICAL;
  const cardId = isPhysicalCard
    ? physicalCard?.externalId
    : virtualCard?.externalId;

  const pomeloSecureDataUrl = isProd
    ? PomelosCardDataURL.PRODUCTION
    : PomelosCardDataURL.STAGING;

  const textStyles = isDarkMode
    ? "color:rgb(237,238,240)!important;span.label{color:rgb(176,180,186)!important}border-bottom:solid 0.5px rgba(54,58,63,1)!important}.security-code{border:none!important;margin:0!important};"
    : "color:rgb(28,32,36)!important;span.label{color:rgb(96,100,108)!important}border-bottom:solid 0.5px rgb(217 217 224)!important}.security-code{border:none!important;margin:0!important;";

  const inlineStyles =
    ".list{box-shadow:none!important;margin:0!important;width:inherit!important;padding:12px 16px!important}.pan,.name,.expiration-date,.security-code{margin-bottom:12px!important;padding-bottom:12px!important;";

  const urlIFrame = `${pomeloSecureDataUrl}/v1/${cardId}?auth=${cardToken?.access_token}&field_list=name,code,pan,expiration&layout=list&locale=es&styles_string=${inlineStyles}${textStyles}`;

  useEffect(() => {
    getCardToken();
  }, []);

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      <Text variant={TextVariants.RegularText} className={styles.title}>
        Datos de tu tarjeta
      </Text>
      <div className={styles.sheetContent}>
        <iframe
          src={urlIFrame}
          width="100%"
          height="300px"
          ref={frameRef}
          frameBorder={0}
          loading="lazy"
        />
      </div>
      <div
        className={classNames(styles.buttonWrapper, { [styles.ios]: isIOS })}
      >
        <FilledButton variant={ThemeVariants.Pay} onClick={onClose}>
          Cerrar
        </FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default CardInfo;
