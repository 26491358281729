import { useState } from "react";

import { Typography } from "@mui/material";
import { useMovements } from "context/MovementsProvider";
import { MovementsTypes } from "interfaces/movements";
import { Currencies } from "interfaces/wallet";
import CommonLayout from "layouts/common";
import {
  OverlappedButtonsSizes,
  OverlappedButtonsTypes,
  UIOverlappedButtons,
} from "components/common/overlapped-buttons";

import MovementsFilter from "../movements-common/movements-filter";
import MovementsInstrument from "../movements-common/movements-instrument";
import MovementsMonetary from "../movements-common/movements-monetary";

import styles from "./styles.module.scss";

const MovementsMobile = () => {
  const [filter, setFilter] = useState<boolean>(false);
  const {
    section,
    setSection,
    cashMovementsData,
    handleClearFilters,
    tickersMovementsData,
    getCashMovementsData,
    getTickerMovementsData,
  } = useMovements();

  const movementTypes = [
    {
      label: "Monetarios",
      value: MovementsTypes.MONETARY,
    },
    {
      label: "Instrumentos",
      value: MovementsTypes.INSTRUMENT,
    },
  ];

  const isMonetary = section === MovementsTypes.MONETARY;

  const handleSection = (value: string) => {
    handleClearFilters();
    setSection(value);

    if (value === MovementsTypes.MONETARY) {
      getCashMovementsData(Currencies.ARS);
      return;
    }

    getTickerMovementsData();
  };

  const handleFilter = (value: boolean) => {
    setFilter(value);
  };

  return (
    <div className={styles.mobilePage}>
      <CommonLayout withHeader={false}>
        <>
          <div className={styles.titles}>
            <Typography className={styles.title}>Movimientos</Typography>
            {section === MovementsTypes.INSTRUMENT && (
              <Typography className={styles.subtitle}>
                Encontrá tus operaciones realizadas
              </Typography>
            )}
          </div>
          <div className={styles.movementsContent}>
            {filter ? (
              <MovementsFilter handleFilter={handleFilter} />
            ) : (
              <>
                <UIOverlappedButtons
                  options={movementTypes}
                  selectedValue={section}
                  type={OverlappedButtonsTypes.Primary}
                  size={OverlappedButtonsSizes.Full}
                  onChangeValue={handleSection}
                />
                {isMonetary ? (
                  <MovementsMonetary
                    cashMovements={cashMovementsData}
                    onClickFilter={handleFilter}
                  />
                ) : (
                  <MovementsInstrument
                    tickerMovements={tickersMovementsData}
                    onClickFilter={handleFilter}
                  />
                )}
              </>
            )}
          </div>
        </>
      </CommonLayout>
    </div>
  );
};

export default MovementsMobile;
