interface PropTypes {
  size?: number;
  color?: string;
}

export const ArrowUpIcon = ({ size, color }: PropTypes) => {
  return (
    <svg
      className="arrow-up"
      fill="none"
      height={`${size ? size : 24}`}
      viewBox="0 0 24 24"
      width={`${size ? size : 24}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0001 12L12.0001 8M12.0001 8L8.00006 12M12.0001 8V16M22.0001 12C22.0001 17.5228 17.5229 22 12.0001 22C6.47721 22 2.00006 17.5228 2.00006 12C2.00006 6.47715 6.47721 2 12.0001 2C17.5229 2 22.0001 6.47715 22.0001 12Z"
        stroke={color || "#002C65"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
