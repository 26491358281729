import { useAppSelector } from "hooks/redux";
import { getLoggedUser } from "store/selectors/user.selector";

import ClaimTag from "./Claim";
import SuccessTag from "./Success";

const CocosTagPage: React.FC = () => {
  const user = useAppSelector(getLoggedUser);

  if (user?.tag) return <SuccessTag tag={user.tag} />;

  return <ClaimTag />;
};

export default CocosTagPage;
