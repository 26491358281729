import Error from "assets/illustrations/Error";
import Success from "assets/illustrations/Success";
import Heading, { HeadingVariants } from "components/common/Heading";

import styles from "./styles.module.scss";

interface ResponseHeaderProps {
  success: boolean;
}

const ResponseHeader: React.FC<ResponseHeaderProps> = ({ success }) => {
  const title = success
    ? "Tu pago se realizó con éxito."
    : "Ocurrió un error al realizar tu pago.";

  const Icon = success ? Success : Error;

  return (
    <div className={styles.ResponseHeaderContainer}>
      <Icon />
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        component="h2"
        color="var(--slate800)"
        className={styles.title}
      >
        {title}
      </Heading>
    </div>
  );
};

export default ResponseHeader;
