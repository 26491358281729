import { Typography } from "@mui/material";
import { OrdersResponse, OrderStatusTypes } from "interfaces/api-responses";
import { OrderType, Instruments } from "interfaces/markets";
import { setOrderDetail } from "features/markets";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import { getOrderStatus, getCurrencyLabel } from "utils";
import { Currency } from "components/common/currency";
import { getOrderName } from "components/page-orders/utils";
import { OrderStatusBadge } from "components/page-orders/OrdersList/components/OrderStatusBadge";
import { useAppDispatch } from "hooks/redux";
import { ACTIVE_BID } from "components/common/bid/constants";

import styles from "./styles.module.scss";

interface PropTypes {
  order: OrdersResponse;
  onClickOrderOptions?: (order: OrdersResponse) => void;
  onClickOrderRow: (orderId: string) => void;
}

export const OrderRowDesktop = (props: PropTypes) => {
  const dispatch = useAppDispatch();
  const { order, onClickOrderRow } = props;

  const {
    status,
    result_amount,
    set_amount,
    result_quantity,
    set_quantity,
    result_price,
    set_price,
    order_id,
    order_type,
    logo_file_name,
    ticker,
    order_type_label,
    currency,
    instrument_type,
    settlement_days,
    instrument_short_name,
  } = order;

  const isCloseMep = instrument_short_name === "Operatoria Dólar MEP";
  const isFciInstrument = instrument_type === Instruments.FCI;
  const isStatusExecuted = status === OrderStatusTypes.EXECUTED;
  const orderPrice = isStatusExecuted ? result_price : set_price;
  const orderQuantity = isStatusExecuted ? result_quantity : set_quantity;
  const orderTotal = isStatusExecuted ? result_amount : set_amount;

  const handleClickRow = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setOrderDetail(order));
    onClickOrderRow(order_id);
  };

  const isActiveBid = ticker === ACTIVE_BID.instrument_code;

  return (
    <div onClick={handleClickRow}>
      <div className={styles.rowContainer}>
        <div className={styles.operationInfo}>
          <InstrumentLogoUI
            logoFileName={logo_file_name}
            placeholderStyle={
              order_type === OrderType.Buy ? "positive" : "negative"
            }
            type={instrument_type}
            variant={InstrumentLogoVariants.Secondary}
          />
          <Typography
            className={styles.operationText}
            component="p"
            variant="textM_bold"
          >
            {getOrderName(
              isCloseMep ? instrument_short_name : ticker || currency
            )}
          </Typography>
        </div>

        <Typography variant="textS">{order_type_label}</Typography>

        {isActiveBid ? (
          <Typography textAlign="right" variant="textS">
            {"-"}
          </Typography>
        ) : (
          <Typography textAlign="right" variant="textS">
            {`${getCurrencyLabel(currency)}`}
            <Currency>{orderPrice}</Currency>
          </Typography>
        )}

        <Typography textAlign="right" variant="textS">
          {(isFciInstrument && !orderQuantity) || isActiveBid
            ? "-"
            : orderQuantity}
        </Typography>

        <Typography textAlign="right" variant="textS">
          {`${getCurrencyLabel(currency)} `}
          <Currency>
            {isFciInstrument ? Math.abs(set_amount) : orderTotal}
          </Currency>
        </Typography>

        <Typography textAlign="center" variant="textS_bold">
          {settlement_days ? (settlement_days * 24).toString() + "hs" : "CI"}
        </Typography>

        <div className={styles.status}>
          <OrderStatusBadge status={status} />
          <Typography variant="textXS">
            {getOrderStatus(status).label}{" "}
          </Typography>
        </div>
      </div>
    </div>
  );
};
