export interface DNICodeFormattedProps {
  firstName: string;
  lastName: string;
  cuit: string;
  gender: string;
  dni: string;
  procedureNumber: string;
  type: string;
  birthDate: string;
  expirationDate?: string;
}

const formatDNICode = (code: string): DNICodeFormattedProps => {
  const values = code.split("@");

  const procedureNumber = values[0];
  const lastName = values[1];
  const firstName = values[2];
  const gender = values[3];
  const dni = values[4];
  const type = values[5];
  const birthDate = values[6];
  const expirationDate = values[7];
  const cuit = `${values[8].slice(0, 2)}-${dni}-${values[8].slice(2, 3)}`;

  const DNIInfo: DNICodeFormattedProps = {
    firstName,
    lastName,
    cuit,
    gender,
    dni,
    expirationDate,
    type,
    birthDate,
    procedureNumber,
  };

  return DNIInfo;
};

export default formatDNICode;
