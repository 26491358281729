import CocosCardIntro1 from "lotties/cocos-card-01.json";
import Lottie from "react-lottie";

const CocosCardIntroAnimation1: React.FC = () => (
  <div>
    <Lottie
      isPaused={false}
      options={{
        loop: false,
        autoplay: true,
        animationData: CocosCardIntro1,
        rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
      }}
      height={350}
      width={300}
      isClickToPauseDisabled={true}
    />
  </div>
);

export default CocosCardIntroAnimation1;
