interface MasterCardProps {
  isDisabled?: boolean;
}

const MasterCard: React.FC<MasterCardProps> = ({ isDisabled = false }) => {
  return (
    <svg
      width="47"
      height="28"
      viewBox="0 0 47 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.447 2.99414L17.1982 2.99414L17.1982 25.0055L29.447 25.0055L29.447 2.99414Z"
        fill={isDisabled ? "#848488" : "#FF5F00"}
      />
      <path
        d="M17.9843 14.0017C17.9824 11.8819 18.4627 9.78938 19.389 7.88262C20.3154 5.97587 21.6633 4.30485 23.3309 2.99607C21.2658 1.37287 18.7857 0.363434 16.1741 0.0831235C13.5624 -0.197187 10.9246 0.262934 8.56211 1.41091C6.1996 2.5589 4.20774 4.34842 2.81419 6.57492C1.42064 8.80142 0.681641 11.3751 0.681641 14.0017C0.681641 16.6284 1.42064 19.202 2.81419 21.4285C4.20774 23.655 6.1996 25.4446 8.56211 26.5926C10.9246 27.7405 13.5624 28.2007 16.1741 27.9203C18.7857 27.64 21.2658 26.6306 23.3309 25.0074C21.6633 23.6986 20.3154 22.0276 19.3891 20.1208C18.4628 18.2141 17.9824 16.1216 17.9843 14.0017Z"
        fill={isDisabled ? "#49494D" : "#EB001B"}
      />
      <path
        d="M45.9802 13.999C45.9803 16.6257 45.2413 19.1993 43.8479 21.4258C42.4544 23.6523 40.4626 25.4418 38.1001 26.5898C35.7377 27.7378 33.0999 28.198 30.4882 27.9177C27.8766 27.6373 25.3966 26.6279 23.3315 25.0047C24.9977 23.6946 26.3446 22.0233 27.2708 20.1169C28.1969 18.2104 28.6782 16.1186 28.6782 13.999C28.6782 11.8795 28.1969 9.78768 27.2708 7.88123C26.3446 5.97477 24.9977 4.30349 23.3315 2.99338C25.3966 1.37018 27.8766 0.360741 30.4882 0.0804343C33.0999 -0.199873 35.7377 0.260268 38.1001 1.40826C40.4626 2.55625 42.4544 4.34577 43.8479 6.57228C45.2413 8.79878 45.9803 11.3724 45.9802 13.999Z"
        fill={isDisabled ? "#AAAAAD" : "#F79E1B"}
      />
      <path
        d="M44.6424 22.6774V22.2267H44.8241V22.1349H44.3613V22.2267H44.5431V22.6774H44.6424ZM45.5408 22.6774V22.134H45.399L45.2358 22.5078L45.0726 22.134H44.9307V22.6774H45.0309V22.2675L45.1839 22.6209H45.2877L45.4407 22.2666V22.6774H45.5408Z"
        fill={isDisabled ? "#AAAAAD" : "#F79E1B"}
      />
    </svg>
  );
};

export default MasterCard;
