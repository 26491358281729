import { useState, useEffect } from "react";

import {
  BottomNavigation,
  BottomNavigationAction,
  Typography,
} from "@mui/material";
import { Currencies } from "interfaces/wallet";

import "./index.scss";

interface Options {
  label: string;
  value: string;
}
interface PropTypes {
  onClickHeaderOption: (option: Currencies) => void;
  selected: string;
  options: Options[];
}

export const TableHeader = ({
  onClickHeaderOption,
  selected,
  options,
}: PropTypes) => {
  const [value, setValue] = useState(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  const handleChange = (event: any, newValue: Currencies) => {
    setValue(newValue);
    onClickHeaderOption(newValue);
  };

  return (
    <BottomNavigation
      showLabels
      className="ui-table-header"
      style={{ justifyContent: "normal" }}
      value={value}
      onChange={handleChange}
    >
      {options.map((option) => (
        <BottomNavigationAction
          key={option.value}
          label={<Typography variant="textS_bold">{option.label}</Typography>}
          value={option.value}
        />
      ))}
    </BottomNavigation>
  );
};
