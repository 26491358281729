import { CocosFunds } from "constants/instruments";

import Heading, { HeadingVariants } from "components/common/Heading";
import GradientCard from "components/common/GradientCard";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { getAnnualRate } from "utils/getAnnualRate";
import {
  cleanHistoricalData,
  clearShortTickerMarketData,
  getMarketDetailByShortTicker,
  resetMarketDataDetail,
  resetOrder,
  setMarketDataDetail,
} from "features/markets";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { ampli } from "ampli";
import { clearSellingPower } from "features/wallet/walletSlice";
import { Currencies } from "interfaces/wallet";
import { getSettlementDays } from "utils";
import { useHomeTickersQuery } from "apis/market/queries/useHomeTickersQuery";
import type { RootState } from "store/store";
import type { InstrumentData } from "interfaces/api-responses";
import { Instruments } from "interfaces/markets";

import styles from "./styles.module.scss";

const GetPerformanceSection: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const { data: homeTickers } = useHomeTickersQuery();

  const onClickCard = (fund: CocosFunds.RMA | CocosFunds.USD) => {
    dispatch(resetMarketDataDetail());
    ampli.homeExited({ referral: fund });

    const market = homeTickers?.find(
      (item: InstrumentData) => item.instrument_code === fund
    );

    dispatch(setMarketDataDetail(market));

    const {
      last,
      currency,
      long_ticker,
      settlement_days,
      instrument_code,
      instrument_short_name,
    } = market;

    if (order && !order.instrument_code?.includes(instrument_code ?? "")) {
      dispatch(
        resetOrder({
          long_ticker,
          price: last,
          instrument_code,
          instrument_short_name,
          term: getSettlementDays(settlement_days),
          currency: currency === Currencies.USD ? currency : Currencies.ARS,
        })
      );
      dispatch(clearSellingPower());
      dispatch(clearShortTickerMarketData());
      dispatch(resetMarketDataDetail());
      dispatch(cleanHistoricalData());
    } else {
      dispatch(
        getMarketDetailByShortTicker({
          short_ticker: instrument_code,
          instrument_type: Instruments.FCI,
        })
      );
    }

    navigate(`${mainRoutes.funds}/${fund}`);
  };

  return (
    <div className={styles.getPerformanceWrapper}>
      <Heading
        component="h2"
        color="var(--slate800)"
        variant={HeadingVariants.RegularSubtitle}
      >
        Obtené rendimientos
      </Heading>
      <div className={styles.cardsWrapper}>
        <GradientCard
          link="Invertir"
          title="Invertí tus pesos"
          subtitle={CocosFunds.RMA}
          variation={getAnnualRate(CocosFunds.RMA)}
          onClick={() => onClickCard(CocosFunds.RMA)}
        />
        <GradientCard
          link="Invertir"
          subtitle={CocosFunds.USD}
          title="Invertí tus dólares"
          variation={getAnnualRate(CocosFunds.USD)}
          onClick={() => onClickCard(CocosFunds.USD)}
        />
      </div>
    </div>
  );
};

export default GetPerformanceSection;
