const CocosCardLogoLight = () => {
  return (
    <svg
      width="94"
      height="80"
      viewBox="0 0 94 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Cocos Capital">
        <path
          d="M3.36318 47.3921C8.68259 58.2154 20.3201 64.2949 32.9578 64.1567L33.0509 64.0876C39.0565 63.9984 44.9663 62.5907 50.3576 59.9655L56.5361 56.9719L50.5198 44.7438L44.3413 47.7375C33.0282 53.1952 20.2284 50.3397 15.7907 41.3587C14.7946 39.1852 14.2919 36.822 14.3158 34.4349C14.3398 32.048 14.8903 29.6952 15.9303 27.5418C18.4557 22.1193 22.8322 17.7556 28.2886 15.2217L34.4685 12.228L28.4509 0L22.2724 2.97063C13.8903 6.91722 7.1905 13.6811 3.36318 22.0611C1.47744 26.0219 0.5 30.3471 0.5 34.7267C0.5 39.1061 1.47744 43.4313 3.36318 47.3921Z"
          fill="#F0F0F3"
        />
        <path
          d="M60.092 67.8871L66.3875 80L72.473 76.8911C90.3608 67.7489 98.445 47.6222 90.3608 32.0551C82.2766 16.488 61.1133 11.1915 43.1802 20.4028L37.0709 23.5116L43.3664 35.6245L49.4758 32.5156C60.6732 26.7816 73.5182 29.4068 78.1421 38.2958C82.7647 47.1846 77.3987 59.0673 66.2014 64.7782L60.092 67.8871Z"
          fill="#F0F0F3"
        />
      </g>
    </svg>
  );
};

export default CocosCardLogoLight;
