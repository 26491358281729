import { Browser } from "@capacitor/browser";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { GraduationCap } from "lucide-react";
import { externalRoutes } from "router/routes";

import ProfileSectionTemplate from "../components/SectionTemplate";

const ProfileLearn: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  const sectionTitle = "Aprendé";

  const sectionOptions = [
    {
      icon: GraduationCap,
      label: "Cocos Academy",
      onClick: () => Browser.open({ url: externalRoutes.academy }),
    },
  ];

  return (
    <ProfileSectionTemplate
      title={sectionTitle}
      subtitle={isMobile ? sectionTitle : undefined}
      options={sectionOptions}
      withHeader={isMobile}
    />
  );
};

export default ProfileLearn;
