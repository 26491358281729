import { Rocket, ShieldCheck, Globe } from "lucide-react";
import { ThemeVariants } from "interfaces/theme";
import { CardSections, UserStatus } from "interfaces/card/enums";
import { useCocosCard } from "context/CocosCardProvider";
import Heading, { HeadingVariants } from "components/common/Heading";
import BasicWrapper from "components/common/BasicWrapper";

import InfoRow from "../components/InfoRow";

import styles from "./styles.module.scss";

const VirtualReady: React.FC = () => {
  const { setSelectedSection, userStatus } = useCocosCard();

  const onClose = () =>
    userStatus === UserStatus.ACTIVE
      ? setSelectedSection(CardSections.HOME)
      : setSelectedSection(CardSections.CARD_SELECTION);

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={{
        title: "¡Listo!",
        onClick: () => onClose(),
      }}
      className={styles.virtualReadyContainer}
      primaryButtonProps={{
        children: "Continuar",
        onClick: () => setSelectedSection(CardSections.HOME),
      }}
    >
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
        className={styles.titleHeader}
      >
        Tu tarjeta virtual está lista para que la empieces a usar
      </Heading>
      <div>
        <InfoRow
          icon={Globe}
          title="Hacé pagos online"
          subtitle="Tu tarjeta virtual es ideal para consumos en sitios web, o a través de plataformas e-commerce."
        />
        <InfoRow
          icon={ShieldCheck}
          title="De forma segura"
          subtitle="Podés pausar y volver a activar tu tarjeta cuando lo desees."
        />
        <InfoRow
          icon={Rocket}
          title="Con el método que quieras"
          subtitle="Elegí hasta tres métodos de pago y viví la experiencia Cocos Card."
        />
      </div>
    </BasicWrapper>
  );
};

export default VirtualReady;
