import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";

import * as Sentry from "@sentry/react";
import API from "apis";
import { onBoardingService } from "apis/services";
import { useNavigate } from "react-router-dom";
import { Steps } from "interfaces/createAccount";
import {
  CreateAccountData,
  OnboardingData,
  NextStep,
} from "interfaces/api-responses";
import { refreshPage } from "utils";
import mainRoutes from "router/routes";
import { withRetries } from "utils/retries";
import { Projects } from "utils/amplitude";
import {
  CropType,
  cropBase64ImageToBlob,
} from "components/page-create-account/utils/processImage";

interface Context {
  accountError: string;
  setAccountError: (error: string) => void;
  step: Steps;
  setStep: (v: Steps) => void;
  dniCode: string;
  setDniCode: (v: string) => void;
  dniFrontImage: string;
  setDniFrontImage: (v: string) => void;
  dniBackImage: string;
  setDniBackImage: (v: string) => void;
  goToNextStep: () => void;
  goToHome: () => void;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  error: boolean;
  setError: (v: boolean) => void;
  cuitError: boolean;
  setCuitError: (v: boolean) => void;
  createUserAccount: (values: CreateAccountData) => Promise<void>;
  uploadDocument: (
    documentType: string,
    imageFile: string,
    imageFileName: string,
    type: CropType
  ) => Promise<void>;
  isInitialStateLoading: boolean;
}

const CreateAccountContext = createContext<Context | null>(null);

export const CreateAccountProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();

  const setInitialStep = (nextStep: NextStep) => {
    switch (nextStep) {
      case NextStep.IDENTITY:
        return setStep(Steps.INTRO);

      case NextStep.SELFIE:
        return setStep(Steps.SELFIE);

      case NextStep.PERSONAL_DATA:
        return setStep(Steps.PERSONAL_INFO);

      default:
        return setStep(Steps.FINAL);
    }
  };

  const [step, setStep] = useState<Steps>(Steps.INTRO);
  const [dniCode, setDniCode] = useState<string>("");
  const [dniFrontImage, setDniFrontImage] = useState<string>("");
  const [dniBackImage, setDniBackImage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialStateLoading, setIsInitialStateLoading] = useState<boolean>(
    false
  );
  const [error, setError] = useState<boolean>(false);
  const [accountError, setAccountError] = useState("");
  const [cuitError, setCuitError] = useState<boolean>(false);

  useEffect(() => {
    const getOnboarding = async () => {
      setIsInitialStateLoading(true);
      try {
        const {
          data: { nextStep },
        } = await API.get<OnboardingData>(onBoardingService.getOnboarding);
        setInitialStep(nextStep);
      } catch (error: any) {
        throw new Error("Error getting onboarding data");
      } finally {
        setIsInitialStateLoading(false);
      }
    };

    getOnboarding();
  }, []);

  const goToHome = () => {
    navigate(mainRoutes.home);
    refreshPage();
  };

  const goToNextStep = () =>
    step === 2 && !!dniCode ? setStep(step + 2) : setStep(step + 1); // Skip manual input step if code was recognized

  const createUserAccount = async (values: CreateAccountData) => {
    try {
      await withRetries(() =>
        API.post<CreateAccountData>(onBoardingService.createAccount, values)
      );
    } catch (error: any) {
      setAccountError(
        error?.response?.data?.message ||
          error?.message ||
          "An unexpected error occurred"
      );
      Sentry.captureException(error, {
        tags: {
          page: Projects.ONBOARDING,
          code: "CREATE_ACCOUNT_ERROR",
        },
      });

      if (error?.response?.data?.code === "ONBOARDING_REJECTED") {
        setCuitError(true);
      }
      throw new Error(`Error creating account: ${error}`);
    }
  };

  const uploadDocument = async (
    documentType: string,
    imageFile: string,
    imageFileName: string,
    type: CropType
  ) => {
    try {
      const processedImage = await cropBase64ImageToBlob(imageFile, type);

      const formToSend = new FormData();
      formToSend.append("documentType", documentType);
      formToSend.append("file", processedImage, imageFileName);

      await withRetries(() =>
        API.post(onBoardingService.uploadDocument, formToSend, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      );
    } catch (error: any) {
      Sentry.captureException(error, {
        tags: {
          page: Projects.ONBOARDING,
          code: "UPLOAD_DOCUMENT_ERROR",
          documentType,
        },
      });

      throw new Error(`Error uploading document: ${error}`);
    }
  };

  const memoizedValues = useMemo(() => {
    return {
      accountError,
      setAccountError,
      step,
      setStep,
      dniCode,
      setDniCode,
      dniFrontImage,
      setDniFrontImage,
      dniBackImage,
      setDniBackImage,
      goToNextStep,
      goToHome,
      isLoading,
      setIsLoading,
      error,
      setError,
      cuitError,
      setCuitError,
      createUserAccount,
      uploadDocument,
      isInitialStateLoading,
    };
  }, [
    accountError,
    setAccountError,
    step,
    setStep,
    dniCode,
    setDniCode,
    dniFrontImage,
    setDniFrontImage,
    dniBackImage,
    setDniBackImage,
    goToNextStep,
    goToHome,
    isLoading,
    setIsLoading,
    error,
    setError,
    cuitError,
    setError,
    isInitialStateLoading,
  ]);

  return (
    <CreateAccountContext.Provider value={memoizedValues}>
      {children}
    </CreateAccountContext.Provider>
  );
};

export default CreateAccountContext;

export const useCreateAccount = () => {
  const context = useContext(CreateAccountContext);
  if (!context) {
    throw new Error("[CreateAccountContext] Missing context");
  }
  return context;
};
