import API from "../../../apis";
// import { subscribeOnStream, unsubscribeFromStream } from "./streaming.js";

const lastBarsCache = new Map();

const configurationData = {
  supported_resolutions: ["1D", "1W", "1M"],
  exchanges: [
    {
      value: "BYMA",
      name: "BYMA",
      desc: "BYMA",
    },
  ],
  symbols_types: [
    {
      name: "acciones",
      value: "ACCIONES",
    },
    {
      name: "cedears",
      value: "CEDEARS",
    },
  ],
};

async function getAllSymbols() {
  const [acciones, cedears, bonos, letras, on, fci] = await Promise.all([
    API.get("/v1/markets/lists/tickers", {
      params: {
        instrument_type: "ACCIONES",
        instrument_subtype: undefined,
        settlement_days: "24hs",
        currency: "ARS",
        segment: "C",
      },
    }),
    API.get("/v1/markets/lists/tickers", {
      params: {
        instrument_type: "ACCIONES",
        instrument_subtype: undefined,
        settlement_days: "24hs",
        currency: "USD",
        segment: "C",
      },
    }),
    API.get("/v1/markets/lists/tickers", {
      params: {
        instrument_type: "CEDEARS",
        instrument_subtype: undefined,
        settlement_days: "24hs",
        currency: "ARS",
        segment: "C",
      },
    }),
    API.get("/v1/markets/lists/tickers", {
      params: {
        instrument_type: "CEDEARS",
        instrument_subtype: undefined,
        settlement_days: "24hs",
        currency: "USD",
        segment: "C",
      },
    }),
    API.get("/v1/markets/lists/tickers", {
      params: {
        instrument_type: "BONOS_PUBLICOS",
        instrument_subtype: undefined,
        settlement_days: "24hs",
        currency: "ARS",
        segment: "C",
      },
    }),
    API.get("/v1/markets/lists/tickers", {
      params: {
        instrument_type: "BONOS_PUBLICOS",
        instrument_subtype: undefined,
        settlement_days: "24hs",
        currency: "ARS",
        segment: "C",
      },
    }),
    API.get("/v1/markets/lists/tickers", {
      params: {
        instrument_type: "LETRAS",
        instrument_subtype: undefined,
        settlement_days: "24hs",
        currency: "ARS",
        segment: "C",
      },
    }),
    API.get("/v1/markets/lists/tickers", {
      params: {
        instrument_type: "BONOS_CORP",
        instrument_subtype: "TOP",
        settlement_days: "24hs",
        currency: "ARS",
        segment: "C",
      },
    }),
    API.get("/v1/markets/lists/tickers", {
      params: {
        instrument_type: "FCI",
        instrument_subtype: undefined,
        settlement_days: undefined,
        currency: "ARS",
        segment: "FCI",
      },
    }),
  ]);

  const allSymbols = [
    ...acciones.data,
    ...cedears.data,
    ...bonos.data,
    ...letras.data,
    ...on.data,
    ...opciones.data,
    ...fci.data,
  ];

  return allSymbols;
}

async function getSymbolInfo(symbol) {
  try {
    if (!symbol) {
      throw new Error("Cannot resolve symbol name");
    }

    const { data, error } = await API.get(`/v1/markets/ticker/${symbol}`);

    if (error) {
      throw new Error(error);
    }

    return data;
  } catch (error) {
    console.error(error);
    onErrorCallback(error);
  }
}

export default {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    const symbols = await getAllSymbols();
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.id_venue === exchange;
      const isFullSymbolContainsInput =
        symbol.short_ticker.toLowerCase().indexOf(userInput.toLowerCase()) !==
        -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols[0]);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    try {
      const symbolItem = await getSymbolInfo(symbolName);

      if (!symbolItem) {
        console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
        onResolveErrorCallback("cannot resolve symbol");
        return;
      }
      const symbolInfo = {
        ticker: symbolItem.long_ticker,
        name: symbolItem.short_ticker,
        description: symbolItem.short_ticker,
        type: symbolItem.instrument_type,
        session: "1100-1700",
        timezone: "America/Argentina/Buenos_Aires",
        exchange: symbolItem.id_venue,
        minmov: 1,
        pricescale: 100,
        has_intraday: false,
        has_no_volume: true,
        has_weekly_and_monthly: false,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2,
        data_status: "streaming",
      };

      onSymbolResolvedCallback(symbolInfo);
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { from, to, firstDataRequest } = periodParams;
    const urlParameters = {
      id_venue: symbolInfo.exchange,
      from: from,
      to: to,
    };
    const query = Object.keys(urlParameters)
      .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
      .join("&");
    try {
      const { data } = await API.get(
        `/v1/markets/tickers/${symbolInfo.ticker}/historic-data-extended?${query}`
      );
      if ((data.Response && data.Response === "Error") || !data.t) {
        // "noData" should be set if there is no data in the requested period.
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }
      let bars = [];
      for (let index = 0; index < data.t.length; index++) {
        bars = [
          ...bars,
          {
            time: data.t[index] * 1000,
            low: data.l[index],
            high: data.h[index],
            open: data.o[index] || data.c[index],
            close: data.c[index] || data.o[index],
          },
        ];
      }
      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.long_ticker, {
          ...bars[bars.length - 1],
        });
      }
      onHistoryCallback(bars, {
        noData: false,
      });
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    // subscribeOnStream(
    //     symbolInfo,
    //     resolution,
    //     onRealtimeCallback,
    //     subscribeUID,
    //     onResetCacheNeededCallback,
    //     lastBarsCache.get(symbolInfo.full_name),
    // );
  },

  unsubscribeBars: (subscriberUID) => {
    // unsubscribeFromStream(subscriberUID);
  },
};
