import { CDN_URL } from "constants/index";

import { Input, Typography } from "@mui/material";
import { getNominalValue } from "components/common/trading-container/components/trading-body/utils";
import { Instruments } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { RiErrorWarningLine } from "react-icons/ri";
import NumberFormat, {
  NumberFormatValues,
  SourceInfo,
} from "react-number-format";
import { getCurrencySymbol } from "utils";
import { getColor } from "utils/getColor";

import styles from "./styles.module.scss";

interface PropTypes {
  value: number | undefined | null;
  handleChangeValue?: (value: number) => void;
  currency: Currencies | Instruments;
  logoFileName?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  newClass?: string;
  errorMessage?: string;
  decimals?: number;
  id?: string;
}

export const UIPriceInputV2 = ({
  value,
  handleChangeValue,
  currency,
  logoFileName,
  disabled = false,
  error = false,
  label,
  newClass,
  errorMessage,
  decimals = 2,
  id,
}: PropTypes) => {
  const isCurrency =
    currency === Currencies.ARS ||
    currency == Currencies.USD ||
    currency === Currencies.EXT ||
    currency == Currencies.EXT_ESP ||
    currency === Currencies.BRL;

  const getLogoSource = () => {
    return `${CDN_URL}/logos/${logoFileName}`;
  };

  const nominalValueString = !isCurrency ? getNominalValue(currency) : "";

  const handleChange = (
    { floatValue }: NumberFormatValues,
    source: SourceInfo
  ) => {
    if (handleChangeValue && source.source === "event")
      handleChangeValue(floatValue ?? 0);
  };

  const preffixString = isCurrency ? `${getCurrencySymbol(currency)} ` : "";

  const placeholderString = () => {
    if (isCurrency) {
      return `${getCurrencySymbol(currency)} 0,00`;
    }
    return `0 ${nominalValueString}`;
  };

  const suffixString = () => {
    if (!isCurrency) {
      return ` ${nominalValueString}`;
    }
    return "";
  };

  return (
    <div className={`${styles.priceInputContainer} ${newClass && newClass}`}>
      {label && (
        <Typography className={styles.label} component={"p"}>
          {label}
        </Typography>
      )}
      <NumberFormat
        fixedDecimalScale
        className={`${styles.priceInput} ${
          disabled ? styles.disabled : styles.abled
        } ${error && styles.error} ${value ? styles.completed : styles.empty}`}
        customInput={Input}
        decimalScale={isCurrency ? decimals : 0}
        decimalSeparator=","
        allowNegative={false}
        disabled={disabled}
        displayType="input"
        startAdornment={
          error ? (
            <RiErrorWarningLine size={32} color={getColor("--red")} />
          ) : (
            !isCurrency && logoFileName && <img src={getLogoSource()} />
          )
        }
        inputProps={{
          inputMode: "decimal",
          disableUnderline: true,
        }}
        placeholder={placeholderString()}
        pattern="[0-9]+([\.,][0-9]+)?"
        prefix={preffixString}
        suffix={suffixString()}
        thousandSeparator="."
        value={value ? value.toString().replace(".", ",") : ""}
        onValueChange={handleChange}
        sx={{
          input: {
            "&::placeholder": {
              opacity: 1,
            },
          },
        }}
        id={id}
      />
      {error && (
        <Typography className={styles.errorMessage}>{errorMessage}</Typography>
      )}
    </div>
  );
};
