import { useEffect } from "react";

import { Skeleton } from "@mui/material";
import NoCtte from "components/common/NoCtte";
import ContainerProfileTest from "components/common/investor-test-check/ContainerProfileTest";
import { OrderDetailSidebarDesktop } from "components/common/order-detail-sidebar";
import OrderRow from "components/common/order-row";
import { OrderRowDesktop } from "components/page-orders/OrdersList/components/OrderRowDesktop";
import DrawerLayout from "layouts/drawer";
import { isEmpty } from "lodash";
import { Navigate, useSearchParams } from "react-router-dom";
import { getOrderById, getOrders } from "features/markets";
import {
  openRightModal,
  closeRightModal,
} from "features/rightModal/rightModalSlice";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import { DesktopOrTablet, Mobile } from "utils/responsive";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

import { OrdersEmptyState } from "./components/OrdersEmptyState";
import { OrdersTableHeader } from "./components/OrdersTableHeader";

import styles from "./styles.module.scss";

const OrdersList = () => {
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();
  const orders = useAppSelector((state: RootState) => state.markets.orders);
  const loading = useAppSelector(
    (state: RootState) => state.markets.getOrdersListLoading
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const currentOrderId = searchParams.get("order");

  useEffect(() => {
    dispatch(getOrders());
  }, []);

  useEffect(() => {
    if (loading || !currentOrderId) return;
    dispatch(getOrderById(currentOrderId));
    openRightModalContent(OrderDetailSidebarComponent(currentOrderId));
    dispatch(
      openRightModal({
        onClose: () => setSearchParams({}),
      })
    );
  }, [loading, currentOrderId]);

  const handleCloseSidebar = () => {
    dispatch(closeRightModal());
  };

  const OrderDetailSidebarComponent = (selectedId: string) => (
    <DrawerLayout>
      <OrderDetailSidebarDesktop
        id={selectedId}
        onCloseSidebar={handleCloseSidebar}
      />
    </DrawerLayout>
  );

  const handleClickOrderRow = (orderId: string) => {
    setSearchParams({ order: orderId });
  };

  const user = useAppSelector(getLoggedUser);
  if (!user) return <Navigate to={mainRoutes.logout} replace />;
  const { hasAccount } = user;

  if (!hasAccount) {
    return <NoCtte />;
  }

  if (isEmpty(orders)) {
    return <OrdersEmptyState />;
  }

  return (
    <div className={styles.ordersContent}>
      <ContainerProfileTest />

      <OrdersTableHeader />

      <div className={styles.movementsRows}>
        {loading
          ? Array.from(Array(5)).map((i) => (
              <Skeleton key={i} animation="wave" height={80} />
            ))
          : orders?.map((order) => (
              <>
                <Mobile>
                  <OrderRow key={order.order_id} order={order} />
                </Mobile>

                <DesktopOrTablet>
                  <OrderRowDesktop
                    key={order.order_id}
                    order={order}
                    onClickOrderRow={handleClickOrderRow}
                  />
                </DesktopOrTablet>
              </>
            ))}
      </div>
    </div>
  );
};

export default OrdersList;
