import { IconProps } from "./BTC";

const FingerprintIcon: React.FC<IconProps> = ({ size, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      {...props}
    >
      <path d="M127-574q-7-3-8.5-11t1.5-14q61-90 157-139t206-49q107 0 202 46.5T842-607q6 9 4.5 16.5T839-578q-6 5-14 4.5t-13-9.5q-56-79-143.5-121.5T483-748q-99 0-185.5 42.5T156-582q-7 9-15 10.5t-14-2.5zM611-67Q494-91 427.5-172.5T361-367q0-51 35.5-87t86.5-36q51 0 87 34.5t36 84.5q0 35 24.5 59t59.5 24q34 0 58-24t24-59q0-117-85-196.5T482-647q-120 0-204 81.5T194-366q0 37 5.5 73t18.5 70q3 10 .5 16t-9.5 9q-8 3-16 1t-12-13q-10-29-17.5-69.5T156-367q0-131 96.5-226.5T483-689q136 0 232 92.5T811-371q0 50-35 86t-86 36q-52 0-88-34.5T566-367q0-35-24-60t-59-25q-34 0-58.5 25T400-367q0 102 58 172t164 93q9 1 12 7.5t2 13.5q-2 8-7.5 12T611-67zM249-790q-10 5-16.5 3t-10.5-8q-3-4-2-13.5t8-12.5q58-34 123.5-49.5T483-886q66 0 129.5 15.5T734-824q9 4 10.5 12t-1.5 14q-4 6-11 9.5t-15-1.5q-54-27-113.5-42T483-847q-62 0-121 14t-113 43zM375-84q-58-59-87-128.5T259-367q0-92 65-157t159-65q93 0 159.5 63.5T709-371q0 10-4.5 16.5T690-348q-11 0-16-6.5t-5-16.5q0-76-54.5-128T483-551q-77 0-131 54t-54 130q0 80 25 139.5T405-109q7 7 7 14t-4 12q-5 7-15.5 7T375-84zm322-68q-97 0-165-56.5T464-370q0-9 5-15t14-6q9 0 14 6t5 15q0 88 57.5 134T697-190q10 0 23-2t24-2q8 0 13.5 5t4.5 11q0 8-3.5 12.5T749-159q-14 5-28.5 6t-23.5 1z" />
    </svg>
  );
};

export default FingerprintIcon;
