import { ATENCION_CRYPTO_EMAIL } from "constants/emails";

import Error from "assets/illustrations/Error";
import BasicWrapper from "components/common/BasicWrapper";
import Text, { TextVariants } from "components/common/Text";
import px2rem from "utils/px2rem";
import { Browser } from "@capacitor/browser";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface PaymentsErrorProps {
  onClose: () => void;
}

const PaymentsError: React.FC<PaymentsErrorProps> = ({ onClose }) => {
  const onClickContactSupport = () => {
    Browser.open({ url: `mailto:${ATENCION_CRYPTO_EMAIL}` });
  };

  return (
    <BasicWrapper
      className={styles.basicWrapper}
      navigationHeaderProps={{
        onClick: onClose,
      }}
      title="Algo salió mal"
      primaryButtonProps={{
        children: "Contactar a soporte",
        onClick: onClickContactSupport,
      }}
      secondaryButtonProps={{
        children: "Volver al inicio",
        onClick: onClose,
      }}
      themeVariant={ThemeVariants.Pay}
    >
      <div className={styles.errorWrapper}>
        <Error size={px2rem(150)} className={styles.illustration} />
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate800)"
          className={styles.text}
        >
          Hubo un error al intentar acceder a Pagos con QR.
        </Text>
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate800)"
          className={styles.text}
        >
          Si el error persiste, por favor comunicate a{" "}
          <Text
            variant={TextVariants.SemiboldText}
            color="var(--black-to-white)"
            component="a"
            href={`mailto:${ATENCION_CRYPTO_EMAIL}`}
          >
            {" "}
            {ATENCION_CRYPTO_EMAIL}
          </Text>
        </Text>
      </div>
    </BasicWrapper>
  );
};

export default PaymentsError;
