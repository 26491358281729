interface PropTypes {
  size?: number | string;
  color?: string;
  thickness?: number;
}

export const ViewIcon = (props: PropTypes) => {
  const { size = 17, color = "#0062E1", thickness = 1.5 } = props;

  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 17 17"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.15686 8.48983C1.15686 8.48983 3.82353 3.15649 8.49019 3.15649C13.1569 3.15649 15.8235 8.48983 15.8235 8.48983C15.8235 8.48983 13.1569 13.8232 8.49019 13.8232C3.82353 13.8232 1.15686 8.48983 1.15686 8.48983Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={`${thickness}`}
      />
      <path
        d="M8.49019 10.4897C9.59476 10.4897 10.4902 9.59431 10.4902 8.48975C10.4902 7.38518 9.59476 6.48975 8.49019 6.48975C7.38562 6.48975 6.49019 7.38518 6.49019 8.48975C6.49019 9.59431 7.38562 10.4897 8.49019 10.4897Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={`${thickness}`}
      />
    </svg>
  );
};
